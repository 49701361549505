import React, { useState, useEffect } from "react";
import { Modal, ModalBody, ModalHeader, Table } from "reactstrap";
// componentes
import ModalDetails from "../ModalDetails";
// service
import { getSalesDetails } from "../../../../utils/services/sales";

export default function ModalProducts(props) {
  const { isOpen, toggle, idSelected, token } = props;
  // carregar
  const [loading, setLoading] = useState(true);
  // id do obj para ter informações
  const [saleId, setSaleId] = useState({});
  // modal
  const [modalDetails, setModalDetails] = useState(false);
  //   dados
  const [data, setData] = useState([]);

  function getInfo() {
    getSalesDetails({ jwt: token, idSale: idSelected }).then((res) => {
      if (res.transactions) setData(res.transactions);

      setLoading(false);
    });
    // .catch((error) => console.log("erro", error));
  }

  useEffect(() => {
    getInfo();
  }, []);

  if (loading)
    return (
      <>
        <Modal isOpen={isOpen} centered>
          <ModalHeader toggle={toggle}>Produtos</ModalHeader>
          <ModalBody style={{ display: "flex", justifyContent: "center" }}>
            <div className="circle-loading" />
          </ModalBody>
        </Modal>
      </>
    );

  return (
    <>
      {modalDetails && (
        <ModalDetails
          isOpen={modalDetails}
          toggle={() => setModalDetails(false)}
          saleId={saleId}
          token={token}
        />
      )}

      <Modal isOpen={isOpen} centered size="lg">
        <ModalHeader toggle={toggle}>Produtos</ModalHeader>
        <ModalBody>
          <div className="table-responsive">
            <Table className="table mb-0">
              <thead className="table-light">
                <tr>
                  <th>Participante</th>
                  <th>Produto</th>
                  <th>Quantidade</th>
                  <th>Valor</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                {data.length > 0 &&
                  data.map((e, i) => (
                    <tr key={i}>
                      <td>{e.name}</td>
                      <td>{e.description}</td>
                      <td>{e.quantity}</td>
                      <td>R$ {e.amount}</td>
                      <td>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <i
                            style={{
                              color: "#aea017",
                              fontSize: 17,
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              setSaleId(e.transaction_id) >
                              setModalDetails(true)
                            }
                            className="bx bx-info-circle"
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
}
