import React, { useState, useEffect } from "react";
import { Modal, ModalBody, ModalHeader, Col, Row, Button } from "reactstrap";
import Select from "react-select";

import { connect } from "react-redux";

import SweetAlert from "react-bootstrap-sweetalert";

import { createPromo, listProducts } from "../../../utils/services/payment";

import ButtonSpinner from "../../../components/Buttons/ButtonSpinner";

function NewPromo(props) {
  const { isOpen, toggle, list } = props;
  const [isLoading, setIsloading] = useState(false);

  const [modalBackdrop, setModalBackdrop] = useState(false);

  const [successDlg, setSuccessDlg] = useState(false);

  const [valueType, setValueType] = useState("P");
  const [quantityAvailable, setQuantityAvailable] = useState("");
  const [value, setValue] = useState("");
  const [code, setCode] = useState("");
  const [date, setDate] = useState();
  const [time, setTime] = useState("");

  const [valueError, setValueError] = useState("");
  const [quantityAvailableError, setQuantityAvailableError] = useState("");
  const [codeError, setCodeError] = useState("");
  const [dateError, setDateError] = useState("");
  const [timeError, setTimeError] = useState("");
  const [apiError, setApiError] = useState([]);

  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);

  const productOptions = products.map((product) => ({
    label: product.description,
    value: product.id,
  }));

  const isEmpty = (value) => {
    return value === undefined || value === null || value.trim() === "";
  };

  const emptyFields = () => {
    const isEmptyCode = isEmpty(code);
    setCodeError(isEmptyCode ? "Código é obrigatório" : "");

    const isEmptyQuantityAvailable = isEmpty(quantityAvailable);
    setQuantityAvailableError(
      isEmptyQuantityAvailable ? "Quantidade é obrigatório" : ""
    );

    const isEmptyValue = isEmpty(value);
    setValueError(isEmptyValue ? "Valor é obrigatório" : "");

    const isEmptyDate = isEmpty(date);
    setDateError(isEmptyDate ? "Data é obrigatório" : "");

    const isEmptyTime = isEmpty(time);
    setTimeError(isEmptyTime ? "Horário é obrigatório" : "");

    const invalid =
      isEmptyCode ||
      isEmptyQuantityAvailable ||
      isEmptyValue ||
      isEmptyDate ||
      isEmptyTime;

    return invalid;
  };

  const handleListProducts = async () => {
    await listProducts({
      jwt: props.state.global_user_data.data.token,
      eventId: props.idEvent,
      offset: 1,
      limit: 100,
      search: "",
    })
      .then((response) => {
        console.log(response);
        const data = response.products.map((product) => {
          return {
            id: product.id,
            description: product.description1,
          };
        });
        setProducts(data);
        // setSelectedPromos(ticket.promos);
      })
      .catch((error) => {
        setProducts([]);
        // setSelectedPromos([]);
        console.log(error);
      });
  };

  const handleSelectProducts = (selectedOptions) => {
    if (!selectedOptions) {
      setSelectedProducts([]);
    } else {
      const selected = selectedOptions.map((option) => option.value);
      setSelectedProducts(selected);
    }
    console.log(selectedOptions);
  };

  const handleMonetaryValue = (newValue) => {
    newValue = newValue.replace(/\D/g, "");
    newValue = newValue.replace(/(\d+)(\d{2})/, "$1,$2");
    setValue(newValue);
  };

  const handlePercentualValue = (newValue) => {
    newValue = newValue.replace(/\D/g, "");
    const number = parseInt(newValue.replace(/(\d+)(\d{2})/, "$1.$2"));
    const isMore100 = number > 100;
    setValue(isMore100 ? "" : newValue.replace(/(\d+)(\d{2})/, "$1,$2"));
  };

  const handleValue = {
    M: handleMonetaryValue,
    P: handlePercentualValue,
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (emptyFields()) return;

    const currentQuantity = parseInt(quantityAvailable, 10);
    const isInvalidQuantity = currentQuantity < 1;
    setQuantityAvailableError(isInvalidQuantity ? "Quantidade inválida" : "");

    const currentValue = parseFloat(value.replace(",", "."));
    const isInvalidValue = currentValue <= 0;
    setValueError(isInvalidValue ? "Valor inválido" : "");

    const currentDate = new Date();

    const [yearStr, monthStr, dayStr] = date.split("-");
    const year = parseInt(yearStr, 10);
    const month = parseInt(monthStr, 10);
    const day = parseInt(dayStr, 10);

    const [hoursStr, minutesStr] = time.split(":");
    const hours = parseInt(hoursStr, 10);
    const minutes = parseInt(minutesStr, 10);

    const expireAt = new Date(year, month - 1, day, hours, minutes);

    const isPreviousDate = currentDate.getTime() > expireAt.getTime();

    setDateError(isPreviousDate ? "Data inválida" : "");

    const isInvalid = isInvalidQuantity || isInvalidValue || isPreviousDate;

    if (isInvalid) return;

    const dto = {
      eventId: props.idEvent,
      code: code,
      quantityAvailable: currentQuantity,
      expireAt: expireAt,
      value: currentValue,
      valueType: valueType,
      products: selectedProducts,
    };

    setIsloading(true);
    await createPromo({
      jwt: props.state.global_user_data.data.token,
      dto,
    })
      .then((res) => {
        console.log(res);
        if (res.success) {
          list();
          setSuccessDlg(true);
        } else {
          setApiError(res.errors);
        }
        setIsloading(false);
      })
      .catch((error) => {
        setIsloading(false);
        console.error(error);
      });
  };

  useEffect(() => {
    handleListProducts();
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => {
        setModalBackdrop(!modalBackdrop);
      }}
      centered={true}
      style={{ width: "25rem", maxHeight: "90vh", height: "50rem" }}
    >
      {successDlg ? (
        <SweetAlert
          success
          title={"Criado"}
          onConfirm={() => {
            setSuccessDlg(false);
            toggle();
          }}
        >
          {"Cupom criado com sucesso!"}
        </SweetAlert>
      ) : null}

      <ModalHeader toggle={toggle}>Adicionar Cupom</ModalHeader>
      <ModalBody style={{ maxHeight: "100vh" }}>
        <form onSubmit={handleSubmit}>
          <Row>
            <Col lg={6}>
              <div>
                <label className="form-label">Código</label>
                <input
                  className="form-control"
                  type="text"
                  value={code}
                  style={{ textTransform: "uppercase" }}
                  onChange={(e) => setCode(e.target.value.toUpperCase())}
                />
                <p style={{ color: "red" }}>{codeError}</p>
              </div>
            </Col>
            <Col lg={6}>
              <div>
                <label className="form-label">Quantidade</label>
                <input
                  className="form-control"
                  type="text"
                  value={quantityAvailable}
                  style={{ textAlign: "right" }}
                  onChange={(e) => {
                    let newValue = e.target.value;
                    newValue = newValue.replace(/\D/g, "");
                    setQuantityAvailable(newValue);
                  }}
                />
                <p style={{ color: "red" }}>{quantityAvailableError}</p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={6}>
              <label className="form-label">Tipo de desconto</label>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  justifyContent: "space-around",
                }}
              >
                <span>
                  %{" "}
                  <input
                    type="radio"
                    value={"P"}
                    checked={valueType === "P"}
                    onChange={(e) => setValueType(e.target.value)}
                  />
                </span>
                <span>
                  R${" "}
                  <input
                    type="radio"
                    value={"M"}
                    checked={valueType === "M"}
                    onChange={(e) => setValueType(e.target.value)}
                  />
                </span>
              </div>
            </Col>
            <Col lg={6}>
              <div>
                <label className="form-label">Valor</label>
                <input
                  className="form-control"
                  type="text"
                  style={{
                    textAlign: "right",
                  }}
                  value={value}
                  onChange={(e) => handleValue[valueType](e.target.value)}
                />
                <p style={{ color: "red" }}>{valueError}</p>
              </div>
            </Col>
          </Row>

          <Row style={{ paddingBottom: "0.5rem" }}>
            <Col lg={12}>Validade</Col>
          </Row>
          <Row>
            <Col lg={6}>
              <div>
                <input
                  className="form-control"
                  type="date"
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                />
                <p style={{ color: "red" }}>{dateError}</p>
              </div>
            </Col>
            <Col lg={6}>
              <div>
                <input
                  className="form-control"
                  type="time"
                  value={time}
                  onChange={(e) => setTime(e.target.value)}
                />
                <p style={{ color: "red" }}>{timeError}</p>
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg={12}>
              <div>
                <label className="form-label">Ingressos</label>
                <Select
                  value={selectedProducts.map((product) => ({
                    label: products.find((p) => p.id === product).description,
                    value: product,
                  }))}
                  placeholder={"Selecione"}
                  isMulti={true}
                  onChange={handleSelectProducts}
                  options={productOptions}
                  classNamePrefix="select2-selection"
                />
              </div>
            </Col>
          </Row>

          <Row>
            <div style={{ marginTop: "1rem" }}>
              {apiError.map((error) => (
                <p style={{ textAlign: "center", color: "red" }}>{error}</p>
              ))}
            </div>
          </Row>
          <Row
            style={{
              margin: "auto",
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "1rem",
            }}
          >
            <Button
              color="danger"
              className="btn btn-danger waves-effect waves-light"
              style={{ width: 80, marginRight: "1rem" }}
              onClick={() => toggle(!isOpen)}
            >
              Fechar
            </Button>
            <ButtonSpinner isLoading={isLoading} title={"Salvar"} />
          </Row>
        </form>
      </ModalBody>
    </Modal>
  );
}

const mapStateToProps = function (state) {
  return { state: state };
};

export default connect(mapStateToProps)(NewPromo);
