import React, { useCallback, useEffect, useState } from "react";
import {
  Table,
  Card,
  CardBody,
  CardTitle,
  Button,
  Collapse,
  Row,
} from "reactstrap";

import SectionComponent from "../../Apps/Personalization/SectionComponent";
import Pagination from "../../../components/Paginationv3";
import { getDownloadCertificate } from "../../../utils/services/certificate";
import { useSelector } from "react-redux";
import WaitExport from "../../../components/WaitExport";
import { createXlsx } from "./createXlsx";
import { useLocation } from "react-router-dom";
import useDebounce from "../../../utils/hooks/useDebounce";
import SearchFilters from "../../../components/SearchFilters";

export default function ReportCertificate(props) {
  const location = useLocation();

  const { token: jwt } = useSelector((state) => state.global_user_data.data);

  //search and paginations
  const [search, setSearch] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  // collapse
  const [isOpen, setIsOpen] = useState(false);

  const [certificates, setCertificates] = useState([]);

  const [exportInProgress, setExportInProgress] = useState(false);
  const [finishExport, setFinishExport] = useState(false);
  const [collapseIsOpen, setCollapseIsOpen] = useState(false);
  const [activeFilter, setActiveFilter] = useState("name");
  const debouncedSearch = useDebounce(search, 300);

  const columnsFilter = [
    { name: "Participante", value: "name", type: "text" },
    { name: "Email", value: "email", type: "text" },
    { name: "Certificado", value: "certificatename", type: "text" },
    { name: "Data", value: "date", type: "date" },
  ];

  function setActiveFilterHandle(columnActive) {
    setActiveFilter(columnActive);
    setSearch("");
  }
  const reverseDate = () => {
    const reverse = debouncedSearch
      .split("/")
      .reverse()
      .join("/")
      .replace(/\//g, "-");
    return reverse;
  };
  function treatmentSearch() {
    if (
      columnsFilter.filter((item) => item.value === activeFilter)[0].type ===
      "date"
    ) {
      return reverseDate();
    }
    return debouncedSearch;
  }
  const formatDate = (date, time) => {
    const formatedDate = date.split("T")[0].split("-").reverse().join("/");
    return `${formatedDate}  ${time}`;
  };

  const getDownloads = useCallback(async () => {
    const response = await getDownloadCertificate({
      jwt,
      eventId: location.state.idEvent,
      offset: currentPage,
      limit: itemsPerPage,
      search: treatmentSearch(),
      columnSearch: activeFilter,
    });

    setCertificates(response.data.data.certificates || []);
    setTotalItems(response.data.data.total);
  }, [jwt, location.state.idEvent, currentPage, itemsPerPage, debouncedSearch]);

  useEffect(() => {
    getDownloads();
  }, [getDownloads]);

  return (
    <div>
      <WaitExport
        isOpen={exportInProgress}
        name="Relatório de Participantes"
        preposicao="do"
        finishExport={finishExport}
        setFinishExport={setFinishExport}
        setExportInProgress={setExportInProgress}
      />
      <Card>
        <CardBody>
          <CardTitle>Relatório por Participante</CardTitle>
          <div>
            <Row
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: 10,
                width: "50px",
              }}
            >
              <SectionComponent
                sectionName="Filtros"
                setIsOpen={setCollapseIsOpen}
                isOpen={collapseIsOpen}
              />
            </Row>
            <Collapse isOpen={collapseIsOpen}>
              <SearchFilters
                columns={columnsFilter}
                activeFilter={activeFilter}
                setActiveFilterHandle={setActiveFilterHandle}
              />
            </Collapse>
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              marginBottom: 10,
            }}
          >
            <form
              className="app-search d-none d-lg-block events-input-search"
              onSubmit={(e) => e.preventDefault()}
            >
              <div className="position-relative">
                <input
                  type={activeFilter === "date" ? "date" : "text"}
                  value={search}
                  className="form-control"
                  placeholder="Pesquisar..."
                  onChange={(e) => setSearch(e.target.value)}
                />
                <span className="bx bx-search-alt" />
              </div>
            </form>
            <div className="mobile-btn-section" style={{ width: "100%" }}>
              <Row className="mobile-btns-top">
                <form
                  className="app-search"
                  style={{ padding: "0px" }}
                  onSubmit={(e) => e.preventDefault()}
                >
                  <div className="position-relative">
                    <input
                      type="text"
                      value={search}
                      className="form-control"
                      placeholder="Pesquisar..."
                      onChange={(e) => setSearch(e.target.value)}
                    />
                    <span className="bx bx-search-alt" />
                  </div>
                </form>
              </Row>
              <SectionComponent
                sectionName="Opções"
                setIsOpen={setIsOpen}
                isOpen={isOpen}
              />
              <Collapse isOpen={isOpen}>
                {certificates.length > 0 && (
                  <Row className="mobile-btns-top" style={{ marginTop: 10 }}>
                    <div
                      onClick={() =>
                        createXlsx({
                          jwt,
                          eventId: location.state.idEvent,
                          limit: 500,
                          search: search,
                          totalItems,
                          setFinishExport,
                        }) > setExportInProgress(true)
                      }
                    >
                      Exportar Relatório
                    </div>
                  </Row>
                )}
              </Collapse>
            </div>
            <div className="header-btns-container">
              {certificates.length > 0 && (
                <Button
                  color="success"
                  className="btn-rounded waves-effect waves-light events-button-add"
                  onClick={() =>
                    createXlsx({
                      jwt,
                      eventId: location.state.idEvent,
                      limit: 500,
                      search: search,
                      totalItems,
                      setFinishExport,
                    }) > setExportInProgress(true)
                  }
                >
                  Exportar Relatório
                </Button>
              )}
            </div>
          </div>
          <div className="table-responsive">
            <Table className="table mb-0">
              <thead className="table-light">
                <tr>
                  <th>Nome do Participante</th>
                  <th>Email do Participante</th>
                  <th>Nome do Certificado</th>
                  <th>Data e Hora do Download</th>
                </tr>
              </thead>
              <tbody>
                {certificates.map((e, i) => (
                  <tr>
                    <td>{e.login}</td>
                    <td>{e.email}</td>
                    <td>{e.certificatename}</td>
                    <td>{formatDate(e.datedownload, e.timedownload)}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </CardBody>
      </Card>
      <div className="bottom-navigation" style={{ justifyContent: "end" }}>
        {/* 
        Fornecer os 4 parametros obrigatórios;
        ** data - a array contendo as informações da tabela
        ** search - o state do termo de pesquisa
        ** currentIndex - posição atual do index
        ** setCurrentIndex - função para atualizar a posição atual do index
        */}
        <Pagination
          data={certificates}
          search={search}
          currentIndex={currentPage}
          setCurrentIndex={setCurrentPage}
          countData={totalItems}
          maxItemPerPage={itemsPerPage}
          setMaxItemPerPage={setItemsPerPage}
          maxPageButtonsToShow={3}
        />
      </div>
    </div>
  );
}
