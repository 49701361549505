import React, { useEffect } from 'react';

import { Label } from 'reactstrap';

export default function LabelDisplay({ attributes, rowId, colId }) {
  useEffect(() => {
    const html = attributes.label;
    const div = document.getElementsByClassName(`label-${rowId}-${colId}`);
  
    for (const element of div) {
      element.innerHTML = html;
    }
  }, [attributes, rowId, colId])

  return (
    <div>
      <div className="custom-input-container">
        <div>
          <div className="displayed-config-container">
            <div className="displayed-label">
              <Label htmlFor="formText" className={`label cellphone-label-${rowId}-${colId}`} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
