import React, { useCallback, useEffect, useState } from "react";
import { MetaTags } from "react-meta-tags";
import { useSelector } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Collapse,
  Row,
  Col,
  Table,
} from "reactstrap";

import SweetAlert from "react-bootstrap-sweetalert";
import { useLocation } from "react-router-dom";
import Pagination from "../../../components/Paginationv3";
import useDebounce from "../../../utils/hooks/useDebounce";
import SectionComponent from "../../Apps/Personalization/SectionComponent";
import EditImg from "./ModalEditFile";
import NewImg from "./ModalNewFile";
import AdjustmentScreen from "./adjustmentScreen";
import DeleteButton from "../../../components/Buttons/DeleteButton";

const data = [
  {
    name: "teste",
    description: "descrição teste",
    date: "10/08/2022",
  },
];

function InteegraDrive() {
  const location = useLocation();

  //paginação
  const [currentIndex, setCurrentIndex] = useState(1);
  const [countData, setCountData] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  //search
  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounce(search, 300);

  // New Image Modal
  const [isNewImgOpen, setIsNewImgOpen] = useState(false);

  // Edit Image Modal
  const [isEditOpen, setIsEditOpen] = useState(false);

  // Delete Image Modal
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [successDelete, setSuccessDelete] = useState(false);

  // adjustement screen
  const [isAdjustmentScreen, setIsAdjustmentScreen] = useState(false);

  // collapse
  const [isCollapse, setIsCollapse] = useState(false);

  async function clipBoard(url) {
    await navigator.clipboard.writeText(url);
    alert("O link foi copiado para a área de transferência");
  }

  return (
    <div>
      {isDeleteOpen && (
        <SweetAlert
          warning
          showCancel
          confirmBtnText="Sim"
          cancelBtnText="Não"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          title={`Você tem certeza que deseja excluir o arquivo?`}
          onConfirm={() => {
            setIsDeleteOpen(false);
            setSuccessDelete(true);
          }}
          onCancel={() => {
            setIsDeleteOpen(false);
          }}
        >
          Você não poderá reverter essa ação.
        </SweetAlert>
      )}
      {successDelete && (
        <SweetAlert
          success
          title="Deletado"
          onConfirm={() => {
            setSuccessDelete(false);
          }}
        >
          {`Arquivo deletado com sucesso`}
        </SweetAlert>
      )}
      {isEditOpen && (
        <EditImg isOpen={isEditOpen} toggle={() => setIsEditOpen(false)} />
      )}
      {isNewImgOpen && (
        <NewImg toggle={() => setIsNewImgOpen(false)} isOpen={isNewImgOpen} />
      )}
      {isAdjustmentScreen && (
        <AdjustmentScreen
          toggle={() => setIsAdjustmentScreen(false)}
          isOpen={isAdjustmentScreen}
        />
      )}
      <Card>
        <CardBody>
          <CardTitle>Documentos</CardTitle>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <form
              className="app-search d-none d-lg-block events-input-search"
              onSubmit={(e) => e.preventDefault()}
            >
              <div className="position-relative">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Pesquisar..."
                  onChange={(e) => setSearch(e.target.value)}
                />
                <span className="bx bx-search-alt" />
              </div>
            </form>
            <div className="mobile-btn-section" style={{ width: "100%" }}>
              <Row className="mobile-btns-top">
                <form
                  className="app-search"
                  style={{ padding: "0px" }}
                  onSubmit={(e) => e.preventDefault()}
                >
                  <div className="position-relative">
                    <input
                      type="text"
                      value={search}
                      className="form-control"
                      placeholder="Pesquisar..."
                      onChange={(e) => setSearch(e.target.value)}
                    />
                    <span className="bx bx-search-alt" />
                  </div>
                </form>
              </Row>
              <SectionComponent
                sectionName="Opções"
                setIsOpen={setIsCollapse}
                isOpen={isCollapse}
              />
              <Collapse isOpen={isCollapse}>
                <Row className="mobile-btns-top">
                  <div onClick={() => setIsAdjustmentScreen(true)}>
                    Configurações da Tela
                  </div>
                  <div onClick={() => setIsNewImgOpen(true)}>
                    Adicionar Documento
                  </div>
                </Row>
              </Collapse>
            </div>
            <div className="header-btns-container">
              <Button
                color="success"
                className="btn-rounded waves-effect waves-light events-button-add"
                onClick={() => setIsAdjustmentScreen(true)}
              >
                Criar Telas
              </Button>
              <Button
                color="success"
                className="btn-rounded waves-effect waves-light events-button-add"
                onClick={() => setIsNewImgOpen(true)}
              >
                Adicionar Documento
              </Button>
            </div>
          </div>
          {/* <Row style={{ margin: "10px 0" }}>
            <Col md={11} style={{ padding: 0 }}>
              <Row style={{ margin: 0 }}>Link Embedado no Aplicativo:</Row>
              <Row style={{ margin: 0 }}>
                {
                  "https://downloadarquivos.sistemainteegra.com.br/?idEvent={EVENTOID}&idPart={PAXID}&native=true"
                }
              </Row>
            </Col>
            <Col md={1} style={{ display: "flex", alignItems: "center" }}>
              <i
                className="mdi mdi-content-copy"
                style={{
                  color: "grey",
                  fontSize: 17,
                  // margin: "0 3px",
                  cursor: "pointer",
                }}
                title="Copiar"
                onClick={() =>
                  clipBoard(
                    "https://downloadarquivos.sistemainteegra.com.br/?idEvent={EVENTOID}&idPart={PAXID}&native=true"
                  )
                }
              />
            </Col>
          </Row> */}
          <div className="table-responsive">
            <Table className="table mb-0">
              <thead className="table-light">
                <tr>
                  <th>Nome do Documento</th>
                  <th>Descrição</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                {data.length > 0 &&
                  data.map((img, i) => (
                    <tr key={i}>
                      <td>{img.name}</td>
                      <td>{img.description}</td>
                      <td>
                        <i
                          className="mdi mdi-content-copy"
                          style={{
                            color: "grey",
                            fontSize: 17,
                            margin: "0 3px",
                            cursor: "pointer",
                          }}
                          title="Copiar"
                          onClick={() => clipBoard(img.name)}
                        />
                        <a
                          href={img.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          title="Abrir Arquivo"
                        >
                          <i
                            className="mdi mdi-eye"
                            style={{
                              color: "blue",
                              fontSize: 17,
                              margin: "0 3px",
                              cursor: "pointer",
                            }}
                          />
                        </a>
                        <i
                          className="mdi mdi-pencil"
                          title="Editar"
                          style={{
                            margin: "0 3px",
                            color: "green",
                            fontSize: 17,
                            cursor: "pointer",
                          }}
                          onClick={() => setIsEditOpen(true)}
                        />
                        {/* <i
                          className="mdi mdi-trash-can"
                          title="Excluir"
                          style={{
                            color: "red",
                            fontSize: 17,
                            cursor: "pointer",
                          }}
                          onClick={() => setIsDeleteOpen(true)}
                        /> */}
                        <DeleteButton
                          onClick={() => setIsDeleteOpen(true)}
                          color="red"
                          fontSize={17}
                        />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
        </CardBody>
      </Card>
      <div className="bottom-navigation" style={{ justifyContent: "end" }}>
        {/* 
        Fornecer os 4 parametros obrigatórios;
        ** data - a array contendo as informações da tabela
        ** search - o state do termo de pesquisa
        ** currentIndex - posição atual do index
        ** setCurrentIndex - função para atualizar a posição atual do index
        */}
        <Pagination
          data={data}
          // search={search}
          currentIndex={currentIndex}
          setCurrentIndex={setCurrentIndex}
          countData={countData}
          maxItemPerPage={itemsPerPage}
          setMaxItemPerPage={setItemsPerPage}
          maxPageButtonsToShow={3}
        />
      </div>
    </div>
  );
}

export default InteegraDrive;
