import React, { useEffect, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { useSelector } from "react-redux";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  Button,
  Collapse,
  UncontrolledTooltip,
} from "reactstrap";
import { createSorteio } from "../../../utils/services/sorteios";
import { getListSessions } from "../../../utils/services/scheduleCotas";
import { useLocation } from "react-router-dom";
import Select from "react-select";
import ButtonSpinner from "../../../components/Buttons/ButtonSpinner";

export default function ModalNewSorteio(props) {
  const { isOpen, toggle, sessionsData, refetch } = props;
  const [isLoading, setIsloading] = useState(false);
  const location = useLocation();
  const { token: jwt } = useSelector((state) => state.global_user_data.data);
  const { id: eventId } = location.state.event;
  const [modalBackdrop, setModalBackdrop] = useState(false);
  const [sessionsSource, setSessionsSource] = useState([]);
  const [sessions, setSessions] = useState([]);
  const [section, setSection] = useState();
  //error
  const [sectionError, setSectionError] = useState(false);
  const [cotasError, setCotasError] = useState(false);
  const [startError, setStartError] = useState(false);
  const [endError, setEndError] = useState(false);
  // cotas
  const [cotasValue, setCotasValues] = useState(0);
  // sucesso
  const [success, setSuccess] = useState(false);
  // carregamento
  const [loading, setLoading] = useState(false);

  const [generalSessions, setGeneralSession] = useState([]);

  async function listSessions() {
    if (!eventId || !jwt) {
      return;
    }

    const res = await getListSessions(eventId, jwt);

    if (res.data.cotasManuais.length > 0) {
      setSessionsSource(res.data.cotasManuais);
      setGeneralSession(res.data.cotasManuais);
      const formattedData = res.data.cotasManuais
        .filter(
          (item) =>
            /* !sessionsData.some(
              (session) => session.sessionId === item.agendaid
            ) && */
            /* parseInt(item.availablemanuais) > 0 || */
            parseInt(item.availablesorteio) > 0 /* || */
          /* parseInt(item.availablefechadas) > 0 */
        )
        .map((e) => ({
          label: `${e.title} - ${formatDate(e.startdate)} ${e.starthour}`,
          value: e.agendaid,
        }));
      setSessions(formattedData);
    }
  }

  const handleChange = (event) => {
    let valor = event.target.value;

    let limit = generalSessions.find(
      (e) => e.agendaid == section?.value
    )?.availablesorteio;

    if (valor > parseInt(limit)) {
      valor = parseInt(limit);
    }
    setCotasValues(valor);
  };

  function toggleBackdrop() {
    setModalBackdrop(!modalBackdrop);
  }

  async function addSorteio(e) {
    setLoading(true);

    if (!section) {
      setSectionError("O campo Sessão é obrigatório.");
      setLoading(false);
      return;
    } else setSectionError("");

    if (!e.target[1].value) {
      setCotasError("O campo Quantidade de Cotas é obrigatório.");
      setLoading(false);
      return;
    } else setCotasError("");

    if (cotasValue < 1) {
      setCotasError("É preciso informar a quantidade de cotas.");
      setLoading(false);
      return;
    } else setCotasError("");

    if (!e.target[2].value) {
      setStartError("O campo Data de Início é obrigatório.");
      setLoading(false);
      return;
    } else setStartError("");

    if (!e.target[3].value) {
      setEndError("O campo Data de Encerramento é obrigatório.");
      setLoading(false);
      return;
    } else setEndError("");

    if (new Date(e.target[2].value) >= new Date(e.target[3].value)) {
      setStartError(
        "A data início tem que ser menor que a data de encerramento."
      );
      setLoading(false);
      return;
    } else setEndError("");

    if (new Date(e.target[2].value) >= new Date(e.target[3].value)) {
      setStartError(
        "A data início tem que ser menor que a data de encerramento."
      );
      setLoading(false);
      return;
    } else setEndError("");

    if (new Date(e.target[3].value) < new Date()) {
      setStartError(
        "A data de encerramento tem que ser maior que a data atual."
      );
      setLoading(false);
      return;
    } else setEndError("");

    const data = {
      eventId: eventId,
      sessionId: section?.value,
      quotasAmount: parseInt(e.target[1].value),
      startRegistration: new Date(e.target[2].value),
      endRegistration: new Date(e.target[3].value),
      jwt: jwt,
    };
    setIsloading(true);
    await createSorteio(data);
    setIsloading(false);
    refetch();
    toggle();

    setLoading(false);
  }

  useEffect(() => {
    listSessions();
  }, []);

  return (
    <Modal isOpen={isOpen} toggle={() => toggleBackdrop()} autoFocus centered>
      {success && (
        <SweetAlert
          success
          title="Edição de Certificado"
          onConfirm={() => {
            setSuccess(false);
            toggle();
          }}
        >
          Sorteio criado com sucesso!
        </SweetAlert>
      )}
      <ModalHeader toggle={toggle}>Adicionar Sorteio</ModalHeader>
      <ModalBody style={{ padding: 20 }}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            if (!loading) {
              addSorteio(e);
            }
          }}
        >
          <Row style={{ marginBottom: 10 }}>
            <Col md={12}>
              <label className="form-label">Sessão*</label>
              <Select
                value={section}
                placeholder={"Selecione"}
                onChange={(e) => {
                  setSection(e);
                }}
                options={[
                  {
                    options: [...sessions],
                  },
                ]}
                classNamePrefix="select2-selection"
              />
              {sectionError && <a style={{ color: "red" }}>{sectionError}</a>}
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <label className="form-label" style={{ padding: 0 }}>
                Quantidade de Cotas*
              </label>
              <input
                className="form-control"
                type="number"
                name="cotas"
                id="cotas"
                min="0"
                value={cotasValue}
                onChange={handleChange}
              />
              {cotasError && <a style={{ color: "red" }}>{cotasError}</a>}
            </Col>
          </Row>
          <Row style={{ padding: "12px 11px" }}>
            <Col md={6} style={{ paddingLeft: 0 }}>
              <label className="form-label">Data de Início*</label>
              <input
                className="form-control"
                type="datetime-local"
                id="example-datetime-local-input"
              />
              {startError && <a style={{ color: "red" }}>{startError}</a>}
            </Col>

            <Col md={6} style={{ paddingLeft: 0 }}>
              <label className="form-label">Data de Encerramento*</label>
              <input
                className="form-control"
                type="datetime-local"
                id="example-datetime-local-input"
              />
              {endError && <a style={{ color: "red" }}>{endError}</a>}
            </Col>
          </Row>

          <Row
            style={{
              width: "100%",
              marginTop: 15,
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              color="danger"
              style={{ width: 80, marginRight: 10 }}
              onClick={() => toggle()}
            >
              Fechar
            </Button>

            <ButtonSpinner isLoading={isLoading} title={"Salvar"} />
          </Row>
        </form>
      </ModalBody>
    </Modal>
  );
}

const formatDate = (date) => {
  return date.split("T")[0].split("-").reverse().join("/");
};
