import React, { useState } from 'react';
import { Button, Input, Label, Modal, ModalBody, ModalHeader, UncontrolledTooltip } from 'reactstrap';

export default function RowsAndCols({ isOpen, setEditCols, toggle }) {
  const [cols, setCols] = useState(1);

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      autoFocus={true}
      centered={true}
    >
    <ModalHeader toggle={toggle}>
      Ajuste as colunas
    </ModalHeader>
    <ModalBody>
      <div>
        <div style={{display: 'flex'}}>
          <Label htmlFor="adjust-cols" className="form-label">
            Número de colunas
          </Label>
          <div>
            <div className="show-tips" id="column-tip">
              <span>?</span>
            </div>
            <UncontrolledTooltip style={{ backgroundColor: '#6f42c1' }} placement="right" target="column-tip">
              Máximo de 3 colunas
            </UncontrolledTooltip>
          </div>
        </div>
        <Input
          id="adjust-cols"
          type="number"
          min="1"
          max="3"
          value={cols}
          onChange={({ target }) => setCols(target.value)}
        />
      </div>
      <div className="save-btn-form-container">
        <Button
          color="success"
          className="btn btn-success waves-effect waves-light save-btn-form"
          onClick={() => setEditCols(cols) > toggle()}
          disabled={cols < 1 || cols > 3}
        >
          Salvar
        </Button>
      </div>
    </ModalBody>
    </Modal>
  )
}
