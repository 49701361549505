import React, { useEffect, useState } from 'react';
import Select from 'react-select';

import { Label, UncontrolledTooltip } from 'reactstrap';

export default function SelectDisplay({ attributes, rowId, colId, setValue }) {
  const [selected, setSelected] = useState('');

  const handleChange = (e) => {
    setSelected(attributes.isMulti ? e.map(x => x.value) : e.value);
  }

  useEffect(() => {
    const html = attributes.label;
    const div = document.getElementsByClassName(`select-label-${rowId}-${colId}`);
  
    for (const element of div) {
      element.innerHTML = html;
    }
  }, [attributes, rowId, colId])

  useEffect(() => {
    setValue((oldValue) => {
      const newValue = [...oldValue];
      newValue[rowId].cols[colId].attributes.value = selected?.length === 0 ? '' : selected;
      if (attributes.required && !selected) {
        newValue[rowId].cols[colId].attributes.errorMessage = `O campo ${attributes.inputName} é obrigatório`;
      } else {
        newValue[rowId].cols[colId].attributes.errorMessage = ''
      }

      return newValue;
    });
  }, [selected, setValue, rowId, colId, attributes.required, attributes.inputName]);

  return (
    <div className="custom-input-container">
      <div>
        <div className="displayed-config-container">
          <div className="displayed-label">
            <Label htmlFor="formFile" className={`form-label select-label-${rowId}-${colId}`} />
            {attributes.hasTip && (
              <div>
                <div className="show-tips" id={`select-tip-${rowId}-${colId}`}>
                  <span>?</span>
                </div>
                <UncontrolledTooltip style={{ backgroundColor: '#6f42c1' }} placement="right" target={`select-tip-${rowId}-${colId}`}>
                  {attributes.tipText}
                </UncontrolledTooltip>
              </div>
            )}
          </div>
        </div>
      </div>
      <Select
        placeholder={attributes.placeholder}
        isMulti={attributes.isMulti}
        onChange={handleChange}
        options={[
          {
            options: [
              ...attributes.selectValues.map((e) => {
                return { label: e.name, value: e.name };
              }),
            ],
          },
        ]}
        id="formFile"
      />
    </div>
  )
}
