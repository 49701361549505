import { urlBase } from "./config";
import axiosInstance from "../services/config/axiosinstance";

async function getAllVehicles(
  eventId,
  offset,
  limit,
  search,
  columnSearch = ""
) {
  try {
    const config = {
      method: "get",
      url: `${urlBase}/vehicle/${eventId}/event/?offset=${offset}&limit=${limit}&search=${search}&columnSearch=${columnSearch}`,
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await axiosInstance(config);
    return response;
  } catch (error) {
    return error.response;
  }
}

async function createVehicle(data) {
  var config = {
    method: "post",
    url: `${urlBase}/vehicle`,
    headers: {
      "Content-Type": "application/json",
    },
    data,
  };

  return axiosInstance(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

async function updateVehicle(data, id) {
  var config = {
    method: "put",
    url: `${urlBase}/vehicle/${id}`,
    headers: {
      "Content-Type": "application/json",
    },
    data,
  };

  return axiosInstance(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

async function deleteVehicle(id) {
  var config = {
    method: "delete",
    url: `${urlBase}/vehicle/${id}`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  return axiosInstance(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}
async function checkVehiclesAvailable({
  eventId,
  email,
  vehicle,
  offset,
  limit,
}) {
  const config = {
    method: "get",
    url: `${urlBase}/vehicle/${eventId}/event/${email}/login/${vehicle}/vehicle?offset=${offset}&limit=${limit}`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  return axiosInstance(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response.data;
    });
}

async function getAllVehiclesToDashboard(
  eventId,
  offset,
  limit,
  search,
  columnSearch = ""
) {
  try {
    const config = {
      method: "get",
      url: `${urlBase}/vehicle/dashboard/${eventId}/event/?offset=${offset}&limit=${limit}&search=${search}&columnSearch=${columnSearch}`,
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await axiosInstance(config);
    return response;
  } catch (error) {
    return error.response;
  }
}

async function createVehicleStatus(data) {
  var config = {
    method: "post",
    url: `${urlBase}/vehiclestatus`,
    headers: {
      "Content-Type": "application/json",
    },
    data,
  };

  return axiosInstance(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

async function getAllVehiclesStatus(
  eventId,
  vehicleId,
  offset,
  limit,
  search,
  columnSearch = ""
) {
  try {
    const config = {
      method: "get",
      url: `${urlBase}/vehiclestatus/${eventId}/event/${vehicleId}/vehicle/?offset=${offset}&limit=${limit}&search=${search}&columnSearch=${columnSearch}`,
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await axiosInstance(config);
    return response;
  } catch (error) {
    return error.response;
  }
}

async function getTotalVehicleByStatus(eventId) {
  try {
    const config = {
      method: "get",
      url: `${urlBase}/vehiclestatus/totalstatus/${eventId}/event`,
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await axiosInstance(config);
    return response;
  } catch (error) {
    return error.response;
  }
}

async function getVehicleByStatus({
  eventId,
  status,
  offset,
  limit,
  search,
  columnSearch,
}) {
  try {
    const config = {
      method: "get",
      url: `${urlBase}/vehiclestatus/details/${eventId}/event/${status}/status?offset=${offset}&limit=${limit}&search=${search}&columnSearch=${columnSearch}`,
      headers: {
        "Content-Type": "application/json",
      },
    };

    const response = await axiosInstance(config);
    return response;
  } catch (error) {
    return error.response;
  }
}

async function getParticipantsByStatus({
  eventId,
  status,
  offset,
  limit,
  search,
  columnSearch,
}) {
  try {
    const config = {
      method: "get",
      url: `${urlBase}/loginstatusvehicle/details/${eventId}/event/${status}/status?offset=${offset}&limit=${limit}&search=${search}&columnSearch=${columnSearch}`,
      headers: {
        "Content-Type": "application/json",
      },
    };

    const response = await axiosInstance(config);
    return response;
  } catch (error) {
    return error.response;
  }
}

export {
  createVehicle,
  createVehicleStatus,
  deleteVehicle,
  getAllVehicles,
  getAllVehiclesToDashboard,
  getAllVehiclesStatus,
  updateVehicle,
  checkVehiclesAvailable,
  getTotalVehicleByStatus,
  getVehicleByStatus,
  getParticipantsByStatus,
};
