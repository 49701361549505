import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import {
  Table,
  Card,
  CardBody,
  CardTitle,
  Collapse,
  Row,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Link } from "react-router-dom";
import "./style.scss";
//redux
import { connect } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";

//paginação
import Pagination from "../../components/Paginationv3";

import { listTemplates } from "../../utils/services/rsvp";
import SectionComponent from "../Apps/Personalization/SectionComponent";

import useDebounce from "../../utils/hooks/useDebounce";

import RowTemplate from "./rowTemplate";
import "moment/locale/pt-br";

function Templates(props) {
  //paginação
  const [currentIndex, setCurrentIndex] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [templates, setTemplates] = useState([]);
  const [totalTemplates, setTotalTemplates] = useState(0);
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  // modal collapse
  const [isOpen, setIsOpen] = useState(false);

  //search
  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounce(search, 300);

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const getTemplates = async () => {
    const data = await listTemplates({
      jwt: props.token,
      offset: currentIndex,
      type: "all",
      name: search,
    });
    setTemplates(data.data.data.templates);
    setTotalTemplates(data.data.data.total);
  };

  useEffect(() => {
    getTemplates();
  }, [debouncedSearch, currentIndex, search]);
  return (
    <div>
      <MetaTags>
        <title>Templates | Inteegra Plus</title>
      </MetaTags>
      {deleteConfirm && (
        <SweetAlert
          success
          showCancel={false}
          confirmBtnText="ok"
          title={`Deletado`}
          onConfirm={() => setDeleteConfirm(false)}
        >
          Template deletado.
          <br />
          <br />
          <br />
        </SweetAlert>
      )}
      <Card>
        <CardBody>
          <CardTitle>Templates</CardTitle>

          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <form className="app-search d-none d-lg-block events-input-search">
              <div className="position-relative">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Pesquisar..."
                  onChange={(e) => setSearch(e.target.value)}
                />
                <span className="bx bx-search-alt" />
              </div>
            </form>

            {/* btn mobile */}
            <div className="mobile-btn-section" style={{ width: "100%" }}>
              <Row className="mobile-btns-top">
                <form
                  className="app-search"
                  style={{ padding: "0px" }}
                  onSubmit={(e) => e.preventDefault()}
                >
                  <div className="position-relative">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Pesquisar..."
                      onChange={(e) => setSearch(e.target.value)}
                    />
                    <span className="bx bx-search-alt" />
                  </div>
                </form>
              </Row>

              <SectionComponent
                sectionName="Opções"
                setIsOpen={setIsOpen}
                isOpen={isOpen}
              />
              <Collapse isOpen={isOpen}>
                <Row className="mobile-btns-top">
                  {/* <div>Criar Template</div> */}
                  <Link
                    to="/html-editor"
                    color="success"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    Criar Template
                  </Link>
                </Row>
              </Collapse>
            </div>

            {/* btn web */}
            <div className="header-btns-container">
              <Dropdown
                isOpen={dropdownOpen}
                toggle={toggle} /* direction={direction} */
              >
                <DropdownToggle
                  caret
                  className="btn-rounded waves-effect waves-light events-button-add"
                  color="success"
                >
                  Criar Template
                </DropdownToggle>
                <DropdownMenu>
                  <Link
                    to={"/html-editor"}
                    state={{ type_template: "email", editMode: false }}
                  >
                    <DropdownItem>Template Email</DropdownItem>
                  </Link>
                  <Link
                    to={"/html-editor"}
                    state={{ type_template: "web", editMode: false }}
                  >
                    <DropdownItem>Template Website</DropdownItem>
                  </Link>
                </DropdownMenu>
              </Dropdown>

              {/* <Link to="/html-editor" color="success">
                <Button
                  className="btn-rounded waves-effect waves-light events-button-add"
                  color="success"
                >
                  Criar Template
                </Button>
              </Link> */}
            </div>
          </div>
          <div className="table-responsive">
            <Table className="table mb-0">
              <thead className="table-light">
                <tr>
                  <th>ID</th>
                  <th>Nome</th>
                  <th>Data de criação</th>
                  <th>Última Atualização</th>
                  <th>Tipo</th>
                  <th
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    Ações
                  </th>
                </tr>
              </thead>
              <tbody>
                {templates.map((e, i) => (
                  <RowTemplate
                    e={e}
                    i={i}
                    {...props}
                    getTemplates={getTemplates}
                    setDeleteConfirm={setDeleteConfirm}
                  />
                ))}
              </tbody>
            </Table>
            {/* {[1, 2, 3, 4, 5, 6, 7, 8].map((e, i) => (
              <div className="col-md-6 col-sm-12 col-lg-4" key={i}>
                <div
                  className="view_placeholder"
                  style={{
                    position: "absolute",
                    width: 327,
                    height: 327,
                  }}
                >
                  <i
                    className="fa fa-eye"
                    style={{
                      zIndex: 2,
                      fontSize: 66,
                      position: "absolute",
                      left: "40%",
                      top: "40%",
                      color: "#dcdcdc",
                    }}
                  ></i>
                  <div
                    style={{
                      background: "#00000082",
                      width: 327,
                      height: 327,
                      position: "absolute",
                      zIndex: 1,
                    }}
                  />
                </div>
                <iframe
                  id="serviceFrameSend"
                  className="frame-fill"
                  src="http://192.168.0.111:8080/teste1.html"
                  width="300"
                  height="300"
                  frameborder="0"
                  scrolling="no"
                />
              </div>
            ))} */}
          </div>
        </CardBody>
      </Card>
      <div className="bottom-navigation" style={{ justifyContent: "end" }}>
        {/* 
      Fornecer os 4 parametros obrigatórios;
      ** data - a array contendo as informações da tabela
      ** search - o state do termo de pesquisa
      ** currentIndex - posição atual do index
      ** setCurrentIndex - função para atualizar a posição atual do index
      */}
        <Pagination
          data={templates}
          search={""}
          currentIndex={currentIndex}
          setCurrentIndex={setCurrentIndex}
          countData={totalTemplates}
          maxItemPerPage={itemsPerPage}
          setMaxItemPerPage={setItemsPerPage}
          maxPageButtonsToShow={3}
        />
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  const { token } = state.global_user_data.data;
  return { token };
};

export default connect(mapStateToProps)(Templates);
