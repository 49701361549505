import React, { useCallback, useEffect, useState } from 'react';

import { Input, Label, UncontrolledTooltip } from 'reactstrap';

import { inputMask } from '../../../../utils/helpers/masks/masks';
import { validation } from '../../../../utils/helpers/masks/validations';

export default function TextDisplay({ attributes, rowId, colId, setValue }) {
  // Mask
  const [maskedValue, setMaskedValue] = useState('');

  const handleChange = (value) => {
    inputMask({ mask: attributes.mask, value, setMaskedValue });
  };

  const validLength = useCallback(() => {
    if (maskedValue.length >= +attributes.minValue && maskedValue.length <= +attributes.maxValue) {
      setValue((oldValue) => {
        const newValue = [...oldValue];
        newValue[rowId].cols[colId].attributes.value = maskedValue;
        newValue[rowId].cols[colId].attributes.errorMessage = '';
        return newValue;
      });
    } else {
      setValue((oldValue) => {
        const newValue = [...oldValue];
        newValue[rowId].cols[colId].attributes.value = maskedValue;
        newValue[rowId].cols[colId].attributes.errorMessage = `O campo deve ter entre ${attributes.minValue} e ${attributes.maxValue} caracteres.`;
        return newValue;
      });
    }
  }, [maskedValue, setValue, rowId, colId, attributes]);

  const validMaxAndMin = useCallback(() => {
    if (+maskedValue >= +attributes.minValue && +maskedValue <= +attributes.maxValue) {
      setValue((oldValue) => {
        const newValue = [...oldValue];
        newValue[rowId].cols[colId].attributes.value = maskedValue;
        newValue[rowId].cols[colId].attributes.errorMessage = '';
        return newValue;
      });
    } else {
      setValue((oldValue) => {
        const newValue = [...oldValue];
        newValue[rowId].cols[colId].attributes.value = maskedValue;
        newValue[rowId].cols[colId].attributes.errorMessage = `O valor deve ser entre ${attributes.minValue} e ${attributes.maxValue}`;
        return newValue;
      });
    }
  }, [maskedValue, setValue, rowId, colId, attributes]);

  useEffect(() => {
    const html = attributes.label;
    const div = document.getElementsByClassName(`text-label-${rowId}-${colId}`);
  
    for (const element of div) {
      element.innerHTML = html;
    }
  }, [attributes, rowId, colId]);

  useEffect(() => {
    if (attributes.hasValidation && attributes.required) {
      if (attributes.isNumeric) {
        return validMaxAndMin();
      } else {
        return validLength();
      }
    } else if(attributes.hasValidation && maskedValue.length > 0) {
      if (attributes.isNumeric) {
        return validMaxAndMin();
      } else {
        return validLength();
      }
    }

    const {isValid, message} = validation(maskedValue, attributes.mask);

    setValue((oldValue) => {
      const newValue = [...oldValue];
      newValue[rowId].cols[colId].attributes.value = maskedValue;
      if (!isValid) {
        newValue[rowId].cols[colId].attributes.errorMessage = message;
      } else if(attributes.required && !maskedValue) {
        newValue[rowId].cols[colId].attributes.errorMessage = `O campo ${attributes.label} é obrigatório`;
      } else {
        newValue[rowId].cols[colId].attributes.errorMessage = ''
      }

      return newValue;
    });
  }, [maskedValue, setValue, rowId, colId, attributes, validLength, validMaxAndMin]);

  return (
    <div>
      <div className="custom-input-container">
        <div>
          <div className="displayed-config-container">
            <div className="displayed-label">
              <Label htmlFor="formText" className={`form-label text-label-${rowId}-${colId}`} />
              {attributes.hasTip && (
                <div>
                  <div className="show-tips" id={`text-tip-${rowId}-${colId}`}>
                    <span>?</span>
                  </div>
                  <UncontrolledTooltip style={{ backgroundColor: '#6f42c1' }} placement="right" target={`text-tip-${rowId}-${colId}`}>
                    {attributes.tipText}
                  </UncontrolledTooltip>
                </div>
              )}
            </div>
          </div>
          <Input
            className="form-control"
            type="text"
            id="formText"
            placeholder={attributes.placeholder}
            value={maskedValue}
            onChange={({ target }) => handleChange(target.value)}
          />
        </div>
      </div>
    </div>
  )
}
