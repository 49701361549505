export const INITIAL_CONFIGS = 'INITIAL_CONFIGS';
export const LOGIN_PERSONALIZATION = 'LOGIN_PERSONALIZATION';
export const EVENTS_LIST_PERSONALIZATION = 'EVENTS_LIST_PERSONALIZATION';
export const GENERAL_PERSONALIZATION = 'GENERAL_PERSONALIZATION';
export const ALERTS_PESONALIZATION = 'ALERTS_PESONALIZATION';
export const PROFILE_PERSONALIZATION = 'PROFILE_PERSONALIZATION';
export const SCHEDULE_PERSONALIZATION = 'SCHEDULE_PERSONALIZATION';
export const FEED_PERSONALIZATION = 'FEED_PERSONALIZATION';
export const LOCALIZATION_PERSONALIZATION = 'LOCALIZATION_PERSONALIZATION';
export const GUEST_PERSONALIZATION = 'GUEST_PERSONALIZATION';
export const LOGISTICS_PERSONALIZATION = 'LOGISTICS_PERSONALIZATION';
export const RANKING_PERSONALIZATION = 'RANKING_PERSONALIZATION';

export const initialConfigs = (data) => ({
  type: INITIAL_CONFIGS,
  data,
});


export const loginPersonalization = (data) => ({
  type: LOGIN_PERSONALIZATION,
  data,
});

export const eventsListPersonalization = (data) => ({
  type: EVENTS_LIST_PERSONALIZATION,
  data,
});

export const generalPersonalization = (data) => ({
  type: GENERAL_PERSONALIZATION,
  data,
});

export const alertsPersonalization = (data) => ({
  type: ALERTS_PESONALIZATION,
  data,
});

export const profilePersonalization = (data) => ({
  type: PROFILE_PERSONALIZATION,
  data,
});

export const schedulePersonalization = (data) => ({
  type: SCHEDULE_PERSONALIZATION,
  data,
});

export const feedPersonalization = (data) => ({
  type: FEED_PERSONALIZATION,
  data,
});

export const localizationPersonalization = (data) => ({
  type: LOCALIZATION_PERSONALIZATION,
  data,
});

export const guestPersonalization = (data) => ({
  type: GUEST_PERSONALIZATION,
  data,
});

export const logisticsPersonalization = (data) => ({
  type: LOGISTICS_PERSONALIZATION,
  data,
});

export const rankingPersonalization = (data) => ({
  type: RANKING_PERSONALIZATION,
  data,
});