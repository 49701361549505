import React from 'react';
import Select from 'react-select';

export default function SelectFilter(props) {
  const [selectedOption, setSelectedOption] = React.useState(null);
  return (
    <div>
      <Select
        className="basic-single"
        classNamePrefix="select"
        isRtl={false}
        isSearchable={true}
        isClearable={true}
        name="color"
        placeholder={props.placeholder}
        isMulti={props.isMulti}
        value={selectedOption}
        onChange={(e) => setSelectedOption(e) > props.onChange(e)}
        options={[
          {
            options: [
              ...props.options.map((e) => {
                return { label: e.name, value: e.name };
              }),
            ],
          },
        ]}
        id="formFile"
      />
    </div>
  )
}
