import writeXlsxFile from "write-excel-file";
import { getReportFunctions } from "../../../utils/services/functions";

export async function createXlsx({ idEvent, countData, setFinishExport, jwt }) {
  try {
    //date format
    const formatDate = (date) => {
      const formatedDate = date.split("T")[0].split("-").reverse().join("/");
      const formatTime = date.split("T")[1].split(".")[0];

      return `${formatedDate} ${formatTime}`;
    };

    const schema = [
      {
        column: "ID do Participante",
        type: String,
        value: (download) => download.login.id,
      },
      {
        column: "Nome do Participante",
        type: String,
        value: (func) => func.login.nome,
      },
      {
        column: "Email do Participante",
        type: String,
        value: (download) => download.login.email,
      },
      {
        column: "Nome da Função",
        type: String,
        value: (download) => download.function.description,
      },
      {
        column: "Data e Hora da Execução",
        type: String,
        value: (download) => formatDate(download.calledAt),
      },
    ];

    const limit = 50;

    const numberOfRequisitions = Math.ceil(countData / limit);
    const allRequests = [];

    for (let i = 1; i <= numberOfRequisitions; i += 1) {
      const response = await getReportFunctions({
        limit,
        offset: i,
        search: "",
        id: idEvent,
        jwt: jwt,
      });

      allRequests.push(response.functions);
      setTimeout(() => {}, 500);

      const progressBar = document.querySelector(".export-progress");
      const percentageBar = document.querySelector(".export-percentage");

      progressBar.style.width = `${(i / numberOfRequisitions) * 100}%`;
      percentageBar.innerHTML = `${((i / numberOfRequisitions) * 100).toFixed(
        0
      )}%`;

      if (i === numberOfRequisitions) {
        setTimeout(() => {
          setFinishExport(true);
        }, 1000);
      }
    }

    const functions = allRequests.flat();

    await writeXlsxFile(functions, {
      schema,
      fileName: "relatorio-funcoes.xlsx",
    });
  } catch (error) {
    // console.log(error);
  }
}
