import writeXlsxFile from "write-excel-file";
import { getMatchNoRoom } from "../../../utils/services/match";

export async function createXlsx({
  jwt,
  idEvent,
  limit,
  countData,
  codeQrcode,
  empresa,
  cargo,
  code1,
  code2,
  code3,
  setFinishExport,
}) {
  const schema = [
    {
      column: 'ID',
      type: String,
      value: (match) => match.login_id,
    },
    {
      column: 'Nome',
      type: String,
      value: (match) => match.login_nome,
    },
    {
      column: 'Email',
      type: String,
      value: (match) => match.login_email,
    },
    {
      column: empresa ? 'Empresa' : null,
      type: String,
      value: (match) => match.empresa,
    },
    {
      column: cargo ? 'Cargo' : null,
      type: String,
      value: (match) => match.cargo,
    },
    {
      column: codeQrcode ? 'Código QRCode' : null,
      type: String,
      value: (match) => match.codeqrcode,
    },
    {
      column: code1 ? 'Código Interno 1' : null,
      type: String,
      value: (match) => match.codigo_interno_1,
    },
    {
      column: code2 ? 'Código Interno 2' : null,
      type: String,
      value: (match) => match.codigo_interno_2,
    },
    {
      column: code3 ? 'Código Interno 3' : null,
      type: String,
      value: (match) => match.codigo_interno_3,
    },
  ];
  const numberOfRequisitions = Math.ceil(countData / limit);

  let allRequests = [];
  for (let i = 1; i <= numberOfRequisitions; i++) {
    const request = await getMatchNoRoom({
      eventId: idEvent,
      jwt: jwt,
      offset: i,
      limit: limit,
    });

    allRequests.push(...request.data);

    setTimeout(() => {}, 500);
    const progressBar = document.querySelector('.export-progress');
    const percentageBar = document.querySelector('.export-percentage');

    progressBar.style.width = `${(i / numberOfRequisitions) * 100}%`;
    percentageBar.innerHTML = `${((i / numberOfRequisitions) * 100).toFixed(
      0
    )}%`;

    if (i === numberOfRequisitions) {
      setTimeout(() => {
        setFinishExport(true);
      }, 1000);
    }
  }
  await writeXlsxFile(allRequests, {
    schema: schema.filter((e) => e.column != null),
    fileName: 'ParticipantesSemQuarto.xlsx',
  });
}
