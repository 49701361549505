import React, { useEffect, useRef, useState } from 'react';

import {
  Modal,
  ModalHeader,
  ModalBody,
  Col,
  Row,
  Card,
  CardText,
  Form,
  CardBody,
  Collapse,
} from 'reactstrap';

import ImportGroup from './ImportGroups';
import ImportParticipant from './ImportParticipant';
import SectionComponent from '../../Apps/Personalization/SectionComponent';

export default function ModalImportWrapper(props) {
  const { isOpen, toggle, idEvent, getAllParticipants, token } = props;

  const [collapseGroupIsOpen, setCollapseGroupIsOpen] = useState(false);
  const [collapseLoginIsOpen, setCollapseLoginIsOpen] = useState(false);
  const [activeImport, setActiveImport] = useState(false);

  function toggleBackdrop() {
    setCollapseGroupIsOpen(false);
    setCollapseLoginIsOpen(false);
    toggle();
  }
  function toggleCollapseLogin() {
    if (activeImport) return;
    setCollapseGroupIsOpen(false);
    setCollapseLoginIsOpen(!collapseLoginIsOpen);
  }

  function toggleCollapseGroup() {
    if (activeImport) return;
    setCollapseGroupIsOpen(!collapseGroupIsOpen);
    setCollapseLoginIsOpen(false);
  }

  function activatedImport(status) {
    setActiveImport(status);
  }

  function setNull() {}

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => toggleBackdrop()}
      autoFocus={true}
      centered={true}
    >
      <ModalHeader toggle={() => toggleBackdrop()}>
        Importar Participantes
      </ModalHeader>
      <ModalBody className="m-1 p-0">
        <Card className="p-1">
          <CardBody className="m-1 p-2">
            <div>
              <Row
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginBottom: 10,
                  width: '245px',
                }}
              >
                <SectionComponent
                  sectionName="Criar/Atualizar participantes"
                  setIsOpen={!activeImport ? toggleCollapseLogin : setNull}
                  isOpen={collapseLoginIsOpen}
                />
              </Row>
              <Collapse isOpen={collapseLoginIsOpen}>
                <ImportParticipant
                  isOpen={isOpen}
                  toggle={toggleBackdrop}
                  active={activatedImport}
                  getAllParticipants={getAllParticipants}
                  token={token}
                  idEvent={idEvent}
                />
              </Collapse>
            </div>
            <div>
              <Row
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginBottom: 10,
                  width: '280px',
                }}
              >
                <SectionComponent
                  sectionName="Alterar Grupos dos Participantes"
                  setIsOpen={!activeImport ? toggleCollapseGroup : setNull}
                  isOpen={collapseGroupIsOpen}
                />
              </Row>
              <Collapse isOpen={collapseGroupIsOpen}>
                <ImportGroup
                  isOpen={isOpen}
                  toggle={toggleBackdrop}
                  active={activatedImport}
                  idEvent={idEvent}
                  token={token}
                  getAllParticipants={getAllParticipants}
                />
              </Collapse>
            </div>
          </CardBody>
        </Card>
      </ModalBody>
    </Modal>
  );
}
