import { urlBase } from "./config";
import axiosInstance from "../services/config/axiosinstance";

function updateProfile(data, jwt) {
  var config = {
    method: "post",
    url: `${urlBase}/admin/updateuser`,
    headers: {
      "content-type": "application/json",
    },
    data: data,
  };
  return axiosInstance(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response;
    });
}

export { updateProfile };
