import { getGroupsByName } from '../../../utils/services/group';
import { getEmailsInEvent } from '../../../utils/services/imports';
import { getParticipants } from '../../../utils/services/participants';

export const excelColumnsPtBr = {
  Email: 'email',
  Grupo: 'group',
};

const regexEmail =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

function emailValidate(email) {
  try {
    const emailValid = regexEmail.test(email);
    return emailValid ? email : undefined;
  } catch (error) {
    return undefined;
  }
}

export function allExcelColumns(row, index) {
  const formatedRow = {
    index,
    email: emailValidate(row.email),
    group: row.group ? row.group : [],
  };

  return formatedRow;
}

const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

async function findGroups(rows, jwt, eventId) {
  const changedRows = [];
  const groupError = [];
  const groupData = [];

  for (const row of rows) {
    if (typeof row.group === 'string') {
      const groupArray = row.group.split(',');
      const groupsIds = [];

      for (const group of groupArray) {
        await sleep(100);
        let groupIsValid = {
          findOneGroupData: {
            data: [],
          },
        };
        let checkGroupCached = groupData.filter((e) => e.name === group);
        if (checkGroupCached.length < 1) {
          groupIsValid = await getGroupsByName({
            jwt,
            eventId,
            search: group.trim(),
          });
          if (groupIsValid.findOneGroupData.data.length >= 1)
            groupData.push({
              id: groupIsValid.findOneGroupData.data[0].id,
              name: group,
            });
        }
        if (
          groupIsValid.findOneGroupData.data.length >= 1 ||
          checkGroupCached.length > 0
        ) {
          if (checkGroupCached.length > 0)
            groupsIds.push(checkGroupCached[0].id);
          else groupsIds.push(groupIsValid.findOneGroupData.data[0].id);
        } else {
          groupError.push(row);
          break;
        }
      }
      row.group = groupsIds;
    }
    changedRows.push(row);
  }
  return [changedRows, groupError];
}

export async function findRowsWithError(validRows, token, eventId) {
  const uniqueEmails = [];
  const duplicatedEmails = [];

  const [changedRows, groupError] = await findGroups(validRows, token, eventId);

  const notRegisteredEmails = await getUnregisteredEmails(
    validRows,
    token,
    eventId
  );

  changedRows.forEach((element) => {
    const isDuplicate = uniqueEmails.includes(element.email);

    if (!isDuplicate) {
      uniqueEmails.push(element.email);
    } else {
      duplicatedEmails.push(element);
    }
  });

  const rowsWithError = changedRows.filter(
    (row) =>
      Object.values(row).filter((value) => value === undefined).length > 0
  );

  duplicatedEmails.forEach(({ index }) => {
    if (rowsWithError.find((row) => row.index === index)) {
      rowsWithError.find((row) => row.index === index).email = undefined;
    } else {
      rowsWithError.push({
        ...changedRows[index],
        email: undefined,
      });
    }
  });

  if (notRegisteredEmails.length > 0) {
    let listEmailsError = [];
    changedRows.forEach((element) => {
      if (notRegisteredEmails.some((obj) => obj.email === element.email)) {
        listEmailsError.push(element);
      }
    });
    listEmailsError.forEach(({ index }) => {
      if (rowsWithError.find((row) => row.index === index)) {
        rowsWithError.find((row) => row.index === index).email = undefined;
      } else {
        rowsWithError.push({
          ...changedRows[index],
          email: undefined,
        });
      }
    });
  }
  groupError.forEach(({ index }) => {
    if (rowsWithError.find((row) => row.index === index)) {
      rowsWithError.find((row) => row.index === index).group = undefined;
    } else {
      rowsWithError.push({
        ...changedRows[index],
        group: undefined,
      });
    }
  });

  return [rowsWithError, changedRows];
}

export async function getUnregisteredEmails(file, jwt, eventId) {
  const limit = 100;
  const dataInDB = [];
  let numberOfRequisitions = 1;

  for (let i = 1; i <= numberOfRequisitions; i += 1) {
    const emails = await getEmailsInEvent({
      jwt,
      eventId,
      offset: i,
      limit,
    });

    if (i === 1) {
      numberOfRequisitions = Math.ceil(emails.count / limit);
    }

    dataInDB.push(emails);
    setTimeout(() => {}, 500);
  }

  const emailsInDB = dataInDB.map((email) => email.data).flat();
  const registeredEmails = file.filter(
    ({ email }) => !emailsInDB.includes(email)
  );

  return registeredEmails;
}

export async function getUnregisteredCpfs(jwt, idEvent, rows = []) {
  const cpfs = await getParticipants({
    jwt: jwt,
    idEvent: idEvent,
    offset: 1,
    limit: 100,
    search: '',
    status: 'ATIVO',
  });

  var cpfsRegistered = [];

  if (cpfs.data.count > 0) {
    cpfs.data.data.map((cpf) => {
      rows.map((row, i) => {
        if (
          row.cpf &&
          cpf.cpf &&
          row.cpf.replace(/\D/g, '') === cpf.cpf.replace(/\D/g, '')
        ) {
          var cpfInArray = cpfsRegistered.filter(
            (e) => e.replace(/\D/g, '') === row.cpf.replace(/\D/g, '')
          );

          if (!cpfInArray.length > 0) {
            cpfsRegistered.push(row.cpf);
          }
        }
      });
    });
  }

  return cpfsRegistered;
}
