import { urlBase } from "./config/index";
import axiosInstance from "../services/config/axiosinstance";

function createFlight({
  event,
  login,
  company,
  locator,
  origin,
  destination,
  boardingdate,
  arrivaldate,
  boardingtill,
  arrivaltime,
  terminal,
  seat,
  boardinggroup,
  extrainformation,
  jwt,
  eticket,
  flightnumber,
}) {
  var data = JSON.stringify({
    event: event,
    login: login,
    company: company,
    locator: locator,
    origin: origin,
    destination: destination,
    boardingdate: boardingdate,
    arrivaldate: arrivaldate,
    boardingtill: boardingtill,
    arrivaltime: arrivaltime,
    terminal: terminal,
    seat: seat,
    boardinggroup: boardinggroup,
    extrainformation: extrainformation,
    eticket: eticket,
    flightnumber: flightnumber,
  });

  var config = {
    method: "post",
    url: `${urlBase}/flights`,
    headers: {
      "Content-Type": "application/json",
    },
    data,
  };

  return axiosInstance(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

async function createFlightImport({
  event,
  login,
  company,
  locator,
  origin,
  destination,
  boardingdate,
  arrivaldate,
  boardingtill,
  arrivaltime,
  terminal,
  seat,
  boardinggroup,
  extrainformation,
  jwt,
  eticket,
  flightnumber,
}) {
  var data = JSON.stringify({
    event: event,
    login: login,
    company: company,
    locator: locator,
    origin: origin,
    destination: destination,
    boardingdate: boardingdate,
    arrivaldate: arrivaldate,
    boardingtill: boardingtill,
    arrivaltime: arrivaltime,
    terminal: terminal,
    seat: seat,
    boardinggroup: boardinggroup,
    extrainformation: extrainformation,
    eticket: eticket,
    flightnumber: flightnumber,
  });

  var config = {
    method: "post",
    url: `${urlBase}/flights/import`,
    headers: {
      "Content-Type": "application/json",
    },
    data,
  };

  return axiosInstance(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

function getFlight({ jwt, idEvent, offset, limit, search, columnSearch = "" }) {
  var config = {
    method: "get",
    url: `${urlBase}/flights/event/${idEvent}/?offset=${offset}&limit=${limit}&search=${search}&columnSearch=${columnSearch}`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  return axiosInstance(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response.data;
    });
}

function deleteFlight({ jwt, id }) {
  var config = {
    method: "delete",
    url: `${urlBase}/flights/${id}`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  return axiosInstance(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response.data;
    });
}

function deleteAllFlights({ jwt, eventId }) {
  var config = {
    method: "put",
    url: `${urlBase}/flights/disableall/${eventId}`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  return axiosInstance(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response.data;
    });
}
function updateFlight({
  event,
  login,
  company,
  locator,
  origin,
  destination,
  boardingdate,
  arrivaldate,
  boardingtill,
  arrivaltime,
  terminal,
  seat,
  boardinggroup,
  extrainformation,
  jwt,
  id,
  eticket,
  flightnumber,
}) {
  var data = JSON.stringify({
    event: event,
    login: login,
    company: company,
    locator: locator,
    origin: origin,
    destination: destination,
    boardingdate: boardingdate,
    arrivaldate: arrivaldate,
    boardingtill: boardingtill,
    arrivaltime: arrivaltime,
    terminal: terminal,
    seat: seat,
    boardinggroup: boardinggroup,
    extrainformation: extrainformation,
    eticket: eticket,
    flightnumber: flightnumber,
  });

  var config = {
    method: "put",
    url: `${urlBase}/flights/${id}`,
    headers: {
      "Content-Type": "application/json",
    },
    data,
  };

  return axiosInstance(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

export {
  createFlight,
  createFlightImport,
  getFlight,
  deleteFlight,
  deleteAllFlights,
  updateFlight,
};
