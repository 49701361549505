import React, { useState } from 'react'
import SweetAlert from 'react-bootstrap-sweetalert';
import { useSelector } from 'react-redux';

import { Button, Input, Label, Modal, ModalBody, ModalHeader, Row, Table, UncontrolledTooltip } from 'reactstrap';
import { newDict } from '../../utils/services/dictionary';

const INITIAL_STATE = [{
  id: 0,
  refText: '',
  translatedText: '',
}];

export default function NewDict(props) {
  const { isOpen, toggle, refetch, descriptions } = props;
  const { token: jwt } = useSelector((state) => state.global_user_data.data);

  const [modalBackdrop, setModalBackdrop] = useState(false);

  //campos fomulário
  const [selectedLanguage, setSelectedLanguage] = useState('');

  //validações campos formulários
  const [selectedLanguageError, setSelectedLanguageError] = useState(false);
  const [fieldsAreEmpty, setFieldsAreEmpty] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);

  //editado com sucesso
  const [success, setSuccess] = useState(false);

  //tabela
  const [modalDeleteRow, setModalDeleteRow] = useState(false);
  const [rowToDelete, setRowToDelete] = useState('');
  const [tableRows, setTableRows] = useState(INITIAL_STATE);

  const updateRowIndex = (newState) => {
    const rows = newState.map((row, index) => {
      row.id = index;
      return row;
    });

    return rows;
  };

  const addRow = () => {
    const newRow = {
      id: tableRows.length,
      refText: '',
      translatedText: '',
    };
    setTableRows([...tableRows, newRow]);
  };

  const removeRow = (id) => {
    const newRows = tableRows.filter((row) => row.id !== id);
    setTableRows(updateRowIndex(newRows));
  };

  const updateRow = (id, key, value) => {
    setTableRows(
      tableRows.map((row) => {
        if (row.id === id) {
          return {
            ...row,
            [key]: value,
          };
        }
        return row;
      })
    );
  }

  const isValidFields = () => {
    return tableRows.every((row) => row.refText !== '' && row.translatedText !== '');
  };

  function toggleBackdrop() {
    setModalBackdrop(!modalBackdrop);
  }

  function resetFields() {
    setTableRows(INITIAL_STATE);
    setSelectedLanguage('');
    setSelectedLanguageError(false);
    setFieldsAreEmpty(false);
    setDescriptionError(false);
    setSuccess(false);
  }

  async function createDict(json) {
    const data = {
      description: selectedLanguage,
      json,
    }
    await newDict({jwt, data});
  }

  async function submitForm() {
    if (descriptions.some((desc) => desc === selectedLanguage)) {
      return setDescriptionError(true);
    } else setDescriptionError(false);

    if (selectedLanguage === '') {
      return setSelectedLanguageError(true);
    } else setSelectedLanguageError(false);

    if (!isValidFields()) {
      return setFieldsAreEmpty(true);
    } else setFieldsAreEmpty(false);

    const rowsWithoutId = {};

    tableRows.forEach((row) => {
      rowsWithoutId[row.refText] = row.translatedText;
    });

    const json = JSON.stringify(rowsWithoutId);

    await createDict(json);

    resetFields();

    refetch();

    setSuccess(true);
  }

  return (
    <Modal
      size="lg"
      isOpen={isOpen}
      toggle={() => {
        toggleBackdrop();
      }}
      onClosed={() => {
        resetFields();
      }}
        autoFocus={true}
      centered={true}
    >
      {success && (
        <SweetAlert
          success
          title="Criado"
          onConfirm={() => {
            setSuccess(false);
            toggle();
          }}
        >
          Dicionário criado com sucesso!
        </SweetAlert>
      )}
      {modalDeleteRow && (
        <SweetAlert
          warning
          showCancel
          confirmBtnText="Sim, deletar!"
          confirmBtnBsStyle="danger"
          title="Deseja deletar essa linha?"
          onConfirm={() => {
            setModalDeleteRow(false);
            removeRow(rowToDelete);
          }}
          onCancel={() => setModalDeleteRow(false)}
          focusCancelBtn
        />
      )}
      <ModalHeader toggle={toggle}>
        Adicionar Dicionário
      </ModalHeader>
      <ModalBody>
        <form>
          <div>
            <div style={{display: 'flex'}}>
              <Label htmlFor="formText" className="form-label">
                Idioma
              </Label>
              <div>
                <div className="show-tips" id="identify-app-tip">
                  <span>?</span>
                </div>
                <UncontrolledTooltip style={{ backgroundColor: '#6f42c1' }} placement="right" target="identify-app-tip">
                  O valor inserido deve ser o identificador do idioma no APP
                </UncontrolledTooltip>
              </div>
            </div>
            <Input
              type="text"
              id="formText"
              className="form-control"
              placeholder="Digite o idioma"
              value={selectedLanguage}
              onChange={(e) => setSelectedLanguage(e.target.value)}
            />
            {selectedLanguageError && (
              <p style={{color: 'red'}}>O campo Idioma é obrigatório</p>
            )}
            {descriptionError && (
              <p style={{color: 'red'}}>O Idioma informado já está cadastrado</p>
            )}
            <Table>
              <thead>
                <tr>
                  <th>Texto Referência</th>
                  <th>Texto Traduzido</th>
                </tr>
              </thead>
              <tbody>
                {tableRows.map((table, index) => (
                  <tr key={index}>
                    <td>
                      <Input
                        type="text"
                        value={table.refText}
                        onChange={(e) => updateRow(table.id, 'refText', e.target.value)}
                      />
                    </td>
                    <td>
                      <div style={{display: 'flex', alignItems: 'center'}}>
                        <Input
                          type="text"
                          value={table.translatedText}
                          onChange={(e) => updateRow(table.id, 'translatedText', e.target.value)}
                        />
                        <span
                          style={{cursor: 'pointer', marginLeft: '10px'}}
                          className="mdi mdi-close close-icon"
                          title="Remover Linha"
                          onClick={() => setRowToDelete(table.id) > setModalDeleteRow(true)}
                        />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            {fieldsAreEmpty && (
              <p style={{color: 'red'}}>Todos os campos devem ser preenchidos</p>
            )}
            <div>
              <Button
                color="primary"
                onClick={addRow}
              >
                Adicionar Linha
              </Button>
            </div>
          </div>
          <Row
            style={{
              width: "100%",
              marginTop: 15,
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              color="secondary"
              className="btn waves-effect waves-light"
              style={{ width: 80, marginTop: 10, marginRight: 10 }}
              onClick={toggle}
            >
              Fechar
            </Button>
            <Button
              color="success"
              className="btn btn-success waves-effect waves-light"
              style={{ width: 80, marginTop: 10 }}
              onClick={submitForm}
            >
              Salvar
            </Button>
          </Row>
        </form>
      </ModalBody>
    </Modal>
  )
}
