import React, { useEffect, useState } from 'react';

import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row, 
  UncontrolledTooltip
} from 'reactstrap';

export default function FormButton({ deleteComponent, rowId, colId, attributes, setAttributes }) {
  const [btnText, setBtnText] = useState(attributes ? attributes.btnText : 'Enviar');
  const [fontColor, setFontColor] = useState(attributes ? attributes.fontColor : '#fff');
  const [btnColor, setBtnColor] = useState(attributes ? attributes.btnColor : '#007bff');

  // Edit Modal
  const [editModal, setEditModal] = useState(false);

  useEffect(() => {
    if (!attributes) {
      setAttributes({
        rowId,
        colId,
        attributes: {
          inputName: 'Botao de envio',
          btnText: 'Enviar',
          fontColor: '#fff',
          btnColor: '#007bff',
        },
      });
    }
  }, [rowId, colId, setAttributes, attributes]);

  const submitForm = (e) => {
    e.preventDefault();
    setAttributes({
      rowId,
      colId,
      attributes: { btnText, fontColor, btnColor, inputName: 'Botao de envio' },
    });
    setEditModal(false);
  };

  return (
    <div className="custom-input-container" id="updload-component">
      <Modal
        size="lg"
        isOpen={editModal}
        toggle={() => setEditModal(!editModal)}
      >
        <ModalHeader toggle={() => setEditModal(false)}>
          Configurar Botão
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <Form>
                <FormGroup className="input-margin-btm">
                  <Label htmlFor="edit-input-text" className="form-label">
                    Texto do Botão
                  </Label>
                  <Input
                    id="edit-input-text"
                    className="form-control"
                    type="text"
                    value={btnText}
                    onChange={({ target }) => setBtnText(target.value)}
                  />
                </FormGroup>
                <FormGroup className="input-margin-btm">
                  <div style={{display: 'flex'}}>
                    <Label htmlFor="edit-input-btnColor" className="form-label">
                      Cor do texto
                    </Label>
                    <div>
                      <div className="show-tips" id="btn-input-tip">
                        <span>?</span>
                      </div>
                      <UncontrolledTooltip style={{ backgroundColor: '#6f42c1' }} placement="right" target="btn-input-tip">
                        RGB, HEX ou nome da cor em inglês
                      </UncontrolledTooltip>
                    </div>
                  </div>
                  <Input
                    id="edit-input-btnColor"
                    className="form-control"
                    type="text"
                    value={fontColor}
                    onChange={({ target }) => setFontColor(target.value)}
                  />
                </FormGroup>
                <FormGroup className="input-margin-btm">
                  <div style={{display: 'flex'}}>
                    <Label htmlFor="edit-input-btnColor" className="form-label">
                      Cor do botão
                    </Label>
                    <div>
                      <div className="show-tips" id="btn-input-tip">
                        <span>?</span>
                      </div>
                      <UncontrolledTooltip style={{ backgroundColor: '#6f42c1' }} placement="right" target="btn-input-tip">
                        RGB, HEX ou nome da cor em inglês
                      </UncontrolledTooltip>
                    </div>
                  </div>
                  <Input
                    id="edit-input-btnColor"
                    className="form-control"
                    type="text"
                    value={btnColor}
                    onChange={({ target }) => setBtnColor(target.value)}
                  />
                </FormGroup>
              </Form>
            </Col>
            <Col style={{borderLeft: '1px solid gray'}}>
              <Button
                style={{
                  backgroundColor: btnColor,
                }}
              >
                <span style={{color: fontColor}}>{btnText}</span>
              </Button>
            </Col>
          </Row>
          <div className="save-btn-form-container">
            <Button
              color="success"
              className="btn btn-success waves-effect waves-light save-btn-form"
              onClick={submitForm}
            >
              Salvar
            </Button>
          </div>
        </ModalBody>
      </Modal>
      <div className="displayed-config-container">
        <Button
          className="displayed-label"
          style={{
            backgroundColor: btnColor,
          }}
        >
          <span style={{color: fontColor}}>{btnText}</span>
        </Button>
        <div className="config-form-icon">
          <span
            className="mdi mdi-cog-outline"
            onClick={() => setEditModal(!editModal)}
          />
          <span
            className="mdi mdi-close close-icon"
            onClick={() => deleteComponent(rowId, colId)}
          />
        </div>
      </div>
    </div>
  )
}
