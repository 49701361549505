import React, { useState, useEffect } from "react";
import {
  Table,
  Card,
  CardBody,
  CardTitle,
  Row,
  Button,
  Collapse,
} from "reactstrap";
// debounce
import useDebounce from "../../../../utils/hooks/useDebounce";
//redux
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
// componentes
import SectionComponent from "../../../Apps/Personalization/SectionComponent";
import SearchFilters from "../../../../components/SearchFilters";
import Pagination from "../../../../components/Paginationv3";
import ModaDetails from "../ModalDetails";
import WaitExport from "../../../../components/WaitExport";
import { createXlsx } from "./createXlsx";
// service
import { getSalesAnalitics } from "../../../../utils/services/sales";

function AnalyticalSales(props) {
  const location = useLocation();
  // token e id
  const token = props.state.global_user_data.data.token;
  const idEvent = location.state.idEvent;
  // exportar
  const [exportInProgress, setExportInProgress] = useState(false);
  const [finishExport, setFinishExport] = useState(false);
  // modais
  const [modalDetails, setModalDetails] = useState(false);
  // objeto selecionado
  const [idSelected, setIdSelected] = useState("");
  // listagem
  const [list, setList] = useState([]);
  // pesquisa
  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounce(search, 300);
  // section component
  const [isOpen, setIsOpen] = useState(false);
  const [collapseIsOpen, setCollapseIsOpen] = useState(false);
  //paginação
  const [currentIndex, setCurrentIndex] = useState(1);
  const [countData, setCountData] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  // filtro
  const [activeFilter, setActiveFilter] = useState("email");
  const columnsFilter = [
    { name: "Email", value: "email", type: "text" },
    { name: "Nome", value: "name", type: "text" },
    { name: "Produto", value: "product", type: "text" },
  ];

  function setActiveFilterHandle(columnActive) {
    setActiveFilter(columnActive);
    setSearch("");
  }

  function exportList() {
    createXlsx({
      token,
      search: debouncedSearch,
      eventId: idEvent,
      limit: 100,
      columnSearch: activeFilter,
      countData,
      setFinishExport,
    });

    setExportInProgress(true);
  }

  function getList() {
    getSalesAnalitics({
      jwt: token,
      search: debouncedSearch,
      eventId: idEvent,
      currentIndex,
      itemsPerPage,
      columnSearch: activeFilter,
    }).then((res) => {
      console.log(res);
      setList(res.transactions);
      setCountData(res.total);
    });
    // .catch((error) => console.log("erro", error));
  }

  useEffect(() => {
    getList();
  }, [debouncedSearch, currentIndex, itemsPerPage]);

  return (
    <>
      {modalDetails && (
        <ModaDetails
          isOpen={modalDetails}
          toggle={() => setModalDetails(false)}
          saleId={idSelected}
          token={token}
        />
      )}

      {exportInProgress && (
        <WaitExport
          isOpen={exportInProgress}
          name="Vendas Analíticas"
          preposicao="das"
          finishExport={finishExport}
          setFinishExport={setFinishExport}
          setExportInProgress={setExportInProgress}
        />
      )}

      <Card>
        <CardBody>
          <CardTitle>Vendas Analíticas</CardTitle>

          <Row
            style={{
              display: "flex",
              flexDirection: "row",
              marginBottom: 10,
              width: "50px",
            }}
          >
            <SectionComponent
              sectionName="Filtros"
              setIsOpen={setCollapseIsOpen}
              isOpen={collapseIsOpen}
            />
          </Row>

          <Collapse isOpen={collapseIsOpen}>
            <SearchFilters
              columns={columnsFilter}
              activeFilter={activeFilter}
              setActiveFilterHandle={setActiveFilterHandle}
            />
          </Collapse>

          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              marginBottom: 10,
            }}
          >
            <form
              className="app-search d-none d-lg-block events-input-search"
              onSubmit={(e) => e.preventDefault()}
            >
              <div className="position-relative">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Pesquisar..."
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
                <span className="bx bx-search-alt" />
              </div>
            </form>

            <div className="mobile-btn-section" style={{ width: "100%" }}>
              <Row className="mobile-btns-top">
                <form
                  className="app-search"
                  style={{ padding: "0px" }}
                  onSubmit={(e) => e.preventDefault()}
                >
                  <div className="position-relative">
                    <input
                      type="text"
                      value={search}
                      className="form-control"
                      placeholder="Pesquisar..."
                      onChange={(e) => setSearch(e.target.value)}
                    />
                    <span className="bx bx-search-alt" />
                  </div>
                </form>
              </Row>

              <SectionComponent
                sectionName="Opções"
                setIsOpen={setIsOpen}
                isOpen={isOpen}
              />

              <Collapse isOpen={isOpen}>
                {countData > 0 && (
                  <Row className="mobile-btns-top" onClick={() => exportList()}>
                    <div>Exportar</div>
                  </Row>
                )}
              </Collapse>
            </div>

            <div className="header-btns-container">
              {countData > 0 && (
                <Button
                  color="success"
                  className="btn-rounded waves-effect waves-light events-button-add-lg"
                  onClick={() => exportList()}
                >
                  Exportar
                </Button>
              )}
            </div>
          </div>

          <div className="table-responsive">
            <Table className="table mb-0">
              <thead className="table-light">
                <tr>
                  <th>Nome Participante</th>
                  <th>Email do Participante</th>
                  <th>Ingresso Comprado</th>
                  <th>Valor Pago</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                {list.length > 0 &&
                  list.map((e, i) => (
                    <tr key={i}>
                      <td>{e.nome}</td>
                      <td>{e.email}</td>
                      <td>{e.product}</td>
                      <td>R$ {e.amount}</td>
                      <td>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <i
                            style={{
                              color: "#aea017",
                              fontSize: 17,
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              setIdSelected(e.transactionid) >
                              setModalDetails(true)
                            }
                            className="bx bx-info-circle"
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
        </CardBody>
      </Card>

      <Pagination
        data={list}
        search={search}
        currentIndex={currentIndex}
        setCurrentIndex={setCurrentIndex}
        countData={countData}
        maxItemPerPage={itemsPerPage}
        setMaxItemPerPage={setItemsPerPage}
        maxPageButtonsToShow={3}
      />
    </>
  );
}

const mapStateToProps = (state) => {
  return { state };
};

export default connect(mapStateToProps)(AnalyticalSales);
