import React, { useRef, useState } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  Button,
  UncontrolledTooltip,
} from "reactstrap";
import TextEditor from "../../../components/TextEditor";
// service
import { sendEmailOnDelete } from "../../../utils/services/scheduleCotas";
import { TooltipAgendaModal } from "./TooltipAgendaModal";

const defaultEmail =
  '<div><p>Olá, <span style="font-weight: bold">${participantName}!</span></p><p>Sua inscrição no <span style="font-weight: bold">${eventName}</span> foi cancelada.</p></div>';

export default function ModalSendEmail(props) {
  const { isOpen, toggle, confirmDelete, deleteScheduleState, jwt } = props;
  const refEditor = useRef(null);

  async function submitForm(e) {
    const data = {
      agendaId: deleteScheduleState.id,
      subject: e.target[0].value,
      templateBase: refEditor.current.getContent(),
    };

    await sendEmailOnDelete(data, jwt).then((res) => confirmDelete.mutate());
    // .catch((error) => console.log("erro", error));
  }

  const [modalTooltip, setModalTooltip] = useState(false);
  const [tooltipType, setTooltipType] = useState("")

  const modalToggle = () => setModalTooltip(!modalTooltip);

  const openModal = (type) => {
    setModalTooltip(true)
    setTooltipType(type)
  }

  return (
    <>
      <Modal isOpen={isOpen} centered autoFocus>
        <ModalHeader toggle={toggle}>Email de Exclusão</ModalHeader>
        <ModalBody>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              submitForm(e);
            }}
          >
            <Row style={{ marginBottom: 10 }}>
              <Col md={12}>
                <label
                  className="form-label"
                  onClick={() => console.log(deleteScheduleState)}
                >
                  Assunto do Email *
                </label>
                <input
                  className="form-control"
                  type="text"
                  defaultValue={"Cancelamento da sessão ${eventName}"}
                />
              </Col>
            </Row>

            <label className="form-labe label-tooltip">
              Corpo do Email *
              <div id={`email-solicitation`} style={{ paddingRight: "7px", height: "16px" }}>
                <div onClick={() => openModal("S")} className="show-tips">
                  <span>?</span>
                </div>
                <UncontrolledTooltip
                  style={{ backgroundColor: "#6f42c1" }}
                  placement="right"
                  target={`email-solicitation`}
                >
                  Email enviado para os participantes da agenda. <br />
                  (Clique para mais instruções)
                </UncontrolledTooltip>
              </div>
            </label>
            <TextEditor defaultState={defaultEmail} refState={refEditor} />

            <Row
              style={{
                width: "100%",
                margin: "20px 0 0 0",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <>
                <Button
                  color="danger"
                  style={{ width: 80, marginRight: 10 }}
                  onClick={() => toggle()}
                >
                  Fechar
                </Button>
                <Button
                  color="success"
                  className="btn btn-success waves-effect waves-light"
                  style={{ width: 80 }}
                  type="submit"
                >
                  Enviar
                </Button>
              </>
            </Row>
          </form>
        </ModalBody>

        {modalTooltip && <TooltipAgendaModal modal={modalTooltip} toggle={modalToggle} type={tooltipType} />}
      </Modal>
    </>
  );
}
