import React from "react";
import { connect } from "react-redux";
import { Outlet } from "react-router-dom";

// import Context from "../utils/context";
// import { FirestoreProvider } from "../utils/firestoreProvider";

import Header from "./Header";
import Footer from "./Footer";
import NavBar from "./NavBar";

const Layout = (props) => {
  const [viewMenu, setViewMenu] = React.useState(false);
  // const [dataContext, setDataContext] = React.useState({});

  return (
    // <Context.Provider value={[dataContext, setDataContext]}>
    <>
      <div className="App">
        <div className="preloader" style={{ opacity: 0, display: "none" }}>
          <div className="preloader-inner">
            <div className="preloader-icon">
              <span />
              <span />
            </div>
          </div>
        </div>
        <div
          className="cursor"
          style={{ transform: "matrix(1, 0, 0, 1, 0, 0)", top: 902, left: 241 }}
        />
        <div className="row m-0">
          {/* web */}
          <div className="row m-0 p-0 layout-menu-web">
            <div
              className={
                !props.state.layout_manager.drawer_openend
                  ? "col-md-1 p-0"
                  : "col-md-2 p-0"
              }
            >
              <NavBar
                logged
                viewMenu={props.viewMenu}
                setViewMenu={props.viewMenusetViewMenu}
              />
            </div>
            <div
              className={
                !props.state.layout_manager.drawer_openend
                  ? "col-md-11 p-0"
                  : "col-md-10 p-0"
              }
              style={{ backgroundColor: "#f0f0f0" }}
            >
              <Header
                logged
                viewMenu={viewMenu}
                setViewMenu={setViewMenu}
                style={{ paddingBottom: 30 }}
              />
              <div
                style={{
                  padding: 20,
                  minHeight: "100vh",
                  backgroundColor: "#f0f0f0",
                  marginBottom: 80,
                }}
              >
                <Outlet />
              </div>
              <div style={{ position: "absolute", bottom: 0, width: "100%" }}>
                <Footer logged />
              </div>
            </div>
          </div>
          {/* mobile */}
          <div className="row m-0 p-0 layout-menu-mobile">
            <div className="col-md-2 p-0">
              <NavBar
                logged
                viewMenu={props.viewMenu}
                setViewMenu={props.viewMenusetViewMenu}
              />
            </div>
            <div className={"col-md-12 p-0"}>
              <Header
                logged
                viewMenu={viewMenu}
                setViewMenu={setViewMenu}
                style={{ paddingBottom: 30, width: "100%" }}
              />
            </div>

            <div className="col-md-12 p-0">
              <div
                style={{
                  padding: 20,
                  minHeight: "100vh",
                  backgroundColor: "#f0f0f0",
                }}
              >
                <Outlet />
              </div>
              <Footer logged />
            </div>
          </div>
        </div>
      </div>
    </>
    // </Context.Provider>
  );
};

const mapStateToProps = function (state) {
  return { state: state };
};

export default connect(mapStateToProps, null)(Layout);
