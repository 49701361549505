import React from "react";
import { Col, Card, CardBody } from "reactstrap";

const cardColor = {
  Duplo: "#00a19a",
  Triplo: "#662483",
  Quadruplo: "#00bcd4",
};

export default function StockCard(props) {
  const { type, stock, used } = props;
  return (
    <Col sm="3">
      <Card className="card-stock" style={{ backgroundColor: cardColor[type] }}>
        <CardBody className="card-body-stock">
          <p>{`Quarto ${type}`}</p>
          <div className="card-stock-subtitle">
            <p>Estoque</p>
            <p>|</p>
            <p>Utilizado</p>
          </div>
          <div className="card-text-stock">
            <p>{stock}</p>
            <p>{used}</p>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
}
