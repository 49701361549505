import React, { useState, useEffect } from "react";
import { Modal, ModalBody, ModalHeader, Col, Row, Button } from "reactstrap";
//redux
import { connect } from "react-redux";

function Instructions(props) {
  const { isOpen, toggle } = props;

  return (
    <>
      <Modal
        toggle={() => {
          toggle();
        }}
        isOpen={isOpen}
        autoFocus={true}
        centered={true}
        size="md"
      >
        <ModalHeader toggle={toggle}>Instruções</ModalHeader>
        <ModalBody>
          <div>
            <p style={{ fontWeight: "bold" }}>YouTube e Vimeo</p>
            <p>
              Para utilizar vídeos ou lives dos players YouTube e Vimeo é
              necessário inserir a URL embed no campo URL.
            </p>
            <p style={{ fontWeight: "bold" }}>M3U8</p>
            <p>
              Para utilizar link de vídeos no formato M3U8 utilize a informação
              ?direct_m3u8_link após o link do vídeo.
            </p>
            <p style={{ fontWeight: "normal" }}>
              <span style={{ fontWeight: "bold" }}>Exemplo: </span>
              https://assets.afcdn.com/video49/20210722/v_645516.m3u8?direct_m3u8_link
            </p>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
}

const mapStateToProps = function (state) {
  return { state: state };
};

export default connect(mapStateToProps)(Instructions);
