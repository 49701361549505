import { urlBaseAgenda } from "./config";
import axiosInstance from "../services/config/axiosinstance";

const baseUrl = urlBaseAgenda;

async function createScheduleQuotas(data) {
  var dataForm = JSON.stringify(data);

  var config = {
    method: "post",
    url: `${baseUrl}/agenda/cotas`,
    headers: {
      "Content-Type": "application/json",
    },
    data: dataForm,
  };
  return axiosInstance(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

async function updateScheduleQuotas(data) {
  var dataForm = JSON.stringify(data);
  var config = {
    method: "put",
    url: `${baseUrl}/agenda/cotas/${data.id}`,

    headers: {
      "Content-Type": "application/json",
    },
    data: dataForm,
  };
  return axiosInstance(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

async function distributeScheduleQuotas(data) {
  var dataForm = JSON.stringify(data);
  var config = {
    method: "put",
    url: `${baseUrl}/agenda/distribuicaocotas/${data.id}`,

    headers: {
      "Content-Type": "application/json",
    },
    data: dataForm,
  };
  return axiosInstance(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

async function listScheduleQuotas({ id, event, jwt }) {
  var config = {
    method: "get",
    url: `${baseUrl}/agenda/cotas/${id}/agenda/${event}/event`,

    headers: {
      "Content-Type": "application/json",
    },
  };

  return axiosInstance(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

async function getListSessions(id, jwt) {
  var config = {
    method: "get",
    url: `${baseUrl}/agenda/cotasmanuais/${id}/event`,

    headers: {
      "Content-Type": "application/json",
    },
  };

  return axiosInstance(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

async function getReportScheduleQuotes({ id, jwt }) {
  var config = {
    method: "get",

    url: `${baseUrl}/agenda/report/agendacotas/${id}/event`,

    headers: {
      "Content-Type": "application/json",
    },
  };

  return axiosInstance(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

function importScheduleQuota(data) {
  var dataForm = JSON.stringify(data);
  var config = {
    method: "post",
    url: `${baseUrl}/agenda/cotas/import`,
    headers: {
      "Content-Type": "application/json",
    },
    data: dataForm,
  };
  return axiosInstance(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

function sendEmailOnDelete(data, jwt) {
  // var dataForm = JSON.stringify(data);
  var config = {
    method: "post",
    url: `${baseUrl}/agenda/cotas/emailondelete`,
    headers: {
      "Content-Type": "application/json",
    },
    data,
  };
  return axiosInstance(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

export {
  createScheduleQuotas,
  distributeScheduleQuotas,
  getListSessions,
  getReportScheduleQuotes,
  importScheduleQuota,
  listScheduleQuotas,
  updateScheduleQuotas,
  sendEmailOnDelete,
};
