import React, { useState, useEffect } from "react";

import { Modal, ModalBody, ModalHeader, Col, Row, Button } from "reactstrap";

//service
import {
  editConfiguration,
  getConfigurations,
} from "../../../utils/services/ranking";
import { getMenus } from "../../../utils/services/menus";

import ModalIcon from "./modalIcon";

import SweetAlert from "react-bootstrap-sweetalert";

import { optionCode } from "./mockCodes";
import ButtonSpinner from "../../../components/Buttons/ButtonSpinner";

function ModalEditConfigRanking(props) {
  const { isOpen, toggle, jwt } = props;
  const [isLoading, setIsloading] = useState(false);
  const [modalBackdrop, setModalBackdrop] = useState(false);
  //icon
  const [modalIcon, setModalIcon] = useState(false);
  const [iconSelected, setIconSelected] = useState(props.editConfig.urlIcon);
  //second form
  const [daily, setDaily] = useState(props.editConfig.daily);
  //state de erros
  const [codeError, setCodeError] = useState("");
  const [pointsError, setPointsError] = useState("");
  const [iconError, setIconError] = useState("");
  const [dateStartError, setDateStartError] = useState("");
  const [hourStartError, setHourStartError] = useState("");
  const [dateEndError, setDateEndError] = useState("");
  const [hourEndError, setHourEndError] = useState("");
  //confirmção de criação
  const [success_dlg, setsuccess_dlg] = useState(false);
  //state de configurações do evento
  const [config, setConfig] = useState([]);
  // state options
  const [options, setOptions] = useState([]);
  const [currentOption, setCurrentOption] = useState("");

  // state form
  const [code, setCode] = useState(props.editConfig.code);
  const [points, setPoints] = useState(props.editConfig.earnedPoints);
  const [description, setDescription] = useState(
    props.editConfig.description || ""
  );
  const [dateStart, setDateStart] = useState("");
  const [hourStart, setHourStart] = useState(props.editConfig.startHour);
  const [dateEnd, setDateEnd] = useState("");
  const [hourEnd, setHourEnd] = useState(props.editConfig.endHour);

  function toggleBackdrop() {
    setModalBackdrop(!modalBackdrop);
  }

  function toggleModalIcon() {
    setModalIcon(!modalIcon);
  }

  function formatData(propsStartDate, propsEndDate) {
    if (propsStartDate) {
      setDateStart(propsStartDate.substr(0, 10));
    }

    if (propsEndDate) {
      setDateEnd(propsEndDate.substr(0, 10));
    }
  }

  async function getAllConfigurations() {
    await getConfigurations({
      id: props.idEvent,
      jwt: props.jwt,
      limit: 100,
    })
      .then((response) => {
        setConfig(response.data);
      })
      .catch((error) => {
        // console.log("response error", error);
      });
  }

  async function submitForm() {
    if (code === "Selecione" || code === "Outros" || code === "") {
      setCodeError("O campo Código é obrigatório");
      return;
    } else setCodeError("");

    if (points === "") {
      setPointsError("O campo Pontuação é obrigatório");
      return;
    } else setPointsError("");

    if (iconSelected === "") {
      setIconError("O campo Ícone é obrigatório");
      return;
    } else setIconError("");

    if (daily) {
      if (dateStart === "") {
        setDateStartError("O campo Data Inicio é obrigatório");
        return;
      } else setDateStartError("");

      if (hourStart === "") {
        setHourStartError("O campo Hora Inicio é obrigatório");
        return;
      } else setHourStartError("");

      if (dateEnd === "") {
        setDateEndError("O campo Data Fim é obrigatório");
        return;
      } else setDateEndError("");

      if (hourEnd === "") {
        setHourEndError("O campo Hora Fim é obrigatório");
        return;
      } else setHourEndError("");
    }

    const getCode = optionCode.find((e) => e.describe === code);

    setIsloading(true);
    await editConfiguration({
      code:
        currentOption === "Outros"
          ? code
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .replace(/\s/g, "")
              .toUpperCase()
          : getCode !== undefined
          ? getCode.code
          : code,
      urlIcon: iconSelected,
      daily: daily,
      startAt: daily ? dateStart : null,
      endAt: daily ? dateEnd : null,
      startHour: daily ? hourStart : null,
      endHour: daily ? hourEnd : null,
      earnedPoints: points,
      description,
      eventId: props.idEvent,
      id: props.editConfig.id,
      jwt: props.jwt,
    }).then((response) => {
      setIsloading(false);
      if (response.message === "CONFIGURATION_UPDATED") {
        setsuccess_dlg(true);
      }
    });
    // .catch((error) => console.log("response error", error));
  }

  const getAllMenus = async () => {
    const formData = await getMenus(jwt, props.idEvent);
    const newOptions = formData.findOneMenuData.buttons.map(
      ({ description }) => ({ option: description })
    );

    setOptions([...optionCode, ...newOptions]);
  };

  useEffect(() => {
    getAllMenus();
    getAllConfigurations();
    formatData(props.editConfig.startAt, props.editConfig.endAt);
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => toggleBackdrop}
      autoFocus={true}
      centered={true}
    >
      {modalIcon && (
        <ModalIcon
          isOpen={modalIcon}
          toggle={toggleModalIcon}
          setIconSelected={setIconSelected}
          iconSelected={iconSelected}
        />
      )}
      {success_dlg ? (
        <SweetAlert
          success
          title={"Editado"}
          onConfirm={() => {
            props.getAllConfigurations();
            setsuccess_dlg(false);
            toggle();
          }}
        >
          {"Configuração de Ranking editada com sucesso!"}
        </SweetAlert>
      ) : null}
      <ModalHeader toggle={toggle}>Configuração do Ranking</ModalHeader>
      <ModalBody>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            submitForm(e);
          }}
        >
          <Row style={{ marginBottom: 20 }}>
            <Col lg={12}>
              <label className="form-label">ID do Ranking</label>
              <input
                className="form-control"
                type="text"
                value={props?.editConfig?.id ? props.editConfig.id : null}
                disabled
              />
            </Col>
          </Row>

          <Row style={{ marginBottom: 20 }}>
            <Col lg={12}>
              <label className="form-label">Código*</label>
              <select
                className="form-control"
                onChange={({ target }) =>
                  setCurrentOption(target.value) > setCode(target.value)
                }
                disabled
              >
                <option>
                  {optionCode.find((e) => e.code === code)?.describe || code}
                </option>
                {options &&
                  options
                    .filter(
                      (e) =>
                        !config.find(
                          (a) => a.code === e.option || a.code === e.code
                        )
                    )
                    .map((e, i) => (
                      <option key={i}>{e?.describe || e.option}</option>
                    ))}
                <option>Outros</option>
              </select>
            </Col>
          </Row>

          {currentOption === "Outros" && (
            <Row style={{ marginBottom: 20 }}>
              <Col lg={12}>
                <label className="form-label">Insira o Código*</label>
                <input
                  className="form-control"
                  type="text"
                  onChange={({ target }) => setCode(target.value)}
                />
              </Col>
            </Row>
          )}
          <p style={{ color: "red" }}>{codeError}</p>

          <Row style={{ marginBottom: 20 }}>
            <Col lg={6}>
              <label className="form-label">Pontuação*</label>
              <input
                className="form-control"
                type="text"
                value={points}
                onChange={({ target }) => setPoints(target.value)}
                disabled
              />
              <p style={{ color: "red" }}>{pointsError}</p>
            </Col>

            <Col lg={6}>
              <label className="form-label">Ícone*</label>
              <div
                onClick={() => setModalIcon(true)}
                className="form-control"
                type={"text"}
              >
                {iconSelected === "" ? (
                  <span>Selecione</span>
                ) : (
                  <i className={iconSelected} />
                )}
              </div>

              <p style={{ color: "red" }}>{iconError}</p>
            </Col>
          </Row>

          <Row style={{ marginBottom: 20 }}>
            <Col lg={12}>
              <label className="form-label">Descrição</label>
              <input
                className="form-control"
                type="text"
                value={description}
                onChange={({ target }) => setDescription(target.value)}
              />
            </Col>
          </Row>

          <Row style={{ marginBottom: 20 }}>
            <Col lg={6}>
              <label className="form-label">Diário</label>
              <input
                // className="form-control"
                style={{ marginLeft: 10 }}
                type="checkbox"
                defaultChecked={props.editConfig.daily}
                onChange={(e) => setDaily(e.target.checked)}
                disabled
              />
            </Col>
          </Row>

          {daily ? (
            <>
              <Row style={{ marginBottom: 20 }}>
                <Col lg={6}>
                  <label className="form-label">Data Inicio</label>
                  <input
                    className="form-control"
                    type={"date"}
                    value={dateStart}
                    onChange={({ target }) => setDateStart(target.value)}
                  />
                  <p style={{ color: "red" }}>{dateStartError}</p>
                </Col>
                <Col lg={6}>
                  <label className="form-label">Hora Inicio</label>
                  <input
                    className="form-control"
                    type={"time"}
                    value={hourStart}
                    onChange={({ target }) => setHourStart(target.value)}
                  />
                  <p style={{ color: "red" }}>{hourStartError}</p>
                </Col>
              </Row>

              <Row style={{ marginBottom: 20 }}>
                <Col lg={6}>
                  <label className="form-label">Data Fim</label>
                  <input
                    className="form-control"
                    type={"date"}
                    value={dateEnd}
                    onChange={({ target }) => setDateEnd(target.value)}
                  />
                  <p style={{ color: "red" }}>{dateEndError}</p>
                </Col>
                <Col lg={6}>
                  <label className="form-label">Hora Fim</label>
                  <input
                    className="form-control"
                    type={"time"}
                    defaultValue={hourEnd}
                    onChange={({ target }) => setHourEnd(target.value)}
                  />
                  <p style={{ color: "red" }}>{hourEndError}</p>
                </Col>
              </Row>
            </>
          ) : null}

          <Row
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              color="danger"
              style={{ width: 80, marginRight: 10 }}
              onClick={() => toggle()}
            >
              Fechar
            </Button>

            <ButtonSpinner isLoading={isLoading} title={"Salvar"} />
          </Row>
        </form>
      </ModalBody>
    </Modal>
  );
}

export default ModalEditConfigRanking;
