import React, { useEffect, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { useSelector } from "react-redux";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  Button,
  Collapse,
  UncontrolledTooltip,
  Table,
  Card,
  CardBody,
  CardTitle,
} from "reactstrap";
import SectionComponent from "../../Apps/Personalization/SectionComponent";
import Pagination from "../../../components/Paginationv3";
import ModalParticipantSession from "./NewParticipantSession";
import ModalEditParticipant from "./EditParticipantSession";
import { listWinners } from "../../../utils/services/sorteios";

export default function Winners(props) {
  const { isOpen, toggle, eventId, refetch, selectedSorteioEdit } = props;
  const { token: jwt } = useSelector((state) => state.global_user_data.data);
  const [modalBackdrop, setModalBackdrop] = useState(false);
  //search and paginations
  const [search, setSearch] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [modalParticipantSession, setModalParticipantSession] = useState(false);
  const [editParticipant, setEditParticipant] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const [offset, setOffset] = useState(1);
  const [limit, setLImit] = useState(10);

  const [selectedParticipant, setSelectedParticipant] = useState(null);

  //deletar evento
  const [confirm_alert, setconfirm_alert] = useState(false);
  const [success_dlg, setsuccess_dlg] = useState(false);

  const [items, setItems] = useState([]);

  function toggleBackdrop() {
    setModalBackdrop(!modalBackdrop);
  }

  async function getItens() {
    const data = await listWinners({
      id: selectedSorteioEdit.id,
      jwt: jwt,
      offset: offset,
      limit: limit,
      search: search,
    });
    console.log(data)
    setItems(data.getDrawWinners.winnersData.data);
    setTotalItems(data.getDrawWinners.winnersData.count);
  }

  useEffect(() => {
    getItens();
  }, [offset, limit, search]);
  return (
    <>
      <Modal
        isOpen={isOpen}
        toggle={() => toggleBackdrop()}
        autoFocus
        centered
        size="xl"
      >
        {success && (
          <SweetAlert
            success
            title="Edição de Certificado"
            onConfirm={() => {
              setSuccess(false);
              toggle();
            }}
          >
            Participante criado com sucesso
          </SweetAlert>
        )}

        <ModalHeader toggle={toggle}>Sorteados</ModalHeader>
        <ModalBody style={{ padding: 20 }}>
          {/* <CardTitle>Sorteios</CardTitle> */}
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              marginBottom: 10,
            }}
          >
            <form
              className="app-search d-none d-lg-block events-input-search"
              onSubmit={(e) => e.preventDefault()}
            >
              <div className="position-relative">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Pesquisar..."
                  onChange={(e) => setSearch(e.target.value)}
                />
                <span className="bx bx-search-alt" />
              </div>
            </form>
            {/* <div className="header-btns-container">
              <Button
                color="success"
                className="btn-rounded waves-effect waves-light events-button-add"
              >
                Exportar Participantes
              </Button>
              <Button
                color="success"
                className="btn-rounded waves-effect waves-light events-button-add"
                onClick={() => setModalParticipantSession(true)}
              >
                Adicionar Participantes
              </Button>
            </div> */}
          </div>
          <div className="table-responsive mb-3">
            <Table className="table mb-0">
              <thead className="table-light">
                <tr>
                  <th>Nome</th>
                  <th>Email</th>
                  <th>Quantidade de Ingressos</th>
                </tr>
              </thead>
              <tbody>
                {items.length > 0 &&
                  items.map((e, i) => (
                    <tr key={i}>
                      <td>{e.nome}</td>
                      <td>{e.email}</td>
                      <td>{e.companionsNumber}</td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>

          <div className="bottom-navigation" style={{ justifyContent: "end" }}>
            {/* 
        Fornecer os 4 parametros obrigatórios;
        ** data - a array contendo as informações da tabela
        ** search - o state do termo de pesquisa
        ** currentIndex - posição atual do index
        ** setCurrentIndex - função para atualizar a posição atual do index
        */}
            <Pagination
              data={items}
              search={search}
              currentIndex={offset}
              setCurrentIndex={setOffset}
              countData={totalItems}
              maxItemPerPage={limit}
              setMaxItemPerPage={setLImit}
              maxPageButtonsToShow={3}
            />
          </div>
        </ModalBody>
      </Modal>
    </>
  );
}
