export const urlBase = `${process.env.REACT_APP_URL_BASE}/api-admin-mobile`;
export const urlBaseAgenda = `${process.env.REACT_APP_URL_BASE}/api-admin-agenda`;
export const urlBaseFiles = `${process.env.REACT_APP_URL_BASE}/api-admin-files`;
export const urlBaseNotifications = `${process.env.REACT_APP_URL_BASE}/api-admin-notification`;
export const urlBaseRanking = `${process.env.REACT_APP_URL_BASE}/api-admin-ranking`;
export const urlBaseGuests = `${process.env.REACT_APP_URL_BASE}/api-admin-guests`;
export const urlBaseManagement = `${process.env.REACT_APP_URL_BASE}/api-admin-management`;
export const urlBaseContract = `${process.env.REACT_APP_URL_BASE}/api-admin-management/contracts`;
export const urlBaseProduct = `${process.env.REACT_APP_URL_BASE}/api-admin-management/products`;
export const urlBaseImports = `${process.env.REACT_APP_URL_BASE}/api-admin-import`;
export const urlBaseImportLoginGroups = `${process.env.REACT_APP_URL_BASE}/api-admin-logingroups-import`;
export const urlBaseCertificate = `${process.env.REACT_APP_URL_BASE}/api-admin-certificado`;
export const urlBaseTemplates = `${process.env.REACT_APP_URL_BASE}/api-admin-template`;
export const urlBaseSorteio = `${process.env.REACT_APP_URL_BASE}/api-admin-mobile`;
// export const urlBaseMatch = `${process.env.REACT_APP_URL_BASE}/match-hospedagem-api`;
export const urlBaseMatch = `${process.env.REACT_APP_URL_BASE}/api-admin-hospedagem`;
export const urlDomain =
  process.env.REACT_APP_URL_DOMAIN ||
  "NO_ENVIRONMENT_VARIABLE_REACT_APP_URL_DOMAIN";
export const urlBaseAgendamento = `${process.env.REACT_APP_SCHEDULING_URL_BASE}`;
export const urlBaseVibra = `${process.env.REACT_APP_URL_BASE_VIBRA}`;
