/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Modal, ModalBody, ModalHeader, Col, Row, Button } from "reactstrap";
import { v4 as uuidv4 } from "uuid";
//axios
import axios from "axios";
//redux
import { connect } from "react-redux";
// alerta
import SweetAlert from "react-bootstrap-sweetalert";
//service
import { newEvent } from "../../utils/services/events";
import { getEventType } from "../../utils/services/eventType";
import ButtonSpinner from "../../components/Buttons/ButtonSpinner";

function NewEvent(props) {
  const { isOpen, toggle } = props;
  const [modalBackdrop, setModalBackdrop] = useState(false);
  const [selectedGroup, setselectedGroup] = useState(null);
  const [isLoading, setIsloading] = useState(false);

  //campos do formulário
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [startHour, setStartHour] = useState("");
  const [endHour, setEndHour] = useState("");
  const [localizationSelected, setLocalizationSelected] = useState();
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [editLatLon, setEditLatLon] = useState(false);
  const [showCompany, setShowCompany] = useState(false);
  const [showOffice, setShowOffice] = useState(false);
  //state opções de localização
  const [optionLocalization, setOptionLocalization] = useState([]);
  //erro dos campos obrigatórios
  const [titleError, setTitleError] = useState("");
  const [dateStartError, setDateStartError] = useState("");
  const [productsError, setProductsError] = useState("");
  const [eventFormatError, setEventFormatError] = useState("");
  const [localizationError, setLocalizationError] = useState("");
  //produtos
  const [productsList, setProductsList] = useState([]);
  const [productsSelected, setProductsSelected] = useState([]);
  // tipos de eventos
  const [eventTypes, setEventTypes] = useState([]);
  const [eventTypesSelected, setEventTypesSelected] = useState("");
  // carregar btn
  const [loading, setLoading] = useState(false);
  // sucesso
  const [success_dlg, setsuccess_dlg] = useState(false);
  // dados da requisição
  const token = props.state.global_user_data.data.token;

  function toggleBackdrop() {
    setModalBackdrop(!modalBackdrop);
  }

  function handleSelectGroup(e) {
    setselectedGroup(e);
  }

  const submitForm = async () => {
    if (title === "") {
      setTitleError("O campo Nome é obrigatório.");
      return;
    } else setTitleError("");

    if (!selectedGroup || selectedGroup === "Selecionar") {
      setEventFormatError("Selecione o formato do evento");
      return;
    } else setEventFormatError("");

    if (selectedGroup != "digital" && !localizationSelected) {
      setLocalizationError("Insira um local");
      return;
    } else setLocalizationError("");

    if (startDate === "") {
      setDateStartError("O campo Data de Início é obrigatório.");
      return;
    } else setDateStartError("");

    if (productsSelected.length === 0) {
      setProductsError("Selecione ao menos um produto");
      return;
    } else setProductsError("");

    // setLoading(true);
    setIsloading(true);
    let eventId = uuidv4();

    const objEvento = {
      id: eventId,
      codEvent: "",
      description: description,
      title: title,
      category: eventTypesSelected === "Selecione" ? "" : eventTypesSelected,
      eventFormat: selectedGroup,
      startDate: new Date(`${startDate}T00:01`).toISOString(),
      endDate: new Date(
        `${endDate === "" ? startDate : endDate}T00:01`
      ).toISOString(),
      startHour: startHour !== "" ? startHour : "00:00",
      endHour: endHour !== "" ? endHour : "00:00",
      enabled: true,
      localizations: [{ description: optionLocalization }],
      products: productsSelected,
      latitude: latitude,
      longitude: longitude,
      showCompany: showCompany,
      showJobPosition: showOffice,
    };

    await newEvent(objEvento, token)
      .then((res) => {
        if (res.message === "created") {
          // setLoading(false);
          setIsloading(false);
          setsuccess_dlg(true);
        }
      })
      .catch((error) => {
        setIsloading(false);
        console.log("erro", error);
      });
  };

  function localization(e) {
    setLocalizationSelected("");
    axios
      .get(
        `https://us-central1-inteegra-ping.cloudfunctions.net/GoogleAutoComplete?search=${e}&language=pt`
      )
      .then((localizations) => {
        setOptionLocalization(localizations);
      })
      .catch((error) => {
        // console.log(error);
      });
  }

  function selectedLocale(l) {
    setLocalizationSelected(l);
    document.getElementById("localInput").value = l.description;
    setOptionLocalization(l.description);
  }

  function setProductCheck(item, check) {
    var products = productsSelected;

    if (check) {
      products.push(item.idmainproduct);
    } else {
      products = products.filter((e) => e != item.idmainproduct);
    }

    setProductsSelected(products);
  }

  function getProducts() {
    var listProducts = [];

    props.state.global_user_data.data.foundProducts.map((product) => {
      var filter = listProducts.filter(
        (e) => e.namemainproduct === product.namemainproduct
      );

      if (filter.length === 0) {
        listProducts.push({
          namemainproduct: product.namemainproduct,
          idmainproduct: product.idmainproduct,
        });
      }
    });

    setProductsList(
      listProducts.filter((f) => f.namemainproduct !== "Telas de Configuração")
    );
  }

  async function getListEventType() {
    await getEventType({
      jwt: token,
      offset: 1,
      limit: 1000,
      search: "",
      active: true,
    }).then((res) => {
      if (res.message === "findEventTypes") {
        setEventTypes(res.findEventTypesData.data);
      }
    });
    // .catch((error) => console.log("erro", error));
  }

  useEffect(() => {
    getProducts();
    getListEventType();
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => {
        toggleBackdrop();
      }}
      autoFocus={true}
      centered={true}
    >
      {success_dlg ? (
        <SweetAlert
          success
          title={"Cadastro"}
          onConfirm={() => {
            props.getAllEvents();
            setsuccess_dlg(false);
            toggle();
          }}
        >
          {"Evento cadastrado com sucesso!"}
        </SweetAlert>
      ) : null}
      <ModalHeader toggle={toggle}>Novo Evento</ModalHeader>
      <ModalBody>
        <form>
          <Row>
            <Col lg={6}>
              <div>
                <label className="form-label">Nome do Evento*</label>
                <input
                  className="form-control"
                  type="text"
                  onChange={(e) => setTitle(e.target.value)}
                />
                <p style={{ color: "red" }}>{titleError}</p>
              </div>
            </Col>

            <Col lg={6}>
              <div>
                <label className="form-label">Tipo de evento</label>
                <select
                  className="form-control"
                  onChange={(e) => {
                    setEventTypesSelected(e.target.value);
                  }}
                >
                  <option value={"Selecione"}>Selecione</option>
                  {eventTypes.length > 0 &&
                    eventTypes.map((e, i) => (
                      <option key={i} value={e.id}>
                        {e.type}
                      </option>
                    ))}
                </select>
              </div>
            </Col>
          </Row>

          <Row style={{ marginTop: 10 }}>
            <Col lg={12}>
              <div>
                <label className="form-label">Descrição</label>
                <textarea
                  className="form-control"
                  type="text"
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
            </Col>
          </Row>

          <Row style={{ marginTop: 10 }}>
            <Col lg={12}>
              <div>
                <label className="form-label">Formato do Evento*</label>
                <select
                  className="form-control"
                  onChange={(e) => {
                    handleSelectGroup(e.target.value);
                    setEditLatLon(false);
                  }}
                >
                  <option>Selecionar</option>
                  <option value="presencial">Presencial</option>
                  <option value="digital">Digital</option>
                  <option value="hibrido">Híbrido</option>
                </select>
                <p style={{ color: "red" }}>{eventFormatError}</p>
              </div>
            </Col>
          </Row>

          {selectedGroup !== "digital" && (
            <>
              <Row style={{ marginTop: 10 }}>
                <Col lg={12}>
                  <div>
                    <label className="form-label">Localização*</label>
                    <input
                      className="form-control"
                      type="text"
                      id="localInput"
                      onChange={(e) => {
                        localization(e.target.value);
                        setLatitude("");
                        setLongitude("");
                      }}
                    />
                    <p style={{ color: "red" }}>{localizationError}</p>
                  </div>
                  {optionLocalization.data &&
                  optionLocalization.data.success ? (
                    <ul className="localization">
                      {optionLocalization.data.success.predictions.map((l) => {
                        return (
                          <li
                            key={l.description}
                            className="localizationLi"
                            onClick={() => selectedLocale(l)}
                          >
                            {l.description}
                          </li>
                        );
                      })}
                    </ul>
                  ) : null}
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <div
                    className="form-check form-switch"
                    style={{
                      borderTop: "1px solid #dbdbdb",
                      borderBottom: "1px solid #dbdbdb",
                      marginTop: 5,
                      marginBottom: 5,
                      paddingTop: 5,
                      paddingBottom: 5,
                    }}
                  >
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id={`checkLatLon`}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setEditLatLon(true);
                        } else setEditLatLon(false);
                      }}
                    />
                    <label className="form-check-label" htmlFor={`checkLatLon`}>
                      Editar Latitude/Longitude
                    </label>
                  </div>
                </Col>
              </Row>

              {editLatLon && (
                <Row style={{ marginTop: 2 }}>
                  <Col lg={6}>
                    <div>
                      <label className="form-label">Latitude</label>
                      <input
                        className="form-control"
                        type="text"
                        value={latitude}
                        onChange={(e) => setLatitude(e.target.value)}
                      />
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div>
                      <div>
                        <label className="form-label">Longitude</label>
                        <input
                          className="form-control"
                          type="text"
                          value={longitude}
                          onChange={(e) => setLongitude(e.target.value)}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
              )}
            </>
          )}

          <Row style={{ marginTop: 10 }}>
            <Col lg={6}>
              <div>
                <label className="form-label">Data Inicio*</label>
                <input
                  className="form-control"
                  type="date"
                  onChange={(e) => setStartDate(e.target.value)}
                />
                <p style={{ color: "red" }}>{dateStartError}</p>
              </div>
            </Col>
            <Col lg={6}>
              <div>
                <label className="form-label">Data Fim</label>
                <input
                  className="form-control"
                  type="date"
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </div>
            </Col>
          </Row>

          <Row style={{ marginTop: 10 }}>
            <Col md={6}>
              <div>
                <label className="form-label">Hora Inicio</label>
                <input
                  className="form-control"
                  type="time"
                  onChange={(e) => setStartHour(e.target.value)}
                />
              </div>
            </Col>
            <Col md={6}>
              <div>
                <label className="form-label">Hora Fim</label>
                <input
                  className="form-control"
                  type="time"
                  onChange={(e) => setEndHour(e.target.value)}
                />
              </div>
            </Col>
          </Row>

          <Row style={{ marginTop: 10 }}>
            <Col md={6}>
              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  defaultValue={false}
                  id="checkCompany"
                  onChange={(e) => setShowCompany(e.target.checked)}
                />
                <label className="form-check-label" htmlFor="checkCompany">
                  Exibir Empresa
                </label>
              </div>
            </Col>

            <Col md={6}>
              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  defaultValue={false}
                  id="checkOffice"
                  onChange={(e) => setShowOffice(e.target.checked)}
                />
                <label className="form-check-label" htmlFor="checkOffice">
                  Exibir Cargo
                </label>
              </div>
            </Col>
          </Row>

          <Row style={{ marginTop: 18 }}>
            <p style={{ marginBottom: 14, fontWeight: "bold" }}>
              Selecione o(s) produto(s) do evento:*
            </p>
          </Row>

          {productsList &&
            productsList.sort().map((item, i) => (
              <Row key={i}>
                <Col md={12}>
                  <div className="form-check form-switch">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id={item.idmainproduct}
                      onChange={(e) => {
                        setProductCheck(item, e.target.checked);
                      }}
                    />
                    <label
                      className="form-check-label"
                      htmlFor={item.idmainproduct}
                    >
                      {item.namemainproduct}
                    </label>
                  </div>
                </Col>
              </Row>
            ))}

          {productsError && (
            <Row style={{ color: "red" }}>
              <p>{productsError}</p>
            </Row>
          )}

          <Row
            style={{
              width: "100%",
              margin: "auto",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            {loading ? (
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            ) : (
              <>
                <Button
                  color="danger"
                  style={{ width: 80, marginRight: 10 }}
                  onClick={() => toggle()}
                >
                  Fechar
                </Button>

                <ButtonSpinner
                  isLoading={isLoading}
                  title={"Salvar"}
                  onClick={submitForm}
                />
              </>
            )}
          </Row>
        </form>
      </ModalBody>
    </Modal>
  );
}

const mapStateToProps = function (state) {
  return { state: state };
};

export default connect(mapStateToProps)(NewEvent);
