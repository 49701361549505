import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Col,
  Row,
  Button,
  Spinner,
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import ColorPicker from "../../../components/ColorPicker/ColorPicker";
import ButtonSpinner from "../../../components/Buttons/ButtonSpinner";
import moment from "moment";
import {
  fetchConfig,
  saveConfig,
} from "../../../utils/services/schedulingAndStock";

export default function ModalConfig(props) {
  const { isOpen, toggle, idEvent, jwt } = props;

  const [successCreateAlert, setSuccessCreateAlert] = useState(false);
  const [successUpdateAlert, setSuccessUpdateAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isConfigLoading, setIsConfigLoading] = useState(true);

  const [colorBackground, setColorBackground] = useState("#FFFFFF");
  const [colorSelect, setColorSelect] = useState("#FFFFFF");
  const [idConfig, setIdConfig] = useState("");

  const [rules, setRules] = useState({
    onePerRoom: false,
    onePerDate: false,
    noLimit: false,
    limitByDate: false,
    allowCancel: false,
  });

  const [dateTime, setDateTime] = useState({
    startDateTime: null,
    endDateTime: null,
  });

  const [errors, setErrors] = useState({
    colorBackground: "",
    colorSelect: "",
    regra: "",
    startDateTime: "",
    endDateTime: "",
  });

  const formatDateToInput = (date) => {
    return moment(date).add(0, "hours").format("YYYY-MM-DDTHH:mm");
  };

  const loadConfig = async () => {
    setIsConfigLoading(true);
    try {
      const data = await fetchConfig(idEvent, jwt);

      if (data) {
        const config = data.data[0];
        setIdConfig(config?.id);
        setColorBackground(config.backgroundcolor || "#FFFFFF");
        setColorSelect(config.buttoncolor || "#FFFFFF");
        setRules({
          onePerRoom: config?.regra === "onePerRoom",
          onePerDate: config?.regra === "onePerDate",
          noLimit: config?.regra === "noLimit",
          limitByDate: config?.limitbydate,
          allowCancel: config?.allowdeleteappointment,
        });
        setDateTime({
          startDateTime: formatDateToInput(config?.startdate) || null,
          endDateTime: formatDateToInput(config?.enddate) || null,
        });
        setIsEditing(true);
      }
    } catch (error) {
      // console.error("Erro ao buscar config:", error);
    } finally {
      setIsConfigLoading(false);
    }
  };

  useEffect(() => {
    loadConfig();
  }, []);

  const validateFields = () => {
    const newErrors = {
      colorBackground: colorBackground ? "" : "Cor de fundo é obrigatória.",
      colorSelect: colorSelect ? "" : "Cor dos botões é obrigatória.",
      regra:
        !rules.onePerRoom && !rules.onePerDate && !rules.noLimit
          ? "Selecione uma opção do campo Regra"
          : "",
      startDateTime:
        rules.limitByDate &&
        (!dateTime.startDateTime || dateTime.startDateTime === "Data inválida")
          ? "Data e Hora de Início são obrigatórias."
          : "",
      endDateTime:
        rules.limitByDate &&
        (!dateTime.endDateTime || dateTime.endDateTime === "Data inválida")
          ? "Data e Hora de Fim são obrigatórias."
          : "",
    };

    setErrors(newErrors);
    return Object.values(newErrors).every((error) => error === "");
  };

  const handleSaveConfig = async () => {
    // setIsLoading(true);

    if (!validateFields()) {
      setIsLoading(false);
      return;
    }

    const startDate =
      dateTime.startDateTime === "Data inválida"
        ? null
        : dateTime.startDateTime;

    const endDate =
      dateTime.endDateTime === "Data inválida" ? null : dateTime.endDateTime;

    const payload = {
      eventId: idEvent,
      backgroundColor: colorBackground,
      buttonColor: colorSelect,
      regra: rules.onePerRoom
        ? "onePerRoom"
        : rules.onePerDate
        ? "onePerDate"
        : rules.noLimit
        ? "noLimit"
        : "",
      allowDeleteAppointment: rules.allowCancel,
      limitByDate: rules.limitByDate,
      startDate: startDate || null,
      endDate: endDate || null,
    };

    try {
      const response = await saveConfig(idConfig, jwt, payload, isEditing);
      if (response.status === 200 || response.status === 201) {
        if (isEditing) {
          setSuccessUpdateAlert(true);
        } else {
          setSuccessCreateAlert(true);
        }
      }
    } catch (error) {
      // console.error("Erro ao salvar config:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCheckboxChange = (e) => {
    const { name } = e.target;

    setRules((prevRules) => ({
      ...prevRules,
      onePerRoom: name === "Room" ? !prevRules.onePerRoom : false,
      onePerDate: name === "Date" ? !prevRules.onePerDate : false,
      noLimit: name === "NoLimit" ? !prevRules.noLimit : false,
    }));
  };

  const handleSelectChange = (e) => {
    const { name, value } = e.target;

    setRules((prevRules) => ({
      ...prevRules,
      [name]: value === "sim",
    }));

    if (name === "limitByDate" && value === "nao") {
      setDateTime({
        startDateTime: null,
        endDateTime: null,
      });
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setDateTime((prevDateTime) => ({
      ...prevDateTime,
      [name]: value,
    }));
  };

  return (
    <>
      <Modal isOpen={isOpen} centered autoFocus>
        {successCreateAlert && (
          <SweetAlert
            success
            title={"Criado"}
            onConfirm={() => {
              setSuccessCreateAlert(false);
              toggle();
            }}
          >
            Configurações criadas com sucesso
          </SweetAlert>
        )}

        {successUpdateAlert && (
          <SweetAlert
            success
            title={"Editado"}
            onConfirm={() => {
              setSuccessUpdateAlert(false);
              toggle();
            }}
          >
            Configurações editadas com sucesso
          </SweetAlert>
        )}

        <ModalHeader toggle={toggle}>Configurações do Agendamento</ModalHeader>

        <ModalBody>
          {isConfigLoading ? (
            <div className="d-flex justify-content-center align-items-center">
              <Spinner color="primary" />
              <span style={{ marginLeft: "10px" }}>Carregando...</span>
            </div>
          ) : (
            <>
              <Row>
                <Col xs="6" style={{ marginBottom: "10px" }}>
                  <ColorPicker
                    classOfContainer="primary-card-pickr"
                    setStateFunc={setColorBackground}
                    defaultColor={colorBackground}
                    labelText="Cor de Fundo"
                    tip="Cor que será utilizada no background do agendamento."
                    idDiv="corPrimaria"
                  />
                  {errors.colorBackground && (
                    <div className="text-danger">{errors.colorBackground}</div>
                  )}
                </Col>

                <Col xs="12" style={{ marginBottom: "20px" }}>
                  <ColorPicker
                    classOfContainer="secundary-card-pickr"
                    setStateFunc={setColorSelect}
                    defaultColor={colorSelect}
                    labelText="Cor dos Botões"
                    tip="Cor que será utilizada nos botões do agendamento."
                    idDiv="corSecundaria"
                  />
                  {errors.colorSelect && (
                    <div className="text-danger">{errors.colorSelect}</div>
                  )}
                </Col>
              </Row>

              <Row>
                <Col xs="12">
                  <label className="form-label">Regra*:</label>
                </Col>

                <Col xs="12">
                  <input
                    type="checkbox"
                    name="Room"
                    className="form-check-input"
                    checked={rules.onePerRoom}
                    onChange={handleCheckboxChange}
                    id="checkPerRoom"
                  />
                  <label
                    className="form-label text-sm"
                    style={{ marginLeft: 10 }}
                    htmlFor="checkPerRoom"
                  >
                    Permitir apenas 1 agendamento por sala
                  </label>
                </Col>

                <Col xs="12">
                  <input
                    type="checkbox"
                    name="Date"
                    className="form-check-input"
                    checked={rules.onePerDate}
                    onChange={handleCheckboxChange}
                    id="checkPerDate"
                  />
                  <label
                    className="form-label text-sm"
                    style={{ marginLeft: 10 }}
                    htmlFor="checkPerDate"
                  >
                    Permitir apenas 1 agendamento por data
                  </label>
                </Col>

                <Col xs="12">
                  <input
                    type="checkbox"
                    name="NoLimit"
                    className="form-check-input"
                    checked={rules.noLimit}
                    onChange={handleCheckboxChange}
                    id="checkNoRule"
                  />
                  <label
                    className="form-label text-sm"
                    style={{ marginLeft: 10 }}
                    htmlFor="checkNoRule"
                  >
                    Não limitar os agendamentos
                  </label>
                </Col>

                {errors.regra && <a style={{ color: "red" }}>{errors.regra}</a>}

                <Col xs="12" style={{ marginTop: "10px" }}>
                  <label className="form-label">
                    Limitar Agendamento por Data?
                  </label>
                  <select
                    className="form-control"
                    name="limitByDate"
                    value={rules.limitByDate ? "sim" : "nao"}
                    onChange={handleSelectChange}
                  >
                    <option value="nao">Não</option>
                    <option value="sim">Sim</option>
                  </select>
                </Col>

                {rules.limitByDate && (
                  <>
                    <Col md={6} style={{ marginTop: "10px" }}>
                      <label className="form-label">Data e Hora Início*</label>
                      <input
                        className="form-control"
                        type="datetime-local"
                        name="startDateTime"
                        value={dateTime.startDateTime}
                        onChange={handleInputChange}
                      />
                      {errors.startDateTime && (
                        <div className="text-danger">
                          {errors.startDateTime}
                        </div>
                      )}
                    </Col>

                    <Col md={6} style={{ marginTop: "10px" }}>
                      <label className="form-label">Data e Hora Fim*</label>
                      <input
                        className="form-control"
                        type="datetime-local"
                        name="endDateTime"
                        value={dateTime.endDateTime}
                        onChange={handleInputChange}
                      />
                      {errors.endDateTime && (
                        <div className="text-danger">{errors.endDateTime}</div>
                      )}
                    </Col>
                  </>
                )}
              </Row>

              <Row>
                <Col md={12} style={{ marginTop: "10px" }}>
                  <label className="form-label">
                    Permitir que o usuário exclua o agendamento?
                  </label>
                  <select
                    className="form-control"
                    name="allowCancel"
                    value={rules.allowCancel ? "sim" : "nao"}
                    onChange={handleSelectChange}
                  >
                    <option value="nao">Não</option>
                    <option value="sim">Sim</option>
                  </select>
                </Col>
              </Row>

              <Row
                style={{
                  width: "100%",
                  marginTop: 15,
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  color="success"
                  className="btn btn-danger waves-effect waves-light"
                  style={{ width: 80, marginRight: 5 }}
                  onClick={() => toggle()}
                >
                  Fechar
                </Button>
                <ButtonSpinner
                  isLoading={isLoading}
                  title={"Salvar"}
                  onClick={handleSaveConfig}
                />
              </Row>
            </>
          )}
        </ModalBody>
      </Modal>
    </>
  );
}
