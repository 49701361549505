import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardTitle,
  Collapse,
  Table,
  Row,
  Button,
} from "reactstrap";
//service
import { getReportFunctions } from "../../../utils/services/functions";
//redux
import { connect } from "react-redux";

import { useLocation } from "react-router-dom";
//components
import SectionComponent from "../../Apps/Personalization/SectionComponent";
import Pagination from "../../../components/Paginationv3";
import WaitExport from "../../../components/WaitExport";
import { createXlsx } from "./createXlsx";
import useDebounce from "../../../utils/hooks/useDebounce";
import SearchFilters from "../../../components/SearchFilters";

import moment from "moment";
function ReportFunctions(props) {
  // collapse
  const [isCollapse, setIsCollapse] = useState(false);
  //Pagination
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  //search
  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounce(search, 300);

  //state report functions
  const [reportFunctions, setReportFunctions] = useState([]);
  // export
  const [exportInProgress, setExportInProgress] = useState(false);
  const [finishExport, setFinishExport] = useState(false);

  const location = useLocation();
  const [collapseIsOpen, setCollapseIsOpen] = useState(false);
  const [activeFilter, setActiveFilter] = useState("name");

  const columnsFilter = [
    { name: "Participante", value: "name", type: "text" },
    { name: "Email", value: "email", type: "text" },
    { name: "Função", value: "function", type: "text" },
    { name: "Data", value: "data", type: "date" },
  ];

  function setActiveFilterHandle(columnActive) {
    setActiveFilter(columnActive);
    setSearch("");
  }
  const reverseDate = () => {
    const reverse = debouncedSearch
      .split("/")
      .reverse()
      .join("/")
      .replace(/\//g, "-");
    return reverse;
  };
  function treatmentSearch() {
    if (
      columnsFilter.filter((item) => item.value === activeFilter)[0].type ===
      "date"
    ) {
      return reverseDate();
    }
    return debouncedSearch;
  }
  //date format
  const formatDate = (date) => {
    return `${moment(date).format("l")} - ${date.split("T")[1].slice(0, 8)}`;
  };

  async function getAllReportFunctions() {
    await getReportFunctions({
      limit: itemsPerPage,
      offset: currentPage > 0 ? currentPage : 1,
      id: location.state.idEvent,
      jwt: props.state.global_user_data.data.token,
      search: treatmentSearch(),
      columnSearch: activeFilter,
    }).then((response) => {
      if (response.functions) {
        setReportFunctions(response.functions);
        setTotalItems(response.total);
      }
    });
  }

  useEffect(() => {
    getAllReportFunctions();
  }, [debouncedSearch, currentPage, itemsPerPage]);
  return (
    <div>
      {exportInProgress && (
        <WaitExport
          isOpen={exportInProgress}
          preposicao="do"
          name="Relatório de Funções"
          finishExport={finishExport}
          setFinishExport={setFinishExport}
          setExportInProgress={setExportInProgress}
        />
      )}

      <Card>
        <CardBody>
          <CardTitle>Relatório por Participantes</CardTitle>
          <div>
            <Row
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: 10,
                width: "50px",
              }}
            >
              <SectionComponent
                sectionName="Filtros"
                setIsOpen={setCollapseIsOpen}
                isOpen={collapseIsOpen}
              />
            </Row>
            <Collapse isOpen={collapseIsOpen}>
              <SearchFilters
                columns={columnsFilter}
                activeFilter={activeFilter}
                setActiveFilterHandle={setActiveFilterHandle}
              />
            </Collapse>
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <form
              className="app-search d-none d-lg-block events-input-search"
              onSubmit={(e) => e.preventDefault()}
            >
              <div className="position-relative">
                <input
                  type={activeFilter === "data" ? "date" : "text"}
                  value={search}
                  className="form-control"
                  placeholder="Pesquisar..."
                  onChange={(e) => setSearch(e.target.value)}
                />
                <span className="bx bx-search-alt" />
              </div>
            </form>
            {reportFunctions.length > 0 && (
              <div className="mobile-btn-section" style={{ width: "100%" }}>
                <Row className="mobile-btns-top">
                  <form
                    className="app-search"
                    style={{ padding: "0px" }}
                    onSubmit={(e) => e.preventDefault()}
                  >
                    <div className="position-relative">
                      <input
                        type={activeFilter === "data" ? "date" : "text"}
                        value={search}
                        className="form-control"
                        placeholder="Pesquisar..."
                        onChange={(e) => setSearch(e.target.value)}
                      />
                      <span className="bx bx-search-alt" />
                    </div>
                  </form>
                </Row>
                <SectionComponent
                  sectionName="Opções"
                  setIsOpen={setIsCollapse}
                  isOpen={isCollapse}
                />
                <Collapse isOpen={isCollapse}>
                  <Row className="mobile-btns-top">
                    <div>Exportar Relatório</div>
                  </Row>
                </Collapse>
              </div>
            )}
            <div className="header-btns-container">
              {reportFunctions.length > 0 && (
                <Button
                  color="success"
                  className="btn-rounded waves-effect waves-light events-button-add"
                  onClick={() => {
                    createXlsx({
                      idEvent: location.state.idEvent,
                      countData: totalItems,
                      setFinishExport,
                      jwt: props.state.global_user_data.data.token,
                    });
                    setExportInProgress(true);
                  }}
                >
                  Exportar Relatório
                </Button>
              )}
            </div>
          </div>
          <div className="table-responsive">
            <Table className="table mb-0">
              <thead className="table-light">
                <tr>
                  <th>Nome do Participante</th>
                  <th>Email do Participante</th>
                  <th>Nome da Função</th>
                  <th>Data e Hora da Execução</th>
                </tr>
              </thead>
              <tbody>
                {reportFunctions.length > 0 &&
                  reportFunctions.map((func, i) => (
                    <tr>
                      <td>{func.login.nome}</td>
                      <td>{func.login.email}</td>
                      <td>{func.function.description}</td>
                      <td>{formatDate(func.calledAt)}</td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
        </CardBody>
      </Card>
      <div className="bottom-navigation" style={{ justifyContent: "end" }}>
        {/* 
        Fornecer os 4 parametros obrigatórios;
        ** data - a array contendo as informações da tabela
        ** search - o state do termo de pesquisa
        ** currentIndex - posição atual do index
        ** setCurrentIndex - função para atualizar a posição atual do index
        */}
        <Pagination
          data={reportFunctions}
          search={search}
          currentIndex={currentPage}
          setCurrentIndex={setCurrentPage}
          countData={totalItems}
          maxItemPerPage={itemsPerPage}
          setMaxItemPerPage={setItemsPerPage}
          maxPageButtonsToShow={3}
        />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return { state };
};

export default connect(mapStateToProps)(ReportFunctions);
