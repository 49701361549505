import React from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";

export default function ViewCode(props) {
  const { isOpen, toggle } = props;
  return (
    <Modal isOpen={isOpen} autoFocus centered>
      <ModalHeader toggle={toggle}>Visualizar código</ModalHeader>
      <ModalBody></ModalBody>
    </Modal>
  );
}
