import React, { useState, useEffect } from "react";
import {
  Table,
  Collapse,
  Card,
  CardBody,
  CardTitle,
  Button,
  Row,
} from "reactstrap";
import SectionComponent from "../../Apps/Personalization/SectionComponent";
import SweetAlert from "react-bootstrap-sweetalert";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import Pagination from "../../../components/Paginationv3";
import ModalNewVehicle from "./ModalNewVehicle";
import ModalEditVehicle from "./ModalEditVehicle";
import ModalDuplicateVehicle from "./ModalDuplicateVehicle";
import ModalImportVehicle from "./ModalImportVehicle";
import { createVeiculoXlsx } from "./createVeiculoXlsx";
import useDebounce from "../../../utils/hooks/useDebounce";
import SearchFilters from "../../../components/SearchFilters";
import WaitExport from "../../../components/WaitExport";

import {
  deleteVehicle,
  getAllVehicles,
} from "../../../utils/services/vehicles";

function Vehicle(props) {
  const location = useLocation();
  const [search, setSearch] = useState("");
  const [modalNewVehicle, setModalNewVehicle] = useState(false);
  const [modalEditVehicle, setModalEditVehicle] = useState(false);
  const [modalSurvey, setModalSurvey] = useState(false);
  const [modalSuperTela3, setModalSuperTela3] = useState(false);
  const [deleteData, setDeleteData] = useState({});
  const [confirm_alert, setconfirm_alert] = useState(false);
  const [success_dlg, setsuccess_dlg] = useState(false);

  const [editData, setEditData] = useState({});
  const [duplicateData, setDuplicateData] = useState({});
  const [exportInProgress, setExportInProgress] = useState(false);
  const [finishExport, setFinishExport] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [countData, setCountData] = useState(0);
  const debouncedSearch = useDebounce(search, 300);

  const [vehicles, setVehicles] = useState([]);

  const [collapseIsOpen, setCollapseIsOpen] = useState(false);
  const [btnsAvailable, setBtnsAvailable] = useState([]);
  const [activeFilter, setActiveFilter] = useState("description");
  const [isOpen, setIsOpen] = useState(false);
  const [idDashboard, setIdDashboard] = useState();
  const [isOpenDuplicate, setIsOpenDuplicate] = useState(false);
  const [modalImportVeiculo, setModalImportVeiculo] = useState(false);
  const columnsFilter = [
    { name: "Nome", value: "description", type: "text" },
    { name: "Origem", value: "origin", type: "text" },
    { name: "Destino", value: "destination", type: "text" },
    { name: "Data Saída", value: "departureDateTime", type: "date" },
    { name: "Data Chegada", value: "arrivalDateTime", type: "date" },
  ];

  function setActiveFilterHandle(columnActive) {
    setActiveFilter(columnActive);
    setSearch("");
  }

  function toggleNewVehicle() {
    setModalNewVehicle(!modalNewVehicle);
    if (modalNewVehicle) {
      getAll();
    }
  }

  function toggleEditVehicle() {
    setModalEditVehicle(!modalEditVehicle);
    if (modalEditVehicle) {
      getAll();
    }
  }
  function toggleDuplicateVehicle() {
    setIsOpenDuplicate(!isOpenDuplicate);
    if (isOpenDuplicate) {
      getAll();
    }
  }

  async function delVehicle() {
    if (deleteData?.id) {
      await deleteVehicle(deleteData.id).then((res) => {
        if (res.message === "deleted") {
          setconfirm_alert(false);
          setsuccess_dlg(true);
        }
      });
    }
  }
  const reverseDate = () => {
    const reverse = debouncedSearch
      .split("/")
      .reverse()
      .join("/")
      .replace(/\//g, "-");
    return reverse;
  };
  function treatmentSearch() {
    if (
      columnsFilter.filter((item) => item.value === activeFilter)[0].type ===
      "date"
    ) {
      return reverseDate();
    }
    return debouncedSearch;
  }
  function getAll() {
    getAllVehicles(
      location.state.idEvent,
      currentIndex,
      itemsPerPage,
      treatmentSearch(),
      activeFilter
    ).then((res) => {
      if (res.data.message === "FOUND") {
        setVehicles(res.data.findAll.data);
        setCountData(res.data.findAll.count);
      }
    });
  }

  useEffect(() => {
    getAll();
  }, [currentIndex, itemsPerPage, debouncedSearch]);

  const formatDate = (date) => {
    if (!date) return "";
    const formatedDate = date.split("T")[0].split("-").reverse().join("-");
    if (date.split("T")[0].includes("1969")) return "";
    const time = date.split("T")[1].substring(0, 5);
    return `${formatedDate.replaceAll("-", "/")} ${time}`;
  };

  return (
    <>
      {modalNewVehicle && (
        <ModalNewVehicle
          isOpen={modalNewVehicle}
          toggle={toggleNewVehicle}
          idEvent={location.state.idEvent}
          getAllVehicles={getAll}
        />
      )}
      {modalEditVehicle && (
        <ModalEditVehicle
          isOpen={modalEditVehicle}
          toggle={toggleEditVehicle}
          idEvent={location.state.idEvent}
          data={editData}
          getAllVehicles={getAll}
        />
      )}
      {modalImportVeiculo && (
        <ModalImportVehicle
          isOpen={modalImportVeiculo}
          toggle={() => setModalImportVeiculo(!modalImportVeiculo)}
          jwt={props.state.global_user_data.data.token}
          eventId={location.state.idEvent}
          getAllVehicles={getAll}
        />
      )}

      {isOpenDuplicate && (
        <ModalDuplicateVehicle
          isOpen={isOpenDuplicate}
          toggle={toggleDuplicateVehicle}
          idEvent={location.state.idEvent}
          data={duplicateData}
          getAllVehicles={getAll}
        />
      )}

      {confirm_alert ? (
        <SweetAlert
          title={`Deseja deletar o Veículo ${deleteData.description}?`}
          warning
          showCancel
          confirmBtnText="Sim"
          cancelBtnText="Não"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            delVehicle();
          }}
          onCancel={() => setconfirm_alert(false)}
        >
          Você não poderá reverter essa ação!
        </SweetAlert>
      ) : null}

      {success_dlg ? (
        <SweetAlert
          success
          title="Deletado"
          onConfirm={() => {
            getAll();
            setsuccess_dlg(false);
          }}
        >
          Veículo Deletado com sucesso
        </SweetAlert>
      ) : null}
      <WaitExport
        isOpen={exportInProgress}
        name="Veículos"
        preposicao="dos"
        finishExport={finishExport}
        setFinishExport={setFinishExport}
        setExportInProgress={setExportInProgress}
      />
      <Card>
        <CardBody>
          <CardTitle>Veículos</CardTitle>
          <div>
            <Row
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: 10,
                width: "50px",
              }}
            >
              <SectionComponent
                sectionName="Filtros"
                setIsOpen={setCollapseIsOpen}
                isOpen={collapseIsOpen}
              />
            </Row>
            <Collapse isOpen={collapseIsOpen}>
              <SearchFilters
                columns={columnsFilter}
                activeFilter={activeFilter}
                setActiveFilterHandle={setActiveFilterHandle}
              />
            </Collapse>
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              margin: "10px 0",
            }}
          >
            <form
              className="app-search d-none d-lg-block events-input-search"
              onSubmit={(e) => e.preventDefault()}
            >
              <div className="position-relative">
                <input
                  type={
                    activeFilter === "departureDateTime" ||
                    activeFilter === "arrivalDateTime"
                      ? "date"
                      : "text"
                  }
                  value={search}
                  className="form-control"
                  placeholder="Pesquisar..."
                  onChange={(e) => setSearch(e.target.value)}
                />
                <span className="bx bx-search-alt" />
              </div>
            </form>

            <div className="mobile-btn-section" style={{ width: "100%" }}>
              <Row className="mobile-btns-top">
                <form
                  className="app-search"
                  style={{ padding: "0px" }}
                  onSubmit={(e) => e.preventDefault()}
                >
                  <div className="position-relative">
                    <input
                      type={
                        activeFilter === "departureDateTime" ||
                        activeFilter === "arrivalDateTime"
                          ? "date"
                          : "text"
                      }
                      value={search}
                      className="form-control"
                      placeholder="Pesquisar..."
                      onChange={(e) => setSearch(e.target.value)}
                    />
                    <span className="bx bx-search-alt" />
                  </div>
                </form>
              </Row>
              <SectionComponent
                sectionName="Opções"
                setIsOpen={setIsOpen}
                isOpen={isOpen}
              />
              <Collapse isOpen={isOpen}>
                <Row className="mobile-btns-top">
                  <div
                    onClick={() =>
                      createVeiculoXlsx({
                        jwt: props.state.global_user_data.data.token,
                        idEvent: location.state.idEvent,
                        limit: 100,
                        countData,
                        setFinishExport,
                      }) > setExportInProgress(true)
                    }
                  >
                    Exportar Veículo
                  </div>
                </Row>
                <Row className="mobile-btns-top">
                  <div onClick={() => setModalImportVeiculo(true)}>
                    Importar Veículos
                  </div>
                </Row>
                <Row className="mobile-btns-top">
                  <div onClick={() => setModalNewVehicle(true)}>
                    Adicionar Veículo
                  </div>
                </Row>
              </Collapse>
            </div>

            <div className="header-btns-container">
              {vehicles?.length > 0 && (
                <Button
                  color="success"
                  className="btn-rounded waves-effect waves-light events-button-add"
                  onClick={() =>
                    createVeiculoXlsx({
                      jwt: props.state.global_user_data.data.token,
                      idEvent: location.state.idEvent,
                      limit: 100,
                      countData,
                      setFinishExport,
                    }) > setExportInProgress(true)
                  }
                >
                  Exportar Veículo
                </Button>
              )}
              <Button
                onClick={() => setModalImportVeiculo(true)}
                color="success"
                className="btn-rounded waves-effect waves-light events-button-add"
              >
                Importar Veículos
              </Button>
              <Button
                color="success"
                className="btn-rounded waves-effect waves-light events-button-add"
                onClick={() => setModalNewVehicle(true)}
              >
                Adicionar Veículo
              </Button>
            </div>
          </div>

          <div className="table-responsive">
            <Table className="table mb-0">
              <thead className="table-light">
                <tr>
                  {/* <th>ID</th> */}
                  <th style={{ width: "20%" }}>Nome</th>
                  <th style={{ width: "15%" }}>Origem</th>
                  <th style={{ width: "15%" }}>Destino</th>
                  <th style={{ width: "20%" }}>Data Saída</th>
                  <th style={{ width: "20%" }}>Data Chegada</th>
                  <th style={{ width: "5%" }}>Utilizado/Capacidade</th>
                  <th style={{ width: "15%" }}>Ações</th>
                </tr>
              </thead>
              <tbody>
                {vehicles.length > 0 &&
                  vehicles.map((e, i) => (
                    <tr key={i}>
                      {/* <td>{e.id}</td> */}
                      <td>{e.description}</td>
                      <td>{e.origin}</td>
                      <td>{e.destination}</td>
                      <td>{formatDate(e.departureDatetime)}</td>
                      <td>{formatDate(e.arrivalDatetime)}</td>
                      <td style={{ textAlign: "center" }}>
                        <span
                          className="tagDriver"
                          style={{
                            whiteSpace: "nowrap",
                            backgroundColor: "red",
                          }}
                        >
                          {e.amount}
                        </span>
                        de{" "}
                        <span
                          className="tagDriver"
                          style={{
                            whiteSpace: "nowrap",
                            backgroundColor: "green",
                          }}
                        >
                          {e.seatingCapacity}
                        </span>
                      </td>
                      <td>
                        <i
                          className="mdi mdi-pencil"
                          style={{
                            color:
                              e.status === "Finalizado" ||
                              e.status === "Cancelado"
                                ? "lightgray"
                                : "green",
                            fontSize: 17,
                            cursor:
                              e.status === "Finalizado" ||
                              e.status === "Cancelado"
                                ? "auto"
                                : "pointer",
                          }}
                          onClick={() => {
                            if (
                              e.status === "Finalizado" ||
                              e.status === "Cancelado"
                            ) {
                              return;
                            }
                            setEditData(e);
                            setModalEditVehicle(true);
                          }}
                          title="Editar"
                        />
                        <i
                          title="Duplicar Veículo"
                          className="mdi mdi-content-duplicate"
                          onClick={() => {
                            setDuplicateData(e);
                            setIsOpenDuplicate(true);
                          }}
                          style={{
                            color: "gray",
                            fontSize: 17,
                            cursor: "pointer",
                          }}
                        />{" "}
                        <i
                          className="mdi mdi-trash-can"
                          style={{
                            color:
                              e.status !== "Não Iniciado" ? "lightgray" : "red",
                            fontSize: 17,
                            cursor:
                              e.status !== "Não Iniciado" ? "auto " : "pointer",
                          }}
                          title="Excluir"
                          onClick={() => {
                            if (e.status !== "Não Iniciado") {
                              return;
                            }
                            setDeleteData(e);
                            setconfirm_alert(true);
                          }}
                        />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
        </CardBody>
      </Card>
      <div className="bottom-navigation" style={{ justifyContent: "flex-end" }}>
        <Pagination
          data={vehicles}
          search={search}
          currentIndex={currentIndex}
          setCurrentIndex={setCurrentIndex}
          countData={countData}
          maxItemPerPage={itemsPerPage}
          setMaxItemPerPage={setItemsPerPage}
          maxPageButtonsToShow={3}
        />
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return { state };
};

export default connect(mapStateToProps)(Vehicle);
