import React from 'react';
import ReactEcharts from 'echarts-for-react';

export default function DonutChart({
  data = [],
  title,
}) {
  const getOptions = () => ({
    title: {
      text: title,
      x: 'left',
      textStyle: {
        color: 'black',
      },
    },
    tooltip: {
      trigger: 'item',
      formatter: '{b}: {c} ({d}%)',
    },
    legend: {
      orient: 'vertical',
      left: '1.5%',
      top: '10%',
      padding: [10, 0, 0, 0],
      itemGap: 5,

      data: data?.map((item) => item.x),
      formatter: function (name) {
        const item = data.find((item) => item.x === name);
        return `${name} (${item.y})`;
      },
    },
    series: [
      {
        name: 'Cliques',
        type: 'pie',
        top: '-20%',
        radius: ['45%', '60%'],
        center: ['70%', '65%'],
        height: '80%',
        data: data.map((item) => ({ name: item.x, value: item.y })),

        label: {
          show: false,
          position: 'center',
        },
        emphasis: {
          label: {
            show: true,
            fontSize: '1.5em',
            fontWeight: 'bold',
          },
        },
        labelLine: {
          show: false,
        },
        itemStyle: {
          emphasis: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)',
          },
        },
      },
    ],
  });
  return (
    <ReactEcharts option={getOptions()} style={{ height: 384 }} />
  );
}