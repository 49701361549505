import React, { useState, useEffect } from "react";
import { Modal, ModalHeader, ModalBody, Row, Col, Button } from "reactstrap";
//redux
import { connect } from "react-redux";
// alerta
import SweetAlert from "react-bootstrap-sweetalert";
import {
  getProducts,
  createScreenProducts,
} from "../../../utils/services/products";
import ButtonSpinner from "../../../components/Buttons/ButtonSpinner";

function ModalNewScreen(props) {
  const { isOpen, toggle } = props;
  const [isLoading, setIsloading] = useState(false);

  // erro
  const [erroProduct, setErroProduct] = useState("");
  const [erroScreen, setErroScreen] = useState("");
  const [erroShortName, setErroShortName] = useState("");
  // alerta
  const [successAlert, setSuccessAlert] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const [errorDuplicateShortName, setErrorDuplicateShortName] = useState(false);

  const [optionsProducts, setOptionsProducts] = useState([]);

  function verifyErros(e) {
    if (e.target[0].value === "Selecione") {
      setErroProduct("Esse campo é obrigatório");
      return;
    } else setErroProduct("");

    if (!e.target[1].value) {
      setErroScreen("Esse campo é obrigatório");
      return;
    } else setErroScreen("");

    if (!e.target[2].value) {
      setErroShortName("Esse campo é obrigatório");
      return;
    } else setErroShortName("");

    submitForm(e);
  }

  function removerAcentos(str) {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  }

  function submitForm(e) {
    const data = {
      name: e.target[1].value,
      mainProductId: e.target[0].value,
      screenName: e.target[1].value,
      shortName: removerAcentos(e.target[2].value).toUpperCase(),
    };
    setIsloading(true);
    createScreenProducts({
      jwt: props.state.global_user_data.data.token,
      data: data,
    })
      .then((res) => {
        setIsloading(false);
        if (res.message === "PRODUCT_CREATED") {
          props.getAllProducts();
          setSuccessAlert(true);
        }

        if (res.message === "SCREEN_ALREADY_EXISTS_IN_PRODUCT") {
          setErrorDuplicateShortName(true);
          setErrorAlert(true);
        }
      })
      .catch((error) => {
        setIsloading(false);
        console.log("erro", error);
      });
  }

  function getAllProducts() {
    getProducts({
      jwt: props.state.global_user_data.data.token,
      offset: 1,
      limit: 200,
      mainProduct: true,
      columnSearch: "name",
      search: "",
    }).then((res) => {
      if (res.message === "PRODUCTS") {
        setOptionsProducts(
          res.data.products //.filter((e) => e.mainProduct === null)
        );
      }
    });
  }

  useEffect(() => {
    getAllProducts();
  }, []);

  return (
    <>
      {successAlert ? (
        <SweetAlert
          success
          title="Criado"
          onConfirm={() => {
            toggle();
            setSuccessAlert(false);
          }}
        >
          Tela criada com sucesso
        </SweetAlert>
      ) : null}

      {errorAlert ? (
        <SweetAlert
          title={`Não foi possível criar a tela`}
          error
          showCancel
          cancelBtnText="Fechar"
          showConfirm={false}
          cancelBtnBsStyle="danger"
          onCancel={() => {
            setErrorAlert(false);
          }}
        >
          {errorDuplicateShortName
            ? "Nome para governança já existe."
            : "Ocorreu um erro."}
        </SweetAlert>
      ) : null}

      <Modal isOpen={isOpen} centered autoFocus>
        <ModalHeader toggle={toggle}>Nova Tela</ModalHeader>
        <ModalBody>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              verifyErros(e);
            }}
          >
            <Row>
              <Col md={12}>
                <label className="form-label">Produto (Sistema) *</label>
                <select className="form-control">
                  <option>Selecione</option>
                  {optionsProducts.length > 0 &&
                    optionsProducts.map((e, i) => (
                      <option
                        key={i}
                        value={e.mainProduct ? e.mainProduct.id : e.id}
                      >
                        {e.mainProduct ? e.mainProduct.name : e.name}
                      </option>
                    ))}
                </select>
                {erroProduct && <p style={{ color: "red" }}>{erroProduct}</p>}
              </Col>
            </Row>

            <Row style={{ marginTop: 10 }}>
              <Col md={12}>
                <label className="form-label">Tela *</label>
                <input className="form-control" type="text" />
                {erroScreen && <p style={{ color: "red" }}>{erroScreen}</p>}
              </Col>
            </Row>
            <Row style={{ marginTop: 10 }}>
              <Col md={12}>
                <label className="form-label">Nome para Governança *</label>
                <input className="form-control" type="text" />
                {erroShortName && (
                  <p style={{ color: "red" }}>{erroShortName}</p>
                )}
              </Col>
            </Row>

            <Row
              style={{
                width: "100%",
                margin: "auto",
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "1rem",
              }}
            >
              <Button
                color="danger"
                style={{ width: 80, marginRight: 10 }}
                onClick={() => toggle()}
              >
                Fechar
              </Button>

              <ButtonSpinner isLoading={isLoading} title={"Salvar"} />
            </Row>
          </form>
        </ModalBody>
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => {
  return { state };
};

export default connect(mapStateToProps)(ModalNewScreen);
