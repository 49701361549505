import moment from "moment";

export const excelColumnsPtBr = {
  Sala: "room",
  Atividade: "task",
  "Data e Hora Inicio": "initialDate",
  "Data e Hora Fim": "endDate",
  "Quantidade Máxima": "maxQuantity",
};

export function allExcelColumns(row, index) {
  const formatedRow = {
    index,
    room: row.room ? row.room : undefined,
    task: row.task ? row.task : undefined,
    initialDate:
      row.initialDate && row.initialDate != "Invalid Date"
        ? row.initialDate
        : undefined,
    endDate:
      row.endDate && row.endDate != "Invalid Date" ? row.endDate : undefined,
    maxQuantity: row.maxQuantity ? row.maxQuantity : undefined,
  };

  return formatedRow;
}

export async function findRowsWithError(validRows) {
  const rowsWithError = validRows.filter(
    (row) =>
      row.room === undefined ||
      row.task === undefined ||
      row.initialDate === undefined ||
      row.endDate === undefined ||
      row.maxQuantity === undefined
  );

  return rowsWithError;
}

export async function validateDuplicity(rows) {
  if (rows.length < 2) return [];

  var allRowsDuplicity = [];

  for (let i = 0; i < rows.length; ++i) {
    const row = rows[i];

    rows.map((e) => {
      // se for a mesma linha do for, é ignorada
      if (row.index === e.index) return;

      // validar se todos os itens são iguais, e se for, verificar se ja foi adicionado ao array de items duplicados
      if (
        e.room === row.room &&
        e.task === row.task &&
        moment(e.initialDate).format("l") ===
          moment(row.initialDate).format("l") &&
        moment(e.endDate).format("l") === moment(row.endDate).format("l") &&
        e.maxQuantity === row.maxQuantity
      ) {
        allRowsDuplicity.push(e);
      }
    });
  }

  // remover duplicados e transformar em array
  const allRowsDuplicityFormated = Array.from(new Set(allRowsDuplicity));

  return allRowsDuplicityFormated;
}
