import { addDays, format } from "date-fns";
import { excelDateToJSDate } from "../../../utils/helpers/files/exceldatetojs";

export const excelColumnsPtBr = {
  'Participante': 'login',
  'Hotel': 'hotel',
  'Data CheckIn': 'checkindate',
  'Hora CheckIn': 'checkintime',
  'Data CheckOut': 'checkoutdate',
  'Hora CheckOut': 'checkouttime',
  'Numero Da Reserva': 'reservationnumber',
  'Endereco': 'address',
  'Telefone Do Hotel': 'phonenumber',
  'Informacoes Extras': 'extrainformation',
};

const regexEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

function formatDate(date) {
  try {
    return format(addDays(new Date(date), 1), 'MM-dd-yyyy');
  } catch (error) {
    return undefined;
  }
}

function formatTime(time) {
  try {
    return format(excelDateToJSDate(time, ), 'HH:mm');
  } catch (error) {
    return undefined;
  }
}

function emailValidate(email) {
  try {
    const emailValid = regexEmail.test(email);
    return emailValid ? email : undefined;
  } catch (error) {
    return undefined;
  }
}

export function allExcelColumns(row, index) {
    const formatedRow = {
      index,
      login: emailValidate(row.login),
      hotel: row.hotel,
      checkindate: formatDate(row.checkindate),
      checkoutdate: formatDate(row.checkoutdate),
      checkintime: formatTime(row.checkintime),
      checkouttime: formatTime(row.checkouttime),
      address: row.address,
      reservationnumber: row.reservationnumber ? row.reservationnumber : '',
      phonenumber: row.phonenumber ? row.phonenumber : '',
      extrainformation: row.extrainformation ?  row.extrainformation : '',
    }

    return formatedRow;
}