import React, { useRef, useState } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  Button,
  UncontrolledTooltip,
} from "reactstrap";
// alerta
import SweetAlert from "react-bootstrap-sweetalert";
// service
import { sendTickedEmail } from "../../../utils/services/schedule";
import { getUrlFiles } from "../../../utils/services/files";
// componentes
import TextEditor from "../../../components/TextEditor";
import { TooltipAgendaModal } from "./TooltipAgendaModal";

const defaultEmail =
  '<div><p>Olá, <span style="font-weight: bold">${participantName}!</span></p><p>Segue anexo ingressos para <span style="font-weight: bold">${eventName}</span></p></div>';

export default function ModalSendEmailSession(props) {
  const { isOpen, toggle, eventId, jwt, participantData } = props;
  const refEditor = useRef(null);
  // email
  const [subject, setSubject] = useState("Ingressos para ${eventName}");
  const [docs, setDocs] = useState([]);
  // carregamento
  const [loading, setLoading] = useState(false);
  // erros
  const [subjectError, setSubjectError] = useState(false);
  const [docError, setDocError] = useState(false);
  // alerta
  const [success, setSuccess] = useState(false);

  async function sendEmail() {
    setLoading(true);

    if (!subject) {
      setSubjectError(true);
      setLoading(false);
      return;
    } else setSubjectError(false);

    if (docs.length < 1) {
      setDocError(true);
      setLoading(false);
      return;
    } else setDocError(false);

    var urlDoc = [];

    for (var i = 0; i < docs.length; i++) {
      var dataDoc = new FormData();
      dataDoc.append("files", docs[i]);

      await getUrlFiles(dataDoc, jwt).then((res) => {
        if (res[0]) {
          urlDoc.push({
            name: res[0].name.split(".")[0],
            url: res[0].url,
          });
        }
      });
      // .catch((error) => console.log("erro up", error));
    }

    const data = {
      eventId: eventId,
      loginId: participantData.login,
      name: participantData.name,
      email: participantData.email,
      sessionId: participantData.session,
      companionsNumber: participantData.companionsnumber,
      attachments: urlDoc,
      subject: subject,
      template: refEditor.current.getContent(),
    };

    await sendTickedEmail(data, jwt).then((res) => {
      if (res.message === "email sended") {
        setSuccess(true);
      }
    });
    // .catch((error) => console.log("erro email", error));

    setLoading(false);
  }

  function newDoc(doc) {
    var docList = docs;

    docList.push(doc.target.files[0]);

    setDocs(docs);

    document.getElementById("input-doc").value = null;
  }

  function removeDoc(index) {
    var docList = [];

    docs.map((e, i) => {
      if (i != index) {
        docList.push(e);
      }
    });

    setDocs(docList);
  }

  const [isModalTooltip, setIsModalTooltip] = useState(false);
  const [tooltipType, setTooltipType] = useState("")

  const modalToggle = () => setIsModalTooltip(!isModalTooltip);

  const openModal = (type) => {
    setIsModalTooltip(true)
    setTooltipType(type)
  }

  return (
    <>
      {success ? (
        <SweetAlert
          success
          title={"Sucesso"}
          onConfirm={() => {
            toggle();
            setSuccess(false);
          }}
        >
          Ingressos enviados com sucesso!
        </SweetAlert>
      ) : null}
      <Modal isOpen={isOpen} centered autoFocus>
        <ModalHeader toggle={toggle}>Enviar Ingressos</ModalHeader>
        <ModalBody>
          <Row>
            <Col md={12}>
              <label className="form-label">Assunto do Email *</label>
              <input
                className="form-control"
                type="text"
                defaultValue={subject}
                onChange={(e) => setSubject(e.target.value)}
              />
            </Col>
            {subjectError && (
              <a style={{ color: "red" }}>Esse campo é obrigatório</a>
            )}
          </Row>

          <Row style={{ marginTop: 10 }}>
            <Col md={12}>
              <label className="form-labe label-tooltip">
                Corpo do Email *
                <div id={`email-solicitation`} style={{ paddingRight: "7px", height: "16px" }}>
                  <div onClick={() => openModal("S")} className="show-tips">
                    <span>?</span>
                  </div>
                  <UncontrolledTooltip
                    style={{ backgroundColor: "#6f42c1" }}
                    placement="right"
                    target={`email-solicitation`}
                  >
                    Email enviado apenas para o participante selecionado. <br />
                    (Clique para mais instruções)
                  </UncontrolledTooltip>
                </div>
              </label>
              <TextEditor defaultState={defaultEmail} refState={refEditor} />
            </Col>
          </Row>

          {docs.length > 0 && (
            <div className="div-list-tickets">
              {docs.map((e, i) => (
                <div className="div-tickets" key={i}>
                  <i className="bx bxs-file-doc" style={{ fontSize: 25 }} />{" "}
                  <a>{e.name}</a>{" "}
                  <i
                    className="mdi mdi-close-thick icon-delete-doc"
                    onClick={() => removeDoc(i)}
                  />
                </div>
              ))}
            </div>
          )}

          <Row style={{ marginTop: 10 }}>
            <Col md={12}>
              <label className="form-label" htmlFor="input-doc">
                Anexar Arquivos PDFs
              </label>
              <input
                className="form-control"
                type="file"
                id="input-doc"
                defaultValue={null}
                onChange={(e) => newDoc(e)}
              />
              {docError && (
                <a style={{ color: "red" }}>Esse campo é obrigatório</a>
              )}
            </Col>
          </Row>

          <Row
            style={{
              width: "100%",
              margin: "20px 0 0 0",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <>
              <Button
                color="danger"
                style={{ width: 80, marginRight: 10 }}
                onClick={() => toggle()}
              >
                Fechar
              </Button>
              <Button
                color="success"
                className="btn btn-success waves-effect waves-light"
                style={{ width: 80 }}
                onClick={() => {
                  if (!loading) {
                    sendEmail();
                  }
                }}
              >
                {loading ? "Carregando" : "Enviar"}
              </Button>
            </>
          </Row>
        </ModalBody>

        {isModalTooltip && <TooltipAgendaModal modal={isModalTooltip} toggle={modalToggle} type={tooltipType} />}
      </Modal>
    </>
  );
}
