import React, { useState, useEffect, useRef } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Col,
  Row,
  Button,
  Input,
} from "reactstrap";

import Select from "react-select";
//redux
import { connect } from "react-redux";
//useMutation
import { useMutation } from "react-query";
// alerta
import SweetAlert from "react-bootstrap-sweetalert";
//react router dom
import { useLocation } from "react-router-dom";
//service
import { getGroups } from "../../../utils/services/group";
import { createSchedule } from "../../../utils/services/schedule";
import { createScheduleQuotas } from "../../../utils/services/scheduleCotas";
import { getUrlFiles } from "../../../utils/services/files";

import ColorPicker from "../../../components/ColorPicker/ColorPicker";
import ButtonSpinner from "../../../components/Buttons/ButtonSpinner";

function NewSchedule(props) {
  const { isOpen, toggle } = props;
  const location = useLocation();
  const [isLoading, setIsloading] = useState(false);

  const [modalBackdrop, setModalBackdrop] = useState(false);
  //confirmção de criação
  const [success_dlg, setsuccess_dlg] = useState(false);
  //state de grupos
  const [groups, setGroups] = useState([]);
  const [allGroups, setAllGroups] = useState(false);
  //state de foto
  const [photo, setPhoto] = useState(null);
  const [expires, setExpires] = useState(null);
  //state de grupos selecionados
  const [groupsMulti, setGroupsMulti] = useState([]);
  // state cor da programação
  const [backgroundColor, setBackgroundColor] = useState("#FFFFFF");
  //state de erros
  const [titleError, setTitleError] = useState("");
  const [dateError, setDateError] = useState("");
  const [quotasError, setQuotasError] = useState("");

  function toggleBackdrop() {
    setModalBackdrop(!modalBackdrop);
  }

  function checkProductExists(product) {
    if (location.state) {
      const search = location.state.event.product.filter(
        (e) => e.name === product
      );

      if (search[0]) {
        return true;
      } else return false;
    }
  }

  function handleGroupMulti(a) {
    setGroupsMulti(a);
    if (a[0]?.value === "todos") {
      setAllGroups(true);
    } else {
      setAllGroups(false);
    }
  }

  const isComGas = () => checkProductExists("Distribuição de Cotas");
  const isApp = () => checkProductExists("APP Eventos Plus");
  const isRsvp = () => checkProductExists("RSVP");

  async function funcPhoto(e) {
    var infoPhoto = null;
    if (isApp() || isRsvp()) {
      if (e.target[5].files[0]) {
        var data = new FormData();
        data.append("files", e.target[5].files[0]);
        data.append("folder", "agenda");
        data.append("eventId", props.idEvent);

        await getUrlFiles(data, props.state.global_user_data.data.token)
          .then((response) => {
            const { url, expires } = response[0];
            setPhoto(url);
            setExpires(new Date(expires));
            infoPhoto = expires;
          })
          .catch((error) => {
            // console.log(error);
          });
      }
    }
    submitForm.mutate(e);
  }

  function formatDateTime(date, time) {
    const timeToStartDate = time?.value ? time : "00:01";
    return `${date}T${timeToStartDate}`;
  }

  const submitForm = useMutation(
    async (e) => {
      setIsloading(true);
      if (e.target[0].value === "") {
        setTitleError("O campo Titulo é obrigatório.");
        return;
      } else setTitleError("");

      const iDate = isApp() || isRsvp() ? 2 : 1;

      if (e.target[iDate].value === "") {
        setDateError("O campo é obrigatório.");
        return;
      } else setDateError("");

      const quota = isApp() || isRsvp() ? 8 : 4;

      if (
        checkProductExists("Distribuição de Cotas") &&
        !e.target[quota].value
      ) {
        setQuotasError("O campo Quantidade de Cotas é obrigatório");
        return;
      } else setQuotasError("");

      var groupsArray = [];

      if (allGroups) {
        groups.forEach((e) => groupsArray.push(e.id));
      } else {
        groupsMulti.forEach((e) => groupsArray.push(e.value));
      }

      //indexes
      const iStartDate = isApp() || isRsvp() ? 2 : 1;
      const iStartHour = isApp() || isRsvp() ? 3 : 2;
      const iEndHour = isApp() || isRsvp() ? 4 : 3;

      // values,
      const descriptionValue = isApp() || isRsvp() ? e?.target[1]?.value : "";
      const typeOrLocationValue =
        isApp() || isRsvp() ? e?.target[6]?.value : "";

      return await createSchedule({
        eventId: props.idEvent,
        startDate: formatDateTime(
          e.target[iStartDate].value,
          e.target[iStartHour].value
        ),
        startHour: e.target[iStartHour].value,
        endHour: e.target[iEndHour].value,
        title: e.target[0].value,
        description: descriptionValue,
        typeOrLocation: typeOrLocationValue,
        color: backgroundColor,
        picture: photo,
        expireDate: expires,
        groups: groupsArray,
        jwt: props.state.global_user_data.data.token,
      });
    },
    {
      onSuccess: async (data, e) => {
        const { message } = data;
        if (message === "AGENDA_CREATED") {
          if (isComGas()) {
            const quota = isApp() || isRsvp() ? 8 : 4;

            await createScheduleQuotas({
              eventId: props.idEvent,
              agendaId: data.agenda.id,
              qtdCotas: e.target[quota].value,
              jwt: props.state.global_user_data.data.token,
            });
          }
          setIsloading(false);
          setsuccess_dlg(true);
        }
      },

      onError: (error) => {
        setIsloading(false);
        // console.log("error", error);
      },
    }
  );

  async function getGroupsFunc() {
    await getGroups({
      jwt: props.state.global_user_data.data.token,
      eventId: props.idEvent,
      offset: 1,
      limit: 0,
      search: "",
    })
      .then((response) => {
        setGroups(response.findOneGroupData.data);
      })
      .catch((error) => {
        // console.log(error);
      });
  }

  useEffect(() => {
    getGroupsFunc();
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => {
        toggleBackdrop();
      }}
      autoFocus={true}
      centered={true}
    >
      {success_dlg ? (
        <SweetAlert
          success
          title={"Criado"}
          onConfirm={() => {
            props.data[0].refetch();
            setsuccess_dlg(false);
            toggle();
          }}
        >
          {"Programação criada com sucesso!"}
        </SweetAlert>
      ) : null}
      <ModalHeader toggle={toggle}>Nova Programação</ModalHeader>
      <ModalBody>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            funcPhoto(e);
            // submitForm.mutate(e);
          }}
        >
          <Row>
            <Col lg={12}>
              <div>
                <label className="form-label">Titulo*</label>
                <input className="form-control" type="text" />
                {titleError && <p style={{ color: "red" }}>{titleError}</p>}
              </div>
            </Col>
          </Row>
          {(isApp() || isRsvp()) && (
            <Row style={{ marginTop: 10 }}>
              <Col lg={12}>
                <div>
                  <label className="form-label">Descrição</label>
                  <textarea className="form-control" type="text" />
                </div>
              </Col>
            </Row>
          )}
          <Row style={{ marginTop: 10 }}>
            <Col lg={12}>
              <div>
                <label className="form-label">Data da Programação*</label>
                <input
                  className="form-control"
                  type="date"
                  id="example-datetime-local-input"
                />
                {dateError && <p style={{ color: "red" }}>{dateError}</p>}
              </div>
            </Col>
          </Row>

          <Row style={{ marginTop: 10 }}>
            <Col lg={6}>
              <div>
                <label className="form-label">Hora Início</label>
                <input
                  className="form-control"
                  type="time"
                  id="start-datetime-local-input"
                />
              </div>
            </Col>
            <Col lg={6}>
              <div>
                <label className="form-label">Hora Fim</label>
                <input
                  className="form-control"
                  type="time"
                  id="end-datetime-local-input"
                />
              </div>
            </Col>
          </Row>
          {(isApp() || isRsvp()) && (
            <Row style={{ marginTop: 10 }}>
              <Col lg={12}>
                <div>
                  <label className="form-label">Foto</label>
                  <Input className="form-control" type="file" id="formFile" />
                </div>
              </Col>
            </Row>
          )}
          {(isApp() || isRsvp()) && (
            <Row style={{ marginTop: 10 }}>
              <Col lg={12}>
                <div>
                  <label className="form-label">
                    Tipo/Local da Programação
                  </label>
                  <input className="form-control" type="text" />
                </div>
              </Col>
            </Row>
          )}
          {(isApp() || isRsvp()) && (
            <Row style={{ marginTop: 10 }}>
              <Col lg={12}>
                <ColorPicker
                  classOfContainer="bg-edit-shedule-event-pickr"
                  labelText="Cor da Programação"
                  setStateFunc={setBackgroundColor}
                />
              </Col>
            </Row>
          )}

          {isComGas() && (
            <Row style={{ marginTop: 10 }}>
              <Col lg={12}>
                <div>
                  <label className="form-label">Quantidade de Cotas*</label>
                  <input className="form-control" type="number" />
                  {quotasError && <p style={{ color: "red" }}>{quotasError}</p>}
                </div>
              </Col>
            </Row>
          )}

          <Row style={{ marginTop: 10 }}>
            <Col lg={12}>
              <div>
                <label className="form-label">Grupos</label>
                <Select
                  value={groupsMulti}
                  placeholder={"Selecione"}
                  isMulti={true}
                  onChange={(e) => {
                    handleGroupMulti(e);
                  }}
                  options={[
                    {
                      options: [
                        { label: "TODOS", value: "todos" },
                        ...groups.map((e) => {
                          return { label: e.name, value: e.id };
                        }),
                      ],
                    },
                  ]}
                  classNamePrefix="select2-selection"
                />
              </div>
            </Col>
          </Row>

          <Row
            style={{
              width: "100%",
              margin: "20px auto 0 auto",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              color="danger"
              style={{ width: 80, marginRight: 10 }}
              onClick={() => toggle()}
            >
              Fechar
            </Button>
            <ButtonSpinner isLoading={isLoading} title={"Salvar"} />
          </Row>
        </form>
      </ModalBody>
    </Modal>
  );
}

const mapStateToProps = function (state) {
  return { state: state };
};

export default connect(mapStateToProps)(NewSchedule);
