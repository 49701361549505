import React, { useState, useEffect } from 'react';
import { Modal, ModalBody, ModalHeader, Row, Col, Button } from 'reactstrap';
import { connect } from 'react-redux';

function ModalAlertStock(props) {
  const { isOpen, toggle, typeQuota, confirmStockAlert } = props;
  const [modalBackdrop, setModalBackdrop] = useState(false);

  function toggleBackdrop() {
    setModalBackdrop(!modalBackdrop);
  }

  async function submitForm(e) {
    e.preventDefault();

    await confirmStockAlert(true);
    toggle();
  }

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => toggleBackdrop()}
      autoFocus={true}
      centered={true}
      size="sm"
    >
      <ModalBody>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            submitForm(e);
          }}
        >
          <Row
            style={{
              marginBottom: 10,
              textAlign: 'center',
              fontSize: '1.4rem',
            }}
          >
            <Col md={12}>
              <label className="form-label">Atenção!</label>
            </Col>
          </Row>
          <Row
            style={{ marginBottom: 10, textAlign: 'center', fontSize: '1rem' }}
          >
            {typeQuota !== 'unavailable' && (
              <Col md={12}>
                <label className="form-label">
                  Cotas do tipo
                  <span style={{ fontWeight: 'bold' }}>{` ${typeQuota} `}</span>
                  serão utilizadas nesse lançamento.
                </label>
              </Col>
            )}
            {typeQuota === 'unavailable' && (
              <Col md={12}>
                <label className="form-label">
                  Quantidade total de ingressos maior do que as cotas
                  disponíveis.
                </label>
              </Col>
            )}
          </Row>

          <Row
            style={{
              width: '100%',
              margin: 'auto',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Button
              color="danger"
              style={{ width: 80, marginRight: 10 }}
              onClick={() => toggle()}
            >
              Fechar
            </Button>
            {typeQuota !== 'unavailable' && (
              <Button
                color="success"
                className="btn btn-success waves-effect waves-light"
                style={{ width: 80 }}
                type="submit"
              >
                Confirmar
              </Button>
            )}
          </Row>
        </form>
      </ModalBody>
    </Modal>
  );
}

const mapStateToProps = function (state) {
  return { state: state };
};

export default connect(mapStateToProps)(ModalAlertStock);
