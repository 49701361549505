import React, { useEffect } from "react";
import {
  MsalProvider,
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import { PublicClientApplication, InteractionType } from "@azure/msal-browser";

function PageContent() {
  const { instance, accounts, inProgress } = useMsal();

  useEffect(() => {
    if (inProgress === InteractionType.None && accounts.length === 0) {
      instance.handleRedirectPromise().catch((error) => console.log(error));
    }
  }, [instance, accounts, inProgress]);

  console.log(instance, accounts, inProgress);

  const handleLogin = () => {
    instance.loginRedirect().catch((error) => console.log(error));
  };

  const handleLogout = () => {
    instance.logoutRedirect();
  };

  if (inProgress === InteractionType.Redirect) {
    return <p>Authentication in progress...</p>;
  }

  return (
    <div>
      <AuthenticatedTemplate>
        <p>You are signed in!</p>
        <button onClick={handleLogout}>Sign out</button>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <p>You are not signed in! Please sign in.</p>
        <button onClick={handleLogin}>Sign in</button>
      </UnauthenticatedTemplate>
    </div>
  );
}

export default PageContent;
