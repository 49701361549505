import { addDays, format } from "date-fns";
import { excelDateToJSDate } from "../../../utils/helpers/files/exceldatetojs";
import { getEmailsInEvent } from "../../../utils/services/imports";

export const excelColumnsPtBr = {
  Participante: "login",
  "Data Embarque": "boardingdate",
  "Hora Embarque": "boardingtill",
  "Data Chegada": "arrivaldate",
  "Hora Chegada": "arrivaltime",
  Companhia: "company",
  Origem: "origin",
  Destino: "destination",
  Localizador: "locator",
  "E-Ticket": "eticket",
  "Numero do Voo": "flightnumber",
  Terminal: "terminal",
  Assento: "seat",
  "Grupo De Embarque": "boardinggroup",
  "Informacoes Extras": "extrainformation",
};

// const regexDate = /^(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d$/;
// const regexHour = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
const regexEmail =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

function formatDate(date) {
  try {
    return format(addDays(new Date(date), 1), "MM-dd-yyyy");
  } catch (error) {
    return undefined;
  }
}

function formatTime(time) {
  try {
    return format(excelDateToJSDate(time), "HH:mm");
  } catch (error) {
    return undefined;
  }
}

function emailValidate(email) {
  try {
    const emailValid = regexEmail.test(email);
    return emailValid ? email : undefined;
  } catch (error) {
    return undefined;
  }
}

export function allExcelColumns(row, index) {
  const formatedRow = {
    index,
    login: emailValidate(row.login),
    boardingdate: formatDate(row.boardingdate),
    boardingtill: formatTime(row.boardingtill),
    arrivaldate: formatDate(row.arrivaldate),
    arrivaltime: formatTime(row.arrivaltime),
    company: row.company,
    origin: row.origin,
    destination: row.destination,
    locator: row.locator ? row.locator : "",
    eticket: row.eticket ? row.eticket : "",
    flightnumber: row.flightnumber ? row.flightnumber : "",
    terminal: row.terminal ? row.terminal : "",
    seat: row.seat ? row.seat : "",
    boardinggroup: row.boardinggroup ? row.boardinggroup : "",
    extrainformation: row.extrainformation ? row.extrainformation : "",
  };

  return formatedRow;
}

export function findRowsWithError(validRows) {
  const rowsWithError = validRows.filter(
    (row) =>
      Object.values(row).filter((value) => value === undefined).length > 0
  );
  return rowsWithError;
}

export async function getUnregisteredEmails(file, jwt, eventId) {
  const limit = 99999999;
  const dataInDB = [];
  let numberOfRequisitions = 1;
  file = file.map((e) => {
    return {
      ...e,
      login: e.login.toLowerCase(),
    };
  });
  for (let i = 1; i <= numberOfRequisitions; i += 1) {
    const emails = await getEmailsInEvent({
      jwt,
      eventId,
      offset: i,
      limit,
    });

    if (i === 1) {
      numberOfRequisitions = Math.ceil(emails.count / limit);
    }
    dataInDB.push(emails);
    setTimeout(() => {}, 500);
  }

  const emailsInDB = dataInDB.map((email) => email.data).flat();

  const unregisteredEmails = file
    .filter(({ login: email }) => !emailsInDB.includes(email))
    .map((row) => ({
      ...row,
      login: undefined,
    }));

  return unregisteredEmails;
}
