import writeXlsxFile from "write-excel-file";
import { getHotel } from "../../../utils/services/hotel";

export async function createXlsx({
  jwt,
  idEvent,
  limit,
  countData,
  setFinishExport,
}) {
  const formatDate = (date) => {
    const formatedDate = date.split("T")[0].split("-").reverse().join("/");
    return `${formatedDate}`;
  };
  const schema = [
    {
      column: 'Id',
      type: String,
      value: (participant) => participant.id,
    },
    {
      column: 'Id Participante',
      type: String,
      value: (participant) => participant.login.id,
    },
    {
      column: 'E-mail do Participante',
      type: String,
      value: (participant) => participant.login.email,
    },
    {
      column: 'Participante',
      type: String,
      value: (participant) => participant.login.nome,
    },
    {
      column: 'Hotel',
      type: String,
      value: (participant) => participant.hotel,
    },
    {
      column: 'Data/hora checkIn',
      type: String,
      value: (participant) =>
        `${formatDate(participant.checkindate)} ${participant.checkintime}`,
    },
    {
      column: 'Data/hora checkOut',
      type: String,
      value: (participant) =>
        `${formatDate(participant.checkoutdate)} ${participant.ckeckouttime}`,
    },
    {
      column: 'Número da reserva',
      type: String,
      value: (participant) => participant.reservationnumber,
    },
    {
      column: 'Endereço',
      type: String,
      value: (participant) => participant.address,
    },
    {
      column: 'Telefone do Hotel',
      type: String,
      value: (participant) => participant.phonenumber,
    },
    {
      column: 'Informações Extras',
      type: String,
      value: (participant) => participant.extrainformation,
    },
  ];

  const allRequestsPromises = [];
  const numberOfRequisitions = Math.ceil(countData / limit);

  for (let i = 1; i <= numberOfRequisitions; i += 1) {
    const request = await getHotel({
      jwt,
      idEvent,
      offset: i,
      limit,
      search: "",
    });
    // }).then((response) => console.log(response));
    // return;
    allRequestsPromises.push(request);
    setTimeout(() => {}, 500);

    const progressBar = document.querySelector(".export-progress");
    const percentageBar = document.querySelector(".export-percentage");

    progressBar.style.width = `${(i / numberOfRequisitions) * 100}%`;
    percentageBar.innerHTML = `${((i / numberOfRequisitions) * 100).toFixed(
      0
    )}%`;

    if (i === numberOfRequisitions) {
      setTimeout(() => {
        setFinishExport(true);
      }, 1000);
    }
  }

  const allDataInSingleArray = allRequestsPromises
    .map((e) => e.findOneHotelData.data)
    .flat();
  if (allDataInSingleArray) {
    await writeXlsxFile(allDataInSingleArray, {
      schema,
      fileName: "hotel.xlsx",
    });
  }
}
