import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader, Row, Button } from "reactstrap";

//service
import { createFunction } from "../../../utils/services/functions";
//redux
import { connect } from "react-redux";

import SweetAlert from "react-bootstrap-sweetalert";
import ButtonSpinner from "../../../components/Buttons/ButtonSpinner";

function ModalNewFunc(props) {
  const { isOpen, toggle } = props;
  const [isLoading, setIsloading] = useState(false);
  const [modalBackdrop, setModalBackdrop] = useState(false);
  //state de erros
  const [nameError, setNameError] = useState(false);
  const [typeError, setTypeError] = useState(false);
  const [funcObjectError, setFuncObjectError] = useState(false);
  const [returnObjectError, setReturnObjectError] = useState(false);
  //confirmção de criação
  const [success_dlg, setsuccess_dlg] = useState(false);

  function toggleBackdrop() {
    setModalBackdrop(!modalBackdrop);
  }

  async function submitForm(e) {
    if (!e.target[0].value) {
      setNameError(true);
      return;
    } else setNameError(false);

    if (!e.target[1].value) {
      setTypeError(true);
      return;
    } else setTypeError(false);

    if (!e.target[2].value) {
      setFuncObjectError(true);
      return;
    } else setFuncObjectError(false);

    if (!e.target[3].value) {
      setReturnObjectError(true);
      return;
    } else setReturnObjectError(false);

    setIsloading(true);

    await createFunction({
      eventId: props.idEvent,
      description: e.target[0].value,
      functionObject: e.target[2].value,
      responseObject: e.target[3].value,
      type: e.target[1].value,
      jwt: props.state.global_user_data.data.token,
    }).then((response) => {
      // console.log("ok", response);
      setIsloading(false);
      if (response.message === "CREATED") {
        setsuccess_dlg(true);
      }
    });
  }
  return (
    <Modal
      size="md"
      isOpen={isOpen}
      toggle={() => toggleBackdrop()}
      autoFocus={true}
      centered={true}
    >
      {success_dlg ? (
        <SweetAlert
          success
          title={"Criado"}
          onConfirm={() => {
            props.getAllFunc();
            setsuccess_dlg(false);
            toggle();
          }}
        >
          {"Função criada com sucesso!"}
        </SweetAlert>
      ) : null}
      <ModalHeader toggle={toggle}>Adicionar nova função</ModalHeader>
      <ModalBody>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            submitForm(e);
          }}
        >
          <Row>
            <label className="form-label">Nome da Função*</label>
            <input className="form-control" type="text" />
            {nameError && (
              <p style={{ color: "red" }}>
                O campo Nome da Função é obrigatório.
              </p>
            )}
          </Row>
          <Row style={{ marginTop: 10 }}>
            <label className="form-label">Tipo da Função*</label>
            <input className="form-control" type="text" />
            {typeError && (
              <p style={{ color: "red" }}>
                O campo Tipo da Função é obrigatório.
              </p>
            )}
          </Row>
          <Row style={{ marginTop: 10 }}>
            <label className="form-label">Objeto da Função*</label>
            <textarea className="form-control" type={"textarea"} />
            {funcObjectError && (
              <p style={{ color: "red" }}>
                O campo Objeto da Função é obrigatório.
              </p>
            )}
          </Row>
          <Row style={{ marginTop: 10 }}>
            <label className="form-label">Objeto de Retorno*</label>
            <textarea className="form-control" type="text" />
            {returnObjectError && (
              <p style={{ color: "red" }}>
                O campo Objeto de Retorno é obrigatório.
              </p>
            )}
          </Row>
          <Row
            style={{
              marginTop: 15,
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              color="danger"
              style={{ width: 80, marginRight: 10 }}
              onClick={() => toggle()}
            >
              Fechar
            </Button>

            <ButtonSpinner isLoading={isLoading} title={"Salvar"} />
          </Row>
        </form>
      </ModalBody>
    </Modal>
  );
}

const mapStateToProps = function (state) {
  return { state: state };
};

export default connect(mapStateToProps)(ModalNewFunc);
