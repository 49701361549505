export const getMenusDefault = () => {
  const menus = [
    'Geral',
    'Agenda',
    'Feed',
    'Localização',
    'Logística',
    'Ranking',
    'Convidados',
    'Networking',
    'Notes',
  ];
  return menus.map((option) => ({
    option,
  }));
};
