import React from "react";
import { Card, CardBody } from "reactstrap";
// provider do fire store
import { useFirestore } from "../../../../utils/firestoreProvider";

export default function Totalizer(props) {
  const firestoreData = useFirestore();

  // const listEmoji = [
  //   { code: "HAP", view: "😃" },
  //   { code: "PLA", view: "👏" },
  //   { code: "HEA", view: "❤️" },
  //   { code: "WOW", view: "😮" },
  //   { code: "LOL", view: "😂" },
  // ];

  function verifyEmoji(emoji) {
    if (firestoreData.data?.superEmoji?.length > 0) {
      var emojiSelected = firestoreData.data.superEmoji.find((e) => e.id === emoji);

      if (emojiSelected) {
        return emojiSelected.count;
      } else return 0;
    } else return 0;
  }

  return (
    <div className="div-totalizer">
      {/* <a onClick={() => console.log(firestoreData.data)}>aqui</a> */}
      <div
        className={`option-totalizer ${
          props.step === "access" && "option-totalizer-selected"
        }`}
        onClick={() => props.setStep("access")}
      >
        <a>
          <i className="mdi mdi-login-variant icon-totalizer" />
          Total de Acessos
        </a>
        <a style={{ fontWeight: "bold" }}>
          {firestoreData.data.access && firestoreData.data.access[0]?.count
            ? firestoreData.data.access[0].count
            : 0}
        </a>
        <a>Acessos</a>
      </div>

      <div
        className={`option-totalizer ${
          props.step === "online" && "option-totalizer-selected"
        }`}
        onClick={() => props.setStep("online")}
      >
        <a>
          <i className="mdi mdi-account-multiple icon-totalizer" />
          Participantes Online
        </a>
        <a style={{ fontWeight: "bold" }}>
          {firestoreData.data.online ? firestoreData.data.online : 0}
        </a>
        <a>Participantes</a>
      </div>

      {props.idChat && (
        <>
          <div
            className={`option-totalizer ${
              props.step === "chat" && "option-totalizer-selected"
            }`}
            onClick={() => props.setStep("chat")}
          >
            <a>
              <i className="mdi mdi-chat icon-totalizer" />
              Chat
            </a>
            <a style={{ fontWeight: "bold" }}>
              {firestoreData.data.chat && firestoreData.data.chat > 0
                ? firestoreData.data.chat
                : 0}
            </a>
            <a>Mensagens no chat</a>
          </div>

          <div
            className={`option-totalizer ${
              props.step === "question" && "option-totalizer-selected"
            }`}
            onClick={() => props.setStep("question")}
          >
            <a>
              <i className="mdi mdi-frequently-asked-questions icon-totalizer" />
              Perguntas
            </a>
            <a style={{ fontWeight: "bold" }}>
              {firestoreData.data.ask && firestoreData.data.ask > 0
                ? firestoreData.data.ask
                : 0}
            </a>
            <a>Perguntas</a>
          </div>

          <div
            className={`option-totalizer ${
              props.step === "emoji" && "option-totalizer-selected"
            }`}
            onClick={() => props.setStep("emoji")}
          >
            <a>
              <i className="mdi mdi-sticker-emoji icon-totalizer" />
              Super Emoji
            </a>
            <div style={{ display: "flex" }}>
              <a>😃 {verifyEmoji("HAP")}</a>
              <a>👏 {verifyEmoji("PLA")}</a>
              <a>❤️ {verifyEmoji("HEA")}</a>
              <a>😮 {verifyEmoji("WOW")}</a>
              <a>😂 {verifyEmoji("LOL")}</a>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
