import writeXlsxFile from "write-excel-file";
import { getQuotasConvention } from "../../../../utils/services/quotas";
import moment from "moment/moment";

export async function quotasLogsXlsx({
  jwt,
  offset,
  limit,
  totalItems,
  setFinishExport,
  type,
}) {
  const formatDate = (date) => {
    const dateFormat = date.split("T")[0].split("-").reverse().join("/");
    const time = date.split("T")[1].slice(0, 8);
    return `${dateFormat} - ${time}`;
  };

  const schema = [
    {
      column: "Login ID",
      type: String,
      value: (e) => e.loginId,
    },
    {
      column: "Email",
      type: String,
      value: (e) => e.email,
    },
    {
      column: "Ação",
      type: String,
      value: (e) => e.acao,
    },
    {
      column: "Hora ação",
      type: String,
      value: (e) => formatDate(e.createdAt),
    },
  ];

  const allRequestsPromises = [];
  const numberOfRequisitions = Math.ceil(totalItems / limit);

  for (let i = 1; i <= numberOfRequisitions; i += 1) {
    const request = await getQuotasConvention(jwt, offset, limit, type);
    allRequestsPromises.push(request);
    setTimeout(() => {}, 500);

    const progressBar = document.querySelector(".export-progress");
    const percentageBar = document.querySelector(".export-percentage");

    progressBar.style.width = `${(i / numberOfRequisitions) * 100}%`;
    percentageBar.innerHTML = `${((i / numberOfRequisitions) * 100).toFixed(
      0
    )}%`;

    if (i === numberOfRequisitions) {
      setTimeout(() => {
        setFinishExport(true);
      }, 1000);
    }
  }
  const allDataInSingleArray = allRequestsPromises.map((e) => e.data).flat();

  if (allDataInSingleArray) {
    await writeXlsxFile(allDataInSingleArray, {
      schema,
      fileName: "cotasLogs.xlsx",
    });
  }
}
