import React, { useEffect, useState } from 'react';

import { Input, Label, UncontrolledTooltip } from 'reactstrap';

export default function PasswordDisplay({ attributes, rowId, colId, setValue }) {
  // Mask
  const [maskedValue, setMaskedValue] = useState('');
  const [compareValue, setCompareValue] = useState('');

  useEffect(() => {
    const html = attributes.label;
    const div = document.getElementsByClassName(`password-label-${rowId}-${colId}`);
  
    for (const element of div) {
      element.innerHTML = html;
    }
  }, [attributes, rowId, colId])

  useEffect(() => {
    const validateCharsLength = [
      maskedValue.length >= +attributes.minCharacters,
      maskedValue.length <= +attributes.maxCharacters
    ];

    setValue((oldValue) => {
      const newValue = [...oldValue];
      newValue[rowId].cols[colId].attributes.value = maskedValue;
      if (compareValue !== '' || maskedValue !== '') {
        if (compareValue !== maskedValue) {
          newValue[rowId].cols[colId].attributes.errorMessage = 'As senhas não conferem';
        } else if (attributes.hasValidation && validateCharsLength.includes(false)) {
          newValue[rowId].cols[colId].attributes.errorMessage = `A senha deve ter entre ${attributes.minCharacters} e ${attributes.maxCharacters} caracteres.`;
        } else {
          newValue[rowId].cols[colId].attributes.errorMessage = '';
        }
      }

      return newValue;
    });
  }, [maskedValue, compareValue ,setValue, rowId, colId, attributes]);

  return (
    <div>
      <div className="custom-input-container">
        <div className="displayed-config-container">
          <div className="displayed-label">
            <Label htmlFor="formText" className={`form-label password-label-${rowId}-${colId}`} />
            {attributes.hasTip && (
              <div>
                <div className="show-tips" id={`password-tip-${rowId}-${colId}`}>
                  <span>?</span>
                </div>
                <UncontrolledTooltip style={{ backgroundColor: '#6f42c1' }} placement="right" target={`password-tip-${rowId}-${colId}`}>
                  {attributes.tipText}
                </UncontrolledTooltip>
              </div>
            )}
          </div>
        </div>
        <Input
          className="form-control"
          type="password"
          id="formText"
          placeholder={attributes.placeholder}
          value={maskedValue}
          onChange={({ target }) => setMaskedValue(target.value)}
        />
      </div>
      <div>
        <Label>
          Confirmar senha
        </Label>
        <Input
          className="form-control"
          type="password"
          id="formText"
          placeholder={attributes.placeholder}
          value={compareValue}
          onChange={({ target }) => setCompareValue(target.value)}
        />
      </div>
    </div>
  )
}
