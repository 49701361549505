import React, { useState } from "react";
//router dom
import { useNavigate } from "react-router-dom";
//
// import { useDispatch, useSelector } from "react-redux";
import { connect } from "react-redux";

import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

import { Input, Spinner } from "reactstrap";
// availity-reactstrap-validation
import { AvForm } from "availity-reactstrap-validation";

//Toast
import toastr from "toastr";
import "toastr/build/toastr.min.css";

import { loginForm } from "../utils/services/loginUser";

function MfaAccess(props) {
  const [step, setStep] = useState("_a");
  const { email, senha } = props.state.mfa;
  //erro do primeiro formulário
  const [userFound, setUserFound] = useState("");
  //campos do formulário
  const [tokenValue, setTokenValue] = useState("");
  //erro do segundo formuçário
  const [tokenErro, setTokenErro] = useState("");
  const history = useNavigate();
  const [isLoading, setIsloading] = useState(false);
  const [captchaToken, setCaptchaToken] = useState(null);

  const { executeRecaptcha } = useGoogleReCaptcha();

  const submitFormToken = async () => {
    if (tokenValue === "") {
      setTokenErro("Insira o token");
      return;
    } else setTokenErro("");

    if (!executeRecaptcha) {
      console.log("Execute recaptcha not yet available");
      return;
    }
    const token = await executeRecaptcha("submit");
    // console.log(token);
    setCaptchaToken(token);
    setIsloading(true);
    var userData = await loginForm({
      email: email.replace(/ /gi, ""),
      senha: senha,
      tokenMFA: tokenValue,
      recaptchaToken: token || "",
    });

    setIsloading(false);
    if (userData.message === "NOT_AUTHORIZED") {
      setUserFound("Usuário ou senha inválidos");
    }

    if (userData.message === "NOT_FOUND") {
      setUserFound("Usuário ou senha inválidos");
    }

    if (userData.message === "FIRST_ACCESS_REQUIRED") {
      setUserFound(
        "O email informado nunca realizou login no Inteegra Plus, gere a sua senha a partir da opção 'Primeiro Acesso'"
      );
    }

    if (userData.message === "found") {
      // setUserFound("");
      props.saveUserGlobalData(userData);
      props.saveMfa(userData);

      localStorage.setItem("authUser", Date.now());
      history("/events");
    }

    setTokenErro("");

    if (userData.message === "TOKEN_ACCESS_INVALID_OR_EXPIRED") {
      setTokenErro("Token inválido ou expirado");
      return;
    }
  };

  return (
    <div className="p-2">
      {userFound && <p style={{ color: "red" }}>{userFound}</p>}

      <AvForm className="form-horizontal">
        {tokenErro && <p style={{ color: "red" }}>{tokenErro}</p>}
        <div className="mb-3">
          <Input
            className="form-control"
            placeholder="Insira o token enviado para o seu email"
            type="text"
            required
            onChange={(e) => setTokenValue(e.target.value)}
          />
        </div>

        <div className="mt-3 d-grid ">
          <button
            className="btn btn-primary btn-block waves-effect waves-light"
            type="submit"
            onClick={submitFormToken}
          >
            {isLoading ? <Spinner size="sm" /> : null}
            <span> {`  Acessar Inteegra Plus`}</span>
          </button>
        </div>
      </AvForm>
      <div className="mt-4 text-center">
        <p
          className="text-muted"
          style={{ cursor: "pointer" }}
          onClick={() => props.setScreen("_a")}
        >
          <i className="mdi mdi-lock me-1" />
          Voltar para tela de login
        </p>
      </div>
    </div>
  );
}
const mapStateToProps = function (state) {
  return { state: state };
};

const mapDispatchToProps = function (dispatch) {
  return {
    saveUserGlobalData: (data) => {
      return dispatch({ type: "SAVE_GLOBAL_USER_DATA", data });
    },

    saveMfa: (data) => {
      return dispatch({ type: "MFA_TOKEN", data });
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(MfaAccess);
