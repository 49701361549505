import React from "react";
import { useEffect } from "react";
import { UncontrolledTooltip, Label } from "reactstrap";

import "@simonwep/pickr/dist/themes/nano.min.css";

import { createColorPicker } from "./colorPickerConfigs";

export default function ColorPicker({
  classOfContainer,
  setStateFunc,
  labelText,
  defaultColor,
  disabled,
  tip,
  idDiv,
}) {
  useEffect(() => {
    createColorPicker(classOfContainer, setStateFunc, defaultColor, disabled);
  }, []);

  return (
    <div className={`color-pickr-container ${classOfContainer}`}>
      {tip ? (
        <div style={{ display: "flex", alignItems: "center" }}>
          <a style={{ marginLeft: 10 }}>{labelText}</a>
          <div id={`${idDiv ? idDiv : "uploadComponents"}`} style={{ height: "16px", paddingRight: "7px" }}>
            <div
              className="show-tips"
            >
              <span style={{ margin: 0 }}>?</span>
            </div>
            <UncontrolledTooltip
              style={{ backgroundColor: "#6f42c1" }}
              placement="right"
              target={`${idDiv ? idDiv : "uploadComponents"}`}
            >
              {tip}
            </UncontrolledTooltip>
          </div>
        </div>
      ) : (
        <span>{labelText}</span>
      )}
    </div>
  );
}
