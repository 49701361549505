import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import {
  Table,
  Card,
  CardBody,
  CardTitle,
  Button,
  Collapse,
  Row,
} from "reactstrap";

import SweetAlert from "react-bootstrap-sweetalert";

//redux
import { connect } from "react-redux";

//components
import ModalNewEvent from "./ModalNewUser";
//import ModalViewEvent from "./ModalViewUser";
import ModalEditEvent from "./ModalEditUser";

//paginação
import Pagination from "../../components/Paginationv3";

//service
import { getUsers, deleteUser } from "../../utils/services/users";

import useDebounce from "../../utils/hooks/useDebounce";
import SectionComponent from "../Apps/Personalization/SectionComponent";
import SearchFilters from "../../components/SearchFilters";

const columnsFilter = [
  { name: "Nome", value: "name", type: "text" },
  { name: "Email", value: "email", type: "text" },
  { name: "Perfil", value: "profile", type: "text" },
  { name: "Inscrito Em", value: "criadoEm", type: "date" },
];

function Users(props) {
  //pesquisa
  const [search, setSearch] = useState("");
  //modais
  const [modalNewEvent, setModalNewEvent] = useState(false);
  //const [modalViewEvent, setModalViewEvent] = useState(false);
  const [modalEditEvent, setModalEditEvent] = useState(false);
  //deletar evento
  const [confirm_alert, setconfirm_alert] = useState(false);
  const [success_dlg, setsuccess_dlg] = useState(false);
  const [dynamic_title, setdynamic_title] = useState("");
  const [dynamic_description, setdynamic_description] = useState("");
  const [usersData, setUsersData] = useState([]);
  const [deleteUserData, setDeleteUserData] = useState(null);
  const [userEdit, setUserEdit] = useState(null);
  // collapse
  const [isOpen, setIsOpen] = useState(false);

  //paginação
  const [countData, setCountData] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const [currentIndex, setCurrentIndex] = useState(1);

  const debouncedSearch = useDebounce(search, 300);
  const [collapseIsOpen, setCollapseIsOpen] = useState(false);
  const [activeFilter, setActiveFilter] = useState("name");

  function setActiveFilterHandle(columnActive) {
    setActiveFilter(columnActive);
    setSearch("");
  }
  const reverseDate = () => {
    const reverse = debouncedSearch
      .split("/")
      .reverse()
      .join("/")
      .replace(/\//g, "-");
    return reverse;
  };
  function treatmentSearch() {
    if (
      columnsFilter?.filter((item) => item?.value === activeFilter)[0]?.type ===
      "date"
    ) {
      return reverseDate();
    }
    return debouncedSearch;
  }

  function toggleNewEvent() {
    setModalNewEvent(!modalNewEvent);
  }

  //function toggleViewEvent() {
  //setModalViewEvent(!modalViewEvent);
  //}

  function toggleEditEvent() {
    setModalEditEvent(!modalEditEvent);
  }

  const getEventsFunction = async () => {
    await getUsers({
      jwt: props.token,
      offset: currentIndex > 0 ? currentIndex : 1,
      limit: itemsPerPage,
      search: treatmentSearch(),
      columnSearch: activeFilter,
    }).then((response) => {
      setUsersData(response?.findAllUsersData?.data);
      setCountData(response?.findAllUsersData?.count);
    });
  };

  const deleteUserFunc = async () => {
    var userData = await deleteUser({
      jwt: props.token,
      id: deleteUserData.id,
    });
    if (userData.message === "USER_DISABLED") {
      deleteConfirm();
      getEventsFunction();
    } else {
      console.error("Error deleted user");
    }
  };
  function deleteConfirm() {
    setconfirm_alert(false);
    setsuccess_dlg(true);
    setdynamic_title("Deletado");
    setdynamic_description("Usuário deletado");
  }
  function formatData(startDate) {
    var data = new Date(startDate);
    var dia = String(data.getDate()).padStart(2, "0");
    var mes = String(data.getMonth() + 1).padStart(2, "0");
    var ano = data.getFullYear();
    var dataAtual = dia + "/" + mes + "/" + ano;

    return dataAtual;
  }

  useEffect(() => {
    getEventsFunction();
  }, [currentIndex, itemsPerPage, debouncedSearch]);

  return (
    <div>
      <MetaTags>
        <title>Usuários | Inteegra Plus</title>
      </MetaTags>
      {confirm_alert ? (
        <SweetAlert
          title={`Deseja deletar ${deleteUserData.nome}`}
          warning
          showCancel
          confirmBtnText="Sim"
          cancelBtnText="Não"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            setconfirm_alert(false);
            setsuccess_dlg(true);
            setdynamic_title("Deletado");
            setdynamic_description("Usuário deletado");
            deleteUserFunc();
          }}
          onCancel={() => setconfirm_alert(false)}
        >
          Você não poderá reverter essa ação.
        </SweetAlert>
      ) : null}

      {success_dlg ? (
        <SweetAlert
          success
          title={dynamic_title}
          onConfirm={() => {
            setsuccess_dlg(false);
          }}
        >
          {dynamic_description}
        </SweetAlert>
      ) : null}

      <ModalNewEvent
        isOpen={modalNewEvent}
        toggle={toggleNewEvent}
        getEvent={getEventsFunction}
        token={props.token}
      />

      {userEdit && (
        <ModalEditEvent
          isOpen={modalEditEvent}
          toggle={toggleEditEvent}
          userEdit={userEdit}
          getEvent={getEventsFunction}
        />
      )}
      <Card>
        <CardBody>
          <CardTitle>Usuários</CardTitle>
          <div>
            <Row
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: 10,
                width: "50px",
              }}
            >
              <SectionComponent
                sectionName="Filtros"
                setIsOpen={setCollapseIsOpen}
                isOpen={collapseIsOpen}
              />
            </Row>
            <Collapse isOpen={collapseIsOpen}>
              <SearchFilters
                columns={columnsFilter}
                activeFilter={activeFilter}
                setActiveFilterHandle={setActiveFilterHandle}
              />
            </Collapse>
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <form
              className="app-search d-none d-lg-block events-input-search"
              onSubmit={(e) => e.preventDefault()}
            >
              <div className="position-relative">
                <input
                  type={activeFilter === "criadoEm" ? "date" : "text"}
                  value={search}
                  className="form-control"
                  placeholder="Pesquisar..."
                  onChange={(e) => setSearch(e.target.value)}
                />
                <span className="bx bx-search-alt" />
              </div>
            </form>
            <div className="mobile-btn-section" style={{ width: "100%" }}>
              <Row className="mobile-btns-top">
                <form
                  className="app-search"
                  style={{ padding: "0px" }}
                  onSubmit={(e) => e.preventDefault()}
                >
                  <div className="position-relative">
                    <input
                      type={activeFilter === "criadoEm" ? "date" : "text"}
                      value={search}
                      className="form-control"
                      placeholder="Pesquisar..."
                      onChange={(e) => setSearch(e.target.value)}
                    />
                    <span className="bx bx-search-alt" />
                  </div>
                </form>
              </Row>
              <SectionComponent
                sectionName="Opções"
                setIsOpen={setIsOpen}
                isOpen={isOpen}
              />
              <Collapse isOpen={isOpen}>
                <Row className="mobile-btns-top">
                  <div onClick={() => setModalNewEvent(true)}>
                    Criar Usuário
                  </div>
                </Row>
              </Collapse>
            </div>
            <div className="header-btns-container">
              <Button
                color="success"
                className="btn-rounded waves-effect waves-light events-button-add"
                onClick={() => setModalNewEvent(true)}
              >
                Criar Usuário
              </Button>
            </div>
          </div>
          <div className="table-responsive">
            <Table className="table mb-0">
              <thead className="table-light">
                <tr>
                  <th>Nome</th>
                  <th>Email</th>
                  <th>Perfil</th>
                  <th>Inscrito Em</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                {usersData
                  ?.filter((e) =>
                    e.nome === null ? "name" : e.nome.toLocaleLowerCase()
                  )
                  ?.map((e, i) => (
                    <tr key={i}>
                      <th scope="row">
                        {e.nome === null ? "Usuário" : e.nome}
                      </th>
                      <td>{e.email}</td>
                      <td>{e.profile}</td>
                      <td>{formatData(e.criadoEm)}</td>
                      <td>
                        <i
                          className="mdi mdi-pencil"
                          style={{
                            color: "green",
                            fontSize: 17,
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setUserEdit(e);
                            setModalEditEvent(true);
                          }}
                        />{" "}
                        <i
                          className="mdi mdi-trash-can"
                          style={{
                            color: "red",
                            fontSize: 17,
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setDeleteUserData(e);
                            setconfirm_alert(true);
                          }}
                        />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
        </CardBody>
      </Card>
      <div className="bottom-navigation" style={{ justifyContent: "end" }}>
        {/* 
        Fornecer os 4 parametros obrigatórios;
        ** data - a array contendo as informações da tabela
        ** search - o state do termo de pesquisa
        ** currentIndex - posição atual do index
        ** setCurrentIndex - função para atualizar a posição atual do index
        */}
        <Pagination
          data={usersData}
          search={search}
          currentIndex={currentIndex}
          setCurrentIndex={setCurrentIndex}
          countData={countData}
          maxItemPerPage={itemsPerPage}
          setMaxItemPerPage={setItemsPerPage}
          maxPageButtonsToShow={3}
        />
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  const { token } = state.global_user_data.data;
  return { token };
};

export default connect(mapStateToProps)(Users);
