import React, { useEffect, useState } from 'react';

import { Input, Label, UncontrolledTooltip } from 'reactstrap';

export default function CheckboxDisplay({ attributes, rowId, colId, setValue }) {
  const [selected, setSelected] = useState([]);

  const handleChange = (value) => {
    if (selected.includes(value)) {
      setSelected(selected.filter((item) => item !== value));
    } else {
      setSelected([...selected, value]);
    }
  };

  const setLabel = ({ value, id }) => {
    const html = value;
    const div = document.getElementsByClassName(`check-label-${rowId}-${colId}-${id}`);
  
    for (const element of div) {
      element.innerHTML = html;
    }

    const askField = document.getElementsByClassName(`main-question-options-${rowId}-${colId}`);

    for (const element of askField) {
      element.innerHTML = attributes.ask;
    }
  };

  useEffect(() => {
    setValue((oldValue) => {
      const newValue = [...oldValue];
      newValue[rowId].cols[colId].attributes.value = selected;
      if (selected.length === 0 && attributes.required) {
        newValue[rowId].cols[colId].attributes.errorMessage = 'Selecione uma opção';
      } else {
        newValue[rowId].cols[colId].attributes.errorMessage = '';
      }

      return newValue;
    });
  }, [selected, setValue, rowId, colId, attributes.required]);

  return (
    <div id="therm-input-component">
      <div className="custom-input-container">
        <div className="displayed-config-container">
          <div className="displayed-label">
            <span className={`main-question-options-${rowId}-${colId}`} />
          </div>
        </div>
        {attributes.boxes.map((box, index) => (
          <div key={`id${index}`} style={{ display: 'flex' }}>
            <Input
              className="input-mg-rgt"
              type="checkbox"
              id={`edit-input-check-show-${index}`}
              onChange={() => handleChange(box.ask)}
              />
            <Label htmlFor={`edit-input-check-show-${index}`} className={`form-label check-label-${rowId}-${colId}-${index}`} />
            {setLabel({ value: box.label, id: index })}
            {box.hasTip && (
              <div>
                <div className="show-tips" id={`tip-${rowId}-${colId}-${box.id}`}>
                  <span>?</span>
                </div>
                <UncontrolledTooltip style={{ backgroundColor: '#6f42c1' }} placement="right" target={`tip-${rowId}-${colId}-${box.id}`}>
                  {box.tipText}
                </UncontrolledTooltip>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  )
}
