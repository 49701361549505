import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Table,
  Collapse,
  Row,
  Button,
} from "reactstrap";
// alerta
import SweetAlert from "react-bootstrap-sweetalert";
//redux
import { connect } from "react-redux";
// componentes
import SectionComponent from "../../Apps/Personalization/SectionComponent";
import Pagination from "../../../components/Paginationv3";
import ModalNewProduct from "./modalNewProduct";
import ModalEditProduct from "./modalEditProduct";
// service
import {
  getProducts,
  deleteScreenProducts,
} from "../../../utils/services/products";
import useDebounce from '../../../utils/hooks/useDebounce';

function Products(props) {
  const { isOpen, toggle } = props;
  // modal collapse
  const [isOpenCollapse, setIsOpenCollapse] = useState(false);
  //paginação
  const [currentIndex, setCurrentIndex] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [search, setSearch] = useState('');
  const [countData, setCountData] = useState(0);

  // deletar
  const [deleteData, setDeleteData] = useState({});
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  // modais
  const [modalNewProduct, setModalNewProduct] = useState(false);
  const [modalEditProduct, setModalEditProduct] = useState(false);
  const [dataEdit, setDataEdit] = useState({});
  // produtos
  const [products, setProducts] = useState([]);
  const debouncedSearch = useDebounce(search, 300);

  function deleteProduct() {
    deleteScreenProducts({
      jwt: props.state.global_user_data.data.token,
      id: deleteData.id,
    }).then((res) => {
      if (res.message === 'PRODUCT_DELETED') {
        getAllProducts();
        setDeleteSuccess(true);
        setConfirmDelete(false);
      }
    });
    // .catch((error) => console.log("erro", error));
  }

  function getAllProducts() {
    getProducts({
      jwt: props.state.global_user_data.data.token,
      offset: currentIndex,
      limit: itemsPerPage,
      mainProduct: true,
      columnSearch: 'name',
      search: debouncedSearch,
    }).then((res) => {
      if (res.message === 'PRODUCTS') {
        setProducts(res.data.products);
        setCountData(res.data.total);
      }
    });
    // .catch((error) => console.log("erro", error));
  }

  useEffect(() => {
    getAllProducts();
  }, [currentIndex, itemsPerPage, debouncedSearch]);

  return (
    <>
      {modalNewProduct && (
        <ModalNewProduct
          isOpen={modalNewProduct}
          toggle={() => setModalNewProduct(!modalNewProduct)}
          getAllProducts={getAllProducts}
        />
      )}

      {modalEditProduct && (
        <ModalEditProduct
          isOpen={modalEditProduct}
          toggle={() => setModalEditProduct(!modalEditProduct)}
          data={dataEdit}
          getAllProducts={getAllProducts}
        />
      )}

      {confirmDelete ? (
        <SweetAlert
          title={`Deseja deletar o produto ${deleteData.name}?`}
          warning
          showCancel
          confirmBtnText="Sim"
          cancelBtnText="Não"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            deleteProduct();
          }}
          onCancel={() => setConfirmDelete(false)}
        >
          Você não poderá reverter essa ação!
        </SweetAlert>
      ) : null}

      {deleteSuccess ? (
        <SweetAlert
          success
          title="Deletado"
          onConfirm={() => {
            setDeleteSuccess(false);
          }}
        >
          Produto deletado com sucesso
        </SweetAlert>
      ) : null}
      <Modal isOpen={isOpen} centered autoFocus size="xl">
        <ModalHeader toggle={toggle}>Produtos</ModalHeader>
        <ModalBody>
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
              marginBottom: 10,
              alignItems: 'center',
            }}
          >
            <form
              className="app-search d-none d-lg-block events-input-search"
              onSubmit={(e) => e.preventDefault()}
            >
              <div className="position-relative">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Pesquisar..."
                  onChange={(e) => setSearch(e.target.value)}
                />
                <span className="bx bx-search-alt" />
              </div>
            </form>

            <div className="mobile-btn-section" style={{ width: '100%' }}>
              <Row className="mobile-btns-top">
                <form
                  className="app-search"
                  style={{ padding: '0px' }}
                  onSubmit={(e) => e.preventDefault()}
                >
                  <div className="position-relative">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Pesquisar..."
                    />
                    <span className="bx bx-search-alt" />
                  </div>
                </form>
              </Row>
              <SectionComponent
                sectionName="Opções"
                setIsOpen={setIsOpenCollapse}
                isOpen={isOpenCollapse}
              />
              <Collapse isOpen={isOpenCollapse}>
                <Row className="mobile-btns-top">
                  <div onClick={() => setModalNewProduct(true)}>
                    Novo Produto
                  </div>
                </Row>
              </Collapse>
            </div>

            <div className="header-btns-container">
              <Button
                color="success"
                className="btn-rounded waves-effect waves-light events-button-add"
                onClick={() => setModalNewProduct(true)}
              >
                Novo Produto
              </Button>
            </div>
          </div>

          <div className="table-responsive mt-2 mb-3">
            <Table className="table mb-0">
              <thead className="table-light">
                <tr>
                  <th>ID do Produto (Sistema)</th>
                  <th>Produto (Sistema)</th>
                  <th>Ações</th>
                </tr>
              </thead>

              <tbody>
                {products &&
                  products.length > 0 &&
                  products
                    // .filter((e) => e.mainProduct === null)
                    .map((e, i) => (
                      <tr key={i}>
                        {/* <td>{e.mainProduct ? e.mainProduct.id : e.id}</td>
                        <td>{e.mainProduct ? e.mainProduct.name : e.name}</td> */}
                        <td>{e.id}</td>
                        <td>{e.name}</td>

                        <td>
                          <i
                            className="mdi mdi-pencil"
                            style={{
                              color: 'green',
                              fontSize: 17,
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              setDataEdit(e);
                              setModalEditProduct(true);
                            }}
                          />
                          {/* <i
                            className="mdi mdi-trash-can"
                            style={{
                              color: 'red',
                              fontSize: 17,
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              setDeleteData(e);
                              setConfirmDelete(true);
                            }}
                          /> */}
                        </td>
                      </tr>
                    ))}
              </tbody>
            </Table>
          </div>
          <Pagination
            data={[]}
            search={search}
            currentIndex={currentIndex}
            setCurrentIndex={setCurrentIndex}
            countData={countData}
            maxItemPerPage={itemsPerPage}
            setMaxItemPerPage={setItemsPerPage}
            maxPageButtonsToShow={3}
          />
        </ModalBody>
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => {
  return { state };
};

export default connect(mapStateToProps)(Products);
