import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";

export default function ModalExcelRows({ toggle, rows, columnNames }) {
  const invalidRows = rows.data.map((row) => ({
    row: row.index + 2,
    error: Object.entries(columnNames)
      .filter(([_key, value]) => row[value] === undefined)
      .map((e) => e[0]),
  }));

  return (
    <SweetAlert
      title="Corrija os erros abaixo e reenvie a planilha"
      warning
      confirmButtonText="Ok!"
      confirmBtnBsStyle="success"
      onConfirm={() => {
        toggle();
      }}
      style={{ width: "39.1%" }}
    >
      <div style={{ marginTop: 15 }}>
        {invalidRows.length !== 0 ? (
          <>
            {/* erros de campos vazios */}
            {rows.title === "default" && (
              <div>
                {invalidRows.map((row) => (
                  <p key={row.row}>
                    Linha {row.row}: Informe um participante na(s) coluna(s):{" "}
                    {row.error.join(", ")}
                  </p>
                ))}
              </div>
            )}

            {/* erros de usuário não encontrado */}
            {rows.title === "userNotFound" && (
              <div>
                <p>Usuários não encontrados</p>

                {invalidRows.map((row) => (
                  <p key={row.row}>
                    Linha {row.row}: {row.error.join(", ")}
                  </p>
                ))}
              </div>
            )}

            {/* erros de usuário duplicados */}
            {rows.title === "userDuplicate" && (
              <div>
                <p>Usuários duplicados</p>

                {invalidRows.map((row) => (
                  <p key={row.row}>
                    Linha {row.row}: {row.error.join(", ")}
                  </p>
                ))}
              </div>
            )}

            {/* erros de quartos indisponíveis */}
            {rows.title === "limitRoom" && (
              <div>
                <p>Quartos indisponíveis</p>

                <p>
                  Tipo de quarto sem limite disponível para essa importação:
                  {rows.data.join(", ")}
                </p>
              </div>
            )}

            {/* erros de usuários ja registrados */}
            {rows.title === "userRegistered" && (
              <div>
                <p>Participantes com quarto</p>

                {invalidRows.map((row) => (
                  <p key={row.row}>
                    Linha {row.row}: {row.error.join(", ")}
                  </p>
                ))}
              </div>
            )}
          </>
        ) : (
          <p>Utilize o Modelo correto para fazer a importação da planilha</p>
        )}
      </div>
    </SweetAlert>
  );
}
