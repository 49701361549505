import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody, Row, Col, Button } from "reactstrap";
// componentes
import ButtonSpinner from "../../../components/Buttons/ButtonSpinner";
// alerta
import SweetAlert from "react-bootstrap-sweetalert";
import { createLoginStatusVehicle } from "../../../utils/services/loginStatusVehicle";

export default function ModalEditStatusPart(props) {
  const {
    isOpen,
    toggle,
    eventId,
    vehicleId,
    loginId,
    previousStatus,
    getAll,
    bulkData,
    changeStatusBySelection,
  } = props;

  const [isLoading, setIsloading] = useState(false);
  // valor do campo
  const [selected, setSelected] = useState("");
  //   alerta
  const [success, setSuccess] = useState(false);
  const [notes, setNotes] = useState("");
  const [notesError, setNotesError] = useState(null);

  async function changeLoginStatusVehicle() {
    setIsloading(true);
    const data = {
      eventId: eventId,
      vehicleId: vehicleId,
      loginId: loginId,
      previousStatus: previousStatus ? previousStatus : "",
      newStatus: selected,
      notes: notes,
    };

    await createLoginStatusVehicle(data).then((res) => {
      setIsloading(false);
      setSuccess(true);
      getAll();
    });
  }

  async function bulkUpdateStatusVehicle() {
    setIsloading(true);
    const newArr = bulkData.map((item) => ({
      eventId: eventId,
      vehicleId: vehicleId,
      loginId: item.participantId,
      previousStatus: item.status ? item.status : "",
      newStatus: selected,
      notes: notes,
    }));

    try {
      await Promise.all(
        newArr.map(async (data) => {
          await createLoginStatusVehicle(data);
        })
      );
      setIsloading(false);
      setSuccess(true);
      getAll();
    } catch (error) {
      setIsloading(false);
      setSuccess(false);
    }
  }

  return (
    <>
      {success && (
        <SweetAlert
          success
          title={"Atualizado"}
          onConfirm={() => {
            setSuccess(false);
            toggle();
          }}
        >
          {"Status atualizado com sucesso!"}
        </SweetAlert>
      )}

      <Modal isOpen={isOpen} centered autoFocus>
        <ModalHeader toggle={toggle}>Status</ModalHeader>
        <ModalBody>
          <Row>
            <Col md={12}>
              <label className="form-label">Alterar Status *</label>
              <select
                className="form-control"
                onChange={(e) => {
                  setSelected(e.target.value);
                }}
                defaultValue={previousStatus}
              >
                {/* <option value={"Selecione"}>Selecione</option> */}
                <option value={""}></option>
                <option value={"Embarcado"}>Embarcado</option>
                <option value={"Desembarcado"}>Desembarcado</option>
                <option value={"Não Embarcou"}>Não Embarcou</option>
              </select>
            </Col>
          </Row>

          {selected === "Desembarcado" && (
            <Row style={{ marginTop: 10 }}>
              <Col md={12}>
                <label className="form-label">Justificativa *</label>
                <input
                  className="form-control"
                  type="text"
                  onChange={(e) => setNotes(e.target.value)}
                />
              </Col>
              {notesError && <a style={{ color: "red" }}>{notesError}</a>}
            </Row>
          )}

          <Row
            style={{
              width: "100%",
              margin: "auto",
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "1rem",
            }}
          >
            <Button
              color="danger"
              style={{ width: 80, marginRight: 10 }}
              onClick={() => toggle()}
            >
              Fechar
            </Button>

            <ButtonSpinner
              isLoading={isLoading}
              title={"Salvar"}
              isDisabled={selected === "" || selected === previousStatus}
              onClick={() => {
                if (!notes && selected === "Desembarcado") {
                  setNotesError("O campo Justificativa é obrigatório");
                  return;
                }
                if (!changeStatusBySelection) {
                  changeLoginStatusVehicle();
                } else {
                  bulkUpdateStatusVehicle();
                }
              }}
            />
          </Row>
        </ModalBody>
      </Modal>
    </>
  );
}
