import React from 'react';

export default function BrazilMapLegend() {
  return (
    <div style={{
      position: 'absolute',
      bottom: '0',
      right: '30px',
    }}>
      <div style={{ display: 'flex' }}>
        <div
          style={{
            backgroundColor: "#FFEBCD",
            width: "25px",
            height: "15px",
            marginRight: "10px",
            borderRadius: "20%",
          }}
        />
        <span>
          0-50
        </span>
      </div>
      <div style={{ display: 'flex' }}>
        <div
          style={{
            backgroundColor: "#FFAE37",
            width: "25px",
            height: "15px",
            marginRight: "10px",
            borderRadius: "20%",
          }}
        />
        <span>
          50-100
        </span>
      </div>
      <div style={{ display: 'flex' }}>
        <div
          style={{
            backgroundColor: "#C87700",
            width: "25px",
            height: "15px",
            marginRight: "10px",
            borderRadius: "20%",
          }}
        />
        <span>
          100+
        </span>
      </div>
    </div>
  )
}
