import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Collapse,
  Row,
  Table,
} from "reactstrap";
import { useLocation, useNavigate } from "react-router-dom";
//redux
import { connect } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
import { Link } from "react-router-dom";
import { listTemplates } from "../../../../utils/services/rsvp";
import moment from "moment";
import Pagination from "../../../../components/Paginationv3";
import useDebounce from "../../../../utils/hooks/useDebounce";
import SectionComponent from "../../../Apps/Personalization/SectionComponent";
import { deleteTemplate } from "../../../../utils/services/rsvp";
import ModalPreview from "../../../Templates/modalPreview";

function Web(props) {
  const location = useLocation();
  const history = useNavigate();

  //paginação
  const [currentIndex, setCurrentIndex] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  //search
  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounce(search, 300);

  // Delete Image Modal
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [successDelete, setSuccessDelete] = useState(false);
  const [deleteEmailData, setDeleteEmailData] = useState();

  const [templates, setTemplates] = useState([]);
  const [totalTemplates, setTotalTemplates] = useState(1);

  // collapse
  const [isCollapse, setIsCollapse] = useState(false);

  // modal
  const [modal, setModal] = useState(false);
  const [showAlertDelete, setShowAlertDelete] = useState(false);
  const [selected, setSelected] = useState(null);
  const toggle = () => setModal(!modal);

  const deleteTemplateRow = async () => {
    setIsDeleteOpen(false);
    await deleteTemplate({
      jwt: props.token,
      payload: {
        id: deleteEmailData.id,
      },
    });
    getTemplates();
    setSuccessDelete(true);
  };

  const getTemplates = async () => {
    const data = await listTemplates({
      id: location.state.idEvent,
      jwt: props.token,
      offset: currentIndex,
      type: "web",
      name: search,
    });
    setTemplates(data.data.data.templates);
    setTotalTemplates(data.data.data.total);
  };

  useEffect(() => {
    setTemplates([]);
    getTemplates();
  }, [currentIndex]);

  useEffect(() => {
    getTemplates();
  }, [debouncedSearch]);

  return (
    <div>
      {isDeleteOpen && (
        <SweetAlert
          warning
          showCancel
          confirmBtnText="Sim"
          cancelBtnText="Não"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          title={`Você tem certeza que deseja excluir o template ${deleteEmailData.name}?`}
          onConfirm={() => {
            deleteTemplateRow();
          }}
          onCancel={() => {
            setIsDeleteOpen(false);
          }}
        >
          Você não poderá reverter essa ação.
        </SweetAlert>
      )}
      {successDelete && (
        <SweetAlert
          success
          title="Deletado"
          onConfirm={() => {
            setSuccessDelete(false);
          }}
        >
          Template deletado com sucesso!
        </SweetAlert>
      )}
      <Card>
        <CardBody>
          <CardTitle>Template Página Web</CardTitle>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <form
              className="app-search d-none d-lg-block events-input-search"
              onSubmit={(e) => e.preventDefault()}
            >
              <div className="position-relative">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Pesquisar..."
                  onChange={(e) => setSearch(e.target.value)}
                />
                <span className="bx bx-search-alt" />
              </div>
            </form>
            <div className="mobile-btn-section" style={{ width: "100%" }}>
              <SectionComponent
                sectionName="Opções"
                setIsOpen={setIsCollapse}
                isOpen={isCollapse}
              />
              <Collapse isOpen={isCollapse}>
                <Row className="mobile-btns-top">
                  <div>Criar Template</div>
                </Row>
              </Collapse>
            </div>
            <div className="header-btns-container">
              <Link
                to={"/event/templates"}
                state={{
                  ...location.state,
                  type_template: "web",
                  id: location.state.idEvent,
                  private: true,
                  editMode: false,
                }}
                style={{ textDecoration: "none", color: "black" }}
              >
                <Button
                  color="success"
                  className="btn-rounded waves-effect waves-light events-button-add"
                  // onClick={() => setIsNewImgOpen(true)}
                >
                  Criar Template
                </Button>
              </Link>
            </div>
          </div>
          <div className="table-responsive">
            <Table className="table mb-0">
              <thead className="table-light">
                <tr>
                  <th>ID</th>
                  <th>Nome</th>
                  <th>Data de criação</th>
                  <th>Última atualização</th>
                  <th>Tipo</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                {selected && (
                  <ModalPreview
                    internal
                    location={location.state}
                    toggle={toggle}
                    modal={modal}
                    setModal={setModal}
                    template={selected}
                    {...props}
                  />
                )}
                {templates.map((email, i) => (
                  <tr key={i}>
                    <td>{email.id}</td>
                    <td>{email.name}</td>
                    <td>{moment(email.createdAt).format("LLL")}</td>
                    <td>{moment(email.updatedAt).format("LLL")}</td>
                    <td>
                      <span
                        className={
                          email.type !== "email"
                            ? "template_type_label_web"
                            : "template_type_label_email"
                        }
                      >
                        {email.type}
                      </span>
                    </td>
                    <td>
                      <i
                        onClick={() => {
                          setSelected(email);
                          toggle();
                        }}
                        className="mdi mdi-eye"
                        style={{
                          color: "blue",
                          fontSize: 17,
                          margin: "0 3px",
                          cursor: "pointer",
                        }}
                      />

                      <i
                        onClick={() => {
                          history("/event/templates", {
                            state: {
                              ...location.state,
                              type_template: "web",
                              editMode: true,
                              template: email,
                            },
                          });
                        }}
                        className="mdi mdi-pencil"
                        title="Editar"
                        style={{
                          margin: "0 3px",
                          color: "green",
                          fontSize: 17,
                          cursor: "pointer",
                        }}
                      />

                      <i
                        className="mdi mdi-trash-can"
                        title="Excluir"
                        style={{
                          color: "red",
                          fontSize: 17,
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          setDeleteEmailData(email) > setIsDeleteOpen(true)
                        }
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </CardBody>
      </Card>
      <div className="bottom-navigation" style={{ justifyContent: "end" }}>
        {/* 
        Fornecer os 4 parametros obrigatórios;
        ** data - a array contendo as informações da tabela
        ** search - o state do termo de pesquisa
        ** currentIndex - posição atual do index
        ** setCurrentIndex - função para atualizar a posição atual do index
        */}
        <Pagination
          data={templates}
          search={search}
          currentIndex={currentIndex}
          setCurrentIndex={setCurrentIndex}
          countData={totalTemplates}
          maxItemPerPage={itemsPerPage}
          setMaxItemPerPage={setItemsPerPage}
          maxPageButtonsToShow={3}
        />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  const { token } = state.global_user_data.data;
  return { token };
};

export default connect(mapStateToProps)(Web);
