import { urlBase } from "./config";
import axiosInstance from "../services/config/axiosinstance";

async function getSalesConsolidated({
  jwt,
  search,
  eventId,
  currentIndex,
  itemsPerPage,
  columnSearch,
}) {
  try {
    const config = {
      method: "get",
      url: `${urlBase}/events/${eventId}/payments/transactions?offset=${currentIndex}&limit=${itemsPerPage}&search=${
        search ? search : ""
      }&type=${columnSearch}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt}`,
      },
    };

    const response = await axiosInstance(config);
    return response.data;
  } catch (error) {
    return error.response;
  }
}

async function deleteSalesConsolidated({ jwt, idSale }) {
  try {
    const config = {
      method: "post",
      url: `${urlBase}/payments/transactions/${idSale}/cancel`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt}`,
      },
    };

    const response = await axiosInstance(config);
    return response.data;
  } catch (error) {
    return error.response;
  }
}

async function getSalesAnalitics({
  jwt,
  search = "",
  eventId,
  currentIndex,
  itemsPerPage,
  columnSearch,
}) {
  try {
    const config = {
      method: "get",
      url: `${urlBase}/events/${eventId}/payments/transactions/analitics?offset=${currentIndex}&limit=${itemsPerPage}&search=${
        search ? search : ""
      }&type=${columnSearch}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt}`,
      },
    };

    const response = await axiosInstance(config);
    return response.data;
  } catch (error) {
    return error.response;
  }
}

async function getSalesDetails({ jwt, idSale }) {
  try {
    const config = {
      method: "get",
      url: `${urlBase}/payments/transactions/${idSale}/details`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt}`,
      },
    };

    const response = await axiosInstance(config);
    return response.data;
  } catch (error) {
    return error.response;
  }
}

async function getSalesEmail({ jwt, idSale }) {
  try {
    const config = {
      method: "get",
      url: `${urlBase}/payments/transactions/${idSale}/emails`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt}`,
      },
    };

    const response = await axiosInstance(config);
    return response.data;
  } catch (error) {
    return error.response;
  }
}

async function resendEmail({ jwt, id }) {
  try {
    const config = {
      method: "post",
      url: `${urlBase}/payments/transactions/emails/${id}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt}`,
      },
    };

    const response = await axiosInstance(config);
    return response.data;
  } catch (error) {
    return error.response;
  }
}

export {
  getSalesConsolidated,
  getSalesAnalitics,
  deleteSalesConsolidated,
  getSalesDetails,
  getSalesEmail,
  resendEmail,
};
