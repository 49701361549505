import React, { useState } from "react";
import MetaTags from "react-meta-tags";
import { Table, Card, CardBody, CardTitle, Button, Row } from "reactstrap";

// import SweetAlert from "react-bootstrap-sweetalert";

// import { Link } from "react-router-dom";

//redux
// import { connect } from "react-redux";

//paginação
import Pagination from "./Pagination";

const historicData = [
  {
    event: "evento 1",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 2",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 3",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 1",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 2",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 3",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 1",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 2",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 3",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 1",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 2",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 3",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 1",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 2",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 3",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 1",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 2",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 3",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 1",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 2",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 3",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 1",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 2",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 3",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 1",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 2",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 3",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 1",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 2",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 3",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 1",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 2",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 3",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 1",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 2",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 3",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 1",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 2",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 3",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 1",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 2",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 3",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 1",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 2",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 3",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 1",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 2",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 3",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 1",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 2",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 3",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 1",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 2",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 3",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 1",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 2",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 3",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 1",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 2",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 3",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 1",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 2",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 3",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 1",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 2",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 3",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 1",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 2",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 3",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 1",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 2",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 3",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 1",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 2",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 3",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 1",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 2",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 3",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 1",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 2",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 3",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 1",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 2",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 3",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 1",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 2",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 3",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 1",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 2",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 3",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 1",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 2",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 3",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 1",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 2",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 3",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 1",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 2",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 3",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 1",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 2",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 3",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 1",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 2",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 3",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 1",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 2",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 3",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 1",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 2",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 3",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 1",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 2",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 3",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 1",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 2",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 3",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 1",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 2",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 3",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 1",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 2",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 3",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 1",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 2",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 3",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 1",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 2",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 3",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 1",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 2",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 3",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 1",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 2",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 3",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 1",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 2",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 3",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 1",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 2",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 3",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 1",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 2",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 3",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 1",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 2",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 3",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 1",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 2",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 3",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 1",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 2",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 3",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 1",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 2",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 3",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 1",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 2",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 3",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 122121212",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 2",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 3",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 12",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 2",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 3",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 12",
    date: "data",
    action: "ação",
  },
  {
    event: "pedro 2",
    date: "data",
    action: "ação",
  },
  {
    event: "evento 3212",
    date: "data",
    action: "ação",
  },
];

function Historic() {
  //pesquisa
  const [searchEvent, setSearchEvent] = useState("");
  //   const [search, setSearch] = useState("");
  //   const [search, setSearch] = useState("");
  //   const [search, setSearch] = useState("");
  //paginação
  const [currentIndex, setCurrentIndex] = useState(0);

  function searchFilter() {
    var event = document.getElementById("event").value;
    setSearchEvent(event);
  }

  return (
    <div>
      <MetaTags>
        <title>Histórico | Inteegra Plus</title>
      </MetaTags>
      <Card>
        <CardBody>
          <CardTitle>Histórico</CardTitle>

          <Row>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "flex-start",
              }}
            >
              <form
                className="app-search d-none d-lg-block events-input-search"
                style={{ padding: 10, margin: 10 }}
              >
                <div className="position-relative">
                  <label
                    className="col-md-2 col-form-label"
                    style={{ width: "auto" }}
                  >
                    Evento
                  </label>
                  <input
                    type="text"
                    id="event"
                    className="form-control"
                    placeholder="Evento"
                  />
                </div>
              </form>
              <form
                className="app-search d-none d-lg-block events-input-search"
                style={{ padding: 10, margin: 10 }}
              >
                <div className="position-relative">
                  <label
                    className="col-md-2 col-form-label"
                    style={{ width: "auto" }}
                  >
                    Usuário
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Usuário"
                  />
                </div>
              </form>
              <form
                className="app-search d-none d-lg-block events-input-search"
                style={{ padding: 10, margin: 10 }}
              >
                <div className="position-relative">
                  <label
                    className="col-md-2 col-form-label"
                    style={{ width: "auto" }}
                  >
                    Data Inicio
                  </label>
                  <input
                    type="date"
                    // defaultValue="2019-08-19"
                    className="form-control"
                  />
                </div>
              </form>
              <form
                className="app-search d-none d-lg-block events-input-search"
                style={{ padding: 10, margin: 10 }}
              >
                <div className="position-relative">
                  <label
                    className="col-md-2 col-form-label"
                    style={{ width: "auto" }}
                  >
                    Data Fim
                  </label>
                  <input
                    type="date"
                    // defaultValue="2019-08-19"
                    className="form-control"
                  />
                  <div style={{ color: "red" }}>*Período máximo de 60 dias</div>
                </div>
              </form>
            </div>
          </Row>
          <Row
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "98%",
              margin: "10px auto",
            }}
          >
            <Button
              color="success"
              className="btn-rounded waves-effect waves-light events-button-add"
              style={{ width: 100 }}
              onClick={() => searchFilter()}
            >
              Pesquisar
            </Button>
          </Row>
          <div className="table-responsive">
            <Table className="table mb-0">
              <thead className="table-light">
                <tr>
                  <th>Eventos</th>
                  <th>Data</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                {historicData &&
                  historicData
                    .filter(
                      (e) =>
                        e.event
                          .toLocaleLowerCase()
                          .indexOf(searchEvent.toLocaleLowerCase()) > -1
                    )
                    .slice(currentIndex, currentIndex + 20)
                    .map((e, i) => (
                      <tr key={i}>
                        <th scope="row">{e.event}</th>
                        <td>{e.date}</td>
                        <td>{e.action}</td>
                      </tr>
                    ))}
              </tbody>
            </Table>
            {/* <Row style={{ display: "flex", justifyContent: "flex-end" }}>
              <nav
                aria-label="Page navigation example"
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <ul class="pagination">
                  {historicData &&
                    [
                      ...Array(
                        Math.ceil(
                          historicData &&
                            historicData.filter(
                              (e) =>
                                e.event
                                  .toLocaleLowerCase()
                                  .indexOf(searchEvent.toLocaleLowerCase()) > -1
                            ).length / 20
                        )
                      ),
                    ].map((_, i) => (
                      <li class="page-item" key={i}>
                        <a
                          class="page-link"
                          key={i}
                          onClick={() => {
                            setCurrentIndex(i * 20);
                          }}
                        >
                          {i + 1}
                        </a>
                      </li>
                    ))}
                </ul>
              </nav>
              <a style={{ width: "auto" }}>
                Total de items: {historicData.length}
              </a>
            </Row> */}
          </div>
        </CardBody>
      </Card>
      {/* 
      Fornecer os 4 parametros obrigatórios;
      ** data - a array contendo as informações da tabela
      ** search - o state do termo de pesquisa
      ** currentIndex - posição atual do index
      ** setCurrentIndex - função para atualizar a posição atual do index
      */}
      <Pagination
        data={historicData}
        search={searchEvent}
        currentIndex={currentIndex}
        setCurrentIndex={setCurrentIndex}
      />
    </div>
  );
}

export default Historic;
