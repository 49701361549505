import React, { useState } from "react";

import { Card, CardBody, Button, Collapse, Row } from "reactstrap";
import CustomForm from "../../../components/CustomForm/CustomForm";
import SectionComponent from "../../Apps/Personalization/SectionComponent";

export default function Rsvp(props) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div>
      <Card>
        <CardBody>
          <div className="mobile-btn-section" style={{ width: "100%" }}>
            <SectionComponent
              sectionName="Opções"
              setIsOpen={setIsOpen}
              isOpen={isOpen}
            />
            <Collapse isOpen={isOpen}>
              <Row className="mobile-btns-top">
                <div onClick={() => props.setStep("_a")}>Voltar</div>
              </Row>
            </Collapse>
          </div>
          <div className="header-btns-container">
            <Button
              color="danger"
              style={{ height: 40, width: 80 }}
              onClick={() => {
                props.setStep("_a");
              }}
            >
              Voltar
            </Button>
          </div>
          <CustomForm
            toggle={() => props.setStep("_a")}
            createOrUpdate="create"
            eventId={props.eventId}
          />
        </CardBody>
      </Card>
    </div>
  );
}
