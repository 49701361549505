import React, { useState } from "react";

import { Modal, ModalBody, ModalHeader } from "reactstrap";

//icon
import { icons } from "../../../utils/helpers/icons/index";
import ComponentIcon from "../../../utils/helpers/icons/component";

export default function ModalIcon(props) {
  const { isOpen, toggle } = props;
  const [modalBackdrop, setModalBackdrop] = useState(false);
  const [iconSelected, setIconSelected] = useState(props.iconSelected);

  function toggleBackdrop() {
    setModalBackdrop(!modalBackdrop);
  }

  function selectIcon(icon) {
    props.setIconSelected(icon);
    setTimeout(() => {
      toggle();
    }, 400)
  }

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => toggleBackdrop}
      autoFocus={true}
      centered={true}
    >
      <ModalHeader toggle={toggle}>Selecione o ícone</ModalHeader>
      <ModalBody>
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {icons.map((e, i) => (
            <ComponentIcon
              key={i}
              icon={e}
              setIconSelected={setIconSelected}
              iconSelected={iconSelected}
              toggleBackdrop={selectIcon}
            />
          ))}
        </div>
      </ModalBody>
    </Modal>
  );
}
