import React, { useState, useEffect } from "react";
import { Modal, ModalHeader, ModalBody, Table } from "reactstrap";
import { getAllLoginStatusVehicle } from "../../../utils/services/loginStatusVehicle";

export default function ModalListHistoricPart(props) {
  const { isOpen, toggle, data, loginId } = props;

  //   modal
  const [modalEditStatus, setModalEditStatus] = useState(false);
  const [listStatus, setListStatus] = useState([]);

  useEffect(() => {
    getAllLoginStatusVehicle(
      data.eventId,
      data.id,
      loginId,
      1,
      100,
      "",
      ""
    ).then((res) => {
      setListStatus(res.data.findAll.data);
    });
  }, []);
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const localDate = new Date(
      date.getTime() - date.getTimezoneOffset() * 60000
    );
    const formatedDate = localDate
      .toISOString()
      .split("T")[0]
      .split("-")
      .reverse()
      .join("/");
    const time = localDate.toISOString().split("T")[1].substring(0, 5);
    return `${formatedDate} ${time}`;
  };

  return (
    <>
      <Modal isOpen={isOpen} centered autoFocus size="lg">
        <ModalHeader toggle={toggle}>Histórico do participante</ModalHeader>
        <ModalBody>
          <div className="table-responsive" style={{ margin: "0px 0" }}>
            <p>
              {" "}
              <span style={{ fontSize: 14, fontWeight: "bold" }}>
                Veículo:
              </span>{" "}
              {data.description}
            </p>
            <Table className="table mb-0">
              <thead className="table-light">
                <tr>
                  <th>Status Anterior</th>
                  <th>Status Atual</th>
                  <th>Data</th>
                  <th>Justificativa</th>
                </tr>
              </thead>

              <tbody>
                {listStatus &&
                  listStatus?.map((item, i) => (
                    <tr key={i}>
                      <td>{item.previousStatus}</td>
                      <td>{item.newStatus}</td>
                      <td>{formatDate(item.dateRegistration)}</td>
                      <td>{item.notes}</td>

                      {/* <td>
                        <i
                          className="mdi mdi-eye"
                          style={{
                            color: "blue",
                            fontSize: 17,
                            cursor: "pointer",
                            marginRight: 5,
                          }}
                        />

                        <i
                          className="mdi mdi-car-arrow-right"
                          style={{
                            color: "green",
                            fontSize: 17,
                            cursor: "pointer",
                          }}
                          onClick={() => setModalEditStatus(true)}
                        />
                      </td> */}
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
}
