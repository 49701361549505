import { urlBase } from "./config";
import axiosInstance from "../services/config/axiosinstance";

async function getAllUploadApp(
  jwt,
  offset,
  limit,
  eventId,
  search,
  columnSearch
) {
  try {
    const config = {
      method: "get",
      url: `${urlBase}/uploadapp/${eventId}/event/?offset=${offset}&limit=${limit}${
        search ? `&search=${search}&columnSearch=${columnSearch}` : ""
      }`,
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await axiosInstance(config);
    return response;
  } catch (error) {
    return error.response;
  }
}
export { getAllUploadApp };
