import React, { useRef, useEffect, useState } from "react";
import {
  Input,
  Card,
  CardBody,
  CardTitle,
  Button,
  FormGroup,
  FormFeedback,
} from "reactstrap";
import MetaTags from "react-meta-tags";
import { createTemplate, updateTemplate } from "../../utils/services/rsvp";
import SweetAlert from "react-bootstrap-sweetalert";
import { useLocation, useNavigate } from "react-router-dom";
import ReactDOMServer from "react-dom/server";
import FormMounted from "../AboutEvent/rsvp/FormMounted";
//redux
import { connect } from "react-redux";
import { getAllForms } from "../../utils/services/rsvp";
import blocksBasic from "grapesjs-blocks-basic";
import webpage from "grapesjs-preset-webpage";
import newsletter from "grapesjs-preset-newsletter";

import "./main.scss";
import "../../assets/scss/app.scss";

const categoryIcons = {
  Componentes: "fa fa-puzzle-piece",
  Elementos: "fa fa-cube",
  Formulários: "fa fa-user-circle",
  Templates: "fa fa-file-code-o",
};

let editor = null;

const TemplateEditor = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [exit, setExit] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isLoading, setIsloading] = useState(false);
  const [btnSave, setBtnSave] = useState(false);
  const [btnSaveExit, setBtnSaveExit] = useState(false);
  const [confirmSaveTemplate, setConfirmSaveTemplate] = useState(false);
  const [confirmEditTemplate, setConfirmEditTemplate] = useState(false);
  const [confirmExitSaveTemplate, setConfirmExitSaveTemplate] = useState(false);
  const [confirmExitEditTemplate, setConfirmExitEditTemplate] = useState(false);
  const [templateName, setTemplateName] = useState("");
  const [successMessage, setSuccessMessage] = useState(false);
  const [errorInvalid, setErrorInvalid] = useState(false);
  const [routeState, setRouteState] = useState(location.state);
  const [redirect, setRedirect] = useState(false);

  function goBackUrl() {
    if (routeState?.urlToBack) {
      navigate(routeState.urlToBack, {
        state: {
          ...routeState,
        },
      });
    } else navigate(-1);
  }

  // Função para carregar o HTML no GrapesJS
  const loadHtmlIntoGrapesjs = (editor, html) => {
    // Define o conteúdo HTML no editor
    editor.setComponents(html);
  };

  const grapeJsInit = async () => {
    const response = await getAllForms({
      eventId: routeState.idEvent,
      jwt: props.token,
      offset: 1,
      limit: 100,
      search: "",
    });
    let formList = [];
    let pluginsConfig = {
      web: [blocksBasic, webpage],
      email: [newsletter],
    };

    response.data.forms.map((form) => {
      const htmlString = ReactDOMServer.renderToString(
        <FormMounted
          form={JSON.parse(form.json)}
          saveForm={() => null}
          components={() => null}
          setForm={() => null}
        />
      );
      formList.push({
        id: form.id,
        label: form.description,
        category: "Formulários",
        content: htmlString,
        attributes: { class: "fa fa-user-circle" },
      });
    });
    let editorConfig = {
      fromElement: 1,
      container: "#gjs",
      allowScripts: 1,
      forceClass: false,
      storageManager: {
        type: 0, // Storage type. Available: local | remote
      },

      canvas: {
        styles: [
          "https://cdn.jsdelivr.net/npm/bootstrap@5.1.0/dist/css/bootstrap.min.css",
          "https://unpkg.com/spectre.css/dist/spectre.min.css",
        ],
        scripts: [],
      },
      canvasCss: `
    .gjs-selected {
      outline: 2px solid #000 !important;
    }
    `,
      blockManager: {
        appendTo: "#blocks",
      },
      styleManager: {
        appendTo: "#style-manager-container",
      },
      traitManager: {
        appendTo: "#traits-container",
      },
      layerManager: {
        appendTo: "#layers-manager-container",
        custom: true,
      },
      plugins: pluginsConfig[routeState.type_template],
      pluginsOpts: {
        [newsletter]: {
          blocks: [
            "sect100",
            "sect50",
            "sect30",
            "sect37",
            "button",
            "divider",
            "text",
            "text-sect",
            "image",
            "quote",
            "grid-items",
            "list-items",
          ],
          category: "Email Components", // Nome personalizado da categoria
        },
        [blocksBasic]: {
          blocksBasicOpts: false,
          category: "Componentes",
        },
        [blocksBasic]: {
          blocks: [
            "column1",
            "column2",
            "column3",
            "text",
            "link",
            "image",
            "video",
            routeState.type_template == "web" ? "map" : null,
          ],
          category: {
            label: "Componentes",
            open: false,
          },
        },
      },
      blockManager: {
        appendTo: "#blocks",
        blocks: [
          {
            id: "link-block",
            label: "Link Block",
            type: "web",
            category: "Componentes",
            content: '<a href="#" class="link-block">Link Block</a>',
            attributes: { class: "fa fa-link" },
          },
          {
            id: "quote",
            label: "Quote",
            type: "web",
            category: "Componentes",
            content: "<blockquote>Insert quote here</blockquote>",
            attributes: { class: "fa fa-quote-right" },
          },

          {
            id: "text-basic",
            label: "Text Section",
            type: "web",
            category: "Componentes",
            content:
              '<section class="bdg-sect"><h1 class="heading">Insert title here</h1><p class="paragraph">Insert text here</p></section>',
            attributes: { class: "fa fa-font" },
          },
          ...formList,
        ],
      },
    };
    //segmentar por categoria
    editorConfig.blockManager.blocks = editorConfig.blockManager.blocks.filter(
      (e) => {
        if (routeState.type_template != "web")
          return e.category != "Formulários" && e.type != "web";
        else return e;
      }
    );
    editor = grapesjs.init(editorConfig);
    //editor.Panels.removeButton('options', 'export-template');
    editor.Panels.removeButton("options", "fullscreen");
    editor.Panels.removeButton("options", "preview");
    //editor.Panels.removeButton('options', 'gjs-open-import-webpage');

    editor.Panels.addButton("options", {
      id: "save-template",
      className: "custom-save-button", // Classe personalizada para estilização
      label: `<i class="fa fa-save"></i> Salvar`, // Ícone + Texto
      command: "save-template", // Nome do comando
      attributes: { title: "Salvar" }, // Tooltip
    });

    editor.Panels.addButton("options", {
      id: "save-template-exit",
      className: "custom-save-button", // Classe personalizada para estilização
      label: `<i class="fa fa-save"></i> Salvar e Sair`, // Ícone + Texto
      command: "save-template-exit", // Nome do comando
      attributes: { title: "Salvar e Sair" }, // Tooltip
    });

    editor.Panels.addButton("options", {
      id: "exit",
      className: "custom-save-button", // Classe personalizada para estilização
      label: `<i class="fa fa-close"></i> Sair`, // Ícone + Texto
      command: "exit", // Nome do comando
      attributes: { title: "Sair" }, // Tooltip
    });

    editor.Commands.add("exit", {
      run: async () => {
        goBackUrl();
      },
    });

    editor.Commands.add("save-template", {
      run: async () => {
        setRedirect(false);
        if (routeState.editMode) {
          setConfirmEditTemplate(true);
        } else {
          setConfirmSaveTemplate(true);
        }
      },
    });

    editor.Commands.add("save-template-exit", {
      run: async () => {
        if (routeState.editMode) {
          setConfirmExitEditTemplate(true);
          setConfirmEditTemplate(true);
        } else {
          setConfirmExitSaveTemplate(true);
          setConfirmSaveTemplate(true);
        }
      },
    });

    //remove uneeded body wrapper from mjml
    editor.getWrapper().toHTML = function (opts) {
      return this.getInnerHTML(opts);
    };

    //open style manager once block is dropped or clicked
    editor.on("block:drag:stop, component:selected", function (model) {
      let tagName = model?.attributes?.tagName;
      if (tagName && tagName != "mj-section") {
        window.openPanel("styles");
      }
    });

    //prevent janky rendering

    // Adicione este código após a inicialização do editor
    editor.on("load", () => {
      /* mudar cor dos ícones */
      const style = document.createElement("style");
      style.textContent = `
      .gjs-block__media i {
        color: #ff0000  !important;
      }
    `;
      document.head.appendChild(style);

      /* resto do bloco config */
      const categories = editor.BlockManager.getCategories();

      const renderCategory = (category) => {
        const view = category.view;
        const iconClass =
          categoryIcons[category.get("label")] || "fa fa-folder";
        const titleEl = view.el.querySelector(".gjs-title");
        if (titleEl) {
          titleEl.innerHTML = `
          <i class="${iconClass}" style="margin-right: 5px; font-size:22px;color:#9933dc;"></i>
          ${category.get("label")}
          <i class="fa fa-chevron-down" style="float: right; transition: transform 0.3s;"></i>
        `;
        }

        view.el.querySelector(".gjs-title").onclick = (e) => {
          e.preventDefault();
          const isOpen = category.get("open");

          categories.each((cat) => {
            if (cat !== category) {
              cat.set("open", false);
              const catTitleEl = cat.view.el.querySelector(".gjs-title");
              if (catTitleEl) {
                const chevron = catTitleEl.querySelector(
                  ".fa-chevron-down, .fa-chevron-up"
                );
                if (chevron) {
                  chevron.className = "fa fa-chevron-down";
                  chevron.style.transform = "rotate(0deg)";
                }
              }
            }
          });
          /* category.set("open", !isOpen); */
          const chevron = titleEl.querySelector(
            ".fa-chevron-down, .fa-chevron-up"
          );
          if (chevron) {
            chevron.className = !isOpen
              ? "fa fa-chevron-up"
              : "fa fa-chevron-down";
          }
        };
      };

      // Renderizar todas as categorias existentes
      categories.each(renderCategory);

      // Observar mudanças nas categorias
      editor.on("block-category:add", (category) => {
        renderCategory(category);
      });
      categories.each((category) => {
        category.set("open", false).on("change:open", (opened) => {
          if (opened.get("open")) {
            categories.each((cat) => {
              if (cat !== opened) cat.set("open", false);
            });
          }
        });
      });
      document.getElementById("gjs").style.display = "block";

      setLoading(false);
    });

    /* editor.Panels.addPanel({
      id: "options",
      buttons: [
        {
          id: "undo",
          className: "fa fa-undo",
          command: "undo",
          attributes: { title: "Undo" },
        },
        {
          id: "redo",
          className: "fa fa-redo",
          command: "redo",
          attributes: { title: "Redo" },
        },
        {
          id: "preview",
          className: "fa fa-eye",
          command: "preview",
          attributes: { title: "Preview" },
        },
        {
          id: "fullscreen",
          className: "fa fa-arrows-alt",
          command: "fullscreen",
          attributes: { title: "Fullscreen" },
        },
        {
          id: "export-template",
          className: "fa fa-code",
          command: "export-template",
          attributes: { title: "View Code" },
        },
      ],
    }); */

    editor.Panels.getButton("options", "sw-visibility").set("active", false);
    editor.Panels.getButton("views", "open-blocks").set("active", true);
    editor.Panels.removeButton("options", "fullscreen");
    editor.Panels.removeButton("options", "preview");

    editor.getWrapper().toHTML = function (opts) {
      return this.getInnerHTML(opts);
    };

    editor.on("block:drag:stop, component:selected", function (model) {
      let tagName = model?.attributes?.tagName;
      if (tagName && tagName != "mj-section") {
        window.openPanel("styles");
      }
    });
    if (location?.state?.editMode)
      loadHtmlIntoGrapesjs(editor, location?.state?.template?.htmlString);

    editor.Panels.removePanel("views");
    window.editor = editor;
  };

  useEffect(() => {
    grapeJsInit();
  }, []);
  return (
    <div>
      {loading && (
        <div className="loading-overlay " style={{ flexDirection: "column" }}>
          <div className="loading-spinner"></div>
        </div>
      )}

      {/* <link rel="stylesheet" href="./modal.css" />
      <link rel="stylesheet" href="./custom.css" /> */}
      <MetaTags>
        <title>Editor | Inteegra Plus</title>
      </MetaTags>
      <SweetAlert
        show={confirmSaveTemplate}
        info
        showCancel
        confirmBtnText="Salvar"
        cancelBtnText="Cancelar"
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="danger"
        title={`Salvar Template`}
        onConfirm={async () => {
          setIsloading(true);

          await createTemplate({
            jwt: props.state.global_user_data.data.token,
            payload: {
              name: templateName,
              type: routeState.type_template,
              htmlString: editor.getHtml(),
              htmlJson: editor.getHtml(),
              eventId: routeState.private ? routeState.id : null,
            },
          });
          setConfirmEditTemplate(false);
          setConfirmSaveTemplate(false);
          setIsloading(false);
          setSuccessMessage(true);
        }}
        onCancel={() => {
          setConfirmSaveTemplate(false);
        }}
      >
        Para continuar, defina um nome para esse template...
        <br />
        <br />
        <br />
        <FormGroup className="position-relative ">
          <Input
            className={
              errorInvalid ? "form-control is-invalid" : "form-control"
            }
            placeholder=""
            type="text"
            required
            onChange={(e) => setTemplateName(e.target.value)}
            value={templateName}
          />
          <FormFeedback style={{ textAlign: "left", paddingTop: 13 }}>
            Preencha o campo nome do template.
          </FormFeedback>
        </FormGroup>
      </SweetAlert>

      {confirmEditTemplate && !isLoading && (
        <SweetAlert
          info
          showCancel
          confirmBtnText="Aplicar"
          cancelBtnText="Cancelar"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          title={`Editar Template`}
          onConfirm={async () => {
            setIsloading(true);

            await updateTemplate({
              jwt: props.state.global_user_data.data.token,
              payload: {
                id: routeState.template.id,
                name: routeState.template.name,
                type: routeState.type_template,
                htmlString: editor.getHtml(),
                htmlJson: editor.getHtml(),
                eventId: null,
              },
            });
            setConfirmEditTemplate(false);
            setIsloading(false);
            setSuccessMessage(true);
          }}
          onCancel={() => {
            setConfirmEditTemplate(false);
          }}
        >
          Aplicar alterações no template?
          <br />
          <br />
          <br />
        </SweetAlert>
      )}

      <SweetAlert
        show={successMessage}
        success
        confirmBtnText="OK"
        confirmBtnBsStyle="info"
        title={`Sucesso`}
        onConfirm={() => {
          setSuccessMessage(false);
          if (confirmExitEditTemplate) {
            goBackUrl();
          }
          if (confirmExitSaveTemplate) {
            goBackUrl();
          }
        }}
        onCancel={() => {
          setSuccessMessage(false);
        }}
      >
        Template foi salvo com sucesso.
      </SweetAlert>

      <div
        id="spectre"
        className="spectre"
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "stretch",
          flexWrap: "nowrap",
          height: "100vh",
        }}
      >
        <div id="style-manager" className="column" style={{ flexBasis: 500 }}>
          <ul className="tab tab-block">
            <li className="tab-item active" data-panel-type="content">
              <a href="#">Content</a>
            </li>
            <li className="tab-item" data-panel-type="styles">
              <a href="#">Styles</a>
            </li>
            <li className="tab-item" data-panel-type="layers">
              <a href="#">Layers</a>
            </li>
          </ul>
          <div id="content" className="tab-content">
            <div id="blocks" />
          </div>
          <div id="styles" className="tab-content" style={{ display: "none" }}>
            <div id="selectors-container" />
            <div id="traits-container" />
            <div id="style-manager-container" />
          </div>
          <div id="layers" className="tab-content" style={{ display: "none" }}>
            <div id="layers-manager-container" />
          </div>
        </div>
        <div className="column editor-clm">
          <div id="gjs"></div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return { state };
};

const mapDispatchToProps = function (dispatch) {
  return {
    openEvent: (data) => {
      return dispatch({ type: "OPEN_EVENT_MENU", data });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TemplateEditor);
