import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Modal, ModalBody, ModalHeader, Row, Col, Button } from "reactstrap";
import Select from "react-select";
import SweetAlert from "react-bootstrap-sweetalert";
import { connect } from "react-redux";
import {
  getContract,
  changeContractLogin,
} from "../../../utils/services/contracts";
import { refreshLogin } from "../../../utils/services/loginUser";

function EditContractSelected(props) {
  const currentContractId = props.state.global_user_data.data.theme[0].id;
  const currentContractName = props.state.global_user_data.data.theme[0].ic;
  const loginId = props.state.global_user_data.data.theme[0].ic;
  const { isOpen, toggle } = props;
  const [selectedGroup, setselectedGroup] = useState(null);
  const [contracts, setContracts] = useState([]);
  const [contractError, setContractError] = useState("");
  const [selectedContract, setSelectedContract] = useState({
    label: currentContractName,
    value: currentContractId,
  });

  const [showModalSuccess, setShowModalSuccess] = useState(false);

  const history = useNavigate();

  function getAllContracts() {
    getContract({
      jwt: props.state.global_user_data.data.token,
      offset: 1,
      limit: 50,
      search: "",
      status: true,
    }).then((res) => {
      if (res.data.contracts) {
        setContracts(res.data.contracts);
      }
    });
  }

  function submitForm(e) {
    setShowModalSuccess(false);
    if (e.target[0].value === "Selecione") {
      setContractError("Selecione um contrato");
      return;
    } else setContractError("");

    try {
      changeLoginContract();
    } catch (error) {}
  }

  async function changeLoginContract() {
    const data = {
      loginId: props?.state?.global_user_data?.data?.foundLogin?.id,
      contractId: selectedContract?.value,
    };
    const res = await changeContractLogin(data);
    updateGlobalState();
  }

  async function updateGlobalState() {
    const email = props.state.global_user_data.data.foundLogin.email;
    const dataState = await refreshLogin({ email });
    props.saveUserGlobalData(dataState);
    setShowModalSuccess(true);
  }

  useEffect(() => {
    getAllContracts();
  }, []);

  // useEffect(() => {
  //   console.log("props", props.state.global_user_data.data.theme[0].ic);
  // }, [props]);

  return (
    <>
      {showModalSuccess && (
        <SweetAlert
          success
          title="Sucesso"
          onConfirm={() => {
            setShowModalSuccess(false);
            toggle();
            window.location.href = "/events";
          }}
        >
          Contrato alterado com sucesso
        </SweetAlert>
      )}

      <Modal isOpen={isOpen} autoFocus={true} centered={true}>
        <ModalHeader toggle={toggle}>Selecionar Contrato</ModalHeader>
        <ModalBody
          style={{
            overflow: "visible",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <form
            onSubmit={(e) => {
              e.preventDefault();
              submitForm(e);
            }}
            style={{ width: "100%" }}
          >
            <Row lg={12}>
              <form onSubmit={(e) => e.preventDefault()}>
                <div>
                  <Select
                    value={selectedContract}
                    placeholder={"Selecione o contrato"}
                    isMulti={false}
                    isClearable
                    isSearchable={true}
                    onChange={(e) => {
                      setSelectedContract(e);
                    }}
                    options={[
                      {
                        options: [
                          ...contracts?.map((e) => {
                            return {
                              label: e.name,
                              value: e.id,
                            };
                          }),
                        ],
                      },
                    ]}
                    classNamePrefix="select2-selection"
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base) => ({
                        ...base,
                        zIndex: 9999,
                      }),
                      menu: (provided) => ({
                        ...provided,
                        maxHeight: 150,
                      }),
                      menuList: (provided) => ({
                        ...provided,
                        maxHeight: 150,
                        overflowY: "auto",
                      }),
                    }}
                  />
                </div>
              </form>
            </Row>
            <Row
              style={{
                width: "100%",
                margin: "20px 0 0 0",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                color="success"
                className="btn btn-success waves-effect waves-light"
                style={{ width: 130 }}
                disabled={
                  currentContractId === selectedContract?.value ||
                  !selectedContract?.value
                }
              >
                Alterar Contrato
              </Button>
            </Row>
          </form>
        </ModalBody>
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => {
  return { state };
};

const mapDispatchToProps = function (dispatch) {
  return {
    saveUserGlobalData: (data) => {
      return dispatch({ type: "SAVE_GLOBAL_USER_DATA", data });
    },
    // saveMsalInstance: (data) => {
    //   // console.log(data);
    //   return dispatch({ type: "MSAL_INSTANCE", data });
    // },
    // saveMfa: (data) => {
    //   // console.log(data);
    //   return dispatch({ type: "MFA_TOKEN", data });
    // },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditContractSelected);
