import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody, Row, Col, Button } from "reactstrap";
// alerta
import SweetAlert from "react-bootstrap-sweetalert";

import {
  distributeScheduleQuotas,
  getListSessions,
} from "../../../utils/services/scheduleCotas";
import { useEffect } from "react";
import ButtonSpinner from "../../../components/Buttons/ButtonSpinner";

export default function ModalConfigQuotas(props) {
  const { toggle, isOpen, data, jwt } = props;
  const [isLoading, setIsloading] = useState(false);

  // alerta
  const [alertSuccess, setAlertSuccess] = useState(false);
  const [alertError, setAlertError] = useState(false);

  const [cotasManuais, setCotasManuais] = useState(null);
  const [cotasFechadas, setCotasFechadas] = useState(data?.cotasFechadas);
  const [cotasSorteio, setCotasSorteio] = useState(data?.cotasSorteio);

  const [cotasManuaisAvailable, setCotasManuaisAvailable] = useState(null);
  const [cotasFechadasAvailable, setCotasFechadasAvailable] = useState(
    data?.cotasFechadas
  );
  const [cotasSorteioAvailable, setCotasSorteioAvailable] = useState(
    data?.cotasSorteio
  );

  const [msgError, setMsgError] = useState("");

  async function submitForm() {
    if (cotasFechadas < data?.cotasFechadas - cotasFechadasAvailable) {
      setMsgError(
        "Quantidade de Cotas Fechadas deve ser maior ou igual as utilizadas."
      );
      setAlertError(true);
      return;
    }
    if (cotasSorteio < data.cotasSorteio - cotasSorteioAvailable) {
      setMsgError(
        "Quantidade de Cotas Sorteio deve ser maior ou igual as utilizadas."
      );
      setAlertError(true);
      return;
    }

    if (
      data.qtdCotas <
      parseInt(cotasSorteio) + parseInt(cotasFechadas) + parseInt(cotasManuais)
    ) {
      setMsgError(
        "Quantidade de cotas maior do que a permitida para a agenda."
      );
      setAlertError(true);
      return;
    }
    setIsloading(true);
    const res = await distributeScheduleQuotas({
      id: data.id,
      cotasFechadas,
      cotasSorteio,
      jwt,
    });
    setIsloading(false);

    if (res.message === "AGENDA_COTAS_UPDATED") {
      setAlertSuccess(true);
    } else {
      setMsgError(
        "Quantidade de Cotas Sorteio deve ser maior ou igual as utilizadas."
      );
      setAlertError(true);
    }
  }

  useEffect(() => {
    getAvailableQuotas();
  }, [isOpen]);

  async function getAvailableQuotas() {
    const res = await getListSessions(data.eventId, jwt);
    const agendaAvailables = res.data.cotasManuais;

    if (agendaAvailables) {
      const pickAgenda = agendaAvailables.filter(
        (e) => e.agendaid === data.agendaId
      );

      setCotasManuais(pickAgenda[0].totalmanuais);

      setCotasManuaisAvailable(pickAgenda[0].availablemanuais);
      setCotasFechadasAvailable(pickAgenda[0].availablefechadas);
      setCotasSorteioAvailable(pickAgenda[0].availablesorteio);
    }
  }

  function updateAvailableQuotes(type, itemChanged) {
    const totalCotas = data.qtdCotas;
    const availableCotas =
      type === "F"
        ? totalCotas - cotasManuais - itemChanged - cotasSorteio
        : totalCotas - cotasManuais - cotasFechadas - itemChanged;

    if (availableCotas >= 0) {
      switch (type) {
        case "F":
          setCotasFechadas(itemChanged);
          break;
        case "S":
          setCotasSorteio(itemChanged);
          break;
        default:
          return;
      }
      setCotasManuaisAvailable(availableCotas);
    }
  }

  return (
    <>
      {alertSuccess ? (
        <SweetAlert
          success
          title={"Salvo"}
          onConfirm={() => {
            toggle();
          }}
        >
          {"Cotas salvas com sucesso!"}
        </SweetAlert>
      ) : null}

      {alertError ? (
        <SweetAlert
          error
          title={"Erro"}
          onConfirm={() => {
            setAlertError(false);
            // toggle();
          }}
        >
          {msgError}
        </SweetAlert>
      ) : null}

      <Modal isOpen={isOpen} autoFocus centered>
        <ModalHeader toggle={toggle}>Distribuição de cotas</ModalHeader>
        <ModalBody>
          <Row>
            <Col lg={12}>
              <div>
                <label className="form-label">Cotas Disponíveis</label>
                <input
                  disabled
                  className="form-control"
                  value={cotasManuaisAvailable}
                  type="text"
                  style={{ backgroundColor: "#e9e9e9" }}
                />
              </div>
            </Col>
          </Row>
          <Row style={{ marginTop: 10 }}>
            <Col lg={12}>
              <div>
                <label className="form-label">Cotas Manuais</label>
                <input
                  disabled
                  className="form-control"
                  value={cotasManuais}
                  type="text"
                  style={{ backgroundColor: "#e9e9e9" }}
                />
              </div>
            </Col>
          </Row>

          <Row style={{ marginTop: 10 }}>
            <Col lg={6}>
              <div>
                <label className="form-label">Cotas Fechadas</label>
                <input
                  className="form-control"
                  type="number"
                  value={cotasFechadas}
                  onChange={(e) => updateAvailableQuotes("F", e.target.value)}
                  min={1}
                />
              </div>
            </Col>
            <Col lg={6}>
              <div>
                <label className="form-label">Cotas Fechadas Utilizadas</label>
                <input
                  className="form-control"
                  type="number"
                  value={data?.cotasFechadas - cotasFechadasAvailable}
                  disabled
                  style={{ backgroundColor: "#e9e9e9" }}
                />
              </div>
            </Col>
          </Row>

          <Row style={{ marginTop: 10 }}>
            <Col lg={6}>
              <div>
                <label className="form-label">Cotas Sorteio</label>
                <input
                  className="form-control"
                  type="number"
                  value={cotasSorteio}
                  onChange={(e) => updateAvailableQuotes("S", e.target.value)}
                  min={1}
                />
              </div>
            </Col>
            <Col lg={6}>
              <div>
                <label className="form-label">Cotas Sorteio Utilizadas</label>
                <input
                  className="form-control"
                  type="number"
                  value={data?.cotasSorteio - cotasSorteioAvailable}
                  disabled
                  style={{ backgroundColor: "#e9e9e9" }}
                />
              </div>
            </Col>
          </Row>

          <Row
            style={{
              width: "100%",
              margin: "20px auto 0 auto",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              color="danger"
              style={{ width: 80, marginRight: 10 }}
              onClick={() => toggle()}
            >
              Fechar
            </Button>

            <ButtonSpinner
              isLoading={isLoading}
              title={"Salvar"}
              onClick={submitForm}
            />
          </Row>
        </ModalBody>
      </Modal>
    </>
  );
}
