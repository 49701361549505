import React from 'react'

export default function SectionComponent({setIsOpen, isOpen, sectionName, index, deleteSection}) {
  return (
    <div
      className="personalization-section"
      
    >
      <span>{sectionName}</span>
      <div className="section-options">
        <i
          style={{ cursor: 'pointer', fontSize: '15px', color: 'red', fontWeight: '900', marginRight: '5px' }}
          className="mdi mdi-close"
          title="Remover checkbox"
          onClick={() => deleteSection(index)}
        />
        <i
          className={isOpen ? 'mdi mdi-arrow-up' : 'mdi mdi-arrow-down'}
          style={{ marginTop: 2 }}
          onClick={() => setIsOpen(index)}
        />
      </div>
    </div>
  )
}
