import writeXlsxFile from 'write-excel-file';
import { getReportScheduleQuotes } from '../../../utils/services/scheduleCotas';
import { formatData } from '../../../utils/helpers/files/formatDate';

export async function createXlsxScheduleQuotas({
  jwt,
  id,
  limit,
  countData,
  setFinishExport,
}) {
  const schema = [
    {
      column: 'Id',
      type: String,
      value: (schedule) => schedule.id,
    },
    {
      column: 'Título',
      type: String,
      value: (schedule) => schedule.agendaTitle,
    },
    {
      column: 'Data',
      type: String,
      value: (schedule) => formatData(schedule.startDate),
    },
    {
      column: 'Hora Início',
      type: String,
      value: (schedule) => schedule.startHour,
    },
    {
      column: 'Hora Fim',
      type: String,
      value: (schedule) => schedule.endHour,
    },
    {
      column: 'Quantidade de Cotas',
      type: Number,
      value: (schedule) => schedule.qtdCotas,
    },

    {
      column: 'Cotas Disponíveis',
      type: Number,
      value: (schedule) =>
        schedule.qtdCotas -
        (schedule.totalManual + schedule.cotasFechadas + schedule.cotasSorteio),
    },
    {
      column: 'Cotas Manuais',
      type: Number,
      value: (schedule) => schedule.totalManual,
    },
    {
      column: 'Cotas Fechadas',
      type: Number,
      value: (schedule) => schedule.cotasFechadas,
    },
    {
      column: 'Cotas Fechadas Utilizadas',
      type: Number,
      value: (schedule) => schedule.totalFechadas,
    },
    {
      column: 'Cotas Sorteio',
      type: Number,
      value: (schedule) => schedule.cotasSorteio,
    },
    {
      column: 'Cotas Sorteio Utilizadas',
      type: Number,
      value: (schedule) => schedule.totalSorteio,
    },
    {
      column: 'Grupos/IDs',
      type: String,
      value: (schedule) => schedule.grupos.map((e) => e.id).join(', '),
    },
    {
      column: 'Grupos/Descrição',
      type: String,
      value: (schedule) => schedule.grupos.map((e) => e.name).join(', '),
    },
  ];

  const allRequestsPromises = [];
  const numberOfRequisitions = Math.ceil(countData / limit);

  for (let i = 1; i <= numberOfRequisitions; i += 1) {
    const request = await getReportScheduleQuotes({
      jwt,
      id,
    });
    allRequestsPromises.push(request);
    setTimeout(() => {}, 500);

    const progressBar = document.querySelector('.export-progress');
    const percentageBar = document.querySelector('.export-percentage');

    progressBar.style.width = `${(i / numberOfRequisitions) * 100}%`;
    percentageBar.innerHTML = `${((i / numberOfRequisitions) * 100).toFixed(
      0
    )}%`;

    if (i === numberOfRequisitions) {
      setTimeout(() => {
        setFinishExport(true);
      }, 1000);
    }
  }

  const allDataInSingleArray = allRequestsPromises
    .map((e) => e.data.agenda)
    .flat();
  if (allDataInSingleArray) {
    await writeXlsxFile(allDataInSingleArray, {
      schema,
      fileName: 'AgendaCotas.xlsx',
    });
  }
}
