import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader, Row, Col, Button } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
// service
import { deleteUserPoints } from "../../../utils/services/ranking";

export default function ModalConfirmDeleteUserPoints(props) {
  const { isOpen, toggle, idEvent, getAllRankingFunc, selectedPlayer } = props;
  const [confirm, setConfirm] = useState("");
  // alerta
  const [alertConfirm, setAlertConfirm] = useState(false);

  function confirmeDelete() {
    deleteUserPoints(idEvent, selectedPlayer.loginId).then((res) => {
      if (res.message === "deleted") {
        setAlertConfirm(true);
        getAllRankingFunc();
      }
    });
    //   .catch((error) => console.log(error));

    return;
  }

  return (
    <>
      {alertConfirm && (
        <SweetAlert
          title="Sucesso!"
          success
          onConfirm={() => toggle() > setConfirm(false)}
        >
          Pontuação do ranking apagada com sucesso
        </SweetAlert>
      )}

      <Modal isOpen={isOpen} centered>
        <ModalHeader toggle={toggle}>
          Deletar Pontos do Participante
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col md={12}>
              <label className="form-label">
                Escreva{" "}
                <span style={{ color: "red", fontWeight: "bolder" }}>
                  "sim, desejo deletar"
                </span>{" "}
                e confirme para deletar
              </label>
              <input
                className="form-control"
                type="text"
                onChange={(e) => setConfirm(e.target.value)}
              />
            </Col>
          </Row>

          <Row style={{ marginTop: 20 }}>
            <Col
              md={12}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Button
                className="btn-danger"
                disabled={confirm !== "sim, desejo deletar"}
                onClick={() => confirmeDelete()}
              >
                Confirmar
              </Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </>
  );
}
