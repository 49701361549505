//service
import { getMatch } from "../../../utils/services/match";

export const excelColumnsPtBr = {
  Hospede1: "host_one",
  Hospede2: "host_two",
  Hospede3: "host_three",
  Hospede4: "host_four",
};

export function allExcelColumns(row, index) {
  const formatedRow = {
    index,
    host_one: row.host_one ? row.host_one : undefined,
    host_two: row.host_two ? row.host_two : undefined,
    host_three: row.host_three ? row.host_three : "Vazio",
    host_four: row.host_four ? row.host_four : "Vazio",
  };

  return formatedRow;
}

export function findRowsWithError(validRows) {
  const rowsWithError = validRows.filter(
    (row) => row.host_one === undefined || row.host_two === undefined
  );

  return rowsWithError;
}

// pegar todos os usuários de todos os campos
async function getUsers(rows) {
  var allUser = [];

  rows.map((e) => {
    allUser.push(e.host_one);
    allUser.push(e.host_two);

    if (e.host_three && e.host_three != "Vazio") allUser.push(e.host_three);
    if (e.host_four && e.host_four != "Vazio") allUser.push(e.host_four);
  });

  return allUser;
}

export async function verifyUser(rows, users) {
  var allUsers = await getUsers(rows);

  var userNotFound = [];

  allUsers.map((e) => {
    const search = users.find((user) => user.email === e);

    if (!search) {
      userNotFound.push(e);
    }
  });

  var rowErro = [];

  rows.forEach((row) => {
    var currentRow = row;
    var changed = false;

    Object.keys(row).forEach((chave) => {
      if (userNotFound.includes(row[chave])) {
        currentRow[chave] = undefined;

        changed = true;
      }
    });

    if (changed) rowErro.push(currentRow);
  });

  return rowErro;
}

export async function verifyDuplicity(rows) {
  var allUser = await getUsers(rows);

  var uniqueUser = [];
  var duplicatedUser = [];

  // validar quais são repetidos
  allUser.forEach((element) => {
    const isDuplicate = uniqueUser.includes(element);

    if (!isDuplicate) {
      uniqueUser.push(element);
    } else if (isDuplicate && !duplicatedUser.find((e) => e === element)) {
      duplicatedUser.push(element);
    } else return;
  });

  var rowsUserDuplicated = [];

  // setar undefined nos campos com emails repetidos
  duplicatedUser.map((duplicated) => {
    rows.map((row) => {
      var rowDuplicated = row;
      var changed = false;

      Object.entries(row).forEach(([key, value]) => {
        if (value === duplicated) {
          rowDuplicated = {
            ...rowDuplicated,
            [key]: undefined,
          };

          changed = true;
        }
      });

      if (changed) rowsUserDuplicated.push(rowDuplicated);
    });
  });

  return rowsUserDuplicated;
}

export async function verifyRooms(rows, rooms) {
  const double = rooms.find((e) => e.descricao === "Duplo");
  const triple = rooms.find((e) => e.descricao === "Triplo");
  const quadruple = rooms.find((e) => e.descricao === "Quadruplo");

  const totalDouble = double ? double.disponivel : 0;
  const totalTriple = triple ? triple.disponivel : 0;
  const totalQuadruple = quadruple ? quadruple.disponivel : 0;

  var roomDouble = [];
  var roomTriplo = [];
  var roomQuadruple = [];

  rows.map((e) => {
    if (e.host_four && e.host_four != "Vazio") {
      roomQuadruple.push(e);
    } else if (e.host_three && e.host_three != "Vazio") {
      roomTriplo.push(e);
    } else {
      roomDouble.push(e);
    }
  });

  var roomError = [];

  if (roomDouble.length > totalDouble) {
    roomError.push("Duplo");
  }

  if (roomTriplo.length > totalTriple) {
    roomError.push("Triplo");
  }

  if (roomQuadruple.length > totalQuadruple) {
    roomError.push("Quádruplo");
  }

  return roomError;
}

export async function verifyUserRegistered(rows, eventId) {
  const allMatch = await getAllMatch(eventId);

  var rowErro = [];

  rows.forEach((row) => {
    var currentRow = row;
    var changed = false;

    Object.keys(row).forEach((chave) => {
      if (allMatch.includes(row[chave])) {
        currentRow[chave] = undefined;

        changed = true;
      }
    });

    if (changed) rowErro.push(currentRow);
  });

  return rowErro;
}

async function getAllMatch(eventId) {
  var allData = [];

  await getMatch({
    eventId: eventId,
    limit: 100000000,
    offset: 1,
    search: "",
  }).then((res) => {
    if (res.count > 0) {
      res.data.map((e) => {
        allData.push(e.login_email_1);
        allData.push(e.login_email_2);

        if (e.login_email_3) allData.push(e.login_email_3);
        if (e.login_email_4) allData.push(e.login_email_4);
      });
    }
  });

  return allData;
}
