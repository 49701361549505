import React, { useState } from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import SalesPieChart from './SalesPieChart';
import BrazilMap from './BrazilMap';
import SalesLineChart from './SalesLineChart';
import BrazilMapLegend from './BrazilMapLegend';
import StatesDetails from './StatesDetails';
import SalesTable from './SalesTable';

export default function DashTupperware(props) {
  const [brazilianStates, setBrazilianStates] = useState();
  console.log(brazilianStates)
  return (
    <>
      <Row>
        <Col>
          <Card className="card-shadow">
            <CardBody>
              <Row>
                <Col xs="12" lg="5" s="5">
                  <SalesPieChart data={{}} title="Pagamentos" />
                </Col>
                <Col xs="12" lg="7" s="7">
                  <SalesLineChart data={{}} title="Pagamentos" />
                </Col>
              </Row>
              <Row>
                <div className="dashboard-access-footer">
                  <span style={{ color: "black", fontWeight: 700 }}>
                    Total vendido:{" "}
                    R$ 37.000,00
                  </span>
                  <span style={{ color: "black", fontWeight: 700 }}>
                    Total de ingressos:{" "}
                    402
                  </span>
                </div>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card className="card-shadow">
            <CardBody>
              <Row>
                <Col xs="12" lg="4" s="4">
                  <div className="map-brazil">
                    <span className="title">Ingressos por estado</span>
                  </div>
                  <div style={{
                    marginTop: "20px",
                  }}>
                    <StatesDetails />
                  </div>
                </Col>
                <Col xs="12" lg="8" s="8">
                  <CardBody>
                    <div
                      style={{
                        maxWidth: "50vh",
                        marginLeft: "30%",
                        marginTop: "20px",
                        position: "relative",
                        right: "0",
                      }}
                    >
                      <BrazilMap setBrazilianStates={setBrazilianStates} />
                      <BrazilMapLegend />
                    </div>
                  </CardBody>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card className="card-shadow">
            <CardBody>
              <div className="map-brazil">
                <span className="title">Extrato de vendas</span>
              </div>
              <div style={{
                marginTop: "20px",
              }}>
                <SalesTable />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  )
}
