import React, { useCallback, useEffect, useState } from "react";
import ReactDOMServer from "react-dom/server";
import {
  Card,
  CardBody,
  Button,
  CardTitle,
  Row,
  Col,
  Collapse,
} from "reactstrap";
import SectionComponent from "../../Apps/Personalization/SectionComponent";

import FormMounted from "./FormMounted"; // Importe o componente que criamos acima

export default function ViewForm(props) {
  const { selectedForm } = props;

  const [form, setForm] = useState([]);
  const [saveForm, setSaveForm] = useState(false);

  // collapse btn
  const [isOpen, setIsOpen] = useState(false);

  const handleSave = () => {
    setSaveForm(true);
    setTimeout(() => {
      setSaveForm(false);
    }, 5000);
  };

  useEffect(() => {
    if (selectedForm.json) {
      const json = JSON.parse(selectedForm.json);
      setForm(json);
    }
  }, [selectedForm.json]);

  return (
    <div>
      <Card>
        <CardTitle>Visualizar</CardTitle>
        <CardBody>
          <div className="mobile-btn-section" style={{ width: "100%" }}>
            <SectionComponent
              sectionName="Opções"
              setIsOpen={setIsOpen}
              isOpen={isOpen}
            />
            <Collapse isOpen={isOpen}>
              <Row className="mobile-btns-top">
                <div onClick={() => props.setStep("_a")}>Voltar</div>
              </Row>
            </Collapse>
          </div>
          <div className="header-btns-container">
            <Button
              color="danger"
              style={{ height: 40, width: 80 }}
              onClick={() => {
                props.setStep("_a");
              }}
            >
              Voltar
            </Button>
          </div>
          <FormMounted form={form} saveForm={saveForm} setForm={setForm} />
        </CardBody>
      </Card>
    </div>
  );
}
