import React, { useState } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Col,
  Row,
  Input,
  Button,
} from "reactstrap";

//redux
import { connect } from "react-redux";

import SweetAlert from "react-bootstrap-sweetalert";

//service
import { newFeed } from "../../../utils/services/feed";
import { getUrlFiles } from "../../../utils/services/files";
import ButtonSpinner from "../../../components/Buttons/ButtonSpinner";

function NewFeed(props) {
  const { isOpen, toggle } = props;
  const [modalBackdrop, setModalBackdrop] = useState(false);
  //confirmação
  const [success_dlg, setsuccess_dlg] = useState(false);
  const [textoErro, setTextoErro] = useState("");
  const [imageErro, setImageErro] = useState("");
  const [isLoading, setIsloading] = useState(false);

  function toggleBackdrop() {
    setModalBackdrop(!modalBackdrop);
  }

  async function submitForm(e) {
    if (e.target[0].value === "" && e.target[1].files[0] === undefined) {
      setTextoErro("O campo Texto é obrigatório.");
      return;
    } else setTextoErro("");

    if (e.target[0].value === "" && e.target[1].files[0] === undefined) {
      setImageErro("O campo Foto é obrigatório.");
      return;
    } else setImageErro("");

    let urlImg = "";
    let expireDate = null;

    var data = new FormData();
    data.append("files", e.target[1].files[0]);
    data.append("folder", "feed");
    data.append("eventId", props.idEvent);

    await getUrlFiles(data, props.state.global_user_data.data.token)
      .then((response) => {
        response.map((e) => {
          urlImg = e.url;
          expireDate = e.expires;
        });
      })
      .catch((error) => {
        // console.log(error);
      });

    const dataPost = {
      eventid: props.idEvent,
      title: "",
      text: e.target[0].value,
      url: urlImg,
      active: e.target[2].checked,
      fixed: e.target[3].checked,
      date: new Date(),
      loginadmin_id: props.state.global_user_data.data.foundLogin.id,
      postsource: null,
      expireDate: expireDate,
    };
    setIsloading(true);
    var feedData = await newFeed(
      dataPost,
      props.state.global_user_data.data.token
    );
    setIsloading(false);
    if (feedData.message === "created") {
      setsuccess_dlg(true);
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => {
        toggleBackdrop();
      }}
      autoFocus={true}
      centered={true}
    >
      {success_dlg ? (
        <SweetAlert
          success
          title={"Criado"}
          onConfirm={() => {
            props.getAllFeed();
            setsuccess_dlg(false);
            toggle();
          }}
        >
          {"Post criado com sucesso!"}
        </SweetAlert>
      ) : null}
      <ModalHeader toggle={toggle}>Novo Post</ModalHeader>
      <ModalBody>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            submitForm(e);
          }}
        >
          <Row>
            <Col lg={12}>
              <div>
                <label className="form-label">Texto</label>
                <textarea className="form-control" type="text" />
                <p style={{ color: "red" }}>{textoErro}</p>
              </div>
            </Col>
          </Row>

          <Row style={{ marginTop: 20, marginBottom: 20 }}>
            <Col lg={12}>
              <div>
                <label className="form-label">Foto</label>
                <Input className="form-control" type="file" id="formFile" />
                <p style={{ color: "red" }}>{imageErro}</p>
              </div>
            </Col>
          </Row>

          <Row style={{ marginTop: 20, marginBottom: 20 }}>
            <Col md={6}>
              <div className="form-check form-check-end">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  defaultChecked
                />
                <label>Visível</label>
              </div>
            </Col>

            <Col md={6}>
              <div className="form-check form-check-end">
                <input className="form-check-input" type="checkbox" />
                <label>Fixado</label>
              </div>
            </Col>
          </Row>

          <Row
            style={{
              width: "100%",
              margin: "auto",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              color="danger"
              style={{ width: 80, marginRight: 10 }}
              onClick={() => toggle()}
            >
              Fechar
            </Button>
            <ButtonSpinner isLoading={isLoading} title={"Salvar"} />
          </Row>
        </form>
      </ModalBody>
    </Modal>
  );
}

const mapStateToProps = function (state) {
  return { state: state };
};

export default connect(mapStateToProps)(NewFeed);
