import React, { useEffect } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Link } from "react-router-dom";

function ModalPreview(args) {
  useEffect(() => {
    //console.log("modal", args);
  });
  return (
    <div className="modal_setter">
      <Modal isOpen={args.modal} toggle={args.toggle} {...args}>
        <ModalHeader toggle={args.toggle}>Pré-visualização</ModalHeader>
        <ModalBody style={{ overflow: "hidden" }}>
          <iframe
            title="_"
            id="serviceFrameSend"
            className="frame-fill"
            srcdoc={args.template.htmlString}
            width="138%"
            height="430"
            frameborder="0"
            scrolling="yes"
          />
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={args.toggle}>
            Fechar
          </Button>
          <Link
            to={args.internal ? "/event/templates" : "/html-editor"}
            state={{
              ...args.location,
              type_template: args.template.type,
              editMode: true,
              template: args.template,
            }}
          >
            <Button color="success" /* onClick={() => console.log(args)} */>
              Editar Template
            </Button>
          </Link>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default ModalPreview;
