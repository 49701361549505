import React, { useState } from "react";
//router dom
import { useNavigate } from "react-router-dom";
//
import { Input } from "reactstrap";
// availity-reactstrap-validation
import { AvForm } from "availity-reactstrap-validation";

//Toast
import toastr from "toastr";
import "toastr/build/toastr.min.css";

//services de primeiro acesso
import {
  firstAccessEmail,
  firstAccessConfirmation,
} from "../utils/services/loginUser";

export default function FirstAcess(props) {
  const [step, setStep] = useState("_a");
  //erro do primeiro formulário
  const [userFound, setUserFound] = useState("");
  //campos do formulário
  const [emailValue, setEmailValue] = useState("");
  const [tokenValue, setTokenValue] = useState("");
  const [passwordValue, setPasswordValue] = useState("");
  const [passwordConfirmValue, setPasswordConfirmValue] = useState("");
  const [viewPass, setViewPass] = useState(false);
  const [viewPassConfirmation, setViewPassConfirmation] = useState(false);
  //erro do segundo formuçário
  const [tokenErro, setTokenErro] = useState("");
  const [passwordErro, setPasswordErro] = useState("");
  const [passwordConfirmErro, setPasswordConfirmErro] = useState("");
  const [apiValidation, setApiValidation] = useState([]);
  const [errorHasCotas, setErrorHasCotas] = useState(false);
  const history = useNavigate();

  const submitFormEmail = async () => {
    setErrorHasCotas(false);

    if (emailValue === "") {
      setUserFound("Digite um email");
      return;
    }

    // if (emailValue.includes('@comgas.com.br')) {
    //   setErrorHasCotas(true);
    //   return;
    // }

    var userDataEmail = await firstAccessEmail({
      email: emailValue.replace(/ /gi, ""),
    });

    // if (userDataEmail.message === "EMAIL_NOT_FOUND") {
    //   setUserFound("Email não encontrado");
    // }

    // if (userDataEmail.message === "DENIED_FIRST_ACCESS_COMPLETED") {
    //   setUserFound(
    //     "Você já realizou ao menos um acesso ao sistema, faça o login ou reset a sua senha para acessar a área administrativa."
    //   );
    // }

    if (userDataEmail.message === "TOKEN_FIRST_ACCESS_SENDED_BY_EMAIL") {
      setUserFound("");
      toastr.options.progressBar = true;
      toastr.options.positionClass = "toast-top-center";
      // toastr.success(
      //   "Um token foi enviado pro seu email!",
      //   "Gerenciamento de Primeiro Acesso"
      // );
      toastr.success(
        "Caso o usuário fornecido seja válido, um token para o primeiro acesso será enviado via e-mail.",
        "Gerenciamento de Primeiro Acesso"
      );

      setStep("_b");
    }
  };

  const submitFormToken = async () => {
    if (tokenValue === "") {
      setTokenErro("Insira o token");
      return;
    } else setTokenErro("");

    if (passwordValue === "") {
      setPasswordErro("Digite uma senha");
      return;
    } else setPasswordErro("");

    if (passwordConfirmValue === "") {
      setPasswordConfirmErro("Confirme sua senha");
      return;
    } else setPasswordConfirmErro("");

    if (passwordValue !== passwordConfirmValue) {
      setPasswordConfirmErro("Senhas diferentes");
      return;
    } else setPasswordConfirmErro("");

    setApiValidation("");
    setTokenErro("");

    var userDataToken = await firstAccessConfirmation({
      email: emailValue,
      password: passwordValue,
      confirmPassword: passwordConfirmValue,
      token: tokenValue,
    });

    if (userDataToken.message === "FIRST_ACCESS_SUCCESS") {
      localStorage.setItem("authUser", Date.now());
      history("/events");
      return;
    }

    if (userDataToken.message === "TOKEN_ACCESS_INVALID_OR_EXPIRED") {
      setTokenErro("Token inválido ou expirado");
      return;
    }

    setApiValidation(userDataToken.message[0]);
  };

  return (
    <div className="p-2">
      {userFound && <p style={{ color: "red" }}>{userFound}</p>}
      {errorHasCotas && (
        <p style={{ color: "red" }}>
          Para acessar o sistema utilize a opção Acessar com SSO da tela de
          login
        </p>
      )}
      <AvForm className="form-horizontal">
        <div className="mb-3">
          <Input
            className="form-control"
            placeholder="Insira seu email"
            type="text"
            required
            disabled={step === "_a" ? false : true}
            onChange={(e) => setEmailValue(e.target.value)}
          />
        </div>

        {step === "_a" && (
          <div className="mt-3 d-grid">
            <button
              className="btn btn-primary btn-block waves-effect waves-light"
              onClick={submitFormEmail}
            >
              Gerar Token
            </button>
          </div>
        )}
      </AvForm>

      {step === "_b" && (
        <AvForm className="form-horizontal">
          {tokenErro && <p style={{ color: "red" }}>{tokenErro}</p>}
          <div className="mb-3">
            <Input
              className="form-control"
              placeholder="Insira o token enviado para o seu email"
              type="text"
              required
              onChange={(e) => setTokenValue(e.target.value)}
            />
          </div>

          <div style={{ display: "flex", flexDirection: "column" }}>
            <span style={{ fontWeight: "bold" }}>A senha deve ter:</span>
            <a>• Mínimo 8 caracteres</a>
            <a>• Letra Minúscula</a>
            <a>• Letra Maiúscula</a>
            <a>• Número</a>
            <a>• Caractere especial (ex: #, %, @, ^)</a>
          </div>

          {passwordErro && <p style={{ color: "red" }}>{passwordErro}</p>}
          <div
            className="mb-3"
            style={{ display: "flex", alignItems: "center" }}
          >
            <Input
              className="form-control"
              placeholder="Insira sua senha"
              type={!viewPass ? "password" : "text"}
              required
              onChange={(e) => setPasswordValue(e.target.value)}
            />
            <a onClick={() => setViewPass(!viewPass)}>
              {viewPass ? (
                <i className="mdi mdi-eye-off-outline icon-password" />
              ) : (
                <i className="mdi mdi-eye-outline icon-password" />
              )}
            </a>
          </div>
          {passwordConfirmErro && (
            <p style={{ color: "red" }}>{passwordConfirmErro}</p>
          )}
          <div
            className="mb-3"
            style={{ display: "flex", alignItems: "center" }}
          >
            <Input
              className="form-control"
              placeholder="Confirme sua senha"
              type={!viewPassConfirmation ? "password" : "text"}
              required
              onChange={(e) => setPasswordConfirmValue(e.target.value)}
            />
            <a onClick={() => setViewPassConfirmation(!viewPassConfirmation)}>
              {viewPassConfirmation ? (
                <i className="mdi mdi-eye-off-outline icon-password" />
              ) : (
                <i className="mdi mdi-eye-outline icon-password" />
              )}
            </a>
          </div>

          {apiValidation.length > 0 && (
            <div
              style={{ display: "flex", flexDirection: "column", color: "red" }}
            >
              {apiValidation.map((e) => (
                <a>• {e}</a>
              ))}
            </div>
          )}

          <div className="mt-3 d-grid">
            <button
              className="btn btn-primary btn-block waves-effect waves-light"
              onClick={submitFormToken}
            >
              Acessar Inteegra Plus
            </button>
          </div>
        </AvForm>
      )}
      <div className="mt-4 text-center">
        <p
          className="text-muted"
          style={{ cursor: "pointer" }}
          onClick={() => props.setScreen("_a")}
        >
          <i className="mdi mdi-lock me-1" />
          Voltar para tela de login
        </p>
      </div>
    </div>
  );
}
