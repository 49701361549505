import writeXlsxFile from "write-excel-file";
import { getAllVehicles } from "../../../utils/services/vehicles";

export async function createVeiculoXlsx({
  jwt,
  idEvent,
  limit,
  countData,
  setFinishExport,
}) {
  const formatDate = (date) => {
    if (!date) return "";
    const formatedDate = date.split("T")[0].split("-").reverse().join("/");
    if (date.split("T")[0].includes("1969")) return "";
    return `${formatedDate} ${date.split("T")[1].substring(0, 5)}`;
  };

  const schema = [
    {
      column: "Id Veículo",
      type: String,
      value: (v) => v.id,
    },
    {
      column: "Nome",
      type: String,
      value: (v) => v.description,
    },
    {
      column: "Origem",
      type: String,
      value: (v) => v.origin,
    },
    {
      column: "Destino",
      type: String,
      value: (v) => v.destination,
    },
    {
      column: "Data da Saída",
      type: String,
      value: (v) => formatDate(v.departureDatetime),
    },
    {
      column: "Data da Chegada",
      type: String,
      value: (v) => formatDate(v.arrivalDatetime),
    },
    {
      column: "Nome do Motorista",
      type: String,
      value: (v) => v.driverName,
    },
    {
      column: "Tipo De Veículo",
      type: String,
      value: (v) => v.vehicleType,
    },
    {
      column: "Capacidade",
      type: Number,
      value: (v) => v.seatingCapacity,
    },
    {
      column: "Utilizado",
      type: Number,
      value: (v) => v.amount,
    },
    {
      column: "Empresa",
      type: String,
      value: (v) => v.company,
    },
    {
      column: "Ponto de Encontro",
      type: String,
      value: (v) => v.meetingPoint,
    },

    {
      column: "Telefone",
      type: String,
      value: (v) => v.phone,
    },
    {
      column: "Observações",
      type: String,
      value: (v) => v.notes,
    },
  ];

  const allRequestsPromises = [];
  const numberOfRequisitions = Math.ceil(countData / limit);

  for (let i = 1; i <= numberOfRequisitions; i += 1) {
    const request = await getAllVehicles(idEvent, i, limit, "", "");
    // }).then((response) => console.log(response));
    // return;
    allRequestsPromises.push(request);
    setTimeout(() => {}, 500);

    const progressBar = document.querySelector(".export-progress");
    const percentageBar = document.querySelector(".export-percentage");
    progressBar.style.width = `${(i / numberOfRequisitions) * 100}%`;
    percentageBar.innerHTML = `${((i / numberOfRequisitions) * 100).toFixed(
      0
    )}%`;

    if (i === numberOfRequisitions) {
      setTimeout(() => {
        setFinishExport(true);
      }, 1000);
    }
  }

  const allDataInSingleArray = allRequestsPromises
    .map((e) => e.data.findAll.data)
    .flat();
  if (allDataInSingleArray) {
    await writeXlsxFile(allDataInSingleArray, {
      schema,
      fileName: "veiculos.xlsx",
    });
  }
}
