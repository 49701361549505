import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";

// Import menuDropdown
import LanguageDropdown from "./CommonForBoth/TopbarDropdown/LanguageDropdown";
import ProfileMenu from "./CommonForBoth/TopbarDropdown/ProfileMenu";

function Header(props) {
  // const [menu, setMenu] = useState(false);
  const [isSearch, setSearch] = useState(false);
  // const [socialDrp, setsocialDrp] = useState(false);

  // const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  function tToggle() {
    //props.setViewMenu(!props.viewMenu)
    if (props.state.layout_manager.drawer_openend) {
      props.closeNavBar();
    } else {
      props.openNavBar();
    }
  }

  // useEffect(() => {
  //   props.closeEvent();
  // }, [props.nome]);

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            {/* <div className="navbar-brand-box">
              <Link to="/" className="logo logo-dark">
                <span className="logo-lg">
                  <img src={logoDark} alt="" height="60" />
                </span>
              </Link>
            </div> */}
            <button
              type="button"
              onClick={() => {
                tToggle();
              }}
              className="btn btn-sm px-3 font-size-16 header-item waves-effect"
              id="vertical-menu-btn"
            >
              <i className="fa fa-fw fa-bars" />
            </button>
          </div>

          <div className="d-flex">
            <div className="dropdown d-inline-block d-lg-none ms-2">
              <button
                type="button"
                className="btn header-item noti-icon waves-effect"
                id="page-header-search-dropdown"
                onClick={() => setSearch(!isSearch)}
              >
                <i className="mdi mdi-magnify" />
              </button>
              <div
                className={
                  isSearch
                    ? "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0 show"
                    : "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0"
                }
                aria-labelledby="page-header-search-dropdown"
              ></div>
            </div>

            <LanguageDropdown />

            <div className="dropdown d-none d-lg-inline-block ms-1">
              <button
                type="button"
                className="btn header-item noti-icon waves-effect"
                onClick={() => {
                  toggleFullscreen();
                }}
                data-toggle="fullscreen"
              >
                <i className="bx bx-fullscreen" />
              </button>
            </div>

            <ProfileMenu />
          </div>
        </div>
      </header>
    </React.Fragment>
  );
}

Header.propTypes = {
  leftMenu: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
};

const mapStateToProps = function (state) {
  const { nome, foto } = state.global_user_data.data.foundLogin;
  return { nome, foto, state };
};

const mapDispatchToProps = function (dispatch) {
  return {
    openNavBar: (data) => {
      return dispatch({ type: "OPEN_GLOBAL_LAYOUT_MENU_NAV_WEB", data });
    },
    closeNavBar: (data) => {
      return dispatch({ type: "CLOSE_GLOBAL_LAYOUT_MENU_NAV_WEB", data });
    },
    closeEvent: (data) => {
      return dispatch({ type: "CLOSE_EVENT_MENU", data });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
