import { addDays, format } from "date-fns";
import { excelDateToJSDate } from "../../../utils/helpers/files/exceldatetojs";
import { getEmailsInEvent } from "../../../utils/services/imports";
import {
  checkVehiclesAvailable,
  getAllVehicles,
} from "../../../utils/services/vehicles";

const DEFAULT_BATCH_SIZE = 999999999;

const VEHICLE_ERRORS = {
  NOT_FOUND: "Veículo não encontrado",
  ALREADY_REGISTERED: "Participante já cadastrado no veículo",
  INSUFFICIENT_CAPACITY: "O veículo não possui capacidade suficiente",
};

export const excelColumnsPtBr = {
  Participante: "login",
  Veiculo: "vehicle",
};

const regexEmail =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

function formatDate(date) {
  try {
    return format(addDays(new Date(date), 1), "MM-dd-yyyy");
  } catch (error) {
    return undefined;
  }
}

function formatTime(time) {
  try {
    return format(excelDateToJSDate(time), "HH:mm");
  } catch (error) {
    return undefined;
  }
}

function emailValidate(email) {
  try {
    const emailValid = regexEmail.test(email);
    return emailValid ? email : undefined;
  } catch (error) {
    return undefined;
  }
}

const normalizeEmails = (entries) =>
  entries.map((entry) => ({
    ...entry,
    login: entry.login?.toLowerCase() ?? "",
  }));

const getVehicleStock = (vehicles, vehicleId) => {
  const vehicle = vehicles.find((v) => v.id === vehicleId);
  return vehicle ? Number(vehicle.seatingCapacity - vehicle.amount) : 0;
};

const getVehicleOccurrencesInFile = (file, vehicleId) =>
  file.filter((entry) => entry.vehicle === vehicleId).length;

const calculateTotalBatches = (totalCount, batchSize) =>
  Math.ceil(totalCount / batchSize);

const fetchEventEmails = async (eventId, offset) => {
  try {
    return await getEmailsInEvent({
      eventId,
      offset,
      limit: DEFAULT_BATCH_SIZE,
    });
  } catch (error) {
    console.error("Error fetching event emails:", error);
    return null;
  }
};

const fetchAllEventEmails = async (eventId, totalBatches) => {
  const emailBatchPromises = Array.from({ length: totalBatches }, (_, index) =>
    fetchEventEmails(eventId, index + 1)
  );

  const emailBatches = await Promise.all(emailBatchPromises);
  return emailBatches
    .filter(Boolean)
    .map((batch) => batch.data)
    .flat();
};

const checkVehicleAvailability = async (eventId, email, vehicle, offset) => {
  try {
    return await checkVehiclesAvailable({
      eventId,
      email: window.btoa(email),
      vehicle,
      offset,
      limit: DEFAULT_BATCH_SIZE,
    });
  } catch (error) {
    console.error("Error checking vehicle availability:", error);
    return null;
  }
};

function getVehicleError(entry, vehicleData) {
  const data = vehicleData.find((d) =>
    d.stock.some((s) => s.id === entry.vehicle)
  );

  if (!data || !data.stock.some((s) => s.id === entry.vehicle)) {
    return VEHICLE_ERRORS.NOT_FOUND;
  }

  if (data.existsInVehicle) {
    return VEHICLE_ERRORS.ALREADY_REGISTERED;
  }

  if (
    data.stock.some((s) => s.id === entry.vehicle && s.stockavailable === 0)
  ) {
    return VEHICLE_ERRORS.INSUFFICIENT_CAPACITY;
  }

  return VEHICLE_ERRORS.INSUFFICIENT_CAPACITY;
}

export function allExcelColumns(row, index) {
  return {
    index,
    login: emailValidate(row.login),
    vehicle: row.vehicleId,
  };
}

export function findRowsWithError(validRows) {
  return validRows
    .filter((e) => !e.login || !e.vehicle)
    .map((e) => ({
      ...e,
      error: !e.login ? "Email inválido" : "Veículo inválido",
    }));
}

const mergeItemsByIndex = (items) => {
  const groupedByIndex = items.reduce((acc, item) => {
    if (!acc[item.index]) {
      acc[item.index] = [];
    }
    acc[item.index].push(item);
    return acc;
  }, {});

  return Object.values(groupedByIndex).map((group) => {
    if (group.length === 1) return group[0];

    return group.reduce((merged, current) => {
      const result = { ...merged };

      // Pegar todas as chaves únicas dos objetos
      const allKeys = [
        ...new Set([...Object.keys(merged), ...Object.keys(current)]),
      ];

      allKeys.forEach((key) => {
        if (key === "error") {
          // Concatenar mensagens de erro
          const mergedError = merged.error;
          const currentError = current.error;

          if (mergedError && currentError) {
            result.error = `${mergedError},${currentError}`;
          } else {
            result.error = mergedError || currentError;
          }
        } else if (key === "index") {
          // Manter o index
          result.index = merged.index;
        } else {
          // Para outros campos (login, vehicle), priorizar undefined
          const mergedValue = merged[key];
          const currentValue = current[key];

          if (mergedValue === undefined || currentValue === undefined) {
            result[key] = undefined;
          } else {
            result[key] = mergedValue;
          }
        }
      });

      return result;
    });
  });
};

export async function getUnregisteredEmails(file, eventId) {
  if (!file?.length || !eventId) return [];

  try {
    const normalizedEntries = normalizeEmails(file);
    const firstBatch = await fetchEventEmails(eventId, 1);

    if (!firstBatch) return [];

    const totalBatches = calculateTotalBatches(
      firstBatch.count,
      DEFAULT_BATCH_SIZE
    );
    const registeredEmails = await fetchAllEventEmails(eventId, totalBatches);

    const unregisteredEntries = normalizedEntries
      .filter(({ login }) => !registeredEmails.includes(login))
      .map((entry) => ({
        ...entry,
        login: undefined,
      }));

    const vehicleIssues = await checkVehicles(normalizedEntries, eventId);

    const arrayErros = [...unregisteredEntries, ...vehicleIssues];
    return mergeItemsByIndex(arrayErros);
  } catch (error) {
    console.error("Error processing unregistered emails:", error);
    return [];
  }
}

export async function checkVehicles(file, eventId) {
  if (!file?.length || !eventId) return [];

  const vehicleDataPromises = file.map((entry, index) =>
    checkVehicleAvailability(eventId, entry.login, entry.vehicle, index)
  );

  const vehicleData = await Promise.all(vehicleDataPromises);
  const validVehicleData = vehicleData.filter(Boolean);

  const invalidVehicles = file
    .filter((entry, index) => {
      const data = validVehicleData[index];
      if (!data) return false;

      const vehicleExists = data.stock.some((s) => s.id === entry.vehicle);
      const isAlreadyRegistered = data.existsInVehicle;
      const hasInsufficientStock = data.stock.some(
        (s) => s.id === entry.vehicle && s.stockavailable === 0
      );

      return !vehicleExists || isAlreadyRegistered || hasInsufficientStock;
    })
    .map((entry) => ({
      ...entry,
      error: getVehicleError(entry, validVehicleData),
      vehicle: undefined,
    }));

  if (invalidVehicles.length > 0) return invalidVehicles;

  try {
    const { data: vehiclesResponse } = await getAllVehicles(
      eventId,
      1,
      9999,
      "",
      ""
    );

    if (vehiclesResponse?.message !== "FOUND") return [];

    const vehicles = vehiclesResponse.findAll.data;
    const overCapacityEntries = file.filter((entry) => {
      const occurrencesInFile = getVehicleOccurrencesInFile(
        file,
        entry.vehicle
      );
      const availableStock = getVehicleStock(vehicles, entry.vehicle);
      return occurrencesInFile > availableStock;
    });

    return overCapacityEntries.map((entry) => ({
      ...entry,
      error: VEHICLE_ERRORS.INSUFFICIENT_CAPACITY,
      vehicle: undefined,
    }));
  } catch (error) {
    console.error("Error checking vehicle capacity:", error);
    return [];
  }
}
