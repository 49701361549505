import { Modal, ModalBody, ModalHeader, Row, Col, Button } from "reactstrap";
import ButtonSpinner from "../../../components/Buttons/ButtonSpinner";
import { useEffect, useState } from "react";

import SweetAlert from "react-bootstrap-sweetalert";
import moment from "moment";
// select
import Select from "react-select";
// service
import { getParticipants } from "../../../utils/services/participants";
import { getAllStockActivities } from "../../../utils/services/schedulingAndStock";
import { createScheduling } from "../../../utils/services/scheduling";

export const ModalNewScheduling = (props) => {
  const { isOpen, toggle, idEvent, participants, token, refetch, list } = props;

  const [form, setForm] = useState({
    participant: "",
    eventId: "",
    activityId: "",
    activityName: "",
    sala: "",
    dataHoraInicio: "",
    dataHoraFim: "",
  });

  const [loading, setLoading] = useState(false);
  const [participantActivities, setParticipantActivities] = useState([]);
  // listagem e valores do select
  const [usersList, setUsersList] = useState([]);
  const [activities, setActivities] = useState([]);
  // alertas
  const [successCreateAlert, setSuccessCreateAlert] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const [fullActivityAlert, setFullActivityAlert] = useState(false);
  // erros
  const [formError, setFormError] = useState({});

  function getUsersList(e) {
    getParticipants({
      jwt: token,
      idEvent,
      offset: 1,
      limit: 10,
      search: e ? e : "",
      columnSearch: "login",
      status: "ATIVO",
    }).then((res) => {
      if (res.message === "users") {
        setUsersList(res.data.data);
      } else setUsersList([]);
    });
  }

  const getActivitiesList = async () => {
    let allActivities = [];
    let offset = 1;
    const limit = 100;
    let hasMoreActivities = true;

    try {
      while (hasMoreActivities) {
        const res = await getAllStockActivities({
          eventId: idEvent,
          token: token,
          offset: offset,
          limit: limit,
        });

        const fetchedActivities = res.data;

        allActivities = [...allActivities, ...fetchedActivities];

        if (fetchedActivities.length < limit) {
          hasMoreActivities = false;
        } else {
          offset++;
        }
      }

      setActivities(allActivities);
    } catch (error) {
      // console.log("Erro ao buscar atividades", error);
    }
  };

  const filterActivitiesByParticipant = (selectedParticipantId) => {
    const participantActivities = list
      .filter((item) => item.participantId === selectedParticipantId)
      .map((item) => ({
        activityId: item.activityId,
        startTime: item.dataHoraInicio,
        endTime: item.dataHoraFim,
      }));

    setParticipantActivities(participantActivities);
  };

  const handleActivityChange = (e) => {
    const selectedActivity = activities.find(
      (activity) => activity.id === e.value
    );
    if (selectedActivity) {
      setForm((prevForm) => ({
        ...prevForm,
        eventId: selectedActivity.eventId,
        activityId: selectedActivity.id,
        activityName: selectedActivity.atividade,
        sala: selectedActivity.sala,
        dataHoraInicio: selectedActivity.dataHoraInicio,
        dataHoraFim: selectedActivity.dataHoraFim,
      }));
    } else {
      setForm((prevForm) => ({
        ...prevForm,
        eventId: "",
        activityId: "",
        activityName: "",
        sala: "",
        dataHoraInicio: "",
        dataHoraFim: "",
      }));
    }
  };

  const handleParticipantChange = (e) => {
    const selectedParticipant = participants.find(
      (participant) => participant.id === e.value
    );
    if (selectedParticipant) {
      setForm((prevForm) => ({
        ...prevForm,
        participant: selectedParticipant.id,
      }));
      filterActivitiesByParticipant(selectedParticipant.id);
    } else {
      setForm((prevForm) => ({
        ...prevForm,
        participant: "",
      }));
    }
  };

  const checkForTimeConflicts = (startTime, endTime) => {
    return participantActivities.some((activity) => {
      const activityStart = moment(activity.startTime);
      const activityEnd = moment(activity.endTime);
      const selectedStart = moment(startTime);
      const selectedEnd = moment(endTime);

      return (
        selectedStart.isBetween(activityStart, activityEnd, null, "[)") ||
        selectedEnd.isBetween(activityStart, activityEnd, null, "(]") ||
        activityStart.isBetween(selectedStart, selectedEnd, null, "[)")
      );
    });
  };

  const checkIfActivityIsFull = (activityId) => {
    const activity = activities.find((a) => a.id === activityId);
    if (!activity) return false;
    return activity.quantidadeUtilizada >= activity.quantidadeMax;
  };

  function verifyErrors() {
    var allErrors = {};
    var erro = false;

    if (!form.participant) {
      erro = true;
      allErrors = {
        ...allErrors,
        userErro: "O campo Participante é obrigatório",
      };
    }

    if (!form.activityId) {
      erro = true;
      allErrors = {
        ...allErrors,
        activityErro: "O campo Atividade é obrigatório",
      };
    }

    setFormError(allErrors);

    return erro;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (verifyErrors()) return;

    setLoading(true);

    if (checkForTimeConflicts(form.dataHoraInicio, form.dataHoraFim)) {
      setErrorAlert(true);
      setLoading(false);
      return;
    }

    if (checkIfActivityIsFull(form.activityId)) {
      setFullActivityAlert(true);
      setLoading(false);
      return;
    }

    try {
      const payload = {
        eventId: form.eventId,
        participantId: form.participant,
        atividade: form.activityName,
        sala: form.sala,
        dataHoraInicio: form.dataHoraInicio,
        dataHoraFim: form.dataHoraFim,
      };

      await createScheduling(payload).then((res) => {
        if (res.message === "CREATED") {
          refetch();
          setSuccessCreateAlert(true);
        } else if (res.message === "APPOINTMENT_MADE_IN_OTHER_TIME") {
          setErrorAlert(true);
        } else return;
      });
    } catch (error) {
      // console.log("Erro ao criar agendamento", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getUsersList();
    getActivitiesList();
  }, []);

  return (
    <Modal isOpen={isOpen} centered autoFocus>
      <ModalHeader toggle={toggle}>Novo Agendamento</ModalHeader>
      <ModalBody>
        <form onSubmit={handleSubmit}>
          <Row>
            <Col lg={12} style={{ marginBottom: 10 }}>
              <Select
                placeholder={"Buscar participantes..."}
                onChange={handleParticipantChange}
                onInputChange={(e) => {
                  getUsersList(e);
                }}
                options={[
                  {
                    options: [
                      ...usersList.map((e) => {
                        return { label: e.nome, value: e.id };
                      }),
                    ],
                  },
                ]}
                classNamePrefix="select2-selection"
                menuPortalTarget={document.body}
                styles={{
                  menuPortal: (base) => ({
                    ...base,
                    zIndex: 9999,
                  }),
                  menu: (provided) => ({
                    ...provided,
                    maxHeight: 150,
                  }),
                  menuList: (provided) => ({
                    ...provided,
                    maxHeight: 150,
                    overflowY: "auto",
                  }),
                }}
              />
              {formError.userErro && (
                <a style={{ color: "red" }}>{formError.userErro}</a>
              )}
            </Col>

            <Col lg={12} style={{ marginBottom: 10 }}>
              <Select
                placeholder={"Buscar atividades..."}
                onChange={handleActivityChange}
                options={[
                  {
                    options: [
                      ...activities.map((e) => {
                        return {
                          label: `${e.sala}, ${e.atividade} - ${moment
                            .utc(e.dataHoraInicio)
                            .format("L")} - ${moment
                            .utc(e.dataHoraInicio)
                            .format("LT")}`,
                          value: e.id,
                        };
                      }),
                    ],
                  },
                ]}
                classNamePrefix="select2-selection"
                menuPortalTarget={document.body}
                styles={{
                  menuPortal: (base) => ({
                    ...base,
                    zIndex: 9999,
                  }),
                  menu: (provided) => ({
                    ...provided,
                    maxHeight: 150,
                  }),
                  menuList: (provided) => ({
                    ...provided,
                    maxHeight: 150,
                    overflowY: "auto",
                  }),
                }}
              />
              {formError.activityErro && (
                <a style={{ color: "red" }}>{formError.activityErro}</a>
              )}
            </Col>
          </Row>

          <Row
            style={{
              width: "100%",
              margin: "auto",
              display: "flex",
              justifyContent: "flex-end",
              marginTop: 20,
            }}
          >
            <Button
              color="danger"
              style={{ width: 80, marginRight: 10 }}
              onClick={toggle}
            >
              Fechar
            </Button>
            <ButtonSpinner isLoading={loading} title={"Salvar"} type="submit" />
          </Row>
        </form>

        {successCreateAlert && (
          <SweetAlert
            success
            title="Sucesso!"
            onConfirm={() => {
              setSuccessCreateAlert(false);
              toggle();
            }}
            confirmBtnBsStyle="success"
          >
            Agendamento criado com sucesso!
          </SweetAlert>
        )}

        {errorAlert && (
          <SweetAlert
            danger
            title="Erro!"
            onConfirm={() => setErrorAlert(false)}
          >
            O participante já está em outra atividade neste horário.
          </SweetAlert>
        )}

        {fullActivityAlert && (
          <SweetAlert
            danger
            title="Erro!"
            onConfirm={() => setFullActivityAlert(false)}
          >
            Atividade não possui estoque.
          </SweetAlert>
        )}
      </ModalBody>
    </Modal>
  );
};
