import React, { useRef, useState } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  Button,
  UncontrolledTooltip,
} from "reactstrap";
import TextEditor from "../../../components/TextEditor";
import { TooltipAgendaModal } from "../Schedule/TooltipAgendaModal";

const defaultEmail =
  '<div><p>Olá, <span style="font-weight: bold">${participantName}!</span></p><p>Sua inscrição no <span style="font-weight: bold">${eventName}</span> foi cancelada.</p></div>';

export default function ModalSendEmail(props) {
  const { isOpen, toggle, confirmDelete } = props;
  const refEditor = useRef(null);
  const [subject, setSubject] = useState(
    "Cancelamento da sessão ${eventName}"
  );
  // erros
  const [subjectError, setSubjectError] = useState(false);

  function sendEmail() {

    if (!subject) {
      setSubjectError(true);
      return;
    } else setSubjectError(false);

    const data = {
      subject: subject,
      // template: refEditor.current.startContent,
      template: refEditor,
    };

    confirmDelete(data);
  }

  const [modalTooltip, setModalTooltip] = useState(false);
  const [tooltipType, setTooltipType] = useState("")

  const modalToggle = () => setModalTooltip(!modalTooltip);

  const openModal = (tyoe) => {
    setModalTooltip(true)
    setTooltipType(type)
  }

  return (
    <>
      <Modal isOpen={isOpen} centered autoFocus>
        <ModalHeader toggle={toggle}>Email de Exclusão</ModalHeader>
        <ModalBody>
          <Row style={{ marginBottom: 10 }}>
            <Col md={12}>
              <label className="form-label">Assunto do Email *</label>
              <input
                className="form-control"
                type="text"
                defaultValue={subject}
                onChange={(e) => setSubject(e.target.value)}
              />
              {subjectError && (
                <a style={{ color: "red" }}>Esse campo é obrigatório</a>
              )}
            </Col>
          </Row>

          <label className="form-labe label-tooltip">
            Corpo do Email *
            <div id={`email-solicitation`} style={{ paddingRight: "7px", height: "16px" }}>
              <div onClick={() => openModal("S")} className="show-tips">
                <span>?</span>
              </div>
              <UncontrolledTooltip
                style={{ backgroundColor: "#6f42c1" }}
                placement="right"
                target={`email-solicitation`}
              >
                Email enviado na exclusão do participante. <br />
                (Clique para mais instruções)
              </UncontrolledTooltip>
            </div>
          </label>
          <TextEditor defaultState={defaultEmail} refState={refEditor} />

          <Row
            style={{
              width: "100%",
              margin: "20px 0 0 0",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <>
              <Button
                color="danger"
                style={{ width: 80, marginRight: 10 }}
                onClick={() => toggle()}
              >
                Fechar
              </Button>
              <Button
                color="success"
                className="btn btn-success waves-effect waves-light"
                style={{ width: 80 }}
                onClick={() => sendEmail()}
              >
                Enviar
              </Button>
            </>
          </Row>
        </ModalBody>

        {modalTooltip && <TooltipAgendaModal modal={modalTooltip} toggle={modalToggle} type={tooltipType} />}
      </Modal>
    </>
  );
}
