import React, { useEffect, useState } from 'react';

import { Input, Label, UncontrolledTooltip } from 'reactstrap';

export default function RadioDisplay({ attributes, rowId, colId, setValue }) {
  const [selected, setSelected] = useState('');

  const setLabel = ({ value, id }) => {
    const html = value;
    const div = document.getElementsByClassName(`check-label-${rowId}-${colId}-${id}`);
  
    for (const element of div) {
      element.innerHTML = html;
    }

    const askField = document.getElementsByClassName(`main-question-options-${rowId}-${colId}`);

    for (const element of askField) {
      element.innerHTML = attributes.ask;
    }
  };

  useEffect(() => {
    setValue((oldValue) => {
      const newValue = [...oldValue];
      newValue[rowId].cols[colId].attributes.value = selected;
      if (selected) {
        newValue[rowId].cols[colId].attributes.errorMessage = '';
      } else {
        newValue[rowId].cols[colId].attributes.errorMessage = 'Selecione uma opção';
      }

      return newValue;
    });
  }, [selected, setValue, rowId, colId]);

  return (
    <div id="therm-input-component">
      <div className="custom-input-container">
        <div className="displayed-config-container">
          <div className="displayed-label">
            <span className={`main-question-options-${rowId}-${colId}`} />
          </div>
        </div>
        {attributes.boxes.map((box, index) => (
          <div key={index} style={{ display: 'flex' }}>
            <Input
              className="input-mg-rgt"
              name="displayed-radio"
              type="radio"
              id={`edit-input-radio-show-${index}`}
              onChange={() => setSelected(index + 1)}
            />
            <Label htmlFor={`edit-input-radio-show-${index}`} className={`form-label check-label-${rowId}-${colId}-${index}`} />
            {setLabel({ value: box.label, id: index })}
            {box.hasTip && (
              <div>
                <div className="show-tips" id={`radio-tip-${rowId}-${colId}-${box.id}`}>
                  <span>?</span>
                </div>
                <UncontrolledTooltip style={{ backgroundColor: '#6f42c1' }} placement="right" target={`radio-tip-${rowId}-${colId}-${box.id}`}>
                  {box.tipText}
                </UncontrolledTooltip>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  )
}
