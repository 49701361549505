import React, { useCallback, useEffect, useState } from "react";

import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Collapse,
  Row,
  Table,
} from "reactstrap";

import Pagination from "../../../../../components/Paginationv3";
import SectionComponent from "../../../../Apps/Personalization/SectionComponent";

import { useLocation } from "react-router-dom";
// service
import { getDownloads } from "../../../../../utils/services/reports";
import { createXlsx } from "./createXlsx";
import WaitExport from "../../../../../components/WaitExport";

export default function ReportParticipants(props) {
  const location = useLocation();

  const idEvent = location.state.idEvent;

  const [downloads, setDownloads] = useState([]);
  const [search, setSearch] = useState("");

  //Pagination
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);

  // collapse
  const [isCollapse, setIsCollapse] = useState(false);

  // export
  const [exportInProgress, setExportInProgress] = useState(false);
  const [finishExport, setFinishExport] = useState(false);

  const allParticipants = useCallback(async () => {
    const response = await getDownloads({
      search,
      idEvent,
      offset: currentPage,
      limit: itemsPerPage,
    });
    setDownloads(response.data.files);
    setTotalItems(response.data.total);
  }, [search, currentPage, itemsPerPage, idEvent]);

  useEffect(() => {
    allParticipants();
  }, [allParticipants]);

  return (
    <div>
      <WaitExport
        isOpen={exportInProgress}
        preposicao="do"
        name="Relatório de Downloads"
        finishExport={finishExport}
        setFinishExport={setFinishExport}
        setExportInProgress={setExportInProgress}
      />
      <Card>
        <CardBody>
          <CardTitle>Relatório de Downloads</CardTitle>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <form
              className="app-search d-none d-lg-block events-input-search"
              onSubmit={(e) => e.preventDefault()}
            >
              <div className="position-relative">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Pesquisar..."
                  onChange={(e) => setSearch(e.target.value)}
                />
                <span className="bx bx-search-alt" />
              </div>
            </form>
            {downloads.length > 0 && (
              <div className="mobile-btn-section" style={{ width: "100%" }}>
                <Row className="mobile-btns-top">
                  <form
                    className="app-search"
                    style={{ padding: "0px" }}
                    onSubmit={(e) => e.preventDefault()}
                  >
                    <div className="position-relative">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Pesquisar..."
                        onChange={(e) => setSearch(e.target.value)}
                      />
                      <span className="bx bx-search-alt" />
                    </div>
                  </form>
                </Row>

                <SectionComponent
                  sectionName="Opções"
                  setIsOpen={setIsCollapse}
                  isOpen={isCollapse}
                />
                <Collapse isOpen={isCollapse}>
                  <Row className="mobile-btns-top">
                    <div
                      onClick={() => {
                        createXlsx({
                          idEvent,
                          countData: totalItems,
                          setFinishExport,
                        });
                        setExportInProgress(true);
                      }}
                    >
                      Exportar Relatório
                    </div>
                  </Row>
                </Collapse>
              </div>
            )}
            <div className="header-btns-container">
              {downloads.length > 0 && (
                <Button
                  color="success"
                  className="btn-rounded waves-effect waves-light events-button-add"
                  onClick={() => {
                    createXlsx({
                      idEvent,
                      countData: totalItems,
                      setFinishExport,
                    });
                    setExportInProgress(true);
                  }}
                >
                  Exportar Relatório
                </Button>
              )}
            </div>
          </div>
          <div className="table-responsive">
            <Table className="table mb-0">
              <thead className="table-light">
                <tr>
                  <th>Nome do Arquivo</th>
                  <th>Quantidade de Downloads</th>
                </tr>
              </thead>
              <tbody>
                {downloads.length > 0 &&
                  downloads.map((download, index) => (
                    <tr key={index}>
                      <td>{download.filename}</td>
                      <td>{download.total}</td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
        </CardBody>
      </Card>
      <div className="bottom-navigation" style={{ justifyContent: "end" }}>
        {/* 
        Fornecer os 4 parametros obrigatórios;
        ** data - a array contendo as informações da tabela
        ** search - o state do termo de pesquisa
        ** currentIndex - posição atual do index
        ** setCurrentIndex - função para atualizar a posição atual do index
        */}
        <Pagination
          data={downloads}
          // search={search}
          currentIndex={currentPage}
          setCurrentIndex={setCurrentPage}
          countData={totalItems}
          maxItemPerPage={itemsPerPage}
          setMaxItemPerPage={setItemsPerPage}
          maxPageButtonsToShow={3}
        />
      </div>
    </div>
  );
}
