import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
  Collapse,
} from "reactstrap";

import SectionComponent from "../../Apps/Personalization/SectionComponent";
import { getLoginBySession } from "../../../utils/services/quotas";
import Pagination from "../../../components/Paginationv3";
import WaitExport from "../../../components/WaitExport";
import useDebounce from "../../../utils/hooks/useDebounce";
import { createXlsxParticipantSessions } from "./createXlsxParticipantSessions";
import ModalSendEmailSession from "./ModalSendEmailSession";

export default function ModalParticipantsSession(props) {
  const { session, isOpen, toggle } = props;
  const { token } = useSelector((state) => state.global_user_data.data);
  const [participants, setParticipants] = useState([]);
  const [countData, setCountData] = useState(1);
  const [currentIndex, setCurrentIndex] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const [isOpenSection, setIsOpenSection] = useState(false);
  const [exportInProgress, setExportInProgress] = useState(false);
  const [finishExport, setFinishExport] = useState(false);
  // modal
  const [modalSendEmailSession, setModalSendEmailSession] = useState(false);
  const [participantData, setParticipantData] = useState(null);

  const debouncedSearch = useDebounce(search, 300);

  function exportReport() {
    createXlsxParticipantSessions({
      eventId: session?.eventId,
      sessionId: session?.agendaId,
      limit: 100,
      countData,
      setFinishExport,
      jwt: token,
    });
    setExportInProgress(true);
  }
  useEffect(() => {
    if (session && isOpen) {
      getLoginBySession({
        eventId: session?.eventId,
        sessionId: session?.agendaId,
        jwt: token,
        offset: currentIndex > 0 ? currentIndex : 1,
        limit: itemsPerPage,
        columnSearch: "nome",
        search: debouncedSearch,
      }).then((data) => {
        setParticipants(data.loginEventosCotas.data);
        setCountData(data.loginEventosCotas.count);
      });
    }
  }, [session, isOpen, debouncedSearch, currentIndex, itemsPerPage]);

  useEffect(() => {
    setCurrentIndex(1);
  }, [isOpen]);

  return (
    <>
      {modalSendEmailSession && (
        <ModalSendEmailSession
          isOpen={modalSendEmailSession}
          toggle={() => setModalSendEmailSession(false)}
          eventId={session.eventId}
          jwt={token}
          participantData={participantData}
        />
      )}
      <Modal size="xl" isOpen={isOpen} autoFocus={true} centered={true}>
        <WaitExport
          isOpen={exportInProgress}
          name="Participantes da sessão"
          preposicao="dos"
          finishExport={finishExport}
          setFinishExport={setFinishExport}
          setExportInProgress={setExportInProgress}
        />
        <ModalHeader toggle={toggle}>Participantes da sessão</ModalHeader>
        <ModalBody>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "flex-end",
              marginBottom: 10,
              alignItems: "center",
            }}
          >
            <div className="mobile-btn-section" style={{ width: "100%" }}>
              {participants.length > 0 && (
                <>
                  <SectionComponent
                    sectionName="Opções"
                    setIsOpen={setIsOpenSection}
                    isOpen={isOpenSection}
                  />
                  <Collapse isOpen={isOpenSection}>
                    <Row className="mobile-btns-top">
                      <div onClick={() => exportReport()}>
                        Exportar Participantes
                      </div>
                    </Row>
                  </Collapse>
                </>
              )}
            </div>

            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                marginBottom: 10,
              }}
            >
              <form
                className="app-search d-none d-lg-block events-input-search"
                onSubmit={(e) => e.preventDefault()}
              >
                <div className="position-relative">
                  <input
                    type="text"
                    value={search}
                    className="form-control"
                    placeholder="Pesquisar..."
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <span className="bx bx-search-alt" />
                </div>
              </form>
              {participants.length > 0 && (
                <div className="header-btns-container">
                  <Button
                    color="success"
                    className="btn-rounded waves-effect waves-light events-button-add"
                    onClick={() => exportReport()}
                  >
                    Exportar Participantes
                  </Button>
                </div>
              )}
            </div>
          </div>
          <Table>
            <thead className="table-light">
              <tr>
                <th>ID Participante</th>
                <th>Nome</th>
                <th>Email</th>
                <th>Sessão</th>
                <th>Quantidade Total de Ingressos</th>
                <th>Você ou seu(s) acompanhante(s) são PCD?</th>
                <th>Quais cuidados: </th>
                <th>Observações</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {participants &&
                participants.map((e, i) => (
                  <tr key={i}>
                    <td>{e.login}</td>
                    <td>{e.name}</td>
                    <td>{e.email}</td>
                    <td>{e.title}</td>
                    <td>{e.companionsnumber}</td>
                    <td>{e.accessibilityrestriction ? "Sim" : "Não"}</td>
                    <td>{e.typerestriction}</td>
                    <td>{e.notes}</td>
                    <td>
                      <i
                        className="mdi mdi-email-send-outline"
                        style={{
                          color: "blue",
                          fontSize: 17,
                          cursor: "pointer",
                          minHeight: "100px",
                        }}
                        onClick={() =>
                          setParticipantData(e) > setModalSendEmailSession(true)
                        }
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
          <Row>
            <div
              className="bottom-navigation"
              style={{ justifyContent: "end" }}
            >
              <Pagination
                data={participants}
                search={search}
                currentIndex={currentIndex}
                setCurrentIndex={setCurrentIndex}
                countData={countData}
                maxItemPerPage={itemsPerPage}
                setMaxItemPerPage={setItemsPerPage}
                maxPageButtonsToShow={3}
              />
            </div>
          </Row>
          <Row
            style={{
              width: "100%",
              marginTop: 15,
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              color="danger"
              style={{ width: 80, marginRight: 10 }}
              onClick={() => toggle()}
            >
              Fechar
            </Button>
          </Row>
        </ModalBody>
      </Modal>
    </>
  );
}
