import React, { useState } from 'react';

import { Button, Collapse, Modal, ModalBody, ModalHeader } from 'reactstrap';
import SectionComponent from '../../../pages/Apps/Personalization/SectionComponent';

export default function EntriesOptions({ toggle, isOpen, addInput, data }) {
  const [defaultEntriesModal, setDefaultEntriesModal] = useState(true);
  const [customEntriesModal, setCustomEntriesModal] = useState(true);
  const [fieldName, setFieldName] = useState('')
  const [error, setError] = useState(false);

  const handleClick = (id, field) => {
    if (data.find((row) => row.cols.map((col) => col.inputId).includes(id))) {
      setFieldName(field);
      setError(true);
      setTimeout(() => {
        setError(false);
        setFieldName('');
      }, 5000);
      return;
    }
    addInput(id);
    toggle(false);
  };


  return (
    <Modal
      isOpen={isOpen}
    >
      <ModalHeader toggle={() => toggle(false)}>Opções de entrada</ModalHeader>
      <ModalBody>
        <SectionComponent
          sectionName="Campos Pré-definidos"
          isOpen={defaultEntriesModal}
          setIsOpen={setDefaultEntriesModal}
        />
        <Collapse isOpen={defaultEntriesModal}>
          <div className="entries-options" onClick={() => handleClick(7, 'Email')}>Email</div>
          <div className="entries-options" onClick={() => handleClick(8, 'Celular')}>Celular</div>
          <div className="entries-options" onClick={() => handleClick(9, 'CPF')}>CPF</div>
          <div className="entries-options" onClick={() => handleClick(11, 'Captcha')}>Captcha</div>
          <div className="entries-options" onClick={() => handleClick(6, 'Botão')}>Botão</div>
          {error && <p style={{color: 'red', marginTop: '10px'}}>O campo {fieldName} já foi adicionado!</p>}
        </Collapse>
        <SectionComponent
          sectionName="Campos Dinâmicos"
          isOpen={customEntriesModal}
          setIsOpen={setCustomEntriesModal}
        />
        <Collapse isOpen={customEntriesModal}>
          <div className="entries-options" onClick={() => addInput(0) > toggle(false)}>Campo de Texto</div>
          <div className="entries-options" onClick={() => addInput(1) > toggle(false)}>Upload de Arquivos</div>
          <div className="entries-options" onClick={() => addInput(2) > toggle(false)}>Termos</div>
          <div className="entries-options" onClick={() => addInput(3) > toggle(false)}>Selecionar Opções</div>
          <div className="entries-options" onClick={() => addInput(4) > toggle(false)}>Múltiplas Opções</div>
          <div className="entries-options" onClick={() => addInput(5) > toggle(false)}>Selecionar uma Opção</div>
          <div className="entries-options" onClick={() => addInput(10) > toggle(false)}>Label</div>
          <div className="entries-options" onClick={() => addInput(12) > toggle(false)}>Senha</div>
        </Collapse>

        <div className="save-btn-form-container">
          <Button
            color="success"
            className="btn btn-success waves-effect waves-light save-btn-form"
            onClick={() => toggle(false)}
          >
            Sair
          </Button>
        </div>
      </ModalBody>
    </Modal>
  )
}
