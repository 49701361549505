import writeXlsxFile from "write-excel-file";
// moment
import moment from "moment";
import { getOnline } from "../../../../../utils/services/dashboardSuperScreen";

function formatDate(date) {
  const dateFormat = date.split("T")[0].split("-").reverse().join("/");
  const time = date.split("T")[1].slice(0, 5);
  return `${dateFormat} - ${time}`;
}

export async function createXlsx({
  eventId,
  transmissionID,
  limit,
  offset,
  search,
  setFinishExport,
  countData,
  mobileapps,
}) {
  const schema = [
    {
      column: "ID do Participante",
      type: String,
      value: (online) => online.id,
    },
    {
      column: "Nome do Participante",
      type: String,
      value: (online) => online.name,
    },
    {
      column: "Data e Hora",
      type: String,
      value: (online) =>
        online.date ? formatDate(moment(online.date).format()) : "",
    },
  ];

  const allRequestsPromises = [];
  const numberOfRequisitions = Math.ceil(countData / limit);

  console.log(countData, limit);
  for (let i = 1; i <= numberOfRequisitions; i += 1) {
    const request = await getOnline({
      eventId,
      transmissionID,
      pageSize: limit,
      offset: i - 1,
      search,
      mobileapps,
    });

    allRequestsPromises.push(request);
    setTimeout(() => {}, 500);

    const progressBar = document.querySelector(".export-progress");
    const percentageBar = document.querySelector(".export-percentage");

    progressBar.style.width = `${(i / numberOfRequisitions) * 100}%`;
    percentageBar.innerHTML = `${((i / numberOfRequisitions) * 100).toFixed(
      0
    )}%`;

    if (i === numberOfRequisitions) {
      setTimeout(() => {
        setFinishExport(true);
      }, 1000);
    }
  }

  const allDataInSingleArray = allRequestsPromises.map((e) => e.data).flat();
  if (allDataInSingleArray) {
    await writeXlsxFile(allDataInSingleArray, {
      schema,
      fileName: "ParticipantesOnline.xlsx",
    });
  }
}
