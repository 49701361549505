import React from 'react';
import { Col, Label, Row } from 'reactstrap';
import SelectFilter from './SelectFilter';

export default function Filters(props) {
  return (
    <Row>
      {props.filters.length && props.filters.map((filter) => (
        <Col md={4} key={filter.name}>
          <Label
            className="form-control-label"
            htmlFor={filter.name}
            style={{
              fontWeight: 'bold',
              backgroundColor: '#EFF2F7',
              width: '100%',
              margin: '20px 0px 5px 0px',
              border: '1px solid #E3E6F0',
              fontSize: '14px',
            }}
          >
            {filter.placeholder}
          </Label>
          <SelectFilter
            placeholder={'Todos'}
            isMulti={filter.isMulti}
            value={props.value[filter.name]}
            onChange={(e) => props.setFilteredSales({ [filter.name]: e?.value || '' })}
            options={props.options[filter.name]}
            id={filter.name}
          />
        </Col>
      ))}
    </Row>
  )
}
