import { urlBase, urlBaseTemplates } from "./config";
import axiosInstance from "../services/config/axiosinstance";

async function getAllForms({ jwt, offset, limit, search, eventId }) {
  try {
    const config = {
      method: "get",
      url: `${urlBase}/events/${eventId}/forms?offset=${offset}&limit=${limit}${
        search ? `&search=${search}` : ""
      }`,
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await axiosInstance(config);
    return response;
  } catch (error) {
    return error.response;
  }
}

async function createForm({ jwt, data }) {
  try {
    const config = {
      method: "post",
      url: `${urlBase}/forms`,
      headers: {
        "Content-Type": "application/json",
      },
      data,
    };

    const response = await axiosInstance(config);
    return response;
  } catch (error) {
    return error.response;
  }
}

async function updateForm({ jwt, data }) {
  try {
    const config = {
      method: "put",
      url: `${urlBase}/forms`,
      headers: {
        "Content-Type": "application/json",
      },
      data,
    };

    const response = await axiosInstance(config);
    return response;
  } catch (error) {
    return error.response;
  }
}

async function deleteForm({ jwt, id }) {
  try {
    const config = {
      method: "delete",
      url: `${urlBase}/forms/${id}`,
      headers: {
        "Content-Type": "application/json",
      },
    };

    const response = await axiosInstance(config);
    return response;
  } catch (error) {
    return error.response;
  }
}

//TEMPLATE SYSTEM
//https://gateway-admin-plus.inteegrav2.com.br/api-admin-template/templates

async function createTemplate({ jwt, payload }) {
  try {
    const config = {
      method: "post",
      url: `${urlBaseTemplates}/templates`,
      headers: {
        "Content-Type": "application/json",
      },
      data: payload,
    };
    const response = await axiosInstance(config);
    return response;
  } catch (error) {
    return error;
  }
}

async function updateTemplate({ jwt, payload }) {
  try {
    const config = {
      method: "put",
      url: `${urlBaseTemplates}/templates`,
      headers: {
        "Content-Type": "application/json",
      },
      data: payload,
    };
    const response = await axiosInstance(config);
    return response;
  } catch (error) {
    return error;
  }
}

async function deleteTemplate({ jwt, payload }) {
  try {
    const config = {
      method: "DELETE",
      url: `${urlBaseTemplates}/templates/${payload.id}`,
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await axiosInstance(config);
    return response;
  } catch (error) {
    return error;
  }
}

async function listTemplates({ id, jwt, offset, limit, type, name }) {
  try {
    let url = id
      ? `${urlBaseTemplates}/templates?eventId=${id}&offset=${offset}&limit=${
          limit || 10
        }&type=${type}&name=${name}`
      : `${urlBaseTemplates}/templates/defaults?offset=${offset}&limit=${
          limit || 10
        }&type=${type}&name=${name}`;
    const config = {
      method: "get",
      url: url,
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await axiosInstance(config);
    return response;
  } catch (error) {
    return error;
  }
}

export {
  getAllForms,
  createForm,
  updateForm,
  deleteForm,
  createTemplate,
  updateTemplate,
  listTemplates,
  deleteTemplate,
};
