import React, { useState, useRef } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Col,
  Row,
  Card,
  CardText,
  Form,
  CardBody,
} from "reactstrap";
import Dropzone from "react-dropzone";
import { formatBytes } from "../../../utils/helpers/files/formatBytes";
// documento
import ModeloUpload from "../../../assets/statics/modelo_importação_pontos.xlsx";
import readXlsxFile from "read-excel-file";
//redux
import { connect } from "react-redux";
// validação
import {
  allExcelColumns,
  findRowsWithError,
  excelColumnsPtBr,
  getUnregisteredUser,
} from "./importValidation";
// alertas
import SweetAlert from "react-bootstrap-sweetalert";
import ModalExcelRows from "./ModalExcelRows";
// service
import { createEarnedPoints } from "../../../utils/services/ranking";

function ModalImportPoints(props) {
  const { isOpen, toggle, idEvent, getAllRankingFunc } = props;
  // dados da requisição
  const token = props.state.global_user_data.data.token;
  // documento
  const [selectedFiles, setselectedFiles] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [totalSended, setTotalSended] = useState(0);
  // botões
  const [btnSend, setBtnSend] = useState(false);
  const [btnCancel, setBtnCancel] = useState(false);
  // modal de erros no arquivo xlsx
  const [modalErrors, setModalErrors] = useState(false);
  const [errors, setErrors] = useState([]);
  // alerta
  const [success_dlg, setsuccess_dlg] = useState(false);

  const importActivated = useRef(false);

  function handleAcceptedFiles(files) {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    setselectedFiles(files);
    setTotalRows(0);
    setTotalSended(0);
    setBtnSend(true);
  }

  function cancelImport() {
    importActivated.current = false;
    setselectedFiles([]);
    setTotalRows(0);
    setTotalSended(0);
    setBtnSend(false);
    setBtnCancel(false);
  }

  async function uploadService() {
    try {
      importActivated.current = true;
      setBtnSend(false);
      setBtnCancel(true);

      const schema = {
        CodigoRanking: { prop: "code", type: String },
        IdParticipante: { prop: "idUser", type: String },
      };

      let count = 0;

      const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

      const readXlsx = async () => {
        try {
          if (!selectedFiles[0]) {
            throw new Error("Nenhum arquivo selecionado");
          }

          const { rows } = await readXlsxFile(selectedFiles[0], { schema });

          if (!rows || !Array.isArray(rows)) {
            throw new Error(
              "Erro ao ler as linhas do arquivo Excel ou arquivo vazio"
            );
          }

          const validateRows = rows.map((row, i) => allExcelColumns(row, i));
          const rowsWithError = findRowsWithError(validateRows);

          setTotalRows(validateRows.length);

          if (rowsWithError.length > 0 || validateRows.length === 0) {
            setErrors(rowsWithError);
            setModalErrors(true);
            return;
          }

          var unregisteredUser = await getUnregisteredUser(
            token,
            idEvent,
            validateRows
          );

          if (unregisteredUser.length > 0) {
            setErrors(unregisteredUser);
            setModalErrors(true);
            return;
          }

          for (var i = 0; i < validateRows.length; i++) {
            await sleep(100);
            await submitPoint(validateRows[i], i, validateRows.length);
          }
        } catch (error) {
          // console.log("Erro ao ler o arquivo Excel:", error);
          setErrors([
            "Erro ao ler o arquivo Excel. Por favor, tente novamente.",
          ]);
          setModalErrors(true);
        }
      };

      readXlsx();

      async function submitPoint(row, index, totalRows) {
        const dataRanking = {
          code: row.code,
          positive: true,
          log: "add points plus",
        };

        await createEarnedPoints(dataRanking, idEvent, row.idUser).then(
          (res) => {
            if (res.message === "ADDED_TO_QUEUE") {
              if (totalRows === index + 1) {
                // confirmar atrasando a chamada da listagem para ter tempo de cair na fila
                setTimeout(() => {
                  getAllRankingFunc();
                }, 300);
                setsuccess_dlg(true);
              }
            } else {
              // console.log("erro ao enviar");
            }
          }
        );
        // .catch((error) => console.log("erro catch", error));

        setTotalSended(count++);
      }
    } catch (error) {
      // console.error("Erro no processo de upload:", error);
      setErrors(["Erro no processo de upload. Por favor, tente novamente."]);
      setModalErrors(true);
    }
  }

  return (
    <>
      {modalErrors && (
        <ModalExcelRows
          toggle={() => setModalErrors(false)}
          rows={errors}
          columnNames={excelColumnsPtBr}
        />
      )}

      {success_dlg ? (
        <SweetAlert
          success
          title={"Upload"}
          onConfirm={() => {
            toggle();
            setsuccess_dlg(false);
          }}
        >
          {"O arquivo foi enviado com sucesso!"}
        </SweetAlert>
      ) : null}

      <Modal isOpen={isOpen} centered>
        <ModalHeader toggle={toggle}>Importar Pontuação</ModalHeader>
        <ModalBody>
          <Card className="p-1">
            <CardBody className="m-1 p-2">
              <CardText className="mb-2 p-10">
                O arquivo a ser importado deve seguir este{" "}
                <a href={ModeloUpload} download="modelo_importação_pontos.xlsx">
                  Modelo
                </a>
                , não modifique seu cabeçalho.
              </CardText>
            </CardBody>
          </Card>
          {selectedFiles.length === 0 && (
            <Dropzone
              onDrop={(acceptedFiles) => {
                handleAcceptedFiles(acceptedFiles);
              }}
            >
              {({ getRootProps, getInputProps }) => (
                <div className="dropzone">
                  <div
                    className="dz-message needsclick mt-1"
                    {...getRootProps()}
                  >
                    <input {...getInputProps()} />
                    <div className="mb-4">
                      <i className="display-4 text-muted bx bxs-cloud-upload" />
                      <h4>Arraste o arquivo ou clique aqui e selecione.</h4>
                    </div>
                  </div>
                </div>
              )}
            </Dropzone>
          )}

          <div className="dropzone-previews mt-0" id="file-previews">
            {selectedFiles.map((f, i) => {
              return (
                <Card
                  className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                  key={i + "-file"}
                >
                  <div className="p-1">
                    <Row className="align-items-center">
                      <Col xs="11">
                        <strong>{`Nome: ${f.name} - Tamanho: ${f.formattedSize}`}</strong>
                      </Col>
                      <Col xs="1">
                        <span
                          style={{
                            fontSize: "16px",
                            color: "red",
                            fontWeight: "700",
                          }}
                          className="mdi mdi-close"
                          onClick={() => setselectedFiles([])}
                        />
                      </Col>
                    </Row>
                    {importActivated.current && (
                      <Row className="align-items-center">
                        <Col>
                          <strong>{`Total enviado: ${totalSended}/${totalRows}`}</strong>
                        </Col>
                      </Row>
                    )}
                  </div>
                </Card>
              );
            })}
          </div>

          <div className="text-center mt-3">
            <button
              type="button"
              disabled={!btnSend}
              className="btn btn-primary waves-effect waves-light"
              onClick={() => uploadService()}
            >
              Enviar Pontos
            </button>
            <button
              type="button"
              className="btn btn-danger mx-1"
              disabled={!btnCancel}
              onClick={() => cancelImport()}
            >
              Cancelar
            </button>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => {
  return { state };
};

export default connect(mapStateToProps)(ModalImportPoints);
