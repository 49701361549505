import { addDays, format } from "date-fns";
import { excelDateToJSDate } from "../../../utils/helpers/files/exceldatetojs";

export const excelColumnsPtBr = {
  'Titulo': 'title',
  'Descricao': 'description',
  'DataDaProgramacao': 'startDate',
  'HoraInicio': 'startHour',
  'HoraFim': 'endHour',
  'Foto': 'picture',
  'TipoOuLocalDaProgramacao': 'typeOrLocation',
  'CorDaProgramacao': 'color',
  'Grupos': 'groups',
};

function formatDate(date) {
  try {
    return format(addDays(new Date(date), 1), 'MM-dd-yyyy');
  } catch (error) {
    return undefined;
  }
}

function formatTime(time) {
  try {
    return format(excelDateToJSDate(time, ), 'HH:mm');
  } catch (error) {
    return undefined;
  }
}

export function allExcelColumns(row, index) {
    const formatedRow = {
      index,
      startDate: formatDate(row.startDate),
      startHour: row.startHour ? formatTime(row.startHour) : '',
      endHour: row.endHour ? formatTime(row.endHour) : '',
      title: row.title ? row.title : undefined,
      description: row.description ? row.description : '',
      typeOrLocation: row.typeOrLocation ? row.typeOrLocation : '',
      color: row.color ? row.color : '',
      picture: row.picture ? row.picture : '',
      expireDate: !row.picture ? null : '2035-12-31T23:59:32.112Z',
      groups: row.groups ? row.groups.split(', ') : '',
    }

    return formatedRow;
}