function isTokenExpired(token) {
  if (!token) return true;

  const decodedToken = parseJwt(token);
  const currentTime = Date.now() / 1000;
  return decodedToken.exp < currentTime;
}

function parseJwt(token) {
  try {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    return JSON.parse(atob(base64));
  } catch (e) {
    return null;
  }
}

export { isTokenExpired };
