import React, { useEffect, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { useSelector } from "react-redux";

import {
  Button,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
  UncontrolledTooltip,
} from "reactstrap";
import { updateDict } from "../../utils/services/dictionary";
import InputNewRef from "./inputNewRef";
import ButtonSpinner from "../../components/Buttons/ButtonSpinner";

export default function NewDict(props) {
  const { isOpen, toggle, refetch, selectedDict, descriptions } = props;
  const { token: jwt } = useSelector((state) => state.global_user_data.data);
  const [isLoading, setIsloading] = useState(false);

  const [modalBackdrop, setModalBackdrop] = useState(false);

  //campos fomulário
  const [selectedLanguage, setSelectedLanguage] = useState("");

  //validações campos formulários
  const [selectedLanguageError, setSelectedLanguageError] = useState(false);
  const [fieldsAreEmpty, setFieldsAreEmpty] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);

  //editado com sucesso
  const [success, setSuccess] = useState(false);

  //tabela
  const [modalDeleteRow, setModalDeleteRow] = useState(false);
  const [rowToDelete, setRowToDelete] = useState("");
  const [tableRows, setTableRows] = useState([]);

  const updateRowIndex = (newState) => {
    const rows = newState.map((row, index) => {
      row.id = index;
      return row;
    });

    return rows;
  };

  const addRow = () => {
    const newRow = {
      id: tableRows.length,
      refText: "",
      translatedText: "",
    };
    setTableRows([newRow, ...tableRows]);
  };

  const removeRow = (id) => {
    const newRows = tableRows.filter((row) => row.id !== id);
    setTableRows(updateRowIndex(newRows));
  };

  const updateRow = (id, key, value) => {
    setTableRows(
      tableRows.map((row) => {
        if (row.id === id) {
          return {
            ...row,
            [key]: value,
          };
        }
        return row;
      })
    );
  };

  const isValidFields = () => {
    return tableRows.every(
      (row) => row.refText !== "" && row.translatedText !== ""
    );
  };

  function toggleBackdrop() {
    setModalBackdrop(!modalBackdrop);
  }

  function resetFields() {
    setSelectedLanguageError(false);
    setDescriptionError(false);
    setFieldsAreEmpty(false);
    setSuccess(false);
  }

  async function updateDictionary(json) {
    const data = {
      id: selectedDict.id,
      description: selectedLanguage,
      json,
    };
    setIsloading(true);
    await updateDict({ jwt, data });
    setIsloading(false);
  }

  async function submitForm() {
    if (selectedLanguage === "") {
      return setSelectedLanguageError(true);
    } else setSelectedLanguageError(false);

    if (
      selectedDict.description !== selectedLanguage &&
      descriptions.some((desc) => desc === selectedLanguage)
    ) {
      return setDescriptionError(true);
    } else setDescriptionError(false);

    if (!isValidFields()) {
      return setFieldsAreEmpty(true);
    } else setFieldsAreEmpty(false);

    const rowsWithoutId = {};

    tableRows.forEach((row) => {
      rowsWithoutId[row.refText] = row.translatedText;
    });

    const json = JSON.stringify(rowsWithoutId);

    await updateDictionary(json);

    resetFields();

    refetch();

    setSuccess(true);
  }

  useEffect(() => {
    if (selectedDict.json) {
      try {
        const json = JSON.parse(selectedDict.json);
        setTableRows(
          Object.entries(json).map(([key, value], i) => ({
            refText: key,
            translatedText: value,
            id: +i,
          }))
        );

        setSelectedLanguage(selectedDict.description);
      } catch (error) {
        // console.log(error);
      }
    }
  }, [selectedDict]);

  return (
    <Modal
      size="lg"
      isOpen={isOpen}
      toggle={() => {
        toggleBackdrop();
      }}
      onClosed={() => {
        resetFields();
      }}
      autoFocus={true}
      centered={true}
    >
      {success && (
        <SweetAlert
          success
          title="Editado"
          onConfirm={() => {
            setSuccess(false);
            toggle();
          }}
        >
          Dicionário editado com sucesso!
        </SweetAlert>
      )}
      {modalDeleteRow && (
        <SweetAlert
          warning
          showCancel
          confirmBtnText="Sim, deletar!"
          confirmBtnBsStyle="danger"
          title="Deseja deletar essa linha?"
          onConfirm={() => {
            setModalDeleteRow(false);
            removeRow(rowToDelete);
          }}
          onCancel={() => setModalDeleteRow(false)}
          focusCancelBtn
        />
      )}
      <ModalHeader toggle={toggle}>Editar Dicionário</ModalHeader>
      <ModalBody>
        <form>
          <div>
            <div style={{ display: "flex" }}>
              <Label htmlFor="formText" className="form-label">
                Idioma
              </Label>
              <div
                id="identify-app-tip"
                style={{ height: "16px", paddingRight: "7px" }}
              >
                <div className="show-tips">
                  <span>?</span>
                </div>
                <UncontrolledTooltip
                  style={{ backgroundColor: "#6f42c1" }}
                  placement="right"
                  target="identify-app-tip"
                >
                  O valor inserido deve ser o identificador do idioma no APP
                </UncontrolledTooltip>
              </div>
            </div>
            <Input
              type="text"
              id="formText"
              className="form-control"
              placeholder="Digite o idioma"
              value={selectedLanguage}
              onChange={(e) => setSelectedLanguage(e.target.value)}
              disabled
            />
            {selectedLanguageError && (
              <p style={{ color: "red" }}>O campo Idioma é obrigatório</p>
            )}
            {descriptionError && (
              <p style={{ color: "red" }}>
                O Idioma informado já está cadastrado
              </p>
            )}
            <div
              style={{
                marginTop: 12,
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button color="primary" onClick={addRow}>
                Adicionar Linha
              </Button>
            </div>
            <Table>
              <thead>
                <tr>
                  <th>Texto Referência</th>
                  <th>Texto Traduzido</th>
                </tr>
              </thead>
              <tbody>
                {tableRows.map((table, index) => (
                  <tr key={index}>
                    <td>
                      <InputNewRef
                        updateRow={updateRow}
                        table={table}
                        tableRows={tableRows}
                      />
                    </td>
                    <td>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Input
                          type="text"
                          value={table.translatedText}
                          onChange={(e) =>
                            updateRow(
                              table.id,
                              "translatedText",
                              e.target.value
                            )
                          }
                        />
                        <span
                          style={{ cursor: "pointer", marginLeft: "10px" }}
                          className="mdi mdi-close close-icon"
                          title="Remover Linha"
                          onClick={() =>
                            setRowToDelete(table.id) > setModalDeleteRow(true)
                          }
                        />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            {fieldsAreEmpty && (
              <p style={{ color: "red" }}>
                Todos os campos devem ser preenchidos
              </p>
            )}
          </div>
          <Row
            style={{
              width: "100%",
              marginTop: 15,
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              color="secondary"
              className="btn waves-effect waves-light"
              style={{ width: 80, marginRight: 10 }}
              onClick={toggle}
            >
              Fechar
            </Button>

            <ButtonSpinner
              isLoading={isLoading}
              title={"Salvar"}
              onClick={submitForm}
            />
          </Row>
        </form>
      </ModalBody>
    </Modal>
  );
}
