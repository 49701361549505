import React, { useCallback, useEffect, useState } from "react";

import { Row, Col, Card, CardBody, CardTitle, Button } from "reactstrap";

import Ranking from "../ranking";
import Participants from "../Participant/Participants";

import DonutChart from "./DonutChart";
import FeedChart from "./FeedChart";
import PieChart from "./PieChart";
import RankChart from "./RankChart";
import Feed from "../Feed/Feed";
import ModalConfigApp from "./ModalConfigApp";
import {
  getClickButtonMenu,
  getDashboardAccesses,
  getDashboardFeed,
  getDashboardRanking,
} from "../../../utils/services/dashboard";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const donut = [
  { x: "Agenda", y: 70, label: parseInt((70 / 195) * 100) },
  { x: "Feed", y: 40, label: parseInt((40 / 195) * 100) },
  { x: "Localização", y: 30, label: parseInt((30 / 195) * 100) },
  { x: "Logística", y: 20, label: parseInt((20 / 195) * 100) },
  { x: "Ranking", y: 35, label: parseInt((35 / 195) * 100) },
];

export default function Dashboard() {
  const { token: jwt } = useSelector((state) => state.global_user_data.data);
  const {
    state: { idEvent: eventId },
  } = useLocation();

  // Dashboard data
  const [accesses, setAccesses] = useState({});
  const [feed, setFeed] = useState({});
  const [ranking, setRanking] = useState({});
  const [clickButtonMenu, setClickButtonMenu] = useState([]);

  // Reports
  const [showRankingReport, setShowRankingReport] = useState(false);
  const [showAccessReport, setShowAccessReport] = useState(false);
  const [showFeedReport, setShowFeedReport] = useState(false);

  // modal
  const [modalApp, setModalApp] = useState(false);

  const backToDashboard = () => {
    setShowRankingReport(false);
    setShowAccessReport(false);
    setShowFeedReport(false);
  };

  const getDashboardData = useCallback(() => {
    const reqParams = { eventId, jwt };

    getDashboardRanking(reqParams)
      .then((response) => {
        setRanking(response.cachedData || {});
      })
      .catch((error) => {
        // console.log(error);
      });

    getDashboardFeed(reqParams)
      .then((response) => {
        setFeed(response.cachedData || {});
      })
      .catch((error) => {
        // console.log(error);
      });

    getDashboardAccesses(reqParams)
      .then((response) => {
        setAccesses(response.cachedData || {});
      })
      .catch((error) => {
        // console.log(error);
      });
    getClickButtonMenu(reqParams)
      .then((response) => {
        setClickButtonMenu(response?.cachedData);
      })
      .catch((error) => {
        // console.log(error);
      });
  }, [eventId, jwt]);

  useEffect(() => {
    getDashboardData();
  }, [getDashboardData]);

  useEffect(() => {
    getDashboardData();
  }, []);

  // console.log({ ranking, feed, accesses });

  return (
    <>
      {modalApp && (
        <ModalConfigApp
          isOpen={modalApp}
          toggle={() => setModalApp(!modalApp)}
          eventId={eventId}
          jwt={jwt}
        />
      )}
      {showAccessReport && (
        <div>
          <div className="back-to-dashboard">
            <i onClick={backToDashboard} className="bx bx-arrow-back" />
            <span onClick={backToDashboard}>Dashboard</span>
          </div>
          <Participants idEvent={eventId} reportToDashboard={true} />
        </div>
      )}
      {showRankingReport && (
        <div>
          <div className="back-to-dashboard">
            <i onClick={backToDashboard} className="bx bx-arrow-back" />
            <span onClick={backToDashboard}>Dashboard</span>
          </div>
          <Ranking idEvent={eventId} />
        </div>
      )}
      {showFeedReport && (
        <div>
          <div className="back-to-dashboard">
            <i onClick={backToDashboard} className="bx bx-arrow-back" />
            <span onClick={backToDashboard}>Dashboard</span>
          </div>
          <Feed idEvent={eventId} reportToDashboard={true} />
        </div>
      )}
      {!showRankingReport && !showAccessReport && !showFeedReport && (
        <div>
          <CardTitle style={{ marginBottom: 16 }}>Dashboard</CardTitle>
          <div className="div-config-app">
            <Button
              color="success"
              className="btn-rounded waves-effect waves-light events-button-add"
              onClick={() => setModalApp(true)}
            >
              Configurar APP
            </Button>
          </div>
          <Row>
            <Col xs="12" lg="6" s="8">
              <Card className="card-shadow">
                <CardBody>
                  <PieChart data={accesses} title="Acessos" />
                  <div className="dashboard-access-footer">
                    <span style={{ color: "black", fontWeight: 700 }}>
                      Total de Usuários cadastrados:{" "}
                      {accesses.totalparticipants}
                    </span>
                    <Button onClick={() => setShowAccessReport(true)}>
                      <i className="bx bxs-report" />
                      <span>Relatório Detalhado</span>
                    </Button>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xs="12" lg="6">
              <Card className="card-shadow">
                <CardBody>
                  <FeedChart feed={feed} />
                  <div className="dashboard-to-ranking">
                    <Button onClick={() => setShowFeedReport(true)}>
                      <i className="bx bxs-report" />
                      <span>Relatório Detalhado</span>
                    </Button>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xs="12" lg="6">
              <Card className="card-shadow">
                <CardBody>
                  <RankChart ranking={ranking} />
                  <div className="dashboard-to-ranking">
                    <Button onClick={() => setShowRankingReport(true)}>
                      <i className="bx bxs-report" />
                      <span>Relatório Detalhado</span>
                    </Button>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xs="12" lg="6">
              <Card className="card-shadow">
                <CardBody>
                  <DonutChart
                    data={clickButtonMenu?.data}
                    title="Cliques do Menu"
                    style={{
                      zIndex: 1,
                    }}
                  />
                  <span style={{ color: "black", fontWeight: 700 }}>
                    Total de Cliques: {clickButtonMenu?.total}
                  </span>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
}
