import React, { useState } from 'react';
import Select from "react-select";

import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row, 
  UncontrolledTooltip
} from 'reactstrap';

import { brazilianStates } from '../static/BrazilianStates';

export default function SelectInput({ deleteComponent, rowId, colId, attributes, setAttributes }) {
  const [inputName, setInputName] = useState(attributes ? attributes.inputName : '');
  const [label, setLabel] = useState(attributes ? attributes.label : 'Label');
  const [placeholder, setPlaceholder] = useState(attributes ? attributes.placeholder : 'Placeholder');
  const [tipText, setTipText] = useState(attributes ? attributes.tipText : 'Dica');
  const [hasTip, setHasTip] = useState(attributes ? attributes.hasTip : false);
  const [required, setRequired] = useState(attributes ? attributes.required : false);
  const [isMulti, setIsMulti] = useState(attributes ? attributes.isMulti : false);
  const [selectValues, setSelectValues] = useState(attributes ? attributes.selectValues : []);
  const [isBrazilianStates, setIsBrazilianStates] = useState(attributes ? attributes.isBrazilianStates : false);
  const [deleteOption, setDeleteOption] = useState('');

  // Error
  const [error, setError] = useState(false);
  const [nameError, setNameError] = useState(false);

  // Edit Modal
  const [editModal, setEditModal] = useState(false);

  const addBrazilianStates = (checked) => {
    if (checked) {
      setSelectValues(brazilianStates);
      setIsBrazilianStates(true);
      setError(false);
    } else {
      setSelectValues([]);
      setIsBrazilianStates(false);
    }
  }

  function addOption(newOption) {
    setError(false);
    if (selectValues.every((item) => item.name !== newOption)) {
      setSelectValues([...selectValues, {name: newOption}]);
    }
  }

  function removeOption(index) {
    const newTag = [...selectValues];
    newTag.splice(index, 1);
    setSelectValues(newTag);
  }

  const submitForm = (e) => {
    e.preventDefault();
    if (selectValues.length === 0) {
      setError(true);
      return;
    }

    setError(false);

    if (inputName === '') {
      setNameError(true);
      return;
    }

    setNameError(false);

    setAttributes({
      rowId,
      colId,
      attributes: {
        inputName,
        label,
        placeholder,
        tipText,
        hasTip,
        required,
        selectValues,
        isMulti,
        isBrazilianStates
      },
    });
    setEditModal(false);
  };

  return (
    <div className="custom-input-container">
      <Modal
        size="lg"
        isOpen={editModal}
        autoFocus={true}
        centered={true}
      >
        <ModalHeader toggle={() => setEditModal(false)}>
          Configurar Input de Seleção
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <Form>
                <FormGroup className="input-margin-btm">
                  <Label htmlFor="edit-input-name" className="form-label">
                    Nome do campo
                  </Label>
                  <Input
                    id="edit-input-name"
                    className="form-control"
                    type="text"
                    placeholder="Nome do campo"
                    value={inputName}
                    onChange={({ target }) => setInputName(target.value)}
                  />
                  {nameError && <p style={{color: 'red'}}>O campo Nome do Campo é obrigatório!</p>}
                </FormGroup>
                <FormGroup className="input-margin-btm">
                  <Label htmlFor="edit-input-text" className="form-label">
                    Texto da Label
                  </Label>
                  <Input
                    id="edit-input-text"
                    className="form-control"
                    type="text"
                    value={label}
                    onChange={({ target }) => setLabel(target.value)}
                  />
                </FormGroup>
                <FormGroup className="input-margin-btm">
                  <Label htmlFor="edit-input-placeholder" className="form-label">
                    Texto do placeholder
                  </Label>
                  <Input
                    id="edit-input-placeholder"
                    className="form-control"
                    type="text"
                    value={placeholder}
                    onChange={({ target }) => setPlaceholder(target.value)}
                  />
                </FormGroup>
                <FormGroup className="input-margin-btm">
                  <div style={{display: 'flex'}}>
                    <Label htmlFor="edit-input-options" className="form-label">
                      Adicionar opções
                    </Label>
                    <div>
                      <div className="" id={`select-${rowId}-${colId}`}>
                        <span>?</span>
                      </div>
                      <UncontrolledTooltip style={{ backgroundColor: '#6f42c1' }} placement="right" target={`select-${rowId}-${colId}`}>
                        Pressione Enter para adicionar uma opção
                      </UncontrolledTooltip>
                    </div>
                  </div>
                  <Input
                    id="edit-input-options"
                    className="form-control"
                    type="text"
                    onKeyPress={(e) => {
                      if (e.key === "Enter" && e.target.value !== '') {
                        addOption(e.target.value);
                        e.target.value = '';
                      }
                    }}
                  />
                </FormGroup>
                {error && <p style={{color: 'red'}}>Adicione pelo menos uma opção!</p>}
                <FormGroup className="input-margin-btm">
                  <Label htmlFor="edit-input-delet-options" className="form-label">
                    Excluir opções
                  </Label>
                  <Select
                    placeholder="Selecione a opção a ser excluída"
                    isMulti={false}
                    value={deleteOption}
                    onChange={(e) => removeOption(e) > setDeleteOption('')}
                    options={[
                      {
                        options: [
                          ...selectValues.map((e) => {
                            return { label: e.name, value: e.name };
                          }),
                        ],
                      },
                    ]}
                    id="formFile"
                  />
                </FormGroup>
                <FormGroup className="input-margin-btm">
                  <Input
                    className="input-mg-rgt"
                    id="edit-input-brazilian-states"
                    type="checkbox"
                    checked={isBrazilianStates}
                    onChange={() => addBrazilianStates(!isBrazilianStates)}
                  />
                  <Label htmlFor="edit-input-brazilian-states" className="form-label">
                    É um select de estados brasileiros?
                  </Label>
                </FormGroup>
                <FormGroup className="input-margin-btm">
                  <Input
                    className="input-mg-rgt"
                    id="edit-input-is-multi"
                    type="checkbox"
                    checked={isMulti}
                    onChange={({ target }) => setIsMulti(target.checked)}
                  />
                  <Label htmlFor="edit-input-is-multi" className="form-label">
                    Permitir múltiplas opções?
                  </Label>
                </FormGroup>
                <FormGroup className="input-margin-btm">
                  <Input
                    className="input-mg-rgt"
                    id="edit-input-has-tip"
                    type="checkbox"
                    checked={hasTip}
                    onChange={({ target }) => setHasTip(target.checked)}
                  />
                  <Label htmlFor="edit-input-has-tip" className="form-label">
                    Possui dica?
                  </Label>
                  {hasTip && (
                    <FormGroup className="input-margin-btm">
                      <Label htmlFor="edit-input-tip" className="form-label">
                        Texto da dica
                      </Label>
                      <Input
                        id="edit-input-tip"
                        className="form-control"
                        type="text"
                        value={tipText}
                        onChange={({ target }) => setTipText(target.value)}
                      />
                    </FormGroup>
                  )}
                </FormGroup>
                <FormGroup className="input-margin-btm">
                  <Input
                    className="input-mg-rgt"
                    id="edit-input-required"
                    type="checkbox"
                    checked={required}
                    onChange={({ target }) => setRequired(target.checked)}
                  />
                  <Label htmlFor="edit-input-required" className="form-label">
                    Campo obrigatório?
                  </Label>
                </FormGroup>
              </Form>
            </Col>

            {/* Preview Section */}
            <Col style={{borderLeft: '1px solid gray'}}>
              <div style={{ display: 'flex' }}>
                <Label className="form-label" htmlFor="formText">
                  {label === "Label" ? label : <div dangerouslySetInnerHTML={{__html: label}} />}
                </Label>
                {hasTip && (
                  <div>
                    <div className="" id={`select-tip-${rowId}-${colId}`}>
                      <span>?</span>
                    </div>
                    <UncontrolledTooltip style={{ backgroundColor: '#6f42c1' }} placement="right" target={`select-tip-${rowId}-${colId}`}>
                      {tipText}
                    </UncontrolledTooltip>
                  </div>
                )}
              </div>
              <Select
                placeholder={placeholder}
                isMulti={isMulti}
                options={[
                  {
                    options: [
                      ...selectValues.map((e) => {
                        return { label: e.name, value: e.name };
                      }),
                    ],
                  },
                ]}
                id="formFile"
              />
            </Col>
          </Row>
          <div className="save-btn-form-container">
            <Button
              color="success"
              className="btn btn-success waves-effect waves-light save-btn-form"
              onClick={submitForm}
            >
              Salvar
            </Button>
          </div>
        </ModalBody>
      </Modal>
      <div>
        <div className="displayed-config-container">
          <div className="displayed-label">
            <Label className="form-label" htmlFor="formText">
              {label === "Label" ? label : <div dangerouslySetInnerHTML={{__html: label}} />}
            </Label>
            {hasTip && (
              <div>
                <div className="" id={`select-tip-${rowId}-${colId}`}>
                  <span>?</span>
                </div>
                <UncontrolledTooltip style={{ backgroundColor: '#6f42c1' }} placement="right" target={`select-tip-${rowId}-${colId}`}>
                  {tipText}
                </UncontrolledTooltip>
              </div>
            )}
          </div>
          <div className="config-form-icon">
            <span
              className="mdi mdi-cog-outline"
              onClick={() => setEditModal(!editModal)}
            />
            <span
              className="mdi mdi-close close-icon"
              onClick={() => deleteComponent(rowId, colId)}
            />
          </div>
        </div>
      </div>
      <Select
        placeholder={placeholder}
        isMulti={isMulti}
        options={[
          {
            options: [
              ...selectValues.map((e) => {
                return { label: e.name, value: e.name };
              }),
            ],
          },
        ]}
        id="formFile"
      />
    </div>
  )
}
