import React, { useEffect, useState } from 'react';

import { Input, Label, UncontrolledTooltip } from 'reactstrap';
import { inputMask } from '../../../../utils/helpers/masks/masks';
import { validation } from '../../../../utils/helpers/masks/validations';

export default function CellphoneDisplay({ attributes, rowId, colId, setValue }) {
  // Mask
  const [maskedValue, setMaskedValue] = useState('');

  const handleChange = (value) => {
    inputMask({ mask: 'cellphone', value, setMaskedValue });
  };

  useEffect(() => {
    const html = attributes.label;
    const div = document.getElementsByClassName(`cellphone-label-${rowId}-${colId}`);
  
    for (const element of div) {
      element.innerHTML = html;
    }
  }, [attributes, rowId, colId])

  useEffect(() => {
    const {isValid, message} = validation(maskedValue, 'cellphone');

    setValue((oldValue) => {
      const newValue = [...oldValue];
      newValue[rowId].cols[colId].attributes.value = maskedValue;
      if (!isValid) {
        newValue[rowId].cols[colId].attributes.errorMessage = message;
      } else {
        newValue[rowId].cols[colId].attributes.errorMessage = '';
      }
      return newValue;
    });
  }, [maskedValue, setValue, rowId, colId]);

  return (
    <div>
      <div className="custom-input-container">
        <div className="displayed-config-container">
          <div className="displayed-label">
            <Label htmlFor="formText" className={`form-label cellphone-label-${rowId}-${colId}`} />
            {attributes.hasTip && (
              <div>
                <div className="show-tips" id={`cell-disp-tip-${rowId}-${colId}`}>
                  <span>?</span>
                </div>
                <UncontrolledTooltip style={{ backgroundColor: '#6f42c1' }} placement="right" target={`cell-disp-tip-${rowId}-${colId}`}>
                  {attributes.tipText}
                </UncontrolledTooltip>
              </div>
            )}
          </div>
        </div>
        <Input
          className="form-control"
          type="text"
          id="formText"
          placeholder={attributes.placeholder}
          value={maskedValue}
          onChange={({ target }) => handleChange(target.value)}
        />
      </div>
    </div>
  )
}
