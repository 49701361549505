import React, { useCallback, useEffect, useState } from "react";
import { MetaTags } from "react-meta-tags";
import { useSelector } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Collapse,
  Row,
  Table,
} from "reactstrap";

import SweetAlert from "react-bootstrap-sweetalert";

import Pagination from "../../components/Paginationv3";
import useDebounce from "../../utils/hooks/useDebounce";
import SectionComponent from "../Apps/Personalization/SectionComponent";
import EditImg from "./ModalDriveEdit";
import NewImg from "./ModalNewDrive";
import { deleteImg, getImg } from "../../utils/services/drive";
import SearchFilters from "../../components/SearchFilters";

const columnsFilter = [
  { name: "Nome", value: "name", type: "text" },
  { name: "Tag", value: "value", type: "text" },
  { name: "Data de Criação", value: "createdAt", type: "date" },
];
function InteegraDrive() {
  const { token: jwt } = useSelector((state) => state.global_user_data.data);
  // Registered Images
  const [images, setImages] = useState([]);

  //paginação
  const [currentIndex, setCurrentIndex] = useState(1);
  const [countData, setCountData] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  //search
  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounce(search, 300);

  // New Image Modal
  const [isNewImgOpen, setIsNewImgOpen] = useState(false);

  // Edit Image Modal
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [editImg, setEditImg] = useState("");

  // Delete Image Modal
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [successDelete, setSuccessDelete] = useState(false);
  const [deleteImgId, setDeleteImgId] = useState("");

  // collapse
  const [isCollapse, setIsCollapse] = useState(false);

  const [collapseIsOpen, setCollapseIsOpen] = useState(false);
  const [activeFilter, setActiveFilter] = useState("name");

  function setActiveFilterHandle(columnActive) {
    setActiveFilter(columnActive);
    setSearch("");
  }
  const reverseDate = () => {
    const reverse = debouncedSearch
      .split("/")
      .reverse()
      .join("/")
      .replace(/\//g, "-");
    return reverse;
  };
  function treatmentSearch() {
    if (
      columnsFilter?.filter((item) => item?.value === activeFilter)[0]?.type ===
      "date"
    ) {
      return reverseDate();
    }
    return debouncedSearch;
  }

  async function clipBoard(url) {
    await navigator.clipboard.writeText(url);
    alert("O link foi copiado para a área de transferência");
  }

  const getImages = useCallback(() => {
    const type = "InteegraDrive";
    getImg(
      jwt,
      currentIndex,
      itemsPerPage,
      type,
      treatmentSearch(),
      activeFilter
    )
      .then((res) => {
        setImages(res.files);
        setCountData(res.total);
      })
      .catch((err) => {
        // console.log(err);
      });
  }, [jwt, currentIndex, itemsPerPage, debouncedSearch]);

  const deleteImage = async () => {
    await deleteImg(jwt, deleteImgId.id);
    setSuccessDelete(true);
    getImages();
  };

  useEffect(() => {
    getImages();
  }, [getImages]);

  return (
    <div>
      {isDeleteOpen && (
        <SweetAlert
          warning
          showCancel
          confirmBtnText="Sim"
          cancelBtnText="Não"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          title={`Você tem certeza que deseja excluir o arquivo ${deleteImgId.name}?`}
          onConfirm={() => {
            setIsDeleteOpen(false);
            deleteImage();
          }}
          onCancel={() => {
            setIsDeleteOpen(false);
          }}
        >
          Você não poderá reverter essa ação.
        </SweetAlert>
      )}
      {successDelete && (
        <SweetAlert
          success
          title="Deletado"
          onConfirm={() => {
            setSuccessDelete(false);
          }}
        >
          {`Arquivo ${deleteImgId.name} deletado com sucesso`}
        </SweetAlert>
      )}
      <EditImg
        isOpen={isEditOpen}
        editImgId={editImg}
        refetch={getImages}
        toggle={() => setIsEditOpen(false)}
      />
      <NewImg
        toggle={() => setIsNewImgOpen(false)}
        isOpen={isNewImgOpen}
        refetch={getImages}
      />
      <MetaTags>
        <title>Inteegra Drive | Inteegra Plus</title>
      </MetaTags>
      <Card>
        <CardBody>
          <CardTitle>Inteegra Drive</CardTitle>
          <div>
            <Row
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: 10,
                width: "50px",
              }}
            >
              <SectionComponent
                sectionName="Filtros"
                setIsOpen={setCollapseIsOpen}
                isOpen={collapseIsOpen}
              />
            </Row>
            <Collapse isOpen={collapseIsOpen}>
              <SearchFilters
                columns={columnsFilter}
                activeFilter={activeFilter}
                setActiveFilterHandle={setActiveFilterHandle}
              />
            </Collapse>
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <form
              className="app-search d-none d-lg-block events-input-search"
              onSubmit={(e) => e.preventDefault()}
            >
              <div className="position-relative">
                <input
                  type={activeFilter === "createdAt" ? "date" : "text"}
                  value={search}
                  className="form-control"
                  placeholder="Pesquisar..."
                  onChange={(e) => setSearch(e.target.value)}
                />
                <span className="bx bx-search-alt" />
              </div>
            </form>
            <div className="mobile-btn-section" style={{ width: "100%" }}>
              <Row className="mobile-btns-top">
                <form
                  className="app-search"
                  style={{ padding: "0px" }}
                  onSubmit={(e) => e.preventDefault()}
                >
                  <div className="position-relative">
                    <input
                      type={activeFilter === "createdAt" ? "date" : "text"}
                      value={search}
                      className="form-control"
                      placeholder="Pesquisar..."
                      onChange={(e) => setSearch(e.target.value)}
                    />
                    <span className="bx bx-search-alt" />
                  </div>
                </form>
              </Row>
              <SectionComponent
                sectionName="Opções"
                setIsOpen={setIsCollapse}
                isOpen={isCollapse}
              />
              <Collapse isOpen={isCollapse}>
                <Row className="mobile-btns-top">
                  <div onClick={() => setIsNewImgOpen(true)}>
                    Adicionar Arquivo
                  </div>
                </Row>
              </Collapse>
            </div>
            <div className="header-btns-container">
              <Button
                color="success"
                className="btn-rounded waves-effect waves-light events-button-add"
                onClick={() => setIsNewImgOpen(true)}
              >
                Adicionar Arquivo
              </Button>
            </div>
          </div>
          <div className="table-responsive">
            <Table className="table mb-0">
              <thead className="table-light">
                <tr>
                  <th>Nome do Arquivo</th>
                  <th>Tag</th>
                  <th>Data de Criação</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                {images.length > 0 &&
                  images
                    .sort(
                      (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
                    )
                    .map((img, i) => (
                      <tr key={i}>
                        <td>{img.name}</td>
                        <td>
                          {img.tags.map((tag) => (
                            <span className="tagDriver">{tag.value + " "}</span>
                          ))}
                        </td>
                        {/* <td>{img.tags.map((tag) => tag.value).join(", ")}</td> */}
                        <td>
                          {img.createdAt
                            .substr(0, 10)
                            .split("-")
                            .reverse()
                            .join("/")}
                        </td>
                        <td>
                          <i
                            className="mdi mdi-content-copy"
                            style={{
                              color: "grey",
                              fontSize: 17,
                              margin: "0 3px",
                              cursor: "pointer",
                            }}
                            title="Copiar"
                            onClick={() => clipBoard(img.url)}
                          />
                          <a
                            href={img.url}
                            target="_blank"
                            rel="noopener noreferrer"
                            title="Abrir Arquivo"
                          >
                            <i
                              className="mdi mdi-location-enter"
                              style={{
                                color: "blue",
                                fontSize: 17,
                                margin: "0 3px",
                                cursor: "pointer",
                              }}
                            />
                          </a>
                          <i
                            className="mdi mdi-pencil"
                            title="Editar"
                            style={{
                              margin: "0 3px",
                              color: "green",
                              fontSize: 17,
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              setEditImg(img) > setIsEditOpen(true)
                            }
                          />
                          <i
                            className="mdi mdi-trash-can"
                            title="Excluir"
                            style={{
                              color: "red",
                              fontSize: 17,
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              setDeleteImgId(img) > setIsDeleteOpen(true)
                            }
                          />
                        </td>
                      </tr>
                    ))}
              </tbody>
            </Table>
          </div>
        </CardBody>
      </Card>
      <div className="bottom-navigation" style={{ justifyContent: "end" }}>
        {/* 
        Fornecer os 4 parametros obrigatórios;
        ** data - a array contendo as informações da tabela
        ** search - o state do termo de pesquisa
        ** currentIndex - posição atual do index
        ** setCurrentIndex - função para atualizar a posição atual do index
        */}
        <Pagination
          data={images}
          // search={search}
          currentIndex={currentIndex}
          setCurrentIndex={setCurrentIndex}
          countData={countData}
          maxItemPerPage={itemsPerPage}
          setMaxItemPerPage={setItemsPerPage}
          maxPageButtonsToShow={3}
        />
      </div>
    </div>
  );
}

export default InteegraDrive;
