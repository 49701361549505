import React from 'react';
import ModalEditSession from './ModalEditSession';
import ModalNewSession from './ModalNewSession';
import ModalDeleteSession from './ModalDeleteSession';

export default function CompositionModals({
  showModalNewSession = false,
  showModalEditSession = false,
  showModalDeleteSession = false,
  toggleModalNewSession,
  toggleModalEditSession,
  toggleModalDeleteSession,
  sessionItem,
  sessionsSource,
  addSession,
  updateSession,
  deleteSession,
  sessions,
}) {
  return (
    <>
      {showModalNewSession && (
        <ModalNewSession
          isOpen={showModalNewSession}
          toggle={toggleModalNewSession}
          sessionsData={sessions}
          addSession={addSession}
        />
      )}
      {showModalEditSession && (
        <ModalEditSession
          isOpen={showModalEditSession}
          toggle={toggleModalEditSession}
          sessionItem={sessionItem}
          sessionsSourceData={sessionsSource}
          updateSession={updateSession}
        />
      )}
      {showModalDeleteSession && (
        <ModalDeleteSession
          isOpen={showModalDeleteSession}
          toggle={toggleModalDeleteSession}
          sessionItem={sessionItem}
          deleteSession={deleteSession}
        />
      )}
    </>
  );
}
