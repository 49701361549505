import writeXlsxFile from "write-excel-file";
import { getSalesConsolidated } from "../../../../utils/services/sales";
import moment from "moment";

export async function createXlsx({
  token,
  search,
  eventId,
  limit,
  columnSearch,
  countData,
  setFinishExport,
  getTypePayment,
  getStatusPayment,
}) {
  const schema = [
    {
      column: "ID",
      type: String,
      value: (sale) => sale.id,
    },
    {
      column: "Email Pagador",
      type: String,
      value: (sale) => sale.email,
    },
    {
      column: "Valor Total",
      type: String,
      value: (sale) => sale.amount,
    },
    {
      column: "Tipo Pagamento",
      type: String,
      value: (sale) => getTypePayment(sale.type),
    },
    {
      column: "Data Pagamento",
      type: String,
      value: (sale) => moment(sale.updated_at).format("DD/MM/YY"),
    },
    {
      column: "Status",
      type: String,
      value: (sale) => getStatusPayment(sale.status).text,
    },
  ];

  const allRequestsPromises = [];
  const numberOfRequisitions = Math.ceil(countData / limit);

  for (let i = 1; i <= numberOfRequisitions; i += 1) {
    const request = await getSalesConsolidated({
      token,
      search,
      eventId,
      currentIndex: i,
      itemsPerPage: limit,
      columnSearch,
    });
    // }).then((response) => console.log(response));
    // return;
    allRequestsPromises.push(request);
    setTimeout(() => {}, 500);

    const progressBar = document.querySelector(".export-progress");
    const percentageBar = document.querySelector(".export-percentage");

    progressBar.style.width = `${(i / numberOfRequisitions) * 100}%`;
    percentageBar.innerHTML = `${((i / numberOfRequisitions) * 100).toFixed(
      0
    )}%`;

    if (i === numberOfRequisitions) {
      setTimeout(() => {
        setFinishExport(true);
      }, 1000);
    }
  }

  const allDataInSingleArray = allRequestsPromises
    .map((e) => e.transactions)
    .flat();
  if (allDataInSingleArray) {
    await writeXlsxFile(allDataInSingleArray, {
      schema,
      fileName: "vendasConsolidadas.xlsx",
    });
  }
}
