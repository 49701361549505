import React, { useState } from "react";
import { useSelector } from "react-redux";

import {
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  Button,
  Collapse,
} from "reactstrap";

import SweetAlert from "react-bootstrap-sweetalert";

import UploadComponent from "../../Apps/Personalization/UploadComponent";
import ColorPicker from "../../../components/ColorPicker/ColorPicker";
import ButtonSpinner from "../../../components/Buttons/ButtonSpinner";
import SectionComponent from "../../Apps/Personalization/SectionComponent";

import {
  getCertificateByName,
  newCertificate,
} from "../../../utils/services/certificate";
import { getUrlFiles } from "../../../utils/services/files";

export default function ModalNewCertificate(props) {
  const { token: jwt } = useSelector((state) => state.global_user_data.data);
  const { isOpen, toggle, eventId, refetch } = props;

  const [modalBackdrop, setModalBackdrop] = useState(false);
  const [tags, setTags] = useState([]);
  const [image, setImage] = useState("");
  const [color, setColor] = useState("#ffffff");
  const [name, setName] = useState("");
  //error state
  const [nameError, setNameError] = useState(false);
  const [imageError, setImageError] = useState(false);
  const [fontSizeError, setFontSizeError] = useState(false);
  const [nameIsRegistered, setNameIsRegistered] = useState(false);
  // const [colorError, setColorError] = useState(false);
  const [heightError, setHeightError] = useState(false);
  const [widthError, setWidthError] = useState(false);
  const [alignError, setAlignError] = useState(false);
  // collapse
  const [collapseIsOpen, setCollapseIsOpen] = useState(false);

  const [success, setSuccess] = useState(false);

  const [isLoading, setIsloading] = useState(false);

  function toggleBackdrop() {
    setModalBackdrop(!modalBackdrop);
  }

  function addTags(newTags) {
    setTags([...new Set([...tags, newTags])]);
  }

  function removeTags(index) {
    const newTags = [...tags];
    newTags.splice(index, 1);
    setTags(newTags);
  }

  async function submitForm(e) {
    if (!e.target[0].value) {
      setNameError(true);
      return;
    } else setNameError(false);

    if (!image) {
      setImageError(true);
      return;
    } else setImageError(false);

    if (!e.target[3].value) {
      setCollapseIsOpen(true);
      setFontSizeError(true);
      return;
    } else setFontSizeError(false);

    if (e.target[4].value === "Selecione") {
      setCollapseIsOpen(true);
      setAlignError(true);
      return;
    } else setAlignError(false);

    if (!e.target[5].value) {
      setCollapseIsOpen(true);
      setHeightError(true);
      return;
    } else setHeightError(false);

    if (!e.target[6].value) {
      setCollapseIsOpen(true);
      setWidthError(true);
      return;
    } else setWidthError(false);

    const nameAlreadyExists = await getCertificateByName({
      jwt,
      eventId,
      name: e.target[0].value,
    });

    if (nameAlreadyExists.data.message === "CERTIFICATE_FOUND") {
      setNameIsRegistered(true);
      return;
    } else setNameIsRegistered(false);

    const formData = new FormData();
    formData.append("files", image.files[0]);
    formData.append("name", name);
    formData.append("folder", "certificados");
    formData.append("eventId", eventId);

    let urlFile;
    let expireDate = null;
    let imageId = null;

    await getUrlFiles(formData, jwt)
      .then((response) => {
        response.forEach((e) => {
          urlFile = e.url;
          expireDate = e.expires;
          imageId = e.fileId;
        });
      })
      .catch((error) => {
        // console.log(error);
      });

    const data = {
      name: e.target[0].value,
      imageId: imageId,
      eventId,
      image: urlFile,
      fontSize: +e.target[3].value,
      textAlignment: e.target[4].value,
      heightPosition: +e.target[5].value,
      widthPosition: +e.target[6].value,
      color: color,
      expireImage: expireDate,
      tags: tags.map((tag) => ({ name: tag })),
    };

    setIsloading(true);
    await newCertificate({ data, jwt });
    setIsloading(false);
    setSuccess(true);
    refetch();
  }
  return (
    <Modal
      isOpen={isOpen}
      toggle={() => toggleBackdrop()}
      autoFocus
      centered
      size="lg"
    >
      {success && (
        <SweetAlert
          success
          title="Criação de Certificado"
          onConfirm={() => {
            setSuccess(false);
            toggle();
          }}
        >
          Certificado criado com sucesso!
        </SweetAlert>
      )}
      <ModalHeader toggle={toggle}>Novo Certificado</ModalHeader>
      <ModalBody style={{ padding: 20 }}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            submitForm(e);
          }}
        >
          <Row style={{ paddingLeft: 0, paddingRight: 0 }}>
            <label className="form-label" style={{ padding: 0 }}>
              Nome*
            </label>
            <input
              className="form-control"
              type="text"
              placeholder="Nome do certificado"
              onChange={(e) => setName(e.target.value)}
              value={name}
            />
            {nameError && (
              <p style={{ color: "red", padding: 0 }}>
                O campo Nome é obrigatório
              </p>
            )}
            {nameIsRegistered && (
              <p style={{ color: "red", padding: 0 }}>
                Já existe um certificado com esse nome
              </p>
            )}
          </Row>
          <Row style={{ marginTop: 10 }}>
            <label className="form-label" style={{ padding: 0 }}>
              Tags
            </label>
            <input
              className="form-control"
              type="text"
              placeholder="Digite e pressione a tecla Enter para adicionar a Tag"
              onKeyPress={(e) => {
                if (e.key === "Enter" && e.target.value !== "") {
                  addTags(e.target.value);
                  e.target.value = "";
                }
              }}
            />
          </Row>
          {tags?.length > 0 && (
            <Row>
              <div className="tag-container" style={{ padding: 0 }}>
                {tags.map((tags, index) => (
                  <div key={index} className="tag-wrapper">
                    <span className="tags-field">{tags}</span>
                    <i
                      className="mdi mdi-close"
                      onClick={() => removeTags(index)}
                    />
                  </div>
                ))}
              </div>
            </Row>
          )}
          <Row>
            <UploadComponent
              id="formFile"
              labelText="Upload da Imagem*"
              setStateFunc={setImage}
              tipText="Tamanho 1000x550"
            />
            {imageError && (
              <p style={{ color: "red", padding: 0 }}>
                O campo Upload de Imagem é obrigatório
              </p>
            )}
          </Row>
          <Row style={{ marginBottom: 10 }}>
            <SectionComponent
              sectionName="Configurações do Nome dos Participantes"
              setIsOpen={setCollapseIsOpen}
              isOpen={collapseIsOpen}
            />
          </Row>
          <Collapse isOpen={collapseIsOpen}>
            <Row>
              <Col md={6} style={{ paddingLeft: 0 }}>
                <label className="form-label" style={{ padding: 0 }}>
                  Tamanho da Fonte*
                </label>
                <input className="form-control" type="number" />
                {fontSizeError && (
                  <p style={{ color: "red", padding: 0 }}>
                    O campo Tamanho da Fonte é obrigatório
                  </p>
                )}
              </Col>
              <Col md={6} style={{ padding: 0 }}>
                <label className="form-label" style={{ padding: 0 }}>
                  Alinhamento do Texto*
                </label>
                <select className="form-control">
                  <option>Selecione</option>
                  <option>Esquerda</option>
                  <option>Centro</option>
                  <option>Direita</option>
                </select>
                {alignError && (
                  <p style={{ color: "red", padding: 0 }}>
                    O campo Alinhamento do Texto é obrigatório
                  </p>
                )}
              </Col>
            </Row>
            <Row style={{ marginTop: 10 }}>
              <Col md={6} style={{ paddingLeft: 0 }}>
                <label className="form-label" style={{ padding: 0 }}>
                  Posição em Altura*
                </label>
                <input className="form-control" type="number" />
                {heightError && (
                  <p style={{ color: "red", padding: 0 }}>
                    O campo Posição em Altura é obrigatório
                  </p>
                )}
              </Col>
              <Col md={6} style={{ padding: 0 }}>
                <label className="form-label" style={{ padding: 0 }}>
                  Posição Largura*
                </label>
                <input className="form-control" type="number" />
                {widthError && (
                  <p style={{ color: "red", padding: 0 }}>
                    O campo Posição Largura é obrigatório
                  </p>
                )}
              </Col>
            </Row>
            <Row style={{ marginTop: 10 }}>
              <label className="form-label" style={{ padding: 0 }}>
                Cor*
              </label>
              <ColorPicker
                classOfContainer="colorpiker-certificate"
                labelText=""
                setStateFunc={setColor}
                defaultColor={color}
              />
              {/* {colorError && (
                <p style={{ color: "red", padding: 0 }}>
                  O campo Cor é obrigatório
                </p>
              )} */}
            </Row>
          </Collapse>
          <Row
            style={{
              width: "100%",
              marginTop: 15,
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              color="danger"
              style={{ width: 80, marginRight: 10 }}
              onClick={() => toggle()}
            >
              Fechar
            </Button>
            <ButtonSpinner isLoading={isLoading} title={"Salvar"} />
          </Row>
        </form>
      </ModalBody>
    </Modal>
  );
}
