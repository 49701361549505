import Pickr from '@simonwep/pickr';
const configs = (el, defaultColor, disabled = false) => ({
  el: el,
  container: 'body',
  theme: 'nano',

  appClass: 'teste-class',

  useAsButton: false,

  padding: 8,

  inline: false,
  autoReposition: true,

  disabled,
  lockOpacity: false,

  outputPrecision: 0,

  comparison: true,

  default: defaultColor || '#FFFFFF',

  swatches: null,

  defaultRepresentation: 'HEX',

  showAlways: false,

  position: 'bottom-start',

  components: {
    palette: true,
    preview: true,
    opacity: false,
    hue: true,
    interaction: {
      input: true,
      save: true,
    },
  },
})

export function createColorPicker(classOfContainer, setStateFunc, defaultColor, disabled) {
  const pickerContainer = document.querySelector(`.${classOfContainer}`);
  const pickerElement = document.createElement('div');
  pickerContainer.insertBefore(pickerElement, pickerContainer.firstChild);
  const pickr = new Pickr(configs(pickerElement, defaultColor, disabled));
  pickr
    .on('save', (color) => {
      setStateFunc(color.toHEXA().toString(3))
      pickr.hide();
    });
}