import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader, Col, Row, Button } from "reactstrap";

//redux
import { connect } from "react-redux";

import SweetAlert from "react-bootstrap-sweetalert";

//service
import { createGroup } from "../../../utils/services/group";

import ButtonSpinner from "../../../components/Buttons/ButtonSpinner";

function NewGroup(props) {
  const { isOpen, toggle } = props;
  const [modalBackdrop, setModalBackdrop] = useState(false);
  //confirmação
  const [success_dlg, setsuccess_dlg] = useState(false);
  //state de erros
  const [descriptionError, setDescriptionError] = useState("");
  const [isLoading, setIsloading] = useState(false);

  function toggleBackdrop() {
    setModalBackdrop(!modalBackdrop);
  }

  async function submitForm(e) {
    if (e.target[0].value === "") {
      setDescriptionError("O campo Descrição é obrigatório");
      return;
    } else setDescriptionError("");
    setIsloading(true);
    await createGroup({
      eventId: props.idEvent,
      description: e.target[0].value,
      jwt: props.state.global_user_data.data.token,
    })
      .then((response) => {
        setIsloading(false);

        if (response.message === "created") {
          props.getGroupsFunc();
          setsuccess_dlg(true);
        }
        if (response.message === "GROUP_DUPLICATED") {
          setDescriptionError("Grupo já cadastrado nesse evento.");
        }
      })
      .catch((error) => {
        setIsloading(false);

        // console.log(error);
      });
  }

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => {
        toggleBackdrop();
      }}
      autoFocus={true}
      centered={true}
    >
      {success_dlg ? (
        <SweetAlert
          success
          title={"Criado"}
          onConfirm={() => {
            setsuccess_dlg(false);
            toggle();
          }}
        >
          {"Grupo criado com sucesso!"}
        </SweetAlert>
      ) : null}
      <ModalHeader toggle={toggle}>Adicionar Grupo</ModalHeader>
      <ModalBody>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            submitForm(e);
          }}
        >
          <Row>
            <Col lg={12}>
              <div>
                <label className="form-label">Descrição</label>
                <input className="form-control" type="text" />
                <p style={{ color: "red" }}>{descriptionError}</p>
              </div>
            </Col>
          </Row>
          <Row
            style={{
              width: "100%",
              margin: "auto",
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "1rem",
            }}
          >
            <Button
              color="danger"
              className="btn btn-danger waves-effect waves-light"
              style={{ width: 80, marginRight: "1rem" }}
              onClick={() => toggle(!isOpen)}
            >
              Fechar
            </Button>

            <ButtonSpinner isLoading={isLoading} title={"Salvar"} />
          </Row>
        </form>
      </ModalBody>
    </Modal>
  );
}

const mapStateToProps = function (state) {
  return { state: state };
};

export default connect(mapStateToProps)(NewGroup);
