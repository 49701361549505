export const optionCode = [
  {
    code: "LOGIN001",
    describe: "LOGIN"
  },
  {
    code: "LIKE001",
    describe: "CURTIR UM POST"
  },
  {
    code: "LIKERECEIVED001",
    describe: "CURTIDA RECEBIDA"
  },
  {
    code: "COMMENT001",
    describe: "COMENTAR UM POST"
  },
  {
    code: "COMMENTRECEIVED001",
    describe: "COMENTÁRIO RECEBIDO"
  },
  {
    code: "POSTCREATE001",
    describe: "CRIAR UM POST"
  },
];