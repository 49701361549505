import React, { useState, useEffect } from "react";

import {
  Table,
  Card,
  CardBody,
  CardTitle,
  Button,
  Collapse,
  Row,
} from "reactstrap";

import SweetAlert from "react-bootstrap-sweetalert";
import { useLocation } from "react-router-dom";

// import formatData from "../utils/formatData";
//useMutation
import { useMutation, useQuery, useQueries } from "react-query";

import useDebounce from "../../../utils/hooks/useDebounce";
//redux
import { connect } from "react-redux";
//paginação
import Pagination from "../../../components/Paginationv3";
//componentes
import ModalNewSchedule from "./ModalNewSchedule";
import ModalEditSchedule from "./ModalEditSchedule";
import ModalConfigQuotas from "./ModalConfigQuotas";
import ImportSchedule from "./ImportSchedule";
import SearchFilters from "../../../components/SearchFilters";
import ModalImportQuotasWrapper from "./ModalImportQuotasWrapper";
import ModalConfigSiteQuotas from "./ModalConfigSiteQuota";
import ModalConfigTemplateEmail from "./ModalConfigTemplateEmail";
import ModalSendEmail from "./ModalSendEmail";
import WaitExport from "../../../components/WaitExport";
import SectionComponent from "../../Apps/Personalization/SectionComponent";
import ModalParticipantsSession from "./modalParticipantsSession";
import DeleteButton from "../../../components/Buttons/DeleteButton";

//services
import {
  getSchedule,
  deleteSchedule,
  deleteAllSchedules,
  getParticipantsEvent,
} from "../../../utils/services/schedule";
// exportar
import { getListSessions } from "../../../utils/services/scheduleCotas";

import { createXlsx } from "./createXlsx";
import { createXlsxScheduleQuotas } from "./createXlsxScheduleQuotas";

function Schedule(props) {
  //pesquisa
  const [search, setSearch] = useState("");
  //modais
  const [modalNewSchedule, setModalNewSchedule] = useState(false);
  const [modalEditSchedule, setModalEditSchedule] = useState(false);
  const [modalImportSchedule, setModalImportSchedule] = useState(false);
  const [modalImportWrapper, setModalImportWrapper] = useState(false);
  const [modalQuotas, setModalQuotas] = useState(false);
  const [modalParticipantsSessions, setModalParticipantsSessions] =
    useState(false);
  const [selectedSession, setSelectedSession] = useState("");
  const [modalConfigSiteQuota, setModalConfigSiteQuota] = useState(false);
  const [modalConfigTemplateEmail, setModalConfigTemplateEmail] =
    useState(false);
  const [modalSendEmail, setModalSendEmail] = useState(false);
  //deletar Schedulee
  const [deleteScheduleState, setDeleteScheduleState] = useState();
  const [confirm_alert, setconfirm_alert] = useState(false);
  const [confirmAllDisabled, setConfirmAllDisabled] = useState(false);
  const [success_dlg, setsuccess_dlg] = useState(false);
  const [dynamic_title, setdynamic_title] = useState("");
  const [dynamic_description, setdynamic_description] = useState("");
  const [alertEmail, setAlertEmail] = useState(false);
  //editar Schedule
  const [editSchedule, setEditSchedule] = useState();
  //paginação
  const [currentIndex, setCurrentIndex] = useState(1);
  //state de Schedules
  const [schedules, setSchedules] = useState([]);
  const [countData, setCountData] = useState(1);
  //state de items por pag
  const [itemsPerPage, setItemsPerPage] = useState(10);
  // export in progress
  const [exportInProgress, setExportInProgress] = useState(false);
  const [finishExport, setFinishExport] = useState(false);
  // collapse
  const [isOpen, setIsOpen] = useState(false);
  const [collapseIsOpen, setCollapseIsOpen] = useState(false);
  const [activeFilter, setActiveFilter] = useState("titulo");
  // botões de importar e exportar
  const [importers, setImporters] = useState(false);
  const [itemToModalConfig, setItemToModalConfig] = useState("");
  const [sessions, setSessions] = useState([]);

  // token
  const token = props.state.global_user_data.data.token;

  const location = useLocation();

  const columnsFilter = [
    { name: "Título", value: "titulo", type: "text" },
    { name: "Data", value: "data", type: "date" },
  ];

  function checkProductExists(product) {
    if (location.state) {
      const search = location.state.event.product.filter(
        (e) => e.name === product
      );

      if (search[0]) {
        return true;
      } else return false;
    }
  }

  function setActiveFilterHandle(columnActive) {
    setActiveFilter(columnActive);
    setSearch("");
  }
  function toggleNewSchedule() {
    setModalNewSchedule(!modalNewSchedule);
  }

  function toggleEditSchedule() {
    setModalEditSchedule(!modalEditSchedule);
  }

  function toggleModalParticipantsSessions() {
    setModalParticipantsSessions(!modalParticipantsSessions);
  }

  function formatData(startDate) {
    var data = new Date(startDate);
    var dia = String(data.getDate()).padStart(2, "0");
    var mes = String(data.getMonth() + 1).padStart(2, "0");
    var ano = data.getFullYear();
    var dataAtual = dia + "/" + mes + "/" + ano;

    return dataAtual;
  }

  function toggleImportSchedule() {
    setModalImportSchedule(!modalImportSchedule);
  }

  function toggleImportWrapper() {
    setModalImportWrapper(!modalImportWrapper);
  }

  const debouncedSearch = useDebounce(search, 300);

  const reverseDate = () => {
    const reverse = debouncedSearch
      .split("/")
      .reverse()
      .join("/")
      .replace(/\//g, "-");
    return reverse;
  };

  function treatmentSearch() {
    if (
      columnsFilter.filter((item) => item.value === activeFilter)[0].type ===
      "date"
    ) {
      return reverseDate();
    }
    return debouncedSearch;
  }

  const data = useQueries([
    {
      queryKey: [
        "getAllSchedule" +
          props.state.global_user_data.data.foundLogin.id +
          currentIndex +
          itemsPerPage +
          debouncedSearch,
      ],
      notifyOnChangeProps: ["currentIndex"],
      queryFn: async () => {
        const scheduleData = await getSchedule({
          id: location.state.idEvent,
          jwt: token,
          offset: currentIndex > 0 ? currentIndex : 1,
          limit: itemsPerPage,
          search: treatmentSearch(),
          columnSearch: activeFilter,
        });
        setSchedules(scheduleData.agenda.data);
        setCountData(scheduleData.agenda.count);
        listSessions();
      },
    },
  ]);

  const confirmDelete = useMutation(
    async () => {
      return deleteSchedule({
        id: deleteScheduleState.id,
        jwt: token,
      });
    },
    {
      onSuccess: (data) => {
        const { message } = data;
        if (message === "AGENDA_DISABLED") {
          setconfirm_alert(false);
          setdynamic_title("Deletado");
          setsuccess_dlg(true);
          // modal e alerta de email
          setAlertEmail(false);
          setModalSendEmail(false);
        }
      },

      onError: (error) => {
        // console.log("error", error);
      },
    }
  );

  async function confirmDeleteAllSchedules() {
    await deleteAllSchedules({
      jwt: token,
      eventId: location.state.idEvent,
    }).then((response) => {
      setConfirmAllDisabled(false);
      setsuccess_dlg(true);
      setdynamic_title("Deletados");
      setdynamic_description("Transfers deletados");
    });
  }

  function checkProductExists(product) {
    if (location.state) {
      const search = location.state.event.product.filter(
        (e) => e.name === product
      );

      if (search[0]) {
        return true;
      } else return false;
    }
  }

  function verifyImporters() {
    if (
      checkProductExists("APP Eventos Plus") ||
      checkProductExists("RSVP") ||
      checkProductExists("Distribuição de Cotas")
    ) {
      setImporters(true);
    } else setImporters(false);
  }

  function checkProductExists(product) {
    if (location.state) {
      const search = location.state.event.product.filter(
        (e) => e.name === product
      );

      if (search[0]) {
        return true;
      } else return false;
    }
  }

  async function calculateDate(date) {
    var dateSession = new Date(date);
    var dateCurrent = new Date();

    dateCurrent.setTime(1715223660000);

    if (dateSession >= dateCurrent) {
      return true;
    } else return false;
  }

  async function getParticipantsEventFunc(sessionId) {
    var haveParticipant = false;
    await getParticipantsEvent(sessionId, token).then((res) => {
      if (res?.totalParticipantes?.count > 0) {
        haveParticipant = true;
      }
    });

    return haveParticipant;
  }

  useEffect(() => {
    verifyImporters();
    listSessions();
  }, []);

  useEffect(() => {
    listSessions();
    getSchedule({
      id: location.state.idEvent,
      jwt: token,
      offset: currentIndex > 0 ? currentIndex : 1,
      limit: itemsPerPage,
      search: debouncedSearch,
    }).then((scheduleData) => {
      setSchedules(scheduleData.agenda.data);
      setCountData(scheduleData.agenda.count);
    });
  }, [, modalQuotas, modalImportWrapper]);

  async function listSessions() {
    const jwt = props?.state?.global_user_data?.data?.token;
    const eventId = location.state.idEvent;
    if (!eventId || !jwt) return;

    const res = await getListSessions(eventId, jwt);

    if (res.data.cotasManuais.length > 0) {
      setSessions(res.data.cotasManuais);
    }
  }

  function getStockAvailable(idSession) {
    const data = sessions.filter((el) => el.agendaid === idSession)[0];

    return `cotas disponíveis: ${data?.availablemanuais} - cotas fechadas: ${data?.availablefechadas} - cotas sorteio: ${data?.availablesorteio} `;
  }

  return (
    <div>
      {modalNewSchedule && (
        <ModalNewSchedule
          isOpen={modalNewSchedule}
          toggle={toggleNewSchedule}
          idEvent={location.state.idEvent}
          data={data}
        />
      )}

      {modalEditSchedule && (
        <ModalEditSchedule
          isOpen={modalEditSchedule}
          toggle={toggleEditSchedule}
          idEvent={location.state.idEvent}
          schedule={editSchedule}
          data={data}
        />
      )}

      <ModalParticipantsSession
        isOpen={modalParticipantsSessions}
        toggle={toggleModalParticipantsSessions}
        session={selectedSession.agendaCota}
      />

      {modalQuotas && (
        <ModalConfigQuotas
          isOpen={modalQuotas}
          toggle={() => setModalQuotas(!modalQuotas)}
          data={itemToModalConfig}
          jwt={token}
        />
      )}

      {modalConfigSiteQuota && (
        <ModalConfigSiteQuotas
          isOpen={modalConfigSiteQuota}
          toggle={() => setModalConfigSiteQuota(!modalConfigSiteQuota)}
          jwt={token}
          eventId={location.state.idEvent}
        />
      )}

      {modalConfigTemplateEmail && (
        <ModalConfigTemplateEmail
          isOpen={modalConfigTemplateEmail}
          toggle={() => setModalConfigTemplateEmail(!modalConfigTemplateEmail)}
          eventId={location.state.idEvent}
          jwt={token}
        />
      )}

      <ModalImportQuotasWrapper
        isOpen={modalImportWrapper}
        toggle={toggleImportWrapper}
        idEvent={location.state.idEvent}
        token={token}
        checkProductExists={checkProductExists}
      />

      {modalSendEmail && (
        <ModalSendEmail
          isOpen={modalSendEmail}
          toggle={() => setModalSendEmail(!modalSendEmail)}
          confirmDelete={confirmDelete}
          deleteScheduleState={deleteScheduleState}
          jwt={token}
        />
      )}

      {confirm_alert ? (
        <SweetAlert
          title={`Deseja deletar ${deleteScheduleState.title}?`}
          warning
          showCancel
          confirmBtnText="Sim"
          cancelBtnText="Não"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            confirmDelete.mutate();
          }}
          onCancel={() => setconfirm_alert(false)}
        >
          Você não poderá reverter essa ação.
        </SweetAlert>
      ) : null}

      {confirmAllDisabled ? (
        <SweetAlert
          title={`Deseja remover todas as Agendas?`}
          warning
          showCancel
          confirmBtnText="Sim"
          cancelBtnText="Não"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            confirmDeleteAllSchedules();
          }}
          onCancel={() => setConfirmAllDisabled(false)}
        >
          <a>Você não poderá reverter essa ação.</a>
        </SweetAlert>
      ) : null}

      {success_dlg ? (
        <SweetAlert
          success
          title={dynamic_title}
          onConfirm={() => {
            data[0].refetch();
            setsuccess_dlg(false);
          }}
        ></SweetAlert>
      ) : null}

      {alertEmail && (
        <SweetAlert warning title={"Deseja enviar email?"} showConfirm={false}>
          <div className="div-options-modal">
            <Button
              color="primary"
              className="btn btn-primary"
              onClick={() => setAlertEmail(false)}
            >
              Fechar
            </Button>
            <Button
              className="btn btn-danger"
              onClick={() => confirmDelete.mutate()}
            >
              Não
            </Button>
            <Button
              className="btn btn-success"
              onClick={() => setModalSendEmail(true) > setAlertEmail(false)}
            >
              Sim
            </Button>
          </div>
        </SweetAlert>
      )}

      <WaitExport
        isOpen={exportInProgress}
        name={
          !checkProductExists("Distribuição de Cotas")
            ? "Agendas"
            : "Agendas Cotas"
        }
        preposicao="das"
        finishExport={finishExport}
        setFinishExport={setFinishExport}
        setExportInProgress={setExportInProgress}
      />
      <Card>
        <CardBody>
          <CardTitle>Agenda</CardTitle>
          {checkProductExists("Distribuição de Cotas") && (
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "flex-end",
                marginBottom: 10,
              }}
            >
              <div className="groups-match">
                <div className="group-btn-match header-btns-container">
                  <Button
                    color="warning"
                    className="btn-rounded waves-effect waves-light events-button-add"
                    onClick={() => setModalConfigTemplateEmail(true)}
                  >
                    Templates de Emails
                  </Button>
                </div>
              </div>
              <div className="groups-match">
                <div className="group-btn-match header-btns-container">
                  <Button
                    color="warning"
                    className="btn-rounded waves-effect waves-light events-button-add"
                    onClick={() => setModalConfigSiteQuota(true)}
                  >
                    Configurar Site Cotas
                  </Button>
                </div>
              </div>
            </div>
          )}
          <div>
            <Row
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: 10,
                width: "50px",
              }}
            >
              <SectionComponent
                sectionName="Filtros"
                setIsOpen={setCollapseIsOpen}
                isOpen={collapseIsOpen}
              />
            </Row>
            <Collapse isOpen={collapseIsOpen}>
              <SearchFilters
                columns={columnsFilter}
                activeFilter={activeFilter}
                setActiveFilterHandle={setActiveFilterHandle}
              />
            </Collapse>
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              marginBottom: 10,
            }}
          >
            <form
              className="app-search d-none d-lg-block events-input-search"
              onSubmit={(e) => e.preventDefault()}
            >
              <div className="position-relative">
                <input
                  type={activeFilter === "data" ? "date" : "text"}
                  value={search}
                  className="form-control"
                  placeholder="Pesquisar..."
                  onChange={(e) => setSearch(e.target.value)}
                />
                <span className="bx bx-search-alt" />
              </div>
            </form>

            <div className="mobile-btn-section" style={{ width: "100%" }}>
              <Row className="mobile-btns-top">
                <form
                  className="app-search"
                  style={{ padding: "0px" }}
                  onSubmit={(e) => e.preventDefault()}
                >
                  <div className="position-relative">
                    <input
                      type="text"
                      value={search}
                      className="form-control"
                      placeholder="Pesquisar..."
                      onChange={(e) => setSearch(e.target.value)}
                    />
                    <span className="bx bx-search-alt" />
                  </div>
                </form>
              </Row>
              <SectionComponent
                sectionName="Opções"
                setIsOpen={setIsOpen}
                isOpen={isOpen}
              />
              <Collapse isOpen={isOpen}>
                {importers &&
                  schedules.length > 0 &&
                  (checkProductExists("APP Eventos Plus") ||
                    checkProductExists("RSVP")) && (
                    <Row className="mobile-btns-top">
                      <div
                        onClick={() =>
                          createXlsx({
                            jwt: token,
                            id: location.state.idEvent,
                            limit: 100,
                            countData,
                            setFinishExport,
                          }) > setExportInProgress(true)
                        }
                      >
                        Exportar Agenda
                      </div>
                    </Row>
                  )}
                {checkProductExists("Distribuição de Cotas") &&
                  schedules.length > 0 && (
                    <Row className="mobile-btns-top">
                      <div
                        onClick={() =>
                          createXlsxScheduleQuotas({
                            jwt: token,
                            id: location.state.idEvent,
                            limit: 1000,
                            countData,
                            setFinishExport,
                          }) > setExportInProgress(true)
                        }
                      >
                        Exportar Agendas Cotas
                      </div>
                    </Row>
                  )}
                {importers && (
                  <Row className="mobile-btns-top">
                    <div
                      onClick={() => setModalImportWrapper(!modalImportWrapper)}
                    >
                      Importar Agenda
                    </div>
                  </Row>
                )}

                <Row className="mobile-btns-top">
                  <div onClick={() => setModalNewSchedule(true)}>
                    Adicionar Programação
                  </div>
                </Row>

                {checkProductExists("Distribuição de Cotas") && (
                  <>
                    <Row className="mobile-btns-top">
                      <div onClick={() => setModalConfigTemplateEmail(true)}>
                        Templates de Emails
                      </div>
                    </Row>
                    <Row className="mobile-btns-top">
                      <div onClick={() => setModalConfigSiteQuota(true)}>
                        Configurar Site Cotas
                      </div>
                    </Row>
                  </>
                )}
              </Collapse>
            </div>
            <div className="header-btns-container">
              {importers &&
                schedules.length > 0 &&
                (checkProductExists("APP Eventos Plus") ||
                  checkProductExists("RSVP")) && (
                  <Button
                    color="success"
                    className="btn-rounded waves-effect waves-light events-button-add"
                    onClick={() =>
                      createXlsx({
                        jwt: token,
                        id: location.state.idEvent,
                        limit: 100,
                        countData,
                        setFinishExport,
                      }) > setExportInProgress(true)
                    }
                  >
                    Exportar Agenda
                  </Button>
                )}
              {checkProductExists("Distribuição de Cotas") &&
                schedules.length > 0 && (
                  <Button
                    color="success"
                    className="btn-rounded waves-effect waves-light events-button-add"
                    onClick={() =>
                      createXlsxScheduleQuotas({
                        jwt: token,
                        id: location.state.idEvent,
                        limit: 1000,
                        countData,
                        setFinishExport,
                      }) > setExportInProgress(true)
                    }
                  >
                    Exportar Agendas Cotas
                  </Button>
                )}
              {importers && (
                <Button
                  color="success"
                  className="btn-rounded waves-effect waves-light events-button-add"
                  onClick={() => setModalImportWrapper(!modalImportWrapper)}
                >
                  Importar Agenda
                </Button>
              )}
              <Button
                color="success"
                className="btn-rounded waves-effect waves-light events-button-add"
                onClick={() => setModalNewSchedule(true)}
              >
                Adicionar Programação
              </Button>
            </div>
          </div>

          <div className="table-responsive">
            <Table className="table mb-0">
              <thead className="table-light">
                <tr>
                  <th>Título</th>
                  {(checkProductExists("RSVP") ||
                    checkProductExists("APP Eventos Plus")) && (
                    <th>Descrição</th>
                  )}
                  {checkProductExists("Distribuição de Cotas") && (
                    <th>Cotas Disponíveis</th>
                  )}
                  <th>Data</th>
                  <th>Hora Início</th>
                  <th>Hora Fim</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                {schedules.length > 0 &&
                  schedules
                    ?.filter(
                      (e) => e.title?.toLocaleLowerCase()
                      // ?.indexOf(search.toLocaleLowerCase()) > -1
                    )
                    // ?.filter((e) => e.enabled === true)
                    // ?.slice(currentIndex, currentIndex + 20)
                    .map((e, i) => (
                      <tr key={i}>
                        <th scope="row">{e.title}</th>
                        {(checkProductExists("RSVP") ||
                          checkProductExists("APP Eventos Plus")) && (
                          <td style={{ maxWidth: 200 }}>{e.description}</td>
                        )}
                        {checkProductExists("Distribuição de Cotas") && (
                          <td style={{ maxWidth: 200 }}>
                            {getStockAvailable(e.id)}
                          </td>
                        )}
                        <td>{formatData(e.startDate)}</td>
                        <td>{e.startHour}</td>
                        <td>{e.endHour}</td>
                        <td>
                          <i
                            className="mdi mdi-pencil"
                            style={{
                              color: "green",
                              fontSize: 17,
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setEditSchedule(e);
                              setModalEditSchedule(!modalEditSchedule);
                            }}
                          />
                          {checkProductExists("Distribuição de Cotas") && (
                            <>
                              {" "}
                              <i
                                className="dripicons-graph-pie"
                                style={{
                                  color: "blue",
                                  fontSize: 15,
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  setItemToModalConfig(e.agendaCota);
                                  setModalQuotas(true);
                                }}
                              />
                            </>
                          )}{" "}
                          {checkProductExists("Distribuição de Cotas") && (
                            <>
                              {" "}
                              <i
                                className="mdi mdi-account-multiple"
                                style={{
                                  color: "blue",
                                  fontSize: 17,
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  setSelectedSession(e);
                                  setModalParticipantsSessions(true);
                                }}
                              />
                            </>
                          )}{" "}
                          {/* <i
                            className="mdi mdi-trash-can"
                            style={{
                              color: "red",
                              fontSize: 17,
                              cursor: "pointer",
                            }}
                            onClick={async () => {
                              setDeleteScheduleState(e);

                              if (
                                checkProductExists("Distribuição de Cotas") &&
                                (await calculateDate(e.startDate)) &&
                                (await getParticipantsEventFunc(e.id))
                              ) {
                                setAlertEmail(true);
                              } else setconfirm_alert(true);
                            }}
                          /> */}
                          <DeleteButton
                            onClick={async () => {
                              setDeleteScheduleState(e);

                              if (
                                checkProductExists("Distribuição de Cotas") &&
                                (await calculateDate(e.startDate)) &&
                                (await getParticipantsEventFunc(e.id))
                              ) {
                                setAlertEmail(true);
                              } else setconfirm_alert(true);
                            }}
                            color="red"
                            fontSize={17}
                          />
                        </td>
                      </tr>
                    ))}
              </tbody>
            </Table>
          </div>
        </CardBody>
      </Card>
      <div
        className="bottom-navigation"
        style={{
          justifyContent: `${schedules?.length > 0 ? "space-between" : "end"}`,
        }}
      >
        {schedules.length > 0 && (
          <Button
            color="danger"
            className="btn-rounded waves-effect waves-light events-button-add"
            onClick={() => setConfirmAllDisabled(true)}
          >
            Limpar Todas Agendas
          </Button>
        )}
        {/* 
        Fornecer os 4 parametros obrigatórios;
        ** data - a array contendo as informações da tabela
        ** search - o state do termo de pesquisa
        ** currentIndex - posição atual do index
        ** setCurrentIndex - função para atualizar a posição atual do index
        */}
        <Pagination
          data={schedules}
          search={search}
          currentIndex={currentIndex}
          setCurrentIndex={setCurrentIndex}
          countData={countData}
          maxItemPerPage={itemsPerPage}
          setMaxItemPerPage={setItemsPerPage}
          maxPageButtonsToShow={3}
        />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return { state };
};

export default connect(mapStateToProps)(Schedule);
