export const excelColumnsPtBr = {
  Código: "code",
  Quantidade: "quantityAvailable",
  TipoDesconto: "valueType",
  "Valor Desconto %": "value",
  Validade: "expireAt",
  "Hora Validade": "validityHour",
  Ingressos: "products",
};

export function allExcelColumns(row, index) {
  const formatedRow = {
    index,
    code: row.code ? row.code : undefined,
    quantityAvailable: row.quantityAvailable
      ? row.quantityAvailable
      : undefined,
    valueType: row.valueType ? row.valueType : undefined,
    value: row.value ? row.value : undefined,
    expireAt: row.expireAt ? row.expireAt : undefined,
    validityHour: row.validityHour ? row.validityHour : "13:00",
    products: row.products ? row.products : undefined,
  };

  return formatedRow;
}

export function findRowsWithError(validRows) {
  const rowsWithError = validRows.filter(
    (row) =>
      row.code === undefined ||
      row.quantityAvailable === undefined ||
      row.value === undefined ||
      row.expireAt === undefined ||
      row.validityHour === undefined
  );

  return rowsWithError;
}
