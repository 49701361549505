import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
// select
import Select from "react-select";
// grafico
import Chart from "react-apexcharts";
// moment
import moment from "moment";

function ColumnChart(props) {
  const { dataByDay = [] } = props; // valor padrão para evitar undefined
  const [allDays, setAllDays] = useState([]);
  const [employee, setEmployee] = useState([]);
  const [dealer, setDealer] = useState([]);
  const [guest, setGuest] = useState([]);

  const opcoes = {
    legend: {
      show: true,
      position: "top",
      fontColor: "#000",
    },
    fill: {
      type: "solid",
      opacity: 1,
    },
    xaxis: {
      categories: allDays,
    },
    chart: {
      toolbar: {
        show: false,
      },
    },
    colors: ["#34C759", "#FFC107", "#8E24AA"],
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "100%",
      },
    },
  };

  const series = [
    {
      name: "Funcionário",
      data: employee,
    },
    {
      name: "Revendedor",
      data: dealer,
    },
    {
      name: "Convidado",
      data: guest,
    },
  ];

  function getDays(max) {
    if (Array.isArray(dataByDay) && dataByDay.length > 0) {
      let listDays = [];

      // Pegar todas as datas do objeto sem repetir
      dataByDay.forEach((day, index) => {
        const dayCurrent = moment(day.data_associacao).format("MM/DD/YYYY");

        if (!listDays.includes(dayCurrent) && listDays.length < max) {
          listDays.push(dayCurrent);
        }
      });

      // Ordenar datas do array por ordem crescente
      listDays.sort((dataA, dataB) => new Date(dataA) - new Date(dataB));

      // Gerar dados dos grupos com base na ordem dos dias
      formatData(listDays, "funcionário", setEmployee);
      formatData(listDays, "revendedor", setDealer);
      formatData(listDays, "convidado", setGuest);

      formatDate(listDays);
    }
  }

  function formatData(listDays, filter, setData) {
    const dataFilter = Array.isArray(dataByDay)
      ? dataByDay.filter((e) => e.grupo === filter)
      : [];

    const dataValueFilter = listDays.map((day) => {
      const search = dataFilter.find(
        (e) => moment(e.data_associacao).format("MM/DD/YYYY") === day
      );

      return search ? search.total_participantes : "0";
    });

    setData(dataValueFilter);
  }

  function formatDate(listDays) {
    const newFormat = listDays.map((e) => moment(new Date(e)).format("DD/MM"));
    setAllDays(newFormat);
  }

  useEffect(() => {
    getDays(7);
  }, [dataByDay]);

  return (
    <div>
      <Row style={{ margin: "20px 0 10px 0" }}>
        <Col style={{ display: "flex", justifyContent: "flex-end" }}>
          <div style={{ maxWidth: 300, display: "flex", alignItems: "center" }}>
            <i
              className="bx bx-filter"
              style={{ marginRight: 5, fontSize: 25, color: "#7c7c7c" }}
            />
            <Select
              styles={{ width: "100%" }}
              placeholder="Selecione a quantidade de dias"
              isMulti={false}
              onChange={(e) => getDays(e.value)}
              options={[
                {
                  options: [
                    { label: "7 dias", value: 7 },
                    { label: "15 dias", value: 15 },
                    { label: "30 dias", value: 30 },
                  ],
                },
              ]}
            />
          </div>
        </Col>
      </Row>
      <Chart options={opcoes} series={series} type="bar" height="400" />
    </div>
  );
}

export default ColumnChart;
