import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Col,
  Row,
  Button,
  Table,
  Collapse,
  Card,
  CardBody,
} from "reactstrap";
//react router dom
import { useLocation } from "react-router-dom";
// select
import Select from "react-select";
//redux
import { connect } from "react-redux";
// alerta
import SweetAlert from "react-bootstrap-sweetalert";
// mascara
import { inputMask } from "../../../utils/helpers/masks/masks";

//service
import { updateParticipant } from "../../../utils/services/sorteios";
import { getGroups } from "../../../utils/services/group";
import { addLoginInNotificationsScheduled } from "../../../utils/services/notification";
import { getQuotasByLogin, updateQuotas } from "../../../utils/services/quotas";
import ButtonSpinner from "../../../components/Buttons/ButtonSpinner";

function EditParticipant(props) {
  const { isOpen, toggle, selectedParticipant, selectedSorteioEdit, refetch } =
    props;
  const location = useLocation();
  const [isLoading, setIsloading] = useState(false);
  const [modalBackdrop, setModalBackdrop] = useState(false);
  const [email] = useState(props?.participant?.email);
  const [nome, setNome] = useState(props?.selectedParticipant?.name);
  // const [password, setPassword] = useState('');
  // const [confirmPassword, setconfirmPassword] = useState('');
  const [titleQrCode, setTitleQrCode] = useState();
  /* props.participant.eventos.filter((e) => e.event.id === selectedSorteioEdit.eventId)[0]
      ?.titleQrCode */
  const [codeQrCode, setCodeQrCode] = useState();
  /*  props.participant.eventos.filter((e) => e.event.id === selectedSorteioEdit.eventId)[0]
      ?.codeQrCode */
  const [infoAcess, setInfoAccess] = useState();
  /* props.participant.eventos.filter((e) => e.event.id === selectedSorteioEdit.eventId)[0]
      ?.infoAcess */
  const [cpf, setCpf] = useState("");
  const [company, setCompany] = useState();
  /*  props.participant.eventos.filter((e) => e.event.id === selectedSorteioEdit.eventId)[0]
      ?.empresa */
  const [office, setOffice] = useState();
  /* props.participant.eventos.filter((e) => e.event.id === selectedSorteioEdit.eventId)[0]
      ?.cargo */
  const [code1, setCode1] = useState(selectedParticipant.internalCode1);
  /* props.participant.eventos.filter((e) => e.event.id === selectedSorteioEdit.eventId)[0]
      ?.codigoInterno1 */
  const [code2, setCode2] = useState(selectedParticipant.internalCode2);
  /* props.participant.eventos.filter((e) => e.event.id === selectedSorteioEdit.eventId)[0]
      ?.codigoInterno2 */
  const [code3, setCode3] = useState(selectedParticipant.internalCode3);
  /* props.participant.eventos.filter((e) => e.event.id === selectedSorteioEdit.eventId)[0]
      ?.codigoInterno3 */
  const [empresa, setEmpresa] = useState(selectedParticipant.company);
  const [cargo, setCargo] = useState(selectedParticipant.position);
  const [chat, setChat] = useState();
  /*  props.participant.eventos.filter((e) => e.event.id === selectedSorteioEdit.eventId)[0]
      ?.interesseChat */
  const [quotasAmount, setQuotasAmount] = useState(
    selectedParticipant.companionsNumber
  );
  const [section, setSection] = useState();
  const [companionsAmount, setCompaniosAmount] = useState();
  const [accessibility, setAccessibility] = useState(
    selectedParticipant.accessibilityRestriction
  );
  const [typeAccessibility, setTypeAccessibility] = useState(
    selectedParticipant.typeRestriction
  );
  const [observation, setObservation] = useState(selectedParticipant.notes);
  const [idQuota, setIdQuota] = useState(null);

  const [showModalNewSession, setShowModalNewSession] = useState(false);
  const [showModalEditSession, setShowModalEditSession] = useState(false);
  const [showModalDeleteSession, setShowModalDeleteSession] = useState(false);

  const [sessionItem, setSessionItem] = useState("");
  const [sessions, setSessions] = useState([]);
  const [sessionsSource, setSessionsSource] = useState([]);
  //exibir cargo e empresa
  const [showCompany, setShowCompany] = useState(false);
  const [showOffice, setShowOffice] = useState(false);
  // const [token, setToken] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [idEvento, setIdEvento] = useState(props?.idEvent);
  //confirmação
  const [sendEmail, setSendEmail] = useState(false);
  //state de grupos
  const [groups, setGroups] = useState([]);
  const [allGroups, setAllGroups] = useState(false);
  //state de grupos selecionados
  const [groupsMulti, setGroupsMulti] = useState([]);
  const [nameError, setNameError] = useState("");
  const [ingressosError, setIngressosError] = useState("");
  const [acessError, setAcessError] = useState("");
  const [typeRestrictionError, setTypeRestrictionError] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [cpfError, setCpfError] = useState("");
  const [sectionError, setSectionError] = useState();
  const [isOpenSession, setIsOpenSession] = useState(true);

  // validações
  const [updateNotification, setUpdateNotification] = useState(false);
  const [propsModals, setPropModals] = useState({});

  const handleChange = (value) => {
    inputMask({ mask: "cpf", value, setMaskedValue: setCpf });
  };

  const formatDate = (date) => {
    return date.split("T")[0].split("-").reverse().join("/");
  };

  useEffect(() => {
    if (props?.participant?.cpf) {
      handleChange(props.participant.cpf);
    }
  }, []);

  function toggleBackdrop() {
    setModalBackdrop(!modalBackdrop);
  }

  const addSession = (data) => setSessions((prev) => [...prev, data]);
  const updateSession = (id, newData) => {
    setSessions((prev) => {
      return prev.map((session) => {
        if (session.sessionId === id) {
          return { ...session, ...newData };
        }
        return session;
      });
    });
  };
  const deleteSession = (sessionIdToDelete) => {
    setSessions((prev) => {
      return prev.filter((session) => session.sessionId !== sessionIdToDelete);
    });
  };

  const toggleSession = () => setIsOpenSession(!isOpenSession);

  function toggleModalNewSession() {
    setShowModalNewSession(!showModalNewSession);
  }

  function toggleModalEditSession() {
    setShowModalEditSession(!showModalEditSession);
  }

  function toggleModalDeleteSession() {
    setShowModalDeleteSession(!showModalDeleteSession);
  }

  function setPropsModals() {
    setPropModals({
      showModalNewSession,
      showModalEditSession,
      showModalDeleteSession,
      toggleModalNewSession,
      toggleModalEditSession,
      toggleModalDeleteSession,
      sessionItem,
      sessionsSource,
      addSession,
      updateSession,
      deleteSession,
      sessions,
    });
  }

  function handleGroupMulti(a) {
    setGroupsMulti(a);
    if (a[0]?.value === "todos") {
      setAllGroups(true);
    } else {
      setAllGroups(false);
    }
  }

  async function submitForm(email) {
    if (!nome) {
      setNameError("O campo Nome é obrigatório.");
      return;
    } else setNameError("");

    if (!quotasAmount) {
      setIngressosError("O campo Quantidade Total de Ingressos é obrigatório.");
      return;
    } else setIngressosError("");

    if (quotasAmount < 1) {
      setIngressosError(
        "O campo Quantidade Total precisa de um valor maior que zero."
      );
      return;
    } else setIngressosError("");

    if (accessibility == null) {
      setAcessError("O campo restrição de acessibilidade é obrigatório.");
      return;
    } else setAcessError("");

    if (!typeAccessibility && accessibility) {
      setTypeRestrictionError("O campo tipo de restrição é obrigatório.");
      return;
    } else setTypeRestrictionError("");

    var groupsArray = [];

    if (allGroups) {
      groups.map((e) => groupsArray.push(e.id));
    } else {
      groupsMulti.map((e) => groupsArray.push(e.value));
    }

    const payload = {
      sorteioId: selectedSorteioEdit.id,
      eventId: selectedSorteioEdit.eventId,
      sessionId: selectedSorteioEdit.session.id,
      email: selectedParticipant.email,
      name: nome,
      companionsNumber: JSON.parse(quotasAmount),
      accessibilityRestriction: JSON.parse(accessibility),
      notes: observation,
      typeRestriction: JSON.parse(accessibility) ? typeAccessibility : "",
      company: empresa,
      position: cargo,
      internalCode1: code1,
      internalCode2: code2,
      internalCode3: code3,
      groups: groupsArray.toString(),
      isSendEmail: email,
    };
    setIsloading(true);
    await updateParticipant(
      payload,
      selectedParticipant.id,
      props.state.global_user_data.data.token
    ).then((res) => {
      setIsloading(false);
      toggle();
      refetch();
    });
    // .catch((error) => console.log("erro", error));
  }

  async function getGroupsFunc() {
    await getGroups({
      jwt: props.state.global_user_data.data.token,
      eventId: props?.selectedSorteioEdit.eventId,
      offset: 1,
      limit: 0,
      search: "",
    })
      .then((response) => {
        setGroups(response.findOneGroupData.data);
      })
      .catch((error) => {
        // console.log(error);
      });
  }

  //verificar cpf válidos
  function cpfValidator(cpfFormat) {
    cpfFormat = cpfFormat.replace(/[^\d]+/g, "");
    if (cpfFormat == "") return false;
    // Elimina CPFs invalidos conhecidos
    if (
      cpfFormat.length != 11 ||
      cpfFormat == "00000000000" ||
      cpfFormat == "11111111111" ||
      cpfFormat == "22222222222" ||
      cpfFormat == "33333333333" ||
      cpfFormat == "44444444444" ||
      cpfFormat == "55555555555" ||
      cpfFormat == "66666666666" ||
      cpfFormat == "77777777777" ||
      cpfFormat == "88888888888" ||
      cpfFormat == "99999999999"
    )
      return false;
    // Valida 1o digito
    var add = 0;
    for (var i = 0; i < 9; i++) add += parseInt(cpfFormat.charAt(i)) * (10 - i);
    var rev = 11 - (add % 11);
    if (rev == 10 || rev == 11) rev = 0;
    if (rev != parseInt(cpfFormat.charAt(9))) return false;
    // Valida 2o digito
    add = 0;
    for (i = 0; i < 10; i++) add += parseInt(cpfFormat.charAt(i)) * (11 - i);
    rev = 11 - (add % 11);
    if (rev == 10 || rev == 11) rev = 0;
    if (rev != parseInt(cpfFormat.charAt(10))) return false;
    return true;
  }

  function checkProductExists(product) {
    if (location.state) {
      const search = location.state.event.product.filter(
        (e) => e.name === product
      );

      if (search[0]) {
        return true;
      } else return false;
    }
  }

  async function getQuotasFunc() {
    await getQuotasByLogin({
      jwt: props.state.global_user_data.data.token,
      idEvent: selectedSorteioEdit.eventId,
      idLogin: props.participant.id,
    }).then((res) => {
      if (res.message === "success") {
        const data = res.loginEventosCotas.data;
        const formattedData = data.map((e) => ({
          id: e.id,
          accessibilityRestriction: e.accessibilityrestriction,
          companionsNumber: e.companionsnumber,
          notes: e.notes,
          sessionId: e.session,
          title: `${e.title} - ${formatDate(e.startdate)} ${e.starthour}`,
          typeRestriction: e.typerestriction,
        }));
        setSessions(formattedData);
        setSessionsSource(formattedData);
      }
    });
  }

  //verificar se o evento exibe empresa e cargo
  useEffect(() => {
    setShowCompany(
      props.state.global_user_data.data.foundEvents.filter(
        (e) => e.id === selectedSorteioEdit.eventId
      )[0]?.showCompany
    );

    setShowOffice(
      props.state.global_user_data.data.foundEvents.filter(
        (e) => e.id === selectedSorteioEdit.eventId
      )[0]?.showJobPosition
    );
  }, [props.state.global_user_data.data.foundEvents]);

  useEffect(() => {
    if (cpf.replace(/[^\d]+/g, "").length === 11 && !cpfValidator(cpf)) {
      setCpfError("CPF inválido");
    } else setCpfError("");
  }, [cpf]);

  useEffect(() => {
    getGroupsFunc();
    if (checkProductExists("Distribuição de Cotas")) {
      //getQuotasFunc();
      //listSessions()
    }
  }, []);

  useEffect(() => {
    funcGroups();
  }, [groups]);

  useEffect(() => {
    setPropsModals();
  }, [showModalNewSession, showModalEditSession, showModalDeleteSession]);

  function funcGroups() {
    var groupsArray = [];
    if (props.selectedParticipant.groups) {
      // eslint-disable-next-line array-callback-return
      props.selectedParticipant.groups.split(",").map((a) => {
        groupsArray.push({
          label: groups.find((e) => e.id == a)?.name,
          value: groups.find((e) => e.id == a)?.id,
        });
      });
      if (groups?.length === groupsArray.length) {
        groupsArray = [];
        groupsArray.push({ label: "TODOS", value: "todos" });
        setAllGroups(true);
      }
    }
    setGroupsMulti(groupsArray);
  }

  return (
    <>
      {sendEmail && !isLoading && (
        <SweetAlert
          warning
          title={"Deseja reenviar o email com os ingressos?"}
          showCancel
          confirmBtnText="Sim"
          cancelBtnText="Não"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            submitForm(true);
          }}
          onCancel={() => {
            submitForm(false);
          }}
        >
          Você não poderá reverter essa ação!
        </SweetAlert>
      )}

      <Modal
        isOpen={isOpen}
        toggle={() => {
          toggleBackdrop();
        }}
        autoFocus={true}
        centered={true}
      >
        <ModalHeader toggle={toggle}>Editar Participante</ModalHeader>
        <ModalBody>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              if (checkProductExists("Distribuição de Cotas")) {
                setSendEmail(true);
              } else submitForm(false);
            }}
          >
            <Row style={{ marginBottom: 10 }}>
              <Col>
                <div>
                  <label className="form-label"> ID do Participante</label>
                  <input
                    className="form-control"
                    type="text"
                    value={props?.selectedParticipant?.id}
                    disabled
                  />
                </div>
              </Col>
            </Row>

            <Row style={{ marginBottom: 10 }}>
              <Col lg={12}>
                <div>
                  <label className="form-label">Nome do Participante*</label>
                  <input
                    className="form-control"
                    type="text"
                    defaultValue={nome}
                    onChange={(e) => setNome(e.target.value)}
                  />
                  {nameError && <a style={{ color: "red" }}>{nameError}</a>}
                </div>
              </Col>
            </Row>

            <Row style={{ marginBottom: 10 }}>
              <Col lg={12}>
                <div>
                  <label className="form-label">Email do Participante*</label>
                  <input
                    className="form-control"
                    type="text"
                    defaultValue={selectedParticipant.email}
                    disabled
                  />
                </div>
              </Col>
            </Row>

            <Row style={{ marginBottom: 10 }}>
              <Col md={12}>
                <label className="form-label">Sessão*</label>
                <input
                  className="form-control"
                  min={1}
                  value={selectedSorteioEdit.session.title}
                  disabled
                />
              </Col>
            </Row>

            <Row style={{ marginBottom: 10 }}>
              <Col md={12}>
                <label className="form-label">
                  Quantidade Total de Ingressos*
                </label>
                <input
                  className="form-control"
                  value={quotasAmount}
                  onChange={(e) => setQuotasAmount(e.target.value)}
                />
                {ingressosError && (
                  <a style={{ color: "red" }}>{ingressosError}</a>
                )}
              </Col>
            </Row>

            <Row style={{ marginBottom: 10 }}>
              <Col md={12}>
                <label className="form-label">
                  Você ou seu(s) acompanhante(s) são PCD?*
                </label>
                <Select
                  placeholder={"Selecione"}
                  onChange={(e) => {
                    setAccessibility(e.value);
                  }}
                  options={[
                    {
                      options: [
                        { label: "Sim", value: true },
                        { label: "Não", value: false },
                      ],
                    },
                  ]}
                  value={{
                    label: accessibility ? "Sim" : "Não",
                    value: accessibility,
                  }}
                  classNamePrefix="select2-selection"
                />
                {acessError && <a style={{ color: "red" }}>{acessError}</a>}
              </Col>
            </Row>

            {accessibility && (
              <Row style={{ marginBottom: 10 }}>
                <Col md={12}>
                  <label className="form-label">
                    Para assegurar o acesso de Pessoas com Deficiência em mais
                    espaços, indique quais cuidados são necessários para que
                    você ou seu(s) acompanhante(s) possam aproveitar essa
                    atividade:*
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    value={typeAccessibility}
                    onChange={(e) => setTypeAccessibility(e.target.value)}
                  />
                  {typeRestrictionError && (
                    <a style={{ color: "red" }}>{typeRestrictionError}</a>
                  )}
                </Col>
              </Row>
            )}

            <Row style={{ marginBottom: 10 }}>
              <Col md={12}>
                <label className="form-label">Observações</label>
                <textarea
                  className="form-control"
                  type="text"
                  onChange={(e) => setObservation(e.target.value)}
                  value={observation}
                />
              </Col>
            </Row>

            {(showCompany || showOffice) && (
              <Row style={{ marginBottom: 10 }}>
                {showCompany && (
                  <Col md={showOffice ? 6 : 12}>
                    <label className="form-label">Empresa</label>
                    <input
                      className="form-control"
                      type="text"
                      defaultValue={empresa}
                      onChange={(e) => setEmpresa(e.target.value)}
                    />
                  </Col>
                )}

                {showOffice && (
                  <Col md={showCompany ? 6 : 12}>
                    <label className="form-label">Cargo</label>
                    <input
                      className="form-control"
                      type="text"
                      defaultValue={cargo}
                      onChange={(e) => setCargo(e.target.value)}
                    />
                  </Col>
                )}
              </Row>
            )}

            <Row style={{ marginBottom: 10 }}>
              <Col md={12}>
                <label className="form-label">Código interno 1</label>
                <input
                  className="form-control"
                  type="text"
                  defaultValue={code1}
                  onChange={(e) => setCode1(e.target.value)}
                />
              </Col>
            </Row>

            <Row style={{ marginBottom: 10 }}>
              <Col md={6}>
                <label className="form-label">Código interno 2</label>
                <input
                  className="form-control"
                  type="text"
                  defaultValue={code2}
                  onChange={(e) => setCode2(e.target.value)}
                />
              </Col>

              <Col md={6}>
                <label className="form-label">Código interno 3</label>
                <input
                  className="form-control"
                  type="text"
                  defaultValue={code3}
                  onChange={(e) => setCode3(e.target.value)}
                />
              </Col>
            </Row>

            <Row style={{ marginBottom: 10 }}>
              <Col lg={12}>
                <div>
                  <label className="form-label">Grupos</label>
                  <Select
                    value={groupsMulti}
                    placeholder={"Selecione"}
                    isMulti={true}
                    onChange={(e) => {
                      handleGroupMulti(e);
                    }}
                    options={[
                      {
                        options: [
                          { label: "TODOS", value: "todos" },
                          ...groups.map((e) => {
                            return { label: e.name, value: e.id };
                          }),
                        ],
                      },
                    ]}
                    classNamePrefix="select2-selection"
                  />
                </div>
              </Col>
            </Row>

            <Row
              style={{
                width: "100%",
                margin: "auto",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                color="danger"
                style={{ width: 80, marginRight: 10 }}
                onClick={() => toggle()}
              >
                Fechar
              </Button>

              <ButtonSpinner isLoading={isLoading} title={"Salvar"} />
            </Row>
          </form>
        </ModalBody>
      </Modal>
    </>
  );
}

const mapStateToProps = function (state) {
  return { state: state };
};

export default connect(mapStateToProps)(EditParticipant);
