import React, { useCallback, useEffect, useState } from "react";
import { MetaTags } from "react-meta-tags";
import { useSelector } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Collapse,
  Row,
  Table,
} from "reactstrap";

import SweetAlert from "react-bootstrap-sweetalert";

import Pagination from "../../components/Paginationv3";
import useDebounce from "../../utils/hooks/useDebounce";
import SectionComponent from "../Apps/Personalization/SectionComponent";
import EditDict from "./ModalDictEdit";
import NewDict from "./ModalNewDict";
import { deleteDict, getAllDict } from "../../utils/services/dictionary";

function Dictionary() {
  const { token: jwt } = useSelector((state) => state.global_user_data.data);

  const [dictionaries, setDictionaries] = useState([]);
  const [allDescription, setAllDescription] = useState([]);

  //paginação
  const [currentIndex, setCurrentIndex] = useState(1);
  const [countData, setCountData] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  //search
  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounce(search, 300);

  // New Dictionary Modal
  const [newDict, setNewDict] = useState(false);

  // Edit Dictionary Modal
  const [editDict, setEditDict] = useState(false);

  // Selected Dictionary
  const [selectedDict, setSelectedDict] = useState("");

  // Delete Dictionary Modal
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);

  const [successDelete, setSuccessDelete] = useState(false);

  // collapse
  const [isCollapse, setIsCollapse] = useState(false);

  const getAllDescription = useCallback(
    async (totalData) => {
      const numberOfRequests = Math.ceil(totalData / itemsPerPage);
      const descriptions = [];

      for (let i = 1; i <= numberOfRequests; i += 1) {
        const response = await getAllDict({ jwt, offset: i, limit: 10 });
        if (response.data.dictionaries) {
          descriptions.push(
            ...response.data.dictionaries.map((dict) => dict.description)
          );
        }
      }
      setAllDescription(descriptions);
      // setAllDescription(response.data.map((item) => item.description));
    },
    [jwt, itemsPerPage]
  );

  const getDictionaries = useCallback(async () => {
    const response = await getAllDict({
      jwt,
      offset: currentIndex,
      limit: itemsPerPage,
      search: debouncedSearch ? debouncedSearch : "",
    });
    getAllDescription(response.data.total);
    setCountData(response.data.total);
    setDictionaries(response.data.dictionaries);
  }, [jwt, currentIndex, itemsPerPage, debouncedSearch, getAllDescription]);

  const deleteDictionary = async () => {
    await deleteDict({ jwt, id: selectedDict.id });

    setSuccessDelete(true);
    await getDictionaries();
  };

  useEffect(() => {
    getDictionaries();
  }, [getDictionaries]);

  return (
    <div>
      {isDeleteOpen && (
        <SweetAlert
          warning
          showCancel
          confirmBtnText="Sim"
          cancelBtnText="Não"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          title={`Você tem certeza que deseja excluir o Dicionário ${selectedDict.description}?`}
          onConfirm={() => {
            deleteDictionary();
            setIsDeleteOpen(false);
            setSuccessDelete(true);
          }}
          onCancel={() => {
            setIsDeleteOpen(false);
          }}
        >
          Você não poderá reverter essa ação.
        </SweetAlert>
      )}
      {successDelete && (
        <SweetAlert
          success
          title="Deletado"
          onConfirm={() => {
            setSuccessDelete(false);
          }}
        >
          {`O Dicionário ${selectedDict.description} foi deletado com sucesso`}
        </SweetAlert>
      )}
      <EditDict
        isOpen={editDict}
        toggle={() => setEditDict(!editDict)}
        selectedDict={selectedDict}
        refetch={getDictionaries}
        descriptions={allDescription}
      />
      <NewDict
        isOpen={newDict}
        toggle={() => setNewDict(!newDict)}
        refetch={getDictionaries}
        descriptions={allDescription}
      />
      <MetaTags>
        <title>Dicionário | Inteegra Plus</title>
      </MetaTags>
      <Card>
        <CardBody>
          <CardTitle>Dicionário</CardTitle>

          {/*<div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <form
              className="app-search d-none d-lg-block events-input-search"
              onSubmit={(e) => e.preventDefault()}
            >
              <div className="position-relative">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Pesquisar..."
                  onChange={(e) => setSearch(e.target.value)}
                />
                <span className="bx bx-search-alt" />
              </div>
            </form>
             <div className="mobile-btn-section" style={{ width: '100%' }}>
              <SectionComponent
                sectionName="Opções"
                setIsOpen={setIsCollapse}
                isOpen={isCollapse}
              />
              <Collapse isOpen={isCollapse}>
                <Row className="mobile-btns-top">
                  <div
                    onClick={() => setNewDict(true)}
                  >
                    Adicionar Dicionário
                  </div>
                </Row>
              </Collapse>
            </div> 
            <div className="header-btns-container">
              <Button
                color="success"
                className="btn-rounded waves-effect waves-light events-button-add"
                onClick={() => setNewDict(true)}
              >
                Adicionar Dicionário
              </Button>
            </div> 
          </div>*/}
          <div className="table-responsive">
            <Table className="table mb-0">
              <thead className="table-light">
                <tr>
                  <th>Idioma</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                {dictionaries.length > 0 &&
                  dictionaries.map((dict, i) => (
                    <tr key={i}>
                      <td>{dict.description}</td>
                      <td>
                        <i
                          className="mdi mdi-pencil"
                          title="Editar"
                          style={{
                            margin: "0 3px",
                            color: "green",
                            fontSize: 17,
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            setSelectedDict(dict) > setEditDict(true)
                          }
                        />
                        {/* <i
                          className="mdi mdi-trash-can"
                          title="Excluir"
                          style={{
                            color: "red",
                            fontSize: 17,
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            setSelectedDict(dict) > setIsDeleteOpen(true)
                          }
                        /> */}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
        </CardBody>
      </Card>
      <div className="bottom-navigation" style={{ justifyContent: "end" }}>
        {/* 
        Fornecer os 4 parametros obrigatórios;
        ** data - a array contendo as informações da tabela
        ** search - o state do termo de pesquisa
        ** currentIndex - posição atual do index
        ** setCurrentIndex - função para atualizar a posição atual do index
        */}
        <Pagination
          data={dictionaries}
          // search={search}
          currentIndex={currentIndex}
          setCurrentIndex={setCurrentIndex}
          countData={countData}
          maxItemPerPage={itemsPerPage}
          setMaxItemPerPage={setItemsPerPage}
          maxPageButtonsToShow={3}
        />
      </div>
    </div>
  );
}

export default Dictionary;
