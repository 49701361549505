import React, { useState, useEffect } from "react";
import { Modal, ModalBody, ModalHeader, Row, Col } from "reactstrap";
// moment
import moment from "moment";
// service
import { getSalesDetails } from "../../../utils/services/sales";

export default function ModalDetails(props) {
  const { isOpen, toggle, saleId, token } = props;
  // dados
  const [data, setData] = useState([]);

  function getTypePayment(type) {
    switch (type) {
      case "pix":
        return "Pix";

      case "credit_card":
        return "Cartão de Crédito";

      case "free":
        return "Gratuito";

      default:
        return "";
    }
  }

  function getStatusPayment(status) {
    switch (status) {
      case "pending":
        return "Pendente";

      case "paid":
        return "Pago";

      case "canceled":
        return "Cancelado";

      case "refunded":
        return "Reembolsado";

      default:
        return "";
    }
  }

  function getInfo() {
    getSalesDetails({ jwt: token, idSale: saleId }).then((res) => {
      if (res.transactions) {
        var searchObjt = res.transactions.find(
          (e) => e.transaction_id === saleId
        );

        if (searchObjt) {
          var allFields = [];

          Object.entries(searchObjt).forEach(([key, value]) => {
            allFields.push({
              key,
              value,
            });
          });

          setData(allFields);
        }
      }
    });
    // .catch((error) => console.log("erro", error));
  }

  useEffect(() => {
    getInfo();
  }, []);

  return (
    <>
      <Modal isOpen={isOpen} centered>
        <ModalHeader toggle={toggle}>Detalhes da venda</ModalHeader>
        <ModalBody>
          {data.length > 0 &&
            data.map((e, i) => (
              <Row>
                <Col lg={12}>
                  <div className="line-underline">
                    <a>{e.key}</a>
                    <a style={{ textAlign: "end" }}>{e.value}</a>
                  </div>
                </Col>
              </Row>
            ))}
        </ModalBody>
      </Modal>
    </>
  );
}
