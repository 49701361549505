import { addDays, format } from "date-fns";
import { excelDateToJSDate } from "../../../utils/helpers/files/exceldatetojs";

export const excelColumnsPtBr = {
  Nome: "description",
  Origem: "origin",
  Destino: "destination",
  "Data Saída": "departureDatetime",
  "Hora Saída": "departureTime",
  "Data Chegada": "arrivalDatetime",
  "Hora Chegada": "arrivalTime",
  "Nome Do Motorista": "driverName",
  "Tipo De Veículo": "vehicleType",
  Capacidade: "seatingCapacity",
  Empresa: "company",
  "Ponto De Encontro": "meetingPoint",
  Telefone: "phone",
  Observação: "notes",
};

function formatDate(date) {
  try {
    return format(addDays(new Date(date), 1), "dd-MM-yyyy");
  } catch (error) {
    return undefined;
  }
}

function formatTime(time) {
  try {
    return format(excelDateToJSDate(time), "HH:mm");
  } catch (error) {
    return undefined;
  }
}

export function allExcelColumns(row, index) {
  const formatedRow = {
    index,
    description: row.description,
    origin: row.origin,
    destination: row.destination,
    departureDatetime: formatDate(row.departureDatetime),
    departureTime: formatTime(row.departureTime),
    arrivalDatetime: row.arrivalDatetime ? formatDate(row.arrivalDatetime) : "",
    arrivalTime: row.arrivalTime ? formatTime(row.arrivalTime) : "",
    driverName: row.driverName ? row.driverName : "",
    vehicleType: row.vehicleType ? row.vehicleType : "",
    seatingCapacity: row.seatingCapacity,
    company: row.company ? row.company : "",
    meetingPoint: row.meetingPoint ? row.meetingPoint : "",
    phone: row.phone ? row.phone : "",
    notes: row.notes ? row.notes : "",
  };

  return formatedRow;
}
