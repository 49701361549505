import React from "react";
import MetaTags from "react-meta-tags";
import { Outlet, useLocation } from "react-router-dom";
import { Card, CardBody, CardTitle } from "reactstrap";

//redux
import { connect } from "react-redux";

function AboutEvent(props) {
  const location = useLocation();

  return (
    <div>
      <MetaTags>
        <title>Sobre Evento | Inteegra Plus</title>
      </MetaTags>
      <Card>
        <CardBody>
          <CardTitle>Sobre o evento {location.state.event.title}</CardTitle>
          <Outlet />
        </CardBody>
      </Card>
    </div>
  );
}

const mapStateToProps = (state) => {
  const { events } = state;
  return { events, state: state };
};

const mapDispatchToProps = function (dispatch) {
  return {
    openEvent: (data) => {
      return dispatch({ type: "OPEN_EVENT_MENU", data });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AboutEvent);
