import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader, Row, Col, Button } from "reactstrap";
//service
import { updateParticipant } from "../../../utils/services/participants";
//redux
import { connect } from "react-redux";
//alert
import SweetAlert from "react-bootstrap-sweetalert";
import ButtonSpinner from "../../../components/Buttons/ButtonSpinner";

function ModalEditPassword(props) {
  const { isOpen, toggle } = props;
  const [modalBackdrop, setModalBackdrop] = useState(false);
  //state de error
  const [passwordError, setPasswordError] = useState("");
  const [passwordConfirmError, setPasswordConfirmError] = useState("");
  //confirmação
  const [success_dlg, setsuccess_dlg] = useState(false);
  // exibir/ocultar senha
  const [viewPass, setViewPass] = useState(false);
  const [viewPassConfirm, setViewPassConfirm] = useState(false);
  const [isLoading, setIsloading] = useState(false);

  function toggleBackdrop() {
    setModalBackdrop(!modalBackdrop);
  }

  async function submitForm(e) {
    if (!e.target[0].value) {
      setPasswordError("Insira uma senha");
      return;
    } else setPasswordError("");

    if (e.target[0].value.length < 8 || e.target[0].value.length > 20) {
      setPasswordError("A senha deve conter entre 8 e 20 caracteres");
      return;
    } else setPasswordError("");

    if (!e.target[1].value) {
      setPasswordConfirmError("Insira a confirmação da senha");
      return;
    } else setPasswordConfirmError("");

    if (e.target[0].value != e.target[1].value) {
      setPasswordConfirmError("As senhas são diferentes");
      return;
    } else setPasswordConfirmError("");

    const form = {
      email: props.editParticipante.email,
      nome: props.editParticipante.nome,
      senha: e.target[0].value,
      titleQrCode: props.editParticipante.eventos[0]
        ? props.editParticipante.eventos[0].titleQrCode
        : "",
      codeQrCode: props.editParticipante.eventos[0]
        ? props.editParticipante.eventos[0].codeQrCode
        : "",
      infoAcess: props.editParticipante.eventos[0]
        ? props.editParticipante.eventos[0].infoAcess
        : "",
      idEvento: props.idEvent,
      grupos: props.editParticipante.grupos,
    };

    var data = new FormData();
    data.append("text", JSON.stringify(form));
    setIsloading(true);
    await updateParticipant(data, props.state.global_user_data.data.token)
      .then((res) => {
        // console.log("ok", res);
        setIsloading(false);
        if (res.message === "updated") {
          setsuccess_dlg(true);
        }
      })
      .catch((error) => {
        setIsloading(false);
        // console.log("error", error);
      });
  }

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => toggleBackdrop()}
      autoFocus={true}
      centered={true}
    >
      {success_dlg ? (
        <SweetAlert
          success
          title={"Editado"}
          onConfirm={() => {
            props.getAllParticipants();
            toggle();
            setsuccess_dlg(false);
          }}
        >
          <>
            <p>Senha editada com sucesso!</p>
          </>
        </SweetAlert>
      ) : null}
      <ModalHeader toggle={toggle}>Alterar Senha do Participante</ModalHeader>
      <ModalBody>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            submitForm(e);
          }}
        >
          <Row>
            <Col lg={12}>
              <div>
                <label className="form-label">Senha*</label>
                <div
                  className="mb-3"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <input
                    className="form-control"
                    type={!viewPass ? "password" : "text"}
                  />
                  <a onClick={() => setViewPass(!viewPass)}>
                    {viewPass ? (
                      <i className="mdi mdi-eye-off-outline icon-password" />
                    ) : (
                      <i className="mdi mdi-eye-outline icon-password" />
                    )}
                  </a>
                </div>
                <p style={{ color: "red" }}>{passwordError}</p>
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg={12}>
              <div>
                <label className="form-label">Confirmar Senha*</label>
                <div
                  className="mb-3"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <input
                    className="form-control"
                    type={!viewPassConfirm ? "password" : "text"}
                  />
                  <a onClick={() => setViewPassConfirm(!viewPassConfirm)}>
                    {viewPassConfirm ? (
                      <i className="mdi mdi-eye-off-outline icon-password" />
                    ) : (
                      <i className="mdi mdi-eye-outline icon-password" />
                    )}
                  </a>
                </div>
                <p style={{ color: "red" }}>{passwordConfirmError}</p>
              </div>
            </Col>
          </Row>

          <Row
            style={{
              width: "100%",
              margin: "auto",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              color="danger"
              style={{ width: 80, marginRight: 10 }}
              onClick={() => toggle()}
            >
              Fechar
            </Button>
            <ButtonSpinner isLoading={isLoading} title={"Salvar"} />
          </Row>
        </form>
      </ModalBody>
    </Modal>
  );
}

const mapStateToProps = function (state) {
  return { state: state };
};

export default connect(mapStateToProps)(ModalEditPassword);
