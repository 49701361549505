import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import firebase from "firebase/app";
import "firebase/firestore";

// Configurações do Firebase (substitua pelas suas configurações)
const firebaseConfig = {
  apiKey: "AIzaSyBIvM2MsMGBGmPTgBRykwCizYuT_XwBwhQ",
  authDomain: "inteegra-eventos-app.firebaseapp.com",
  projectId: "inteegra-eventos-app",
  storageBucket: "inteegra-eventos-app.appspot.com",
  messagingSenderId: "22283585667",
  appId: "1:22283585667:web:1769722142ba30944f33b6",
  measurementId: "G-3RHYHE7YWT",
};

function verifyId(id) {
  switch (id) {
    case "inteegra-eventos-app":
      return {
        apiKey: "AIzaSyBIvM2MsMGBGmPTgBRykwCizYuT_XwBwhQ",
        authDomain: "inteegra-eventos-app.firebaseapp.com",
        projectId: "inteegra-eventos-app",
        storageBucket: "inteegra-eventos-app.appspot.com",
        messagingSenderId: "22283585667",
        appId: "1:22283585667:web:1769722142ba30944f33b6",
        measurementId: "G-3RHYHE7YWT",
      };

    default:
      return {
        apiKey: "AIzaSyBoBM6G1xv6T7sL5SnETr4SAdhz7Ku3SUQ",
        authDomain: "inteegra-eventos-app-2.firebaseapp.com",
        projectId: "inteegra-eventos-app-2",
        storageBucket: "inteegra-eventos-app-2.appspot.com",
        messagingSenderId: "944275595989",
        appId: "1:944275595989:web:cd232b1aee60dee79df85c",
        measurementId: "G-B21BRG2FP8",
      };
  }
}

// const firebaseConfig = {
//   apiKey: "AIzaSyBoBM6G1xv6T7sL5SnETr4SAdhz7Ku3SUQ",
//   authDomain: "inteegra-eventos-app-2.firebaseapp.com",
//   projectId: "inteegra-eventos-app-2",
//   storageBucket: "inteegra-eventos-app-2.appspot.com",
//   messagingSenderId: "944275595989",
//   appId: "1:944275595989:web:cd232b1aee60dee79df85c",
//   measurementId: "G-B21BRG2FP8"
// };

// Inicialize o Firebase
// firebase.initializeApp(firebaseConfig);
// const firestore = firebase.firestore();
let firestore = {};

// Crie o contexto
const FirestoreContext = createContext();

export const useFirestore = () => {
  return useContext(FirestoreContext);
};

export const FirestoreProvider = ({
  children,
  idEvent,
  idDashboard,
  idChat,
  idFireStore,
}) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    if (idFireStore && idFireStore.length > 0) {
      firebase.initializeApp(verifyId(idFireStore));
      firestore = firebase.firestore();
    } else if (firestore.collection) {
      firebase.app().delete();
    } else {
      return;
    }
  }, [idFireStore]);

  // ação de fixar a mensagem
  function fixeChat({ msg, enabled, avatar, name, eventIdChat, chatId }) {
    return firestore
      .collection(`super-tela/chat/${eventIdChat}/${chatId}/fixed`)
      .doc("_")
      .set(
        {
          date: new Date(),
          msg: msg,
          enabled: enabled,
          avatar: avatar,
          name: name,
        },
        { merge: true }
      );
  }

  // Carregue dados do Firestore e atualize o estado
  useEffect(() => {
    if (idChat && firestore?.collection) {
      const chat = firestore
        .collection(`/super-tela/chat/${idEvent}/${idChat}/msgs`)
        .where("active", "==", true);

      const chatUpdate = chat.onSnapshot((snapshot) => {
        const data = snapshot.docs.map((doc) => doc.data());
        setData((state) => {
          return {
            ...state,
            chat: data.length,
          };
        });
      });

      return () => chatUpdate();
    }
  }, [firestore]);

  useEffect(() => {
    if (idChat && firestore?.collection) {
      const chatFixed = firestore
        .collection(`/super-tela/chat/${idEvent}/${idChat}/fixed`)
        .where("enabled", "==", true);

      const chatFixeUpdate = chatFixed.onSnapshot((snapshot) => {
        const data = snapshot.docs.map((doc) => doc.data());
        setData((state) => {
          return {
            ...state,
            chatFixed: data,
          };
        });
      });

      return () => chatFixeUpdate();
    }
  }, [firestore]);

  useEffect(() => {
    if (idChat && firestore?.collection) {
      const ask = firestore
        .collection(`/super-tela/ask/${idEvent}/${idDashboard}/list`)
        .where("active", "==", true);

      const askUpdate = ask.onSnapshot((snapshot) => {
        const data = snapshot.docs.map((doc) => doc.data());
        setData((state) => {
          return {
            ...state,
            ask: data.length,
          };
        });
      });

      return () => askUpdate();
    }
  }, [firestore]);

  useEffect(() => {
    if (firestore?.collection) {
      const access = firestore.collection(
        `/super-tela/statistics/${idEvent}/${idDashboard}/states/count/_`
      );

      const accessUpdate = access.onSnapshot((snapshot) => {
        const data = snapshot.docs.map((doc) => doc.data());
        setData((state) => {
          return {
            ...state,
            access: data,
          };
        });
      });

      return () => accessUpdate();
    }
  }, [firestore]);

  async function getOnline() {
    if (firestore?.collection) {
      const currentDate = new Date();
      const fiveMinutesAgo = new Date(currentDate.getTime() - 5 * 60 * 1000);
      const online = await firestore
        .collection(
          `/super-tela/statistics/${idEvent}/${idDashboard}/states/log/users`
        )
        .where("date", ">=", fiveMinutesAgo)
        .get();

      const data = online.docs.map((doc) => doc.data());
      setData((state) => {
        return {
          ...state,
          online: data.length,
        };
      });
    }
  }

  useEffect(() => {
    getOnline();
    const interval = setInterval(() => {
      getOnline();
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (idChat && firestore?.collection) {
      const superEmoji = firestore.collection(
        `/super-tela/super-emoji/${idEvent}/${idDashboard}/count`
      );

      const superEmojiUpdate = superEmoji.onSnapshot((snapshot) => {
        const data = snapshot.docs.map((doc) => {
          return { ...doc.data(), id: doc.id };
        });
        setData((state) => {
          return {
            ...state,
            superEmoji: data,
          };
        });
      });

      return () => superEmojiUpdate();
    }
  }, [firestore]);

  return (
    <FirestoreContext.Provider
      value={{
        data: data,
        fixeChat: fixeChat,
      }}
      fixeChat={fixeChat}
    >
      {children}
    </FirestoreContext.Provider>
  );
};
