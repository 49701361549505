import React, { useRef, useState, useEffect } from 'react';
import { Editor } from '@tinymce/tinymce-react';

export default function TextEditor(props) {
  const { defaultState, refState } = props;

  const onChange = (editor) => {
    refState.current = editor;
  };

  return (
    <>
      <Editor
        apiKey='xzvyhdr3g51rxde6ka5g9pafsyhtsh7lmev9vq6fjdigaymv'
        onInit={(evt, editor) => onChange(editor)}
        initialValue={defaultState}
        init={{
          height: 500,
          menubar: false,
          plugins: [
            'advlist',
            'autolink',
            'lists',
            'link',
            'image',
            'charmap',
            'preview',
            'anchor',
            'searchreplace',
            'visualblocks',
            'code',
            'fullscreen',
            'insertdatetime',
            'media',
            'table',
            'code',
            'help',
            'wordcount',
            'code',
          ],
          toolbar:
            'undo redo | blocks | ' +
            'bold italic forecolor | alignleft aligncenter ' +
            'alignright alignjustify | bullist numlist outdent indent | ' +
            'removeformat | code',
          content_style:
            'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
        }}
      />
    </>
  );
}
