import React from 'react';
import ReactEcharts from 'echarts-for-react';

export default function PieChart({
  data = {},
  title,
}) {
  const accesses = data.totalwithaccess > 0 || data.totalwithoutaccess > 0
  ? [
    {x: "Adesão", y: data.totalwithaccess},
    {x: "No Show", y: data.totalwithoutaccess}
  ]
  : [];

  const getOptions = () => ({
    title: {
      text: title,
      x: 'left',
      textStyle: {
        color: 'black'
      },
    },
    tooltip: {
      trigger: 'item',
      formatter: '{b}: {c} ({d}%)'
    },
    legend: {
      orient: 'vertical',
      left: '1.5%',
      top: '10%',
      padding: [10, 0, 0, 0],
      itemGap: 5,

      data: accesses.map(item => item.x),
      formatter: function (name) {
        const item = accesses.find(item => item.x === name);
        return `${name} (${item.y})`;
      }
    },
    series: [
      {
        type: 'pie',
        radius: '55%',
        center: ['50%', '65%'],
        data: accesses.map(item => ({ name: item.x, value: item.y })),
        label: {
          show: false,
        },
        itemStyle: {
          emphasis: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
          }
        }
      }
    ]
  });
  return (
    <ReactEcharts option={getOptions()} style={{ height: 367 }} />
  );
}