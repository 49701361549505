import React, { useEffect, useRef, useState } from "react";

import {
  Modal,
  ModalHeader,
  ModalBody,
  Col,
  Row,
  Card,
  CardText,
  Form,
  CardBody,
  Collapse,
} from "reactstrap";

// import ImportGroup from './ImportGroups';
// import ImportParticipant from './ImportParticipant';
import ImportTransfer from "./ImportTransfer";
import ImportTransferVehicle from "./ImportTransferVehicle";
import SectionComponent from "../../Apps/Personalization/SectionComponent";

export default function ModalImportWrapper(props) {
  const { isOpen, toggle, idEvent, data, token } = props;

  const [collapseTransferIsOpen, setCollapseTransferIsOpen] = useState(false);
  const [collapseVehicleIsOpen, setCollapseVehicleIsOpen] = useState(false);

  const [activeImport, setActiveImport] = useState(false);

  function toggleBackdrop() {
    setCollapseVehicleIsOpen(false);
    setCollapseTransferIsOpen(false);
    toggle();
  }
  function toggleCollapseTransfer() {
    if (activeImport) return;
    setCollapseVehicleIsOpen(false);
    setCollapseTransferIsOpen(!collapseTransferIsOpen);
  }

  function toggleCollapseVehicle() {
    if (activeImport) return;
    setCollapseVehicleIsOpen(!collapseVehicleIsOpen);
    setCollapseTransferIsOpen(false);
  }

  function activatedImport(status) {
    setActiveImport(status);
  }

  function setNull() {}

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => toggleBackdrop()}
      autoFocus={true}
      centered={true}
    >
      <ModalHeader toggle={() => toggleBackdrop()}>
        Importador de Transfers
      </ModalHeader>
      <ModalBody className="m-1 p-0">
        <Card className="p-1">
          <CardBody className="m-1 p-2">
            <div>
              <Row
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: 10,
                  width: "245px",
                }}
              >
                <SectionComponent
                  sectionName="Importar Transfers"
                  setIsOpen={!activeImport ? toggleCollapseTransfer : setNull}
                  isOpen={collapseTransferIsOpen}
                />
              </Row>
              <Collapse isOpen={collapseTransferIsOpen}>
                {/* <ImportParticipant
                  isOpen={isOpen}
                  toggle={toggleBackdrop}
                  active={activatedImport}
                  getAllParticipants={getAllParticipants}
                  token={token}
                  idEvent={idEvent}
                /> */}
                <ImportTransfer
                  isOpen={isOpen}
                  toggle={toggleBackdrop}
                  token={token}
                  idEvent={idEvent}
                  data={data}
                />
              </Collapse>
            </div>
            <div>
              <Row
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: 10,
                  width: "280px",
                }}
              >
                <SectionComponent
                  sectionName="Participantes por Veículos"
                  setIsOpen={!activeImport ? toggleCollapseVehicle : setNull}
                  isOpen={collapseVehicleIsOpen}
                />
              </Row>
              <Collapse isOpen={collapseVehicleIsOpen}>
                {/* <ImportGroup
                  isOpen={isOpen}
                  toggle={toggleBackdrop}
                  active={activatedImport}
                  idEvent={idEvent}
                  token={token}
                  getAllParticipants={getAllParticipants}
                /> */}
                <ImportTransferVehicle
                  isOpen={isOpen}
                  toggle={toggleBackdrop}
                  token={token}
                  idEvent={idEvent}
                  data={data}
                />
              </Collapse>
            </div>
          </CardBody>
        </Card>
      </ModalBody>
    </Modal>
  );
}
