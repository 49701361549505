import writeXlsxFile from "write-excel-file";
import { fetchRegistersReports } from "../../../../utils/services/portalBoticario";

export async function createRegistersXlsx(
  jwt,
  limit,
  countData,
  setFinishExport,
  idEvent
) {
  const schema = [
    {
      column: "ID",
      type: String,
      value: (data) => data.id,
    },
    {
      column: "E-mail",
      type: String,
      value: (data) => data.email,
    },
    {
      column: "Nome",
      type: String,
      value: (data) => data.nome,
    },
    {
      column: "Departamento",
      type: String,
      value: (data) => data.codigo_interno_1,
    },
  ];

  const allRequestsPromises = [];
  const numberOfRequisitions = Math.ceil(countData / limit);

  for (let i = 1; i <= numberOfRequisitions; i += 1) {
    const request = await fetchRegistersReports(jwt, idEvent);
    allRequestsPromises.push(request);
    setTimeout(() => {}, 500);

    const progressBar = document.querySelector(".export-progress");
    const percentageBar = document.querySelector(".export-percentage");

    progressBar.style.width = `${(i / numberOfRequisitions) * 100}%`;
    percentageBar.innerHTML = `${((i / numberOfRequisitions) * 100).toFixed(
      0
    )}%`;

    if (i === numberOfRequisitions) {
      setTimeout(() => {
        setFinishExport(true);
      }, 1000);
    }
  }

  const allDataInSingleArray = allRequestsPromises.flat();
  if (allDataInSingleArray) {
    await writeXlsxFile(allDataInSingleArray, {
      schema,
      fileName: "registradores_boticario_cotas.xlsx",
    });
  }
}
