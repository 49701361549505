import React, { useState, useEffect } from "react";
import {
  Table,
  Card,
  CardBody,
  CardTitle,
  Row,
  Button,
  Collapse,
} from "reactstrap";
//redux
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
// moment
import moment from "moment";
// select
import Select from "react-select";
// componentes
import SectionComponent from "../../../Apps/Personalization/SectionComponent";
import SearchFilters from "../../../../components/SearchFilters";
import useDebounce from "../../../../utils/hooks/useDebounce";
import Pagination from "../../../../components/Paginationv3";
import ModalProducts from "./ModalProducts";
import WaitExport from "../../../../components/WaitExport";
import ModalListEmail from "./ModalListEmails";
import { createXlsx } from "./createXlsx";
// service
import {
  getSalesConsolidated,
  deleteSalesConsolidated,
} from "../../../../utils/services/sales";
// alerta
import SweetAlert from "react-bootstrap-sweetalert";

function ConsolidatedSales(props) {
  const location = useLocation();
  // pesquisa
  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounce(search, 300);
  // exportar
  const [exportInProgress, setExportInProgress] = useState(false);
  const [finishExport, setFinishExport] = useState(false);
  // alerta
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [confirmedDeleteAlert, setConfirmedDeleteAlert] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  // obj para deletar e visualizar email
  const [selected, setSelected] = useState({});
  // listagem
  const [list, setList] = useState([]);
  // section component
  const [isOpen, setIsOpen] = useState(false);
  const [collapseIsOpen, setCollapseIsOpen] = useState(false);
  //paginação
  const [currentIndex, setCurrentIndex] = useState(1);
  const [countData, setCountData] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  // modal
  const [modalProducts, setModalProducts] = useState(false);
  const [modalListEmail, setModalListEmail] = useState(false);
  // objeto selecionado para visualizar
  const [idSelected, setIdSelected] = useState("");
  // token
  const token = props.state.global_user_data.data.token;
  // filtro
  const [activeFilter, setActiveFilter] = useState("email");
  const columnsFilter = [
    { name: "Email", value: "email", type: "text" },
    { name: "Tipo Pagamento", value: "payment", type: "text" },
    { name: "Status", value: "status", type: "text" },
    { name: "Data Pagamento", value: "date", type: "text" },
  ];

  function setActiveFilterHandle(columnActive) {
    setActiveFilter(columnActive);
    setSearch("");
  }

  function getTypePayment(type) {
    switch (type) {
      case "pix":
        return "Pix";

      case "credit_card":
        return "Cartão de Crédito";

      case "free":
        return "Gratuito";

      default:
        return "";
    }
  }

  function getStatusPayment(status) {
    switch (status) {
      case "pending":
        return {
          text: "Pendente",
          color: "orange",
        };

      case "paid":
        return {
          text: "Pago",
          color: "#32dc32",
        };

      case "canceled":
        return {
          text: "Cancelado",
          color: "red",
        };

      case "expired":
        return {
          text: "Expirado",
          color: "red",
        };

      case "refunded":
        return {
          text: "Reembolsado",
          color: "blue",
        };

      default:
        return "";
    }
  }

  function exportList() {
    createXlsx({
      token,
      search: debouncedSearch,
      eventId: location.state.idEvent,
      limit: 100,
      columnSearch: activeFilter,
      countData,
      setFinishExport,
      getTypePayment,
      getStatusPayment,
    });

    setExportInProgress(true);
  }

  function deleteSale() {
    deleteSalesConsolidated({
      jwt: token,
      idSale: selected.id,
    }).then((res) => {
      if (res.id) {
        setDeleteAlert(false);
        setConfirmedDeleteAlert(true);
        getListSales();
      } else setErrorAlert(true);
    });
    // .catch((error) => console.log("erro", error));
  }

  function getListSales() {
    getSalesConsolidated({
      token,
      search: debouncedSearch,
      eventId: location.state.idEvent,
      currentIndex,
      itemsPerPage,
      columnSearch: activeFilter,
    }).then((res) => {
      if (res.total !== "0") {
        setList(res.transactions);
        setCountData(Number(res.total));
      } else setList([]);
    });
  }

  const paymentOptions = [
    {
      label: "Pix",
      value: "pix",
    },
    {
      label: "Cartão de Crédito",
      value: "credit_card",
    },
    {
      label: "Gratuito",
      value: "free",
    },
  ];

  const statustOptions = [
    {
      label: "Pendente",
      value: "pending",
    },
    {
      label: "Pago",
      value: "paid",
    },
    {
      label: "Cancelado",
      value: "canceled",
    },
    {
      label: "Expirado",
      value: "expired",
    },
    {
      label: "Reembolsado",
      value: "refunded",
    },
  ];

  useEffect(() => {
    getListSales();
  }, [debouncedSearch, currentIndex, itemsPerPage]);

  return (
    <>
      {modalProducts && (
        <ModalProducts
          isOpen={modalProducts}
          toggle={() => setModalProducts(false)}
          idSelected={idSelected}
          token={token}
        />
      )}

      {modalListEmail && (
        <ModalListEmail
          isOpen={modalListEmail}
          toggle={() => setModalListEmail(false)}
          token={token}
          sale={selected}
        />
      )}

      {exportInProgress && (
        <WaitExport
          isOpen={exportInProgress}
          name="Vendas Consolidadas"
          preposicao="das"
          finishExport={finishExport}
          setFinishExport={setFinishExport}
          setExportInProgress={setExportInProgress}
        />
      )}

      {deleteAlert && (
        <SweetAlert
          title={`Deseja deletar a venda do email ${selected.email} ?`}
          warning
          showCancel
          confirmBtnText="Sim"
          cancelBtnText="Não"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            deleteSale();
          }}
          onCancel={() => setDeleteAlert(false)}
        >
          <a>Você não poderá reverter essa ação!</a>
        </SweetAlert>
      )}

      {confirmedDeleteAlert && (
        <SweetAlert
          success
          title="Confirmado"
          onConfirm={() => {
            setConfirmedDeleteAlert(false);
          }}
        >
          Cancelamento confirmado com sucesso!
        </SweetAlert>
      )}

      {errorAlert && (
        <SweetAlert
          error
          title="Erro ao tentar cancelar venda"
          onConfirm={() => {
            setErrorAlert(false);
          }}
        >
          Tente novamente em breve
        </SweetAlert>
      )}

      <Card>
        <CardBody>
          <CardTitle>Vendas Consolidadas</CardTitle>

          <Row
            style={{
              display: "flex",
              flexDirection: "row",
              marginBottom: 10,
              width: "50px",
            }}
          >
            <SectionComponent
              sectionName="Filtros"
              setIsOpen={setCollapseIsOpen}
              isOpen={collapseIsOpen}
            />
          </Row>

          <Collapse isOpen={collapseIsOpen}>
            <SearchFilters
              columns={columnsFilter}
              activeFilter={activeFilter}
              setActiveFilterHandle={setActiveFilterHandle}
            />
          </Collapse>

          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              marginBottom: 10,
            }}
          >
            {/* {activeFilter} */}
            <form
              className="app-search d-none d-lg-block events-input-search"
              onSubmit={(e) => e.preventDefault()}
            >
              <div className="position-relative">
                {activeFilter === "payment" || activeFilter === "status" ? (
                  <Select
                    onChange={(e) => setSearch(e.value)}
                    options={
                      activeFilter === "payment"
                        ? paymentOptions
                        : statustOptions
                    }
                    placeholder="Selecione"
                  />
                ) : (
                  <>
                    <input
                      type={activeFilter === "date" ? "date" : "text"}
                      className="form-control"
                      placeholder="Pesquisar..."
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                    <span className="bx bx-search-alt" />
                  </>
                )}
              </div>
            </form>

            <div className="mobile-btn-section" style={{ width: "100%" }}>
              <Row className="mobile-btns-top">
                <form
                  className="app-search"
                  style={{ padding: "0px" }}
                  onSubmit={(e) => e.preventDefault()}
                >
                  <div className="position-relative">
                    <input
                      type={activeFilter === "date" ? "date" : "text"}
                      value={search}
                      className="form-control"
                      placeholder="Pesquisar..."
                      onChange={(e) => setSearch(e.target.value)}
                    />
                    <span className="bx bx-search-alt" />
                  </div>
                </form>
              </Row>

              <SectionComponent
                sectionName="Opções"
                setIsOpen={setIsOpen}
                isOpen={isOpen}
              />

              <Collapse isOpen={isOpen}>
                {countData > 0 && (
                  <Row className="mobile-btns-top" onClick={() => exportList()}>
                    <div>Exportar</div>
                  </Row>
                )}
              </Collapse>
            </div>

            <div className="header-btns-container">
              {countData > 0 && (
                <Button
                  color="success"
                  className="btn-rounded waves-effect waves-light events-button-add-lg"
                  onClick={() => exportList()}
                >
                  Exportar
                </Button>
              )}
            </div>
          </div>

          <div className="table-responsive">
            <Table className="table mb-0">
              <thead className="table-light">
                <tr>
                  <th>Email Pagador</th>
                  <th>Valor Total</th>
                  <th>Tipo Pagamento</th>
                  <th>Data Pagamento</th>
                  <th>Status</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                {list.map((e, i) => (
                  <tr key={i}>
                    <td>{e.email}</td>
                    <td>R${e.amount}</td>
                    <td>{getTypePayment(e.type)}</td>
                    <td>{moment(e.updated_at).format("DD/MM/YY")}</td>
                    <td>
                      <a
                        className="a-status-payment"
                        style={{
                          backgroundColor: getStatusPayment(e.status).color,
                        }}
                      >
                        {getStatusPayment(e.status).text}
                      </a>
                    </td>
                    <td>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <i
                          style={{
                            color: "#aea017",
                            fontSize: 17,
                            cursor: "pointer",
                          }}
                          className="bx bx-info-circle"
                          onClick={() =>
                            setIdSelected(e.id) > setModalProducts(true)
                          }
                        />
                        <i
                          style={{
                            color: "blue",
                            fontSize: 17,
                            cursor: "pointer",
                            margin: "0 5px",
                          }}
                          onClick={() =>
                            setSelected(e) > setModalListEmail(true)
                          }
                          className="mdi mdi-email-search"
                        />
                        <i
                          style={{
                            color: "red",
                            fontSize: 17,
                            cursor: "pointer",
                          }}
                          onClick={() => setSelected(e) > setDeleteAlert(true)}
                          className="mdi mdi-trash-can"
                        />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </CardBody>
      </Card>

      <Pagination
        data={list}
        search={search}
        currentIndex={currentIndex}
        setCurrentIndex={setCurrentIndex}
        countData={countData}
        maxItemPerPage={itemsPerPage}
        setMaxItemPerPage={setItemsPerPage}
        maxPageButtonsToShow={3}
      />
    </>
  );
}

const mapStateToProps = (state) => {
  return { state };
};

export default connect(mapStateToProps)(ConsolidatedSales);
