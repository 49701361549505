import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import moment from "moment";

function LineChart(props) {
  const { dataEvolution = [] } = props; // valor padrão
  const [allDays, setAllDays] = useState([]);
  const [employee, setEmployee] = useState([]);
  const [dealer, setDealer] = useState([]);
  const [guest, setGuest] = useState([]);

  const opcoes = {
    legend: {
      show: true,
      position: "top",
    },
    xaxis: {
      categories: allDays,
    },
    colors: ["#34C759", "#FFC107", "#8E24AA"],
  };

  const series = [
    {
      name: "Funcionário",
      data: employee,
    },
    {
      name: "Revendedor",
      data: dealer,
    },
    {
      name: "Convidado",
      data: guest,
    },
  ];

  function getDays() {
    if (Array.isArray(dataEvolution) && dataEvolution.length > 0) {
      let listDays = [];

      dataEvolution.forEach((day) => {
        const dayCurrent = moment(day.data_criacao).format("MM/DD/YYYY");

        if (!listDays.includes(dayCurrent)) {
          listDays.push(dayCurrent);
        }
      });

      listDays.sort((dataA, dataB) => new Date(dataA) - new Date(dataB));

      formatData(listDays, "funcionário", setEmployee);
      formatData(listDays, "revendedor", setDealer);
      formatData(listDays, "convidado", setGuest);

      formatDate(listDays);
    }
  }

  function formatData(listDays, filter, setData) {
    const dataFilter = Array.isArray(dataEvolution)
      ? dataEvolution.filter((e) => e.grupo === filter)
      : [];

    const dataValueFilter = [];
    let previous = "0";

    listDays.forEach((day) => {
      const search = dataFilter.find(
        (e) => moment(e.data_criacao).format("MM/DD/YYYY") === day
      );

      const totalSet = search ? search.total_participantes : previous;
      previous = totalSet;
      dataValueFilter.push(totalSet);
    });

    setData(dataValueFilter);
  }

  function formatDate(listDays) {
    const newFormat = listDays.map((e) => moment(new Date(e)).format("DD/MM"));
    setAllDays(newFormat);
  }

  useEffect(() => {
    getDays();
  }, [dataEvolution]);

  return (
    <div>
      <Chart options={opcoes} series={series} type="line" height="350" />
    </div>
  );
}

export default LineChart;
