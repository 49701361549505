import React, { useState, useEffect } from "react";
import { Input } from "reactstrap";

export default function InputNewRef(props) {
  useEffect(() => {
    var maxValue = props.tableRows.length > 0 ? props.tableRows.length - 1 : 0;
    if (props.table.id === maxValue && props.table.refText === "") {
      setStatdeDisable(false);
    }
  }, [props.tableRows]);

  const [stateDisable, setStatdeDisable] = useState(true);
  return (
    <Input
      type="text"
      value={props.table.refText}
      onChange={(e) =>
        props.updateRow(props.table.id, "refText", e.target.value)
      }
      disabled={stateDisable}
    />
  );
}
