import React, { useState } from 'react';

import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row, 
  UncontrolledTooltip
} from 'reactstrap';

export default function UploadInput({ deleteComponent, rowId, colId, attributes, setAttributes }) {
  const [inputName, setInputName] = useState(attributes ? attributes.inputName : '');
  const [label, setLabel] = useState(attributes ? attributes.label : 'Texto do Upload');
  const [placeholder, setPlaceholder] = useState(attributes ? attributes.placeholder : 'Texto do Placeholder');
  const [tipText, setTipText] = useState(attributes ? attributes.tipText : 'Texto da Dica');
  const [hasTip, setHasTip] = useState(attributes ? attributes.hasTip : false);
  const [required, setRequired] = useState(attributes ? attributes.required : false);

  // Edit Modal
  const [editModal, setEditModal] = useState(false);

  const [nameError, setNameError] = useState(false);

  const submitForm = (e) => {
    e.preventDefault();

    if (inputName === '') {
      setNameError(true);
      return;
    }

    setNameError(false);

    setAttributes({
      rowId,
      colId,
      attributes: {
        inputName,
        label,
        placeholder,
        tipText,
        hasTip,
        required,
      },
    });
    setEditModal(false);
  };

  return (
    <div className="custom-input-container" id="updload-component">
      <Modal
        size="lg"
        isOpen={editModal}
        toggle={() => setEditModal(!editModal)}
      >
        <ModalHeader toggle={() => setEditModal(false)}>
          Configurar Input
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <Form>
                <FormGroup className="input-margin-btm">
                  <Label htmlFor="edit-input-name" className="form-label">
                    Nome do campo
                  </Label>
                  <Input
                    id="edit-input-name"
                    className="form-control"
                    type="text"
                    placeholder="Nome do campo"
                    value={inputName}
                    onChange={({ target }) => setInputName(target.value)}
                  />
                </FormGroup>
                <FormGroup className="input-margin-btm">
                  <Label htmlFor="edit-input-text" className="form-label">
                    Nome do Campo
                  </Label>
                  <Input
                    id="edit-input-text"
                    className="form-control"
                    type="text"
                    value={label}
                    onChange={({ target }) => setLabel(target.value)}
                  />
                  {nameError && <p style={{color: 'red'}}>O campo Nome do Campo é obrigatório!</p>}
                </FormGroup>
                <FormGroup className="input-margin-btm">
                  <Label htmlFor="edit-input-placeholder" className="form-label">
                    Texto do placeholder
                  </Label>
                  <Input
                    id="edit-input-placeholder"
                    className="form-control"
                    type="text"
                    value={placeholder}
                    onChange={({ target }) => setPlaceholder(target.value)}
                  />
                </FormGroup>
                <FormGroup className="input-margin-btm">
                  <Input
                    className="input-mg-rgt"
                    id="edit-input-has-tip"
                    type="checkbox"
                    checked={hasTip}
                    onChange={({ target }) => setHasTip(target.checked)}
                  />
                  <Label htmlFor="edit-input-has-tip" className="form-label">
                    Possui dica?
                  </Label>
                  {hasTip && (
                    <FormGroup className="input-margin-btm">
                      <Label htmlFor="edit-input-tip" className="form-label">
                        Texto da dica
                      </Label>
                      <Input
                        id="edit-input-tip"
                        className="form-control"
                        type="text"
                        value={tipText}
                        onChange={({ target }) => setTipText(target.value)}
                        />
                    </FormGroup>
                  )}
                </FormGroup>
                <FormGroup className="input-margin-btm">
                  <Input
                    className="input-mg-rgt"
                    id="edit-input-required"
                    type="checkbox"
                    checked={required}
                    onChange={({ target }) => setRequired(target.checked)}
                  />
                  <Label htmlFor="edit-input-required" className="form-label">
                    Campo obrigatório?
                  </Label>
                </FormGroup>
              </Form>
            </Col>
            <Col style={{borderLeft: '1px solid gray'}}>
              <div style={{ display: 'flex' }}>
                <Label className="form-label" htmlFor="formText">
                  {label === "Texto do Upload" ? label : <div dangerouslySetInnerHTML={{__html: label}} />}
                </Label>
                {hasTip && (
                  <div>
                    <div className="show-tips" id={`upload-tip-${rowId}-${colId}`}>
                      <span>?</span>
                    </div>
                    <UncontrolledTooltip style={{ backgroundColor: '#6f42c1' }} placement="right" target={`upload-tip-${rowId}-${colId}`}>
                      {tipText}
                    </UncontrolledTooltip>
                  </div>
                )}
              </div>
              <Input
                className="form-control"
                type="text"
                id="formText"
                placeholder={placeholder}
              />
            </Col>
          </Row>
          <div className="save-btn-form-container">
            <Button
              color="success"
              className="btn btn-success waves-effect waves-light save-btn-form"
              onClick={submitForm}
            >
              Salvar
            </Button>
          </div>
        </ModalBody>
      </Modal>
      <div>
        <div className="displayed-config-container">
          <div className="displayed-label">
            <Label className="form-label" htmlFor="formText">
              {label === "Texto do Upload" ? label : <div dangerouslySetInnerHTML={{__html: label}} />}
            </Label>
            {hasTip && (
              <div>
                <div className="show-tips" id={`upload-tip-${rowId}-${colId}`}>
                  <span>?</span>
                </div>
                <UncontrolledTooltip style={{ backgroundColor: '#6f42c1' }} placement="right" target={`upload-tip-${rowId}-${colId}`}>
                  {tipText}
                </UncontrolledTooltip>
              </div>
            )}
          </div>
          <div className="config-form-icon">
            <span
              className="mdi mdi-cog-outline"
              onClick={() => setEditModal(!editModal)}
            />
            <span
              className="mdi mdi-close close-icon"
              onClick={() => deleteComponent(rowId, colId)}
            />
          </div>
        </div>
      </div>
      <Input
        className="form-control"
        type="file"
        id="formFile"
        defaultValue={null}
      />
    </div>
  )
}
