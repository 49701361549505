import React, { useState } from 'react';

import { Button, Col, Collapse, Form, FormGroup, Input, Label, Modal, ModalBody, ModalHeader, Row, UncontrolledTooltip } from 'reactstrap';

import { v4 as uuidv4 } from 'uuid';

import SectionComponent from './SectionComponent';

export default function RadioInput({ deleteComponent, rowId, colId, attributes, setAttributes }) {
  const [inputName, setInputName] = useState(attributes ? attributes.inputName : '');
  const [ask, setAsk] = useState(attributes ? attributes.ask : 'Texto da Pergunta');
  const [isRequired, setIsRequired] = useState(attributes ? attributes.isRequired : false);

  // Checkboxes options
  const [boxes, setBoxes] = useState(attributes ? attributes.boxes : []);

  // Error
  const [error, setError] = useState(false);
  const [nameError, setNameError] = useState(false);


  // Edit Modal
  const [editModal, setEditModal] = useState(false);

  const submitForm = (e) => {
    e.preventDefault();

    if (inputName === '') {
      setNameError(true);
      return;
    }

    setNameError(false);

    if (boxes.length === 0) {
      setError(true);
      return;
    }

    setAttributes({
      rowId,
      colId,
      attributes: { ask, required: isRequired, boxes, inputName },
    });
    setEditModal(false);
  };

  const addBox = (index) => {
    setError(false);
    const id = uuidv4();
    const newBox = {
      label: 'Texto da label',
      index,
      id,
      hasTip: false,
      tipText: 'Dica',
      required: false,
      isOpen: false,
    }

    setBoxes([
      ...boxes,
      newBox,
    ]);
  };

  const editBox = ({index, key, value, className}) => {
    const newBoxes = [...boxes];
    newBoxes[index][key] = value;
    if (key === 'label') {
      const html = value;
      const div = document.getElementsByClassName(className);
    
      for (const element of div) {
        element.innerHTML = html;
      }
    }
    setBoxes(newBoxes);
  };

  const deleteBox = (index) => {
    const newBoxes = boxes.filter((_box, i) => i !== index);
    setBoxes(newBoxes);
  };

  const setSectionOpen = (index) => {
    const newBoxes = boxes.map((box, i) => {
      if (i === index) {
        return {
          ...box,
          isOpen: !box.isOpen,
        }
      }
      return box;
    });
    setBoxes(newBoxes);
  };

  return (
    <div id="therm-input-component">
      <div className="custom-input-container">
        <Modal
          size="lg"
          isOpen={editModal}
          toggle={() => setEditModal(!editModal)}
        >
          <ModalHeader toggle={() => setEditModal(false)}>
            Configurar Radio
          </ModalHeader>
          <ModalBody>
            <Row>

              {/* Edit Section */}
              <Col>
                <Form>
                  <FormGroup className="input-margin-btm">
                    <Label htmlFor="edit-input-name" className="form-label">
                      Nome do campo
                    </Label>
                    <Input
                      id="edit-input-name"
                      className="form-control"
                      type="text"
                      placeholder="Nome do campo"
                      value={inputName}
                      onChange={({ target }) => setInputName(target.value)}
                    />
                    {nameError && <p style={{color: 'red'}}>O campo Nome do Campo é obrigatório!</p>}
                  </FormGroup>
                  <FormGroup className="input-margin-btm">
                    <Label htmlFor="edit-input-text" className="form-label">
                      Texto da Pergunta
                    </Label>
                    <Input
                      id="edit-input-text"
                      className="form-control"
                      type="text"
                      value={ask}
                      onChange={({ target }) => setAsk(target.value)}
                    />
                  </FormGroup>
                  <FormGroup className="input-margin-btm">
                    <Input
                      className="input-mg-rgt"
                      type="checkbox"
                      id="isRequired"
                      onChange={({ target }) => setIsRequired(target.checked)}
                    />
                    <Label htmlFor="isRequired" className="form-label">
                      Campo obrigatório?
                    </Label>
                  </FormGroup>
                  {boxes.map((box, index) => (
                    <div key={`radio/${index}`}>
                      <SectionComponent
                        key={box.id}
                        sectionName={'Editar label'}
                        index={index}
                        isOpen={box.isOpen}
                        setIsOpen={setSectionOpen}
                        deleteSection={deleteBox}
                      />
                      <Collapse isOpen={box.isOpen}>
                        <FormGroup className="input-margin-btm">
                          <div style={{display: 'flex'}}>
                            <Label htmlFor={`edit-input-text-${index}`} className="form-label">
                              Texto da label
                            </Label>
                          </div>
                          <Input
                            id={`edit-input-text-${index}`}
                            className="form-control"
                            type="text"
                            value={box.label}
                            onChange={({ target }) => editBox({
                              index,
                              key: 'label',
                              value: target.value,
                            })}
                          />
                        </FormGroup>
                        <FormGroup className="input-margin-btm">
                          <Input
                            className="input-mg-rgt"
                            id={`edit-input-has-tip-${index}`}
                            type="checkbox"
                            checked={box.hasTip}
                            onChange={({ target }) => editBox({index, key: 'hasTip', value: target.checked})}
                          />
                          <Label htmlFor={`edit-input-has-tip-${index}`} className="form-label">
                            Possui dica?
                          </Label>
                          {box.hasTip && (
                            <FormGroup>
                              <Label htmlFor={`edit-input-tip-${index}`} className="form-label">
                                Texto da dica
                              </Label>
                              <Input
                                id={`edit-input-tip-${index}`}
                                className="form-control"
                                type="text"
                                value={box.tipText}
                                onChange={({ target }) => editBox({index, key: 'tipText', value: target.value})}
                              />
                            </FormGroup>
                          )}
                        </FormGroup>
                      </Collapse>
                    </div>
                  ))}
                  {error && <p style={{color: 'red'}}>Adicione pelo menos uma opção!</p>}
                  <FormGroup style={{marginTop: '20px'}}>
                    <Button
                      color="primary"
                      onClick={() => addBox(boxes.length)}
                    >
                      Adicionar opção
                    </Button>
                  </FormGroup>
                </Form>
              </Col>

              {/* Preview Section */}
              <Col style={{borderLeft: '1px solid gray'}}>
                <div>
                  <span>
                    {ask === "Texto da Pergunta" ? ask : <div dangerouslySetInnerHTML={{__html: ask}} />}
                  </span>
                </div>
                {boxes.map((box, index) => (
                  <div key={`row${rowId}/col${colId}/radio${index}`} style={{ display: 'flex' }}>
                    <Input
                      className="input-mg-rgt"
                      name="radio"
                      type="radio"
                      id={`edit-input-preview-${index}`}
                    />
                    <Label htmlFor={`edit-input-preview-${index}`} className="form-label">
                      {box.label === "Texto da label" ? box.label : <div dangerouslySetInnerHTML={{__html: box.label}} />}
                    </Label>
                    {box.hasTip && (
                      <div>
                        <div className="show-tips" id={`radio-${index}-input`}>
                          <span>?</span>
                        </div>
                        <UncontrolledTooltip style={{ backgroundColor: '#6f42c1' }} placement="right" target={`radio-${index}-input`}>
                          {box.tipText}
                        </UncontrolledTooltip>
                      </div>
                    )}
                  </div>
                ))}
              </Col>
            </Row>
            <div className="save-btn-form-container">
              <Button
                color="success"
                className="btn btn-success waves-effect waves-light save-btn-form"
                onClick={submitForm}
              >
                Salvar
              </Button>
            </div>
          </ModalBody>
        </Modal>
        {/* Displayed Section */}
        <div className="displayed-config-container">
          <div className="displayed-label">
            <span>
              {ask === "Texto da Pergunta" ? ask : <div dangerouslySetInnerHTML={{__html: ask}} />}
            </span>
          </div>
          <div className="config-form-icon">
          <span
            className="mdi mdi-cog-outline"
            onClick={() => setEditModal(!editModal)}
          />
          <span
            className="mdi mdi-close close-icon"
            onClick={() => deleteComponent(rowId, colId)}
          />
        </div>
        </div>
        {boxes.map((box, index) => (
          <div key={index} style={{ display: 'flex' }}>
            <Input
              className="input-mg-rgt"
              name="displayed-radio"
              type="radio"
              id={`edit-input-radio-show-${index}`}
            />
            <Label htmlFor={`edit-input-radio-show-${index}`} className="form-label">
              {box.label === "Texto da label" ? box.label : <div dangerouslySetInnerHTML={{__html: box.label}} />}
            </Label>
            {box.hasTip && (
              <div>
                <div className="show-tips" id={`radio-${index}-display`}>
                  <span>?</span>
                </div>
                <UncontrolledTooltip style={{ backgroundColor: '#6f42c1' }} placement="right" target={`radio-${index}-display`}>
                  {box.tipText}
                </UncontrolledTooltip>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  )
}
