import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader, Row, Col, Button } from "reactstrap";
import ButtonSpinner from "../../../components/Buttons/ButtonSpinner";
import { createStockActivity } from "../../../utils/services/schedulingAndStock";
import SweetAlert from "react-bootstrap-sweetalert";

export default function ModalNewStock(props) {
  const { isOpen, toggle, idEvent, jwt, refetch } = props;
  const [loading, setLoading] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [formRoom, setFormRoom] = useState({
    room: "",
    activity: "",
    initialDate: "",
    endDate: "",
    maxQuantity: 0,
    quantityUsed: 0,
  });
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormRoom((prev) => ({
      ...prev,
      [name]: value,
    }));
    setErrors((prev) => ({
      ...prev,
      [name]: "",
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const newErrors = {};
    if (!formRoom.room) newErrors.room = "O campo Sala é obrigatório";
    if (!formRoom.activity)
      newErrors.activity = "O campo Atividade é obrigatório";
    if (!formRoom.initialDate)
      newErrors.initialDate = "O campo Data e Hora Inicio é obrigatório";
    if (!formRoom.endDate)
      newErrors.endDate = "O campo Data e Hora Fim é obrigatório";
    if (formRoom.maxQuantity <= 0)
      newErrors.maxQuantity =
        "O campo Quantidade Máxima é obrigatório e deve ser maior que 0";

    setErrors(newErrors);

    if (Object.keys(newErrors).length > 0) {
      setLoading(false);
      return;
    }

    try {
      const response = await createStockActivity(
        idEvent,
        formRoom.initialDate,
        formRoom.endDate,
        Number(formRoom.maxQuantity),
        formRoom.quantityUsed,
        formRoom.activity,
        formRoom.room,
        jwt
      );

      console.log("Atividade de estoque criada com sucesso:", response);
      refetch();
      setShowSuccessAlert(true);
    } catch (error) {
      console.error("Erro ao criar atividade de estoque:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Modal isOpen={isOpen} centered autoFocus>
        <ModalHeader toggle={toggle}>Novo Estoque</ModalHeader>
        <ModalBody>
          <form onSubmit={handleSubmit}>
            <Row>
              <Col lg={6} style={{ marginBottom: 10 }}>
                <div>
                  <label className="form-label">Sala*</label>
                  <input
                    className="form-control"
                    type="text"
                    name="room"
                    value={formRoom.room}
                    onChange={handleChange}
                  />
                  {errors.room && (
                    <div style={{ color: "red" }}>{errors.room}</div>
                  )}
                </div>
              </Col>

              <Col lg={6} style={{ marginBottom: 10 }}>
                <div>
                  <label className="form-label">Atividade*</label>
                  <input
                    className="form-control"
                    type="text"
                    name="activity"
                    value={formRoom.activity}
                    onChange={handleChange}
                  />
                  {errors.activity && (
                    <div style={{ color: "red" }}>{errors.activity}</div>
                  )}
                </div>
              </Col>
            </Row>

            <Row>
              <Col lg={6} style={{ marginBottom: 10 }}>
                <div>
                  <label className="form-label">Data e Hora Inicio*</label>
                  <input
                    className="form-control"
                    type="datetime-local"
                    name="initialDate"
                    value={formRoom.initialDate}
                    onChange={handleChange}
                  />
                  {errors.initialDate && (
                    <div style={{ color: "red" }}>{errors.initialDate}</div>
                  )}
                </div>
              </Col>

              <Col lg={6} style={{ marginBottom: 10 }}>
                <div>
                  <label className="form-label">Data e Hora Fim*</label>
                  <input
                    className="form-control"
                    type="datetime-local"
                    name="endDate"
                    value={formRoom.endDate}
                    onChange={handleChange}
                  />
                  {errors.endDate && (
                    <div style={{ color: "red" }}>{errors.endDate}</div>
                  )}
                </div>
              </Col>
            </Row>

            <Row>
              <Col lg={12} style={{ marginBottom: 10 }}>
                <div>
                  <label className="form-label">Quantidade Máxima*</label>
                  <input
                    className="form-control"
                    type="number"
                    name="maxQuantity"
                    value={formRoom.maxQuantity}
                    onChange={handleChange}
                  />
                  {errors.maxQuantity && (
                    <div style={{ color: "red" }}>{errors.maxQuantity}</div>
                  )}
                </div>
              </Col>
            </Row>

            <Row
              style={{
                width: "100%",
                margin: "auto",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                color="danger"
                style={{ width: 80, marginRight: 10 }}
                onClick={toggle}
              >
                Fechar
              </Button>
              <ButtonSpinner
                isLoading={loading}
                title={"Salvar"}
                type="submit"
              />
            </Row>
          </form>
        </ModalBody>
      </Modal>

      {showSuccessAlert && (
        <SweetAlert
          title={`Criado`}
          success
          onConfirm={() => {
            setShowSuccessAlert(false);
            toggle();
          }}
        >
          Atividade criada com sucesso!
        </SweetAlert>
      )}
    </>
  );
}
