import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader, Col, Row, Button } from "reactstrap";
//redux
import { connect } from "react-redux";
// alerta
import SweetAlert from "react-bootstrap-sweetalert";
// select
import Select from "react-select";
//service
import { createHotel } from "../../../utils/services/hotel";
// componentes
import ButtonSpinner from "../../../components/Buttons/ButtonSpinner";

function NewHotel(props) {
  const { isOpen, toggle, participants } = props;
  const [modalBackdrop, setModalBackdrop] = useState(false);
  //confirmção de criação
  const [success_dlg, setsuccess_dlg] = useState(false);
  //state de participantes
  // const [participants, setParticipants] = useState([]);
  const [participantSelected, setParticipantSelected] = useState();
  //state dos campos obrigatórios
  const [participantError, setParticipantError] = useState("");
  const [companyError, setCompanyError] = useState("");
  const [dateBoardingError, setDateBoardingError] = useState("");
  const [timeBoardingError, setTimeBoardingError] = useState("");
  const [dateArrivalError, setDateArrivalError] = useState("");
  const [timeArrivalError, setTimeArrivalError] = useState("");
  const [addresError, setAddresError] = useState("");
  const [isLoading, setIsloading] = useState(false);

  function toggleBackdrop() {
    setModalBackdrop(!modalBackdrop);
  }

  const submitForm = async (e) => {
    //tratamento de erros
    if (!participantSelected) {
      setParticipantError("O campo Participante é obrigatório");
      return;
    } else setParticipantError("");

    if (e.target[1].value === "") {
      setCompanyError("O campo Hotel é obrigatório");
      return;
    } else setCompanyError("");

    if (e.target[2].value === "") {
      setDateBoardingError("O campo Data checkIn é obrigatório");
      return;
    } else setDateBoardingError("");

    if (e.target[3].value === "") {
      setTimeBoardingError("O campo Hora checkIn é obrigatório");
      return;
    } else setTimeBoardingError("");

    if (e.target[4].value === "") {
      setDateArrivalError("O campo Data checkOut é obrigatório");
      return;
    } else setDateArrivalError("");

    if (e.target[5].value === "") {
      setTimeArrivalError("O campo Hora checkOut é obrigatório");
      return;
    } else setTimeArrivalError("");

    if (e.target[7].value === "") {
      setAddresError("O campo Endereço é obrigatório");
      return;
    } else setAddresError("");
    setIsloading(true);
    await createHotel({
      event: props.idEvent,
      login: participantSelected,
      hotel: e.target[1].value,
      checkindate: e.target[2].value,
      checkoutdate: e.target[4].value,
      checkintime: e.target[3].value,
      checkouttime: e.target[5].value,
      reservationnumber: e.target[6].value,
      address: e.target[7].value,
      phonenumber: e.target[8].value,
      extrainformation: e.target[9].value,
      jwt: props.state.global_user_data.data.token,
    })
      .then((response) => {
        setIsloading(false);
        setsuccess_dlg(true);
      })
      .catch((error) => {
        setIsloading(false);
        // console.log("error", error);
      });
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => {
        toggleBackdrop();
      }}
      autoFocus={true}
      centered={true}
    >
      {success_dlg ? (
        <SweetAlert
          success
          title={"Criado"}
          onConfirm={() => {
            props.data[0].refetch();
            props.data[1].refetch();
            setsuccess_dlg(false);
            toggle();
          }}
        >
          {"Hotel criado com sucesso!"}
        </SweetAlert>
      ) : null}
      <ModalHeader toggle={toggle}>Novo Hotel</ModalHeader>
      <ModalBody>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            submitForm(e);
          }}
        >
          <Row>
            <Col lg={12}>
              <Select
                placeholder={"Selecione um participante..."}
                isLoading={participants?.length > 0 ? false : true}
                loadingMessage={() => "Carregando..."}
                noOptionsMessage={() => "Nenhum participante encontrado"}
                onChange={(e) => {
                  setParticipantSelected(e.value);
                }}
                options={[
                  {
                    options: [
                      ...participants?.map((e) => {
                        return { label: e.nome, value: e.id };
                      }),
                    ],
                  },
                ]}
                classNamePrefix="select2-selection"
                menuPortalTarget={document.body}
                styles={{
                  menuPortal: (base) => ({
                    ...base,
                    zIndex: 9999,
                  }),
                  menu: (provided) => ({
                    ...provided,
                    maxHeight: 150,
                  }),
                  menuList: (provided) => ({
                    ...provided,
                    maxHeight: 150,
                    overflowY: "auto",
                  }),
                }}
              />
              {participantError && (
                <a style={{ color: "red" }}>{participantError}</a>
              )}
            </Col>
          </Row>

          <Row style={{ marginTop: 20 }}>
            <Col lg={12}>
              <div>
                <label className="form-label">Hotel*</label>
                <input className="form-control" type="text" />
              </div>
              {companyError && <a style={{ color: "red" }}>{companyError}</a>}
            </Col>
          </Row>

          <Row style={{ marginTop: 20 }}>
            <Col lg={6}>
              <div>
                <label className="form-label">Data checkIn*</label>
                <input
                  className="form-control"
                  type="date"
                  // defaultValue="2019-08-19T13:45:00"
                  id="example-datetime-local-input"
                />
              </div>
              {dateBoardingError && (
                <a style={{ color: "red" }}>{dateBoardingError}</a>
              )}
            </Col>

            <Col lg={6}>
              <div>
                <label className="form-label">Hora checkIn*</label>
                <input
                  className="form-control"
                  type="time"
                  // defaultValue="2019-08-19T13:45:00"
                  id="example-datetime-local-input"
                />
              </div>
              {timeBoardingError && (
                <a style={{ color: "red" }}>{timeBoardingError}</a>
              )}
            </Col>
          </Row>

          <Row style={{ marginTop: 20 }}>
            <Col lg={6}>
              <div>
                <label className="form-label">Data checkOut*</label>
                <input
                  className="form-control"
                  type="date"
                  // defaultValue="2019-08-19T13:45:00"
                  id="example-datetime-local-input"
                />
              </div>
              {dateArrivalError && (
                <a style={{ color: "red" }}>{dateArrivalError}</a>
              )}
            </Col>

            <Col lg={6}>
              <div>
                <label className="form-label">Hora checkOut*</label>
                <input
                  className="form-control"
                  type="time"
                  // defaultValue="2019-08-19T13:45:00"
                  id="example-datetime-local-input"
                />
              </div>
              {timeArrivalError && (
                <a style={{ color: "red" }}>{timeArrivalError}</a>
              )}
            </Col>
          </Row>

          <Row style={{ marginTop: 20 }}>
            <Col lg={12}>
              <div>
                <label className="form-label">Número da reserva</label>
                <input className="form-control" type="text" />
              </div>
            </Col>
          </Row>

          <Row style={{ marginTop: 20 }}>
            <Col lg={12}>
              <div>
                <label className="form-label">Endereço*</label>
                <input className="form-control" type="text" />
              </div>
              {addresError && <a style={{ color: "red" }}>{addresError}</a>}
            </Col>
          </Row>

          <Row style={{ marginTop: 20 }}>
            <Col lg={12}>
              <div>
                <label className="form-label">Telefone do hotel</label>
                <input className="form-control" type="text" />
              </div>
            </Col>
          </Row>

          <Row style={{ marginTop: 20 }}>
            <Col lg={12}>
              <div>
                <label className="form-label">Informações extras</label>
                <input className="form-control" type="text" />
              </div>
            </Col>
          </Row>

          <Row
            style={{
              width: "100%",
              margin: "20px auto 0",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              color="danger"
              className="btn waves-effect waves-light"
              style={{ width: 80, marginRight: 15 }}
              onClick={() => toggle()}
            >
              Fechar
            </Button>
            <ButtonSpinner isLoading={isLoading} title={"Salvar"} />
          </Row>
        </form>
      </ModalBody>
    </Modal>
  );
}

const mapStateToProps = function (state) {
  return { state: state };
};

export default connect(mapStateToProps)(NewHotel);
