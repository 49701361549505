export const allowedMimeTypes = [
  // Excel
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  // Word
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  // PPT
  "application/vnd.ms-powerpoint",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  // Video
  "video/mp4",
  "video/x-msvideo",
  "video/quicktime",
  "video/x-ms-wmv",
  // Audio
  "audio/mpeg",
  "audio/wav",
  "audio/aac",
  "audio/flac",
  // Images
  "image/jpeg",
  "image/png",
  "image/gif",
  "image/bmp",
  "image/webp",
  // Text
  "text/plain",
  // HTML
  "text/html",
  // JavaScript
  "application/javascript",
  // CSS
  "text/css",
  // JSON
  "application/json",
  // PDF
  "application/pdf",
];
