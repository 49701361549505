import React, { useEffect, useState } from "react";
import { MetaTags } from "react-meta-tags";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Collapse,
  Col,
  Row,
  Table,
} from "reactstrap";

import SweetAlert from "react-bootstrap-sweetalert";
import Select from "react-select";

import Pagination from "../../components/Paginationv3";
import ModalNewApps from "./ModalNewApps";
import ModalEditApps from "./ModalEditApps";
import { deleteApp, getMobileApps } from "../../utils/services/mobileApps";
import { getContract } from "../../utils/services/contracts";

import useDebounce from "../../utils/hooks/useDebounce";
import { updateMobileApps } from "../../utils/store/actions/mobileApps";
import ModalEnableApp from "./ModalEnableApp";
import SectionComponent from "./Personalization/SectionComponent";
import { ModalVersionApp } from "./ModalVersionApps";

function AppsMobile() {
  const dispatch = useDispatch();
  const { token, theme } = useSelector((state) => state.global_user_data.data);

  //search
  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounce(search, 300);

  //data
  const { mobileApps } = useSelector((state) => state);

  //criar app
  const [modalNewApp, setModalNewApp] = useState(false);

  //editar app
  const [modalEditApp, setModalEditApp] = useState(false);
  const [editAppId, setEditAppId] = useState("");

  //deletar app
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [successDelete, setSuccessDelete] = useState(false);

  //version
  const [modalVersionApp, setModalVersionApp] = useState(false);

  //paginação
  const [currentIndex, setCurrentIndex] = useState(1);
  const [countData, setCountData] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  //delete app id
  const [deleteAppId, setDeleteAppId] = useState("");
  const [deleteAppName, setDeleteAppName] = useState("");

  //apps inativos ativos
  const [disabledApps, setDisabledApps] = useState(false);
  const [enableAppAlert, setEnableAppAlert] = useState(false);
  const [enableAppId, setEnableAppId] = useState("");
  // collapse
  const [isOpen, setIsOpen] = useState(false);

  //contract
  const [contracts, setContracts] = useState([]);
  const [selectedContract, setSelectedContract] = useState({
    label: "Inteegra Eventos",
    value: "8029badb-d64c-4af8-9f52-59b60e346767",
  });

  function toggleNewApp() {
    setModalNewApp(!modalNewApp);
  }

  function toggleEditApp() {
    setModalEditApp(!modalEditApp);
  }

  function toggleVersionApp() {
    setModalVersionApp(!modalVersionApp);
  }

  async function getAllMobileApps() {
    getMobileApps({
      jwt: token,
      offset: currentIndex > 0 ? currentIndex : 1,
      limit: itemsPerPage,
      search: debouncedSearch,
      contractId: selectedContract?.value
        ? selectedContract?.value
        : theme[0]?.id,
    }).then((data) => {
      setCountData(data.findAllMobileAppData.count);
      dispatch(updateMobileApps(data.findAllMobileAppData.data));
    });
    // .catch((error) => console.log(error));
  }

  async function deleteAppMobile() {
    return deleteApp({
      jwt: token,
      id: deleteAppId,
      contractId: selectedContract?.value
        ? selectedContract?.value
        : theme[0]?.id,
    }).then((response) => {
      setDeleteAppId("");
      setDeleteAppName("");
      getAllMobileApps();
    });
    // .catch((error) => console.log(error))
  }

  useEffect(() => {
    getMobileApps({
      jwt: token,
      offset: currentIndex > 0 ? currentIndex : 1,
      limit: itemsPerPage,
      search: debouncedSearch,
      status: disabledApps,
      contractId: selectedContract?.value
        ? selectedContract?.value
        : theme[0]?.id,
    }).then((data) => {
      setCountData(data.findAllMobileAppData.count);
      dispatch(updateMobileApps(data.findAllMobileAppData.data));
    });
    // .catch((error) => console.log(error));
  }, [
    token,
    currentIndex,
    itemsPerPage,
    debouncedSearch,
    dispatch,
    disabledApps,
    selectedContract,
  ]);

  useEffect(() => {
    getContract({
      jwt: token,
      offset: currentIndex > 0 ? currentIndex : 1,
      limit: 50,
      search: debouncedSearch,
      status: true,
    }).then((data) => {
      setContracts(data.data.contracts);
      // dispatch(updateMobileApps(data.findAllMobileAppData.data))
    });
    // .catch((error) => console.log(error));
  }, []);

  return (
    <div>
      <MetaTags>
        <title>Versões do APP | Inteegra Plus</title>
      </MetaTags>
      <ModalNewApps
        isOpen={modalNewApp}
        toggle={toggleNewApp}
        refetch={getAllMobileApps}
        contractId={
          selectedContract?.value ? selectedContract?.value : theme[0]?.id
        }
      />
      <ModalEditApps
        isOpen={modalEditApp}
        editAppId={editAppId}
        toggle={toggleEditApp}
        refetch={getAllMobileApps}
        contractId={
          selectedContract?.value ? selectedContract?.value : theme[0]?.id
        }
      />
      <ModalVersionApp isOpen={modalVersionApp} toggle={toggleVersionApp} />

      {enableAppAlert && (
        <ModalEnableApp
          enableAppId={enableAppId}
          refetch={getAllMobileApps}
          setEnableAppAlert={setEnableAppAlert}
          setDisabledApps={setDisabledApps}
          contractId={
            selectedContract?.value ? selectedContract?.value : theme[0]?.id
          }
        />
      )}
      {deleteAlert && (
        <SweetAlert
          title={`Deseja desativar o App ${deleteAppName}?`}
          warning
          showCancel
          confirmBtnText="Sim"
          cancelBtnText="Não"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            deleteAppMobile();
            setDeleteAlert(false);
            setSuccessDelete(true);
          }}
          onCancel={() => {
            setDeleteAppId("");
            setDeleteAppName("");
            setDeleteAlert(false);
          }}
        >
          O App será desativado.
        </SweetAlert>
      )}
      {successDelete && (
        <SweetAlert
          success
          title="Deletado"
          onConfirm={() => {
            setSuccessDelete(false);
          }}
        >
          {`App ${deleteAppName} deletado com sucesso`}
        </SweetAlert>
      )}
      <Card>
        <CardBody>
          <CardTitle>Versões do APP</CardTitle>
          <div
            className="col-12 col-sm-1"
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <form
              className="app-search d-none d-lg-block events-input-search-select"
              onSubmit={(e) => e.preventDefault()}
            >
              <div className="position-relative">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Pesquisar..."
                  onChange={(e) => setSearch(e.target.value)}
                />
                <span className="bx bx-search-alt" />
              </div>
            </form>
            <form
              className="app-search d-none d-lg-block events-input-search-select"
              onSubmit={(e) => e.preventDefault()}
            >
              <div className="position-relative">
                <Select
                  value={selectedContract}
                  placeholder={"Selecione o contrato"}
                  isMulti={false}
                  isClearable
                  onChange={(e) => {
                    setSelectedContract(e);
                  }}
                  options={[
                    {
                      options: [
                        ...contracts?.map((e) => {
                          return {
                            label: e.name,
                            value: e.id,
                          };
                        }),
                      ],
                    },
                  ]}
                  classNamePrefix="select2-selection"
                />
              </div>
            </form>
            <div className="mobile-btn-section" style={{ width: "100%" }}>
              <Row className="mobile-btns-top">
                <form
                  className="app-search"
                  style={{ padding: "0px" }}
                  onSubmit={(e) => e.preventDefault()}
                >
                  <div className="position-relative">
                    <input
                      type="text"
                      value={search}
                      className="form-control"
                      placeholder="Pesquisar..."
                      onChange={(e) => setSearch(e.target.value)}
                    />
                    <span className="bx bx-search-alt" />
                  </div>
                </form>
              </Row>
              <SectionComponent
                sectionName="Opções"
                setIsOpen={setIsOpen}
                isOpen={isOpen}
              />
              <Collapse isOpen={isOpen}>
                <Row className="mobile-btns-top">
                  <Button
                    color="secondary"
                    className="btn-rounded waves-effect waves-light events-button-add"
                    onClick={() => setDisabledApps(!disabledApps)}
                    style={{ width: "49%" }}
                  >
                    {disabledApps
                      ? "Filtrar: Apps Ativos"
                      : "Filtrar: Apps Inativos"}
                  </Button>
                  <Button
                    style={{ width: "49%", marginLeft: "2%" }}
                    color="success"
                    className="btn-rounded waves-effect waves-light events-button-add"
                    onClick={() => setModalNewApp(true)}
                  >
                    Criar App
                  </Button>
                </Row>
                <Row className="mobile-btns-top">
                  <form onSubmit={(e) => e.preventDefault()}>
                    <div className="position-relative">
                      <Select
                        value={selectedContract}
                        placeholder={"Selecione o contrato"}
                        isMulti={false}
                        isClearable
                        onChange={(e) => {
                          setSelectedContract(e);
                        }}
                        options={[
                          {
                            options: [
                              ...contracts?.map((e) => {
                                return {
                                  label: e.name,
                                  value: e.id,
                                };
                              }),
                            ],
                          },
                        ]}
                        classNamePrefix="select2-selection"
                      />
                    </div>
                  </form>
                </Row>
              </Collapse>
            </div>
            <div className="header-btns-container">
              <Button
                color="secondary"
                className="btn-rounded waves-effect waves-light events-button-add"
                onClick={() => setDisabledApps(!disabledApps)}
              >
                {disabledApps
                  ? "Filtrar: Apps Ativos"
                  : "Filtrar: Apps Inativos"}
              </Button>

              <Button
                color="success"
                className="btn-rounded waves-effect waves-light events-button-add"
                onClick={() => setModalNewApp(true)}
              >
                Criar App
              </Button>
            </div>
          </div>
          <div className="table-responsive">
            <Table className="table mb-0">
              <thead className="table-light">
                <tr>
                  <th>ID</th>
                  <th>Nome</th>
                  <th>Bundle ID</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                {mobileApps?.map((app, i) => (
                  <tr key={i}>
                    <td>{app.id}</td>
                    <td>{app.name}</td>
                    <td>{app.bundleId}</td>
                    {disabledApps ? (
                      <td>
                        <i
                          className="mdi mdi-account-reactivate"
                          style={{
                            color: "green",
                            fontSize: 17,
                            cursor: "pointer",
                            marginLeft: 13,
                          }}
                          onClick={() => {
                            setEnableAppId(app.id);
                            setEnableAppAlert(true);
                          }}
                        />
                      </td>
                    ) : (
                      <td>
                        <i
                          className="mdi mdi-pencil"
                          style={{
                            marginLeft: 5,
                            color: "green",
                            fontSize: 17,
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setModalEditApp(true);
                            setEditAppId(app.id);
                          }}
                        />
                        {/* <i
                          className="mdi mdi-cloud-upload"
                          style={{
                            color: "blue",
                            fontSize: 17,
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setModalVersionApp(true);
                          }}
                        /> */}
                        <i
                          className="mdi mdi-trash-can"
                          style={{
                            color: "red",
                            fontSize: 17,
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setDeleteAppId(app.id);
                            setDeleteAppName(app.name);
                            setDeleteAlert(true);
                          }}
                        />
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </CardBody>
      </Card>
      <div className="bottom-navigation" style={{ justifyContent: "end" }}>
        {/* 
        Fornecer os 4 parametros obrigatórios;
        ** data - a array contendo as informações da tabela
        ** search - o state do termo de pesquisa
        ** currentIndex - posição atual do index
        ** setCurrentIndex - função para atualizar a posição atual do index
        */}
        <Pagination
          data={mobileApps}
          search={search}
          currentIndex={currentIndex}
          setCurrentIndex={setCurrentIndex}
          countData={countData}
          maxItemPerPage={itemsPerPage}
          setMaxItemPerPage={setItemsPerPage}
          maxPageButtonsToShow={3}
        />
      </div>
    </div>
  );
}

export default AppsMobile;
