import React, { useState, useEffect } from "react";
import { Row, Col, Button, Modal, ModalHeader, ModalBody } from "reactstrap";
// service
import { updateSurvey } from "../../../../utils/services/superScreen";
import { getMobileAppsByEvent } from "../../../../utils/services/mobileApps";
//redux
import { connect } from "react-redux";
// alerta
import SweetAlert from "react-bootstrap-sweetalert";
import ButtonSpinner from "../../../../components/Buttons/ButtonSpinner";

function ModalNewSurvey(props) {
  const { isOpen, toggle } = props;
  const [response, setResponse] = useState(props.editSurvey.rightAnswer);
  const [points, setPoints] = useState(props.editSurvey.hasScore);
  const [sendQuestion, setSendQuestion] = useState(false);
  const [isLoading, setIsloading] = useState(false);

  // dados do formulário
  const [formData, setFormData] = useState({});
  const [openAnswer, setOpenAnswer] = useState(false);
  // erros do formulário
  const [formErros, setFormErros] = useState({});
  // alerta
  const [successAlert, setSuccessAlert] = useState(false);
  // carregando botão
  const [loadindBtn, setLoadingBtn] = useState(false);
  // apps
  const [mobileapps, setMobileApps] = useState([]);

  function formatAnswer(e) {
    var stringFormat = e
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toUpperCase();

    return stringFormat;
  }

  function verifyErrros() {
    setLoadingBtn(true);

    if (!formData.question) {
      setLoadingBtn(false);
      setFormErros({ question: "Esse campo é obrigatório" });
      return;
    }

    if (!formData.option1) {
      setLoadingBtn(false);
      setFormErros({ option1: "Esse campo é obrigatório" });
      return;
    }

    if (!formData.option2) {
      setLoadingBtn(false);
      setFormErros({ option2: "Esse campo é obrigatório" });
      return;
    }

    if (
      formData.rightAnswer &&
      (!formData.optionRightAnswer ||
        formData.optionRightAnswer === "Selecione")
    ) {
      setLoadingBtn(false);
      setFormErros({ optionRightAnswer: "Esse campo é obrigatório" });
      return;
    }

    if (formData.points && !formData.pointsCode) {
      setLoadingBtn(false);
      setFormErros({ pointsCode: "Esse campo é obrigatório" });
      return;
    }

    if (!formData.sendAnswerNow && !formData.dateToSend) {
      setLoadingBtn(false);
      setFormErros({ dateToSend: "Esse campo é obrigatório" });
      return;
    }
    setFormErros({});

    submitForm();
  }

  function submitForm() {
    let firebaseProjectsId = [];

    mobileapps.map((e) => {
      firebaseProjectsId.push(e?.idfirebaseproject);
    });
    firebaseProjectsId = firebaseProjectsId.map((e) => `${e}`).join(",");

    const data = {
      transmissionId: props.idSuperScreen,
      eventId: props.idEvent,
      question: formData.question,
      option1: !openAnswer ? formData.option1 : "",
      option2: !openAnswer ? formData.option2 : "",
      option3: !openAnswer && formData.option3 ? formData.option3 : "",
      option4: !openAnswer && formData.option4 ? formData.option4 : "",
      option5: !openAnswer && formData.option5 ? formData.option5 : "",
      rightAnswer: formData.rightAnswer,
      answer: formData.rightAnswer
        ? verifyCorrectAnswer(formData.optionRightAnswer)
        : "",
      hasScore: formData.points,
      scoreCode: formData.pointsCode ? formData.pointsCode : "",
      scoreOnlyRightAnswer: formData.pointsRightAnswer,
      dateSurvey: formData.sendAnswerNow
        ? new Date(Date.now())
        : new Date(formData.dateToSend),
      scheduled: !sendQuestion,
      tipo: openAnswer ? "Aberta" : "Fechada",
      firebaseProject: firebaseProjectsId,
    };
    setIsloading(true);
    updateSurvey(
      data,
      props.state.global_user_data.data.token,
      props.editSurvey.id
    ).then((res) => {
      setIsloading(false);
      if (res.message === "updated") {
        props.getAllSurvey();
        setSuccessAlert(true);
      }

      setLoadingBtn(false);
    });
  }

  function verifyCorrectAnswer(e) {
    var answer = Number(e);
    // console.log(answer);
    if (answer === 1) {
      return formData.option1;
    } else if (answer === 2) {
      return formData.option2;
    } else if (answer === 3) {
      return formData.option3;
    } else if (answer === 4) {
      return formData.option4;
    } else if (answer === 5) {
      return formData.option5;
    } else return "";
  }

  function formtDateToInput(e) {
    var dateFormated = e.slice(0, 16);

    return dateFormated;
  }

  async function getMobileAppEvent() {
    await getMobileAppsByEvent({
      jwt: props.state.global_user_data.data.token,
      eventId: props.idEvent,
    })
      .then((response) => {
        setMobileApps(response.findOneMobileAppData);
      })
      .catch((error) => {
        // console.log(error);
      });
  }

  useEffect(() => {
    setFormData({
      rightAnswer: props.editSurvey.rightAnswer,
      points: props.editSurvey.hasScore,
      pointsRightAnswer: props.editSurvey.scoreOnlyRightAnswer,
      sendAnswerNow: false,
      question: props.editSurvey.question,
      option1: props.editSurvey.option1,
      option2: props.editSurvey.option2,
      option3: props.editSurvey.option3,
      option4: props.editSurvey.option4,
      option5: props.editSurvey.option5,
      dateToSend: formtDateToInput(props.editSurvey.dateSurvey),
      optionRightAnswer: props.editSurvey.answer,
      pointsCode: props.editSurvey.scoreCode,
    });

    setOpenAnswer(props.editSurvey.tipo === "Aberta" ? true : false);

    getMobileAppEvent();
  }, []);

  useEffect(() => {
    setFormData((state) => {
      return {
        ...state,
        optionRightAnswer: null,
      };
    });
  }, [openAnswer]);

  return (
    <Modal isOpen={isOpen} centered autoFocus size="lg">
      {successAlert ? (
        <SweetAlert
          success
          title="Editado"
          onConfirm={() => {
            toggle();
            setSuccessAlert(false);
          }}
        >
          Enquete editada com sucesso
        </SweetAlert>
      ) : null}
      <ModalHeader toggle={toggle}>Editar Enquete</ModalHeader>
      <ModalBody>
        {/* <a onClick={() => console.log(formData)}>formData</a>
        <a onClick={() => console.log(props.editSurvey)}>edit</a> */}
        <Row>
          <Col md={12}>
            <label className="form-label">ID</label>
            <input
              className="form-control"
              type="text"
              disabled
              defaultValue={props.editSurvey.id}
            />
          </Col>
        </Row>

        <Row className="mt-3">
          <Col md={12}>
            <label className="form-label">Pergunta *</label>
            <input
              className="form-control"
              type="text"
              disabled={!props.editable}
              defaultValue={props.editSurvey.question}
              onChange={(e) =>
                setFormData((state) => {
                  return {
                    ...state,
                    question: e.target.value,
                  };
                })
              }
            />
            {formErros.question && (
              <p style={{ color: "red" }}>{formErros.question}</p>
            )}
          </Col>
        </Row>

        <Row className="mt-3">
          <Col md={4}>
            <label className="form-label">Tipo de Enquete *</label>
            <div>
              <input
                type="radio"
                name="openAnswer"
                value="link"
                style={{ marginRight: 5 }}
                id="openAnswerOption1"
                defaultChecked={props.editSurvey.tipo === "Fechada"}
                // disabled
                onChange={() => {
                  setOpenAnswer(false);
                }}
              />
              <label htmlFor="openAnswerOption1" className="form-label">
                Fechada
              </label>
            </div>
            <div>
              <input
                type="radio"
                name="openAnswer"
                value="link"
                style={{ marginRight: 5 }}
                id="openAnswerOption2"
                defaultChecked={props.editSurvey.tipo === "Aberta"}
                // disabled
                onChange={() => {
                  setOpenAnswer(true);
                }}
              />
              <label htmlFor="openAnswerOption2" className="form-label">
                Aberta
              </label>
            </div>
          </Col>
        </Row>

        {!openAnswer && (
          <>
            <Row className="mt-3">
              <Col md={4}>
                <label className="form-label">Opção 1 *</label>
                <input
                  className="form-control"
                  type="text"
                  disabled={!props.editable}
                  defaultValue={props.editSurvey.option1}
                  onChange={(e) =>
                    setFormData((state) => {
                      return {
                        ...state,
                        option1: e.target.value,
                      };
                    })
                  }
                />
                {formErros.option1 && (
                  <p style={{ color: "red" }}>{formErros.option1}</p>
                )}
              </Col>

              <Col md={4}>
                <label className="form-label">Opção 2 *</label>
                <input
                  className="form-control"
                  type="text"
                  disabled={!props.editable}
                  defaultValue={props.editSurvey.option2}
                  onChange={(e) =>
                    setFormData((state) => {
                      return {
                        ...state,
                        option2: e.target.value,
                      };
                    })
                  }
                />
                {formErros.option2 && (
                  <p style={{ color: "red" }}>{formErros.option2}</p>
                )}
              </Col>

              <Col md={4}>
                <label className="form-label">Opção 3</label>
                <input
                  className="form-control"
                  type="text"
                  disabled={!props.editable}
                  defaultValue={props.editSurvey.option3}
                  onChange={(e) =>
                    setFormData((state) => {
                      return {
                        ...state,
                        option3: e.target.value,
                      };
                    })
                  }
                />
              </Col>
            </Row>

            <Row className="mt-3">
              <Col md={6}>
                <label className="form-label">Opção 4</label>
                <input
                  className="form-control"
                  type="text"
                  disabled={!props.editable}
                  defaultValue={props.editSurvey.option4}
                  onChange={(e) =>
                    setFormData((state) => {
                      return {
                        ...state,
                        option4: e.target.value,
                      };
                    })
                  }
                />
              </Col>

              <Col md={6}>
                <label className="form-label">Opção 5</label>
                <input
                  className="form-control"
                  type="text"
                  disabled={!props.editable}
                  defaultValue={props.editSurvey.option5}
                  onChange={(e) =>
                    setFormData((state) => {
                      return {
                        ...state,
                        option5: e.target.value,
                      };
                    })
                  }
                />
              </Col>
            </Row>
          </>
        )}

        <Row className="mt-3">
          <Col md={4}>
            <label className="form-label">Resposta correta? *</label>
            <div>
              <input
                type="radio"
                name="response"
                value="link"
                style={{ marginRight: 5 }}
                id="responseOption1"
                disabled={!props.editable}
                defaultChecked={props.editSurvey.rightAnswer ? false : true}
                onChange={() => {
                  setResponse(false);
                  setFormData((state) => {
                    return {
                      ...state,
                      rightAnswer: false,
                    };
                  });
                }}
              />
              <label htmlFor="responseOption1" className="form-label">
                Não
              </label>
            </div>
            <div>
              <input
                type="radio"
                name="response"
                value="link"
                style={{ marginRight: 5 }}
                id="responseOption2"
                disabled={!props.editable}
                defaultChecked={props.editSurvey.rightAnswer ? true : false}
                onChange={() => {
                  setResponse(true);
                  setFormData((state) => {
                    return {
                      ...state,
                      rightAnswer: true,
                    };
                  });
                }}
              />
              <label htmlFor="responseOption2" className="form-label">
                Sim
              </label>
            </div>
          </Col>

          <Col md={4}>
            <label className="form-label">Possui pontuação? *</label>
            <div>
              <input
                type="radio"
                name="points"
                value="link"
                style={{ marginRight: 5 }}
                id="pointsOption1"
                disabled={!props.editable}
                defaultChecked={props.editSurvey.hasScore ? false : true}
                onChange={() => {
                  setPoints(false);
                  setFormData((state) => {
                    return {
                      ...state,
                      points: false,
                    };
                  });
                }}
              />
              <label htmlFor="pointsOption1" className="form-label">
                Não
              </label>
            </div>
            <div>
              <input
                type="radio"
                name="points"
                value="link"
                style={{ marginRight: 5 }}
                id="pointsOption2"
                disabled={!props.editable}
                defaultChecked={props.editSurvey.hasScore ? true : false}
                onChange={() => {
                  setPoints(true);
                  setFormData((state) => {
                    return {
                      ...state,
                      points: true,
                    };
                  });
                }}
              />
              <label htmlFor="pointsOption2" className="form-label">
                Sim
              </label>
            </div>
          </Col>

          {points && response && (
            <Col md={4}>
              <label className="form-label">
                Pontuar somente a resposta correta *
              </label>

              <div>
                <input
                  type="radio"
                  name="responseCorrect"
                  style={{ marginRight: 5 }}
                  id="responseCorrectOption1"
                  disabled={!props.editable}
                  defaultChecked={
                    props.editSurvey.scoreOnlyRightAnswer ? false : true
                  }
                  onChange={() => {
                    setFormData((state) => {
                      return {
                        ...state,
                        pointsRightAnswer: false,
                      };
                    });
                  }}
                />
                <label htmlFor="responseCorrectOption1" className="form-label">
                  Não
                </label>
              </div>

              <div>
                <input
                  type="radio"
                  name="responseCorrect"
                  style={{ marginRight: 5 }}
                  id="responseCorrectOption2"
                  disabled={!props.editable}
                  defaultChecked={
                    props.editSurvey.scoreOnlyRightAnswer ? true : false
                  }
                  onChange={() => {
                    setFormData((state) => {
                      return {
                        ...state,
                        pointsRightAnswer: true,
                      };
                    });
                  }}
                />
                <label htmlFor="responseCorrectOption2" className="form-label">
                  Sim
                </label>
              </div>
            </Col>
          )}
        </Row>

        <Row className="mt-3">
          {response && (
            <Col md={4}>
              <label className="form-label">Resposta *</label>
              {openAnswer ? (
                <input
                  className="form-control"
                  type="text"
                  defaultValue={props.editSurvey.answer}
                  onChange={(e) => {
                    setFormData((state) => {
                      return {
                        ...state,
                        optionRightAnswer: formatAnswer(e.target.value),
                      };
                    });
                  }}
                />
              ) : (
                <select
                  className="form-control"
                  disabled={!props.editable}
                  onChange={(e) => {
                    setFormData((state) => {
                      return {
                        ...state,
                        optionRightAnswer: e.target.value,
                      };
                    });
                  }}
                >
                  <option>Selecione</option>
                  <option
                    value={1}
                    selected={
                      props.editSurvey.answer === props.editSurvey.option1
                        ? true
                        : false
                    }
                  >
                    Opção 1
                  </option>
                  <option
                    value={2}
                    selected={
                      props.editSurvey.answer === props.editSurvey.option2
                        ? true
                        : false
                    }
                  >
                    Opção 2
                  </option>
                  {formData.option3 && (
                    <option
                      value={3}
                      selected={
                        props.editSurvey.answer === props.editSurvey.option3
                          ? true
                          : false
                      }
                    >
                      Opção 3
                    </option>
                  )}
                  {formData.option4 && (
                    <option
                      value={4}
                      selected={
                        props.editSurvey.answer === props.editSurvey.option4
                          ? true
                          : false
                      }
                    >
                      Opção 4
                    </option>
                  )}
                  {formData.option5 && (
                    <option
                      value={5}
                      selected={
                        props.editSurvey.answer === props.editSurvey.option5
                          ? true
                          : false
                      }
                    >
                      Opção 5
                    </option>
                  )}
                </select>
              )}

              {formErros.optionRightAnswer && (
                <p style={{ color: "red" }}>{formErros.optionRightAnswer}</p>
              )}
            </Col>
          )}

          {points && (
            <Col md={4}>
              <label className="form-label">Código da pontuação *</label>
              <input
                className="form-control"
                type="text"
                disabled={!props.editable}
                defaultValue={props.editSurvey.scoreCode}
                onChange={(e) => {
                  setFormData((state) => {
                    return {
                      ...state,
                      pointsCode: e.target.value,
                    };
                  });
                }}
              />
              {formErros.pointsCode && (
                <p style={{ color: "red" }}>{formErros.pointsCode}</p>
              )}
            </Col>
          )}
        </Row>

        <Row className="mt-3">
          <Col md={4}>
            <label className="form-label">Enviar pergunta agora *</label>
            <div>
              <input
                type="radio"
                name="sendQuestion"
                style={{ marginRight: 5 }}
                id="sendQuestionOption2"
                disabled={!props.editable}
                onChange={(e) => {
                  setSendQuestion(true);
                  setFormData((state) => {
                    return {
                      ...state,
                      sendAnswerNow: true,
                    };
                  });
                }}
              />
              <label htmlFor="sendQuestionOption2" className="form-label">
                Sim
              </label>
            </div>
            <div>
              <input
                type="radio"
                name="sendQuestion"
                style={{ marginRight: 5 }}
                id="sendQuestionOption1"
                defaultChecked
                disabled={!props.editable}
                onChange={(e) => {
                  setSendQuestion(false);
                  setFormData((state) => {
                    return {
                      ...state,
                      sendAnswerNow: false,
                    };
                  });
                }}
              />
              <label htmlFor="sendQuestionOption1" className="form-label">
                Não
              </label>
            </div>
          </Col>

          {!sendQuestion && (
            <Col md={4}>
              <label className="form-label">Agendar para o dia *</label>
              <input
                className="form-control"
                type="datetime-local"
                disabled={!props.editable}
                defaultValue={formtDateToInput(props.editSurvey.dateSurvey)}
                onChange={(e) => {
                  setFormData((state) => {
                    return {
                      ...state,
                      dateToSend: e.target.value,
                    };
                  });
                }}
              />
              {formErros.dateToSend && (
                <p style={{ color: "red" }}>{formErros.dateToSend}</p>
              )}
            </Col>
          )}
        </Row>

        <Row
          style={{
            width: "100%",
            margin: "auto",
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "1rem",
          }}
        >
          <Button
            color="danger"
            style={{ width: 80, marginRight: 10 }}
            onClick={() => toggle()}
          >
            Fechar
          </Button>

          <ButtonSpinner
            isLoading={isLoading}
            title={"Salvar"}
            onClick={verifyErrros}
          />
        </Row>
      </ModalBody>
    </Modal>
  );
}

const mapStateToProps = function (state) {
  return { state: state };
};

export default connect(mapStateToProps)(ModalNewSurvey);
