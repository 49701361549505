import {
  Table,
  Card,
  CardBody,
  CardTitle,
  Button,
  Collapse,
  Row,
} from "reactstrap";
import SectionComponent from "../../../Apps/Personalization/SectionComponent";
import { useEffect, useState } from "react";
import SearchFilters from "../../../../components/SearchFilters";
import { ModalNewDepartament } from "./ModalNewDepartament";

import { connect } from "react-redux";
import {
  deleteDepartment,
  fetchAttendeeReports,
  fetchDepartments,
  fetchEstoqueReports,
  fetchRegistersReports,
} from "../../../../utils/services/portalBoticario";
import Pagination from "../../../../components/Paginationv3";
import { ModalEditDepartament } from "./ModalEditDepartament";
import SweetAlert from "react-bootstrap-sweetalert";
import WaitExport from "../../../../components/WaitExport";
import { createXlsxDepartaments } from "./createXlsx";
import { useLocation } from "react-router-dom";
import { createXlsxStock } from "./createStockXlsx";
import { createRegistersXlsx } from "./createRegistersXlsx";
import { createParticipantsXlsx } from "./createParticipantsXlsx";

const QuotasPerDepartament = (props) => {
  const location = useLocation();

  const token = props.state.global_user_data.data.token;
  const idEvent = location.state.idEvent;

  const [departaments, setDepartaments] = useState([]);
  const [departamentEdit, setDepartamentEdit] = useState([]);
  const [confirmDelete, setConfirmDelete] = useState(false);

  const [newDepartament, setNewDepartament] = useState(false);
  const [showDepartamentEdit, setShowDepartamentEdit] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const [collapseIsOpen, setCollapseIsOpen] = useState(false);
  const [search, setSearch] = useState("");

  const [countData, setCountData] = useState(1);
  const [currentIndex, setCurrentIndex] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const [exportInProgress, setExportInProgress] = useState(false);
  const [finishExport, setFinishExport] = useState(false);

  const columnsFilter = [
    { name: "Nome", value: "name", type: "text" },
    { name: "Código", value: "code", type: "text" },
  ];

  const getDepartments = async () => {
    try {
      const data = await fetchDepartments(
        token,
        currentIndex > 0 ? currentIndex : 1,
        itemsPerPage,
        search,
        "",
        idEvent
      );
      setDepartaments(data);
    } catch (error) {
      console.error("Erro ao obter departamentos:", error);
    }
  };

  useEffect(() => {
    getDepartments();
  }, [search]);

  const [reportStock, setReportStock] = useState([]);
  const [reportAttendee, setReportAttendee] = useState([]);
  const [reportRegisters, setReportRegisters] = useState([]);

  const getReportStock = async () => {
    try {
      const data = await fetchEstoqueReports(token, idEvent);
      setReportStock(data);
    } catch (error) {
      console.error("Erro ao obter estoque:", error);
    }
  };

  const getReportAttendee = async () => {
    try {
      const data = await fetchAttendeeReports(token, idEvent);
      setReportAttendee(data);
    } catch (error) {
      console.error("Erro ao obter estoque:", error);
    }
  };

  const getReportsRegisters = async () => {
    try {
      const data = await fetchRegistersReports(token, idEvent);
      setReportRegisters(data);
    } catch (error) {
      console.error("Erro ao obter estoque:", error);
    }
  };

  useEffect(() => {
    getReportStock();
    getReportAttendee();
    getReportsRegisters();
  }, [idEvent]);

  useEffect(() => {
    console.log({ reportAttendee });
  }, [reportAttendee]);

  return (
    <>
      {newDepartament && (
        <ModalNewDepartament
          isOpen={newDepartament}
          toggle={() => setNewDepartament(false)}
          jwt={token}
          refetch={getDepartments}
          eventId={idEvent}
        />
      )}

      {showDepartamentEdit && (
        <ModalEditDepartament
          isOpen={showDepartamentEdit}
          toggle={() => setShowDepartamentEdit(false)}
          jwt={token}
          departament={departamentEdit}
          refetch={getDepartments}
        />
      )}

      {confirmDelete && (
        <SweetAlert
          title="Deseja deletar esse departamento?"
          warning
          showCancel
          confirmBtnText="Sim"
          cancelBtnText="Não"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={async () => {
            try {
              await deleteDepartment(departamentEdit.id, token);
              await getDepartments();
              setConfirmDelete(false);
              setShowSuccessAlert(true);
            } catch (error) {
              console.error("Erro ao deletar o departamento:", error);
            }
          }}
          onCancel={() => setConfirmDelete(false)}
        >
          Você não poderá reverter essa ação.
        </SweetAlert>
      )}

      <WaitExport
        isOpen={exportInProgress}
        name="Departamentos"
        preposicao="dos"
        finishExport={finishExport}
        setFinishExport={setFinishExport}
        setExportInProgress={setExportInProgress}
      />

      <div>
        <Card>
          <CardBody>
            <CardTitle>Cotas por Departamento</CardTitle>
            <div>
              <Collapse isOpen={collapseIsOpen}>
                <SearchFilters columns={columnsFilter} />
              </Collapse>
            </div>

            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                marginBottom: 10,
              }}
            >
              <form
                className="app-search d-none d-lg-block events-input-search"
                onSubmit={(e) => e.preventDefault()}
              >
                <div className="position-relative">
                  <input
                    type={search.includes("date") ? "date" : "text"}
                    value={search}
                    className="form-control"
                    placeholder="Pesquisar..."
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <span className="bx bx-search-alt" />
                </div>
              </form>
              <div className="mobile-btn-section" style={{ width: "100%" }}>
                <Row className="mobile-btns-top">
                  <form
                    className="app-search"
                    style={{ padding: "0px" }}
                    onSubmit={(e) => e.preventDefault()}
                  >
                    <div className="position-relative">
                      <input
                        type="text"
                        value={search}
                        className="form-control"
                        placeholder="Pesquisar..."
                        onChange={(e) => setSearch(e.target.value)}
                      />
                      <span className="bx bx-search-alt" />
                    </div>
                  </form>
                </Row>
                <SectionComponent
                  sectionName="Opções"
                  setIsOpen={setIsOpen}
                  isOpen={isOpen}
                />

                <Collapse isOpen={isOpen}>
                  {reportAttendee && reportAttendee.length > 0 && (
                    <Row className="mobile-btns-top">
                      <div
                        onClick={() =>
                          createParticipantsXlsx(
                            token,
                            reportAttendee.length,
                            countData,
                            setFinishExport,
                            idEvent
                          ) > setExportInProgress(true)
                        }
                      >
                        Exportar Participantes
                      </div>
                    </Row>
                  )}

                  {reportRegisters && reportRegisters.length > 0 && (
                    <Row className="mobile-btns-top">
                      <div
                        onClick={() =>
                          createRegistersXlsx(
                            token,
                            reportRegisters.length,
                            countData,
                            setFinishExport,
                            idEvent
                          ) > setExportInProgress(true)
                        }
                      >
                        Exportar Registradores
                      </div>
                    </Row>
                  )}

                  {reportStock && reportStock.length > 0 && (
                    <Row className="mobile-btns-top">
                      <div
                        onClick={() =>
                          createXlsxStock(
                            token,
                            reportStock.length,
                            countData,
                            setFinishExport,
                            idEvent
                          ) > setExportInProgress(true)
                        }
                      >
                        Exportar Estoque
                      </div>
                    </Row>
                  )}

                  {departaments && departaments.length > 0 && (
                    <Row className="mobile-btns-top">
                      <div
                        onClick={() =>
                          createXlsxDepartaments(
                            token,
                            100,
                            search,
                            countData,
                            setFinishExport,
                            idEvent
                          ) > setExportInProgress(true)
                        }
                      >
                        Exportar Departamentos
                      </div>
                    </Row>
                  )}

                  <Row className="mobile-btns-top">
                    <div onClick={() => setNewDepartament(true)}>
                      Novo Departamento
                    </div>
                  </Row>
                </Collapse>
              </div>

              <div className="header-btns-container">
                {reportAttendee && reportAttendee.length > 0 && (
                  <Button
                    color="success"
                    className="btn-rounded waves-effect waves-light events-button-add-lg"
                    onClick={() =>
                      createParticipantsXlsx(
                        token,
                        reportAttendee.length,
                        countData,
                        setFinishExport,
                        idEvent
                      ) > setExportInProgress(true)
                    }
                  >
                    Exportar Participantes
                  </Button>
                )}

                {reportRegisters && reportRegisters.length > 0 && (
                  <Button
                    color="success"
                    className="btn-rounded waves-effect waves-light events-button-add-lg"
                    onClick={() =>
                      createRegistersXlsx(
                        token,
                        reportRegisters.length,
                        countData,
                        setFinishExport,
                        idEvent
                      ) > setExportInProgress(true)
                    }
                  >
                    Exportar Registradores
                  </Button>
                )}

                {reportStock && reportStock.length > 0 && (
                  <Button
                    color="success"
                    className="btn-rounded waves-effect waves-light events-button-add-lg"
                    onClick={() =>
                      createXlsxStock(
                        token,
                        reportStock.length,
                        countData,
                        setFinishExport,
                        idEvent
                      ) > setExportInProgress(true)
                    }
                  >
                    Exportar Estoque
                  </Button>
                )}

                {departaments && departaments.length > 0 && (
                  <Button
                    color="success"
                    className="btn-rounded waves-effect waves-light events-button-add-lg"
                    onClick={() =>
                      createXlsxDepartaments(
                        token,
                        100,
                        search,
                        countData,
                        setFinishExport,
                        idEvent
                      ) > setExportInProgress(true)
                    }
                  >
                    Exportar Departamentos
                  </Button>
                )}

                <Button
                  color="success"
                  className="btn-rounded waves-effect waves-light events-button-add"
                  onClick={() => setNewDepartament(true)}
                >
                  Novo Departamento
                </Button>
              </div>
            </div>

            <div className="table-responsive">
              <Table className="table mb-0">
                <thead className="thead-light">
                  <tr>
                    <th>Nome</th>
                    <th>Código</th>
                    <th>Quantidade Disponível</th>
                    <th>Data Limite</th>
                  </tr>
                </thead>
                <tbody>
                  {departaments?.map((departament, index) => (
                    <tr key={index}>
                      <td>{departament.name}</td>
                      <td>{departament.code}</td>
                      <td>{departament.available}</td>
                      <td>
                        {new Date(departament.untilat).toLocaleDateString()}
                      </td>
                      <td>
                        <i
                          className={"mdi mdi-pencil"}
                          style={{
                            color: "green",
                            fontSize: 17,
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setDepartamentEdit(departament);
                            setShowDepartamentEdit(true);
                          }}
                        />
                        <i
                          className="mdi mdi-trash-can"
                          style={{
                            color: "red",
                            fontSize: 17,
                            cursor: "pointer",
                            minHeight: "100px",
                          }}
                          onClick={async () => {
                            setDepartamentEdit(departament);
                            setConfirmDelete(true);
                          }}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </CardBody>
        </Card>

        {showSuccessAlert && (
          <SweetAlert
            title="Deletado"
            success
            onConfirm={() => setShowSuccessAlert(false)}
          >
            Departamento deletado!
          </SweetAlert>
        )}

        <Pagination
          data={departaments}
          currentIndex={currentIndex}
          setCurrentIndex={setCurrentIndex}
          countData={countData}
          maxItemPerPage={itemsPerPage}
          setMaxItemPerPage={setItemsPerPage}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return { state };
};

export default connect(mapStateToProps)(QuotasPerDepartament);
