import React, { useEffect, useState } from 'react';

import { Input, Label, UncontrolledTooltip } from 'reactstrap';

export default function ThermDisplay({ attributes, rowId, colId, setValue }) {
  const [selected, setSelected] = useState('');

  useEffect(() => {
    const html = attributes.label;
    const div = document.getElementsByClassName(`therm-label-${rowId}-${colId}`);
  
    for (const element of div) {
      element.innerHTML = html;
    }
  }, [attributes, rowId, colId]);

  useEffect(() => {
    setValue((oldValue) => {
      const newValue = [...oldValue];
      newValue[rowId].cols[colId].attributes.value = selected;
      if (attributes.required && !selected) {
        newValue[rowId].cols[colId].attributes.errorMessage = `O ${attributes.inputName} deve ser aceito`;
      } else {
        newValue[rowId].cols[colId].attributes.errorMessage = '';
      }

      return newValue;
    });
  }, [selected, setValue, rowId, colId, attributes.required, attributes.inputName]);

  return (
    <div id="therm-input-component">
      <div className="custom-input-container">
        <div>
          <div className="displayed-config-container">
            <div className="displayed-label">
              <Input
                className="input-mg-rgt"
                type="checkbox"
                id="checkbox-input"
                onChange={(e) => setSelected(e.target.checked)}
              />
              <Label htmlFor="checkbox-input" className={`form-label therm-label-${rowId}-${colId}`} />
              {attributes.hasTip && (
                <div>
                  <div className="show-tips" id={`therm-tip-${rowId}-${colId}`}>
                    <span>?</span>
                  </div>
                  <UncontrolledTooltip style={{ backgroundColor: '#6f42c1' }} placement="right" target={`therm-tip-${rowId}-${colId}`}>
                    {attributes.tipText}
                  </UncontrolledTooltip>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
