import React, { useState, useEffect } from "react";
import { Table, Row, Col, Collapse, Button } from "reactstrap";

//paginação
import Pagination from "../../../../components/Paginationv3";
// sessão mobile
import SectionComponent from "../../../Apps/Personalization/SectionComponent";
// nuvem de palavras
import ModalWordCloud from "./wordCloud";
// alerta
import SweetAlert from "react-bootstrap-sweetalert";
// service
import {
  getAsk,
  disableAsk,
} from "../../../../utils/services/dashboardSuperScreen";
// carregamento de tabela
import LoadingSkelentonTable from "../../../../components/loadingSkeletonTable";
// exportar tabela
import { createXlsx } from "./exports/createXlsxQuestion";
// usedebounce
import useDebounce from "../../../../utils/hooks/useDebounce";
// componente de carregamento de exportação
import WaitExport from "../../../../components/WaitExport";
// moment
import moment from "moment";

export default function TableQuestion(props) {
  const eventId = props.idEvent;
  const transmissionId = props.idDashboard;
  // pesquisa da listagem
  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounce(search, 300);
  //paginação
  const [currentIndex, setCurrentIndex] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [countData, setCountData] = useState(0);
  // modal collapse
  const [isOpen, setIsOpen] = useState(false);
  // modal da nuvem de palavras
  const [modalWordCloud, setModalWordCloud] = useState(false);
  // export in progress
  const [exportInProgress, setExportInProgress] = useState(false);
  const [finishExport, setFinishExport] = useState(false);
  //deletar/desativar enquete
  const [deleteData, setDeleteData] = useState({});
  const [confirm_alert, setconfirm_alert] = useState(false);
  const [success_dlg, setsuccess_dlg] = useState(false);
  // carregamento do botão
  const [loading, setLoading] = useState(false);

  function formatDate(date) {
    const dateFormat = date.split("T")[0].split("-").reverse().join("/");
    const time = date.split("T")[1].slice(0, 5);
    return `${dateFormat} - ${time}`;
  }

  function exportReport() {
    createXlsx({
      eventId: eventId,
      transmissionID: transmissionId,
      limit: itemsPerPage,
      offset: currentIndex - 1,
      search: "",
      countData: countData,
      setFinishExport,
      mobileapps: props.mobileapps,
    });
    setExportInProgress(true);
  }

  async function funcDeleteAsk() {
    setLoading(true);
    await disableAsk({
      eventId: eventId,
      transmissionID: transmissionId,
      askId: deleteData.id,
      mobileapps: props.mobileapps,
    }).then((res) => {
      if (res.success === true) {
        setconfirm_alert(false);
        setsuccess_dlg(true);
      }
    });
    setLoading(false);
  }

  function getAllAsk() {
    getAsk({
      eventId: eventId,
      transmissionID: transmissionId,
      pageSize: itemsPerPage,
      offset: currentIndex - 1,
      search: debouncedSearch,
      mobileapps: props.mobileapps,
    }).then((res) => {
      // console.log("question", res);
      if (res.success) {
        setCountData(res.total);
        props.setTableData((state) => {
          return {
            ...state,
            question: {
              loading: false,
              data: res.data,
            },
          };
        });
      }
    });
    // .catch((error) => console.log("error", error));
  }

  function resetPagination() {
    setSearch("");
    setItemsPerPage(10);
    setCurrentIndex(1);
    if (props.mobileapps) {
      getAllAsk();
    }
  }

  useEffect(() => {
    if (props.mobileapps) {
      getAllAsk();
    }
  }, [debouncedSearch, itemsPerPage, currentIndex, props.mobileapps]);

  return (
    <div>
      <WaitExport
        isOpen={exportInProgress}
        name="Relatório"
        preposicao="dos"
        finishExport={finishExport}
        setFinishExport={setFinishExport}
        setExportInProgress={setExportInProgress}
      />

      {modalWordCloud && (
        <ModalWordCloud
          isOpen={modalWordCloud}
          toggle={() => setModalWordCloud(!modalWordCloud)}
          eventId={eventId}
          transmissionID={transmissionId}
          type={"asks"}
          mobileapps={props.mobileapps}
        />
      )}

      {confirm_alert ? (
        <SweetAlert
          title={`Deseja deletar a pergunta ${deleteData.msg}?`}
          warning
          showConfirm={false}
          showCancel={false}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <a style={{ marginBottom: 10 }}>
              Você não poderá reverter essa ação!
            </a>
            {loading ? (
              <a
                className="downloadCloud"
                style={{ backgroundColor: "grey", width: 120 }}
              >
                <i className="bx bx-loader bx-spin font-size-16 align-middle me-2" />
                Carregando
              </a>
            ) : (
              <div style={{ display: "flex" }}>
                <Button
                  color="success"
                  className="btn btn-success waves-effect waves-light"
                  style={{ width: 100, marginRight: 10 }}
                  onClick={() => funcDeleteAsk()}
                >
                  Confirmar
                </Button>
                <Button
                  color="danger"
                  className="btn btn-danger waves-effect waves-light"
                  style={{ width: 100 }}
                  onClick={() => setconfirm_alert(false)}
                >
                  Cancelar
                </Button>
              </div>
            )}
          </div>
        </SweetAlert>
      ) : null}

      {success_dlg ? (
        <SweetAlert
          success
          title="Deletada"
          onConfirm={() => {
            getAllAsk();
            setsuccess_dlg(false);
          }}
        >
          Pergunta deletada com sucesso
        </SweetAlert>
      ) : null}
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          margin: "10px 0",
        }}
      >
        <form
          className="app-search d-none d-lg-block events-input-search"
          onSubmit={(e) => e.preventDefault()}
        >
          <div className="position-relative">
            <input
              type="text"
              className="form-control"
              placeholder="Pesquisar..."
              value={search}
              onChange={(e) => setSearch(e.target.value.toLowerCase())}
            />
            <span className="bx bx-search-alt" />
          </div>
        </form>

        <div className="mobile-btn-section" style={{ width: "100%" }}>
          <Row className="mobile-btns-top">
            <form
              className="app-search"
              style={{ padding: "0px" }}
              onSubmit={(e) => e.preventDefault()}
            >
              <div className="position-relative">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Pesquisar..."
                  value={search}
                  onChange={(e) => setSearch(e.target.value.toLowerCase())}
                />
                <span className="bx bx-search-alt" />
              </div>
            </form>
          </Row>

          {props.tableData.question.data.length > 0 && (
            <>
              <SectionComponent
                sectionName="Opções"
                setIsOpen={setIsOpen}
                isOpen={isOpen}
              />
              <Collapse isOpen={isOpen}>
                <Row className="mobile-btns-top">
                  <div onClick={() => setModalWordCloud(true)}>
                    Nuvem de Palavras
                  </div>
                  <div onClick={() => exportReport()}>Exportar Perguntas</div>
                </Row>
              </Collapse>
            </>
          )}
        </div>

        <div className="header-btns-container">
          {props.tableData.question.data.length > 0 && (
            <>
              <Button
                color="success"
                className="btn-rounded waves-effect waves-light events-button-add"
                onClick={() => setModalWordCloud(true)}
              >
                Nuvem de Palavras
              </Button>

              <Button
                color="success"
                className="btn-rounded waves-effect waves-light events-button-add"
                onClick={() => exportReport()}
              >
                Exportar Perguntas
              </Button>
            </>
          )}
        </div>
      </div>

      <div
        style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
      >
        <a className="btn-reset-table" onClick={() => resetPagination()}>
          <i className="mdi mdi-refresh" style={{ marginRight: 5 }} />
          Refresh
        </a>
      </div>

      <div className="table-responsive" style={{ backgroundColor: "white" }}>
        <Table className="table mb-0">
          <thead className="table-light">
            <tr>
              <th>ID do Participante</th>
              <th>Nome</th>
              <th>Mensagem</th>
              <th>Data e hora</th>
              <th>Ação</th>
            </tr>
          </thead>

          <tbody>
            {props.tableData.question.loading ? (
              <LoadingSkelentonTable lines={3} cols={5} />
            ) : (
              props.tableData.question.data.map((e, i) => (
                <tr key={i}>
                  <td>{e.userID}</td>
                  <td>{e.name}</td>
                  <td>{e.msg}</td>
                  <td>{formatDate(moment(e.date).format())}</td>
                  <td>
                    <i
                      className="mdi mdi-trash-can"
                      style={{
                        color: "red",
                        fontSize: 17,
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setDeleteData(e);
                        setconfirm_alert(true);
                      }}
                    />
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </Table>
      </div>
      <Row style={{ marginTop: 20 }}>
        <Col md={12}>
          <Pagination
            data={props.tableData.question.data}
            search={search}
            currentIndex={currentIndex}
            setCurrentIndex={setCurrentIndex}
            countData={countData}
            maxItemPerPage={itemsPerPage}
            setMaxItemPerPage={setItemsPerPage}
            maxPageButtonsToShow={3}
          />
        </Col>
      </Row>
    </div>
  );
}
