import React, { useState } from 'react';

import { Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalHeader, Row, UncontrolledTooltip } from 'reactstrap';

export default function PasswordInput({ deleteComponent, rowId, colId, attributes, setAttributes }) {
  const [inputName, setInputName] = useState(attributes ? attributes.inputName : '');
  const [label, setLabel] = useState(attributes ? attributes.label : 'Senha');
  const [placeholder, setPlaceholder] = useState(attributes ? attributes.placeholder : 'Digite sua senha');
  const [tipText, setTipText] = useState(attributes ? attributes.tipText : 'Texto dica');
  const [required, setRequired] = useState(attributes ? attributes.required : false);
  const [hasTip, setHasTip] = useState(attributes ? attributes.hasTip : false);

  // validation
  const [maxCharacters, setMaxCharacters] = useState(attributes ? attributes.maxCharacters : 0);
  const [minCharacters, setMinCharacters] = useState(attributes ? attributes.minCharacters : 0);
  const [hasValidation, setHasValidation] = useState(attributes ? attributes.hasValidation : false);

  // error message
  const [errorMessage, setErrorMessage] = useState(false);

  // Mask
  const [maskedValue, setMaskedValue] = useState('');

  // Edit Modal
  const [editModal, setEditModal] = useState(false);

  const validate = () => {
    if (inputName === '') {
      setErrorMessage('O campo "Nome do campo" é obrigatório');
      return false;
    }

    if (hasValidation) {
      if (maxCharacters < minCharacters) {
        setErrorMessage('A quantidade Máxima de caracteres deve ser maior ou igual a quantidade Mínima de caracteres');
        return false;
      }
  
      if (minCharacters < 1) {
        setErrorMessage('A quantidade Mínima de caracteres deve ser maior que 0');
        return false;
      }
    }

    return true;
  };


  const submitForm = (e) => {
    e.preventDefault();
    if (!validate()) {
      return;
    }

    setErrorMessage(false);

    setAttributes({
      rowId,
      colId,
      attributes: {
        inputName,
        label,
        placeholder,
        tipText,
        hasTip,
        required,
        maxCharacters,
        minCharacters,
        hasValidation,
      },
    });
    setEditModal(false);
  };

  return (
    <div>
      <div className="custom-input-container">
        <Modal
          size="lg"
          isOpen={editModal}
          toggle={() => setEditModal(!editModal)}
        >
          <ModalHeader toggle={() => setEditModal(false)}>
            Configurar campo Senha
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col>
                <Form>
                  <FormGroup className="input-margin-btm">
                    <Label htmlFor="edit-input-name" className="form-label">
                      Nome do campo
                    </Label>
                    <Input
                      id="edit-input-name"
                      className="form-control"
                      type="text"
                      placeholder="Nome do campo"
                      value={inputName}
                      onChange={({ target }) => setInputName(target.value)}
                    />
                  </FormGroup>
                  <FormGroup className="input-margin-btm">
                    <Label htmlFor="edit-input-text" className="form-label">
                      Nome da label
                    </Label>
                    <Input
                      id="edit-input-text"
                      className="form-control"
                      type="text"
                      value={label}
                      onChange={({ target }) => setLabel(target.value)}
                    />
                  </FormGroup>
                  <FormGroup className="input-margin-btm">
                    <Label htmlFor="edit-input-placeholder" className="form-label">
                      Texto do placeholder
                    </Label>
                    <Input
                      id="edit-input-placeholder"
                      className="form-control"
                      type="text"
                      value={placeholder}
                      onChange={({ target }) => setPlaceholder(target.value)}
                    />
                  </FormGroup>
                  <FormGroup className="input-margin-btm">
                    <Input
                      className="input-mg-rgt"
                      id="edit-input-has-tip"
                      type="checkbox"
                      checked={hasTip}
                      onChange={({ target }) => setHasTip(target.checked)}
                    />
                    <Label htmlFor="edit-input-has-tip" className="form-label">
                      Possui dica?
                    </Label>
                    {hasTip && (
                      <FormGroup>
                        <Label htmlFor="edit-input-tip" className="form-label">
                          Texto da dica
                        </Label>
                        <Input
                          id="edit-input-tip"
                          className="form-control"
                          type="text"
                          value={tipText}
                          onChange={({ target }) => setTipText(target.value)}
                        />
                      </FormGroup>
                    )}
                  </FormGroup>
                  <FormGroup className="input-margin-btm">
                    <Input
                      className="input-mg-rgt"
                      id="edit-input-required"
                      type="checkbox"
                      checked={required}
                      onChange={({ target }) => setRequired(target.checked)}
                    />
                    <Label htmlFor="edit-input-required" className="form-label">
                      Campo obrigatório?
                    </Label>
                  </FormGroup>
                  <FormGroup className="input-margin-btm">
                    <Input
                      className="input-mg-rgt"
                      id="edit-input-has-validation"
                      type="checkbox"
                      checked={hasValidation}
                      onChange={({ target }) => setHasValidation(target.checked)}
                    />
                    <Label htmlFor="edit-input-has-validation" className="form-label">
                      Possui validação?
                    </Label>
                  </FormGroup>
                  {hasValidation && (
                    <div>
                      <FormGroup>
                        <Label htmlFor="edit-input-validation" className="form-label">
                          Quantidade miníma de caracteres
                        </Label>
                        <Input
                          id="edit-input-validation"
                          className="form-control"
                          type="number"
                          min={1}
                          value={minCharacters}
                          onChange={({ target }) => setMinCharacters(target.value)}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label htmlFor="edit-input-validation" className="form-label">
                          Quantidade máxima de caracteres
                        </Label>
                        <Input
                          id="edit-input-validation"
                          className="form-control"
                          type="number"
                          min={1}
                          value={maxCharacters}
                          onChange={({ target }) => setMaxCharacters(target.value)}
                        />
                      </FormGroup>
                    </div>
                  )}
                </Form>
              </Col>
              <Col style={{borderLeft: '1px solid gray'}}>
                <div style={{ display: 'flex' }}>
                  <Label className="form-label" htmlFor="formText">
                    {label === "Senha" ? label : <div dangerouslySetInnerHTML={{__html: label}} />}
                  </Label>
                  {hasTip && (
                    <div>
                      <div className="show-tips" id="password-input-tip">
                        <span>?</span>
                      </div>
                      <UncontrolledTooltip style={{ backgroundColor: '#6f42c1' }} placement="right" target="password-input-tip">
                        {tipText}
                      </UncontrolledTooltip>
                    </div>
                  )}
                </div>
                <Input
                  className="form-control"
                  type="password"
                  id="formText"
                  placeholder={placeholder}
                  value={maskedValue}
                  onChange={({ target }) => setMaskedValue(target.value)}
                />
              </Col>
            </Row>
            {errorMessage && (
              <p style={{color: 'red'}}>{errorMessage}</p>
            )}
            <div className="save-btn-form-container">
              <Button
                color="success"
                className="btn btn-success waves-effect waves-light save-btn-form"
                onClick={submitForm}
              >
                Salvar
              </Button>
            </div>
          </ModalBody>
        </Modal>
        <div>
          <div className="displayed-config-container">
            <div className="displayed-label">
              <Label className="form-label" htmlFor="formText">
                {label === "Senha" ? label : <div dangerouslySetInnerHTML={{__html: label}} />}
              </Label>
              {hasTip && (
                <div>
                  <div className="show-tips" id="password-display-tip">
                    <span>?</span>
                  </div>
                  <UncontrolledTooltip style={{ backgroundColor: '#6f42c1' }} placement="right" target="password-display-tip">
                    {tipText}
                  </UncontrolledTooltip>
                </div>
              )}
            </div>
            <div className="config-form-icon">
              <span
                className="mdi mdi-cog-outline"
                onClick={() => setEditModal(!editModal)}
              />
              <span
                className="mdi mdi-close close-icon"
                title="Remover Campo"
                onClick={() => deleteComponent(rowId, colId)}
              />
            </div>
          </div>
          <Input
            className="form-control"
            type="password"
            id="formText"
            placeholder={placeholder}
            value={maskedValue}
            onChange={({ target }) => setMaskedValue(target.value)}
          />
        </div>
      </div>
    </div>
  )
}
