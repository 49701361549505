import React from "react";
import { useUserPermissions } from "../../utils/hooks/useUserPermissions";

const DeleteButton = ({
  onClick,
  color = "red",
  fontSize = 17,
  cursor = "pointer",
  style = {},
  ...props
}) => {
  const { canDelete } = useUserPermissions();
  if (!canDelete()) return;
  return (
    <>
      {canDelete() && (
        <i
          className="mdi mdi-trash-can"
          style={{
            color,
            fontSize,
            cursor,
            ...style,
          }}
          onClick={onClick}
          {...props}
        />
      )}
    </>
  );
};

export default DeleteButton;
