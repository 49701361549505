import writeXlsxFile from "write-excel-file";
import { fetchEstoqueReports } from "../../../../utils/services/portalBoticario";

export async function createXlsxStock(
  jwt,
  limit,
  countData,
  setFinishExport,
  idEvent
) {
  //   const formatDate = (date) => {
  //     return date.split("T")[0].split("-").reverse().join("-");
  //   };

  const schema = [
    {
      column: "Nome",
      type: String,
      value: (data) => data.code,
    },
    {
      column: "Total",
      type: Number,
      value: (data) => data.available,
    },
    {
      column: "Quantidade Utilizada",
      type: Number,
      value: (data) => data.registered,
    },
    {
      column: "Quantidade Disponível",
      type: Number,
      value: (data) => data.remaining,
    },
  ];

  const allRequestsPromises = [];
  const numberOfRequisitions = Math.ceil(countData / limit);

  for (let i = 1; i <= numberOfRequisitions; i += 1) {
    const request = await fetchEstoqueReports(jwt, idEvent);
    allRequestsPromises.push(request);
    setTimeout(() => {}, 500);

    const progressBar = document.querySelector(".export-progress");
    const percentageBar = document.querySelector(".export-percentage");

    progressBar.style.width = `${(i / numberOfRequisitions) * 100}%`;
    percentageBar.innerHTML = `${((i / numberOfRequisitions) * 100).toFixed(
      0
    )}%`;

    if (i === numberOfRequisitions) {
      setTimeout(() => {
        setFinishExport(true);
      }, 1000);
    }
  }

  const allDataInSingleArray = allRequestsPromises.flat();
  if (allDataInSingleArray) {
    await writeXlsxFile(allDataInSingleArray, {
      schema,
      fileName: "estoque_boticario_cotas.xlsx",
    });
  }
}
