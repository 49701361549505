import React, { useEffect, useRef, useState } from 'react';

import { Col, Row, Card, CardText, Form, CardBody } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';

import { createLoginGroup } from '../../../utils/services/logingroup';

import Dropzone from 'react-dropzone';
import ModeloUpload from '../../../assets/statics/modelo_grupos_participante.xlsx';

import SweetAlert from 'react-bootstrap-sweetalert';
import readXlsxFile from 'read-excel-file';
import {
  allExcelColumns,
  excelColumnsPtBr,
  findRowsWithError,
} from './importGroupsValidation';
import ModalExcelRows from '../Voo/ModalExcelRows';

export default function ImportGroup(props) {
  const { isOpen, toggle, active, idEvent, token } = props;
  const [modalBackdrop, setModalBackdrop] = useState(false);
  const [selectedFiles, setselectedFiles] = useState([]);
  const [success_dlg, setsuccess_dlg] = useState(false);
  const [imgError, setImgError] = useState('');

  // rows of excel file
  const [totalRows, setTotalRows] = useState(0);
  const [totalSended, setTotalSended] = useState(0);

  // modal de erros no arquivo xlsx
  const [modalErrors, setModalErrors] = useState(false);
  const [errors, setErrors] = useState([]);
  const [btnSend, setBtnSend] = useState(false);
  const [btnCancel, setBtnCancel] = useState(false);

  const importActivated = useRef(false);

  let timerID = null;

  function toggleBackdrop() {
    setModalBackdrop(!modalBackdrop);
  }

  useEffect(() => {
    setselectedFiles([]);
    setImgError('');
  }, [isOpen]);

  function handleAcceptedFiles(files) {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    setselectedFiles(files);
    setTotalRows(0);
    setTotalSended(0);
    setBtnSend(true);
    clearTimeout(timerID);
  }

  function cancelImport() {
    importActivated.current = false;
    active(importActivated.current);
    setselectedFiles([]);
    setTotalRows(0);
    setTotalSended(0);
    setBtnSend(false);
    setBtnCancel(false);
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }
  const sleep = (ms) => new Promise((r) => setTimeout(r, ms));
  async function uploadService() {
    importActivated.current = true;
    active(importActivated.current);
    clearTimeout(timerID);
    setBtnSend(false);
    setBtnCancel(true);

    const schema = {
      email: {
        prop: 'email',
        type: String,
      },
      grupo: {
        prop: 'group',
        type: String,
      },
    };

    let count = 0;

    const readXlsx = async () => {
      const { rows } = await readXlsxFile(selectedFiles[0], { schema });
      const validateRows = rows.map((row, i) => allExcelColumns(row, i));

      const [rowsWithError, validRows] = await findRowsWithError(
        validateRows,
        token,
        props.idEvent
      );
      setTotalRows(validRows.length);

      if (rowsWithError.length > 0 || rows.length === 0) {
        setErrors(rowsWithError);
        importActivated.current = false;
        active(importActivated.current);
        return setModalErrors(true);
      }

      let i = 0;
      for (const row of validRows) {
        await sleep(100);
        if (!importActivated.current) {
          break;
        }
        importLoginGroups(row, i, validRows.length, props.token);
        i++;
      }
    };

    readXlsx();

    async function importLoginGroups(row, index, totalRows, token) {
      await createLoginGroup(
        {
          email: row.email,
          groups: row?.group.includes(',') ? row.group.split(',') : row?.group,
          eventId: props.idEvent,
        },
        token
      ).then((e) => {
        if (e.message === 'SENDED_TO_QUEUE') {
          setTotalSended(++count);
        }
        if (totalRows === ++index) {
          setBtnCancel(false);
        }

        if (count === totalRows) {
          setsuccess_dlg(true);
          props.getAllParticipants();
        }
      });
      // .catch((error) => console.log(error));
    }
  }

  return (
    <div
      isOpen={isOpen}
      toggle={() => toggleBackdrop()}
      autoFocus={true}
      centered={true}
    >
      {success_dlg ? (
        <SweetAlert
          success
          title={'Upload'}
          onConfirm={() => {
            props.getAllParticipants();
            setsuccess_dlg(false);
            importActivated.current = false;
            active(importActivated.current);
            toggle();
          }}
        >
          {'O arquivo foi enviado com sucesso!'}
        </SweetAlert>
      ) : null}
      {modalErrors && (
        <ModalExcelRows
          toggle={() => setModalErrors(false)}
          rows={errors.sort((a, b) => a.index - b.index)}
          columnNames={excelColumnsPtBr}
        />
      )}
      <div className="m-1 p-0">
        <Card className="p-1">
          <CardBody className="m-1 p-2">
            <CardText className="mb-2 p-10">
              O arquivo a ser importado deve seguir este{' '}
              <a
                href={ModeloUpload}
                download="modelo_grupos_participantes.xlsx"
              >
                Modelo
              </a>
              , não modifique seu cabeçalho.
            </CardText>

            <Form>
              {selectedFiles.length === 0 && (
                <Dropzone
                  onDrop={(acceptedFiles) => {
                    handleAcceptedFiles(acceptedFiles);
                  }}
                >
                  {({ getRootProps, getInputProps }) => (
                    <div className="dropzone">
                      <div
                        className="dz-message needsclick mt-1"
                        {...getRootProps()}
                      >
                        <input {...getInputProps()} />
                        <div className="mb-4">
                          <i className="display-4 text-muted bx bxs-cloud-upload" />
                          <h4>Arraste o arquivo ou clique aqui e selecione.</h4>
                        </div>
                      </div>
                    </div>
                  )}
                </Dropzone>
              )}
              <div className="dropzone-previews mt-0" id="file-previews">
                {selectedFiles.map((f, i) => {
                  return (
                    <Card
                      className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                      key={i + '-file'}
                    >
                      <div className="p-1">
                        <Row className="align-items-center">
                          <Col xs="11">
                            <strong>{`Nome: ${f.name} - Tamanho: ${f.formattedSize}`}</strong>
                          </Col>
                          <Col xs="1">
                            <span
                              style={{
                                fontSize: '16px',
                                color: 'red',
                                fontWeight: '700',
                                cursor: 'pointer',
                              }}
                              className="mdi mdi-close"
                              onClick={() => {
                                setselectedFiles([]);
                                setBtnSend(false);
                              }}
                            />
                          </Col>
                        </Row>
                        {importActivated.current && (
                          <Row className="align-items-center">
                            <Col>
                              <strong>{`Total enviado: ${totalSended}/${totalRows}`}</strong>
                            </Col>
                          </Row>
                        )}
                      </div>
                    </Card>
                  );
                })}
              </div>
            </Form>

            <div className="text-center mt-3">
              <p style={{ color: 'red' }}>{imgError}</p>
              <button
                type="button"
                className="btn btn-primary waves-effect waves-light"
                disabled={!btnSend}
                onClick={() => uploadService()}
              >
                Enviar Grupos
              </button>
              <button
                type="button"
                className="btn btn-danger mx-1"
                disabled={!btnCancel}
                onClick={() => cancelImport()}
              >
                Cancelar
              </button>
            </div>
          </CardBody>
        </Card>
      </div>
    </div>
  );
}
