import React from "react";
import { Row } from "reactstrap";

const App = ({ data: foundEvents, search, currentIndex, setCurrentIndex }) => {
  return (
    <Row style={{ display: "flex", justifyContent: "flex-end" }}>
      <nav
        aria-label="Page navigation example"
        style={{ display: "flex", justifyContent: "flex-end" }}
      >
        <ul className="pagination">
          <li
            className={
              [
                ...Array(
                  Math.ceil(
                    foundEvents &&
                      foundEvents.filter(
                        (e) =>
                          e.event
                            .toLocaleLowerCase()
                            .indexOf(search.toLocaleLowerCase()) > -1
                      ).length / 20
                  )
                ),
              ].length < 6
                ? "page-item disabled"
                : "page-item"
            }
          >
            <a className="page-link">
              <i className="fas fa-arrow-left"></i>
            </a>
          </li>
          {foundEvents &&
            [
              ...Array(
                Math.ceil(
                  foundEvents &&
                    foundEvents.filter(
                      (e) =>
                        e.event
                          .toLocaleLowerCase()
                          .indexOf(search.toLocaleLowerCase()) > -1
                    ).length / 20
                )
              ),
            ].map((_, i) => (
              <li
                className={
                  currentIndex === i * 20 ? "page-item active" : "page-item"
                }
                key={i}
              >
                <a
                  className="page-link"
                  key={i}
                  onClick={() => {
                    setCurrentIndex(i * 20);
                  }}
                >
                  {i + 1}
                </a>
              </li>
            ))}
          <li
            className={
              foundEvents.filter(
                (e) =>
                  e.event
                    .toLocaleLowerCase()
                    .indexOf(search.toLocaleLowerCase()) > -1
              ).length > 100
                ? "page-item"
                : "page-item disabled"
            }
          >
            <a className="page-link">
              <i className="fas fa-arrow-right"></i>
            </a>
          </li>
        </ul>
      </nav>
      <a style={{ width: "auto" }}>Total de items: {foundEvents.length}</a>
    </Row>
  );
};

export default App;
