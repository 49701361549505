import React, { useState } from "react";
// componentes
import ModalEditStatus from "./modalEditStatus";
import ModalListParticipants from "./modalListParticipants";
import ModalListHistoric from "./modalListHistoric";

export default function RowTable(props) {
  const { data, setEditData, setModalEditVehicle, changeStatus } = props;

  // abrir
  const [open, setOpen] = useState(false);
  // modais
  const [modalEditaStatus, setModalEditStatus] = useState(false);
  const [modalHistoric, setModalHistoric] = useState(false);

  const [modalListParticipants, setModalListParticipants] = useState(false);

  const handleChangeStatus = (newStatus, notes) => {
    changeStatus(newStatus, data, notes);
  };

  function isDisabledEdit() {
    if (data?.status === "Cancelado" || data?.status === "Finalizado") {
      return true;
    }
    return false;
  }

  return (
    <>
      {modalEditaStatus && (
        <ModalEditStatus
          isOpen={modalEditaStatus}
          toggle={() => setModalEditStatus(false)}
          changeStatus={handleChangeStatus}
          data={data}
        />
      )}

      {modalListParticipants && (
        <ModalListParticipants
          isOpen={modalListParticipants}
          toggle={() => setModalListParticipants(false)}
          data={data}
        />
      )}

      {modalHistoric && (
        <ModalListHistoric
          isOpen={modalHistoric}
          toggle={() => setModalHistoric(false)}
          data={data}
        />
      )}

      <tr>
        <td>{data.description}</td>
        <td>
          {data.status && (
            <span
              className="tagDriver"
              style={{
                whiteSpace: "nowrap",
                backgroundColor:
                  data.status === "Não Iniciado"
                    ? "gray"
                    : data.status === "Embarcando" ||
                      data.status === "Desembarcando"
                    ? "#03A9F4"
                    : data.status === "Em Trânsito"
                    ? "#FFC107"
                    : data.status === "Finalizado"
                    ? "green"
                    : data.status === "Cancelado"
                    ? "red"
                    : "#607D8B",
              }}
            >
              {data.status}
            </span>
          )}
        </td>

        <td>
          <i
            className="mdi mdi-eye"
            style={{
              color: "blue",
              fontSize: 17,
              cursor: "pointer",
              marginRight: 5,
            }}
            onClick={() => setOpen(!open)}
          />

          <i
            className={"mdi mdi-pencil"}
            style={{
              color: isDisabledEdit() ? "gray" : "green",
              fontSize: 17,
              cursor: isDisabledEdit() ? "not-allowed" : "pointer",
              marginRight: 5,
            }}
            onClick={() => {
              if (!isDisabledEdit()) {
                setEditData(data);
                setModalEditVehicle(true);
              }
            }}
          />

          <i
            className="dripicons-user-group"
            style={{
              color: "blue",
              fontSize: 17,
              cursor: "pointer",
              marginRight: 5,
            }}
            onClick={() => setModalListParticipants(true)}
          />

          <i
            className="mdi mdi-car-arrow-right"
            style={{
              color: isDisabledEdit() ? "gray" : "green",
              fontSize: 17,
              cursor: isDisabledEdit() ? "not-allowed" : "pointer",
              fontSize: 17,
              cursor: "pointer",
            }}
            onClick={() => {
              if (!isDisabledEdit()) {
                setModalEditStatus(true);
              }
            }}
          />
          <i
            className="mdi mdi-note"
            style={{
              marginLeft: "5px",
              color: "blue",
              fontSize: 17,
              cursor: "pointer",
              fontSize: 17,
            }}
            onClick={() => {
              setModalHistoric(true);
            }}
          />
        </td>
        <td></td>
      </tr>

      {open && (
        <tr>
          <td style={{ backgroundColor: "white" }}>De: {data?.origin}</td>
          <td style={{ backgroundColor: "white" }}>Para:{data?.destination}</td>
          <td style={{ backgroundColor: "white" }}>
            Capacidade: {data?.seatingCapacity}
          </td>
          <td style={{ backgroundColor: "white" }}>
            Participantes no Veículo: {data?.amount}
          </td>
        </tr>
      )}
    </>
  );
}
