import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Col,
  Button,
  CardFooter,
  CardBody,
  Card,
} from "reactstrap";

//redux
import { connect, useSelector } from "react-redux";

import SweetAlert from "react-bootstrap-sweetalert";

//service
import { updateProfile } from "../../../utils/services/profile";
import { getUrlFiles } from "../../../utils/services/files";

import ButtonSpinner from "../../../components/Buttons/ButtonSpinner";

function EditProfile(props) {
  const { foundLogin } = useSelector((state) => state.global_user_data.data);

  const { isOpen, toggle } = props;
  const [modalBackdrop, setModalBackdrop] = useState(false);
  const [name, setName] = useState(props.nome);
  const [imageForm, setImageForm] = useState(props.foto);
  const [imageView, setImageView] = useState(props.foto);

  //confirmação
  const [success_dlg, setsuccess_dlg] = useState(false);
  const [photo, setPhoto] = useState(props.foto);
  const [expiresPhoto, setExpiresPhoto] = useState(foundLogin.expireDate);

  const [isLoading, setIsloading] = useState(false);

  function toggleBackdrop() {
    setModalBackdrop(!modalBackdrop);
  }

  const editProfile = async () => {
    var data = new FormData();
    data.append("files", imageForm);
    let expireDate = expiresPhoto;
    let urlPhoto = photo;

    if (props.foto !== imageView) {
      await getUrlFiles(data, props.state.global_user_data.data.token)
        .then((response) => {
          const { url, expires } = response[0];
          urlPhoto = url;
          expireDate = expires;
          setPhoto(url);
          setExpiresPhoto(expires);
        })
        .catch((error) => {
          // console.log(error);
        });
    }

    // eslint-disable-next-line no-useless-escape
    const profileObj = {
      email: props.email,
      nome: name,
      foto: urlPhoto,
      expireDate: expireDate,
    };
    setIsloading(true);

    var profileData = await updateProfile(
      profileObj,
      props.state.global_user_data.data.token
    );

    if (profileData.message === "updated") {
      props.updateProfileRedux(profileData);
      setsuccess_dlg(true);
    }
    setIsloading(true);
  };

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      reader.onload = (e) => {
        setImageView(e.target.result);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  useEffect(() => {
    setImageForm(props.foto);
    setImageView(props.foto);
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => {
        toggleBackdrop();
      }}
      autoFocus={true}
      centered={true}
    >
      {success_dlg ? (
        <SweetAlert
          success
          title={"Editado"}
          onConfirm={() => {
            setsuccess_dlg(false);
            toggle();
          }}
        >
          {"Usuário editado com sucesso!"}
        </SweetAlert>
      ) : null}
      <ModalHeader toggle={toggle}>Editar Perfil</ModalHeader>
      <ModalBody style={{ display: "flex", justifyContent: "center" }}>
        <Col xl="11">
          <Card className="text-center">
            <CardBody>
              <div className="mb-4">
                <img
                  className="rounded-circle"
                  style={{ width: 100 }}
                  src={imageView}
                  alt="profile"
                />
                <label htmlFor="inputImage">
                  <i className="bx bx-pencil" style={{ cursor: "pointer" }} />
                </label>
                <input
                  id="inputImage"
                  type={"file"}
                  onChange={(e) => {
                    setImageForm(e.target.files[0]);
                    onImageChange(e);
                  }}
                  style={{ display: "none" }}
                />
              </div>
              {/* {image && <img src={image} />} */}
              <div>
                <label className="form-label">Nome</label>

                <input
                  className="form-control"
                  type="text"
                  defaultValue={name}
                  style={{ textAlign: "center" }}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
            </CardBody>
            <CardFooter className="bg-transparent border-top">
              <ButtonSpinner
                isLoading={isLoading}
                title={"Salvar"}
                onClick={editProfile}
              />
            </CardFooter>
          </Card>
        </Col>
      </ModalBody>
    </Modal>
  );
}

const mapStatetoProps = (state) => {
  const { nome, foto, email } = state.global_user_data.data.foundLogin;
  return { nome, foto, state, email };
};

const mapDispatchToProps = function (dispatch) {
  return {
    updateProfileRedux: (data) => {
      return dispatch({ type: "UPDATE_PROFILE", data });
    },
  };
};

export default connect(mapStatetoProps, mapDispatchToProps)(EditProfile);
