import React, { useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { useSelector } from "react-redux";
import { Button, Col, Collapse, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import ColorPicker from "../../../components/ColorPicker/ColorPicker";
import SectionComponent from "../../Apps/Personalization/SectionComponent";

export default function EditScreen(props) {
  const { isOpen, toggle, selectedScreen } = props;
  const { token: jwt } = useSelector((state) => state.global_user_data.data);

  const [modalBackdrop, setModalBackdrop] = useState(false);

  //campos fomulário
  const [name, setName] = useState("nome teste");

  //validações campos formulários
  const [nameError, setNameError] = useState(false);

  // color picker
  const [isCollapse, setIsCollapse] = useState(false);

  //color
  const [colorBg, setColorBg] = useState("#FFFFFF");
  const [colorCard, setColorCard] = useState("#FFFFFF");
  const [colorBorder, setColorBorder] = useState("#FFFFFF");
  const [colorTextName, setColorTextName] = useState("#FFFFFF");
  const [colorButton, setColorButton] = useState("#FFFFFF");
  const [colorTextButton, setColorTextButton] = useState("#FFFFFF");

  //editado com sucesso
  const [success, setSuccess] = useState(false);

  function toggleBackdrop() {
    setModalBackdrop(!modalBackdrop);
  }

  function resetFields() {
    setNameError(false);
  }

  async function submitForm() {
    if (name === "") {
      return setNameError(true);
    } else setNameError(false);

    resetFields();

    setSuccess(true);
  }

  return (
    <Modal
      size="lg"
      isOpen={isOpen}
      toggle={() => {
        toggleBackdrop();
      }}
      onClosed={() => {
        resetFields();
      }}
      autoFocus={true}
      centered={true}
    >
      {success && (
        <SweetAlert
          success
          title="Editado"
          onConfirm={() => {
            setSuccess(false);
            toggle();
          }}
        >
          Documento editado com sucesso!
        </SweetAlert>
      )}
      <ModalHeader toggle={toggle}>Editar Arquivo</ModalHeader>
      <ModalBody>
        <form>
          <div>
            <Row style={{ marginTop: 10 }}>
              <Col>
                <label className="form-label">Nome do documento*</label>
                <input
                  className="form-control"
                  type="text"
                  defaultValue={name}
                  onChange={(e) => setName(e.target.value)}
                />
                {nameError && (
                  <p style={{ color: "red" }}>O campo Nome é obrigatório.</p>
                )}
              </Col>
            </Row>
          </div>
        <SectionComponent
          sectionName={"Cores da Tela"}
          setIsOpen={setIsCollapse}
          isOpen={isCollapse}
        />
        <Collapse isOpen={isCollapse}>
          <Row>
            <Col md={6} onClick={() => console.log(colorBg)}>
              <ColorPicker
                classOfContainer="primary-card-edit-pickr"
                labelText="Cor de Fundo"
                setStateFunc={setColorBg}
                defaultColor={colorBg}
              />
            </Col>
            <Col md={6}>
              <ColorPicker
                classOfContainer="secondary-card-edit-pickr"
                labelText="Cor do Cartão"
                setStateFunc={setColorCard}
                defaultColor={colorCard}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <ColorPicker
                classOfContainer="tertiary-card-edit-pickr"
                labelText="Cor da Borda do Cartão"
                setStateFunc={setColorBorder}
                defaultColor={colorBorder}
              />
            </Col>
            <Col md={6}>
              <ColorPicker
                classOfContainer="quaternary-card-edit-pickr"
                labelText=" Cor dos Textos Nome e Descrição do Documento"
                setStateFunc={setColorTextName}
                defaultColor={colorTextName}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <ColorPicker
                classOfContainer="quintenary-card-edit-pickr"
                labelText="Cor do Botão"
                setStateFunc={setColorButton}
                defaultColor={colorButton}
              />
            </Col>
            <Col md={6}>
              <ColorPicker
                classOfContainer="sextiary-card-edit-pickr"
                labelText="Cor do Texto do Botão"
                setStateFunc={setColorTextButton}
                defaultColor={colorTextButton}
              />
            </Col>
          </Row>
        </Collapse>
          <Row
            style={{
              width: "100%",
              marginTop: 15,
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              color="success"
              className="btn btn-success waves-effect waves-light"
              style={{ width: 80, marginTop: 10 }}
              onClick={submitForm}
            >
              Salvar
            </Button>
          </Row>
        </form>
      </ModalBody>
    </Modal>
  );
}
