import React, { useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { useSelector } from "react-redux";
import { updateApp } from "../../utils/services/mobileApps";

export default function ModalEnableApp(props) {
  const { enableAppId, refetch, setEnableAppAlert, contractId } = props;

  const { token } = useSelector((state) => state.global_user_data.data);
  const { mobileApps } = useSelector((state) => state);

  const [success, setSuccess] = useState(false);

  const selectedApp = mobileApps.find((e) => e.id === enableAppId);

  async function updateMobileApps() {
    const data = {
      name: selectedApp.name,
      bundleId: selectedApp.bundleId,
      enabled: true,
      contractId,
    };

    updateApp({ jwt: token, id: enableAppId, data }).then(() => {
      props.setDisabledApps(false);
      refetch();
    });
    // .catch((error) => console.log('error', error));
  }

  return (
    <div>
      <SweetAlert
        title={`Deseja ativar o App ${selectedApp.name}?`}
        warning
        showCancel
        confirmBtnText="Sim"
        cancelBtnText="Não"
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="danger"
        onConfirm={() => {
          updateMobileApps();
          setEnableAppAlert(false);
          setSuccess(true);
        }}
        onCancel={() => {
          setEnableAppAlert(false);
        }}
      >
        O App será reativado.
      </SweetAlert>
      {success && (
        <SweetAlert
          success
          title="Edição"
          onConfirm={() => {
            setSuccess(false);
          }}
        >
          App habilitado com sucesso!
        </SweetAlert>
      )}
    </div>
  );
}
