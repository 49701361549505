import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader, Col, Row, Button } from "reactstrap";

//redux
import { connect } from "react-redux";

import SweetAlert from "react-bootstrap-sweetalert";

//service
import { editGroup } from "../../../utils/services/group";

import ButtonSpinner from "../../../components/Buttons/ButtonSpinner";

function EditGroup(props) {
  const { isOpen, toggle } = props;
  const [modalBackdrop, setModalBackdrop] = useState(false);
  //confirmação
  const [success_dlg, setsuccess_dlg] = useState(false);
  const [descriptionError, setDescriptionError] = useState("");
  const [isLoading, setIsloading] = useState(false);

  function toggleBackdrop() {
    setModalBackdrop(!modalBackdrop);
  }

  async function submitForm(e) {
    if (e.target[1].value === "") {
      setDescriptionError("O campo Descrição é obrigatório");
      return;
    } else setDescriptionError("");

    setIsloading(true);
    await editGroup({
      groupId: props.group.id,
      eventId: props.group.eventid,
      description: e.target[1].value,
      sourceName: props.group.name,
      jwt: props.state.global_user_data.data.token,
    })
      .then((response) => {
        setIsloading(false);
        if (response.message === "updated") {
          setsuccess_dlg(true);
          props.getGroupsFunc();
        }
        if (response.message === "GROUP_DUPLICATED") {
          setDescriptionError("Grupo já cadastrado nesse evento.");
        }
      })
      .catch((error) => {
        setIsloading(false);
        // console.log(error);
      });
  }

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => {
        toggleBackdrop();
      }}
      autoFocus={true}
      centered={true}
    >
      {success_dlg ? (
        <SweetAlert
          success
          title={"Editado"}
          onConfirm={() => {
            //props.getAllFeed();
            setsuccess_dlg(false);
            toggle();
          }}
        >
          {"Grupo editado com sucesso!"}
        </SweetAlert>
      ) : null}
      <ModalHeader toggle={toggle}>Editar Grupo</ModalHeader>
      <ModalBody>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            submitForm(e);
          }}
        >
          <Row style={{ marginBottom: 10 }}>
            <Col lg={12}>
              <div>
                <label className="form-label">ID do Grupo</label>
                <input
                  className="form-control"
                  type="text"
                  defaultValue={props.group.id}
                  disabled
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg={12}>
              <div>
                <label className="form-label">Descrição</label>
                <input
                  className="form-control"
                  type="text"
                  defaultValue={props.group.name}
                />
                <p style={{ color: "red" }}>{descriptionError}</p>
              </div>
            </Col>
          </Row>

          <Row
            style={{
              width: "100%",
              margin: "auto",
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "1rem",
            }}
          >
            <Button
              color="danger"
              className="btn btn-danger waves-effect waves-light"
              style={{ width: 80, marginRight: "1rem" }}
              onClick={() => toggle(!isOpen)}
            >
              Fechar
            </Button>
            <ButtonSpinner isLoading={isLoading} title={"Salvar"} />
          </Row>
        </form>
      </ModalBody>
    </Modal>
  );
}

const mapStateToProps = function (state) {
  return { state: state };
};

export default connect(mapStateToProps)(EditGroup);
