import axiosInstance from "../services/config/axiosinstance";
import { urlBaseVibra } from "./config/index";

async function getDays() {
  const config = {
    method: "get",
    url: `${urlBaseVibra}/total/dias`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  return axiosInstance(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

async function getGeneral() {
  const config = {
    method: "get",
    url: `${urlBaseVibra}/total/geral`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  return axiosInstance(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

async function getEvolution() {
  const config = {
    method: "get",
    url: `${urlBaseVibra}/total/evolucao`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  return axiosInstance(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      // console.log(error.response);
      return error.response.data;
    });
}

async function getFuncionarios() {
  const config = {
    method: "get",
    url: `https://vibra-api.sistemasinteegra.com.br/vibra`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const { data } = await axiosInstance(config);
    // console.log("api", data);

    return data;
  } catch (error) {
    // console.log("erro api", error);
    return {
      colaboradores: {
        inscritos: [],
        pendentes: [],
      },
      revendedores: {
        inscritos: [],
        pendentes: [],
      },
      convidados: {
        inscritos: [],
        pendentes: [],
      },
    };
  }
}

export { getDays, getGeneral, getEvolution, getFuncionarios };
