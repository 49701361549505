import React from 'react';
import ReactEcharts from 'echarts-for-react';

export default function SimpleDonutChart({
  data = [],
  height,
  color = '#FFC107'
}) {
  const getOptions = () => ({
    color: [color, '#E0E0E0'],
    title: {
      show: false
    },
    tooltip: {
      show: false,
    },
    legend: {
      show: false,
    },
    series: [
      {
        name: 'Cliques',
        type: 'pie',
        radius: ['40%', '60%'],
        center: ['50%', '50%'],
        height: '100%',
        data: data.map(item => ({ name: item.x, value: item.y })),

        label: {
          show: false,
        },
      }
    ]
  });
  return (
    <ReactEcharts option={getOptions()} style={{ height }} />
  );
}