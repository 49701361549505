import React, { useState, useEffect } from "react";
import {
  Table,
  Collapse,
  Card,
  CardBody,
  CardTitle,
  Button,
  Row,
} from "reactstrap";
import { FirestoreProvider } from "../../../utils/firestoreProvider";
import SectionComponent from "../../Apps/Personalization/SectionComponent";
// alerta
import SweetAlert from "react-bootstrap-sweetalert";
//redux
import { connect } from "react-redux";
// location
import { useLocation } from "react-router-dom";
//paginação
import Pagination from "../../../components/Paginationv3";
//componentes
import ModalNewSuperTela from "./ModalNewSuperTela";
import ModalEdidSuperTela from "./ModalEditSuperTela";
import ModalSurvey from "./Survey/ModalSurvey";
import SuperTela3 from "./SuperTela3";
// service
import {
  getSuperScreen,
  deleteSuperScreen,
} from "../../../utils/services/superScreen";

import useDebounce from "../../../utils/hooks/useDebounce";
import SearchFilters from "../../../components/SearchFilters";

//services
// import { getFeed, deleteFeed } from "../../../utils/services/feed";

function SuperTela(props) {
  // location
  const location = useLocation();
  //pesquisa
  const [search, setSearch] = useState("");
  //modais
  const [modalNewSuperTela, setModalNewSuperTela] = useState(false);
  const [modalEditSuperTela, setModalEditSuperTela] = useState(false);
  const [modalSurvey, setModalSurvey] = useState(false);
  const [modalSuperTela3, setModalSuperTela3] = useState(false);
  //deletar super tela
  const [deleteData, setDeleteData] = useState({});
  const [confirm_alert, setconfirm_alert] = useState(false);
  const [success_dlg, setsuccess_dlg] = useState(false);
  // editar
  const [editData, setEditData] = useState({});
  //paginação
  const [currentIndex, setCurrentIndex] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [countData, setCountData] = useState(0);
  const debouncedSearch = useDebounce(search, 300);
  //state de super tela
  const [superTelas, setSuperTelas] = useState([]);
  // id da supertela para as enquetes
  const [superScreenId, setSuperScreenId] = useState("");
  const [collapseIsOpen, setCollapseIsOpen] = useState(false);
  const [btnsAvailable, setBtnsAvailable] = useState([]);
  const [activeFilter, setActiveFilter] = useState("name");
  // modal collapse
  const [isOpen, setIsOpen] = useState(false);
  const [idDashboard, setIdDashboard] = useState();

  const columnsFilter = [
    { name: "Nome", value: "name", type: "text" },
    { name: "Canais", value: "channels", type: "text" },
    { name: "Chat", value: "chat", type: "boolean" },
    { name: "Super emoji", value: "emoji", type: "boolean" },
  ];

  function setActiveFilterHandle(columnActive) {
    setActiveFilter(columnActive);
    setSearch("");
  }
  const reverseDate = () => {
    const reverse = debouncedSearch
      .split("/")
      .reverse()
      .join("/")
      .replace(/\//g, "-");
    return reverse;
  };
  function treatmentSearch() {
    if (
      columnsFilter.filter((item) => item.value === activeFilter)[0].type ===
      "date"
    ) {
      return reverseDate();
    }
    return debouncedSearch;
  }
  function toggleNewSuperTela() {
    setModalNewSuperTela(!modalNewSuperTela);
    // if (modalNewSuperTela) {
    //   getAllSuperScreen();
    // }
  }

  function toggleEditSuperTela() {
    setModalEditSuperTela(!modalEditSuperTela);
    // if (modalEditSuperTela) {
    //   getAllSuperScreen();
    // }
  }

  function toggleSurvey() {
    setModalSurvey(!modalSurvey);
  }

  async function clipBoard(id) {
    await navigator.clipboard.writeText(
      "https://unhealthy-birds.surge.sh?transmissionKey=" + id
    );
    alert("O link foi copiado para a área de transferência");
  }

  function getAllSuperScreen() {
    getSuperScreen(
      props.state.global_user_data.data.token,
      location.state.idEvent,
      currentIndex,
      itemsPerPage,
      treatmentSearch(),
      activeFilter
    ).then((res) => {
      if (res.message === "transmissionByEvent") {
        setSuperTelas(res.findOneTransmissionData.data);
        setCountData(res.findOneTransmissionData.count);
      }
    });
  }

  function funcDeleteFeed() {
    deleteSuperScreen(
      deleteData.id,
      props.state.global_user_data.data.token
    ).then((res) => {
      if (res.message === "DELETED_TRANSMISSION") {
        setconfirm_alert(false);
        setsuccess_dlg(true);
      }
    });
    // .catch((error) => console.log("erro", error));
  }

  useEffect(() => {
    getAllSuperScreen();
  }, [currentIndex, itemsPerPage, debouncedSearch]);

  return (
    <>
      {modalNewSuperTela && (
        <ModalNewSuperTela
          isOpen={modalNewSuperTela}
          toggle={toggleNewSuperTela}
          idEvent={location.state.idEvent}
          getSuperScreen={getAllSuperScreen}
        />
      )}
      {modalEditSuperTela && (
        <ModalEdidSuperTela
          isOpen={modalEditSuperTela}
          toggle={toggleEditSuperTela}
          idEvent={location.state.idEvent}
          data={editData}
          getAllSuperScreen={getAllSuperScreen}
        />
      )}

      {modalSurvey && (
        <ModalSurvey
          isOpen={modalSurvey}
          toggle={toggleSurvey}
          idEvent={location.state.idEvent}
          idSuperScreen={superScreenId}
        />
      )}

      {modalSuperTela3 && (
        <SuperTela3
          isOpen={modalSuperTela3}
          toggle={() => setModalSuperTela3(!modalSuperTela3)}
          idDashboard={idDashboard}
          idEvent={location.state.idEvent}
        />
      )}

      {confirm_alert ? (
        <SweetAlert
          title={`Deseja deletar a Super Tela ${deleteData.name}?`}
          warning
          showCancel
          confirmBtnText="Sim"
          cancelBtnText="Não"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            funcDeleteFeed();
          }}
          onCancel={() => setconfirm_alert(false)}
        >
          Você não poderá reverter essa ação!
        </SweetAlert>
      ) : null}

      {success_dlg ? (
        <SweetAlert
          success
          title="Deletado"
          onConfirm={() => {
            getAllSuperScreen();
            setsuccess_dlg(false);
          }}
        >
          Super Tela Deletada com sucesso
        </SweetAlert>
      ) : null}

      <Card>
        <CardBody>
          <CardTitle>Super Tela</CardTitle>
          <div>
            <Row
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: 10,
                width: "50px",
              }}
            >
              <SectionComponent
                sectionName="Filtros"
                setIsOpen={setCollapseIsOpen}
                isOpen={collapseIsOpen}
              />
            </Row>
            <Collapse isOpen={collapseIsOpen}>
              <SearchFilters
                columns={columnsFilter}
                activeFilter={activeFilter}
                setActiveFilterHandle={setActiveFilterHandle}
              />
            </Collapse>
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              margin: "10px 0",
            }}
          >
            <form
              className="app-search d-none d-lg-block events-input-search"
              onSubmit={(e) => e.preventDefault()}
            >
              <div className="position-relative">
                <input
                  type="text"
                  value={search}
                  className="form-control"
                  placeholder="Pesquisar..."
                  onChange={(e) => setSearch(e.target.value)}
                />
                <span className="bx bx-search-alt" />
              </div>
            </form>

            <div className="mobile-btn-section" style={{ width: "100%" }}>
              <Row className="mobile-btns-top">
                <form
                  className="app-search"
                  style={{ padding: "0px" }}
                  onSubmit={(e) => e.preventDefault()}
                >
                  <div className="position-relative">
                    <input
                      type="text"
                      value={search}
                      className="form-control"
                      placeholder="Pesquisar..."
                      onChange={(e) => setSearch(e.target.value)}
                    />
                    <span className="bx bx-search-alt" />
                  </div>
                </form>
              </Row>
              <SectionComponent
                sectionName="Opções"
                setIsOpen={setIsOpen}
                isOpen={isOpen}
              />
              <Collapse isOpen={isOpen}>
                <Row className="mobile-btns-top">
                  <div onClick={() => setModalNewSuperTela(true)}>
                    Adicionar Super Tela
                  </div>
                </Row>
              </Collapse>
            </div>

            <div className="header-btns-container">
              <Button
                color="success"
                className="btn-rounded waves-effect waves-light events-button-add"
                onClick={() => setModalNewSuperTela(true)}
              >
                Adicionar Super Tela
              </Button>
            </div>
          </div>

          <div className="table-responsive">
            <Table className="table mb-0">
              <thead className="table-light">
                <tr>
                  <th>ID</th>
                  <th>Nome</th>
                  <th>Canais</th>
                  <th>Chat</th>
                  <th>Super emoji</th>
                  <th>Perguntas</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                {superTelas.length > 0 &&
                  superTelas.map((e, i) => (
                    <tr key={i}>
                      <td>{e.id}</td>
                      <td>{e.name}</td>
                      <td>
                        {e.channels.map((channel) => channel.name).join(", ")}
                      </td>
                      <td>{e.visibleChat ? "Sim" : "Não"}</td>
                      <td>{e.emojiActive ? "Sim" : "Não"}</td>
                      <td>{e.questions ? "Sim" : "Não"}</td>
                      <td>
                        <i
                          className="mdi mdi-form-select"
                          style={{
                            color: "blue",
                            fontSize: 17,
                            margin: "0 3px",
                            cursor: "pointer",
                          }}
                          title="Enquetes"
                          onClick={() => {
                            setSuperScreenId(e.id);
                            setModalSurvey(true);
                          }}
                        />
                        <i
                          className="mdi mdi-chart-areaspline"
                          style={{
                            color: "#e0e013",
                            fontSize: 17,
                            margin: "0 3px",
                            cursor: "pointer",
                          }}
                          title="Dashboard"
                          onClick={() => {
                            setIdDashboard({
                              transmissionId: e.id,
                              chatId: e.visibleChat ? e.chats[0].chatId : null,
                            });
                            setModalSuperTela3(true);
                          }}
                        />
                        <i
                          className="mdi mdi-content-copy"
                          style={{
                            color: "grey",
                            fontSize: 17,
                            margin: "0 3px",
                            cursor: "pointer",
                          }}
                          title="Copiar"
                          onClick={() => clipBoard(e.id)}
                        />
                        <i
                          className="mdi mdi-pencil"
                          style={{
                            color: "green",
                            fontSize: 17,
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setEditData(e);
                            setModalEditSuperTela(true);
                          }}
                          title="Editar"
                        />
                        <i
                          className="mdi mdi-trash-can"
                          style={{
                            color: "red",
                            fontSize: 17,
                            cursor: "pointer",
                          }}
                          title="Excluir"
                          onClick={() => {
                            setDeleteData(e);
                            setconfirm_alert(true);
                          }}
                        />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
        </CardBody>
      </Card>
      {/* 
      Fornecer os 4 parametros obrigatórios;
      ** data - a array contendo as informações da tabela
      ** search - o state do termo de pesquisa
      ** currentIndex - posição atual do index
      ** setCurrentIndex - função para atualizar a posição atual do index
      */}
      <Pagination
        data={superTelas}
        search={search}
        currentIndex={currentIndex}
        setCurrentIndex={setCurrentIndex}
        countData={countData}
        maxItemPerPage={itemsPerPage}
        setMaxItemPerPage={setItemsPerPage}
        maxPageButtonsToShow={3}
      />
    </>
  );
}

const mapStateToProps = (state) => {
  return { state };
};

export default connect(mapStateToProps)(SuperTela);
