import React from "react";

export default function ChatFixed(props) {
  const { data } = props;
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      {props.loadingChatFixed && props.lengthData === 0 && (
        <div style={{ display: "flex", alignItems: "center" }}>
          <div className="div-chatfixed" style={{ justifyContent: "center" }}>
            <a style={{ color: "black" }}>
              <i className="bx bx-loader bx-spin font-size-16 align-middle me-2" />
              Carregando
            </a>
          </div>
        </div>
      )}

      {props.lengthData > 0 && (
        <div style={{ display: "flex", alignItems: "center" }}>
          <div className="div-chatfixed">
            {data.avatar && <img src={data.avatar} className="img-chatfixed" />}

            <div className="data-chatfixed">
              <a className="name-chatfixed">{data.name}</a>
              <a className="msg-chatfixed">{data.msg}</a>
            </div>
          </div>

          <i
            className="mdi mdi-pin-off-outline icon-chatfix"
            onClick={() => {
              props.setChatFixData(data);
              props.setConfirmRemoveChatFix(true);
            }}
          />
        </div>
      )}
    </div>
  );
}
