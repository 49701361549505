import React, { useState, useEffect } from "react";
import { Button, Table, Collapse, Row } from "reactstrap";
// componentes
import Pagination from "../../../../components/Paginationv3";
import SectionComponent from "../../../Apps/Personalization/SectionComponent";
import SearchFilters from "../../../../components/SearchFilters";
import WaitExport from "../../../../components/WaitExport";
// service
import { getSurveysReport } from "../../../../utils/services/superScreen";
import { createXlsx } from "./createXlsx";
//redux
import { connect } from "react-redux";

function ReportSurvey(props) {
  //paginação
  const [currentIndex, setCurrentIndex] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [countData, setCountData] = useState(0);
  //pesquisa
  const [search, setSearch] = useState("");
  // filtros
  const [collapseIsOpen, setCollapseIsOpen] = useState(false);
  const [activeFilter, setActiveFilter] = useState("loginName");
  // data
  const [reportData, setReportData] = useState([]);
  // export in progress
  const [exportInProgress, setExportInProgress] = useState(false);
  const [finishExport, setFinishExport] = useState(false);
  // modal collapse
  const [isOpen, setIsOpen] = useState(false);

  const columnsFilter = [
    { name: "Nome do Participante", value: "loginName", type: "text" },
    { name: "Pergunta", value: "question", type: "text" },
    { name: "Resposta", value: "answer", type: "text" },
    { name: "Acertou", value: "hit", type: "text" },
    { name: "Data e Hora", value: "date", type: "date" },
  ];

  function setActiveFilterHandle(columnActive) {
    setActiveFilter(columnActive);
    setSearch("");
  }

  function treatmentSearch() {
    if (
      columnsFilter.filter((item) => item.value === activeFilter)[0].type ===
      "date"
    ) {
      return reverseDate();
    }
    return search;
  }

  const reverseDate = () => {
    const reverse = search.split("/").reverse().join("/").replace(/\//g, "-");
    return reverse;
  };

  function formatDate(date) {
    const dateFormat = date.split("T")[0].split("-").reverse().join("/");
    const time = date.split("T")[1].slice(0, 5);
    return `${dateFormat} - ${time}`;
  }

  function exportReport() {
    createXlsx({
      jwt: props.state.global_user_data.data.token,
      idEvent: props.idEvent,
      idSuperScreen: props.idSuperScreen,
      idReportSurvey: props.idReportSurvey,
      limit: itemsPerPage,
      search: treatmentSearch(),
      countData: countData,
      activeFilter: activeFilter,
      currentIndex: currentIndex,
      setFinishExport,
    });
    setExportInProgress(true);
  }

  function getAllSurveyReport() {
    getSurveysReport(
      // "ab4d88fc-86c0-4998-b077-3a0b6232d155",
      // "32daf276-c08c-4bf5-a592-60831ba14976",
      // "85625f05-efd8-4cae-bf0b-4152ab2a8182",
      props.idEvent,
      props.idSuperScreen,
      props.idReportSurvey,
      currentIndex,
      itemsPerPage,
      treatmentSearch(),
      activeFilter,
      props.state.global_user_data.data.token
    ).then((res) => {
      // console.log("ok", res);
      if (res.message === "findOne") {
        setReportData(res.findOneTransmissionData.data);
        setCountData(res.findOneTransmissionData.count);
      }
    });
    // .catch((error) => console.log.log("erro", error));
  }

  useEffect(() => {
    getAllSurveyReport();
  }, [currentIndex, itemsPerPage, search]);

  return (
    <div>
      <WaitExport
        isOpen={exportInProgress}
        name="Relatório"
        preposicao="dos"
        finishExport={finishExport}
        setFinishExport={setFinishExport}
        setExportInProgress={setExportInProgress}
      />
      <div>
        <Row
          style={{
            display: "flex",
            flexDirection: "row",
            marginBottom: 10,
            width: "50px",
          }}
        >
          <SectionComponent
            sectionName="Filtros"
            setIsOpen={setCollapseIsOpen}
            isOpen={collapseIsOpen}
          />
        </Row>
        <Collapse isOpen={collapseIsOpen}>
          <SearchFilters
            columns={columnsFilter}
            activeFilter={activeFilter}
            setActiveFilterHandle={setActiveFilterHandle}
          />
        </Collapse>
      </div>

      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          marginBottom: 10,
          alignItems: "center",
        }}
      >
        <form
          className="app-search d-none d-lg-block events-input-search"
          onSubmit={(e) => e.preventDefault()}
        >
          <div className="position-relative">
            <input
              type="text"
              className="form-control"
              placeholder="Pesquisar..."
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <span className="bx bx-search-alt" />
          </div>
        </form>

        <div className="mobile-btn-section" style={{ width: "100%" }}>
          <Row className="mobile-btns-top">
            <form
              className="app-search"
              style={{ padding: "0px" }}
              onSubmit={(e) => e.preventDefault()}
            >
              <div className="position-relative">
                <input
                  type="text"
                  value={search}
                  className="form-control"
                  placeholder="Pesquisar..."
                  onChange={(e) => setSearch(e.target.value)}
                />
                <span className="bx bx-search-alt" />
              </div>
            </form>
          </Row>
          {reportData.length > 0 && (
            <>
              <SectionComponent
                sectionName="Opções"
                setIsOpen={setIsOpen}
                isOpen={isOpen}
              />
              <Collapse isOpen={isOpen}>
                <Row className="mobile-btns-top">
                  <div onClick={() => exportReport()}>Exportar relatório</div>
                </Row>
              </Collapse>
            </>
          )}
        </div>

        {reportData.length > 0 && (
          <div className="header-btns-container">
            <Button
              color="success"
              className="btn-rounded waves-effect waves-light events-button-add"
              onClick={() => exportReport()}
            >
              Exportar relatório
            </Button>
          </div>
        )}
      </div>
      <div className="table-responsive mb-3">
        <Table className="table mb-0">
          <thead className="table-light">
            <tr>
              <th>Nome do Participante</th>
              <th>Pergunta</th>
              <th>Resposta</th>
              <th>Acertou</th>
              <th>Data e Hora</th>
            </tr>
          </thead>

          <tbody>
            {reportData.length > 0 &&
              reportData.map((e, i) => (
                <tr key={i}>
                  <td>{e.loginName}</td>
                  <td>{e.question}</td>
                  <td>{e.answer}</td>
                  <td>{e.hit ? "Sim" : "Não"}</td>
                  <td>{formatDate(e.createdAt)}</td>
                </tr>
              ))}
          </tbody>
        </Table>
      </div>
      <Pagination
        data={reportData}
        search={search}
        currentIndex={currentIndex}
        setCurrentIndex={setCurrentIndex}
        countData={countData}
        maxItemPerPage={itemsPerPage}
        setMaxItemPerPage={setItemsPerPage}
        maxPageButtonsToShow={3}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return { state };
};

export default connect(mapStateToProps)(ReportSurvey);
