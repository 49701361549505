import React, { useState } from "react";

export default function RowTableStatus(props) {
  const { e } = props;
  // exibir info
  const [open, setOpen] = useState(false);

  return (
    <>
      <>
        <tr>
          <td>{e.vehicleDescription}</td>
          <td>
            {e.status && (
              <span
                className="tagDriver"
                style={{
                  whiteSpace: "nowrap",
                  backgroundColor:
                    e.status === "Não Iniciado"
                      ? "gray"
                      : e.status === "Embarcando" ||
                        e.status === "Desembarcando"
                      ? "#03A9F4"
                      : e.status === "Em Trânsito"
                      ? "#FFC107"
                      : e.status === "Finalizado"
                      ? "green"
                      : e.status === "Cancelado"
                      ? "red"
                      : "#607D8B",
                }}
              >
                {e.status}
              </span>
            )}
          </td>
          <td>
            <i
              className="mdi mdi-eye"
              style={{
                color: "blue",
                fontSize: 17,
                cursor: "pointer",
                marginRight: 5,
              }}
              onClick={() => setOpen(!open)}
            />
          </td>
          <td></td>
        </tr>

        {open && (
          <tr>
            <td style={{ backgroundColor: "white" }}>De: {e.origin}</td>
            <td style={{ backgroundColor: "white" }}>Para: {e.destination}</td>
            <td style={{ backgroundColor: "white" }}>
              Capacidade: {e.seatingCapacity}
            </td>
            <td style={{ backgroundColor: "white" }}>
              Participantes no Veículo: {e.amount}
            </td>
          </tr>
        )}
      </>
    </>
  );
}
