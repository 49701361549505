// Default messages

export var createEditLoginHtml =
  '<div><p>Olá, <span style="font-weight:bold;">${participantName}!</span></p><p>Sua inscrição no <span style="font-weight:bold;">${eventName}</span> está confirmada</p><p>Sessão <span style="font-weight:bold;">${sessionDate} ${sessionHour}</span></p><p>Quantidade de ingressos <span style="font-weight:bold;">${totalTickets}</span></p><p>Local <span style="font-weight:bold;">${addressSession}</span></p></div>';

export var confirmRegistrationHtml =
  '<div><p>Olá, <span style="font-weight:bold;">${participantName}!</span></p><p>Sua inscrição no sorteio do <span style="font-weight:bold;">${eventName}</span> está confirmada</p><p>Sessão <span style="font-weight:bold;">${sessionDate} ${sessionHour}</span></p><p>Quantidade de ingressos <span style="font-weight:bold;">${totalTickets}</span></p><p>Local <span style="font-weight:bold;">${addressSession}</span></p></div>';

export var winnersHtml =
  '<div><p>Olá, <span style="font-weight: bold">${participantName}!</span></p><p>Parabéns! Você ganhou ganhou o sorteio de ingressos para <span style="font-weight: bold">${eventName}</span></p><p>Sessão <span style="font-weight: bold">${sessionDate} ${sessionHour}</span></p><p>Quantidade de ingressos <span style="font-weight: bold">${totalTickets}</span></p><p>Local <span style="font-weight: bold">${addressSession}</span></p><p>Os ingressos ganhos neste sorteio, são intransferíveis.</p><p>Eles são destinados exclusivamentes ao vencedor(a) e não podem ser repassados a terceiros</p></div>';

export var notChosenHtml =
  '<div><p>Olá, <span style="font-weight:bold;">${participantName}!</span></p><p>Infelizmente você não foi sorteado(a) para <span style="font-weight:bold;">${eventName} ${sessionDate} ${sessionHour}</span></p><p>Mas fique tranquilo(a): Você ainda pode se inscrever para participar do sorteio das próximas sessões </p></div>';
