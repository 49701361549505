import React, { useState, useEffect } from "react";
import { Modal, ModalHeader, ModalBody, Row, Col, Button } from "reactstrap";
import ButtonSpinner from "../../../components/Buttons/ButtonSpinner";
import Select from "react-select";
import SweetAlert from "react-bootstrap-sweetalert";
import { movePartipantsToNewVehicle } from "../../../utils/services/transfer";
import { getAllVehicles } from "../../../utils/services/vehicles";

export default function modalEditMovePartVehicle(props) {
  const {
    isOpen,
    toggle,
    eventId,
    vehicleId,
    loginId,
    getAll,
    bulkData,
    changeStatusBySelection,
  } = props;

  const [isLoading, setIsloading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [fail, setFail] = useState(false);
  const [vehicles, setVehicles] = useState([]);
  const [vehicleSelected, setVehicleSelected] = useState();

  async function moveParticpant() {
    let data = [];
    setIsloading(true);

    if (changeStatusBySelection) {
      data = bulkData.map((item) => ({
        eventId: eventId,
        loginId: item.participantId,
        vehiclePrevious: vehicleId,
        vehicleNew: vehicleSelected,
      }));
    } else {
      data = [
        {
          eventId: eventId,
          loginId: loginId,
          vehiclePrevious: vehicleId,
          vehicleNew: vehicleSelected,
        },
      ];
    }

    await movePartipantsToNewVehicle(data).then((res) => {
      const resp = res?.createTransferData;
      setIsloading(false);
      if (resp.errorData.length === 0) {
        setSuccess(true);
      } else {
        setFail(true);
      }
      getAll();
    });
  }

  async function getVehicles() {
    getAllVehicles(eventId, 1, 100, "", "").then((res) => {
      if (res.data.message === "FOUND") {
        if (res?.data.findAll.data.length > 0) {
          const vehiclesAvailable = res.data.findAll.data.filter(
            (e) => e.seatingCapacity > e.amount && e.id !== vehicleId
          );
          setVehicles(vehiclesAvailable);
        }
      }
    });
  }

  useEffect(() => {
    getVehicles();
  }, []);

  return (
    <>
      {success && (
        <SweetAlert
          success
          title={"Atualizado"}
          onConfirm={() => {
            setSuccess(false);
            toggle();
          }}
        >
          {"Status atualizado com sucesso!"}
        </SweetAlert>
      )}

      {fail && (
        <SweetAlert
          warning
          showCancel
          cancelBtnText="Fechar"
          showConfirm={false}
          cancelBtnBsStyle="warning"
          title={"Atenção!"}
          onCancel={() => {
            setFail(false);
            toggle();
          }}
        >
          {
            "Nem todos os participantes foram movidos para o novo veículo. Capacidade do veículo esgotada."
          }
        </SweetAlert>
      )}

      <Modal isOpen={isOpen} centered autoFocus>
        <ModalHeader toggle={toggle}>Alteração do Veículo </ModalHeader>
        <ModalBody>
          <Row style={{ marginTop: "10px" }}>
            <Col lg={12}>
              <label className="form-label">Nome do Veículo*</label>
              <Select
                placeholder={"Selecione um veículo..."}
                onChange={(e) => {
                  setVehicleSelected(e.value);
                }}
                options={[
                  {
                    options: [
                      ...vehicles.map((e) => {
                        return { label: e.description, value: e.id };
                      }),
                    ],
                  },
                ]}
                classNamePrefix="select2-selection"
                menuPortalTarget={document.body}
                styles={{
                  menuPortal: (base) => ({
                    ...base,
                    zIndex: 9999,
                  }),
                  menu: (provided) => ({
                    ...provided,
                    maxHeight: 150,
                  }),
                  menuList: (provided) => ({
                    ...provided,
                    maxHeight: 150,
                    overflowY: "auto",
                  }),
                }}
              />
              {/* {vehicleError && <a style={{ color: "red" }}>{vehicleError}</a>} */}
            </Col>
          </Row>
          <Row
            style={{
              width: "100%",
              margin: "auto",
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "1rem",
            }}
          >
            <Button
              color="danger"
              style={{ width: 80, marginRight: 10 }}
              onClick={() => toggle()}
            >
              Fechar
            </Button>

            <ButtonSpinner
              isLoading={isLoading}
              title={"Salvar"}
              isDisabled={vehicleSelected === ""}
              onClick={() => {
                moveParticpant();
              }}
            />
          </Row>
        </ModalBody>
      </Modal>
    </>
  );
}
