import writeXlsxFile from "write-excel-file";
import { getEarnedPoints } from "../../../utils/services/ranking";
import { optionCode } from "./mockCodes";
import { format } from "date-fns";

export async function createXlsx({
  loginId,
  eventId,
  jwt,
  offset,
  limit,
  countData,
  setFinishExport,
}) {
  const schema = [
    {
      column: "Descrição",
      type: String,
      value: (player) =>
        optionCode.find(({ code }) => code === player.configuration.code)
          ?.describe || player.configuration.code,
    },
    {
      column: "Pontos",
      type: Number,
      value: (player) => player.configuration.earnedPoints,
    },
    {
      column: "Data",
      type: String,
      value: (player) =>
        format(new Date(player.createdAt), "dd/MM/yyyy HH:mm:ss"),
    },
  ];

  const allRequestsPromises = [];
  const numberOfRequisitions = Math.ceil(countData / limit);

  for (let i = 1; i <= numberOfRequisitions; i += 1) {
    const request = await getEarnedPoints({
      loginId,
      eventId,
      jwt,
      offset,
      limit,
    });

    console.log(request)

    allRequestsPromises.push(request);
    setTimeout(() => {}, 500);

    const progressBar = document.querySelector(".export-progress");
    const percentageBar = document.querySelector(".export-percentage");

    progressBar.style.width = `${100}%`;
    percentageBar.innerHTML = `${(100).toFixed(
      0
    )}%`;

    if (i === numberOfRequisitions) {
      setTimeout(() => {
        setFinishExport(true);
      }, 1000);
    }
  }

  const allDataInSingleArray = allRequestsPromises.map((e) => e.data).flat();

  if (allDataInSingleArray) {
    await writeXlsxFile(allDataInSingleArray, {
      schema,
      fileName: "extratoParticipante.xlsx",
    });
  }
}
