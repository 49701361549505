import React from "react";
import { Button } from "reactstrap";
import microsoftIcon from "../assets/image/msal-icon.png";

export default function ButtonMsalSSO(props) {
  return (
    <Button
      outline
      style={{
        marginTop: "16px",
        backgroundColor: "#FFF",
        color: "gray",
      }}
      onClick={props.handleLogin}
    >
      <img src={microsoftIcon} />
      <span style={{ marginLeft: "10px" }}>Acessar com SSO</span>
    </Button>
  );
}
