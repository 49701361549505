import { UPDATE_MOBILE_APPS } from "./actions/mobileApps";
import {
  INITIAL_CONFIGS,
  LOGIN_PERSONALIZATION,
  EVENTS_LIST_PERSONALIZATION,
  GENERAL_PERSONALIZATION,
  ALERTS_PESONALIZATION,
  PROFILE_PERSONALIZATION,
  SCHEDULE_PERSONALIZATION,
  FEED_PERSONALIZATION,
  LOCALIZATION_PERSONALIZATION,
  GUEST_PERSONALIZATION,
  LOGISTICS_PERSONALIZATION,
  RANKING_PERSONALIZATION,
} from "./actions/personalization";

import { SET_NEW_TOKEN } from "./actions/token";

const INITIAL_STATE = {
  global_user_data: {
    data: {
      theme: [],
      foundLogin: {
        nome: "a",
        foto: "b",
      },
    },
  },
  layout_manager: {
    drawer_openend: true,
    header_collapsed: true,
    footer_collapsed: true,
  },
  events: "",
  msalInstance: {},
  mfa: {},
  mobileApps: [],
  personalization: {},
  customForm: {
    inputText: {},
  },
  menu: {
    menuConfig: false,
    menuApp: false,
    menuContract: false,
  },
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "UPDATE_PROFILE":
      return {
        ...state,
        global_user_data: {
          ...state.global_user_data,
          data: {
            ...state.global_user_data.data,
            foundLogin: {
              ...state.global_user_data.data.foundLogin,
              nome: action.data.nome,
              foto: action.data.foto,
            },
          },
        },
      };
    case "UPDATE_EVENT_LIST":
      return {
        ...state,
        global_user_data: {
          ...state.global_user_data,
          data: {
            ...state.global_user_data.data,
            foundEvents: [...action.data],
          },
        },
      };
    case "OPEN_GLOBAL_LAYOUT_MENU_NAV_WEB":
      return {
        ...state,
        layout_manager: {
          drawer_openend: true,
          header_collapsed: true,
          footer_collapsed: true,
        },
      };
    case "CLOSE_GLOBAL_LAYOUT_MENU_NAV_WEB":
      return {
        ...state,
        layout_manager: {
          drawer_openend: false,
          header_collapsed: false,
          footer_collapsed: false,
        },
      };
    case "SAVE_GLOBAL_USER_DATA":
      return {
        ...state,
        global_user_data: action.data,
      };
    case "OPEN_EVENT_MENU":
      return {
        ...state,
        events: action.data,
      };
    case "MSAL_INSTANCE":
      return {
        ...state,
        msalInstance: action.data,
      };
    case "MFA_TOKEN":
      return {
        ...state,
        mfa: action.data,
      };

    case UPDATE_MOBILE_APPS:
      return {
        ...state,
        mobileApps: action.data,
      };
    case INITIAL_CONFIGS:
      return {
        ...state,
        personalization: {
          ...state.personalization,
          initialConfigs: action.data,
        },
      };
    case LOGIN_PERSONALIZATION:
      return {
        ...state,
        personalization: {
          ...state.personalization,
          login: action.data,
        },
      };
    case EVENTS_LIST_PERSONALIZATION:
      return {
        ...state,
        personalization: {
          ...state.personalization,
          eventsList: action.data,
        },
      };
    case GENERAL_PERSONALIZATION:
      return {
        ...state,
        personalization: {
          ...state.personalization,
          general: action.data,
        },
      };
    case ALERTS_PESONALIZATION:
      return {
        ...state,
        personalization: {
          ...state.personalization,
          alerts: action.data,
        },
      };
    case PROFILE_PERSONALIZATION:
      return {
        ...state,
        personalization: {
          ...state.personalization,
          profile: action.data,
        },
      };
    case SCHEDULE_PERSONALIZATION:
      return {
        ...state,
        personalization: {
          ...state.personalization,
          schedule: action.data,
        },
      };
    case FEED_PERSONALIZATION:
      return {
        ...state,
        personalization: {
          ...state.personalization,
          feed: action.data,
        },
      };
    case LOCALIZATION_PERSONALIZATION:
      return {
        ...state,
        personalization: {
          ...state.personalization,
          localization: action.data,
        },
      };
    case GUEST_PERSONALIZATION:
      return {
        ...state,
        personalization: {
          ...state.personalization,
          guest: action.data,
        },
      };
    case LOGISTICS_PERSONALIZATION:
      return {
        ...state,
        personalization: {
          ...state.personalization,
          logistics: action.data,
        },
      };
    case RANKING_PERSONALIZATION:
      return {
        ...state,
        personalization: {
          ...state.personalization,
          ranking: action.data,
        },
      };
    case "open_menuConfig":
      return {
        ...state,
        menu: {
          ...state.menu,
          menuConfig: true,
        },
      };
    case "close_menuConfig":
      return {
        ...state,
        menu: {
          ...state.menu,
          menuConfig: false,
        },
      };
    case "open_menuApp":
      return {
        ...state,
        menu: {
          ...state.menu,
          menuApp: true,
        },
      };
    case "close_menuApp":
      return {
        ...state,
        menu: {
          ...state.menu,
          menuApp: false,
        },
      };
    case "OPEN_MENU_CONTRACTS":
      return {
        ...state,
        menu: {
          ...state.menu,
          menuContract: true,
        },
      };
    case "CLOSE_MENU_CONTRACTS":
      return {
        ...state,
        menu: {
          ...state.menu,
          menuContract: false,
        },
      };
    case SET_NEW_TOKEN:
      return {
        ...state,
        global_user_data: {
          ...state.global_user_data,
          data: {
            ...state.global_user_data.data,
            foundEvents: { ...state.global_user_data.data.foundLogin },
            token: action.data,
          },
        },
      };
    default:
      return state;
  }
};

export default reducer;
