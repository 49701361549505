import React, { useState, useEffect } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { FirestoreProvider } from "../../../../utils/firestoreProvider";
// location
import { useLocation } from "react-router-dom";
// componentes
import Totalizer from "./totalizer";
import TableAccess from "./tableAccess";
import TableOnline from "./tableOnline";
import TableChat from "./tableChat";
import TableQuestion from "./tableQuestion";
import TableSuperEmoji from "./tableSuperEmoji";
// service
import { getMobileAppsByEvent } from "../../../../utils/services/mobileApps";
//redux
import { connect } from "react-redux";

function SuperTela3(props) {
  // ids
  const idEvent = props.idEvent;
  const idDashboard = props.idDashboard.transmissionId;
  const idChat = props.idDashboard.chatId;
  const { isOpen, toggle } = props;
  // location
  const location = useLocation();
  // listagem
  const [step, setStep] = useState("access");
  // apps
  const [mobileapps, setMobileApps] = useState();
  // dados das tabelas
  const [tableData, setTableData] = useState({
    access: {
      loading: true,
      data: [],
    },
    online: {
      loading: true,
      data: [],
    },
    chat: {
      loading: true,
      data: [],
    },
    question: {
      loading: true,
      data: [],
    },
    superEmoji: {
      loading: true,
      data: [],
    },
  });

  async function getMobileAppEvent() {
    await getMobileAppsByEvent({
      jwt: props.state.global_user_data.data.token,
      eventId: idEvent,
    }).then((response) => {
      // console.log("ok", response);
      if (response.findOneMobileAppData.length > 0) {
        const idsArray = [...new Set(response.findOneMobileAppData)];

        var searchIdFirebaseApp = idsArray.find(
          (e) => e.bundle != "com.inteegra.mice.plus"
        );

        if (searchIdFirebaseApp) {
          setMobileApps(searchIdFirebaseApp.idfirebaseproject);
        } else {
          var searchIdFirebaseInteegra = idsArray.find(
            (e) => e.bundle === "com.inteegra.mice.plus"
          );

          if (searchIdFirebaseInteegra) {
            setMobileApps(searchIdFirebaseInteegra.idfirebaseproject);
          }
        }
      }
    });
    // .catch((error) => console.log(error));
  }

  function toggleFunc() {
    setMobileApps(null);
    toggle();
  }

  useEffect(() => {
    getMobileAppEvent();
  }, []);

  return (
    <FirestoreProvider
      idEvent={idEvent}
      idDashboard={idDashboard}
      idChat={idChat}
      idFireStore={mobileapps}
    >
      <Modal isOpen={isOpen} centered autoFocus size="xl">
        <ModalHeader toggle={() => toggleFunc()}>Dashboard</ModalHeader>
        <ModalBody style={{ backgroundColor: "#E4E8E7" }}>
          <Totalizer setStep={setStep} step={step} idChat={idChat} />
          {step === "access" && (
            <TableAccess
              idEvent={idEvent}
              idDashboard={idDashboard}
              tableData={tableData}
              setTableData={setTableData}
              mobileapps={mobileapps}
            />
          )}
          {step === "online" && (
            <TableOnline
              idEvent={idEvent}
              idDashboard={idDashboard}
              tableData={tableData}
              setTableData={setTableData}
              mobileapps={mobileapps}
            />
          )}
          {step === "chat" && (
            <TableChat
              idEvent={idEvent}
              idDashboard={idDashboard}
              tableData={tableData}
              setTableData={setTableData}
              idChat={idChat}
              mobileapps={mobileapps}
            />
          )}
          {step === "question" && (
            <TableQuestion
              idEvent={idEvent}
              idDashboard={idDashboard}
              tableData={tableData}
              setTableData={setTableData}
              mobileapps={mobileapps}
            />
          )}
          {step === "emoji" && (
            <TableSuperEmoji
              idEvent={idEvent}
              idDashboard={idDashboard}
              tableData={tableData}
              setTableData={setTableData}
              mobileapps={mobileapps}
            />
          )}
        </ModalBody>
      </Modal>
    </FirestoreProvider>
  );
}

const mapStateToProps = function (state) {
  return { state: state };
};

export default connect(mapStateToProps)(SuperTela3);
