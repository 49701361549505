import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader, Row, Col, Button } from "reactstrap";
//redux
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
// service
import { createEarnedPoints } from "../../../utils/services/ranking";
// componentes
import ModalSearchUser from "./modalSearchUser";
// alerta
import SweetAlert from "react-bootstrap-sweetalert";

function ModalCreatePoint(props) {
  const { isOpen, toggle, getAllRankingFunc } = props;
  const [selected, setSelected] = useState(null);
  const [idPoint, setIdPoint] = useState(null);
  // modal
  const [modalSearch, setModalSearch] = useState(false);
  // alerta
  const [alertSuccess, setAlertSuccess] = useState(false);
  const [alertError, setAlertError] = useState("");
  // location
  const location = useLocation();

  async function submitPoint() {
    const dataRanking = {
      code: idPoint,
      positive: true,
      log: "add points plus",
    };

    await createEarnedPoints(
      dataRanking,
      location.state.idEvent,
      selected.id
    ).then((res) => {
      console.log("ok", res);
      if (res.message === "ADDED_TO_QUEUE") {
        setAlertSuccess(true);
      } else if (res.message === "CONFIGURATION_NOT_FOUND") {
        setAlertError("Código não encontrado");
      } else setAlertError("Erro ao pontuar participante");
    });
    // .catch((error) => console.log("erro", error));
  }

  return (
    <>
      {modalSearch && (
        <ModalSearchUser
          isOpen={modalSearch}
          toggle={() => setModalSearch(false)}
          setSelected={setSelected}
        />
      )}

      {alertSuccess && (
        <SweetAlert
          title="Sucesso!"
          success
          onConfirm={() => {
            getAllRankingFunc();
            toggle();
            setAlertSuccess(false);
          }}
        >
          Participante pontuado com sucesso
        </SweetAlert>
      )}

      {alertError && (
        <SweetAlert
          title="Erro"
          error
          onConfirm={() => {
            setAlertError(false);
          }}
        >
          {alertError}
        </SweetAlert>
      )}

      <Modal isOpen={isOpen} centered>
        <ModalHeader toggle={toggle}>Pontuar Participante</ModalHeader>
        <ModalBody>
          <Row>
            <Col
              md={5}
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <label className="form-label">Selecione um participante</label>
              <i
                className="bx bx-user-plus icon-add-user"
                onClick={() => setModalSearch(true)}
                style={{
                  backgroundColor: selected ? "#08f308" : "#4747fd",
                }}
              />
            </Col>

            <Col
              md={7}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <a onClick={() => console.log(selected)}>
                Nome: {selected && selected.nome ? selected.nome : "-"}
              </a>
              <a onClick={() => console.log(selected)}>
                Email: {selected && selected.email ? selected.email : "-"}
              </a>
            </Col>
          </Row>

          <Row style={{ marginTop: 10 }}>
            <Col md={12}>
              <label className="form-label">Código da pontuação</label>
              <input
                className="form-control"
                type="text"
                onChange={(e) => setIdPoint(e.target.value)}
              />
            </Col>
          </Row>

          <Row style={{ marginTop: 20 }}>
            <Col
              md={12}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Button
                className="btn-danger"
                style={{ marginRight: 10 }}
                onClick={() => toggle()}
              >
                Fechar
              </Button>
              <Button
                className="btn-success"
                disabled={!selected || !idPoint}
                onClick={() => submitPoint()}
              >
                Pontuar
              </Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => {
  return { state };
};

export default connect(mapStateToProps)(ModalCreatePoint);
