import React, { useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { useSelector } from "react-redux";

import { Button, Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";

import { postImg } from "../../utils/services/drive";
import UploadComponent from "../Apps/Personalization/UploadComponent";
import ButtonSpinner from "../../components/Buttons/ButtonSpinner";
import { allowedMimeTypes } from "./static/mimeTypes";

export default function NewImg(props) {
  const { isOpen, toggle, refetch } = props;
  const { token: jwt } = useSelector((state) => state.global_user_data.data);
  const [isLoading, setIsloading] = useState(false);

  const [modalBackdrop, setModalBackdrop] = useState(false);

  //campos fomulário
  const [name, setName] = useState("");
  const [image, setImage] = useState("");
  const [tags, setTags] = useState([]);

  //validações campos formulários
  const [nameError, setNameError] = useState(false);
  const [imageError, setImageError] = useState(false);

  //editado com sucesso
  const [success, setSuccess] = useState(false);

  function toggleBackdrop() {
    setModalBackdrop(!modalBackdrop);
  }

  function resetFields() {
    setName("");
    setImage("");
    setTags([]);
    setNameError(false);
    setImageError(false);
  }

  function addTags(newTags) {
    setTags([...new Set([...tags, newTags])]);
  }

  function removeTags(index) {
    const newTags = [...tags];
    newTags.splice(index, 1);
    setTags(newTags);
  }

  async function submitForm() {
    if (name === "") {
      return setNameError(true);
    } else {
      setNameError(false);
    }

    if (!image) {
      return setImageError("O campo Anexar arquivo é obrigatório.");
    }

    const file = image.files[0];
    const maxFileSize = 50 * 1024 * 1024;

    if (file.size > maxFileSize) {
      return setImageError("O arquivo deve ser menor que 50MB.");
    } else if (!allowedMimeTypes.includes(file.type)) {
      return setImageError("Tipo de arquivo inválido.");
    } else {
      setImageError(false);
    }
    const data = new FormData();
    data.append("files", file);
    data.append("name", name);
    data.append("tags", JSON.stringify(tags));

    try {
      setIsloading(true);
      await postImg(data, jwt);
      setIsloading(false);
      setSuccess(true);
    } catch (error) {
      setIsloading(false);
      // console.log(error);
    }

    resetFields();
    await refetch();
  }

  return (
    <Modal
      size="lg"
      isOpen={isOpen}
      toggle={() => {
        toggleBackdrop();
      }}
      onClosed={() => {
        resetFields();
      }}
      autoFocus={true}
      centered={true}
    >
      {success && (
        <SweetAlert
          success
          title="Upload de arquivo"
          onConfirm={() => {
            setSuccess(false);
            resetFields();
            toggle();
          }}
        >
          Arquivo criado com sucesso!
        </SweetAlert>
      )}
      <ModalHeader toggle={toggle}>Upload de Arquivo</ModalHeader>
      <ModalBody>
        <form>
          <div>
            <Row style={{ marginTop: 10 }}>
              <Col>
                <label className="form-label">Nome do arquivo*</label>
                <input
                  className="form-control"
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                {nameError && (
                  <p style={{ color: "red" }}>O campo Nome é obrigatório.</p>
                )}
              </Col>
            </Row>
          </div>
          <div>
            <Row style={{ marginTop: 10 }}>
              <Col>
                <label className="form-label">Tags</label>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Digite e pressione a tecla Enter para adicionar a Tag"
                  onKeyPress={(e) => {
                    if (e.key === "Enter" && e.target.value !== "") {
                      addTags(e.target.value);
                      e.target.value = "";
                    }
                  }}
                />
              </Col>
            </Row>
            {tags?.length > 0 && (
              <Row>
                <div className="tag-container">
                  {tags.map((tags, index) => (
                    <div key={index} className="tag-wrapper">
                      <span className="tags-field">{tags}</span>
                      <i
                        className="mdi mdi-close"
                        onClick={() => removeTags(index)}
                      />
                    </div>
                  ))}
                </div>
              </Row>
            )}
          </div>
          <UploadComponent
            id="formFile"
            labelText="Anexar arquivo"
            setStateFunc={setImage}
            tipText="Tamanho máximo 50MB."
          />
          {imageError && <p style={{ color: "red" }}>{imageError}</p>}
          <Row
            style={{
              width: "100%",
              marginTop: 15,
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              color="danger"
              style={{ width: 80, marginRight: 10 }}
              onClick={() => toggle()}
            >
              Fechar
            </Button>
            <ButtonSpinner
              isLoading={isLoading}
              title={"Upload"}
              onClick={submitForm}
            />
          </Row>
        </form>
      </ModalBody>
    </Modal>
  );
}
