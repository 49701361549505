import React from "react";
// carregamento
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export default function LoadingSkelentonTable(props) {
  return (
    // recebe 2 parametros: lines e cols (quantia de linhas e colunas) em number
    <>
      {[...Array(Math.ceil(props.lines))].map(() => (
        <tr>
          {[...Array(Math.ceil(props.cols))].map(() => (
            <td>
              <Skeleton />
            </td>
          ))}
        </tr>
      ))}
    </>
  );
}
