import React, { useState, useEffect } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { useSelector } from "react-redux";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  Button,
  Collapse,
  UncontrolledTooltip,
} from "reactstrap";
import moment from "moment";
import { editSorteio } from "../../../utils/services/sorteios";
import { getListSessions } from "../../../utils/services/scheduleCotas";

import { useLocation } from "react-router-dom";
import ButtonSpinner from "../../../components/Buttons/ButtonSpinner";

const formatDate = (date) => {
  return date.split("T")[0].split("-").reverse().join("/");
};

export default function ModalNewSorteio(props) {
  const location = useLocation();
  const { isOpen, toggle, refetch, selectedSorteioEdit } = props;
  const { id: eventId } = location.state.event;
  const { token: jwt } = useSelector((state) => state.global_user_data.data);
  const [modalBackdrop, setModalBackdrop] = useState(false);
  const [isLoading, setIsloading] = useState(false);

  const [error, setError] = useState(false);
  const [quotasError, setQuotasError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [cotas, setCotas] = useState();
  const [startTime, setStartTime] = useState();
  const [endTime, setEndTIme] = useState();

  const [generalSessions, setGeneralSession] = useState([]);
  const [sessionsSource, setSessionsSource] = useState([]);
  const [sessions, setSessions] = useState([]);

  async function listSessions() {
    if (!eventId || !jwt) {
      return;
    }

    const res = await getListSessions(eventId, jwt);

    if (res.data.cotasManuais.length > 0) {
      setSessionsSource(res.data.cotasManuais);
      setGeneralSession(res.data.cotasManuais);
      const formattedData = res.data.cotasManuais
        .filter(
          (item) =>
            /* !sessionsData.some(
              (session) => session.sessionId === item.agendaid
            ) && */
            /* parseInt(item.availablemanuais) > 0 || */
            parseInt(item.availablesorteio) > 0 /* || */
          /* parseInt(item.availablefechadas) > 0 */
        )
        .map((e) => ({
          label: `${e.title} - ${formatDate(e.startdate)} ${e.starthour}`,
          value: e.agendaid,
        }));
      setSessions(formattedData);
    }
  }

  const handleChange = (event) => {
    let valor = event.target.value;

    let limit = generalSessions.find(
      (e) => e.agendaid == selectedSorteioEdit.session?.id
    )?.availablesorteio;

    var limitTotal =
      parseInt(limit) + parseInt(selectedSorteioEdit.quotasAmount);

    if (valor > limitTotal) {
      valor = limitTotal;
    }
    setCotas(valor);
  };

  function toggleBackdrop() {
    setModalBackdrop(!modalBackdrop);
  }

  async function submitForm(e) {
    if (cotas < 1) {
      setQuotasError(true);
      return;
    } else setQuotasError(false);

    let data = {
      id: selectedSorteioEdit.id,
      quotasAmount: parseInt(cotas),
      startRegistration: new Date(startTime),
      endRegistration: new Date(endTime),
      jwt: jwt,
    };
    setIsloading(true);
    await editSorteio(data);
    setIsloading(false);
    toggle();
    refetch();
  }

  useEffect(() => {
    setCotas(selectedSorteioEdit.quotasAmount);
    setStartTime(selectedSorteioEdit.startRegistration);
    setEndTIme(selectedSorteioEdit.endRegistration);
  }, []);

  useEffect(() => {
    listSessions();
  }, []);

  return (
    <Modal isOpen={isOpen} toggle={() => toggleBackdrop()} autoFocus centered>
      <ModalHeader toggle={toggle}>Editar Sorteio</ModalHeader>
      <ModalBody style={{ padding: 20 }}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            submitForm(e);
          }}
        >
          <Row style={{ paddingLeft: 0, paddingRight: 0 }}></Row>

          <Row className="mt-2 mb-3">
            <label className="form-label" style={{ padding: 0 }}>
              ID da Sessão
            </label>
            <input
              className="form-control"
              value={selectedSorteioEdit.session.id}
              disabled
            />
          </Row>
          <Row className="mt-2 mb-3">
            <label className="form-label" style={{ padding: 0 }}>
              Sessão
            </label>
            <input
              className="form-control"
              value={selectedSorteioEdit.session.title}
              disabled
            />
          </Row>
          <Row className="mt-2 mb-3">
            <label className="form-label" style={{ padding: 0 }}>
              ID do Sorteio
            </label>
            <input
              className="form-control"
              value={selectedSorteioEdit.id}
              disabled
            />
          </Row>
          <Row className="mt-2 mb-3">
            <label className="form-label" style={{ padding: 0 }}>
              Quantidade de Cotas*
            </label>
            <input
              className="form-control"
              type="number"
              name="cotas"
              id="cotas"
              min="0"
              value={cotas}
              onChange={handleChange}
            />
            {quotasError && (
              <a style={{ color: "red" }}>
                O campo Quantidade de Cotas é obrigatório
              </a>
            )}
          </Row>
          <Row>
            <Col md={6} style={{ paddingLeft: 0 }}>
              <label className="form-label">Data de Início*</label>
              <input
                className="form-control"
                type="datetime-local"
                id="example-datetime-local-input"
                disabled={new Date() > new Date(startTime) ? true : false}
                value={moment.utc(startTime).format("YYYY-MM-DDTHH:mm")}
                onChange={(e) =>
                  setStartTime(
                    moment.utc(e.target.value).format("YYYY-MM-DDTHH:mm")
                  )
                }
              />

              {error && (
                <p style={{ color: "red", padding: 0 }}>
                  O campo Data de Início é obrigatório
                </p>
              )}
            </Col>

            <Col md={6} style={{ paddingLeft: 0, paddingRight: 0 }}>
              <label className="form-label">Data de Encerramento*</label>
              <input
                className="form-control"
                type="datetime-local"
                id="example-datetime-local-input"
                value={moment.utc(endTime).format("YYYY-MM-DDTHH:mm")}
                onChange={(e) =>
                  setEndTIme(
                    moment.utc(e.target.value).format("YYYY-MM-DDTHH:mm")
                  )
                }
              />
              {error && (
                <p style={{ color: "red", padding: 0 }}>
                  O campo Data de Início é obrigatório
                </p>
              )}
            </Col>
          </Row>

          <Row
            style={{
              width: "100%",
              marginTop: 15,
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              color="danger"
              style={{ width: 80, marginRight: 10 }}
              onClick={() => toggle()}
            >
              Fechar
            </Button>

            {/* <Button
              color="success"
              className="btn btn-success waves-effect waves-light"
              style={{ width: 80 }}
              type="sumit"
            >
              Salvar
            </Button> */}
            <ButtonSpinner isLoading={isLoading} title={"Salvar"} />
          </Row>
        </form>
      </ModalBody>
    </Modal>
  );
}
