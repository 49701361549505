import { useState } from "react";
import moment from "moment";
import "moment/locale/pt-br";
import ModalPreview from "./modalPreview";
import { Link } from "react-router-dom";
import { deleteTemplate } from "../../utils/services/rsvp";
import SweetAlert from "react-bootstrap-sweetalert";
import { useLocation, useNavigate } from "react-router-dom";

function RowTemplate(props) {
  const history = useNavigate();

  const [modal, setModal] = useState(false);
  const [showAlertDelete, setShowAlertDelete] = useState(false);
  const toggle = () => setModal(!modal);

  const deleteTemplateRow = async () => {
    setShowAlertDelete(false);
    let data = await deleteTemplate({
      jwt: props.token,
      payload: {
        id: props.e.id,
      },
    });
    props.getTemplates();
    props.setDeleteConfirm(true);
  };

  return (
    <>
      {showAlertDelete && (
        <SweetAlert
          warning
          showCancel
          confirmBtnText="Sim"
          cancelBtnText="Não"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          title={`Você tem certeza que deseja deletar o template "${props.e.name}"?`}
          onConfirm={() => deleteTemplateRow()}
          onCancel={() => {
            setShowAlertDelete(false);
          }}
        >
          Você não poderá reverter essa ação.
          <br />
          <br />
          <br />
        </SweetAlert>
      )}

      <ModalPreview
        toggle={toggle}
        modal={modal}
        setModal={setModal}
        template={props.e}
        {...props}
      />
      <tr key={props.i}>
        <th scope="row">{props.e.id}</th>
        <th scope="row">{props.e.name}</th>
        <td>{moment(props.e.createdAt).format("LLL")}</td>
        <td>{moment(props.e.updatedAt).format("LLL")}</td>
        <td>
          <span
            className={
              props.e.type != "email"
                ? "template_type_label_web"
                : "template_type_label_email"
            }
          >
            {props.e.type}
          </span>
        </td>
        <td
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <i
            className="mdi mdi-eye"
            style={{
              color: "blue",
              fontSize: 17,
              cursor: "pointer",
              marginRight: 10,
            }}
            onClick={toggle}
          />{" "}
          <i
            onClick={() => {
              history("/html-editor", {
                state: {
                  type_template: props.e.type,
                  editMode: true,
                  template: props.e,
                },
              });
            }}
            className="mdi mdi-pencil"
            style={{
              color: "green",
              fontSize: 17,
              cursor: "pointer",
              marginRight: 10,
            }}
          />
          <i
            onClick={() => setShowAlertDelete(true)}
            className="mdi mdi-trash-can"
            style={{
              color: "red",
              fontSize: 17,
              cursor: "pointer",
            }}
          />{" "}
        </td>
      </tr>
    </>
  );
}

export default RowTemplate;
