function cpfIsValid(strCPF) {
  const error = {
    isValid: false,
    message: 'CPF inválido',
  };

  const success = {
    isValid: true,
    message: 'CPF Válido'
  };

  try {
    const cpf = strCPF.replace(/[^\d]+/g,'');

    let Soma;
    let Resto;
    Soma = 0;
    //strCPF  = RetiraCaracteresInvalidos(strCPF,11);

    if (cpf === "00000000000") return error;

    for (let i = 1; i <= 9; i++) Soma = Soma + parseInt(cpf.substring(i-1, i)) * (11 - i);
    Resto = (Soma * 10) % 11;

    if ((Resto === 10) || (Resto === 11)) Resto = 0;
    if (Resto !== parseInt(cpf.substring(9, 10))) return error;

    Soma = 0;
    for (let i = 1; i <= 10; i++) Soma = Soma + parseInt(cpf.substring(i-1, i)) * (12 - i);
    Resto = (Soma * 10) % 11;

    if ((Resto === 10) || (Resto === 11)) Resto = 0;
    if (Resto !== parseInt(cpf.substring(10, 11))) return error;
  
    return success;
  } catch (error) {
    // console.log(error);
    return error;
  }
}

function cnpjIsValid(strCNPJ) {
  const error = {
    isValid: false,
    message: 'CNPJ inválido',
  }

  const success = {
    isValid: true,
    message: 'CNPJ Válido'
  }

  try {
    const cnpj = strCNPJ.replace(/[^\d]+/g,'');
  
    if(cnpj === '') return error;
     
    if (cnpj.length !== 14) return error;
  
    // Elimina CNPJs invalidos conhecidos
    if (cnpj === "00000000000000" ||
        cnpj === "11111111111111" ||
        cnpj === "22222222222222" ||
        cnpj === "33333333333333" ||
        cnpj === "44444444444444" ||
        cnpj === "55555555555555" ||
        cnpj === "66666666666666" ||
        cnpj === "77777777777777" ||
        cnpj === "88888888888888" ||
        cnpj === "99999999999999") {
          return error;
        }
  
    // Valida DVs
    let tamanho = cnpj.length - 2
    let numeros = cnpj.substring(0,tamanho);
    let digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i -= 1) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) {
        pos = 9;
      }
    }
  
    let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado !== digitos.charAt(0)) {
      return error;
    }
  
    tamanho = tamanho + 1;
    numeros = cnpj.substring(0,tamanho);
    soma = 0;
    pos = tamanho - 7;
  
    for (let i = tamanho; i >= 1; i -= 1) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) {
        pos = 9;
      }
    }
  
    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado !== digitos.charAt(1)) {
      return error;
    }
  
    return success;
  } catch (error) {
    // console.log(error);
    return error;
  }
}

function phoneIsValid(strPhone) {
  const error = {
    isValid: false,
    message: 'Telefone inválido',
  };

  const success = {
    isValid: true,
    message: 'Telefone Válido'
  };

  const phone = strPhone.replace(/[^\d]+/g,'');
  if (phone.length < 10) {
    return error;
  }

  return success;
}

function cellphoneIsValid(strCellphone) {
  const error = {
    isValid: false,
    message: 'Celular inválido',
  };

  const success = {
    isValid: true,
    message: 'Celular Válido'
  };

  const cellphone = strCellphone.replace(/[^\d]+/g,'');
  if (cellphone.length < 11) {
    return error;
  }

  return success;
}

function cepIsValid(strCEP) {
  const error = {
    isValid: false,
    message: 'CEP inválido',
  };

  const success = {
    isValid: true,
    message: 'CEP Válido'
  };

  const cep = strCEP.replace(/[^\d]+/g,'');
  if (cep.length < 8) {
    return error;
  }

  return success;
}

function dateIsValid(strDate) {
  const error = {
    isValid: false,
    message: 'Data inválida',
  };

  const success = {
    isValid: true,
    message: 'Data Válida'
  };

  const months = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  const date = strDate.split('/');

  if (date.some((item) => item === '00')) {
    return error;
  }

  if (date.length < 3) {
    return error;
  }

  if (+date[0] > months[+date[1] -1]) {
    return error;
  }

  if (+date[1] > 12) {
    return error;
  }

  return success;
}

function timeIsValid(strTime) {
  const error = {
    isValid: false,
    message: 'Hora inválida',
  };

  const success = {
    isValid: true,
    message: 'Hora Válida'
  };

  try {
    const time = strTime.split(':');
    if (time.length < 2) {
      return error;
    }
  
    if (+time[0] > 23 || +time[1] > 59) {
      return error;
    }

    return success;
  } catch(error) {
    // console.log(error);
    return error;
  }
}

function emailIsValid(strEmail) {
  const error = {
    isValid: false,
    message: 'Email inválido',
  };

  const success = {
    isValid: true,
    message: 'Email Válido'
  };

  const regexEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regexEmail.test(strEmail) ? success : error;
}

export function validation(value, type) {
  switch (type) {
    case 'cpf':
      return cpfIsValid(value);
    case 'cnpj':
      return cnpjIsValid(value);
    case 'phone':
      return phoneIsValid(value);
    case 'cellphone':
      return cellphoneIsValid(value);
    case 'cep':
      return cepIsValid(value);
    case 'date':
      return dateIsValid(value);
    case 'time':
      return timeIsValid(value);
    case 'email':
      return emailIsValid(value);
    default:
      return {
        isValid: true,
        message: 'Sem validação'
      };
  }
}
