import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody, Row, Col, Button } from "reactstrap";
// componentes
import ButtonSpinner from "../../../components/Buttons/ButtonSpinner";
// alerta
import SweetAlert from "react-bootstrap-sweetalert";
import { QrReader } from "react-qr-reader";
import ModalEditParticipantQRCode from "./modalEditParticipantQRCode";
import { getParticipantById } from "../../../utils/services/participants";

export default function ModalQRCode(props) {
  const { isOpen, toggle, data, eventId, vehicleId, getaAll } = props;
  const [isLoading, setIsloading] = useState(false);
  // valor do campo
  const [selected, setSelected] = useState("");
  const [notes, setNotes] = useState(null);
  //   alerta
  const [notesError, setNotesError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [scan, setScan] = useState(true);
  const [qrcodeReaderBtn, setQrcodeReaderBtn] = useState(true);
  const [showMessage, setShowMessage] = useState(false);
  const [modalEditParticipant, setModalEditParticipant] = useState(false);
  const [participant, setParticipant] = useState("");
  const [errorFindParticipant, setErrorFindParticipant] = useState(false);
  const [newParticipantInVehicle, setNewParticipantInVehivle] = useState(false);

  async function scanQrcode(e) {
    setNewParticipantInVehivle(false);
    const findParticipant = data.filter(
      (participant) => participant.participantId === e.text
    );

    if (findParticipant.length > 0) {
      setParticipant(findParticipant);
      setScan(false);
      setNewParticipantInVehivle(false);
      setModalEditParticipant(true);
    } else {
      getParticipantById(eventId, e.text, 1, 1).then((res) => {
        const resp = res.data.data;
        if (resp.length > 0) {
          const participantFound = [
            {
              participantEmail: resp[0].email,
              participantId: resp[0].id,
              participantName: resp[0].nome,
              status: "",
            },
          ];
          setParticipant(participantFound);
          setScan(false);
          setNewParticipantInVehivle(true);
          setModalEditParticipant(true);
        } else {
          setErrorFindParticipant(true);
        }
      });
    }
  }
  function getAllPart() {
    getaAll();
  }

  return (
    <>
      {modalEditParticipant && (
        <ModalEditParticipantQRCode
          isOpen={modalEditParticipant}
          toggle={() => {
            setModalEditParticipant(false);
          }}
          eventId={eventId}
          vehicleId={vehicleId}
          getAll={getAllPart}
          data={participant}
          newParticipantInVehicle={newParticipantInVehicle}
        />
      )}
      {success && (
        <SweetAlert
          success
          title={"Atualizado"}
          onConfirm={() => {
            setSuccess(false);
            toggle();
          }}
        >
          {"Status atualizado com sucesso!"}
        </SweetAlert>
      )}
      {errorFindParticipant && (
        <SweetAlert
          warning
          showCancel
          cancelBtnText="Fechar"
          showConfirm={false}
          cancelBtnBsStyle="warning"
          title={"Atenção!"}
          onCancel={() => {
            setErrorFindParticipant(false);
            toggle();
          }}
        >
          {"Participante não encontrado."}
        </SweetAlert>
      )}

      <Modal isOpen={isOpen} centered autoFocus>
        <ModalHeader toggle={toggle}>Ler QRCODE</ModalHeader>
        <ModalBody>
          <div
            style={{
              position: "relative",
              width: "100%",
              height: "auto",
              padding: 0,
              margin: 0,
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "flex-end",
              }}
            >
              {!scan && (
                <Button
                  color="success"
                  className="btn-rounded waves-effect waves-light events-button-add"
                  onClick={() => {
                    setScan(true);
                  }}
                >
                  Abrir Leitor
                </Button>
              )}
              {scan && (
                <Button
                  color="success"
                  className="btn-rounded waves-effect waves-light events-button-add"
                  onClick={() => {
                    setScan(false);
                  }}
                >
                  Fechar Leitor
                </Button>
              )}
            </div>

            {/* Leitor de QR Code */}

            {scan && (
              <div>
                <QrReader
                  onResult={(result, error) => {
                    if (!!result) {
                      scanQrcode(result);
                    }

                    if (!!error) {
                      // console.info(error);
                    }
                  }}
                  // onError={(err) => console.error(err)}
                />
                <div
                  style={{
                    position: "absolute",
                    top: "30%",
                    left: "25%",
                    width: "40px",
                    height: "40px",
                    borderTop: "4px solid red",
                    borderLeft: "4px solid red",
                  }}
                ></div>

                <div
                  style={{
                    position: "absolute",
                    top: "30%",
                    right: "25%",
                    width: "40px",
                    height: "40px",
                    borderTop: "4px solid red",
                    borderRight: "4px solid red",
                  }}
                ></div>

                <div
                  style={{
                    position: "absolute",
                    bottom: "30%",
                    left: "25%",
                    width: "40px",
                    height: "40px",
                    borderBottom: "4px solid red",
                    borderLeft: "4px solid red",
                  }}
                ></div>

                <div
                  style={{
                    position: "absolute",
                    bottom: "30%",
                    right: "25%",
                    width: "40px",
                    height: "40px",
                    borderBottom: "4px solid red",
                    borderRight: "4px solid red",
                  }}
                ></div>
              </div>
            )}
          </div>
        </ModalBody>
      </Modal>
    </>
  );
}
