import React, { useState, useEffect } from "react";
import { Table, Row, Col, Collapse, Button } from "reactstrap";
//paginação
import Pagination from "../../../../components/Paginationv3";
// sessão mobile
import SectionComponent from "../../../Apps/Personalization/SectionComponent";
// nuvem de palavras
import ModalWordCloud from "./wordCloud";
// alerta
import SweetAlert from "react-bootstrap-sweetalert";
import {
  getChat,
  disableChat,
} from "../../../../utils/services/dashboardSuperScreen";
// carregamento de tabela
import LoadingSkelentonTable from "../../../../components/loadingSkeletonTable";
// exportar tabela
import { createXlsx } from "./exports/createXlsxChat";
// usedebounce
import useDebounce from "../../../../utils/hooks/useDebounce";
// componente de carregamento de exportação
import WaitExport from "../../../../components/WaitExport";
// provider do fire store
import { useFirestore } from "../../../../utils/firestoreProvider";
// componente do chat fixo
import ChatFixed from "./chatFixed";
// moment
import moment from "moment";

export default function TableChat(props) {
  // firestore
  const firestoreData = useFirestore();
  // ids do evento
  const eventId = props.idEvent;
  const idChat = props.idChat;

  // pesquisa da listagem
  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounce(search, 300);
  //paginação
  const [currentIndex, setCurrentIndex] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [countData, setCountData] = useState(0);
  // modal collapse
  const [isOpen, setIsOpen] = useState(false);
  // modal da nuvem de palavras
  const [modalWordCloud, setModalWordCloud] = useState(false);
  //deletar/desativar enquete
  const [deleteData, setDeleteData] = useState({});
  const [confirm_alert, setconfirm_alert] = useState(false);
  const [success_dlg, setsuccess_dlg] = useState(false);
  // fixar chat
  const [confirmChatFix, setConfirmChatFix] = useState(false);
  const [successChatFix, setSuccessChatFix] = useState(false);
  const [chatFixData, setChatFixData] = useState({});
  // desfixar chat
  const [confirmRemoveChatFix, setConfirmRemoveChatFix] = useState(false);
  const [successRemoveChatFix, setSuccessRemoveChatFix] = useState(false);
  // export in progress
  const [exportInProgress, setExportInProgress] = useState(false);
  const [finishExport, setFinishExport] = useState(false);
  // carregamento do botão
  const [loading, setLoading] = useState(false);
  const [loadingChatFixed, setLoadingChatFixed] = useState(false);

  function formatDate(date) {
    const dateFormat = date.split("T")[0].split("-").reverse().join("/");
    const time = date.split("T")[1].slice(0, 5);
    return `${dateFormat} - ${time}`;
  }

  async function verifyFixChat(e) {
    await firestoreData
      .fixeChat({
        msg: chatFixData.msg,
        enabled: e,
        avatar: chatFixData.avatar,
        name: chatFixData.name,
        eventIdChat: eventId,
        chatId: idChat,
        mobileapps: props.mobileapps,
      })
      .then((res) => {
        if (e) {
          setSuccessChatFix(true);
          setConfirmChatFix(false);
          setLoadingChatFixed(true);
        } else {
          setSuccessRemoveChatFix(true);
          setConfirmRemoveChatFix(false);
          setLoadingChatFixed(false);
        }
      });
  }

  function exportReport() {
    createXlsx({
      eventId: eventId,
      transmissionID: idChat,
      limit: itemsPerPage,
      offset: currentIndex - 1,
      search: "",
      countData: countData,
      setFinishExport,
      mobileapps: props.mobileapps,
    });
    setExportInProgress(true);
  }

  async function funcDeleteChat() {
    setLoading(true);
    await disableChat({
      eventId: eventId,
      transmissionID: idChat,
      chatId: deleteData.id,
      mobileapps: props.mobileapps,
    }).then((res) => {
      if (res.success === true) {
        setconfirm_alert(false);
        setsuccess_dlg(true);
      }
    });
    setLoading(false);
  }

  function getAllChat() {
    getChat({
      eventId: eventId,
      transmissionID: idChat,
      pageSize: itemsPerPage,
      offset: currentIndex - 1,
      search: debouncedSearch,
      mobileapps: props.mobileapps,
    }).then((res) => {
      // console.log("chat", res);
      if (res.success) {
        setCountData(res.total);
        props.setTableData((state) => {
          return {
            ...state,
            chat: {
              loading: false,
              data: res.data,
            },
          };
        });
      }
    });
  }

  function resetPagination() {
    setSearch("");
    setItemsPerPage(10);
    setCurrentIndex(1);
    if (props.mobileapps) {
      getAllChat();
    }
  }

  useEffect(() => {
    if (props.mobileapps) {
      getAllChat();
    }
  }, [debouncedSearch, itemsPerPage, currentIndex, props.mobileapps]);

  return (
    <div>
      <WaitExport
        isOpen={exportInProgress}
        name="Relatório"
        preposicao="dos"
        finishExport={finishExport}
        setFinishExport={setFinishExport}
        setExportInProgress={setExportInProgress}
      />

      {modalWordCloud && (
        <ModalWordCloud
          isOpen={modalWordCloud}
          toggle={() => setModalWordCloud(!modalWordCloud)}
          eventId={eventId}
          idChat={idChat}
          type={"chat"}
          mobileapps={props.mobileapps}
        />
      )}

      {confirm_alert ? (
        <SweetAlert
          title={`Deseja deletar o chat ${deleteData.msg}?`}
          warning
          showConfirm={false}
          showCancel={false}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <a style={{ marginBottom: 10 }}>
              Você não poderá reverter essa ação!
            </a>
            {loading ? (
              <a
                className="downloadCloud"
                style={{ backgroundColor: "grey", width: 120 }}
              >
                <i className="bx bx-loader bx-spin font-size-16 align-middle me-2" />
                Carregando
              </a>
            ) : (
              <div style={{ display: "flex" }}>
                <Button
                  color="success"
                  className="btn btn-success waves-effect waves-light"
                  style={{ width: 100, marginRight: 10 }}
                  onClick={() => funcDeleteChat()}
                >
                  Confirmar
                </Button>
                <Button
                  color="danger"
                  className="btn btn-danger waves-effect waves-light"
                  style={{ width: 100 }}
                  onClick={() => setconfirm_alert(false)}
                >
                  Cancelar
                </Button>
              </div>
            )}
          </div>
        </SweetAlert>
      ) : null}

      {success_dlg ? (
        <SweetAlert
          success
          title="Deletado"
          onConfirm={() => {
            getAllChat();
            setsuccess_dlg(false);
          }}
        >
          Chat deletado com sucesso
        </SweetAlert>
      ) : null}

      {confirmChatFix ? (
        <SweetAlert
          title={`Deseja fixar o chat ${chatFixData.msg}?`}
          warning
          showConfirm={false}
          showCancel={false}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            {loading ? (
              <a
                className="downloadCloud"
                style={{ backgroundColor: "grey", width: 120 }}
              >
                <i className="bx bx-loader bx-spin font-size-16 align-middle me-2" />
                Carregando
              </a>
            ) : (
              <div style={{ display: "flex" }}>
                <Button
                  color="success"
                  className="btn btn-success waves-effect waves-light"
                  style={{ width: 100, marginRight: 10 }}
                  onClick={() => verifyFixChat(true)}
                >
                  Confirmar
                </Button>
                <Button
                  color="danger"
                  className="btn btn-danger waves-effect waves-light"
                  style={{ width: 100 }}
                  onClick={() => setConfirmChatFix(false)}
                >
                  Cancelar
                </Button>
              </div>
            )}
          </div>
        </SweetAlert>
      ) : null}

      {successChatFix ? (
        <SweetAlert
          success
          title="Fixado"
          onConfirm={() => {
            setSuccessChatFix(false);
          }}
        >
          Chat fixado com sucesso
        </SweetAlert>
      ) : null}

      {confirmRemoveChatFix ? (
        <SweetAlert
          title={`Deseja desafixar o chat ${chatFixData.msg}?`}
          warning
          showConfirm={false}
          showCancel={false}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            {loading ? (
              <a
                className="downloadCloud"
                style={{ backgroundColor: "grey", width: 120 }}
              >
                <i className="bx bx-loader bx-spin font-size-16 align-middle me-2" />
                Carregando
              </a>
            ) : (
              <div style={{ display: "flex" }}>
                <Button
                  color="success"
                  className="btn btn-success waves-effect waves-light"
                  style={{ width: 100, marginRight: 10 }}
                  onClick={() => verifyFixChat(false)}
                >
                  Confirmar
                </Button>
                <Button
                  color="danger"
                  className="btn btn-danger waves-effect waves-light"
                  style={{ width: 100 }}
                  onClick={() => setConfirmRemoveChatFix(false)}
                >
                  Cancelar
                </Button>
              </div>
            )}
          </div>
        </SweetAlert>
      ) : null}

      {successRemoveChatFix ? (
        <SweetAlert
          success
          title="Desafixado"
          onConfirm={() => {
            setSuccessRemoveChatFix(false);
          }}
        >
          Chat desafixado com sucesso
        </SweetAlert>
      ) : null}

      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          margin: "10px 0",
        }}
      >
        <form
          className="app-search d-none d-lg-block events-input-search"
          onSubmit={(e) => e.preventDefault()}
        >
          <div className="position-relative">
            <input
              type="text"
              className="form-control"
              placeholder="Pesquisar..."
              value={search}
              onChange={(e) => setSearch(e.target.value.toLowerCase())}
            />
            <span className="bx bx-search-alt" />
          </div>
        </form>

        <div className="mobile-btn-section" style={{ width: "100%" }}>
          <Row className="mobile-btns-top">
            <form
              className="app-search"
              style={{ padding: "0px" }}
              onSubmit={(e) => e.preventDefault()}
            >
              <div className="position-relative">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Pesquisar..."
                  value={search}
                  onChange={(e) => setSearch(e.target.value.toLowerCase())}
                />
                <span className="bx bx-search-alt" />
              </div>
            </form>
          </Row>
          {props.tableData?.chat?.data?.length > 0 && (
            <>
              <SectionComponent
                sectionName="Opções"
                setIsOpen={setIsOpen}
                isOpen={isOpen}
              />
              <Collapse isOpen={isOpen}>
                <Row className="mobile-btns-top">
                  <div onClick={() => setModalWordCloud(true)}>
                    Nuvem de Palavras
                  </div>

                  <div onClick={() => exportReport()}>Exportar Mensagens</div>
                </Row>
              </Collapse>
            </>
          )}
        </div>

        <div className="header-btns-container">
          {props.tableData?.chat?.data?.length > 0 && (
            <>
              <Button
                color="success"
                className="btn-rounded waves-effect waves-light events-button-add"
                onClick={() => setModalWordCloud(true)}
              >
                Nuvem de Palavras
              </Button>

              <Button
                color="success"
                className="btn-rounded waves-effect waves-light events-button-add"
                onClick={() => exportReport()}
              >
                Exportar Mensagens
              </Button>
            </>
          )}
        </div>
      </div>

      <ChatFixed
        data={
          firestoreData?.data?.chatFixed[0]
            ? firestoreData.data.chatFixed[0]
            : null
        }
        setChatFixData={setChatFixData}
        setConfirmRemoveChatFix={setConfirmRemoveChatFix}
        loadingChatFixed={loadingChatFixed}
        lengthData={firestoreData.data.chatFixed.length}
      />
      {/* {firestoreData.data.chatFixed.length > 0 && (
      )} */}

      <div
        style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
      >
        <a className="btn-reset-table" onClick={() => resetPagination()}>
          <i className="mdi mdi-refresh" style={{ marginRight: 5 }} />
          Refresh
        </a>
      </div>

      <div className="table-responsive" style={{ backgroundColor: "white" }}>
        <Table className="table mb-0">
          <thead className="table-light">
            <tr>
              <th>ID do Participante</th>
              <th>Nome</th>
              <th>Mensagem</th>
              <th>Data e hora</th>
              <th>Ação</th>
            </tr>
          </thead>

          <tbody>
            {props.tableData.chat.loading ? (
              <LoadingSkelentonTable lines={3} cols={5} />
            ) : (
              props.tableData.chat.data.map((e, i) => (
                <tr key={i}>
                  <td>{e.userId}</td>
                  <td>{e.name}</td>
                  <td>{e.msg}</td>
                  <td>{formatDate(moment(e.date).format())}</td>
                  <td>
                    <i
                      className="mdi mdi-trash-can"
                      style={{
                        color: "red",
                        fontSize: 17,
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setDeleteData(e);
                        setconfirm_alert(true);
                      }}
                    />

                    <i
                      className="mdi mdi-pin-outline"
                      style={{
                        color: "blue",
                        fontSize: 17,
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setChatFixData(e);
                        setConfirmChatFix(true);
                      }}
                    />
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </Table>
      </div>
      <Row style={{ marginTop: 20 }}>
        <Col md={12}>
          <Pagination
            data={props.tableData.chat.data}
            search={search}
            currentIndex={currentIndex}
            setCurrentIndex={setCurrentIndex}
            countData={countData}
            maxItemPerPage={itemsPerPage}
            setMaxItemPerPage={setItemsPerPage}
            maxPageButtonsToShow={3}
          />
        </Col>
      </Row>
    </div>
  );
}
