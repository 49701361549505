import React from 'react';

import { Col, Row } from 'reactstrap';

export default function RankChart({ ranking }) {
  const totalUsers = Object.values(ranking).length > 0
    ? ranking.totalUserRanking + ranking.participantsWithoutRanking
    : 0;

  const totalUserRankingPercentage = totalUsers
    ? ((ranking.totalUserRanking / totalUsers) * 100).toFixed(2)
    : 0;

  const participantsWithoutRankingPercentage = totalUsers
    ? ((ranking.participantsWithoutRanking / totalUsers) * 100).toFixed(2)
    : 0;

  const userRankingText = `${ranking.totalUserRanking || 0} (${totalUserRankingPercentage})%`;
  const userWithoutRankingText = `${ranking.participantsWithoutRanking || 0} (${participantsWithoutRankingPercentage})%`;

  return (
    <div className="rank-wrapper">
      <Row>
        <span className="title">Ranking</span>
      </Row>
      <div className="sub-title-container margin-ranking">
        <div>
          <i className="fa fa-trophy" />
        </div>
        <div>
          <span>Total Geral de Pontos: {ranking.totalPoints || 0}</span>
        </div>
      </div>
      <Row className="point-container">
        <Col>
          <span>Usuários com pontuação</span>
        </Col>
        <Col>
          <span>{userRankingText}</span>
        </Col>
        <Col>
          <div
            data-title-withpoint={userRankingText}
            style={{
              width: `${totalUserRankingPercentage
                ? totalUserRankingPercentage
                : 100}%`,
              backgroundColor: `${totalUserRankingPercentage
                ? '#91cc75'
                : '#e6e6e6'}`
            }}
            className="with-point"
          />
        </Col>
      </Row>
      <hr />
      <Row className="point-container">
        <Col>
          <span>Usuários sem pontuação</span>
        </Col>
        <Col>
          <span>{userWithoutRankingText}</span>
        </Col>
        <Col>
          <div
            className="without-point"
            style={{
              width: `${participantsWithoutRankingPercentage
                ? participantsWithoutRankingPercentage
                : 100}%`,
              backgroundColor: `${participantsWithoutRankingPercentage
                ? '#ee6666'
                : '#e6e6e6'}`
            }}
            data-title-withoutpoint={userWithoutRankingText}
          />
        </Col>
      </Row>
    </div>
  )
}
