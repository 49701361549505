import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import {
  Card,
  CardBody,
  CardTitle,
  Table,
  Collapse,
  Row,
  Button,
} from "reactstrap";
//redux
import { connect } from "react-redux";
// alerta
import SweetAlert from "react-bootstrap-sweetalert";
// componentes
import SectionComponent from "../Apps/Personalization/SectionComponent";
import ModalNewEventType from "./ModalNewEventType";
import ModalEditEventType from "./ModalEditEventType";
import Pagination from "../../components/Paginationv3";
// service
import { getEventType, editEventType } from "../../utils/services/eventType";

function EventType(props) {
  const [isCollapse, setIsCollapse] = useState(false);
  // dados da requisição
  const { token, theme, foundLogin } = props.state.global_user_data.data;
  // modais
  const [modalNew, setModalNew] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  // alerta
  const [alertDelete, setAlertDelete] = useState(false);
  const [alertConfirmDelete, setAlertConfirmDelete] = useState(false);
  const [alertReactivate, setAlertReactivate] = useState(false);
  const [alertConfirmReactivate, setAlertConfirmReactivate] = useState(false);
  // paginação
  const [eventTypes, setEventTypes] = useState([]);
  const [countData, setCountData] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentIndex, setCurrentIndex] = useState(1);
  const [search, setSearch] = useState("");
  // filtro de ativo
  const [enabled, setEnabled] = useState(true);
  // editar, excluir e reativar
  const [editOneEventType, setEditOneEventType] = useState(null);
  const [deleteEventType, setDeleteEventType] = useState(null);
  const [reactivateEventType, setReactivateEventType] = useState(null);

  async function editEventTypeFunc(type, eventType) {
    const data = {
      contractId: theme[0].id,
      type: eventType.type,
      active: type,
    };
    await editEventType({
      data: data,
      jwt: token,
      id: eventType.id,
    })
      .then((res) => {
        if (res.message === "updated") {
          getListEventType();
          setAlertDelete(true);
          setAlertConfirmDelete(false);
          setAlertConfirmReactivate(false);
        }
      })
      .catch((error) => console.log("erro", error));
  }

  async function getListEventType() {
    await getEventType({
      jwt: token,
      offset: currentIndex,
      limit: itemsPerPage,
      search: search,
      active: enabled,
    })
      .then((res) => {
        if (res.message === "findEventTypes") {
          setEventTypes(res.findEventTypesData.data);
          setCountData(res.findEventTypesData.count);
        }
      })
      .catch((error) => console.log("erro", error));
  }

  useEffect(() => {
    getListEventType();
  }, [search, currentIndex, itemsPerPage, token, enabled]);

  return (
    <>
      {modalNew && (
        <ModalNewEventType
          isOpen={modalNew}
          toggle={() => setModalNew(!modalNew)}
          getListEventType={getListEventType}
          idContract={theme[0].id}
          token={token}
        />
      )}

      {modalEdit && (
        <ModalEditEventType
          isOpen={modalEdit}
          toggle={() => setModalEdit(!modalEdit)}
          editOneEventType={editOneEventType}
          getListEventType={getListEventType}
          idContract={theme[0].id}
          token={token}
        />
      )}

      {alertConfirmDelete && (
        <SweetAlert
          title={`Deseja deletar ${deleteEventType.type}?`}
          warning
          showCancel
          confirmBtnText="Sim"
          cancelBtnText="Não"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            editEventTypeFunc(false, deleteEventType);
          }}
          onCancel={() => setAlertConfirmDelete(false)}
        />
      )}

      {alertDelete && (
        <SweetAlert
          success
          title={"Concluído"}
          onConfirm={() => {
            setAlertDelete(false);
          }}
        >
          {"Ação concluída com sucesso!"}
        </SweetAlert>
      )}

      {alertConfirmReactivate && (
        <SweetAlert
          title={`Deseja reativar ${reactivateEventType.type}?`}
          warning
          showCancel
          confirmBtnText="Sim"
          cancelBtnText="Não"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            editEventTypeFunc(true, reactivateEventType);
          }}
          onCancel={() => setAlertConfirmReactivate(false)}
        />
      )}

      <MetaTags>
        <title>Tipos de Evento | Inteegra Plus</title>
      </MetaTags>

      <Card>
        <CardBody>
          <CardTitle>Tipos de Evento</CardTitle>

          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              marginBottom: 10,
            }}
          >
            <form
              className="app-search d-none d-lg-block events-input-search"
              onSubmit={(e) => e.preventDefault()}
            >
              <div className="position-relative">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Pesquisar..."
                  onChange={(e) => setSearch(e.target.value)}
                />
                <span className="bx bx-search-alt" />
              </div>
            </form>
            <div className="mobile-btn-section" style={{ width: "100%" }}>
              <SectionComponent
                sectionName="Opções"
                setIsOpen={setIsCollapse}
                isOpen={isCollapse}
              />
              <Collapse isOpen={isCollapse}>
                <Row className="mobile-btns-top">
                  <div
                    onClick={() => {
                      setEnabled(!enabled);
                    }}
                  >
                    {!enabled ? "Filtrar: Ativos" : "Filtrar: Inativos"}
                  </div>
                </Row>
                <Row className="mobile-btns-top">
                  <div onClick={() => setModalNew(true)}>
                    Adicionar Tipo de Evento
                  </div>
                </Row>
              </Collapse>
            </div>
            <div className="header-btns-container">
              <Button
                color="secondary"
                className="btn-rounded waves-effect waves-light events-button-add-lg"
                onClick={() => {
                  setEnabled(!enabled);
                }}
              >
                {!enabled ? "Filtrar: Ativos" : "Filtrar: Inativos"}
              </Button>

              <Button
                color="success"
                className="btn-rounded waves-effect waves-light events-button-add"
                onClick={() => setModalNew(true)}
              >
                Adicionar Tipo de Evento
              </Button>
            </div>
          </div>

          <div className="table-responsive">
            <Table className="table mb-0">
              <thead className="table-light">
                <tr>
                  <th>Tipos</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                {eventTypes.length > 0 &&
                  eventTypes.map((e, i) => (
                    <tr key={i}>
                      <td>{e.type}</td>
                      <td>
                        {enabled ? (
                          <>
                            <i
                              className="mdi mdi-pencil"
                              style={{
                                color: "green",
                                fontSize: 17,
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                setEditOneEventType(e) > setModalEdit(true)
                              }
                            />{" "}
                            <i
                              className="mdi mdi-trash-can"
                              style={{
                                color: "red",
                                fontSize: 17,
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                setDeleteEventType(e) >
                                setAlertConfirmDelete(true)
                              }
                            />
                          </>
                        ) : (
                          <i
                            className="mdi mdi-account-reactivate"
                            style={{
                              color: "green",
                              fontSize: 17,
                              cursor: "pointer",
                              marginLeft: 13,
                              minHeight: "100px",
                            }}
                            onClick={() =>
                              setReactivateEventType(e) >
                              setAlertConfirmReactivate(true)
                            }
                          />
                        )}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
        </CardBody>
      </Card>

      <div className="bottom-navigation" style={{ justifyContent: "end" }}>
        <Pagination
          data={eventTypes}
          search={search}
          currentIndex={currentIndex}
          setCurrentIndex={setCurrentIndex}
          countData={countData}
          maxItemPerPage={itemsPerPage}
          setMaxItemPerPage={setItemsPerPage}
          maxPageButtonsToShow={3}
        />
      </div>
    </>
  );
}

const mapStateToProps = function (state) {
  return { state: state };
};

export default connect(mapStateToProps)(EventType);
