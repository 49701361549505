import React, { useEffect, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  Button,
  Collapse,
  UncontrolledTooltip,
  Table,
  Card,
  CardBody,
  CardTitle,
} from "reactstrap";
import SectionComponent from "../../Apps/Personalization/SectionComponent";
import Pagination from "../../../components/Paginationv3";
import ModalParticipantSession from "./NewParticipantSession";
import ModalEditParticipant from "./EditParticipantSession";
import {
  listParticipants,
  deleteParticipant,
  sendEmailOnDeleteUser,
} from "../../../utils/services/sorteios";
import WaitExport from "../../../components/WaitExport";
import DeleteButton from "../../../components/Buttons/DeleteButton";

import { createXlsx } from "./createXls";

export default function ModalNewSorteio(props) {
  const { isOpen, toggle, eventId, refetch, selectedSorteioEdit } = props;
  const location = useLocation();

  const { token: jwt } = useSelector((state) => state.global_user_data.data);
  const [modalBackdrop, setModalBackdrop] = useState(false);
  //search and paginations
  const [search, setSearch] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [modalParticipantSession, setModalParticipantSession] = useState(false);
  const [editParticipant, setEditParticipant] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [participantSuccess, setParticipantSuccess] = useState(false);

  const [countData, setCountData] = useState(1);
  const [offset, setOffset] = useState(1);
  const [limit, setLImit] = useState(10);

  const [participantEditedSuccess, setParticipantEditedSuccess] =
    useState(false);

  const [selectedParticipant, setSelectedParticipant] = useState(null);

  //deletar evento
  const [confirm_alert, setconfirm_alert] = useState(false);
  const [success_dlg, setsuccess_dlg] = useState(false);
  const [alertEmail, setAlertEmail] = useState(false);
  //export
  const [exportInProgress, setExportInProgress] = useState(false);
  const [finishExport, setFinishExport] = useState(false);

  const [items, setItems] = useState([]);

  function checkProductExists(product) {
    if (location.state) {
      const search = location.state.event.product.filter(
        (e) => e.name === product
      );

      if (search[0]) {
        return true;
      } else return false;
    }
  }

  function toggleBackdrop() {
    setModalBackdrop(!modalBackdrop);
  }

  async function deleteUser() {
    await deleteParticipant({ id: selectedParticipant.id, jwt: jwt }).then(
      (res) => {
        setconfirm_alert(false);
        setAlertEmail(false);
        setsuccess_dlg(true);
      }
    );
    // .catch((error) => console.log("erro delete", error));
  }

  async function sendEmail() {
    const data = {
      eventId: eventId,
      name: selectedParticipant.name,
      email: selectedParticipant.email,
      companionsNumber: selectedParticipant.companionsNumber,
      title: selectedParticipant.session.title,
      startDate: selectedParticipant.session.startDate,
      startHour: selectedParticipant.session.startHour,
    };

    await sendEmailOnDeleteUser(data, jwt).then((res) => {
      if (res.message === "email_sended") {
        deleteUser();
      }
    });
    // .catch((error) => console.log("erro", error));
  }

  async function getItens() {
    const { findAllSorteiosData } = await listParticipants({
      id: props.selectedSorteioEdit.id,
      jwt: jwt,
      offset: offset,
      limit: limit,
      search: search,
    });
    setCountData(findAllSorteiosData.count);
    setItems(findAllSorteiosData?.data);
  }

  useEffect(() => {
    getItens();
  }, [offset, limit, search]);

  return (
    <>
      <WaitExport
        isOpen={exportInProgress}
        name="Participantes"
        preposicao="dos"
        finishExport={finishExport}
        setFinishExport={setFinishExport}
        setExportInProgress={setExportInProgress}
      />

      {confirm_alert ? (
        <SweetAlert
          title="Deseja deletar esse participante?"
          warning
          showCancel
          confirmBtnText="Sim"
          cancelBtnText="Não"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={async () => {
            if (checkProductExists("Distribuição de Cotas")) {
              setAlertEmail(true);
            } else deleteUser();
          }}
          onCancel={() => setconfirm_alert(false)}
        >
          Você não poderá reverter essa ação!
        </SweetAlert>
      ) : null}

      {alertEmail && (
        <SweetAlert
          warning
          title={"Deseja enviar email ao participante?"}
          showCancel
          confirmBtnText="Sim"
          cancelBtnText="Não"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => sendEmail()}
          onCancel={() => deleteUser()}
        >
          Você não poderá reverter essa ação!
        </SweetAlert>
      )}

      {success_dlg ? (
        <SweetAlert
          success
          title="Deletado"
          onConfirm={() => {
            setsuccess_dlg(false);
            getItens();
          }}
        >
          Participante deletado com sucesso
        </SweetAlert>
      ) : null}

      {participantSuccess ? (
        <SweetAlert
          success
          title="Criado"
          onConfirm={() => {
            setParticipantSuccess(false);
          }}
        >
          Participante criado com sucesso
        </SweetAlert>
      ) : null}

      {modalParticipantSession && (
        <ModalParticipantSession
          isOpen={modalParticipantSession}
          toggle={() => setModalParticipantSession(!modalParticipantSession)}
          refetch={() => {
            setParticipantSuccess(true);
            getItens();
          }}
          selectedSorteioEdit={selectedSorteioEdit}
        />
      )}

      {editParticipant && (
        <ModalEditParticipant
          isOpen={editParticipant}
          toggle={() => setEditParticipant(!editParticipant)}
          eventId={props.idEvent}
          refetch={() => {
            getItens();
            setParticipantEditedSuccess(true);
          }}
          selectedSorteioEdit={selectedSorteioEdit}
          selectedParticipant={selectedParticipant}
        />
      )}
      <Modal
        isOpen={isOpen}
        toggle={() => toggleBackdrop()}
        autoFocus
        centered
        size="xl"
      >
        {success && (
          <SweetAlert
            success
            title="Criado"
            onConfirm={() => {
              setSuccess(false);
              toggle();
            }}
          >
            Participante criado com sucesso
          </SweetAlert>
        )}

        {participantEditedSuccess && (
          <SweetAlert
            success
            title="Editado"
            onConfirm={() => {
              setParticipantEditedSuccess(false);
              // toggle();
            }}
          >
            Participante editado com sucesso
          </SweetAlert>
        )}

        <ModalHeader toggle={toggle}>Participantes</ModalHeader>
        <ModalBody style={{ padding: 20 }}>
          {/* <CardTitle>Sorteios</CardTitle> */}
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              marginBottom: 10,
            }}
          >
            <form
              className="app-search d-none d-lg-block events-input-search"
              onSubmit={(e) => e.preventDefault()}
            >
              <div className="position-relative">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Pesquisar..."
                  onChange={(e) => setSearch(e.target.value)}
                />
                <span className="bx bx-search-alt" />
              </div>
            </form>
            <div className="header-btns-container">
              {items?.length > 0 && (
                <Button
                  color="success"
                  className="btn-rounded waves-effect waves-light events-button-add"
                  onClick={() =>
                    createXlsx({
                      jwt: jwt,
                      id: props.selectedSorteioEdit.id,
                      idEvent: selectedSorteioEdit.eventId,
                      limit: 500,
                      search: "",
                      countData,
                      setFinishExport,
                    }) > setExportInProgress(true)
                  }
                >
                  Exportar Participantes
                </Button>
              )}

              {selectedSorteioEdit.status == "NAO_SORTEADO" && (
                <Button
                  color="success"
                  className="btn-rounded waves-effect waves-light events-button-add"
                  onClick={() => setModalParticipantSession(true)}
                >
                  Adicionar Participantes
                </Button>
              )}
            </div>
          </div>
          <div className="table-responsive mb-3">
            <Table className="table mb-0">
              <thead className="table-light">
                <tr>
                  <th>ID Participante</th>
                  <th>Nome</th>
                  <th>Email</th>
                  <th>Sessão</th>
                  <th>Quantidade Total de Ingressos</th>
                  <th>Você ou seu(s) acompanhante(s) são PCD?</th>
                  <th>Quais cuidados:</th>
                  <th>Observações</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                {items.length > 0 &&
                  items.map((e, i) => (
                    <tr key={i}>
                      <td>{e.id}</td>
                      <td>{e.name}</td>
                      <td>{e.email}</td>
                      <td>{e.session.title}</td>
                      <td>{e.companionsNumber}</td>
                      <td>{e.accessibilityRestriction ? "Sim" : "Não"}</td>
                      <td>{e.typeRestriction}</td>
                      <td>{e.notes}</td>
                      <td>
                        {selectedSorteioEdit.status == "NAO_SORTEADO" && (
                          <>
                            <i
                              className="mdi mdi-pencil"
                              title="Editar"
                              style={{
                                margin: "0 3px",
                                color: "green",
                                fontSize: 17,
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setSelectedParticipant(e);
                                setEditParticipant(true);
                              }}
                            />
                            {/* <i
                              className="mdi mdi-trash-can"
                              style={{
                                color: "red",
                                fontSize: 17,
                                margin: "0 3px",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setSelectedParticipant(e);
                                setconfirm_alert(true);
                              }}
                              title="Excluir"
                            /> */}
                            <DeleteButton
                              onClick={() => {
                                setSelectedParticipant(e);
                                setconfirm_alert(true);
                              }}
                              color="red"
                              fontSize={17}
                            />
                          </>
                        )}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>

          <div className="bottom-navigation" style={{ justifyContent: "end" }}>
            {/* 
        Fornecer os 4 parametros obrigatórios;
        ** data - a array contendo as informações da tabela
        ** search - o state do termo de pesquisa
        ** currentIndex - posição atual do index
        ** setCurrentIndex - função para atualizar a posição atual do index
        */}
            <Pagination
              data={items}
              search={search}
              currentIndex={offset}
              setCurrentIndex={setOffset}
              countData={countData}
              maxItemPerPage={limit}
              setMaxItemPerPage={setLImit}
              maxPageButtonsToShow={3}
            />
          </div>
        </ModalBody>
      </Modal>
    </>
  );
}
