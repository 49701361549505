import React, { useState } from "react";

import { Modal, ModalBody, ModalHeader, Col, Row, Button } from "reactstrap";

function ConfigColorRanking(props) {
  const { isOpen, toggle } = props;
  const [modalBackdrop, setModalBackdrop] = useState(false);

  function toggleBackdrop() {
    setModalBackdrop(!modalBackdrop);
  }

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => toggleBackdrop}
      autoFocus={true}
      centered={true}
    >
      <ModalHeader toggle={toggle}>Configuração de cor do Ranking</ModalHeader>
      <ModalBody>
        <form>
          <Row style={{ marginBottom: 20 }}>
            <Col lg={6}>
              <label className="form-label">
                Cor de fundo da tela de ranking geral
              </label>
              <input
                className="form-control"
                type={"color"}
                defaultValue="#FFFFFF"
              />
            </Col>
            <Col lg={6}>
              <label className="form-label">
                Cor de fundo da tela Meus Pontos
              </label>
              <input
                className="form-control"
                type={"color"}
                defaultValue="#FFFFFF"
              />
            </Col>
          </Row>

          <Row style={{ marginBottom: 20 }}>
            <Col lg={6}>
              <label className="form-label">Cor do bloco Meus Pontos</label>
              <input
                className="form-control"
                type={"color"}
                defaultValue="#FFFFFF"
              />
            </Col>
          </Row>

          <Row style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
            <Button
              className="btn btn-success waves-effect waves-light"
              style={{ width: 80 }}
            >
              Salvar
            </Button>
          </Row>
        </form>
      </ModalBody>
    </Modal>
  );
}

export default ConfigColorRanking;
