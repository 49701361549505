import React, { useState, useEffect } from "react";

import DeleteButton from "../../../components/Buttons/DeleteButton";

import {
  Table,
  Card,
  CardBody,
  CardTitle,
  Button,
  Collapse,
  Row,
} from "reactstrap";

import SweetAlert from "react-bootstrap-sweetalert";

//service
import { getMenus } from "../../../utils/services/menus";
import { useLocation } from "react-router-dom";
//redux
import { connect } from "react-redux";

//componentes
import ModalNewMenu from "./ModalNewMenu";
import ModalEdidMenu from "./ModalEditMenu";

//posição da tabela
import ReactDragListView from "react-drag-listview";

import {
  deleteButtonMenu,
  orderButtonMenu,
} from "../../../utils/services/menus";
import SectionComponent from "../../Apps/Personalization/SectionComponent";

function Menu(props) {
  const location = useLocation();

  //pesquisa
  const [search, setSearch] = useState("");
  //modais
  const [modalNewMenu, setModalNewMenu] = useState(false);
  const [modalEditMenu, setModalEditMenu] = useState(false);
  //deletar evento
  const [confirm_alert, setconfirm_alert] = useState(false);
  const [success_dlg, setsuccess_dlg] = useState(false);
  const [dynamic_title, setdynamic_title] = useState("");
  const [dynamic_description, setdynamic_description] = useState("");
  const [deleteButtonData, setDeleteButtonData] = useState(null);

  //paginação
  // eslint-disable-next-line no-unused-vars
  const [currentIndex, setCurrentIndex] = useState(0);
  //state dos menus
  const [menus, setMenus] = useState([]);
  //state de editar botão
  const [editMenu, setEditMenu] = useState();
  //state id botão
  const [idButton, setIdButton] = useState();
  const [isDirty, setIsDirty] = useState(false);
  // collapse
  const [isOpen, setIsOpen] = useState(false);

  function toggleNewMenu() {
    setModalNewMenu(!modalNewMenu);
  }

  function toggleEditMenu() {
    setModalEditMenu(!modalEditMenu);
  }

  const getAllMenus = async () => {
    var formData = await getMenus(
      props.state.global_user_data.data.token,
      location.state.idEvent
    );
    if (formData.findOneMenuData) {
      setIdButton(formData.findOneMenuData.id);
      setMenus(
        formData.findOneMenuData.buttons.sort((a, b) => a.order - b.order)
      );
    }
  };
  const deleteButton = async () => {
    let eventData = await deleteButtonMenu(
      deleteButtonData.id,
      props.state.global_user_data.data.token
    );
    if (eventData.message === "BUTTON_DELETED") {
      deleteConfirm();
      const items = [
        ...menus.filter((e) => e.id !== eventData.disableUserData.id),
      ];
      const data = items.map((e, i) => {
        return { ...e, order: ++i };
      });
      orderButtonMenu(
        { menuId: idButton, buttons: data },
        props.state.global_user_data.data.token
      );
      setMenus(data);
    }
  };

  function deleteConfirm() {
    setconfirm_alert(false);
    setsuccess_dlg(true);
    setdynamic_title("Deletado");
    setdynamic_description("Botão deletado");
  }
  useEffect(() => {
    getAllMenus();
  }, [modalEditMenu]);

  const onDragEnd = (fromIndex, toIndex) => {
    if (toIndex < 0) return;

    const items = [...menus];
    const item = items.splice(fromIndex, 1)[0];
    items.splice(toIndex, 0, item);
    setIsDirty(true);
    setMenus(items);
    const data = items.map((e, i) => {
      return { ...e, order: ++i };
    });
    orderButtonMenu(
      { menuId: idButton, buttons: data },
      props.state.global_user_data.data.token
    );
  };
  return (
    <div>
      {modalNewMenu && (
        <ModalNewMenu
          isOpen={modalNewMenu}
          toggle={toggleNewMenu}
          getAllMenus={getAllMenus}
          idButton={idButton}
          menus={menus}
          idEvent={location.state.idEvent}
        />
      )}
      {modalEditMenu && (
        <ModalEdidMenu
          isOpen={modalEditMenu}
          toggle={toggleEditMenu}
          editMenu={editMenu}
          getAllMenus={getAllMenus}
          idButton={idButton}
          menus={menus}
          idEvent={location.state.idEvent}
        />
      )}

      {confirm_alert ? (
        <SweetAlert
          title="Deseja deletar esse botão?"
          warning
          showCancel
          confirmBtnText="Sim"
          cancelBtnText="Não"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            deleteButton();
          }}
          onCancel={() => setconfirm_alert(false)}
        >
          Você não poderá reverter essa ação!
        </SweetAlert>
      ) : null}

      {success_dlg ? (
        <SweetAlert
          success
          title={dynamic_title}
          onConfirm={() => {
            setsuccess_dlg(false);
          }}
        >
          {dynamic_description}
        </SweetAlert>
      ) : null}

      <Card>
        <CardBody>
          <CardTitle>Menu do App</CardTitle>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              marginBottom: 10,
            }}
          >
            <form
              className="app-search d-none d-lg-block events-input-search"
              onSubmit={(e) => e.preventDefault()}
            >
              <div className="position-relative">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Pesquisar..."
                  onChange={(e) => setSearch(e.target.value)}
                />
                <span className="bx bx-search-alt" />
              </div>
            </form>
            <div className="mobile-btn-section" style={{ width: "100%" }}>
              <Row className="mobile-btns-top">
                <form
                  className="app-search"
                  style={{ padding: "0px" }}
                  onSubmit={(e) => e.preventDefault()}
                >
                  <div className="position-relative">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Pesquisar..."
                      onChange={(e) => setSearch(e.target.value)}
                    />
                    <span className="bx bx-search-alt" />
                  </div>
                </form>
              </Row>

              <SectionComponent
                sectionName="Opções"
                setIsOpen={setIsOpen}
                isOpen={isOpen}
              />
              <Collapse isOpen={isOpen}>
                <Row className="mobile-btns-top">
                  <div onClick={() => setModalNewMenu(true)}>Criar Botão</div>
                </Row>
              </Collapse>
            </div>
            <div className="header-btns-container">
              <Button
                color="success"
                className="btn-rounded waves-effect waves-light events-button-add"
                onClick={() => setModalNewMenu(true)}
              >
                Criar Botão
              </Button>
            </div>
          </div>

          <div className="table-responsive">
            <ReactDragListView onDragEnd={onDragEnd}>
              <Table className="table mb-0">
                <thead className="table-light">
                  <tr>
                    <th>Ordem</th>
                    <th>Descrição</th>
                    <th>Nome de Exibição</th>
                    <th>Visível</th>
                    <th>Padrão</th>
                    <th>Ações</th>
                  </tr>
                </thead>
                <tbody>
                  {menus
                    .filter(
                      (e) =>
                        e.description
                          .toLocaleLowerCase()
                          .indexOf(search.toLocaleLowerCase()) > -1
                    )
                    .slice(currentIndex, currentIndex + 200)
                    .map((e, i) => (
                      <tr key={i} style={{ cursor: "move" }}>
                        <th scope="row">{!isDirty ? e.order : ++i}</th>
                        <td>{e.description}</td>
                        <td>{e.nameExhibition}</td>
                        <td>{e.isVisible ? "Sim" : "Não"}</td>
                        <td>{e.isDefault ? "Sim" : "Não"}</td>
                        <td>
                          <i
                            className="mdi mdi-pencil"
                            style={{
                              color: "green",
                              fontSize: 17,
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setEditMenu(e);
                              setModalEditMenu(true);
                            }}
                          />{" "}
                          {!e.isDefault ? (
                            // <i
                            //   className="mdi mdi-trash-can"
                            //   style={{
                            //     color: "red",
                            //     fontSize: 17,
                            //     cursor: "pointer",
                            //   }}
                            //   onClick={() => {
                            //     setDeleteButtonData(e);
                            //     setconfirm_alert(true);
                            //   }}
                            // />
                            <DeleteButton
                              onClick={() => {
                                setDeleteButtonData(e);
                                setconfirm_alert(true);
                              }}
                              color="red"
                              fontSize={17}
                            />
                          ) : (
                            // <i
                            //   className="mdi mdi-trash-can"
                            //   style={{
                            //     color: "#adb5bd",
                            //     fontSize: 17,
                            //   }}
                            // />
                            <></>
                          )}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </ReactDragListView>
          </div>
        </CardBody>
      </Card>
    </div>
  );
}

const mapStateToProps = (state) => {
  return { state };
};

export default connect(mapStateToProps)(Menu);
