import writeXlsxFile from "write-excel-file";
import { getScheduling } from "../../../utils/services/scheduling";

export async function createXlsx({
  eventId,
  //   limit,
  search,
  columnSearch,
  countData,
  setFinishExport,
}) {
  function formatDateTime(data) {
    const datePart = data.split("T");
    return `${formatDate(datePart[0])} ${datePart[1].substring(0, 5)}`;
  }

  function formatDate(date) {
    const [year, month, day] = date.split("-");
    return `${day}/${month}/${year}`;
  }

  const schema = [
    {
      column: "ID do Participante",
      type: String,
      value: (appointment) => appointment.participantId,
    },
    {
      column: "Nome do Participante",
      type: String,
      value: (appointment) => appointment.participantName,
    },
    {
      column: "Sala",
      type: String,
      value: (appointment) => appointment.sala,
    },
    {
      column: "Atividade",
      type: String,
      value: (appointment) => appointment.atividade,
    },
    {
      column: "Data e Hora do Início",
      type: String,
      value: (appointment) => formatDateTime(appointment.dataHoraInicio),
    },
    {
      column: "Data e Hora do Fim",
      type: String,
      value: (appointment) => formatDateTime(appointment.dataHoraFim),
    },
  ];

  const limit = 100;

  const allRequestsPromises = [];
  const numberOfRequisitions = Math.ceil(countData / limit);

  for (let i = 1; i <= numberOfRequisitions; i += 1) {
    const request = await getScheduling({
      eventId,
      offset: i,
      limit,
      search,
      columnSearch,
    });

    allRequestsPromises.push(request);
    setTimeout(() => {}, 500);

    const progressBar = document.querySelector(".export-progress");
    const percentageBar = document.querySelector(".export-percentage");

    progressBar.style.width = `${(i / numberOfRequisitions) * 100}%`;
    percentageBar.innerHTML = `${((i / numberOfRequisitions) * 100).toFixed(
      0
    )}%`;

    if (i === numberOfRequisitions) {
      setTimeout(() => {
        setFinishExport(true);
      }, 1000);
    }
  }
  const allDataInSingleArray = allRequestsPromises.map((e) => e.data).flat();
  if (allDataInSingleArray) {
    await writeXlsxFile(allDataInSingleArray, {
      schema,
      fileName: "agendamentos.xlsx",
    });
  }
}
