import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

import { Button, Col, Input, Label, Row } from "reactstrap";

// service
import { createForm, updateForm } from "../../utils/services/rsvp";

import toastr from "toastr";
import "toastr/build/toastr.min.css";

// Rows and Cols
import RowsAndCols from "./components/EditRowAndCols";

// Custom Components
import SelectInput from "./components/SelectInput";
import TextInput from "./components/TextInput";
import UploadInput from "./components/UploadInput";
import ThermInput from "./components/ThermInput";
import CheckboxInput from "./components/CheckboxInput";
import RadioInput from "./components/RadioInput";
import FormButton from "./components/FormButton";
import EmailInput from "./components/EmailInput";
import CellphoneInput from "./components/CellphoneInput";
import CPFInput from "./components/CPFInput";
import LabelInput from "./components/LabelInput";
import ErrorModal from "./components/ErrorModal";
import EntriesOptions from "./components/EntriesOptions";
import Captcha from "./components/Captcha";
import PasswordInput from "./components/PasswordInput";
import ButtonSpinner from "../../components/Buttons/ButtonSpinner";

export default function CustomForm({
  toggle,
  createOrUpdate,
  eventId,
  selectedForm,
}) {
  const { token: jwt } = useSelector((state) => state.global_user_data.data);

  const [formName, setFormName] = useState(
    selectedForm ? selectedForm.description : ""
  );
  const [isLoading, setIsloading] = useState(false);
  const [btnSave, setBtnSave] = useState(false);
  const [btnSaveExit, setBtnSaveExit] = useState(false);

  // Modal Edit Rows and Cols
  const [modalRowAndCol, setModalRowAndCol] = useState(false);
  const [rowsAndCols, setRowsAndCols] = useState([]);

  // Modal to add new component
  const [modal, setModal] = useState(false);

  // Error Modal
  const [errorModal, setErrorModal] = useState(false);

  const [selectedRowAndCol, setSelectedRowAndCol] = useState({});

  // Current form
  const formRef = useRef([]);

  // Função passada para o componente filho para adicionar os atributos do componente
  const addAttributes = ({ rowId, colId, attributes }) => {
    const newRows = formRef.current.map((row) => {
      if (row.row === rowId) {
        row.cols = row.cols.map((col) => {
          if (col.col === colId) {
            col.attributes = attributes;
          }
          return col;
        });
      }
      return row;
    });

    formRef.current = newRows;
    setRowsAndCols(newRows);
  };

  // Função passada para o componente filho para excluir o respectivo componente
  const deleteCustomInput = (rowId, colId) => {
    const newState = [...formRef.current];
    newState[rowId].cols[colId].input = undefined;
    newState[rowId].cols[colId].inputId = undefined;
    newState[rowId].cols[colId].attributes = undefined;

    formRef.current = newState;
    setRowsAndCols(newState);
  };

  // Função que lista os possiveis componentes a serem renderizados
  const components = useCallback(
    (rowId, colId, attributes) => [
      <TextInput
        deleteComponent={deleteCustomInput}
        attributes={attributes}
        setAttributes={addAttributes}
        rowId={rowId}
        colId={colId}
      />,
      <UploadInput
        deleteComponent={deleteCustomInput}
        attributes={attributes}
        setAttributes={addAttributes}
        rowId={rowId}
        colId={colId}
      />,
      <ThermInput
        deleteComponent={deleteCustomInput}
        attributes={attributes}
        setAttributes={addAttributes}
        rowId={rowId}
        colId={colId}
      />,
      <SelectInput
        deleteComponent={deleteCustomInput}
        attributes={attributes}
        setAttributes={addAttributes}
        rowId={rowId}
        colId={colId}
      />,
      <CheckboxInput
        deleteComponent={deleteCustomInput}
        attributes={attributes}
        setAttributes={addAttributes}
        rowId={rowId}
        colId={colId}
      />,
      <RadioInput
        deleteComponent={deleteCustomInput}
        attributes={attributes}
        setAttributes={addAttributes}
        rowId={rowId}
        colId={colId}
      />,
      <FormButton
        deleteComponent={deleteCustomInput}
        attributes={attributes}
        setAttributes={addAttributes}
        rowId={rowId}
        colId={colId}
      />,
      <EmailInput
        deleteComponent={deleteCustomInput}
        attributes={attributes}
        setAttributes={addAttributes}
        rowId={rowId}
        colId={colId}
      />,
      <CellphoneInput
        deleteComponent={deleteCustomInput}
        attributes={attributes}
        setAttributes={addAttributes}
        rowId={rowId}
        colId={colId}
      />,
      <CPFInput
        deleteComponent={deleteCustomInput}
        attributes={attributes}
        setAttributes={addAttributes}
        rowId={rowId}
        colId={colId}
      />,
      <LabelInput
        deleteComponent={deleteCustomInput}
        attributes={attributes}
        setAttributes={addAttributes}
        rowId={rowId}
        colId={colId}
      />,
      <Captcha
        deleteComponent={deleteCustomInput}
        attributes={attributes}
        setAttributes={addAttributes}
        rowId={rowId}
        colId={colId}
      />,
      <PasswordInput
        deleteComponent={deleteCustomInput}
        attributes={attributes}
        setAttributes={addAttributes}
        rowId={rowId}
        colId={colId}
      />,
    ],
    []
  );

  // Função que atualiza o valor da posição da linha e coluna no array
  const updateRowsAndColsIndex = (newState, customComponent) => {
    const rows = newState.map((row, index) => {
      row.row = index;
      row.cols = row.cols.map((col, index) => {
        col.col = index;
        if (col.inputId) {
          col.input = customComponent(row.row, col.col)[col.inputId];
        }
        return col;
      });
      return row;
    });

    return rows;
  };

  // Função que deleta uma coluna
  const deleteCol = (target) => {
    const newState = [...formRef.current];
    const rowId = target.id.split("/")[1];
    const colId = target.id.split("/")[3];
    newState[rowId].cols = newState[rowId].cols.filter(
      (item) => item.col !== +colId
    );
    if (newState[rowId].cols.length === 0) {
      newState.splice(+rowId, 1);
    }

    formRef.current = updateRowsAndColsIndex(newState, components);
    setRowsAndCols(updateRowsAndColsIndex(newState, components));
  };

  // Função adiciona uma linha e coluna(s) no array
  const editRowAndCol = (cols) => {
    const newCol = {
      row: formRef.current.length,
      cols: [...new Array(+cols)].map((_e, i) => ({
        col: i,
      })),
    };

    setRowsAndCols((prev) => [...prev, newCol]);
    formRef.current = [...formRef.current, newCol];
  };

  // Função que adiciona apenas uma coluna
  const addCol = (target) => {
    const newState = [...formRef.current];
    const rowId = target.id.split("/")[1];
    const colId = target.id.split("/")[3];

    if (newState[rowId].cols.length < 3) {
      newState[rowId].cols.push({ col: +colId + 1 });
      setRowsAndCols(updateRowsAndColsIndex(newState, components));
      formRef.current = updateRowsAndColsIndex(newState, components);
    }
  };

  // Função que adiciona um componente customizado
  const addInput = (id) => {
    const newRowsAndCols = [...formRef.current];

    newRowsAndCols[selectedRowAndCol.row].cols[selectedRowAndCol.col].input =
      components(selectedRowAndCol.row, selectedRowAndCol.col)[id];
    newRowsAndCols[selectedRowAndCol.row].cols[selectedRowAndCol.col].inputId =
      id;

    formRef.current = newRowsAndCols;
    setRowsAndCols(newRowsAndCols);
  };

  // Função que verifica se a coluna está vazia ou sem atributos
  const hasEmptyFields = () => {
    const hasEmptyCol = rowsAndCols.some((row) => {
      return row.cols.some((col) => {
        return !col.input || !col.attributes;
      });
    });

    return hasEmptyCol;
  };

  // Função que remove o componente customizado do array para salvar no banco
  const removeHtml = () => {
    const newRowsAndCols = [...formRef.current];

    newRowsAndCols.forEach((row) => {
      row.cols.forEach((col) => {
        delete col.input;
      });
    });

    return newRowsAndCols;
  };

  const handleSubmit = async () => {
    if (hasEmptyFields() || !formName) {
      setErrorModal(true);
      return;
    }

    if (createOrUpdate === "create") {
      const json = JSON.stringify(removeHtml());
      setIsloading(true);
      await createForm({
        jwt,
        data: {
          json,
          eventId,
          description: formName,
        },
      });
      setIsloading(false);
    }

    if (createOrUpdate === "update") {
      const json = JSON.stringify(removeHtml());
      setIsloading(true);
      await updateForm({
        jwt,
        data: {
          json,
          id: selectedForm.id,
          description: formName,
        },
      });

      setIsloading(false);
    }
    toastr.options.progressBar = true;
    toastr.options.positionClass = "toast-top-center";
    toastr.success("Salvo com sucesso.");
  };
  const handleSubmitAndExit = async () => {
    if (hasEmptyFields() || !formName) {
      setErrorModal(true);
      return;
    }

    if (createOrUpdate === "create") {
      setIsloading(true);
      const json = JSON.stringify(removeHtml());
      await createForm({
        jwt,
        data: {
          json,
          eventId,
          description: formName,
        },
      });
      setIsloading(false);
    }

    if (createOrUpdate === "update") {
      setIsloading(true);

      const json = JSON.stringify(removeHtml());
      await updateForm({
        jwt,
        data: {
          json,
          id: selectedForm.id,
          description: formName,
        },
      });
      setIsloading(false);
    }
    toggle();
  };

  useEffect(() => {
    // Verifica se o usuário está editando um formulário
    if (selectedForm) {
      const form = {
        ...selectedForm,
        json: JSON.parse(selectedForm.json).map((row) => ({
          ...row,
          cols: row.cols.map((col) => ({
            ...col,
            // Adiciona o componente customizado no array
            input: components(row.row, col.col, col.attributes)[col.inputId],
          })),
        })),
      };

      formRef.current = form.json;
      setRowsAndCols(form.json);
    }
  }, [selectedForm, components]);

  return (
    <div className="custom-form-wrapper">
      <ErrorModal
        formName={formName}
        isOpen={errorModal}
        toggle={setErrorModal}
        rows={rowsAndCols}
      />
      <EntriesOptions
        isOpen={modal}
        toggle={setModal}
        addInput={addInput}
        data={rowsAndCols}
      />
      <RowsAndCols
        isOpen={modalRowAndCol}
        toggle={() => setModalRowAndCol(!modalRowAndCol)}
        setEditCols={editRowAndCol}
      />
      <Row>
        <Col>
          <Label>
            <span className="name-custom-form">Nome do formulário</span>
          </Label>
          <Input
            type="text"
            placeholder="Nome do formulário"
            className="custom-form-mb"
            value={formName}
            onChange={(e) => setFormName(e.target.value)}
          />
        </Col>
      </Row>
      <hr style={{ marginBottom: "30px" }} />
      <Row>
        <Col id="dragContainer" className="dragItems" sm="11">
          {rowsAndCols.length > 0 &&
            rowsAndCols.map((e) => (
              <Row
                id={`row/${e.row}`}
                key={`row${e.row}`}
                style={{ marginBottom: "10px" }}
              >
                {e.cols.length > 0 &&
                  e.cols.map((col) => (
                    <Col style={{ padding: "4px" }} key={`col/${col.col}`}>
                      <Row>
                        <div className="row-config-container">
                          {e.cols.length === +col.col + 1 && (
                            <i
                              id={`row/${e.row}/col/${col.col}`}
                              className="mdi mdi-plus"
                              title="Adicionar coluna"
                              onClick={({ target }) => addCol(target)}
                            />
                          )}
                          <i
                            id={`row/${e.row}/col/${col.col}`}
                            className="mdi mdi-close"
                            title="Remover coluna"
                            onClick={({ target }) => deleteCol(target)}
                          />
                        </div>
                      </Row>
                      <Row id={`col/${col.col}`} className="dragItem">
                        {col.input ? (
                          col.input
                        ) : (
                          <button
                            className="select-input-btn"
                            onClick={() => {
                              setModal(true);
                              setSelectedRowAndCol({
                                row: e.row,
                                col: col.col,
                              });
                            }}
                          >
                            <i className="mdi mdi-plus" />
                          </button>
                        )}
                      </Row>
                    </Col>
                  ))}
              </Row>
            ))}
          <Row>
            <Button
              className="add-row-button"
              color="primary"
              onClick={() => setModalRowAndCol(true)}
            >
              Adicionar Linha e coluna(s)
            </Button>
          </Row>
        </Col>
      </Row>
      <div className="save-btn-form-container">
        <ButtonSpinner
          isLoading={(btnSave && isLoading) || rowsAndCols.length === 0}
          title={"Salvar"}
          onClick={() => {
            setBtnSave(true);
            handleSubmit();
          }}
        />
        <div style={{ marginLeft: 10 }}>
          <ButtonSpinner
            isLoading={(btnSaveExit && isLoading) || rowsAndCols.length === 0}
            title={"Salvar e Sair"}
            size={125}
            onClick={() => {
              setBtnSave(false);
              setBtnSaveExit(true);
              handleSubmitAndExit();
            }}
          />
        </div>
      </div>
    </div>
  );
}
