/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Col,
  Row,
  Button,
  Label,
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import { createUser } from "../../utils/services/users";
import ButtonSpinner from "../../components/Buttons/ButtonSpinner";

export default function NewEvent(props) {
  const { isOpen, toggle, token } = props;
  const [modalBackdrop, setModalBackdrop] = useState(false);
  const [selectedGroup, setselectedGroup] = useState(null);
  const [success_dlg, setsuccess_dlg] = useState(false);
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [emailExists, setEmailExists] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [profile, setProfile] = useState("");
  const [profileError, setProfileError] = useState("");
  const [isLoading, setIsloading] = useState(false);

  function toggleBackdrop() {
    setModalBackdrop(!modalBackdrop);
  }

  useEffect(() => {
    setName("");
    setEmail("");
    setNameError("");
    setEmailError("");
    setProfile("");
    setEmailExists(false);
  }, [isOpen]);

  const CreateUserFunc = async () => {
    if (name === "") {
      setNameError("O campo Nome é obrigatório");
      return;
    } else setNameError("");

    if (email === "") {
      setEmailError("O campo Email é obrigatório");
      return;
    } else setEmailError("");

    if (!profile || profile === "Selecione um perfil") {
      setProfileError("O campo Perfil é obrigatório");
      return;
    } else setProfileError("");

    if (!validateEmail()) {
      setEmailError("Formato do email inválido.");
      return;
    }
    setIsloading(true);
    var userData = await createUser(
      {
        nome: name,
        email: email,
        senha: "",
        profile: profile,
      },
      token
    );
    setIsloading(false);
    if (userData.message === "created") {
      setsuccess_dlg(true);
      props.getEvent();
    }
    if (userData.message === "EMAIL_ALREADY_REGISTERED") {
      setEmailExists(true);
    }
  };

  function validateEmail() {
    const pattern =
      /^(?=[a-zA-Z0-9]*[a-zA-Z])[a-zA-Z0-9._%+-]{2,}@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?$/;
    return pattern.test(email);
  }

  function handleSelectGroup(selectedGroup) {
    setselectedGroup(selectedGroup);
  }

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => {
        toggleBackdrop();
      }}
      autoFocus={true}
      centered={true}
    >
      {success_dlg ? (
        <SweetAlert
          success
          title={"Criado"}
          onConfirm={() => {
            setsuccess_dlg(false);
            props.toggle();
          }}
        >
          {"Usuário criado com sucesso!"}
        </SweetAlert>
      ) : null}
      <ModalHeader toggle={toggle}>Novo Usuário</ModalHeader>

      <ModalBody>
        <Row>
          <Col lg={12}>
            <div>
              <label className="form-label"> Nome do Usuário*</label>
              <input
                className="form-control"
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <p style={{ color: "red" }}>{nameError}</p>
            </div>
          </Col>
        </Row>

        <Row style={{ marginTop: 10 }}>
          <Col lg={12}>
            <div>
              <label className="form-label">Email*</label>
              <input
                className="form-control"
                type="email"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <p style={{ color: "red" }}>{emailError}</p>
              {emailExists && (
                <p style={{ color: "red" }}>
                  O email informado já está cadastrado.
                </p>
              )}
            </div>
          </Col>
        </Row>

        <Row style={{ marginTop: 10 }}>
          <Col lg={12}>
            <Label htmlFor="edit-input-delet-options" className="form-label">
              Selecione um Perfil de Usuário
            </Label>
            <select
              className="form-control"
              onChange={(e) => setProfile(e.target.value)}
            >
              <option>Selecione um perfil</option>
              <option>Administrador</option>
              <option disabled className="select-disable">
                Editor
              </option>
              <option disabled className="select-disable">
                Leitor
              </option>
            </select>
            <p style={{ color: "red" }}>{profileError}</p>
          </Col>
        </Row>

        <Row
          style={{
            width: "100%",
            margin: "auto",
            display: "flex",
            justifyContent: "flex-end",
            marginTop: 10,
          }}
        >
          <Button
            color="danger"
            style={{ width: 80, marginRight: 10 }}
            onClick={() => toggle()}
          >
            Fechar
          </Button>

          <ButtonSpinner
            isLoading={isLoading}
            title={"Salvar"}
            onClick={CreateUserFunc}
          />
        </Row>
      </ModalBody>
    </Modal>
  );
}
