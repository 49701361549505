import React from "react";

export default function Footer({ logged }) {
  return (
    <section className="footer-section">
      <div className="footer-row">
        <a style={{ width: "auto" }}>{new Date().getFullYear()} © Inteegra</a>
      </div>
    </section>
  );
}
