import React, { useState, useEffect } from 'react';
import { Modal, ModalBody, ModalHeader, Col, Row, Button } from 'reactstrap';

import { connect } from 'react-redux';

import SweetAlert from 'react-bootstrap-sweetalert';

import { listProducts } from '../../../utils/services/payment';

function ViewPromo(props) {
  const { isOpen, toggle, promo } = props;

  const [modalBackdrop, setModalBackdrop] = useState(false);

  const [successDlg, setSuccessDlg] = useState(false);

  const [valueType] = useState(promo.valueType);
  const [quantityAvailable] = useState(promo.quantity);
  const [value] = useState(
    promo.originalValue.toLocaleString('pt-BR', {
      minimumFractionDigits: 2,
      useGrouping: false,
    }),
  );
  const [code] = useState(promo.code);
  const [date] = useState(promo.expireAtView.substring(0, 10));
  const [time] = useState(promo.expireAtView.substring(11, 19));

  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState(promo.products);

  const handleListProducts = async () => {
    await listProducts({
      jwt: props.state.global_user_data.data.token,
      eventId: props.idEvent,
      offset: 1,
      limit: 100,
      search: '',
    })
      .then(response => {
        const data = response.products.map(product => {
          return {
            id: product.id,
            description: product.description1,
          };
        });
        setProducts(data);
        setSelectedProducts(promo.products);
      })
      .catch(error => {
        setProducts([]);
        setSelectedProducts([]);
        console.log(error);
      });
  };

  useEffect(() => {
    handleListProducts();
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => {
        setModalBackdrop(!modalBackdrop);
      }}
      centered={true}
      style={{ width: '25rem', maxHeight: '90vh', height: '50rem' }}>
      {successDlg ? (
        <SweetAlert
          success
          title={'Criado'}
          onConfirm={() => {
            setSuccessDlg(false);
            toggle();
          }}>
          {'Cupom criado com sucesso!'}
        </SweetAlert>
      ) : null}

      <ModalHeader toggle={toggle}>Adicionar Cupom</ModalHeader>
      <ModalBody style={{ maxHeight: '100vh' }}>
        <Row>
          <Col lg={6}>
            <div>
              <label className="form-label">Código</label>
              <input
                disabled
                className="form-control"
                type="text"
                value={code}
                style={{ textTransform: 'uppercase' }}
              />
            </div>
          </Col>
          <Col lg={6}>
            <div>
              <label className="form-label">Quantidade</label>
              <input
                disabled
                className="form-control"
                type="text"
                value={quantityAvailable}
                style={{ textAlign: 'right' }}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={6}>
            <label className="form-label">Tipo de desconto</label>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
                justifyContent: 'space-around',
              }}>
              <span>
                % <input disabled type="radio" value={'P'} checked={valueType === 'P'} />
              </span>
              <span>
                R$ <input disabled type="radio" value={'M'} checked={valueType === 'M'} />
              </span>
            </div>
          </Col>
          <Col lg={6}>
            <div>
              <label className="form-label">Valor</label>
              <input
                disabled
                className="form-control"
                type="text"
                style={{
                  textAlign: 'right',
                }}
                value={value}
              />
            </div>
          </Col>
        </Row>

        <Row style={{ paddingBottom: '0.5rem' }}>
          <Col lg={12}>Validade</Col>
        </Row>
        <Row>
          <Col lg={6}>
            <div>
              <input disabled className="form-control" type="date" value={date} />
            </div>
          </Col>
          <Col lg={6}>
            <div>
              <input disabled className="form-control" type="time" value={time} />
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <label className="form-label">Ingressos</label>
            <select disabled multiple className="form-select" value={selectedProducts}>
              {products.map((product, index) => {
                return (
                  <option key={index} value={product.id}>
                    {product.description}
                  </option>
                );
              })}
            </select>
          </Col>
        </Row>
        <Row
          style={{
            margin: 'auto',
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '1rem',
          }}>
          <Button
            color="danger"
            className="btn btn-danger waves-effect waves-light"
            style={{ width: 80, marginRight: '1rem' }}
            onClick={() => toggle(!isOpen)}>
            Fechar
          </Button>
        </Row>
      </ModalBody>
    </Modal>
  );
}

const mapStateToProps = function (state) {
  return { state: state };
};

export default connect(mapStateToProps)(ViewPromo);
