import React, { useState, useEffect } from "react";

import {
  Table,
  Card,
  CardBody,
  CardTitle,
  Button,
  Collapse,
  Row,
} from "reactstrap";

//redux
import { connect } from "react-redux";

//paginação
import Pagination from "../../../components/Paginationv3";

//componentes
import ModalNewFeed from "./ModalNewFeed";
import ModalEdidFeed from "./ModalEditFeed";

import { useLocation } from "react-router-dom";
//services
import { getFeed, disableAllPosts } from "../../../utils/services/feed";

import useDebounce from "../../../utils/hooks/useDebounce";
import SectionComponent from "../../Apps/Personalization/SectionComponent";
import { createXlsx } from "./createXlsx";
import WaitExport from "../../../components/WaitExport";

import SweetAlert from "react-bootstrap-sweetalert";

import SearchFilters from "../../../components/SearchFilters";

function Feed(props) {
  const location = useLocation();

  //pesquisa
  const [search, setSearch] = useState("");
  //modais
  const [modalNewFeed, setModalNewFeed] = useState(false);
  const [modalEditFeed, setModalEditFeed] = useState(false);
  //deletar evento
  // const [feedDelete, setFeedDelete] = useState();
  // const [confirm_alert, setconfirm_alert] = useState(false);
  const [success_dlg, setsuccess_dlg] = useState(false);
  const [dynamic_title, setdynamic_title] = useState("");
  const [dynamic_description, setdynamic_description] = useState("");
  //editar feed
  const [feedEdit, setFeedEdit] = useState();
  //paginação
  const [currentIndex, setCurrentIndex] = useState(1);
  //state de feed
  const [feed, setFeed] = useState([]);
  const [countData, setCountData] = useState(1);
  // itens por pagina
  // const itemsPerPage = 10;

  // export in progress
  const [exportInProgress, setExportInProgress] = useState(false);
  const [finishExport, setFinishExport] = useState(false);

  const [itemsPerPage, setItemsPerPage] = useState(10);

  // collapse
  const [isOpen, setIsOpen] = useState(false);
  const [confirmAllDisabled, setConfirmAllDisabled] = useState(false);
  const [collapseIsOpen, setCollapseIsOpen] = useState(false);
  const [activeFilter, setActiveFilter] = useState("user");
  const debouncedSearch = useDebounce(search, 300);

  const columnsFilter = [
    { name: "Usuário", value: "user", type: "text" },
    { name: "Texto", value: "texto", type: "text" },
    { name: "Criado Em", value: "date", type: "date" },
  ];

  function setActiveFilterHandle(columnActive) {
    setActiveFilter(columnActive);
    setSearch("");
  }
  const reverseDate = () => {
    const reverse = debouncedSearch
      .split("/")
      .reverse()
      .join("/")
      .replace(/\//g, "-");
    return reverse;
  };
  function treatmentSearch() {
    if (
      columnsFilter.filter((item) => item.value === activeFilter)[0].type ===
      "date"
    ) {
      return reverseDate();
    }
    return debouncedSearch;
  }
  function toggleNewFeed() {
    setModalNewFeed(!modalNewFeed);
  }

  function toggleEditFeed() {
    setModalEditFeed(!modalEditFeed);
  }

  const getAllFeed = async () => {
    var formData = await getFeed({
      jwt: props.state.global_user_data.data.token,
      idEvent: location.state.idEvent,
      offset: currentIndex > 0 ? currentIndex : 1,
      limit: itemsPerPage,
      search: treatmentSearch(),
      columnSearch: activeFilter,
    });

    if (formData.message && formData.message === "findPostByEvent") {
      setCountData(formData.findPostsData.count);
      setFeed(formData.findPostsData.data);
    }
  };

  function formatData(startDate) {
    const data = new Date(startDate);
    const dia = String(data.getDate()).padStart(2, "0");
    const mes = String(data.getMonth() + 1).padStart(2, "0");
    const ano = data.getFullYear();
    const hour = String(data.getHours()).padStart(2, "0");
    const min = String(data.getMinutes()).padStart(2, "0");
    const dataAtual = dia + "/" + mes + "/" + ano + " - " + hour + ":" + min;

    return dataAtual;
  }

  async function confirmDeleteAllPosts() {
    await disableAllPosts({
      jwt: props.state.global_user_data.data.token,
      eventId: location.state.idEvent,
    }).then((response) => {
      setConfirmAllDisabled(false);
      setsuccess_dlg(true);
      setdynamic_title("Deletados");
      setdynamic_description("Posts deletados");
    });
  }

  useEffect(() => {
    getAllFeed();
  }, [currentIndex, itemsPerPage, debouncedSearch]);

  return (
    <div>
      {modalNewFeed && (
        <ModalNewFeed
          isOpen={modalNewFeed}
          toggle={toggleNewFeed}
          idEvent={location.state.idEvent}
          getAllFeed={getAllFeed}
        />
      )}
      {modalEditFeed && (
        <ModalEdidFeed
          isOpen={modalEditFeed}
          toggle={toggleEditFeed}
          feedEdit={feedEdit}
          idEvent={location.state.idEvent}
          getAllFeed={getAllFeed}
        />
      )}

      {confirmAllDisabled ? (
        <SweetAlert
          title={`Deseja remover todos os Posts?`}
          warning
          showCancel
          confirmBtnText="Sim"
          cancelBtnText="Não"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            confirmDeleteAllPosts();
          }}
          onCancel={() => setConfirmAllDisabled(false)}
        >
          <span>Você não poderá reverter essa ação.</span>
        </SweetAlert>
      ) : null}

      {success_dlg ? (
        <SweetAlert
          success
          title={dynamic_title}
          onConfirm={() => {
            getAllFeed();
            setsuccess_dlg(false);
          }}
        >
          {dynamic_description}
        </SweetAlert>
      ) : null}
      <WaitExport
        isOpen={exportInProgress}
        name="Feed"
        preposicao="do"
        finishExport={finishExport}
        setFinishExport={setFinishExport}
        setExportInProgress={setExportInProgress}
      />
      <Card>
        <CardBody>
          <CardTitle>Feed</CardTitle>
          <div>
            <Row
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: 10,
                width: "50px",
              }}
            >
              <SectionComponent
                sectionName="Filtros"
                setIsOpen={setCollapseIsOpen}
                isOpen={collapseIsOpen}
              />
            </Row>
            <Collapse isOpen={collapseIsOpen}>
              <SearchFilters
                columns={columnsFilter}
                activeFilter={activeFilter}
                setActiveFilterHandle={setActiveFilterHandle}
              />
            </Collapse>
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              marginBottom: 10,
            }}
          >
            <form
              className="app-search d-none d-lg-block events-input-search"
              onSubmit={(e) => e.preventDefault()}
            >
              <div className="position-relative">
                <input
                  type={activeFilter === "date" ? "date" : "text"}
                  value={search}
                  className="form-control"
                  placeholder="Pesquisar..."
                  onChange={(e) => setSearch(e.target.value)}
                />
                <span className="bx bx-search-alt" />
              </div>
            </form>
            <div className="mobile-btn-section" style={{ width: "100%" }}>
              <Row className="mobile-btns-top">
                <form
                  className="app-search"
                  style={{ padding: "0px" }}
                  onSubmit={(e) => e.preventDefault()}
                >
                  <div className="position-relative">
                    <input
                      type={activeFilter === "date" ? "date" : "text"}
                      value={search}
                      className="form-control"
                      placeholder="Pesquisar..."
                      onChange={(e) => setSearch(e.target.value)}
                    />
                    <span className="bx bx-search-alt" />
                  </div>
                </form>
              </Row>
              <SectionComponent
                sectionName="Opções"
                setIsOpen={setIsOpen}
                isOpen={isOpen}
              />
              <Collapse isOpen={isOpen}>
                {feed?.length > 0 && (
                  <Row className="mobile-btns-top">
                    <div
                      onClick={() =>
                        createXlsx({
                          jwt: props.state.global_user_data.data.token,
                          idEvent: location.state.idEvent,
                          limit: 500,
                          search: search,
                          countData,
                          setFinishExport,
                        }) > setExportInProgress(true)
                      }
                    >
                      Exportar Posts
                    </div>
                  </Row>
                )}
                {!props.reportToDashboard && (
                  <Row className="mobile-btns-top">
                    <div onClick={() => setModalNewFeed(true)}>Criar Post</div>
                  </Row>
                )}
              </Collapse>
            </div>
            <div className="header-btns-container">
              {feed?.length > 0 && (
                <Button
                  color="success"
                  className="btn-rounded waves-effect waves-light events-button-add-lg"
                  onClick={() =>
                    createXlsx({
                      jwt: props.state.global_user_data.data.token,
                      idEvent: location.state.idEvent,
                      limit: 500,
                      search: search,
                      countData,
                      setFinishExport,
                    }) > setExportInProgress(true)
                  }
                >
                  Exportar Posts
                </Button>
              )}
              {!props.reportToDashboard && (
                <Button
                  color="success"
                  className="btn-rounded waves-effect waves-light events-button-add"
                  onClick={() => setModalNewFeed(true)}
                >
                  Criar Post
                </Button>
              )}
            </div>
          </div>

          <div className="table-responsive">
            <Table className="table mb-0">
              <thead className="table-light">
                <tr>
                  <th>Usuário</th>
                  <th>Texto</th>
                  <th>Visível</th>
                  <th>Fixado</th>
                  <th>Criado Em</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                {feed
                  // .filter(
                  //   (e) =>
                  //     e.text
                  //       .toLocaleLowerCase()
                  //       .indexOf(search.toLocaleLowerCase()) > -1
                  // )
                  // .slice(currentIndex, currentIndex + 20)
                  .map((e, i) => (
                    <tr key={i}>
                      {/* <th scope="row"></th> */}
                      <td>
                        {e.loginid !== null
                          ? e.loginid.nome
                          : e.loginadmin_id.nome + " (Admin)"}
                      </td>
                      <td>{e.text}</td>
                      <td>{e.active ? "Sim" : "Não"}</td>
                      <td>{e.fixed ? "Sim" : "Não"}</td>
                      {/* <td>{formatData(e.date)}</td> */}
                      <td>{formatData(e.date)}</td>
                      <td>
                        <i
                          className="mdi mdi-pencil"
                          style={{
                            color: "green",
                            fontSize: 17,
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setFeedEdit(e);
                            setModalEditFeed(true);
                          }}
                        />{" "}
                        {/* <i
                          className="mdi mdi-trash-can"
                          style={{
                            color: "red",
                            fontSize: 17,
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setFeedDelete(e.id);
                            setconfirm_alert(true);
                          }}
                        /> */}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
        </CardBody>
      </Card>
      {!props.reportToDashboard ? (
        <div
          className="bottom-navigation"
          style={{
            justifyContent: `${feed?.length > 0 ? "space-between" : "end"}`,
          }}
        >
          {feed?.length > 0 && (
            <Button
              color="danger"
              className="btn-rounded waves-effect waves-light events-button-add"
              onClick={() => setConfirmAllDisabled(true)}
            >
              Limpar Todos Posts
            </Button>
          )}
          {/* 
          Fornecer os 8 parametros obrigatórios;
          ** data - a array contendo as informações da tabela
          ** search - o state do termo de pesquisa
          ** currentIndex - posição atual do index
          ** setCurrentIndex - função para atualizar a posição atual do index
          ** countData - quantidade total de itens
          ** maxItemPerPage - estado quantidade de de itens por página
          ** setMaxItemPerPage - função que troca o estado com a quantidade maxima de itens por página 
          ** maxPageButtonsToShow - quantidade de botões qua vão aparecer para a pessoa clicar, somente números impares
          ** 5 = vai de 1 até 5 / 7 vai de 1 até 7 
          */}
          <Pagination
            data={feed}
            search={search}
            currentIndex={currentIndex}
            setCurrentIndex={setCurrentIndex}
            countData={countData}
            maxItemPerPage={itemsPerPage}
            setMaxItemPerPage={setItemsPerPage}
            maxPageButtonsToShow={3}
          />
        </div>
      ) : (
        <Pagination
          data={feed}
          search={search}
          currentIndex={currentIndex}
          setCurrentIndex={setCurrentIndex}
          countData={countData}
          maxItemPerPage={itemsPerPage}
          setMaxItemPerPage={setItemsPerPage}
          maxPageButtonsToShow={3}
        />
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return { state };
};

export default connect(mapStateToProps)(Feed);
