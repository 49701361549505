import React, { useState, useEffect } from "react";

import {
  Table,
  Card,
  CardBody,
  CardTitle,
  Button,
  Collapse,
  Row,
} from "reactstrap";

import { useLocation } from "react-router-dom";
//redux
import { connect } from "react-redux";

//paginação
import Pagination from "../../../components/Paginationv3";

import SweetAlert from "react-bootstrap-sweetalert";

//componentes
import ModalNewConfigRanking from "./modalNewConfigRanking";
import ModalEditConfigRanking from "./modalEditConfigRanking";
import ConfigColorRanking from "./modalConfigColorRankink";
import useDebounce from "../../../utils/hooks/useDebounce";
import SearchFilters from "../../../components/SearchFilters";
import DeleteButton from "../../../components/Buttons/DeleteButton";

//service
import {
  getConfigurations,
  deleteConfiguration,
} from "../../../utils/services/ranking";

import { optionCode } from "./mockCodes";
import SectionComponent from "../../Apps/Personalization/SectionComponent";

function Ranking(props) {
  const location = useLocation();
  //state do modal
  const [modalNewConfig, setModalNewConfig] = useState(false);
  const [modalEditConfig, setModalEditConfig] = useState(false);
  const [modalColor, setModalColor] = useState(false);
  //state de pesquisa
  const [search, setSearch] = useState("");
  //paginação
  const [currentIndex, setCurrentIndex] = useState(1);
  const [countData, setCountData] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  //dados da listagem
  const [config, setConfig] = useState([]);
  //state editar config
  const [editConfig, setEditConfig] = useState();
  //state deletar config
  const [confirm_alert, setconfirm_alert] = useState(false);
  const [success_dlg, setsuccess_dlg] = useState(false);
  const [deleteConfig, setDeleteConfig] = useState();
  const [dynamic_title, setdynamic_title] = useState("");
  const [dynamic_description, setdynamic_description] = useState("");
  // collapse
  const [isOpen, setIsOpen] = useState(false);

  const debouncedSearch = useDebounce(search, 300);
  const [collapseIsOpen, setCollapseIsOpen] = useState(false);
  const [activeFilter, setActiveFilter] = useState("code");

  const columnsFilter = [
    { name: "Código", value: "code", type: "text" },
    { name: "Pontuação", value: "point", type: "text" },
  ];

  function setActiveFilterHandle(columnActive) {
    setActiveFilter(columnActive);
    setSearch("");
  }
  const reverseDate = () => {
    const reverse = debouncedSearch
      .split("/")
      .reverse()
      .join("/")
      .replace(/\//g, "-");
    return reverse;
  };
  function treatmentSearch() {
    if (
      columnsFilter.filter((item) => item.value === activeFilter)[0].type ===
      "date"
    ) {
      return reverseDate();
    }
    return debouncedSearch;
  }
  function toggleModalNewConfig() {
    setModalNewConfig(!modalNewConfig);
  }

  function toggleModalEditConfig() {
    setModalEditConfig(!modalEditConfig);
  }

  function toggleModalColor() {
    setModalColor(!modalColor);
  }

  function formatData(startDate) {
    return startDate.substr(0, 10);
  }

  async function deleteConfigFunc() {
    await deleteConfiguration(
      deleteConfig.id,
      props.state.global_user_data.data.token
    ).then((response) => {
      if (response.message === "CONFIGURATION_DISABLED") {
        setconfirm_alert(false);
        setsuccess_dlg(true);
        setdynamic_title("Deletado");
        setdynamic_description("Configuração Deletada");
        getAllConfigurations();
      }
    });
    // .catch((error) => console.log("response error", error));
  }

  async function getAllConfigurations() {
    await getConfigurations({
      id: location.state.idEvent,
      jwt: props.state.global_user_data.data.token,
      offset: currentIndex > 0 ? currentIndex : 1,
      limit: itemsPerPage,
      search: treatmentSearch(),
      columnSearch: activeFilter,
    })
      .then((response) => {
        setConfig(response.data);
        setCountData(response.count);
      })
      .catch((error) => {
        // console.log("response error", error);
      });
  }

  useEffect(() => {
    getAllConfigurations();
  }, [currentIndex, itemsPerPage, debouncedSearch]);

  return (
    <div>
      {modalNewConfig && (
        <ModalNewConfigRanking
          isOpen={modalNewConfig}
          toggle={toggleModalNewConfig}
          jwt={props.state.global_user_data.data.token}
          idEvent={location.state.idEvent}
          getAllConfigurations={getAllConfigurations}
        />
      )}
      {modalEditConfig && (
        <ModalEditConfigRanking
          isOpen={modalEditConfig}
          toggle={toggleModalEditConfig}
          jwt={props.state.global_user_data.data.token}
          idEvent={location.state.idEvent}
          getAllConfigurations={getAllConfigurations}
          editConfig={editConfig}
        />
      )}
      {modalColor && (
        <ConfigColorRanking isOpen={modalColor} toggle={toggleModalColor} />
      )}
      {confirm_alert ? (
        <SweetAlert
          title={`Deseja deletar ${
            optionCode.find((a) => a.code === deleteConfig.code)?.describe ||
            deleteConfig.code
          } ?`}
          warning
          showCancel
          confirmBtnText="Sim"
          cancelBtnText="Não"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            deleteConfigFunc();
          }}
          onCancel={() => setconfirm_alert(false)}
        >
          <span>Você não poderá reverter essa ação!</span>
        </SweetAlert>
      ) : null}

      {success_dlg ? (
        <SweetAlert
          success
          title={dynamic_title}
          onConfirm={() => {
            setsuccess_dlg(false);
          }}
        >
          {dynamic_description}
        </SweetAlert>
      ) : null}
      <Card>
        <CardBody>
          <CardTitle>Configurações</CardTitle>
          <div>
            <Row
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: 10,
                width: "50px",
              }}
            >
              <SectionComponent
                sectionName="Filtros"
                setIsOpen={setCollapseIsOpen}
                isOpen={collapseIsOpen}
              />
            </Row>
            <Collapse isOpen={collapseIsOpen}>
              <SearchFilters
                columns={columnsFilter}
                activeFilter={activeFilter}
                setActiveFilterHandle={setActiveFilterHandle}
              />
            </Collapse>
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              marginBottom: 10,
            }}
          >
            <form
              className="app-search d-none d-lg-block events-input-search"
              onSubmit={(e) => e.preventDefault()}
            >
              <div className="position-relative">
                <input
                  type="text"
                  value={search}
                  className="form-control"
                  placeholder="Pesquisar..."
                  onChange={(e) => setSearch(e.target.value)}
                />
                <span className="bx bx-search-alt" />
              </div>
            </form>

            <div className="mobile-btn-section" style={{ width: "100%" }}>
              <Row className="mobile-btns-top">
                <form
                  className="app-search"
                  style={{ padding: "0px" }}
                  onSubmit={(e) => e.preventDefault()}
                >
                  <div className="position-relative">
                    <input
                      type="text"
                      value={search}
                      className="form-control"
                      placeholder="Pesquisar..."
                      onChange={(e) => setSearch(e.target.value)}
                    />
                    <span className="bx bx-search-alt" />
                  </div>
                </form>
              </Row>
              <SectionComponent
                sectionName="Opções"
                setIsOpen={setIsOpen}
                isOpen={isOpen}
              />
              <Collapse isOpen={isOpen}>
                <Row className="mobile-btns-top">
                  <div onClick={() => setModalNewConfig(true)}>
                    Adicionar Configuração
                  </div>
                </Row>
              </Collapse>
            </div>
            <div className="header-btns-container">
              <Button
                color="success"
                className="btn-rounded waves-effect waves-light events-button-add"
                onClick={() => setModalNewConfig(true)}
              >
                Adicionar Configuração
              </Button>

              {/* <Button
                color="success"
                className="btn-rounded waves-effect waves-light events-button-add"
                onClick={() => setModalColor(true)}
                style={{ marginLeft: 10 }}
              >
                Configurações de cor do Ranking
              </Button> */}
            </div>
          </div>
          <div className="table-responsive">
            <Table>
              <thead className="table-light">
                <tr>
                  <th>Código</th>
                  <th>Icone</th>
                  <th>Pontuação</th>
                  <th>Diário</th>
                  <th>Data Inicio</th>
                  <th>Hora Inicio</th>
                  <th>Data Fim</th>
                  <th>Hora Fim</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                {config?.length > 0 &&
                  config?.map((e, i) => (
                    <tr key={e.code}>
                      <td>
                        {/* {optionCode.find((f) => f.code === e.code)?.describe ||
                          e.code} */}
                        {e.code}
                      </td>
                      <td>
                        <i className={e.urlIcon} />
                      </td>
                      <td>{e.earnedPoints}</td>
                      <td>{e.daily ? "Sim" : "Não"}</td>
                      <td>
                        {e.daily
                          ? formatData(e.startAt).split("-").reverse().join("/")
                          : null}
                      </td>
                      <td>{e.startHour}</td>
                      <td>
                        {e.daily
                          ? formatData(e.endAt).split("-").reverse().join("/")
                          : null}
                      </td>
                      <td>{e.endHour}</td>
                      <td>
                        <i
                          className="mdi mdi-pencil"
                          style={{
                            color: "green",
                            fontSize: 17,
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setEditConfig(e);
                            setModalEditConfig(true);
                          }}
                        />{" "}
                        {/* <i
                          className="mdi mdi-trash-can"
                          style={{
                            color: "red",
                            fontSize: 17,
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setDeleteConfig(e);
                            setconfirm_alert(true);
                          }}
                        /> */}
                        <DeleteButton
                          onClick={() => {
                            setDeleteConfig(e);
                            setconfirm_alert(true);
                          }}
                          color="red"
                          fontSize={17}
                        />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
        </CardBody>
      </Card>
      <div className="bottom-navigation" style={{ justifyContent: "end" }}>
        <Pagination
          data={config}
          search={search}
          currentIndex={currentIndex}
          setCurrentIndex={setCurrentIndex}
          countData={countData}
          maxItemPerPage={itemsPerPage}
          setMaxItemPerPage={setItemsPerPage}
          maxPageButtonsToShow={3}
        />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return { state };
};

export default connect(mapStateToProps)(Ranking);
