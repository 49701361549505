import { urlBaseFiles } from "./config/index";
import axiosInstance from "../services/config/axiosinstance";

function getUrlFiles(data, jwt) {
  var config = {
    method: "post",
    url: `${urlBaseFiles}/files`,
    headers: {
      "content-type": "multipart/form-data",
    },
    data: data,
  };

  return axiosInstance(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

async function deleteFile(id, jwt) {
  var config = {
    method: "delete",
    url: `${urlBaseFiles}/files/${id}`,
    headers: {},
  };

  return axiosInstance(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

export { getUrlFiles, deleteFile };
