import React from 'react'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';

export default function ModalRegisteredEmails({ emails, isOpen, toggle, setEmails }) {
  return (
    <Modal
    isOpen={isOpen}
    autoFocus={true}
    centered={true}
    >
    <ModalHeader toggle={toggle} onClick={() => setEmails([])} >
      Os emails abaixo já estavam cadastrados para o evento e foram atualizados:
    </ModalHeader>
      <ModalBody>
        <ul>
          {emails && emails.map((email) => (
            <li key={email.index}>{email.email}</li>
          ))}
        </ul>
        <div
          style={{
              width: "100%",
              margin: "auto",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              color="primary"
              className="btn waves-effect waves-light"
              style={{ width: 80, marginRight: 15 }}
              onClick={() => toggle() > setEmails([])}
            >
              OK
            </Button>
          </div>
      </ModalBody>
    </Modal>
  )
}
