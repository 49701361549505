import writeXlsxFile from "write-excel-file";
import { fetchAttendeeReports, fetchRegistersReports } from "../../../../utils/services/portalBoticario";

const hasDate = (accomodation, date) =>
  accomodation.includes(`Ingresso_${date}`);

export async function createParticipantsXlsx(
  jwt,
  limit,
  countData,
  setFinishExport,
  idEvent
) {
  const schema = [
    {
      column: "Nome",
      type: String,
      value: (data) => data.name,
    },
    {
      column: "E-mail",
      type: String,
      value: (data) => data.email,
    },
    {
      column: "Cargo",
      type: String,
      value: (data) => data.role,
    },
    {
      column: "Hospedagem",
      type: String,
      value: (data) => data.accomodation.split(";")[0],
    },
    {
      column: "Dia 08/04",
      type: String,
      value: (data) => (hasDate(data.accomodation, "0804") ? "Sim" : "Não"),
    },
    {
      column: "Dia 09/04",
      type: String,
      value: (data) => (hasDate(data.accomodation, "0904") ? "Sim" : "Não"),
    },
    {
      column: "Dia 10/04",
      type: String,
      value: (data) => (hasDate(data.accomodation, "1004") ? "Sim" : "Não"),
    },
    {
      column: "Departamento",
      type: String,
      value: (data) => data.code,
    },
    {
      column: "Inscrito por",
      type: String,
      value: (data) => `${data.creatorName} (${data.creatorEmail})`,
    },
  ];

  const allRequestsPromises = [];
  const numberOfRequisitions = Math.ceil(countData / limit);

  for (let i = 1; i <= numberOfRequisitions; i += 1) {
    const request = await fetchAttendeeReports(jwt, idEvent);
    allRequestsPromises.push(request);
    setTimeout(() => {}, 500);

    const progressBar = document.querySelector(".export-progress");
    const percentageBar = document.querySelector(".export-percentage");

    progressBar.style.width = `${(i / numberOfRequisitions) * 100}%`;
    percentageBar.innerHTML = `${((i / numberOfRequisitions) * 100).toFixed(
      0
    )}%`;

    if (i === numberOfRequisitions) {
      setTimeout(() => {
        setFinishExport(true);
      }, 1000);
    }
  }

  const allDataInSingleArray = allRequestsPromises.flat();
  if (allDataInSingleArray) {
    await writeXlsxFile(allDataInSingleArray, {
      schema,
      fileName: "participantes_boticario_cotas.xlsx",
    });
  }
}
