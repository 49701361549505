import React from "react";
import { Col, Row } from "reactstrap";

export default function SearchFilters(props) {
  const { columns, activeFilter, setActiveFilterHandle } = { ...props };

  return (
    <Row className="search-filters">
      {columns.map((col) => (
        <div className="search-item">
          <span>
            <input
              type="radio"
              value={col.value === activeFilter}
              checked={col.value === activeFilter}
              onChange={(e) => {
                setActiveFilterHandle(col.value);
              }}
            />
            {col.name}
          </span>
        </div>
      ))}
    </Row>
  );
}
