import React from "react";
import { Input, Label, UncontrolledTooltip } from "reactstrap";

export default function UploadComponent({
  labelText,
  setStateFunc,
  tipText,
  idDiv,
}) {
  return (
    <div style={{ margin: "15px 0", padding: 0 }} id="updload-component">
      <div style={{ display: "flex" }}>
        <Label htmlFor="formFile" className="form-label">
          {labelText}
        </Label>
        <div id={`${idDiv ? idDiv : "uploadComponents"}`} style={{ height: "16px", paddingRight: "7px" }}>
          <div
            className="show-tips"
          >
            <span>?</span>
          </div>
          <UncontrolledTooltip
            style={{ backgroundColor: "#6f42c1" }}
            placement="right"
            target={`${idDiv ? idDiv : "uploadComponents"}`}
          >
            {tipText}
          </UncontrolledTooltip>
        </div>
      </div>
      <Input
        className="form-control"
        type="file"
        id="formFile"
        defaultValue={null}
        onChange={({ target }) =>
          setStateFunc({ files: target.files, path: target.value })
        }
      />
    </div>
  );
}
