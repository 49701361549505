import React, { useEffect, useState } from 'react';

import { Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalHeader, Row, UncontrolledTooltip } from 'reactstrap';

import { inputMask } from '../../../utils/helpers/masks/masks';

export default function CPFInput({ deleteComponent, rowId, colId, attributes, setAttributes }) {
  const [inputName, setInputName] = useState(attributes ? attributes.inputName : 'CPF');
  const [label, setLabel] = useState(attributes ? attributes.label : 'CPF');
  const [placeholder, setPlaceholder] = useState(attributes ? attributes.placeholder : 'Digite seu CPF');
  const [tipText, setTipText] = useState(attributes ? attributes.tipText : 'Texto dica');
  const [hasTip, setHasTip] = useState(attributes ? attributes.hasTip : false);

  // Mask
  const [maskedValue, setMaskedValue] = useState('');

  // Edit Modal
  const [editModal, setEditModal] = useState(false);

  const handleChange = (value) => {
    inputMask({ mask: 'cpf', value, setMaskedValue });
  };

  useEffect(() => {
    if (!attributes) {
      setAttributes({
        rowId,
        colId,
        attributes: {
          inputName: 'CPF',
          label: 'CPF',
          placeholder: 'Digite seu CPF',
          tipText: 'Texto dica',
          hasTip: false,
          mask: 'cpf',
          required: true,
          uniqueField: 'cpf',
        },
      });
    }
  }, [rowId, colId, setAttributes, attributes]);

  const submitForm = (e) => {
    e.preventDefault();
    setAttributes({
      rowId,
      colId,
      attributes: {
        inputName,
        label,
        placeholder,
        tipText,
        hasTip,
        mask: 'cpf',
        required: true,
        uniqueField: 'cpf',
      },
    });
    setEditModal(false);
  };

  return (
    <div>
      <div className="custom-input-container">
        <Modal
          size="lg"
          isOpen={editModal}
          toggle={() => setEditModal(!editModal)}
        >
          <ModalHeader toggle={() => setEditModal(false)}>
            Configurar CPF
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col>
                <Form>
                  <FormGroup className="input-margin-btm">
                    <Label htmlFor="edit-input-name" className="form-label">
                      Nome do campo
                    </Label>
                    <Input
                      id="edit-input-name"
                      className="form-control"
                      type="text"
                      placeholder="Nome do campo"
                      value={inputName}
                      onChange={({ target }) => setInputName(target.value)}
                    />
                  </FormGroup>
                  <FormGroup className="input-margin-btm">
                    <Label htmlFor="edit-input-text" className="form-label">
                      Nome do Campo de Texto
                    </Label>
                    <Input
                      id="edit-input-text"
                      className="form-control"
                      type="text"
                      value={label}
                      onChange={({ target }) => setLabel(target.value)}
                    />
                  </FormGroup>
                  <FormGroup className="input-margin-btm">
                    <Label htmlFor="edit-input-placeholder" className="form-label">
                      Texto do placeholder
                    </Label>
                    <Input
                      id="edit-input-placeholder"
                      className="form-control"
                      type="text"
                      value={placeholder}
                      onChange={({ target }) => setPlaceholder(target.value)}
                    />
                  </FormGroup>
                  <FormGroup className="input-margin-btm">
                    <Input
                      className="input-mg-rgt"
                      id="edit-input-has-tip"
                      type="checkbox"
                      checked={hasTip}
                      onChange={({ target }) => setHasTip(target.checked)}
                    />
                    <Label htmlFor="edit-input-has-tip" className="form-label">
                      Possui dica?
                    </Label>
                    {hasTip && (
                      <FormGroup>
                        <Label htmlFor="edit-input-tip" className="form-label">
                          Texto da dica
                        </Label>
                        <Input
                          id="edit-input-tip"
                          className="form-control"
                          type="text"
                          value={tipText}
                          onChange={({ target }) => setTipText(target.value)}
                        />
                      </FormGroup>
                    )}
                  </FormGroup>
                </Form>
              </Col>
              <Col style={{borderLeft: '1px solid gray'}}>
                <div style={{ display: 'flex' }}>
                  <Label className="form-label" htmlFor="formText">
                    {label === "CPF" ? `${label} *` : <div dangerouslySetInnerHTML={{__html: label}} />}
                  </Label>
                  {hasTip && (
                    <div>
                      <div className="show-tips" id="cpf-tip-input">
                        <span>?</span>
                      </div>
                      <UncontrolledTooltip style={{ backgroundColor: '#6f42c1' }} placement="right" target="cpf-tip-input">
                        {tipText}
                      </UncontrolledTooltip>
                    </div>
                  )}
                </div>
                <Input
                  className="form-control"
                  type="text"
                  id="formText"
                  placeholder={placeholder}
                  value={maskedValue}
                  onChange={({ target }) => handleChange(target.value)}
                />
              </Col>
            </Row>
            <div className="save-btn-form-container">
              <Button
                color="success"
                className="btn btn-success waves-effect waves-light save-btn-form"
                onClick={submitForm}
              >
                Salvar
              </Button>
            </div>
          </ModalBody>
        </Modal>
        <div>
          <div className="displayed-config-container">
            <div className="displayed-label">
              <Label className="form-label" htmlFor="formText">
                {label === "CPF" ? `${label} *` : <div dangerouslySetInnerHTML={{__html: label}} />}
              </Label>
              {hasTip && (
                <div>
                  <div className="show-tips" id="cpf-tip-display">
                    <span>?</span>
                  </div>
                  <UncontrolledTooltip style={{ backgroundColor: '#6f42c1' }} placement="right" target="cpf-tip-display">
                    {tipText}
                  </UncontrolledTooltip>
                </div>
              )}
            </div>
            <div className="config-form-icon">
              <span
                className="mdi mdi-cog-outline"
                onClick={() => setEditModal(!editModal)}
              />
              <span
                className="mdi mdi-close close-icon"
                title="Remover Campo"
                onClick={() => deleteComponent(rowId, colId)}
              />
            </div>
          </div>
          <Input
            className="form-control"
            type="text"
            id="formText"
            placeholder={placeholder}
            value={maskedValue}
            onChange={({ target }) => handleChange(target.value)}
          />
        </div>
      </div>
    </div>
  )
}
