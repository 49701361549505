import writeXlsxFile from "write-excel-file";
import { getTransfer } from "../../../utils/services/transfer";

export async function createXlsx({
  jwt,
  idEvent,
  limit,
  countData,
  setFinishExport,
}) {
  const formatDate = (date, type) => {
    // const formatedDate = date.split("T")[0].split("-").reverse().join("/");
    // return `${formatedDate}`;
    // const formatDateVeiculo = (date, type) => {
    //validate if date is undefined
    if (!date) return "-";
    if (type !== "VEICULO") {
      const formatedDate = date.split("T")[0].split("-").reverse().join("/");
      return `${formatedDate}`;
    }

    if (type === "VEICULO") {
      if (date?.includes("1969")) return "-";
      const datePart = date?.split("T")[0];
      const hour = date?.split("T")[1].substring(0, 5);
      const [year, month, day] = datePart.split("-");
      return `${day}/${month}/${year} ${hour}`;
    }
  };

  function isVehicle(transfer) {
    return transfer.registrationType === "VEICULO";
  }

  const schema = [
    {
      column: "Id Transfer",
      type: String,
      value: (transfer) => transfer.id,
    },
    {
      column: "Id Participante",
      type: String,
      value: (transfer) => transfer.login.id,
    },
    {
      column: "Email Participante",
      type: String,
      value: (transfer) => transfer.login.email,
    },
    {
      column: "Participante",
      type: String,
      value: (transfer) => transfer.login.nome,
    },
    {
      column: "Empresa",
      type: String,
      value: (transfer) =>
        isVehicle(transfer) ? transfer?.vehicle?.company : transfer?.company,
    },
    {
      column: "Origem",
      type: String,
      value: (transfer) =>
        isVehicle(transfer) ? transfer.vehicle?.origin : transfer?.origin,
    },
    {
      column: "Destino",
      type: String,
      value: (transfer) =>
        isVehicle(transfer)
          ? transfer.vehicle?.destination
          : transfer?.destination,
    },
    {
      column: "Data/hora embarque",
      type: String,
      value: (transfer) =>
        !isVehicle(transfer)
          ? `${formatDate(transfer.boardingdate, transfer.registrationType)} ${
              transfer.boardingtill
            }`
          : `${formatDate(
              transfer.vehicle?.departureDatetime,
              transfer.registrationType
            )} `,
    },
    {
      column: "Data/hora chegada",
      type: String,
      value: (transfer) =>
        !isVehicle(transfer)
          ? transfer?.arrivaldate
            ? `${formatDate(transfer.arrivaldate)} ${transfer.arrivaltime}`
            : ""
          : `${formatDate(
              transfer.vehicle?.arrivalDatetime,
              transfer.registrationType
            )} `,
    },
    {
      column: "Ponto de Encontro",
      type: String,
      value: (transfer) =>
        isVehicle(transfer)
          ? transfer.vehicle?.meetingPoint
          : transfer.meetingpoint,
    },
    {
      column: "Nome do Motorista",
      type: String,
      value: (transfer) => transfer.drivername,
    },
    {
      column: "Telefone do Motorista",
      type: String,
      value: (transfer) =>
        isVehicle(transfer) ? transfer.vehicle?.phone : transfer.driverphone,
    },
    {
      column: "Informações Extras",
      type: String,
      value: (transfer) =>
        isVehicle(transfer)
          ? transfer.vehicle?.notes
          : transfer.extrainformation,
    },
    {
      column: "Tipo de Cadastro",
      type: String,
      value: (transfer) => transfer.registrationType,
    },
    {
      column: "ID do Veículo",
      type: String,
      value: (transfer) => transfer.vehicle?.id,
    },
    {
      column: "Nome do Veículo",
      type: String,
      value: (transfer) => transfer.vehicle?.description,
    },
  ];

  const allRequestsPromises = [];
  const numberOfRequisitions = Math.ceil(countData / limit);

  for (let i = 1; i <= numberOfRequisitions; i += 1) {
    const request = await getTransfer({
      jwt,
      idEvent,
      offset: i,
      limit,
      search: "",
      columnSearch: "",
      hasGestaoClick: "true",
    });
    // }).then((response) => console.log(response));
    // return;
    allRequestsPromises.push(request);
    setTimeout(() => {}, 500);

    const progressBar = document.querySelector(".export-progress");
    const percentageBar = document.querySelector(".export-percentage");
    progressBar.style.width = `${(i / numberOfRequisitions) * 100}%`;
    percentageBar.innerHTML = `${((i / numberOfRequisitions) * 100).toFixed(
      0
    )}%`;

    if (i === numberOfRequisitions) {
      setTimeout(() => {
        setFinishExport(true);
      }, 1000);
    }
  }

  const allDataInSingleArray = allRequestsPromises
    .map((e) => e.findOneTransferData.data)
    .flat();
  if (allDataInSingleArray) {
    await writeXlsxFile(allDataInSingleArray, {
      schema,
      fileName: "transfer.xlsx",
    });
  }
}
