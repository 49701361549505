import React, { useEffect, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

export default function Captcha({ rowId, colId, setValue }) {
  const [value, setValueCaptcha] = useState('');

  useEffect(() => {
    setValue((oldValue) => {
      const newValue = [...oldValue];
      newValue[rowId].cols[colId].attributes.value = value;
      if (!value) {
        newValue[rowId].cols[colId].attributes.errorMessage = 'O captcha é obrigatório';
      } else {
        newValue[rowId].cols[colId].attributes.errorMessage = '';
      }
      return newValue;
    });
  }, [value, setValue, rowId, colId]);

  return (
    <div>
    <div className="displayed-config-container">
      <div className="displayed-label">
        <ReCAPTCHA
          sitekey="6Lcj77QUAAAAALq8HRPFs-tEagWfhWqg7AfYJ8JP"
          onChange={(e) => setValueCaptcha(e)}
        />
      </div>
    </div>
  </div>
  )
}
