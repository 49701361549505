import React, { useState, useEffect } from "react";

import {
  Table,
  Card,
  CardTitle,
  CardBody,
  Button,
  Spinner,
  Collapse,
  Row,
} from "reactstrap";
// alerta
import SweetAlert from "react-bootstrap-sweetalert";
//redux
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
//service
import {
  getParticipants,
  deleteParticipant,
  deleteAllParticipants,
  reactivateParticipant,
} from "../../../utils/services/participants";
import {
  addLoginInNotificationsScheduled,
  removeLoginInNotificationsScheduled,
} from "../../../utils/services/notification";
import {
  getQuotas,
  disableAllQuotas,
  activateQuotas,
  getSessionUser,
} from "../../../utils/services/quotas";
//paginação
import Pagination from "../../../components/Paginationv3";
//componentes
import ModalNewParticipant from "./ModalNewParticipant";
import ModalEditParticipant from "./ModalEditParticipant";
import ImportParticipant from "./ImportParticipant";
import ModalImportWrapper from "./ModalImportWrapper";
import ModalEditPassword from "./ModalEditPassword";
import ModalSendEmail from "./ModalSendEmail";
import DeleteButton from "../../../components/Buttons/DeleteButton";

// componentes
import useDebounce from "../../../utils/hooks/useDebounce";
import { createXlsx } from "./createXlsx";
import { createXlsxQuota } from "./createXlsxQuota";
import WaitExport from "../../../components/WaitExport";
import SectionComponent from "../../Apps/Personalization/SectionComponent";
import SearchFilters from "../../../components/SearchFilters";

const columnsFilter = [
  { name: "Nome", value: "login", type: "text" },
  { name: "Email", value: "email", type: "text" },
];
function Participants(props) {
  const location = useLocation();

  //pesquisa
  const [search, setSearch] = useState("");
  //modais
  const [modalNewParticipant, setModalNewParticipant] = useState(false);
  const [modalEditParticpant, setModalEditParticipant] = useState(false);
  const [modalImportParticipant, setModalImportParticipant] = useState(false);
  const [modalImportWrapper, setModalImportWrapper] = useState(false);
  const [modalEditPassword, setModalEditPassword] = useState(false);
  const [modalSendEmail, setModalSendEmail] = useState(false);
  //deletar participante
  const [deleteParticipantState, setDeleteParticipantState] = useState();
  const [deleteQuotaData, setDeleteQuotaData] = useState(null);
  const [participant, setParticipant] = useState();
  const [activateQuotaData, setActivateQuotaData] = useState(null);
  const [confirm_alert, setconfirm_alert] = useState(false);
  const [confirmAllDisabled, setConfirmAllDisabled] = useState(false);
  const [confirmAlertReactivate, setConfirmAlertReactivate] = useState(false);
  // alerta
  const [success_dlg, setsuccess_dlg] = useState(false);
  const [dynamic_title, setdynamic_title] = useState("");
  const [dynamic_description, setdynamic_description] = useState("");
  const [alertEmail, setAlertEmail] = useState(false);
  const [alertEmailConfirm, setAlertEmailConfirm] = useState(false);
  //editar participante
  const [editParticipante, setEditParticipante] = useState();
  //paginação
  const [currentIndex, setCurrentIndex] = useState(1);
  //state de participants
  const [participants, setParticipants] = useState([]);
  //estado com a quantidade total de items na API
  const [countData, setCountData] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const [status, setStatus] = useState(true);
  const [loading, setLoading] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [transition, setTransition] = useState(false);
  const [updateNotification, setUpdateNotification] = useState(false);
  // export in progress
  const [exportInProgress, setExportInProgress] = useState(false);
  const [finishExport, setFinishExport] = useState(false);
  // collapse state
  const [isOpen, setIsOpen] = useState(false);
  const [collapseIsOpen, setCollapseIsOpen] = useState(false);
  const [activeFilter, setActiveFilter] = useState("login");
  const [productsList, setProductsList] = useState([]);
  // botões de importar e exportar
  const [importers, setImporters] = useState(true);
  // cotas
  const [quotas, setQuotas] = useState([]);
  // token
  const token = props.state.global_user_data.data.token;

  const debouncedSearch = useDebounce(search, 300);
  function setActiveFilterHandle(columnActive) {
    setActiveFilter(columnActive);
    setSearch("");
  }
  const reverseDate = () => {
    const reverse = debouncedSearch
      .split("/")
      .reverse()
      .join("/")
      .replace(/\//g, "-");
    return reverse;
  };

  function treatmentSearch() {
    if (
      columnsFilter.filter((item) => item.value === activeFilter)[0].type ===
      "date"
    ) {
      return reverseDate();
    }
    return debouncedSearch;
  }

  function toggleNewParticipant() {
    setModalNewParticipant(!modalNewParticipant);
  }

  function toggleEditParticipant() {
    setModalEditParticipant(!modalEditParticpant);
  }

  function toggleImportParticipant() {
    setModalImportParticipant(!modalImportParticipant);
  }

  function toggleImportWrapper() {
    setModalImportWrapper(!modalImportWrapper);
  }

  function toggleEditPassword() {
    setModalEditPassword(!modalEditPassword);
  }

  function checkProductExists(product) {
    if (location.state) {
      const search = location.state.event.product.filter(
        (e) => e.name === product
      );

      if (search[0]) {
        return true;
      } else return false;
    }
  }

  const getAllParticipants = async () => {
    setLoading(true);
    var formData = await getParticipants({
      jwt: token,
      idEvent: location.state.idEvent,
      offset: currentIndex > 0 ? currentIndex : 1,
      limit: itemsPerPage,
      search: treatmentSearch(),
      columnSearch: activeFilter,
      status: status ? "ATIVO" : "INATIVO",
    });
    setCountData(formData?.data?.count);
    setParticipants(formData?.data?.data);
    setLoading(false);
    setTransition(false);
  };

  function formatData(startDate) {
    var data = new Date(startDate);
    var dia = String(data.getDate()).padStart(2, "0");
    var mes = String(data.getMonth() + 1).padStart(2, "0");
    var ano = data.getFullYear();
    var dataAtual = dia + "/" + mes + "/" + ano;

    return dataAtual;
  }

  async function confirmDelete(email) {
    var formData = await deleteParticipant(
      deleteParticipantState.id,
      token,
      location.state.idEvent
    );
    if (formData.message === "USER_DISABLED") {
      try {
        // const { data } = await removeLoginInNotificationsScheduled(
        await removeLoginInNotificationsScheduled(
          location?.state?.idEvent,
          deleteParticipantState.id
        ).then((res) => {
          setUpdateNotification(res.data);
        });

        if (checkProductExists("Distribuição de Cotas") && deleteQuotaData) {
          const dataDisableCotas = {
            eventId: location.state.idEvent,
            loginId: deleteParticipantState.id,
            isSendEmail: email ? true : false,
            subject: email ? email.subject : "",
            templateBase: email ? email.template.current.getContent() : "",
          };

          await disableAllQuotas({
            jwt: token,
            data: dataDisableCotas,
          });
          // .then((res) => console.log("cotas", res))
          // .catch((error) => console.log("erro cotas", error));
        }
      } catch (error) {
        // console.log(error);
      } finally {
        setconfirm_alert(false);
        setsuccess_dlg(true);
        setdynamic_title("Deletado");
        setdynamic_description("Participante deletado");
        // modal e alerta de email
        setModalSendEmail(false);
        setAlertEmail(false);
      }

      getAllParticipants();
    }
  }
  async function confirmReactivate() {
    let arrayGroups = [];

    var formData = await reactivateParticipant(
      location.state.idEvent,
      participant?.id,
      token
    );
    if (participant.grupos.length > 0) {
      participant.grupos.map((e) => arrayGroups.push({ grupo: e.grupo.id }));
    }
    if (formData.message === "LOGIN_REACTIVATED") {
      try {
        const { data } = await addLoginInNotificationsScheduled(
          location.state.idEvent,
          participant?.id,
          token,
          arrayGroups
        );
        setUpdateNotification(data);

        // if (checkProductExists("Distribuição de Cotas") && activateQuotaData) {
        //   await activateQuotas({
        //     jwt: token,
        //     id: activateQuotaData.id,
        //   });
        // }
      } catch (error) {
        // console.log(error);
      } finally {
        setConfirmAlertReactivate(false);
        setsuccess_dlg(true);
        setdynamic_title("Reativado");
        setdynamic_description("Participante reativado");
      }
      getAllParticipants();
    }
  }
  async function confirmDeleteAllParticipants() {
    setLoadingDelete(true);
    await deleteAllParticipants({
      jwt: token,
      eventId: location.state.idEvent,
    }).then((response) => {
      setConfirmAllDisabled(false);
      setLoadingDelete(false);
      setsuccess_dlg(true);
      setdynamic_title("Deletados");
      setdynamic_description("Participantes deletados");
    });
  }

  function getFirstAccess(f) {
    const data = f.eventos.filter(
      (e) => (e.event.id = location.state.idEvent)
    )[0];
    return data?.firstAccess ? formatData(data.firstAccess) : "";
  }

  function getRegistrationDate(reg) {
    const data = formatData(
      reg.eventos.find(({ event }) => event.id === location.state.idEvent)
        ?.criadoEm
    );
    return data ? data : "";
  }

  // verificar se o evento tem o app como produto
  function checkItemMenuExists(menuOption) {
    return productsList.filter((item) => item === menuOption).length > 0;
  }

  function verifyImporters() {
    if (
      location.state &&
      location.state.event?.product?.length === 1 &&
      location.state.event.product[0].name === "Distribuição de Cotas"
    ) {
      setImporters(false);
    }
  }

  async function getAllQuotas() {
    await getQuotas({
      jwt: token,
      id: location.state.idEvent,
    }).then((res) => {
      setQuotas(res.loginEventosCotas.data);
    });
    // .catch((error) => console.log("erro", error));
  }

  async function getSessionUserFunc(loginId) {
    var haveSession = false;
    await getSessionUser(token, loginId).then((res) => {
      if (res?.totalSessionActive?.count > 0) {
        haveSession = true;
      }
    });

    return haveSession;
  }

  useEffect(() => {
    verifyImporters();
    getAllParticipants();
    getAllQuotas();
    setProductsList(
      [
        ...new Set(
          props.state.global_user_data.data.foundProducts.map(
            (e) => e.namemainproduct
          )
        ),
      ].filter((f) => f !== "Telas de Configuração")
    );
  }, [currentIndex, itemsPerPage, debouncedSearch, status, confirmAllDisabled]);
  return (
    <div>
      {modalNewParticipant && (
        <ModalNewParticipant
          isOpen={modalNewParticipant}
          toggle={toggleNewParticipant}
          idEvent={location.state.idEvent}
          getAllParticipants={getAllParticipants}
        />
      )}

      {modalEditParticpant && (
        <ModalEditParticipant
          isOpen={modalEditParticpant}
          toggle={toggleEditParticipant}
          participant={editParticipante}
          getAllParticipants={getAllParticipants}
          idEvent={location.state.idEvent}
        />
      )}

      {/* <ImportParticipant
        isOpen={modalImportParticipant}
        toggle={toggleImportParticipant}
        getAllParticipants={getAllParticipants}
        token={token}
        idEvent={location.state.idEvent}
      /> */}

      <ModalImportWrapper
        isOpen={modalImportWrapper}
        toggle={toggleImportWrapper}
        getAllParticipants={getAllParticipants}
        token={token}
        idEvent={location.state.idEvent}
      />

      {modalSendEmail && (
        <ModalSendEmail
          isOpen={modalSendEmail}
          toggle={() => setModalSendEmail(!modalSendEmail)}
          confirmDelete={confirmDelete}
        />
      )}

      {modalEditPassword && (
        <ModalEditPassword
          isOpen={modalEditPassword}
          toggle={toggleEditPassword}
          editParticipante={editParticipante}
          idEvent={location.state.idEvent}
          getAllParticipants={getAllParticipants}
        />
      )}

      {confirm_alert ? (
        <SweetAlert
          title={`Deseja deletar ${deleteParticipantState.nome}?`}
          warning
          showCancel
          confirmBtnText="Sim"
          cancelBtnText="Não"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            confirmDelete();
          }}
          onCancel={() => setconfirm_alert(false)}
        >
          {/* Você não poderá reverter essa ação. */}
          <a></a>
        </SweetAlert>
      ) : null}

      {confirmAlertReactivate ? (
        <SweetAlert
          title={`Deseja Reativar ${participant.nome}?`}
          warning
          showCancel
          confirmBtnText="Sim"
          cancelBtnText="Não"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            confirmReactivate();
          }}
          onCancel={() => setConfirmAlertReactivate(false)}
        >
          {/* Você não poderá reverter essa ação. */}
          <a></a>
        </SweetAlert>
      ) : null}

      {confirmAllDisabled ? (
        <SweetAlert
          title={`Deseja remover todos os Participantes?`}
          warning
          showCancel={!loadingDelete}
          showConfirm={!loadingDelete}
          confirmBtnText="Sim"
          cancelBtnText="Não"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            confirmDeleteAllParticipants();
          }}
          onCancel={() => setConfirmAllDisabled(false)}
        >
          {loadingDelete && (
            <>
              <Spinner
                style={{ color: "gray", marginBottom: "5px" }}
                animation="border"
                size="lg"
              />
              <p>Limpando participantes...</p>
            </>
          )}
        </SweetAlert>
      ) : null}

      {success_dlg ? (
        <SweetAlert
          success
          title={dynamic_title}
          onConfirm={() => {
            setsuccess_dlg(false);
          }}
        >
          <>
            <p>{dynamic_description}</p>
            {updateNotification && (
              <p style={{ color: "#218a64", fontStyle: "italic" }}>
                As Notificações agendadas foram atualizadas com esse
                participante.
              </p>
            )}
          </>
        </SweetAlert>
      ) : null}

      {alertEmail && (
        <SweetAlert
          warning
          title={"Deseja enviar email ao participante?"}
          showConfirm={false}
        >
          <div className="div-options-modal">
            <Button
              color="primary"
              className="btn btn-primary"
              onClick={() => setAlertEmail(false)}
            >
              Fechar
            </Button>
            <Button className="btn btn-danger" onClick={() => confirmDelete()}>
              Não
            </Button>
            <Button
              className="btn btn-success"
              onClick={() => setModalSendEmail(true) > setAlertEmail(false)}
            >
              Sim
            </Button>
          </div>
        </SweetAlert>
      )}

      {alertEmailConfirm && (
        <SweetAlert
          success
          title={"Deletado"}
          onConfirm={() => {
            setAlertEmailConfirm(false);
          }}
        >
          Participante deletado com sucesso
        </SweetAlert>
      )}

      <WaitExport
        isOpen={exportInProgress}
        name="Participantes"
        preposicao="dos"
        finishExport={finishExport}
        setFinishExport={setFinishExport}
        setExportInProgress={setExportInProgress}
      />
      <Card>
        <CardBody>
          <CardTitle>Participantes</CardTitle>
          <div>
            <Row
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: 10,
                width: "50px",
              }}
            >
              <SectionComponent
                sectionName="Filtros"
                setIsOpen={setCollapseIsOpen}
                isOpen={collapseIsOpen}
              />
            </Row>
            <Collapse isOpen={collapseIsOpen}>
              <SearchFilters
                columns={columnsFilter}
                activeFilter={activeFilter}
                setActiveFilterHandle={setActiveFilterHandle}
              />
            </Collapse>
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              marginBottom: 10,
            }}
          >
            <form
              className="app-search d-none d-lg-block events-input-search"
              onSubmit={(e) => e.preventDefault()}
            >
              <div className="position-relative">
                <input
                  value={search}
                  type="text"
                  className="form-control"
                  placeholder="Pesquisar..."
                  onChange={(e) => setSearch(e.target.value)}
                />
                <span className="bx bx-search-alt" />
              </div>
            </form>
            <div className="mobile-btn-section" style={{ width: "100%" }}>
              <Row className="mobile-btns-top">
                <form
                  className="app-search"
                  style={{ padding: "0px" }}
                  onSubmit={(e) => e.preventDefault()}
                >
                  <div className="position-relative">
                    <input
                      type="text"
                      value={search}
                      className="form-control"
                      placeholder="Pesquisar..."
                      onChange={(e) => setSearch(e.target.value)}
                    />
                    <span className="bx bx-search-alt" />
                  </div>
                </form>
              </Row>
              <SectionComponent
                sectionName="Opções"
                setIsOpen={setIsOpen}
                isOpen={isOpen}
              />
              <Collapse isOpen={isOpen}>
                <Row className="mobile-btns-top">
                  {!props.reportToDashboard && (
                    <div
                      onClick={() => {
                        setTransition(true);
                        setStatus(!status);
                      }}
                    >
                      {!status ? "Filtrar: Ativos" : "Filtrar: Inativos"}
                    </div>
                  )}
                </Row>

                {importers && participants.length > 0 && (
                  <>
                    <Row className="mobile-btns-top">
                      <div
                        onClick={() =>
                          createXlsx({
                            jwt: token,
                            idEvent: location.state.idEvent,
                            limit: 500,
                            search: search,
                            status: status,
                            countData,
                            setFinishExport,
                          }) > setExportInProgress(true)
                        }
                      >
                        Exportar Participantes
                      </div>
                    </Row>
                  </>
                )}

                {checkProductExists("Distribuição de Cotas") &&
                  participants.length > 0 && (
                    <Row className="mobile-btns-top">
                      <div
                        onClick={() =>
                          createXlsxQuota({
                            jwt: token,
                            idEvent: location.state.idEvent,
                            limit: 1000,
                            countData,
                            setFinishExport,
                          }) > setExportInProgress(true)
                        }
                      >
                        Exportar Participantes com Cota(s)
                      </div>
                    </Row>
                  )}

                {!props.reportToDashboard && (
                  <>
                    {importers && (
                      <Row className="mobile-btns-top">
                        <div
                          onClick={() =>
                            // setModalImportParticipant(!modalImportParticipant)
                            {
                              setModalImportWrapper(!modalImportWrapper);
                            }
                          }
                        >
                          Importar Participantes
                        </div>
                      </Row>
                    )}
                    <Row className="mobile-btns-top">
                      <div onClick={() => setModalNewParticipant(true)}>
                        Adicionar Participante
                      </div>
                    </Row>
                  </>
                )}
              </Collapse>
            </div>
            <div className="header-btns-container">
              {!props.reportToDashboard && (
                <Button
                  color="secondary"
                  className="btn-rounded waves-effect waves-light events-button-add-lg"
                  onClick={() => {
                    setTransition(true);
                    setStatus(!status);
                  }}
                >
                  {!status ? "Filtrar: Ativos" : "Filtrar: Inativos"}
                </Button>
              )}
              {importers && participants.length > 0 && (
                <Button
                  color="success"
                  className="btn-rounded waves-effect waves-light events-button-add-lg"
                  onClick={() =>
                    createXlsx({
                      jwt: token,
                      idEvent: location.state.idEvent,
                      limit: 500,
                      search: search,
                      status: status,
                      countData,
                      setFinishExport,
                    }) > setExportInProgress(true)
                  }
                >
                  Exportar Participantes
                </Button>
              )}
              {checkProductExists("Distribuição de Cotas") &&
                participants.length > 0 && (
                  <Button
                    color="success"
                    className="btn-rounded waves-effect waves-light events-button-add-lg"
                    onClick={() =>
                      createXlsxQuota({
                        jwt: token,
                        idEvent: location.state.idEvent,
                        limit: 1000,
                        countData,
                        setFinishExport,
                      }) > setExportInProgress(true)
                    }
                  >
                    Exportar Participantes com Cota(s)
                  </Button>
                )}
              {!props.reportToDashboard && (
                <>
                  {importers && (
                    <Button
                      color="success"
                      className="btn-rounded waves-effect waves-light events-button-add-lg"
                      onClick={() =>
                        // setModalImportParticipant(!modalImportParticipant)
                        setModalImportWrapper(!modalImportWrapper)
                      }
                    >
                      Importar Participantes
                    </Button>
                  )}

                  <Button
                    color="success"
                    className="btn-rounded waves-effect waves-light events-button-add-lg"
                    onClick={() => setModalNewParticipant(true)}
                  >
                    Adicionar Participante
                  </Button>
                </>
              )}
            </div>
          </div>

          <div className="table-responsive">
            <Table className="table mb-0">
              <thead className="table-light">
                <tr>
                  <th>Nome</th>
                  <th>Email</th>
                  <th>Inscrito em</th>
                  <th>Primeiro acesso</th>
                  {!props.reportToDashboard && <th>Ações</th>}
                </tr>
              </thead>
              <tbody>
                {participants &&
                  participants
                    ?.filter((e) => e.nome?.toLocaleLowerCase())
                    ?.map((e, i) => (
                      <tr key={i}>
                        <th>{e.nome}</th>
                        <td>{e.email}</td>

                        <td>{getRegistrationDate(e)}</td>
                        <td>{getFirstAccess(e)}</td>

                        {!props.reportToDashboard && status && (
                          <td>
                            {loading && transition ? (
                              <Spinner
                                style={{ color: "green" }}
                                animation="border"
                                size="sm"
                              />
                            ) : (
                              <>
                                <i
                                  className={"mdi mdi-pencil"}
                                  style={{
                                    color: "green",
                                    fontSize: 17,
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setEditParticipante(e);
                                    setModalEditParticipant(
                                      !modalEditParticpant
                                    );
                                  }}
                                />

                                {checkItemMenuExists("APP Eventos Plus") &&
                                  checkProductExists("APP Eventos Plus") && (
                                    <i
                                      className="mdi mdi-key"
                                      style={{
                                        color: "blue",
                                        fontSize: 17,
                                        cursor: "pointer",
                                        minHeight: "100px",
                                      }}
                                      onClick={() => {
                                        setModalEditPassword(true);
                                        setEditParticipante(e);
                                      }}
                                    />
                                  )}

                                {/* <i
                                  className="mdi mdi-trash-can"
                                  style={{
                                    color: "red",
                                    fontSize: 17,
                                    cursor: "pointer",
                                    minHeight: "100px",
                                  }}
                                  onClick={async () => {
                                    setDeleteParticipantState(e);
                                    setDeleteQuotaData(
                                      quotas.filter(
                                        (quota) => quota.login === e.id
                                      )[0]
                                    );
                                    if (
                                      checkProductExists(
                                        "Distribuição de Cotas"
                                      ) &&
                                      quotas.filter(
                                        (quota) => quota.login === e.id
                                      )[0] &&
                                      (await getSessionUserFunc(e.id))
                                    ) {
                                      setAlertEmail(true);
                                    } else setconfirm_alert(true);
                                  }}
                                /> */}
                                <DeleteButton
                                  onClick={async () => {
                                    setDeleteParticipantState(e);
                                    setDeleteQuotaData(
                                      quotas.filter(
                                        (quota) => quota.login === e.id
                                      )[0]
                                    );
                                    if (
                                      checkProductExists(
                                        "Distribuição de Cotas"
                                      ) &&
                                      quotas.filter(
                                        (quota) => quota.login === e.id
                                      )[0] &&
                                      (await getSessionUserFunc(e.id))
                                    ) {
                                      setAlertEmail(true);
                                    } else setconfirm_alert(true);
                                  }}
                                  color="red"
                                  fontSize={17}
                                />
                              </>
                            )}
                          </td>
                        )}
                        {!props.reportToDashboard && !status && (
                          <td>
                            {loading && transition ? (
                              <Spinner
                                style={{ color: "green" }}
                                animation="border"
                                size="sm"
                              />
                            ) : (
                              <i
                                className="mdi mdi-account-reactivate"
                                style={{
                                  color: "green",
                                  fontSize: 17,
                                  cursor: "pointer",
                                  marginLeft: 13,
                                  minHeight: "100px",
                                }}
                                onClick={() => {
                                  setParticipant(e);
                                  setActivateQuotaData(
                                    quotas.filter(
                                      (quota) => quota.login === e.id
                                    )[0]
                                  );
                                  setConfirmAlertReactivate(true);
                                }}
                              />
                            )}
                          </td>
                        )}
                      </tr>
                    ))}
              </tbody>
            </Table>
          </div>
        </CardBody>
      </Card>

      {!props.reportToDashboard ? (
        <div
          className="bottom-navigation"
          style={{
            justifyContent: `${
              status && participants?.length > 0 ? "space-between" : "end"
            }`,
          }}
        >
          {participants?.length > 0 && status && (
            <Button
              color="danger"
              className="btn-rounded waves-effect waves-light events-button-add-lg"
              onClick={() => setConfirmAllDisabled(true)}
            >
              Limpar Todos Participantes
            </Button>
          )}
          {/* 
          Fornecer os 8 parametros obrigatórios;
          ** data - a array contendo as informações da tabela
          ** search - o state do termo de pesquisa
          ** currentIndex - posição atual do index
          ** setCurrentIndex - função para atualizar a posição atual do index
          ** countData - quantidade total de itens
          ** maxItemPerPage - estado quantidade de de itens por página
          ** setMaxItemPerPage - função que troca o estado com a quantidade maxima de itens por página 
          ** maxPageButtonsToShow - quantidade de botões qua vão aparecer para a pessoa clicar, somente números impares
          ** 5 = vai de 1 até 5 / 7 vai de 1 até 7 
          */}
          <Pagination
            data={participants}
            search={search}
            currentIndex={currentIndex}
            setCurrentIndex={setCurrentIndex}
            countData={countData}
            maxItemPerPage={itemsPerPage}
            setMaxItemPerPage={setItemsPerPage}
            maxPageButtonsToShow={3}
          />
        </div>
      ) : (
        <Pagination
          data={participants}
          search={search}
          currentIndex={currentIndex}
          setCurrentIndex={setCurrentIndex}
          countData={countData}
          maxItemPerPage={itemsPerPage}
          setMaxItemPerPage={setItemsPerPage}
          maxPageButtonsToShow={3}
        />
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return { state };
};

export default connect(mapStateToProps)(Participants);
