import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Collapse,
  Row,
  Table,
} from "reactstrap";
import { useLocation } from "react-router-dom";
import Pagination from "../../../components/Paginationv3";
import useDebounce from "../../../utils/hooks/useDebounce";
import { getAllUploadApp } from "../../../utils/services/uploadapp";
import WaitExport from "../../../components/WaitExport";
import { createXlsx } from "./createXlsx";
import SearchFilters from "../../../components/SearchFilters";
import SectionComponent from "../../Apps/Personalization/SectionComponent";

const columnsFilter = [
  { name: "Nome", value: "loginName", type: "text" },
  { name: "Data de Criação", value: "createdAt", type: "date" },
];

function UploadApp(props) {
  const location = useLocation();
  const { token: jwt } = useSelector((state) => state.global_user_data.data);
  const [uploads, setUploads] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(1);
  const [countData, setCountData] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounce(search, 300);
  const [exportInProgress, setExportInProgress] = useState(false);
  const [finishExport, setFinishExport] = useState(false);
  // modal collapse
  const [isOpen, setIsOpen] = useState(false);

  const [collapseIsOpen, setCollapseIsOpen] = useState(false);
  const [btnsAvailable, setBtnsAvailable] = useState([]);
  const [activeFilter, setActiveFilter] = useState("loginName");

  function setActiveFilterHandle(columnActive) {
    setActiveFilter(columnActive);
    setSearch("");
  }
  const reverseDate = () => {
    const reverse = debouncedSearch
      .split("/")
      .reverse()
      .join("/")
      .replace(/\//g, "-");
    return reverse;
  };
  function treatmentSearch() {
    if (
      columnsFilter?.filter((item) => item?.value === activeFilter)[0]?.type ===
      "date"
    ) {
      return reverseDate();
    }
    return debouncedSearch;
  }

  async function clipBoard(url) {
    await navigator.clipboard.writeText(url);
    alert("O link foi copiado para a área de transferência");
  }

  function exportReport() {
    createXlsx({
      jwt,
      eventId: location.state.idEvent,
      limit: 500,
      search: search,
      totalItems: countData,
      setFinishExport,
    });
    setExportInProgress(true);
  }

  const formatDate = (date) => {
    try {
      const formatedDate = date.split("T")[0].split("-").reverse().join("/");
      const formatTime = date.split("T")[1].split(".")[0];

      return `${formatedDate} ${formatTime}`;
    } catch (error) {
      return date;
    }
  };

  const getUploads = useCallback(() => {
    getAllUploadApp(
      jwt,
      currentIndex,
      itemsPerPage,
      location.state.idEvent,
      treatmentSearch(),
      activeFilter
    )
      .then((res) => {
        setUploads(res.data.findAllUpload.data);
        setCountData(res.data.findAllUpload.count);
      })
      .catch((err) => {
        // console.log(err);
      });
  }, [jwt, currentIndex, itemsPerPage, debouncedSearch]);
  useEffect(() => {
    getUploads();
  }, [getUploads]);

  return (
    <div>
      <WaitExport
        isOpen={exportInProgress}
        name="Relatório de Uploads"
        preposicao="do"
        finishExport={finishExport}
        setFinishExport={setFinishExport}
        setExportInProgress={setExportInProgress}
      />
      <Card>
        <CardBody>
          <CardTitle>Relatório por Participante</CardTitle>
          <div>
            <Row
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: 10,
                width: "50px",
              }}
            >
              <SectionComponent
                sectionName="Filtros"
                setIsOpen={setCollapseIsOpen}
                isOpen={collapseIsOpen}
              />
            </Row>
            <Collapse isOpen={collapseIsOpen}>
              <SearchFilters
                columns={columnsFilter}
                activeFilter={activeFilter}
                setActiveFilterHandle={setActiveFilterHandle}
              />
            </Collapse>
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <form
              className="app-search d-none d-lg-block events-input-search"
              onSubmit={(e) => e.preventDefault()}
            >
              <div className="position-relative">
                <input
                  type={activeFilter === "createdAt" ? "date" : "text"}
                  value={search}
                  className="form-control"
                  placeholder="Pesquisar..."
                  onChange={(e) => setSearch(e.target.value)}
                />
                <span className="bx bx-search-alt" />
              </div>
            </form>
            <div className="mobile-btn-section" style={{ width: "100%" }}>
              <Row className="mobile-btns-top">
                <form
                  className="app-search"
                  style={{ padding: "0px" }}
                  onSubmit={(e) => e.preventDefault()}
                >
                  <div className="position-relative">
                    <input
                      type={activeFilter === "createdAt" ? "date" : "text"}
                      value={search}
                      className="form-control"
                      placeholder="Pesquisar..."
                      onChange={(e) => setSearch(e.target.value)}
                    />
                    <span className="bx bx-search-alt" />
                  </div>
                </form>
              </Row>

              <SectionComponent
                sectionName="Opções"
                setIsOpen={setIsOpen}
                isOpen={isOpen}
              />

              <Collapse isOpen={isOpen}>
                <Row className="mobile-btns-top">
                  <div onClick={() => exportReport()}>Exportar Relatório</div>
                </Row>
              </Collapse>
            </div>

            <div className="header-btns-container">
              {uploads.length > 0 && (
                <Button
                  color="success"
                  className="btn-rounded waves-effect waves-light events-button-add"
                  onClick={() => exportReport()}
                >
                  Exportar Relatório
                </Button>
              )}
            </div>
          </div>
          <div className="table-responsive">
            <Table className="table mb-0">
              <thead className="table-light">
                <tr>
                  <th>Participante</th>
                  <th>Data de Criação</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                {uploads?.length > 0 &&
                  uploads?.map((up, i) => (
                    <tr key={i}>
                      <td>{up.loginName}</td>
                      <td>
                        <td>{formatDate(up.createdAt)}</td>
                      </td>
                      <td>
                        <i
                          className="mdi mdi-content-copy"
                          style={{
                            color: "grey",
                            fontSize: 17,
                            margin: "0 3px",
                            cursor: "pointer",
                          }}
                          title="Copiar"
                          onClick={() => clipBoard(up.url)}
                        />
                        <a
                          href={up.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          title="Abrir Arquivo"
                        >
                          <i
                            className="mdi mdi-location-enter"
                            style={{
                              color: "blue",
                              fontSize: 17,
                              margin: "0 3px",
                              cursor: "pointer",
                            }}
                          />
                        </a>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
        </CardBody>
      </Card>
      <div className="bottom-navigation" style={{ justifyContent: "end" }}>
        <Pagination
          data={uploads}
          currentIndex={currentIndex}
          setCurrentIndex={setCurrentIndex}
          countData={countData}
          maxItemPerPage={itemsPerPage}
          setMaxItemPerPage={setItemsPerPage}
          maxPageButtonsToShow={3}
        />
      </div>
    </div>
  );
}

export default UploadApp;
