import React, { useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  Button,
  Input,
  Label,
  Collapse,
  Table,
} from "reactstrap";

//input color
import ColorPicker from "../../../components/ColorPicker/ColorPicker";
import SectionComponent from "../../Apps/Personalization/SectionComponent";
import EditScreen from "./ModalEditScreen";

const data = [
  {
    name: "teste",
    description: "descrição teste",
    date: "10/08/2022",
  },
];

export default function AdjustmentScreen(props) {
  const { isOpen, toggle } = props;
  const [modalBackdrop, setModalBackdrop] = useState(false);

  // screen name
  const [screenName, setScreenName] = useState("");

  // color picker
  const [isCollapse, setIsCollapse] = useState(false);

  //color
  const [colorBg, setColorBg] = useState("#FFFFFF");
  const [colorCard, setColorCard] = useState("#FFFFFF");
  const [colorBorder, setColorBorder] = useState("#FFFFFF");
  const [colorTextName, setColorTextName] = useState("#FFFFFF");
  const [colorButton, setColorButton] = useState("#FFFFFF");
  const [colorTextButton, setColorTextButton] = useState("#FFFFFF");

  const [selectedScreen, setSelectedScreen] = useState("");
  const [deleteScreen, setDeleteScreen] = useState(false);
  const [editScreen, setEditScreen] = useState(false);
  const [successDelete, setSuccessDelete] = useState(false);

  async function clipBoard(url) {
    await navigator.clipboard.writeText(url);
    alert("O link foi copiado para a área de transferência");
  }

  function toggleBackdrop() {
    setModalBackdrop(!modalBackdrop);
  }

  return (
    <Modal
      size="lg"
      isOpen={isOpen}
      toggle={() => toggleBackdrop()}
      autoFocus={true}
      centered={true}
    >
      {deleteScreen && (
        <SweetAlert
          warning
          showCancel
          confirmBtnText="Sim"
          cancelBtnText="Não"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          title={`Você tem certeza que deseja excluir a tela?`}
          onConfirm={() => {
            setDeleteScreen(false);
            setSuccessDelete(true);
          }}
          onCancel={() => {
            setDeleteScreen(false);
          }}
        >
          Você não poderá reverter essa ação.
        </SweetAlert>
      )}
      {successDelete && (
        <SweetAlert
          success
          title="Deletado"
          onConfirm={() => {
            setSuccessDelete(false);
          }}
        >
          {`Tela deletada com sucesso`}
        </SweetAlert>
      )}
      <EditScreen
        isOpen={editScreen}
        toggle={() => setEditScreen(!editScreen)}
        selectedScreen={selectedScreen}
      />
      <ModalHeader toggle={toggle}>Configurações da Tela</ModalHeader>
      <ModalBody>
        <Row>
          <Col>
            <Label htmlFor="nameScreen">Nome da Tela</Label>
            <Input
              type="text"
              className="form-control"
              placeholder="Digite o nome da tela"
              id="nameScreen"
              value={screenName}
              onChange={(e) => setScreenName(e.target.value)}
            />
          </Col>
        </Row>
        <SectionComponent
          sectionName={"Cores da Tela"}
          setIsOpen={setIsCollapse}
          isOpen={isCollapse}
        />
        <Collapse isOpen={isCollapse}>
          <Row>
            <Col md={6}>
              <ColorPicker
                classOfContainer="primary-card-pickr"
                labelText="Cor de Fundo"
                setStateFunc={setColorBg}
                defaultColor={colorBg}
              />
            </Col>
            <Col md={6}>
              <ColorPicker
                classOfContainer="secondary-card-pickr"
                labelText="Cor do Cartão"
                setStateFunc={setColorCard}
                defaultColor={colorCard}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <ColorPicker
                classOfContainer="tertiary-card-pickr"
                labelText="Cor da Borda do Cartão"
                setStateFunc={setColorBorder}
                defaultColor={colorBorder}
              />
            </Col>
            <Col md={6}>
              <ColorPicker
                classOfContainer="quaternary-card-pickr"
                labelText=" Cor dos Textos Nome e Descrição do Documento"
                setStateFunc={setColorTextName}
                defaultColor={colorTextName}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <ColorPicker
                classOfContainer="quintenary-card-pickr"
                labelText="Cor do Botão"
                setStateFunc={setColorButton}
                defaultColor={colorButton}
              />
            </Col>
            <Col md={6}>
              <ColorPicker
                classOfContainer="sextiary-card-pickr"
                labelText="Cor do Texto do Botão"
                setStateFunc={setColorTextButton}
                defaultColor={colorTextButton}
              />
            </Col>
          </Row>
        </Collapse>
        <Row
          style={{
            width: "100%",
            marginTop: 15,
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button
            color="success"
            className="btn btn-success waves-effect waves-light"
            style={{ width: 80, marginTop: 10 }}
            disabled={screenName === ""}
          >
            Salvar
          </Button>
        </Row>
        <Table className="table mb-0" style={{ marginTop: "15px" }}>
          <thead className="table-light">
            <tr>
              <th>Tela</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            {data.length > 0 &&
              data.map((img, i) => (
                <tr key={i}>
                  <td>{img.name}</td>
                  <td>
                    <i
                      className="mdi mdi-content-copy"
                      style={{
                        color: "grey",
                        fontSize: 17,
                        margin: "0 3px",
                        cursor: "pointer",
                      }}
                      title="Copiar"
                      onClick={() => clipBoard(img.name)}
                    />
                    <a
                      href="https://www.google.com"
                      target="_blank"
                      rel="noopener noreferrer"
                      title="Visualizar"
                    >
                      <i
                        className="mdi mdi-eye"
                        style={{
                          color: "blue",
                          fontSize: 17,
                          margin: "0 3px",
                          cursor: "pointer",
                        }}
                      />
                    </a>
                    <i
                      className="mdi mdi-pencil"
                      title="Editar"
                      style={{
                        margin: "0 3px",
                        color: "green",
                        fontSize: 17,
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setEditScreen(true);
                        setSelectedScreen(img);
                      }}
                    />
                    <i
                      className="mdi mdi-trash-can"
                      title="Excluir"
                      style={{
                        color: "red",
                        fontSize: 17,
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setDeleteScreen(true);
                        setSelectedScreen(img);
                      }}
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </ModalBody>
    </Modal>
  );
}
