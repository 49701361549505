/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Col,
  Row,
  Label,
  Button,
  Collapse,
  UncontrolledTooltip,
} from "reactstrap";
//redux
import { connect } from "react-redux";
import ColorPicker from "../../components/ColorPicker/ColorPicker";
import { getMobileAppsByEvent } from "../../utils/services/events";
import { getEventType } from "../../utils/services/eventType";

function ViewEvent(props) {
  const { isOpen, toggle, currentEventSelected } = props;
  const { token } = useSelector((state) => state.global_user_data.data);

  const [modalBackdrop, setModalBackdrop] = useState(false);
  //produtos
  const [productsList, setProductsList] = useState([]);
  // tipo de evento
  const [eventTypesSelected, setEventTypesSelected] = useState();

  function toggleBackdrop() {
    setModalBackdrop(!modalBackdrop);
  }

  function formatData(startDate) {
    var data = new Date(startDate);
    var dia = String(data.getDate()).padStart(2, "0");
    var mes = String(data.getMonth() + 1).padStart(2, "0");
    var ano = data.getFullYear();
    var dataAtual = dia + "-" + mes + "-" + ano;

    return dataAtual;
  }

  function getProductChecked(item) {
    var product = currentEventSelected.product.filter(
      (e) => e.id === item.idmainproduct
    );

    if (product.length > 0) {
      return true;
    } else return false;
  }

  function getProducts() {
    var listProducts = [];

    props.state.global_user_data.data.foundProducts.map((product) => {
      var filter = listProducts.filter(
        (e) => e.namemainproduct === product.namemainproduct
      );

      if (filter.length === 0) {
        listProducts.push({
          namemainproduct: product.namemainproduct,
          idmainproduct: product.idmainproduct,
        });
      }
    });

    setProductsList(
      listProducts.filter((f) => f.namemainproduct !== "Telas de Configuração")
    );
  }

  async function getListEventType() {
    await getEventType({
      jwt: token,
      offset: 1,
      limit: 1000,
      search: "",
      active: "",
    })
      .then((res) => {
        if (res.message === "findEventTypes") {
          var listTypes = null;
          var searchTypes = res.findEventTypesData.data.filter(
            (e) => e.id === currentEventSelected.category
          );

          if (searchTypes[0]) {
            listTypes = searchTypes[0].type;
          }

          setEventTypesSelected(listTypes);
        }
      })
      // .catch((error) => console.log("erro", error));
  }

  useEffect(() => {
    getProducts();
    getListEventType();
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => {
        toggleBackdrop();
      }}
      autoFocus={true}
      centered={true}
    >
      <ModalHeader toggle={toggle}>Visualizar Evento</ModalHeader>
      <ModalBody>
        <Row>
          <Col>
            <div>
              <label className="form-label"> ID do Evento</label>
              <input
                className="form-control"
                type="text"
                value={currentEventSelected.id}
                disabled
              />
            </div>
          </Col>
        </Row>

        <Row style={{ marginTop: 10 }}>
          <Col lg={6}>
            <div>
              <label className="form-label">Nome do Evento</label>
              <input
                className="form-control"
                type="text"
                value={currentEventSelected.title}
                disabled
              />
            </div>
          </Col>
          <Col lg={6}>
            <div>
              <label className="form-label">Tipo de Evento</label>
              <input
                className="form-control"
                type="text"
                value={eventTypesSelected}
                disabled
              />
            </div>
          </Col>
        </Row>

        <Row style={{ marginTop: 10 }}>
          <Col lg={12}>
            <div>
              <label className="form-label">Descrição</label>
              <textarea
                className="form-control"
                type="text"
                value={currentEventSelected.description}
                disabled
              />
            </div>
          </Col>
        </Row>

        <Row style={{ marginTop: 10 }}>
          <Col lg={12}>
            <div>
              <label className="form-label">Formato do Evento</label>
              <input
                className="form-control"
                type="text"
                value={currentEventSelected.eventFormat}
                disabled
              />
            </div>
          </Col>
        </Row>
        {currentEventSelected.eventFormat === "digital" ? null : (
          <>
            <Row style={{ marginTop: 10 }}>
              <Col lg={12}>
                <div>
                  <label className="form-label">Localização</label>
                  <input
                    className="form-control"
                    type="text"
                    value={`${currentEventSelected.localizations[0].description}`}
                    disabled
                  />
                </div>
              </Col>
            </Row>
            <Row style={{ marginTop: 2 }}>
              <Col lg={6}>
                <div>
                  <label className="form-label">Latitude</label>
                  <input
                    value={`${currentEventSelected.localizations[0].latitude}`}
                    className="form-control"
                    type="text"
                    disabled
                  />
                </div>
              </Col>
              <Col lg={6}>
                <div>
                  <div>
                    <label className="form-label">Longitude</label>
                    <input
                      value={`${currentEventSelected.localizations[0].longitude}`}
                      className="form-control"
                      type="text"
                      disabled
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </>
        )}

        <Row style={{ marginTop: 10 }}>
          <Col lg={6}>
            <div>
              <label className="form-label">Data Inicio</label>
              <input
                className="form-control"
                type="text"
                value={formatData(currentEventSelected.startDate)}
                disabled
              />
            </div>
          </Col>
          <Col lg={6}>
            <div>
              <label className="form-label">Data Fim</label>
              <input
                className="form-control"
                type="text"
                value={formatData(currentEventSelected.endDate)}
                disabled
              />
            </div>
          </Col>
        </Row>

        <Row style={{ marginTop: 10 }}>
          <Col lg={6}>
            <div>
              <label className="form-label">Hora Inicio</label>
              <input
                className="form-control"
                type="text"
                value={currentEventSelected.startHour}
                disabled
              />
            </div>
          </Col>
          <Col lg={6}>
            <div>
              <label className="form-label">Hora Fim</label>
              <input
                className="form-control"
                type="text"
                value={currentEventSelected.endHour}
                disabled
              />
            </div>
          </Col>
        </Row>

        <Row style={{ marginTop: 10 }}>
          <Col md={6}>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                defaultChecked={currentEventSelected.showCompany}
                id="checkCompany"
                disabled
              />
              <label className="form-check-label" htmlFor="checkCompany">
                Exibir Empresa
              </label>
            </div>
          </Col>

          <Col md={6}>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                defaultChecked={currentEventSelected.showJobPosition}
                id="checkOffice"
                disabled
              />
              <label className="form-check-label" htmlFor="checkOffice">
                Exbir Cargo
              </label>
            </div>
          </Col>
        </Row>

        <Row style={{ marginTop: 18 }}>
          <p style={{ marginBottom: 14, fontWeight: "bold" }}>
            Produto(s) do evento:
          </p>
        </Row>

        {productsList &&
          productsList.sort().map((item) => (
            <Row>
              <Col md={12}>
                <div className="form-check form-switch">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id={item.idmainproduct}
                    disabled
                    defaultChecked={getProductChecked(item)}
                  />
                  <label
                    className="form-check-label"
                    htmlFor={item.idmainproduct}
                  >
                    {item.namemainproduct}
                  </label>
                </div>
              </Col>
            </Row>
          ))}

        <Row
          style={{
            width: "100%",
            margin: "auto",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button
            color="danger"
            className="btn btn-success waves-effect waves-light"
            style={{ width: 80 }}
            onClick={() => toggle()}
          >
            Fechar
          </Button>
        </Row>
      </ModalBody>
    </Modal>
  );
}

const mapStateToProps = function (state) {
  return { state: state };
};

export default connect(mapStateToProps)(ViewEvent);
