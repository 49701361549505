import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  Row,
  Col,
  Collapse,
} from "reactstrap";
// componentes
import WaitExport from "../../../components/WaitExport";
import SectionComponent from "../../Apps/Personalization/SectionComponent";
import { createXlsx } from "./createXlsx";

export default function ModalExport(props) {
  const { isOpen, toggle, jwt, eventId, count } = props;

  // exportar
  const [exportInProgress, setExportInProgress] = useState(false);
  const [finishExport, setFinishExport] = useState(false);
  // sessoes
  const [sectionSimple, setSectionSimple] = useState(false);
  const [sectionDynamic, setSectionDynamic] = useState(false);
  // codigos internos
  const [cargo, setCargo] = useState(false);
  const [codeQrcode, setCodeQrcode] = useState(false);
  const [empresa, setEmpresa] = useState(false);
  const [code1, setCode1] = useState(false);
  const [code2, setCode2] = useState(false);
  const [code3, setCode3] = useState(false);
  // erros
  const [codeErro, setCodeErro] = useState(false);

  function exportFunc(e) {
    if (
      e === 'person' &&
      !cargo &&
      !codeQrcode &&
      !empresa &&
      !code1 &&
      !code2 &&
      !code3
    ) {
      setSectionDynamic(true);
      setCodeErro(true);
      return;
    } else {
      setCodeErro(false);
      setCodeQrcode(false);
      setEmpresa(false);
      setCargo(false);
      setCode1(false);
      setCode2(false);
      setCode3(false);
    }

    createXlsx({
      jwt: jwt,
      idEvent: eventId,
      limit: 500,
      countData: count,
      codeQrcode: e && codeQrcode,
      empresa: e && empresa,
      cargo: e && cargo,
      code1: e && code1,
      code2: e && code2,
      code3: e && code3,

      setFinishExport,
    });
    setExportInProgress(true);
  }

  function verifySimple() {
    setSectionSimple(!sectionSimple);
    setSectionDynamic(false);
  }

  function verifyDynamic() {
    setSectionDynamic(!sectionDynamic);
    setSectionSimple(false);
  }
  return (
    <>
      <WaitExport
        isOpen={exportInProgress}
        name="Relatório de Quartos"
        preposicao="dos"
        finishExport={finishExport}
        setFinishExport={() => toggle()}
        setExportInProgress={setExportInProgress}
      />
      <Modal isOpen={isOpen} autoFocus centered>
        <ModalHeader toggle={toggle}>Exportar Relatório de Quartos</ModalHeader>
        <ModalBody>
          <Row>
            <SectionComponent
              sectionName={'Relatório Simples'}
              setIsOpen={verifySimple}
              isOpen={sectionSimple}
            />
          </Row>

          <Collapse isOpen={sectionSimple}>
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                marginTop: 20,
              }}
            >
              <Button
                color="success"
                className="btn btn-success waves-effect waves-light"
                style={{ width: '300px' }}
                onClick={() => exportFunc()}
              >
                Gerar relatório simples
              </Button>
            </div>
          </Collapse>

          <Row>
            <SectionComponent
              sectionName={'Relatório Personalizado'}
              setIsOpen={verifyDynamic}
              isOpen={sectionDynamic}
            />
          </Row>

          <Collapse isOpen={sectionDynamic}>
            <Row>
              <div>
                <label
                  className="switch"
                  style={{ display: 'flex', marginTop: 10 }}
                >
                  <input
                    type="checkbox"
                    onChange={(e) => setCodeQrcode(e.target.checked)}
                  />
                  <span className="slider round" style={{ marginLeft: 10 }}>
                    Código QRCode
                  </span>
                </label>
              </div>
              <div>
                <label className="switch" style={{ display: 'flex' }}>
                  <input
                    type="checkbox"
                    onChange={(e) => setEmpresa(e.target.checked)}
                  />
                  <span className="slider round" style={{ marginLeft: 10 }}>
                    Empresa
                  </span>
                </label>
              </div>
              <div>
                <label className="switch" style={{ display: 'flex' }}>
                  <input
                    type="checkbox"
                    onChange={(e) => setCargo(e.target.checked)}
                  />
                  <span className="slider round" style={{ marginLeft: 10 }}>
                    Cargo
                  </span>
                </label>
              </div>
              <div>
                <label className="switch" style={{ display: 'flex' }}>
                  <input
                    type="checkbox"
                    onChange={(e) => setCode1(e.target.checked)}
                  />
                  <span className="slider round" style={{ marginLeft: 10 }}>
                    Código Interno 1
                  </span>
                </label>
              </div>

              <div>
                <label className="switch" style={{ display: 'flex' }}>
                  <input
                    type="checkbox"
                    onChange={(e) => setCode2(e.target.checked)}
                  />
                  <span className="slider round" style={{ marginLeft: 10 }}>
                    Código Interno 2
                  </span>
                </label>
              </div>

              <div>
                <label className="switch" style={{ display: 'flex' }}>
                  <input
                    type="checkbox"
                    onChange={(e) => setCode3(e.target.checked)}
                  />
                  <span className="slider round" style={{ marginLeft: 10 }}>
                    Código Interno 3
                  </span>
                </label>
              </div>
            </Row>

            {codeErro && (
              <p style={{ color: 'red' }}>Selecione ao menos uma opção</p>
            )}

            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                marginTop: 20,
              }}
            >
              <Row>
                <Col md={12}>
                  <Button
                    color="success"
                    className="btn btn-success waves-effect waves-light"
                    style={{ width: '300px' }}
                    onClick={() => exportFunc('person')}
                  >
                    Gerar relatório personalizado
                  </Button>
                </Col>
              </Row>
            </div>
          </Collapse>
        </ModalBody>
      </Modal>
    </>
  );
}
