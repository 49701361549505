import React, { useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { useSelector } from "react-redux";
import { enableContract } from "../../utils/services/contracts";

export default function ModalEnableContract(props) {
  const { enableContractId, nameContract, refetch, setEnableContractAlert } =
    props;
  const { token } = useSelector((state) => state.global_user_data.data);
  const [success, setSuccess] = useState(false);

  async function enable() {
    enableContract(token, enableContractId).then(() => refetch());
    // .catch((error) => console.log('error', error));
  }

  return (
    <div>
      <SweetAlert
        title={`Deseja ativar o Contrato ${nameContract}?`}
        warning
        showCancel
        confirmBtnText="Sim"
        cancelBtnText="Não"
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="danger"
        onConfirm={() => {
          enable();
          setSuccess(true);
        }}
        onCancel={() => {
          setEnableContractAlert(false);
        }}
      ></SweetAlert>
      {success && (
        <SweetAlert
          success
          title="Reativar"
          onConfirm={() => {
            setSuccess(false);
            setEnableContractAlert(false);
          }}
        >
          Contrato reativado com sucesso!
        </SweetAlert>
      )}
    </div>
  );
}
