import { useSelector } from "react-redux";

export const useUserPermissions = () => {
  const { act } = useSelector((state) => state.global_user_data.data.theme[0]);

  const canCreate = () => {
    return act?.includes("W");
  };

  const canDelete = () => {
    return act?.includes("D");
  };

  const canRead = () => {
    return act?.includes("R");
  };

  const canUpdate = () => {
    return act?.includes("E");
  };

  return {
    canCreate,
    canDelete,
    canRead,
    canUpdate,
  };
};
