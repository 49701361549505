import { format } from "date-fns";
import writeXlsxFile from "write-excel-file";
import { getAllRanking } from "../../../utils/services/ranking";

export async function createXlsx({
  jwt,
  id,
  limit,
  search,
  countData,
  setFinishExport,
}) {
  const schema = [
    {
      column: "ID do Participante",
      type: String,
      value: (ranking) => ranking?.loginId,
    },
    {
      column: "Nome",
      type: String,
      value: (ranking) => ranking?.name,
    },
    {
      column: "Pontuação",
      type: Number,
      value: (ranking) => ranking.totalPoints,
    },
    {
      column: "Data/Hora da última pontuação",
      type: String,
      value: (ranking) =>
        format(new Date(ranking.updatedAt), "dd-MM-yyyy HH:mm:ss"),
    },
  ];

  const allRequestsPromises = [];
  const numberOfRequisitions = Math.ceil(countData / limit);

  for (let i = 1; i <= numberOfRequisitions; i += 1) {
    const request = await getAllRanking({
      jwt,
      id,
      offset: i,
      limit,
      search,
    });
    allRequestsPromises.push(request);
    setTimeout(() => {}, 500);

    const progressBar = document.querySelector(".export-progress");
    const percentageBar = document.querySelector(".export-percentage");

    progressBar.style.width = `${(i / numberOfRequisitions) * 100}%`;
    percentageBar.innerHTML = `${((i / numberOfRequisitions) * 100).toFixed(
      0
    )}%`;

    if (i === numberOfRequisitions) {
      setTimeout(() => {
        setFinishExport(true);
      }, 1000);
    }
  }

  const allDataInSingleArray = allRequestsPromises.map((e) => e.data).flat();

  if (allDataInSingleArray) {
    await writeXlsxFile(allDataInSingleArray, {
      schema,
      fileName: "ranking.xlsx",
    });
  }
}
