import { urlBase } from "./config";
import axiosInstance from "../services/config/axiosinstance";

function createHotel({
  event,
  login,
  hotel,
  checkindate,
  checkoutdate,
  checkintime,
  checkouttime,
  reservationnumber,
  address,
  phonenumber,
  extrainformation,
  jwt,
}) {
  var data = JSON.stringify({
    event: event,
    login: login,
    hotel: hotel,
    checkindate: checkindate,
    checkoutdate: checkoutdate,
    checkintime: checkintime,
    ckeckouttime: checkouttime,
    reservationnumber: reservationnumber,
    address: address,
    phonenumber: phonenumber,
    extrainformation: extrainformation,
  });

  var config = {
    method: "post",
    url: `${urlBase}/hotels`,
    headers: {
      "Content-Type": "application/json",
    },
    data,
  };

  return axiosInstance(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

function createHotelImport({
  event,
  login,
  hotel,
  checkindate,
  checkoutdate,
  checkintime,
  checkouttime,
  reservationnumber,
  address,
  phonenumber,
  extrainformation,
  jwt,
}) {
  var data = JSON.stringify({
    event: event,
    login: login,
    hotel: hotel,
    checkindate: checkindate,
    checkoutdate: checkoutdate,
    checkintime: checkintime,
    ckeckouttime: checkouttime,
    reservationnumber: reservationnumber,
    address: address,
    phonenumber: phonenumber,
    extrainformation: extrainformation,
  });

  var config = {
    method: "post",
    url: `${urlBase}/hotels/import`,
    headers: {
      "Content-Type": "application/json",
    },
    data,
  };

  return axiosInstance(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

function getHotel({ jwt, idEvent, offset, limit, search, columnSearch = "" }) {
  var config = {
    method: "get",
    url: `${urlBase}/hotels/event/${idEvent}?offset=${offset}&limit=${limit}&search=${search}&columnSearch=${columnSearch}`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  return axiosInstance(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response.data;
    });
}

function deleteHotel({ jwt, id }) {
  var config = {
    method: "delete",
    url: `${urlBase}/hotels/${id}`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  return axiosInstance(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response.data;
    });
}

async function deleteAllHotels({ jwt, eventId }) {
  var config = {
    method: "put",
    url: `${urlBase}/hotels/disableall/${eventId}`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  return axiosInstance(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response.data;
    });
}

function updateHotel({
  event,
  login,
  hotel,
  checkindate,
  checkoutdate,
  checkintime,
  checkouttime,
  reservationnumber,
  address,
  phonenumber,
  extrainformation,
  jwt,
  idHotel,
}) {
  var data = JSON.stringify({
    event: event,
    login: login,
    hotel: hotel,
    checkindate: checkindate,
    checkoutdate: checkoutdate,
    checkintime: checkintime,
    ckeckouttime: checkouttime,
    reservationnumber: reservationnumber,
    address: address,
    phonenumber: phonenumber,
    extrainformation: extrainformation,
  });

  var config = {
    method: "put",
    url: `${urlBase}/hotels/${idHotel}`,
    headers: {
      "Content-Type": "application/json",
    },
    data,
  };

  return axiosInstance(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

export {
  createHotel,
  createHotelImport,
  getHotel,
  deleteAllHotels,
  deleteHotel,
  updateHotel,
};
