import { urlBase } from "./config";
import axiosInstance from "../services/config/axiosinstance";

function getUsers({ jwt, offset, limit, search, columnSearch = "" }) {
  // console.log(search)
  var config = {
    method: "get",
    url: `${urlBase}/admin?offset=${offset}&limit=${limit}&search=${search}&columnSearch=${columnSearch}`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  return axiosInstance(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response.data;
    });
}

function deleteUser({ jwt, id }) {
  var config = {
    method: "delete",
    url: `${urlBase}/admin/${id}`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  return axiosInstance(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response.data;
    });
}
function updateUser(data, jwt) {
  var config = {
    method: "post",
    url: `${urlBase}/admin/updateuser`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  return axiosInstance(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      // console.log(error)
      return error.response;
    });
}
function createUser({ nome, email, senha, profile }, jwt) {
  var data = JSON.stringify({
    nome,
    email,
    senha,
    profile,
  });
  var config = {
    method: "post",
    url: `${urlBase}/signup`,
    headers: {
      "Content-Type": "application/json",
    },
    data,
  };

  return axiosInstance(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response.data;
    });
}

export { getUsers, deleteUser, updateUser, createUser };
