import writeXlsxFile from "write-excel-file";
import { getParticipants } from "../../../../../utils/services/reports";

export async function createXlsx({ idEvent, countData, setFinishExport }) {
  try {
    const formatDate = (datedownload, timeDownload) => {
      const date = datedownload.split("T")[0].split("-").reverse().join("-");
      return `${date} ${timeDownload}`;
    };

    const schema = [
      {
        column: "ID do Participante",
        type: String,
        value: (participant) => participant.loginid,
      },
      {
        column: "Nome do Participante",
        type: String,
        value: (participant) => participant.loginname,
      },
      {
        column: "Email do Participante",
        type: String,
        value: (participant) => participant.loginemail,
      },
      {
        column: "Nome do Arquivo",
        type: String,
        value: (participant) => participant.filename,
      },
      {
        column: "Data e Hora do Download",
        type: String,
        value: (participant) =>
          formatDate(participant.datedownload, participant.timedownload),
      },
    ];

    const limit = 50;

    const numberOfRequisitions = Math.ceil(countData / limit);
    const allRequests = [];

    for (let i = 1; i <= numberOfRequisitions; i += 1) {
      const response = await getParticipants({
        idEvent,
        offset: i,
        limit,
      });

      allRequests.push(response.data.files);
      setTimeout(() => {}, 500);

      const progressBar = document.querySelector(".export-progress");
      const percentageBar = document.querySelector(".export-percentage");

      progressBar.style.width = `${(i / numberOfRequisitions) * 100}%`;
      percentageBar.innerHTML = `${((i / numberOfRequisitions) * 100).toFixed(
        0
      )}%`;

      if (i === numberOfRequisitions) {
        setTimeout(() => {
          setFinishExport(true);
        }, 1000);
      }
    }

    const participants = allRequests.flat();

    await writeXlsxFile(participants, {
      schema,
      fileName: "relatorio-participantes.xlsx",
    });
  } catch (error) {
    // console.log(error);
  }
}
