import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  Button,
  Collapse,
} from "reactstrap";
import * as XLSX from "xlsx";
// componentes
import ColumnChart from "./columnChart";
import LineChart from "./lineChart";
import SectionComponent from "../../Apps/Personalization/SectionComponent";
// utils
import {
  colaboradorInscrito,
  colaboradorPendente,
  convidadoInscrito,
  convidadoPendente,
  revendaInscrito,
  revendaPendente,
  sheets,
} from "./dataUtils";
// service
import {
  getDays,
  getGeneral,
  getEvolution,
  getFuncionarios,
} from "../../../utils/services/dashboardVibra";

export default function DashboardVibra() {
  const [dataByDay, setDataByDay] = useState([]);
  const [dataEvolution, setDataEvolution] = useState([]);
  const [generalData, setGeneralData] = useState(null);
  // collapse state
  const [isOpen, setIsOpen] = useState(false);
  // botão de exportar
  const [downloadText, setDownloadText] = useState("Exportar Planilha");

  async function exportarFuncionarios() {
    setDownloadText("Processando...");
    const { colaboradores, revendedores, convidados } = await getFuncionarios();

    const wsci = XLSX.utils.json_to_sheet(colaboradores.inscritos);
    const wscp = XLSX.utils.json_to_sheet(colaboradores.pendentes);
    const wsri = XLSX.utils.json_to_sheet(revendedores.inscritos);
    const wsrp = XLSX.utils.json_to_sheet(revendedores.pendentes);
    const wsgi = XLSX.utils.json_to_sheet(convidados.inscritos);
    const wsgp = XLSX.utils.json_to_sheet(convidados.pendentes);

    colaboradorInscrito.forEach((header, index) => {
      wsci[XLSX.utils.encode_cell({ r: 0, c: index })] = { v: header };
    });

    colaboradorPendente.forEach((header, index) => {
      wscp[XLSX.utils.encode_cell({ r: 0, c: index })] = { v: header };
    });

    revendaInscrito.forEach((header, index) => {
      wsri[XLSX.utils.encode_cell({ r: 0, c: index })] = { v: header };
    });

    revendaPendente.forEach((header, index) => {
      wsrp[XLSX.utils.encode_cell({ r: 0, c: index })] = { v: header };
    });

    convidadoInscrito.forEach((header, index) => {
      wsgi[XLSX.utils.encode_cell({ r: 0, c: index })] = { v: header };
    });

    convidadoPendente.forEach((header, index) => {
      wsgp[XLSX.utils.encode_cell({ r: 0, c: index })] = { v: header };
    });

    const wb = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(wb, wsci, sheets[0]);
    XLSX.utils.book_append_sheet(wb, wscp, sheets[1]);
    XLSX.utils.book_append_sheet(wb, wsri, sheets[2]);
    XLSX.utils.book_append_sheet(wb, wsrp, sheets[3]);
    XLSX.utils.book_append_sheet(wb, wsgi, sheets[4]);
    XLSX.utils.book_append_sheet(wb, wsgp, sheets[5]);

    XLSX.writeFile(wb, "vibra.xlsx");
    setDownloadText("Exportar Planilha");
  }

  function getDaysFunc() {
    getDays()
      .then((res) => {
        if (res[0]) setDataByDay(res);
      })
      .catch((error) => console.log("erro", error));
  }

  function getGeneralFunc() {
    getGeneral()
      .then((res) => {
        // console.log("ok geral", res);
        if (res.total) {
          setGeneralData(res.geral);
        }
      })
      .catch((error) => console.log("erro", error));
  }

  function getRegistered(name) {
    var search = generalData.find((e) => e.grupo === name);

    var totalReturn = "-";

    if (search) {
      totalReturn = search.total_participantes;
    }

    return totalReturn;
  }

  function getNeedRegister(name) {
    var search = generalData.find((e) => e.grupo === name);

    var totalReturn = "-";

    if (search) {
      if (name === "revendedor") {
        totalReturn = `${
          Number(search.total_geral) - Number(search.total_revendedores)
        } ( ${(100 - search.percentual_revendedores).toFixed(2)} % )`;
      } else {
        totalReturn = `${
          Number(search.total_geral) - Number(search.total_participantes)
        } ( ${(100 - search.percentual_participantes).toFixed(2)} % )`;
      }
    }

    return totalReturn;
  }

  function getEvolutionFunc() {
    getEvolution()
      .then((res) => {
        // console.log("ok evolution", res);
        if (res[0]) setDataEvolution(res);
      })
      .catch((error) => console.log("erro evolution", error));
  }

  useEffect(() => {
    getDaysFunc();
    getGeneralFunc();
    getEvolutionFunc();
  }, []);

  return (
    <>
      <Card>
        <CardBody>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "flex-end",
              marginBottom: 10,
            }}
          >
            <div className="header-btns-container">
              <Button
                color="success"
                className="btn-rounded waves-effect waves-light events-button-add-lg"
                onClick={() => {
                  if (downloadText === "Exportar Planilha") {
                    exportarFuncionarios();
                  }
                }}
              >
                {downloadText}
              </Button>
            </div>
            <div className="mobile-btn-section" style={{ width: "100%" }}>
              <SectionComponent
                sectionName="Opções"
                setIsOpen={setIsOpen}
                isOpen={isOpen}
              />

              <Collapse isOpen={isOpen}>
                <Row
                  className="mobile-btns-top"
                  onClick={() => {
                    if (downloadText === "Exportar Planilha") {
                      exportarFuncionarios();
                    }
                  }}
                >
                  <div>{downloadText}</div>
                </Row>
              </Collapse>
            </div>
          </div>
          <CardTitle>Totalizadores</CardTitle>

          <Row style={{ marginTop: 20 }}>
            <h5>Cadastrados</h5>
          </Row>

          <Row style={{ margin: "20px 0" }}>
            <Col
              md={12}
              className="logistic-all-topics"
              style={{ flexWrap: "wrap" }}
            >
              <div
                className="logistic-topic card-shadow"
                style={{
                  background:
                    "linear-gradient(45deg, rgb(52, 199, 89) 0%, rgb(114 241 146) 100%)",
                }}
              >
                <h4
                  className="logistics-title-topic"
                  style={{ color: "black" }}
                >
                  Time Vibra
                </h4>
                <a style={{ color: "black" }}>
                  {generalData ? getRegistered("funcionário") : "-"}
                </a>
              </div>

              <div
                className="logistic-topic card-shadow"
                style={{
                  background:
                    "linear-gradient(45deg, rgb(255, 193, 7) 0%, #ffe596 100%)",
                }}
              >
                <h4
                  className="logistics-title-topic"
                  style={{ color: "black" }}
                >
                  Revendedor
                </h4>
                <a style={{ color: "black" }}>
                  {generalData ? getRegistered("revendedor") : "-"}
                </a>
              </div>

              <div
                className="logistic-topic card-shadow"
                style={{
                  background:
                    "linear-gradient(45deg, rgb(142, 36, 170) 0%, rgb(193 86 221) 100%)",
                }}
              >
                <h4
                  className="logistics-title-topic"
                  style={{ color: "black" }}
                >
                  Convidado
                </h4>
                <a style={{ color: "black" }}>
                  {generalData ? getRegistered("convidado") : "-"}
                </a>
              </div>
            </Col>
          </Row>

          <Row style={{ marginTop: 50 }}>
            <h5>Faltam se Cadastrar</h5>
          </Row>

          <Row style={{ margin: "20px 0" }}>
            <Col
              md={12}
              className="logistic-all-topics"
              style={{ flexWrap: "wrap" }}
            >
              <div
                className="logistic-topic card-shadow"
                style={{
                  background:
                    "linear-gradient(45deg, rgb(52, 199, 89) 0%, rgb(114 241 146) 100%)",
                }}
              >
                <h4
                  className="logistics-title-topic"
                  style={{ color: "black" }}
                >
                  Time Vibra
                </h4>
                <a style={{ color: "black" }}>
                  {generalData ? getNeedRegister("funcionário") : "-"}
                </a>
              </div>

              <div
                className="logistic-topic card-shadow"
                style={{
                  background:
                    "linear-gradient(45deg, rgb(255, 193, 7) 0%, #ffe596 100%)",
                }}
              >
                <h4
                  className="logistics-title-topic"
                  style={{ color: "black" }}
                >
                  Revendedor
                </h4>
                <a style={{ color: "black" }}>
                  {generalData ? getNeedRegister("revendedor") : "-"}
                </a>
              </div>

              <div
                className="logistic-topic card-shadow"
                style={{
                  background:
                    "linear-gradient(45deg, rgb(142, 36, 170) 0%, rgb(193 86 221) 100%)",
                }}
              >
                <h4
                  className="logistics-title-topic"
                  style={{ color: "black" }}
                >
                  Convidado
                </h4>
                <a style={{ color: "black" }}>
                  {generalData ? getNeedRegister("convidado") : "-"}
                </a>
              </div>
            </Col>
          </Row>

          <Row style={{ margin: "20px 0" }}>
            <Col md={12}>
              <ColumnChart dataByDay={dataByDay} />
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <LineChart dataByDay={dataByDay} dataEvolution={dataEvolution} />
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
}
