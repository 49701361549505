import writeXlsxFile from "write-excel-file";
import { getStockActivities } from "../../../utils/services/schedulingAndStock";

export async function createStockXlsx({
  eventId,
  token,
  limit,
  countData,
  search,
  columnSearch,
  setFinishExport,
}) {
  if (!countData || !limit) {
    return;
  }

  const schema = [
    {
      column: "ID do Estoque",
      type: String,
      value: (stock) => stock.id,
    },
    {
      column: "ID do Evento",
      type: String,
      value: (stock) => stock.eventId,
    },
    {
      column: "Sala",
      type: String,
      value: (stock) => stock.sala,
    },
    {
      column: "Atividade",
      type: String,
      value: (stock) => stock.atividade,
    },
    {
      column: "Data e Hora Início",
      type: String,
      value: (stock) => {
        const date = new Date(stock.dataHoraInicio);

        date.setHours(date.getHours() + 3);

        return date.toLocaleString("pt-BR", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hour12: false,
        });
      },
    },
    {
      column: "Data e Hora Fim",
      type: String,
      value: (stock) => {
        const date = new Date(stock.dataHoraFim);

        date.setHours(date.getHours() + 3);

        return date.toLocaleString("pt-BR", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hour12: false,
        });
      },
    },
    {
      column: "Quantidade Máxima",
      type: Number,
      value: (stock) => stock.quantidadeMax,
    },
    {
      column: "Quantidade Utilizada",
      type: Number,
      value: (stock) => stock.quantidadeUtilizada,
    },
    {
      column: "Data e Hora da Criação",
      type: String,
      value: (stock) => {
        const date = new Date(stock.createdAt);

        date.setHours(date.getHours() + 3);

        return date.toLocaleString("pt-BR", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hour12: false,
        });
      },
    },
    {
      column: "Data e Hora Atualização",
      type: String,
      value: (stock) => {
        const date = new Date(stock.updatedAt);

        date.setHours(date.getHours() + 3);

        return date.toLocaleString("pt-BR", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hour12: false,
        });
      },
    },
  ];

  const numberOfRequisitions = Math.ceil(countData / limit);
  console.log("Número de requisições calculado:", numberOfRequisitions);
  console.log({
    eventId,
    token,
    limit,
    countData,
    search,
    columnSearch,
    setFinishExport,
  });

  const allRequestsPromises = [];

  for (let i = 1; i <= numberOfRequisitions; i += 1) {
    console.log("DENTRO DA FUNÇÃO: ", {
      eventId,
      token,
      limit,
      countData,
      search,
      columnSearch,
      i,
    });

    try {
      const request = await getStockActivities(
        eventId,
        token,
        i,
        limit,
        search,
        "ATIVO",
        columnSearch
      );

      if (request && request.data && request.data) {
        allRequestsPromises.push(request.data);
      } else {
        console.warn(`Requisição ${i} não retornou dados válidos`);
      }
    } catch (error) {
      console.error(`Erro ao fazer a requisição ${i}:`, error);
    }

    await new Promise((resolve) => setTimeout(resolve, 500));

    const progressBar = document.querySelector(".export-progress");
    const percentageBar = document.querySelector(".export-percentage");

    if (progressBar && percentageBar) {
      progressBar.style.width = `${(i / numberOfRequisitions) * 100}%`;
      percentageBar.innerHTML = `${((i / numberOfRequisitions) * 100).toFixed(
        0
      )}%`;
    }

    if (i === numberOfRequisitions) {
      setTimeout(() => {
        setFinishExport(true);
      }, 1000);
    }
  }

  const allDataInSingleArray = allRequestsPromises.flat();

  if (allDataInSingleArray.length > 0) {
    await writeXlsxFile(allDataInSingleArray, {
      schema,
      fileName: "relatorio-estoque.xlsx",
    });
  } else {
    console.warn("Nenhum dado encontrado para escrever no Excel.");
  }
}
