import React, { useEffect, useState } from 'react';

import { Input, Label, UncontrolledTooltip } from 'reactstrap';

import { inputMask } from '../../../../utils/helpers/masks/masks';
import { validation } from '../../../../utils/helpers/masks/validations';

export default function CPFDisplay({ attributes, rowId, colId, setValue }) {
  // Mask
  const [maskedValue, setMaskedValue] = useState('');

  const handleChange = (value) => {
    inputMask({ mask: 'cpf', value, setMaskedValue });
  };

  useEffect(() => {
    const html = attributes.label;
    const div = document.getElementsByClassName(`cpf-label-${rowId}-${colId}`);
  
    for (const element of div) {
      element.innerHTML = html;
    }
  }, [attributes, rowId, colId])

  useEffect(() => {
    const {isValid, message} = validation(maskedValue, 'cpf');

    setValue((oldValue) => {
      const newValue = [...oldValue];
      newValue[rowId].cols[colId].attributes.value = maskedValue;
      if (!isValid) {
        newValue[rowId].cols[colId].attributes.errorMessage = message;
      } else {
        newValue[rowId].cols[colId].attributes.errorMessage = '';
      }
      return newValue;
    });
  }, [maskedValue, setValue, rowId, colId]);

  return (
    <div>
      <div className="custom-input-container">
        <div>
          <div className="displayed-config-container">
            <div className="displayed-label">
              <Label htmlFor="formText" className={`form-label cpf-label-${rowId}-${colId}`} />
              {attributes.hasTip && (
                <div>
                  <div className="show-tips" id={`cpf-tip-${rowId}-${colId}`}>
                    <span>?</span>
                  </div>
                  <UncontrolledTooltip style={{ backgroundColor: '#6f42c1' }} placement="right" target={`cpf-tip-${rowId}-${colId}`}>
                    {attributes.tipText}
                  </UncontrolledTooltip>
                </div>
              )}
            </div>
          </div>
          <Input
            className="form-control"
            type="text"
            id="formText"
            placeholder={attributes.placeholder}
            value={maskedValue}
            onChange={({ target }) => handleChange(target.value)}
          />
        </div>
      </div>
    </div>
  )
}
