import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody, Row, Col, Button } from "reactstrap";

export default function ModalInfoLogistica(props) {
  const { isOpen, toggle, eventId, participantId } = props;

  return (
    <>
      <Modal isOpen={isOpen} centered autoFocus>
        <ModalHeader toggle={toggle}>Logística</ModalHeader>
        <ModalBody>
          <Row>
            <Col md={12}>
              <iframe
                src={`https://logistica-plus.sistemasinteegra.com.br/?userId=${participantId}&eventId=${eventId}`}
                title="Logística"
                style={{
                  width: "100%",
                  height: "400px",
                  border: "none",
                }}
              ></iframe>
            </Col>
          </Row>

          <Row
            style={{
              width: "100%",
              margin: "auto",
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "1rem",
            }}
          >
            <Button
              color="danger"
              style={{ width: 80, marginRight: 10 }}
              onClick={() => toggle()}
            >
              Fechar
            </Button>
          </Row>
        </ModalBody>
      </Modal>
    </>
  );
}
