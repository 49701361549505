import writeXlsxFile from 'write-excel-file';
import { getDownloads } from '../../../../../utils/services/reports';


export async function createXlsx({idEvent, countData, setFinishExport}) {
  try {
    const schema = [
      {
        column: 'Nome do Arquivo',
        type: String,
        value: download => download.filename
      },
      {
        column: 'Quantidade de Downloads',
        type: String,
        value: download => download.total
      },
    ];
  
    const limit = 50;
  
    const numberOfRequisitions = Math.ceil(countData / limit);
    const allRequests = [];
  
    for (let i = 1; i <= numberOfRequisitions; i += 1) {
      const response = await getDownloads({
        idEvent,
        offset: i,
        limit,
      })
  
      allRequests.push(response.data.files);
      setTimeout(() => {}, 500);

      const progressBar = document.querySelector('.export-progress');
      const percentageBar = document.querySelector('.export-percentage')
  
      progressBar.style.width = `${(i / numberOfRequisitions) * 100}%`;
      percentageBar.innerHTML = `${((i / numberOfRequisitions) * 100).toFixed(0)}%`;
  
      if (i === numberOfRequisitions) {
        setTimeout(() => {
          setFinishExport(true);
        }, 1000);
      }
    }
  
    const downloads = allRequests.flat();
  
    await writeXlsxFile(downloads, {
      schema,
      fileName: 'relatorio-arquivos.xlsx'
    })
  } catch (error) {
    // console.log(error);
  }
}