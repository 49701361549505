import React from "react";
import { Button, Spinner } from "reactstrap";
import { useUserPermissions } from "../../utils/hooks/useUserPermissions";

export default function ButtonSpinner(props) {
  const { canCreate, canUpdate } = useUserPermissions();

  const {
    isLoading,
    title,
    onClick,
    isDisabled = false,
    size = 80,
    action = "C",
  } = props;
  const checkCanActions = () => {
    if (canCreate() && action === "C") return true;
    if (canUpdate() && action === "U") return true;
    return false;
  };
  return (
    <>
      {checkCanActions() && (
        <Button
          color={isLoading ? "secondary" : "success"}
          className="waves-effect waves-light"
          style={{ width: isLoading ? "auto" : size }}
          disabled={isLoading || isDisabled}
          onClick={onClick}
        >
          {isLoading ? <Spinner size="sm" /> : null}
          <span> {`  ${title}`}</span>
        </Button>
      )}
    </>
  );
}
