import axios from "axios";
import { urlBase } from "./config";
import axiosInstance from "../services/config/axiosinstance";

async function loginForm({ email, senha, recaptchaToken, tokenMFA = "" }) {
  var data = JSON.stringify({
    email: email,
    senha: senha,
    recaptchaToken: recaptchaToken,
    tokenMFA: tokenMFA,
  });

  var config = {
    method: "post",
    url: `${urlBase}/signin`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response.data;
    });
}

async function loginSso({ email }) {
  var data = JSON.stringify({
    email: email,
  });

  var config = {
    method: "post",
    url: `${urlBase}/loginsso`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response.data;
    });
}

function firstAccessEmail({ email }) {
  var data = JSON.stringify({
    email: email,
  });

  var config = {
    method: "post",
    url: `${urlBase}/admin/tokenaccess`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response.data;
    });
}

function firstAccessConfirmation({ email, password, confirmPassword, token }) {
  var data = JSON.stringify({
    email: email,
    password: password,
    confirmPassord: confirmPassword,
    token: token,
  });

  var config = {
    method: "post",
    url: `${urlBase}/admin/firstaccess`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response.data;
    });
}

function recoveryPasswordEmail({ email }) {
  var data = JSON.stringify({
    email: email,
  });

  var config = {
    method: "post",
    url: `${urlBase}/admin/tokenrecoverypassword`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response.data;
    });
}

function getMe() {
  var config = {
    method: "get",
    url: `${urlBase}/admin/me`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  return axiosInstance(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response.data;
    });
}

function refreshLogin({ email }) {
  var data = JSON.stringify({
    email: email,
  });

  var config = {
    method: "post",
    url: `${urlBase}/admin/refresh-login`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  return axiosInstance(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response.data;
    });
}

function recoveryPasswordConfirm({ email, password, confirmPassword, token }) {
  var data = JSON.stringify({
    email: email,
    password: password,
    confirmPassord: confirmPassword,
    token: token,
  });

  var config = {
    method: "post",
    url: `${urlBase}/admin/recoverypassword`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response.data;
    });
}

export {
  getMe,
  loginForm,
  loginSso,
  firstAccessEmail,
  firstAccessConfirmation,
  recoveryPasswordEmail,
  recoveryPasswordConfirm,
  refreshLogin,
};
