import React, { useState } from 'react';

import { Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalHeader, Row, UncontrolledTooltip } from 'reactstrap';

import { inputMask } from '../../../utils/helpers/masks/masks';

export default function TextInput({ deleteComponent, rowId, colId, setAttributes, attributes }) {
  const [inputName, setInputName] = useState(attributes ? attributes.inputName : '');
  const [label, setLabel] = useState(attributes ? attributes.label : 'Input de texto');
  const [placeholder, setPlaceholder] = useState(attributes ? attributes.placeholder : 'Placeholder');
  const [tipText, setTipText] = useState(attributes ? attributes.tipText : 'Texto de dica');
  const [hasTip, setHasTip] = useState(attributes ? attributes.hasTip : false);
  const [required, setRequired] = useState(attributes ? attributes.required : false);

  // Mask
  const [hasMask, setHasMask] = useState(attributes ? attributes.hasMask : false);
  const [mask, setMask] = useState(attributes ? attributes.mask : '');
  const [maskedValue, setMaskedValue] = useState('');

  // validation
  const [maxValue, setMaxValue] = useState(attributes ? attributes.maxValue : 0);
  const [minValue, setMinValue] = useState(attributes ? attributes.minValue : 0);
  const [hasValidation, setHasValidation] = useState(attributes ? attributes.hasValidation : false);
  const [isNumeric, setIsNumeric] = useState(attributes ? attributes.isNumeric : false);

  // Edit Modal
  const [editModal, setEditModal] = useState(false);

  // error message
  const [errorMessage, setErrorMessage] = useState(false);
  const [nameError, setNameError] = useState(false);

  const validate = () => {
    if (inputName === '') {
      setErrorMessage('O campo "Nome do campo" é obrigatório');
      return false;
    }

    if (hasMask && mask === '') {
      setErrorMessage('Selecione uma "Máscara"');
      return false;
    }

    if (hasValidation) {
      if (+minValue > +maxValue) {
        setErrorMessage('O valor mínimo deve ser maior ou igual o valor máximo');
        return false;
      }
  
      if (+minValue < 1) {
        setErrorMessage('O valor mínimo deve ser maior que 0');
        return false;
      }
    }

    return true;
  };

  const handleChange = (value) => {
    inputMask({ mask, value, setMaskedValue });
  };

  const submitForm = (e) => {
    e.preventDefault();
    if (!validate()) {
      return;
    }

    if (inputName === '') {
      setNameError(true);
      return;
    }

    setNameError(false);

    setErrorMessage(false);

    setAttributes({rowId, colId, attributes: {
      inputName,
      label,
      placeholder,
      tipText,
      hasTip,
      required,
      hasMask,
      mask,
      hasValidation,
      maxValue,
      minValue,
      isNumeric,
    }});
    setEditModal(false);
  };

  return (
    <div>
      <div className="custom-input-container">
        <Modal
          size="lg"
          isOpen={editModal}
          toggle={() => setEditModal(!editModal)}
        >
          <ModalHeader toggle={() => setEditModal(false)}>
            Configurar Input de Texto
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col>
                <Form>
                  <FormGroup className="input-margin-btm">
                    <Label htmlFor="edit-input-name" className="form-label">
                      Nome do campo
                    </Label>
                    <Input
                      id="edit-input-name"
                      className="form-control"
                      type="text"
                      placeholder="Nome do campo"
                      value={inputName}
                      onChange={({ target }) => setInputName(target.value)}
                    />
                    {nameError && <p style={{color: 'red'}}>O campo Nome do Campo é obrigatório!</p>}
                  </FormGroup>
                  <FormGroup className="input-margin-btm">
                    <Label htmlFor="edit-input-text" className="form-label">
                      Texto da Label
                    </Label>
                    <Input
                      id="edit-input-text"
                      className="form-control"
                      type="text"
                      value={label}
                      onChange={({ target }) => setLabel(target.value)}
                    />
                  </FormGroup>
                  <FormGroup className="input-margin-btm">
                    <Label htmlFor="edit-input-placeholder" className="form-label">
                      Texto do placeholder
                    </Label>
                    <Input
                      id="edit-input-placeholder"
                      className="form-control"
                      type="text"
                      value={placeholder}
                      onChange={({ target }) => setPlaceholder(target.value)}
                    />
                  </FormGroup>
                  <FormGroup className="input-margin-btm">
                    <Input
                      className="input-mg-rgt"
                      id="edit-input-has-tip"
                      type="checkbox"
                      checked={hasTip}
                      onChange={({ target }) => setHasTip(target.checked)}
                    />
                    <Label htmlFor="edit-input-has-tip" className="form-label">
                      Possui dica?
                    </Label>
                    {hasTip && (
                      <FormGroup>
                        <Label htmlFor="edit-input-tip" className="form-label">
                          Texto da dica
                        </Label>
                        <Input
                          id="edit-input-tip"
                          className="form-control"
                          type="text"
                          value={tipText}
                          onChange={({ target }) => setTipText(target.value)}
                        />
                      </FormGroup>
                    )}
                  </FormGroup>
                  <FormGroup className="input-margin-btm">
                    <Input
                      className="input-mg-rgt"
                      id="edit-input-required"
                      type="checkbox"
                      checked={required}
                      onChange={({ target }) => setRequired(target.checked)}
                    />
                    <Label htmlFor="edit-input-required" className="form-label">
                      Campo obrigatório?
                    </Label>
                  </FormGroup>
                  <FormGroup className="input-margin-btm">
                    <div style={{display: 'flex'}}>
                      <Input
                        className="input-mg-rgt"
                        id="edit-input-mask"
                        type="checkbox"
                        checked={hasMask}
                        disabled={hasValidation}
                        onChange={({ target }) => setHasMask(target.checked)}
                      />
                      <div style={{display: 'flex'}}>
                        <Label htmlFor="edit-input-mask" className="form-label">
                          Possui máscara?
                        </Label>
                        <div>
                          <div className="show-tips" id="mask-tip">
                            <span>?</span>
                          </div>
                          <UncontrolledTooltip style={{ backgroundColor: '#6f42c1' }} placement="right" target="mask-tip">
                            As máscaras já possuem validação
                          </UncontrolledTooltip>
                        </div>
                      </div>
                    </div>
                  </FormGroup>
                  {hasMask && (
                    <div>
                      <Row>
                        <Col>
                          <Input
                            className="input-mg-rgt"
                            id="mask-phone"
                            type="radio"
                            name="mask"
                            checked={mask === 'phone'}
                            value="phone"
                            onChange={({ target }) => setMask(target.value) > setMaskedValue('')}
                          />
                          <Label htmlFor="mask-phone" className="form-label">
                            Telefone Residencial
                          </Label>
                        </Col>
                        <Col>
                          <Input
                            className="input-mg-rgt"
                            id="mask-cellphone"
                            type="radio"
                            checked={mask === 'cellphone'}
                            name="mask"
                            value="cellphone"
                            onChange={({ target }) => setMask(target.value) > setMaskedValue('')}
                          />
                          <Label htmlFor="mask-cellphone" className="form-label">
                            Celular
                          </Label>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Input
                            className="input-mg-rgt"
                            id="mask-date"
                            type="radio"
                            checked={mask === 'date'}
                            name="mask"
                            value="date"
                            onChange={({ target }) => setMask(target.value) > setMaskedValue('')}
                          />
                          <Label htmlFor="mask-date" className="form-label">
                            Data
                          </Label>
                        </Col>
                        <Col>
                          <Input
                            className="input-mg-rgt"
                            id="mask-time"
                            type="radio"
                            checked={mask === 'time'}
                            name="mask"
                            value="time"
                            onChange={({ target }) => setMask(target.value) > setMaskedValue('')}
                          />
                          <Label htmlFor="mask-time" className="form-label">
                            Hora
                          </Label>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Input
                            className="input-mg-rgt"
                            id="mask-cpf"
                            type="radio"
                            checked={mask === 'cpf'}
                            name="mask"
                            value="cpf"
                            onChange={({ target }) => setMask(target.value) > setMaskedValue('')}
                          />
                          <Label htmlFor="mask-cpf" className="form-label">
                            CPF
                          </Label>
                        </Col>
                        <Col>
                          <Input
                            className="input-mg-rgt"
                            id="mask-cnpj"
                            type="radio"
                            checked={mask === 'cnpj'}
                            name="mask"
                            value="cnpj"
                            onChange={({ target }) => setMask(target.value) > setMaskedValue('')}
                          />
                          <Label htmlFor="mask-cnpj" className="form-label">
                            CNPJ
                          </Label>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Input
                            className="input-mg-rgt"
                            id="mask-cep"
                            type="radio"
                            name="mask"
                            checked={mask === 'cep'}
                            value="cep"
                            onChange={({ target }) => setMask(target.value) > setMaskedValue('')}
                          />
                          <Label htmlFor="mask-cep" className="form-label">
                            CEP
                          </Label>
                        </Col>
                      </Row>
                    </div>)}
                    <FormGroup className="input-margin-btm">
                      <div style={{display: 'flex'}}>
                        <Input
                          className="input-mg-rgt"
                          id="edit-input-has-validation"
                          type="checkbox"
                          disabled={hasMask}
                          checked={hasValidation}
                          onChange={({ target }) => setHasValidation(target.checked)}
                        />
                        <Label htmlFor="edit-input-has-validation" className="form-label">
                          Possui validação?
                        </Label>
                        <div>
                          <div className="show-tips" id="validation-tip">
                            <span>?</span>
                          </div>
                          <UncontrolledTooltip style={{ backgroundColor: '#6f42c1' }} placement="right" target="validation-tip">
                            Não é possível adicionar validação caso uma máscara seja selecionada
                          </UncontrolledTooltip>
                        </div>
                      </div>
                    </FormGroup>
                    {hasValidation && (
                      <div>
                        <FormGroup>
                          <p>
                            Tipo de validação
                          </p>
                          <Input
                            className="input-mg-rgt"
                            id="edit-input-validation-text"
                            type="radio"
                            name="validation"
                            value="Text"
                            defaultChecked={true}
                            onChange={({ target }) => setIsNumeric(!target.checked)}
                          />
                          <Label htmlFor="edit-input-validation-text" className="form-label">
                            É um campo de texto?
                          </Label>
                        </FormGroup>
                        <FormGroup>
                          <Input
                            className="input-mg-rgt"
                            id="edit-input-validation-number"
                            type="radio"
                            name="validation"
                            value="Number"
                            onChange={({ target }) => setIsNumeric(target.checked)}
                          />
                          <Label htmlFor="edit-input-validation-number" className="form-label">
                            É um campo numérico?
                          </Label>
                        </FormGroup>
                        {!isNumeric ? (
                          <div style={{marginBottom: '10px'}}>
                            <FormGroup>
                              <Label htmlFor="edit-input-validation" className="form-label">
                                Quantidade miníma de caracteres
                              </Label>
                              <Input
                                id="edit-input-validation"
                                className="form-control"
                                type="number"
                                min={1}
                                value={minValue}
                                onChange={({ target }) => setMinValue(target.value)}
                              />
                            </FormGroup>
                            <FormGroup>
                              <Label htmlFor="edit-input-validation" className="form-label">
                                Quantidade máxima de caracteres
                              </Label>
                              <Input
                                id="edit-input-validation"
                                className="form-control"
                                type="number"
                                min={1}
                                value={maxValue}
                                onChange={({ target }) => setMaxValue(target.value)}
                              />
                            </FormGroup>
                          </div>) : (
                          <div style={{marginBottom: '10px'}}>
                            <FormGroup>
                              <Label htmlFor="edit-input-validation" className="form-label">
                                Valor mínimo
                              </Label>
                              <Input
                                id="edit-input-validation"
                                className="form-control"
                                type="number"
                                min={1}
                                value={minValue}
                                onChange={({ target }) => setMinValue(target.value)}
                              />
                            </FormGroup>
                            <FormGroup>
                              <Label htmlFor="edit-input-validation" className="form-label">
                                Valor máximo
                              </Label>
                              <Input
                                id="edit-input-validation"
                                className="form-control"
                                type="number"
                                min={1}
                                value={maxValue}
                                onChange={({ target }) => setMaxValue(target.value)}
                              />
                            </FormGroup>
                          </div>)}
                        </div>)}
                </Form>
              </Col>
              <Col style={{borderLeft: '1px solid gray'}}>
                <div style={{ display: 'flex' }}>
                  <Label className="form-label" htmlFor="formText">
                    {label === "Input de texto" ? label : <div dangerouslySetInnerHTML={{__html: label}} />}
                  </Label>
                  {hasTip && (
                    <div>
                      <div className="show-tips" id={`text-${rowId}-${colId}`}>
                        <span>?</span>
                      </div>
                      <UncontrolledTooltip style={{ backgroundColor: '#6f42c1' }} placement="right" target={`text-${rowId}-${colId}`}>
                        {tipText}
                      </UncontrolledTooltip>
                    </div>
                  )}
                </div>
                <Input
                  className="form-control"
                  type="text"
                  id="formText"
                  placeholder={placeholder}
                  value={maskedValue}
                  onChange={({ target }) => handleChange(target.value)}
                />
              </Col>
            </Row>
            {errorMessage && (
              <p style={{color: 'red'}}>{errorMessage}</p>
            )}
            <div className="save-btn-form-container">
              <Button
                color="success"
                className="btn btn-success waves-effect waves-light save-btn-form"
                onClick={submitForm}
              >
                Salvar
              </Button>
            </div>
          </ModalBody>
        </Modal>
        <div>
          <div className="displayed-config-container">
            <div className="displayed-label">
              <Label className="form-label" htmlFor="formText">
                {label === "Input de texto" ? label : <div dangerouslySetInnerHTML={{__html: label}} />}
              </Label>
              {hasTip && (
                <div>
                  <div className="show-tips" id={`text-${rowId}-${colId}`}>
                    <span>?</span>
                  </div>
                  <UncontrolledTooltip style={{ backgroundColor: '#6f42c1' }} placement="right" target={`text-${rowId}-${colId}`}>
                    {tipText}
                  </UncontrolledTooltip>
                </div>
              )}
            </div>
            <div className="config-form-icon">
              <span
                className="mdi mdi-cog-outline"
                onClick={() => setEditModal(!editModal)}
              />
              <span
                className="mdi mdi-close close-icon"
                title="Remover Campo"
                onClick={() => deleteComponent(rowId, colId)}
              />
            </div>
          </div>
          <Input
            className="form-control"
            type="text"
            id="formText"
            placeholder={placeholder}
            value={maskedValue}
            onChange={({ target }) => handleChange(target.value)}
          />
        </div>
      </div>
    </div>
  )
}
