import React from 'react';
import { Table } from 'reactstrap';

const brazilianStates = [
  {
    uf: 'AC',
    quantity: 1,
  },
  {
    uf: 'AL',
    quantity: 1,
  },
  {
    uf: 'AM',
    quantity: 1,
  },
  {
    uf: 'AP',
    quantity: 1,
  }
]

export default function StatesDetails(props) {
  return (
    <div>
      <Table className="table mb-0">
        <thead className="table-light">
          <tr>
            <th>Estado UF</th>
            <th>Quantidade de ingressos</th>
          </tr>
        </thead>
        <tbody>
          {brazilianStates.map((state) => (
            <tr key={state}>
              <td>{state.uf}</td>
              <td>{state.quantity}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  )
}
