import React, { useState } from "react";
import { QRCodeCanvas, QRCodeSVG } from "qrcode.react";
import { Modal, ModalBody, ModalHeader, Row } from "reactstrap";

export default function ModalQRCode(props) {
  const { isOpen, toggle } = props;
  const [modalBackdrop, setModalBackdrop] = useState(false);

  function toggleBackdrop() {
    setModalBackdrop(!modalBackdrop);
  }

  const downloadQR = () => {
    const canvas = document.getElementById("qrCode-download");
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = "qrCode-download.png";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  //copy func id
  async function clipBoard(id) {
    await navigator.clipboard.writeText(id);
    alert("O id foi copiado para a área de transferência");
  }
  return (
    <Modal
      size="md"
      isOpen={isOpen}
      toggle={() => toggleBackdrop()}
      autoFocus={true}
      centered={true}
    >
      <ModalHeader toggle={toggle}>QRCode</ModalHeader>
      <ModalBody>
        <Row>
          <QRCodeSVG value={props.editFunction.id} />,
        </Row>
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <span style={{ textAlign: "center" }}>
            ID da função: {props.editFunction.id}
          </span>

          <i
            className="mdi mdi-content-copy"
            style={{
              color: "grey",
              fontSize: 17,
              cursor: "pointer",
            }}
            title="Copiar"
            onClick={() => clipBoard(props.editFunction.id)}
          />
          <i
            className="mdi mdi-download"
            style={{
              color: "grey",
              fontSize: 17,
              cursor: "pointer",
            }}
            title="Baixar"
            onClick={downloadQR}
          />
        </div>
        <div></div>
        <QRCodeCanvas
          style={{ display: "none" }}
          id="qrCode-download"
          value={props.editFunction.id}
        />
      </ModalBody>
    </Modal>
  );
}
