import React from 'react';
import ReactEcharts from 'echarts-for-react';

export default function SalesPieChart({
  data = {},
  title,
}) {
  const sales = !data.totalwithaccess > 0
  ? [
    {x: "Cartão de Crédito", y: 5000.7, percent: '30%'},
    {x: "PIX", y: 12569.3, percent: '70%'}
  ]
  : [];

  const getOptions = () => ({
    title: {
      text: title,
      x: 'left',
      textStyle: {
        color: 'black'
      },
    },
    tooltip: {
      trigger: 'item',
      formatter: '{b}: R$ {c} ({d}%)'
    },
    legend: {
      orient: 'vertical',
      left: '1.5%',
      top: '10%',
      padding: [10, 0, 0, 0],
      itemGap: 5,

      data: sales.map(item => item.x),
      formatter: function (name) {
        const item = sales.find(item => item.x === name);
        return `${name} R$ ${item.y} (${item.percent})`;
      }
    },
    series: [
      {
        type: 'pie',
        radius: '65%',
        center: ['50%', '60%'],
        data: sales.map(item => ({ name: item.x, value: item.y })),
        label: {
          show: false,
        },
        itemStyle: {
          emphasis: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
          }
        }
      }
    ]
  });
  return (
    <ReactEcharts option={getOptions()} style={{ height: '40vh' }} />
  );
}