import writeXlsxFile from 'write-excel-file';
import { getFeed } from '../../../utils/services/feed';

export async function createXlsx({ jwt, idEvent, limit, search, countData, setFinishExport }) {
  // const formatDate = (date) => {
  //   try {
  //     const formatedDate = date.split("T")[0].split("-").reverse().join("/");
  //     const formatTime = date.split("T")[1].split(".")[0].split(':').slice(0, 2).join(':');

  //     return `${formatedDate} - ${formatTime}`;
  //   } catch (error) {
  //     return 'sem data';
  //   }
  // };
  function formatData(startDate) {
    const data = new Date(startDate);
    const dia = String(data.getDate()).padStart(2, "0");
    const mes = String(data.getMonth() + 1).padStart(2, "0");
    const ano = data.getFullYear();
    const hour = String(data.getHours()).padStart(2, "0");
    const min = String(data.getMinutes()).padStart(2, "0");
    const dataAtual = dia + "/" + mes + "/" + ano + " - " + hour + ":" + min;

    return dataAtual;
  }

  const schema = [
    {
      column: 'Usuário',
      type: String,
      value: feed => feed.loginid ? feed.loginid.nome : `${feed.loginadmin_id.nome} (Admin)`,
    },
    {
      column: 'Texto',
      type: String,
      value: feed => feed.text ? feed.text : '',
    },
    {
      column: 'Foto',
      type: String,
      value: feed => feed.url ? feed.url : '',
    },
    {
      column: 'Visível',
      type: String,
      value: feed => feed.active ? 'Sim' : 'Não',
    },
    {
      column: 'Fixado',
      type: String,
      value: feed => feed.fixed ? 'Sim' : 'Não',
    },
    {
      column: 'Criado Em',
      type: String,
      value: feed => formatData(feed.date),
    },
  ];

  const allRequestsPromises = [];
  const numberOfRequisitions = Math.ceil(countData / limit);

  for (let i = 1; i <= numberOfRequisitions; i += 1) {
    const request = await getFeed({
        jwt,
        idEvent,
        offset: i,
        limit,
        search,
    });
    allRequestsPromises.push(request);
    setTimeout(() => {}, 500);

    const progressBar = document.querySelector('.export-progress');
    const percentageBar = document.querySelector('.export-percentage')

    progressBar.style.width = `${(i / numberOfRequisitions) * 100}%`;
    percentageBar.innerHTML = `${((i / numberOfRequisitions) * 100).toFixed(0)}%`;

    if (i === numberOfRequisitions) {
      setTimeout(() => {
        setFinishExport(true);
      }, 1000);
    }
  }
  const allDataInSingleArray = allRequestsPromises.map((e) => e.findPostsData.data).flat();

  if (allDataInSingleArray) {
    await writeXlsxFile(allDataInSingleArray, {
      schema,
      fileName: 'feed.xlsx'
    })
  }
}