import { urlBaseImportLoginGroups } from "./config";
import axiosInstance from "../services/config/axiosinstance";

function createLoginGroup({ email, groups, eventId }, jwt) {
  var data = JSON.stringify({ email, groups, eventId });

  var config = {
    method: "post",
    url: `${urlBaseImportLoginGroups}/groups`,
    headers: {
      "Content-Type": "application/json",
    },
    data,
  };

  return axiosInstance(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

export { createLoginGroup };
