import { urlBase } from "./config";
import axiosInstance from "../services/config/axiosinstance";

function getMenus(jwt, idEvent) {
  var data = JSON.stringify({
    id: idEvent,
  });

  var config = {
    method: "post",
    url: `${urlBase}/admin/menuevent`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  return axiosInstance(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response.data;
    });
}

function newMenu(data, jwt) {
  var config = {
    method: "post",
    url: `${urlBase}/buttons/create`,
    headers: {
      "content-type": "application/json",
    },
    data: data,
  };

  return axiosInstance(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response.data;
    });
}

function updateMenu(data, jwt) {
  var config = {
    method: "post",
    url: `${urlBase}/buttons/update`,
    headers: {
      "content-type": "application/json",
    },
    data: data,
  };

  return axiosInstance(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response.data;
    });
}

async function orderButtonMenu(data, jwt) {
  var config = {
    method: "post",
    url: `${urlBase}/buttons/order`,
    headers: {
      "content-type": "application/json",
    },
    data: data,
  };

  try {
    const response = await axiosInstance(config);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}
async function deleteButtonMenu(id, jwt) {
  var config = {
    method: "delete",
    url: `${urlBase}/buttons/${id}`,
    headers: {
      "content-type": "application/json",
    },
  };

  try {
    const response = await axiosInstance(config);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export { getMenus, newMenu, updateMenu, orderButtonMenu, deleteButtonMenu };
