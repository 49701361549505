import React, { useState, useEffect } from "react";
import SweetAlert from "react-bootstrap-sweetalert";

export default function ModalRowsDuplicity({ toggle, rows }) {
  const [rowsDuplicity, setRowsDuplicity] = useState("");

  useEffect(() => {
    if (rows) {
      const withError = rows
        .sort(function (a, b) {
          return a.index - b.index;
        })
        .map((e) => {
          return `Linha ${e.index + 2}`;
        })
        .join(", ");
      setRowsDuplicity(withError);
    }
  }, [rows]);
  return (
    <SweetAlert
      title="Corrija os erros abaixo e reenvie a planilha"
      warning
      confirmButtonText="Ok!"
      confirmBtnBsStyle="success"
      onConfirm={() => {
        toggle();
      }}
      style={{ width: "39.1%" }}
    >
      <div style={{ marginTop: 15 }}>
        <p>As seguintes linhas estão duplicadas: </p>

        {rowsDuplicity}
      </div>

      {/* participant.groups
          .map((e) => {
            return e.gruponame;
          })
          .join(", "), */}
    </SweetAlert>
  );
}
