import { urlBaseRanking } from "./config";
const urlBase = urlBaseRanking;
import axiosInstance from "../services/config/axiosinstance";

function getConfigurations({
  id,
  jwt,
  offset = 1,
  limit = 10,
  search = "",
  columnSearch = "",
}) {
  var config = {
    method: "get",
    url: `${urlBase}/events/${id}/configurations/?offset=${offset}&limit=${limit}&search=${search}&columnSearch=${columnSearch}`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  return axiosInstance(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

function createConfiguration(data) {
  var dataForm = JSON.stringify(data);

  var config = {
    method: "post",
    url: `${urlBase}/events/${data.idEvent}/configurations`,
    headers: {
      "Content-Type": "application/json",
    },
    data: dataForm,
  };
  return axiosInstance(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

function editConfiguration(data) {
  var dataForm = JSON.stringify(data);

  var config = {
    method: "put",
    url: `${urlBase}/configurations/${data.id}`,
    headers: {
      "Content-Type": "application/json",
    },
    data: dataForm,
  };
  return axiosInstance(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

function deleteConfiguration(id, jwt) {
  var config = {
    method: "delete",
    url: `${urlBase}/configurations/${id}`,
    headers: {
      "Content-Type": "application/json",
    },
  };
  return axiosInstance(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

function getAllRanking({ id, jwt, offset, limit, search, columnSearch = "" }) {
  var config = {
    method: "get",
    url: `${urlBase}/events/${id}/ranking/?offset=${offset}&limit=${limit}&search=${search}&columnSearch=${columnSearch}`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  return axiosInstance(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

async function getEarnedPoints({ loginId, eventId, jwt, offset, limit }) {
  var config = {
    method: "get",
    url: `${urlBaseRanking}/events/${eventId}/logins/${loginId}/earnedpoints?offset=${offset}&limit=${limit}`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  return axiosInstance(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

// pontuar
async function createEarnedPoints(dataRanking, event, login) {
  const data = JSON.stringify({ ...dataRanking });
  var config = {
    method: "post",
    url: `${urlBaseRanking}/events/${event}/logins/${login}/earnedpointcreate`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  return axiosInstance(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response.data;
    });
}

// zerar ranking do evento
async function deleteRankingEvent(eventId) {
  var config = {
    method: "delete",
    url: `${urlBaseRanking}/events/${eventId}/ranking`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  return axiosInstance(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response.data;
    });
}

// apagar pontos de um usuário
async function deleteUserPoints(eventId, userId) {
  var config = {
    method: "delete",
    url: `${urlBaseRanking}/events/${eventId}/login/${userId}/ranking`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  return axiosInstance(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response.data;
    });
}

export {
  getConfigurations,
  createConfiguration,
  editConfiguration,
  deleteConfiguration,
  getAllRanking,
  getEarnedPoints,
  createEarnedPoints,
  deleteRankingEvent,
  deleteUserPoints,
};
