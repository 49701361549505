// service
import { getParticipants } from "../../../utils/services/participants";
import { getConfigurations } from "../../../utils/services/ranking";

export const excelColumnsPtBr = {
  CodigoRanking: "code",
  IdParticipante: "idUser",
};

export function allExcelColumns(row, index) {
  const formatedRow = {
    index,
    code: row.code ? row.code : undefined,
    idUser: row.idUser ? row.idUser : undefined,
  };

  return formatedRow;
}

export function findRowsWithError(validRows) {
  const rowsWithError = validRows.filter(
    (row) => row.code === undefined || row.idUser === undefined
  );

  return rowsWithError;
}

export async function getUnregisteredUser(token, idEvent, validateRows) {
  // validar participante
  var allUsers = [];

  const data = {
    jwt: token,
    idEvent: idEvent,
    offset: 1,
    limit: 99999999,
    search: "",
    columnSearch: "email",
    status: "ATIVO",
  };

  await getParticipants(data)
    .then((res) => {
      if (res.message === "users" && res.data.count > 0) {
        allUsers = res.data.data.map((e) => {
          return e.id;
        });
      }
    })
    .catch((error) => {
      // console.log(error);
    });

  var userError = [];

  for (var i = 0; i < validateRows.length; i++) {
    const row = validateRows[i];

    var search = allUsers.find((e) => e === row.idUser);

    if (!search)
      userError.push({
        ...row,
        idUser: undefined,
      });
  }

  // validar codigo do ranking
  var allCodes = [];

  await getConfigurations({
    id: idEvent,
    jwt: token,
    offset: 1,
    limit: 99999999,
    search: "",
    columnSearch: "code",
  }).then((res) => {
    if (res.count && res.count > 0) {
      allCodes = res.data.map((e) => {
        return e.code;
      });
    }
  });

  var codesError = [];

  for (var i = 0; i < validateRows.length; i++) {
    const row = validateRows[i];

    var search = allCodes.find((e) => e === row.code);

    if (!search)
      codesError.push({
        ...row,
        code: undefined,
      });
  }

  // juntar os erros
  var allErrors = [...codesError, ...userError];
  var errorsFormated = [];

  for (var i = 0; i < allErrors.length; i++) {
    const error = allErrors[i];

    var searchRow = allErrors.filter((e) => e.index === error.index);

    // se a linha tiver apenas 1 erro
    if (searchRow.length === 1) {
      errorsFormated.push(searchRow[0]);
    }
    // se na linha tiver 2 erros
    else {
      var searchRowInErros = errorsFormated.find(
        (row) => row.index === error.index
      );

      if (!searchRowInErros) {
        errorsFormated.push({
          ...error,
          code: undefined,
          idUser: undefined,
        });
      }
    }
  }

  return errorsFormated;
}
