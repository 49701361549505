import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Col,
  Row,
  Button,
  Label,
} from "reactstrap";

import SweetAlert from "react-bootstrap-sweetalert";
import { updateUser } from "../../utils/services/users";
import { connect } from "react-redux";
import ButtonSpinner from "../../components/Buttons/ButtonSpinner";

function EditUser(props) {
  const { isOpen, toggle } = props;
  const [modalBackdrop, setModalBackdrop] = useState(false);
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");
  const [success_dlg, setsuccess_dlg] = useState(false);
  const [profile, setProfile] = useState(props.userEdit?.profile);
  const [profileError, setProfileError] = useState("");
  const [isLoading, setIsloading] = useState(false);

  function toggleBackdrop() {
    setModalBackdrop(!modalBackdrop);
  }
  useEffect(() => {
    setName(props?.userEdit?.nome);
    setProfile(props?.userEdit?.profile);
    if (!isOpen) {
      setName("");
      setProfile("");
      setNameError("");
      setProfileError("");
    }
  }, [isOpen]);

  async function submitForm() {
    if (name === "") {
      setNameError("O campo Nome é obrigatório");
      return;
    } else setNameError("");

    if (!profile) {
      setProfileError("O campo Perfil é obrigatório");
      return;
    } else setProfileError("");
    setIsloading(true);
    const data = {
      nome: name,
      email: props.userEdit.email,
      profile: profile,
      expireDate: null,
    };

    const userData = await updateUser(
      data,
      props.state.global_user_data.data.token
    );
    setIsloading(false);
    if (userData.message === "updated") {
      setsuccess_dlg(true);
      props.getEvent();
    }
  }
  return (
    <Modal
      isOpen={isOpen}
      toggle={() => {
        toggleBackdrop();
      }}
      autoFocus={true}
      centered={true}
    >
      {success_dlg ? (
        <SweetAlert
          success
          title={"Editado"}
          onConfirm={() => {
            setsuccess_dlg(false);
            toggle();
          }}
        >
          {"Usuário editado com sucesso!"}
        </SweetAlert>
      ) : null}
      <ModalHeader toggle={toggle}>Editar Usuário</ModalHeader>
      <ModalBody>
        <Row style={{ marginBottom: 10 }}>
          <Col lg={12}>
            <div>
              <label className="form-label"> ID do Usuário</label>
              <input
                className="form-control"
                type="text"
                defaultValue={props.userEdit.id}
                disabled
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg={12}>
            <div>
              <label className="form-label"> Nome do Usuário</label>
              <input
                className="form-control"
                type="text"
                defaultValue={props.userEdit.nome}
                onChange={(e) => setName(e.target.value)}
              />
              <p style={{ color: "red" }}>{nameError}</p>
            </div>
          </Col>
        </Row>

        <Row style={{ marginTop: 10 }}>
          <Col lg={12}>
            <div>
              <label className="form-label">Email</label>
              <input
                className="form-control"
                type="email"
                value={props.userEdit.email}
                disabled
              />
            </div>
          </Col>
        </Row>

        <Row style={{ marginTop: 10 }}>
          <Col lg={12}>
            <Label htmlFor="edit-input-delet-options" className="form-label">
              Selecione um Perfil de Usuário
            </Label>
            <select
              className="form-control"
              onChange={(e) => setProfile(e.target.value)}
              defaultValue={profile}
            >
              <option selected>Administrador</option>
              <option disabled className="select-disable">
                Editor
              </option>
              <option disabled className="select-disable">
                Leitor
              </option>
            </select>
            <p style={{ color: "red" }}>{profileError}</p>
          </Col>
        </Row>

        <Row
          style={{
            width: "100%",
            margin: "auto",
            display: "flex",
            justifyContent: "flex-end",
            marginTop: 10,
          }}
        >
          <Button
            color="success"
            className="btn btn-danger waves-effect waves-light"
            style={{ width: 80, marginRight: 5 }}
            onClick={() => toggle()}
          >
            Fechar
          </Button>
          {/* <Button
            color="success"
            className="btn btn-success waves-effect waves-light"
            style={{ width: 80 }}
            onClick={() => submitForm()}
          >
            Salvar
          </Button> */}
          <ButtonSpinner
            isLoading={isLoading}
            title={"Salvar"}
            onClick={submitForm}
          />
        </Row>
      </ModalBody>
    </Modal>
  );
}
const mapStatetoProps = (state) => {
  const { nome, foto, email } = state.global_user_data.data.foundLogin;
  return { nome, foto, state, email };
};

export default connect(mapStatetoProps)(EditUser);
