import React, { useState } from "react";
import { useSelector } from "react-redux";

import {
  Button,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";

import { createForm } from "../../../utils/services/rsvp";

export default function DuplicateForm({
  isOpen,
  toggle,
  selectedForm,
  updateList,
}) {
  const { token: jwt } = useSelector((state) => state.global_user_data.data);

  const [formName, setFormName] = useState("");
  const [error, setError] = useState("");

  const resetFields = () => {
    setFormName("");
    setError("");
  };

  const submitForm = async () => {
    if (formName === "") {
      setError("O campo nome do formulário deve ser preenchido");
      return;
    }

    const data = {
      json: selectedForm.json,
      eventId: selectedForm.eventId,
      description: formName,
    };

    await createForm({ jwt, data });

    await updateList();
  };

  const submitFormAndExit = async () => {
    if (formName === "") {
      setError("O campo nome do formulário deve ser preenchido");
      return;
    }

    const data = {
      json: selectedForm.json,
      eventId: selectedForm.eventId,
      description: formName,
    };

    await createForm({ jwt, data });

    await updateList();

    resetFields();
    toggle();
  };

  return (
    <Modal centered isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Duplicar Formulário</ModalHeader>
      <ModalBody>
        <Label>Nome do novo Formulário</Label>
        <Input
          type="text"
          value={formName}
          onChange={(e) => setFormName(e.target.value)}
        />
        {error !== "" && <p style={{ color: "red" }}>{error}</p>}
        <div className="save-btn-form-container">
          <Button
            color="danger"
            className="btn btn-danger waves-effect waves-light save-btn-form"
            style={{ width: 80, marginRight: 10 }}
            onClick={() => toggle()}
          >
            Fechar
          </Button>
          <Button
            color="success"
            className="btn btn-success waves-effect waves-light save-btn-form"
            onClick={submitForm}
          >
            Salvar
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
}
