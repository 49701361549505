import writeXlsxFile from 'write-excel-file';
import { getDownloadCertificate } from '../../../utils/services/certificate';

export async function createXlsx({ jwt, eventId, limit, search, totalItems, setFinishExport }) {
  const formatDate = (date, time) => {
    const formatedDate = date.split('T')[0].split('-').reverse().join('-');
    return `${formatedDate} - ${time}`;
  }

  const schema = [
    {
      column: 'ID do Participante',
      type: String,
      value: participant => participant.loginid,
    },
    {
      column: 'Nome do Participante',
      type: String,
      value: participant => participant.login
    },
    {
      column: 'Email do Participante',
      type: String,
      value: participant => participant.email
    },
    {
      column: 'Nome do Certificado',
      type: String,
      value: participant => participant.certificatename,
    },
    {
      column: 'Data e Hora do Download',
      type: String,
      value: participant => formatDate(participant.datedownload, participant.timedownload),
    },
  ];

  const allRequestsPromises = [];
  const numberOfRequisitions = Math.ceil(totalItems / limit);

  for (let i = 1; i <= numberOfRequisitions; i += 1) {
    const request = await getDownloadCertificate({
      jwt, eventId, offset: i, limit, search
    });
    allRequestsPromises.push(request);
    setTimeout(() => {}, 500);

    const progressBar = document.querySelector('.export-progress');
    const percentageBar = document.querySelector('.export-percentage')

    progressBar.style.width = `${(i / numberOfRequisitions) * 100}%`;
    percentageBar.innerHTML = `${((i / numberOfRequisitions) * 100).toFixed(0)}%`;

    if (i === numberOfRequisitions) {
      setTimeout(() => {
        setFinishExport(true);
      }, 1000);
    }
  }

  const allDataInSingleArray = allRequestsPromises.map((e) => e.data.data.certificates).flat();
  // console.log(allDataInSingleArray);

  if (allDataInSingleArray) {
    await writeXlsxFile(allDataInSingleArray, {
      schema,
      fileName: 'download-certificados.xlsx'
    })
  }
}