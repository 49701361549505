import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";

export default function ModalExcelRows({
  toggle,
  rows = [],
  columnNames = {},
}) {
  const invalidRows = (Array.isArray(rows) ? rows : []).map(
    (row, rowIndex) => ({
      row: rowIndex + 1,
      error: Object.entries(columnNames)
        .filter(([_key, value]) => row && row[value] === undefined)
        .map(([key]) => key),
    })
  );

  return (
    <SweetAlert
      title="Corrija os erros abaixo e reenvie a planilha"
      warning
      confirmButtonText="Ok!"
      confirmBtnBsStyle="success"
      onConfirm={toggle}
      style={{ width: "39.1%" }}
    >
      <div style={{ marginTop: 15 }}>
        {invalidRows.length !== 0 ? (
          invalidRows.map((row) => (
            <p key={row.row}>
              Linha {row.row}: Preencha os campos: {row.error.join(", ")}
            </p>
          ))
        ) : (
          <p>Utilize o Modelo correto para fazer a importação da planilha</p>
        )}
      </div>
    </SweetAlert>
  );
}
