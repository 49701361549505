export const instructionsPT = {
  title: "Instruções",
  chooserName: "✔️ ${chooserName} - Participante que receberá o e-mail.",
  listRoomates: "✔️ ${listRoomates()} - Lista de convidados para divisão de quartos.",
  chosenName: "✔️ ${chosenName} - Participante que solicitou a ação no convite.",
  btnAccept: "✔️ ${btnAccept} - Botão para o participante aceitar a solicitação.",
  btnRefuse: "✔️ ${btnRefuse} - Botão para o participante recusar a solicitação.",
  warn: ""
}

export const instructionsEN = {
  title: "Instruções",
  chooserName: "✔️ ${chooserName} - Participant who will receive the email.",
  listRoomates: "✔️ ${listRoomates()} - Guest list for room division.",
  chosenName: "✔️ ${chosenName} - Participant who requested the action in the invitation.",
  btnAccept: "✔️ ${btnAccept} - Button for the participant to accept the request.",
  btnRefuse: "✔️ ${btnRefuse} - Button for the participant to reject the request.",
  warn: ""
}

export const instructionsSP = {
  title: "Instruções",
  chooserName: "✔️ ${chooserName} - Participante que recibirá el correo electrónico.",
  listRoomates: "✔️ ${listRoomates()} - Lista de invitados para división de habitaciones.",
  chosenName: "✔️ ${chosenName} - Participante que solicitó la acción en la invitación.",
  btnAccept: "✔️ ${btnAccept} - Botón para que el participante acepte la solicitud.",
  btnRefuse: "✔️ ${btnRefuse} - Botón para que el participante rechace la solicitud.",
  warn: ""
}