export function inputMask({ mask, value, setMaskedValue }) {
  switch (mask) {
    case 'cpf':
      const cpf = value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,3})(\d{0,2})/);
      setMaskedValue(!cpf[2] ? cpf[1] : `${cpf[1]}.${cpf[2]}${cpf[3] ? `.${cpf[3]}` : ''}${cpf[4] ? `-${cpf[4]}` : ''}`);
      break;
    case 'cnpj':
      const cnpj = value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,4})(\d{0,2})/);
      setMaskedValue(!cnpj[2] ? cnpj[1] : `${cnpj[1]}.${cnpj[2]}${cnpj[3] ? `.${cnpj[3]}` : ''}${cnpj[4] ? `/${cnpj[4]}` : ''}${cnpj[5] ? `-${cnpj[5]}` : ''}`);
      break;
    case 'phone':
      const phone = value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,4})(\d{0,4})/);
      setMaskedValue(!phone[2] ? phone[1] : `(${phone[1]}) ${phone[2]}${phone[3] ? `-${phone[3]}` : ''}`);
      break;
    case 'cellphone':
      const cellphone = value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,5})(\d{0,4})/);
      setMaskedValue(!cellphone[2] ? cellphone[1] : `(${cellphone[1]}) ${cellphone[2]}${cellphone[3] ? `-${cellphone[3]}` : ''}`);
      break;
    case 'cep':
      const cep = value.replace(/\D/g, '').match(/(\d{0,5})(\d{0,3})/);
      setMaskedValue(!cep[2] ? cep[1] : `${cep[1]}-${cep[2]}`);
      break;
    case 'date':
      const date = value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,2})(\d{0,4})/);
      setMaskedValue(!date[2] ? date[1] : `${date[1]}/${date[2]}${date[3] ? `/${date[3]}` : ''}`);
      break;
    case 'time':
      const time = value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,2})/);
      setMaskedValue(!time[2] ? time[1] : `${time[1]}:${time[2]}`);
      break;
    default:
      setMaskedValue(value);
      break;
  }
}