import React from 'react';

import { Col, Row } from 'reactstrap';

import SimpleDonutChart from './SimpleDonutChart';

export default function FeedChart({ feed }) {
  const totalGeneral = feed.totalGeneral;
  const totalPosts = [
    { x: "Posts", y: feed.totalPosts || 0 },
    { x: "nothing", y: totalGeneral ? totalGeneral - (feed.totalPosts || 0) : 100 },
  ];

  const totalLikes = [
    { x: "Curtidas", y: feed.totalLikes || 0 },
    { x: "nothing", y: totalGeneral ? totalGeneral - (feed.totalLikes || 0) : 100 },
  ];

  const totalComments = [
    { x: "Comentários", y: feed.totalComments || 0 },
    { x: "nothing", y: totalGeneral ? totalGeneral - (feed.totalComments || 0) : 100 },
  ];

  const postsPercentage = feed.totalPosts ? ((feed.totalPosts / totalGeneral) * 100).toFixed(2) : 0;
  const likesPercentage = feed.totalLikes ? ((feed.totalLikes / totalGeneral) * 100).toFixed(2) : 0;
  const commentsPercentage = feed.totalComments ? ((feed.totalComments / totalGeneral) * 100).toFixed(2) : 0;

  return (
    <div className="rank-wrapper">
      <Row>
        <span className="title">Feed</span>
      </Row>
      <div className="sub-title-container" >
        <div>
          <i className="fa fa-users fa-light" style={{ color: '#5470c6' }} />
        </div>
        <div>
          <span>Total de Interações: {totalGeneral}</span>
        </div>
      </div>
      <Row className="point-container" style={{marginTop: 10}}>
        <Col lg="6" sm="6" xs="5">
          Posts
        </Col>
        <Col sm="3" xs="3">
          <SimpleDonutChart
            height={60}
            data={totalPosts}
          />
        </Col>
        <Col sm="3" xs="4" className="text-center">
          <Row>
            <span>Quantidade</span>
          </Row>
          <Row>
            <span>
              {feed.totalPosts} ({postsPercentage}%)
            </span>
          </Row>
        </Col>
      </Row>
      <div className="line-break-rank"/>
      <Row className="point-container">
        <Col lg="6" sm="6" xs="5">
          Curtidas
        </Col>
        <Col sm="3" xs="3">
          <SimpleDonutChart
            height={60}
            data={totalLikes}
            color={'#91cc75'}
          />
        </Col>
        <Col sm="3" xs="4" className="text-center">
          <Row>
            <span>Quantidade</span>
          </Row>
          <Row>
            <span>
              {feed.totalLikes} ({likesPercentage}%)
            </span>
          </Row>
        </Col>
      </Row>
      <div className="line-break-rank"/>
      <Row className="point-container">
        <Col lg="6" sm="6" xs="5">
          Comentários
        </Col>
        <Col sm="3" xs="3">
          <SimpleDonutChart
            height={60}
            data={totalComments}
            color={'#ee6666'}
          />
        </Col>
        <Col sm="3" xs="4" className="text-center">
          <Row>
            <span>Quantidade</span>
          </Row>
          <Row>
            <span>
              {feed.totalComments} ({commentsPercentage}%)
            </span>
          </Row>
        </Col>
      </Row>
    </div>
  )
}
