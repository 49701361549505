import React, { useState, useEffect } from "react";
import { Modal, ModalBody, ModalHeader, Row, Col, Button } from "reactstrap";
import { useLocation } from "react-router-dom";
import Select from "react-select";

import { getListSessions } from "../../../../utils/services/scheduleCotas";
import { isOpenDraw } from "../../../../utils/services/sorteios";
import { connect } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
import ModalAlertStock from "./ModalAlertStock";

function ModalEditSession(props) {
  const { isOpen, toggle, sessionItem, sessionsSourceData, updateSession } =
    props;
  const [modalBackdrop, setModalBackdrop] = useState(false);

  const [sessions, setSessions] = useState([]);
  const [section, setSection] = useState();
  const [sessionsSource, setSessionsSource] = useState([]);
  const [typeQuotaToSubtract, setTypeQuotaToSubtract] = useState("manual");

  const [registerQuota, setRegisterQuota] = useState(false);
  const [companionsAmount, setCompaniosAmount] = useState(
    sessionItem.companionsNumber
  );
  const [accessibility, setAccessibility] = useState(getAccessibility());
  const [typeAccessibility, setTypeAccessibility] = useState(
    sessionItem.typeRestriction
  );
  const [observation, setObservation] = useState(sessionItem.notes);

  const [sectionError, setSectionError] = useState();
  const [companionsAmountError, setCompaniosAmountError] = useState();
  const [accessibilityError, setAccessibilityError] = useState();
  const [typeAccessibilityError, setTypeAccessibilityError] = useState();

  const [success_dlg, setsuccess_dlg] = useState(false);
  const [showModalAlertStock, setShowModalAlertStock] = useState(false);

  const location = useLocation();

  useEffect(() => {
    listSessions();
  }, []);

  useEffect(() => {
    if (registerQuota) {
      updateSessions();
    }
  }, [registerQuota]);

  const formatDate = (date) => {
    return date.split("T")[0].split("-").reverse().join("/");
  };

  function confirmStockAlert(value) {
    setRegisterQuota(value);
  }
  function setModalAlert() {
    setShowModalAlertStock(!showModalAlertStock);
  }

  async function listSessions() {
    const jwt = props?.state?.global_user_data?.data?.token;
    const eventId = location.state.idEvent;
    if (!eventId || !jwt) return;

    const res = await getListSessions(eventId, jwt);

    if (res.data.cotasManuais.length > 0) {
      setSessionsSource(res.data.cotasManuais);
      const formattedData = res.data.cotasManuais
        .filter(
          (el) =>
            el.agendaid === sessionItem.sessionId &&
            (parseInt(el.availablemanuais) > 0 ||
              parseInt(el.availablesorteio) > 0 ||
              parseInt(el.availablefechadas) > 0)
        )
        .map((e) => ({
          label: `${e.title} - ${formatDate(e.startdate)} ${
            e.starthour
          } - cotas disponíveis: ${e.availablemanuais} - cotas fechadas: ${
            e.availablefechadas
          } - cotas sorteio: ${e.availablesorteio}`,
          value: e.agendaid,
        }));
      setSessions(formattedData);
      setSection(formattedData);
    }
  }

  function getAccessibility() {
    return sessionItem.accessibilityRestriction
      ? { label: "Sim", value: true }
      : { label: "Não", value: false };
  }

  function toggleBackdrop() {
    setModalBackdrop(!modalBackdrop);
  }

  function checkProductExists(product) {
    if (location.state) {
      const search = location.state.event.product.filter(
        (e) => e.name === product
      );

      if (search[0]) {
        return true;
      } else return false;
    }
  }

  async function submitForm(e) {
    e.preventDefault();

    if (checkProductExists("Distribuição de Cotas") && !section) {
      setSectionError("O campo Sessão é obrigatório");
      return;
    } else setSectionError("");

    if (checkProductExists("Distribuição de Cotas") && !companionsAmount) {
      setCompaniosAmountError(
        "O campo Quantidade Total de Ingressos é obrigatório"
      );
      return;
    } else setCompaniosAmountError("");

    if (checkProductExists("Distribuição de Cotas") && !accessibility) {
      setAccessibilityError("O campo Acessibilidade é obrigatório");
      return;
    } else setAccessibilityError("");

    if (
      checkProductExists("Distribuição de Cotas") &&
      accessibility.value &&
      !typeAccessibility
    ) {
      setTypeAccessibilityError("O campo Qual acessibilidade é obrigatório");
      return;
    } else setTypeAccessibilityError("");

    const stock = sessionsSource.filter(
      (e) => e.agendaid === section[0].value
    )[0];

    const resOpendraw = await isOpenDraw(section[0].value);

    const availablemanuais =
      parseInt(stock.availablemanuais) +
      parseInt(sessionsSourceData[0].companionsNumber);
    const availableSorteio = !resOpendraw
      ? parseInt(stock.availablesorteio)
      : 0;
    const availablefechadas = parseInt(stock.availablefechadas);

    const totalAvailable =
      availablemanuais + availableSorteio + availablefechadas;

    if (companionsAmount > totalAvailable) {
      setTypeQuotaToSubtract("unavailable");
      setShowModalAlertStock(true);
      return;
    }

    if (availablemanuais < companionsAmount) {
      if (
        !resOpendraw?.data &&
        availablemanuais + availableSorteio >= companionsAmount
      ) {
        setTypeQuotaToSubtract("sorteio");
      } else if (totalAvailable >= companionsAmount) {
        if (availableSorteio > 0 && !resOpendraw?.data) {
          setTypeQuotaToSubtract("sorteio e fechadas");
        } else {
          setTypeQuotaToSubtract("fechadas");
        }
      }
      setShowModalAlertStock(true);
      return;
    }
    updateSessions();
  }

  async function updateSessions() {
    const data = {
      sessionId: section[0].value,
      title: section[0].label,
      companionsNumber: companionsAmount,
      accessibilityRestriction: accessibility.value,
      typeRestriction: accessibility.value ? typeAccessibility : "",
      notes: observation,
    };
    await updateSession(sessionItem.sessionId, data);
    toggle();
  }

  return (
    <>
      {showModalAlertStock && (
        <ModalAlertStock
          isOpen={showModalAlertStock}
          toggle={setModalAlert}
          typeQuota={typeQuotaToSubtract}
          confirmStockAlert={confirmStockAlert}
        />
      )}
      <Modal
        isOpen={isOpen}
        toggle={() => toggleBackdrop()}
        autoFocus={true}
        centered={true}
      >
        {success_dlg ? (
          <SweetAlert
            success
            title={"Editado"}
            onConfirm={() => {
              props.getAllParticipants();
              toggle();
              setsuccess_dlg(false);
            }}
          >
            <>
              <p>Senha editada com sucesso!</p>
            </>
          </SweetAlert>
        ) : null}
        <ModalHeader toggle={toggle}>Editar Sessão</ModalHeader>
        <ModalBody>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              submitForm(e);
            }}
          >
            {checkProductExists("Distribuição de Cotas") && (
              <>
                <Row style={{ marginBottom: 10 }}>
                  <Col md={12}>
                    <label className="form-label">Sessão*</label>
                    <Select
                      value={section}
                      isDisabled={true}
                      options={[
                        {
                          options: [...sessions],
                        },
                      ]}
                      classNamePrefix="select2-selection"
                    />
                    {/* {sectionError && (
                    <a style={{ color: 'red' }}>{sectionError}</a>
                  )} */}
                  </Col>
                </Row>

                <Row style={{ marginBottom: 10 }}>
                  <Col md={12}>
                    <label className="form-label">
                      Quantidade Total de Ingressos*
                    </label>
                    <input
                      className="form-control"
                      type="number"
                      value={companionsAmount}
                      onChange={(e) => setCompaniosAmount(e.target.value)}
                    />
                    {companionsAmountError && (
                      <a style={{ color: "red" }}>{companionsAmountError}</a>
                    )}
                  </Col>
                </Row>

                <Row style={{ marginBottom: 10 }}>
                  <Col md={12}>
                    <label className="form-label">
                      Você ou seu(s) acompanhante(s) são PCD?*
                    </label>
                    <Select
                      value={accessibility}
                      placeholder={"Selecione"}
                      onChange={(e) => {
                        setAccessibility(e);
                      }}
                      options={[
                        {
                          options: [
                            { label: "Sim", value: true },
                            { label: "Não", value: false },
                          ],
                        },
                      ]}
                      classNamePrefix="select2-selection"
                    />
                    {accessibilityError && (
                      <a style={{ color: "red" }}>{accessibilityError}</a>
                    )}
                  </Col>
                </Row>

                {accessibility && accessibility.value && (
                  <Row style={{ marginBottom: 10 }}>
                    <Col md={12}>
                      <label className="form-label">
                        Para assegurar o acesso de Pessoas com Deficiência em
                        mais espaços, indique quais cuidados são necessários
                        para que você ou seu(s) acompanhante(s) possam
                        aproveitar essa atividade:*
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        value={typeAccessibility}
                        onChange={(e) => setTypeAccessibility(e.target.value)}
                      />
                      {typeAccessibilityError && (
                        <a style={{ color: "red" }}>{typeAccessibilityError}</a>
                      )}
                    </Col>
                  </Row>
                )}

                <Row style={{ marginBottom: 10 }}>
                  <Col md={12}>
                    <label className="form-label">Observações</label>
                    <textarea
                      value={observation}
                      className="form-control"
                      type="text"
                      onChange={(e) => setObservation(e.target.value)}
                    />
                  </Col>
                </Row>
              </>
            )}

            <Row
              style={{
                width: "100%",
                margin: "auto",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                color="danger"
                style={{ width: 80, marginRight: 10 }}
                onClick={() => toggle()}
              >
                Fechar
              </Button>
              <Button
                color="success"
                className="btn btn-success waves-effect waves-light"
                style={{ width: 80 }}
                type="submit"
              >
                Confirmar
              </Button>
            </Row>
          </form>
        </ModalBody>
      </Modal>
    </>
  );
}

const mapStateToProps = function (state) {
  return { state: state };
};

export default connect(mapStateToProps)(ModalEditSession);
