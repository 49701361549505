import React, { useEffect, useReducer, useState } from 'react';
import { Table } from 'reactstrap';
import Pagination from '../../../components/Paginationv3';
import Filters from './Filters';

const salesMock = [
  {
    transactionId: '1',
    status: 'success',
    buyer: 'John Doe',
    buyerEmail: 'john@email.com',
    buyerDocument: '123456789',
    guest: 'Amanda',
    guestEmail: 'amanda@email.com',
    guestDocument: '987654321',
    ticketValue: 100,
  },
  {
    transactionId: '1',
    status: 'success',
    buyer: 'John Doe',
    buyerEmail: 'john@email.com',
    buyerDocument: '123456789',
    guest: 'Amanda',
    guestEmail: 'amanda@email.com',
    guestDocument: '987654321',
    ticketValue: 100,
  },
  {
    transactionId: '1',
    status: 'success',
    buyer: 'John Doe',
    buyerEmail: 'john@email.com',
    buyerDocument: '123456789',
    guest: 'Amanda',
    guestEmail: 'amanda@email.com',
    guestDocument: '987654321',
    ticketValue: 100,
  },
  {
    transactionId: '1',
    status: 'success',
    buyer: 'John Doe',
    buyerEmail: 'john@email.com',
    buyerDocument: '123456789',
    guest: 'Amanda',
    guestEmail: 'amanda@email.com',
    guestDocument: '987654321',
    ticketValue: 100,
  }
];

const filtersMock = [
  {
    name: 'Amanda',
    value: 'buyer',
  },
  {
    name: 'Joel',
    value: 'buyerEmail',
  },
  {
    name: 'Gui',
    value: 'buyerDocument',
  },
  {
    name: 'Felipe',
    value: 'guest',
  },
  {
    name: 'Lucas',
    value: 'guestEmail',
  },
];

export default function SalesTable() {
  const [sales, setSales] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [selectOptions, setSelectOptions] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      buyer: filtersMock,
      buyerEmail: filtersMock,
      buyerDocument: filtersMock,
      guest: filtersMock,
      guestEmail: filtersMock,
      guestDocument: filtersMock,
    }  
  );

  const [filteredSales, setFilteredSales] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      buyer: '',
      buyerEmail: '',
      buyerDocument: '',
      guest: '',
      guestEmail: '',
      guestDocument: '',
    }
  );

  useEffect(() => {
    setSales(salesMock);
    setTotalItems(salesMock.length);
  }, []);

  const filters = [
    {
      name: 'buyer',
      placeholder: 'Nome do comprador',
      isMulti: false,
    },
    {
      name: 'buyerEmail',
      placeholder: 'Email do comprador',
      isMulti: false,
    },
    {
      name: 'buyerDocument',
      placeholder: 'Documento do comprador',
      isMulti: false,
    },
    {
      name: 'guest',
      placeholder: 'Nome do convidado',
      isMulti: false,
    },
    {
      name: 'guestEmail',
      placeholder: 'Email do convidado',
      isMulti: false,
    },
    {
      name: 'guestDocument',
      placeholder: 'Documento do convidado',
      isMulti: false,
    },
  ];

  return (
    <div>
      <Filters
        filters={filters}
        value={filteredSales}
        setFilteredSales={setFilteredSales}
        options={selectOptions}
      />
      <div className="table-responsive" style={{ marginTop: '20px' }}>
        <Table>
          <thead className="table-light">
            <tr>
              <th>Transação</th>
              <th>Status Pagamento</th>
              <th>Comprador</th>
              <th>Comprador Email</th>
              <th>Comprador Documento</th>
              <th>Convidado</th>
              <th>Convidado Email</th>
              <th>Convidado CPF</th>
              <th>Valor do Ingresso</th>
            </tr>
          </thead>
          <tbody>
            {sales.length > 0 &&
              sales.map((e, i) => (
                <tr key={i}>
                  <td>{e.transactionId}</td>
                  <td>{e.status}</td>
                  <td>{e.buyer}</td>
                  <td>{e.buyerEmail}</td>
                  <td>{e.buyerDocument}</td>
                  <td>{e.guest}</td>
                  <td>{e.guestEmail}</td>
                  <td>{e.guestDocument}</td>
                  <td>{e.ticketValue}</td>
                </tr>
              ))}
          </tbody>
        </Table>
      </div>
      <div className="bottom-navigation" style={{ justifyContent: "end" }}>
        {/* 
        Fornecer os 4 parametros obrigatórios;
        ** data - a array contendo as informações da tabela
        ** search - o state do termo de pesquisa
        ** currentIndex - posição atual do index
        ** setCurrentIndex - função para atualizar a posição atual do index
        */}
        <Pagination
          data={sales}
          currentIndex={currentPage}
          setCurrentIndex={setCurrentPage}
          countData={totalItems}
          maxItemPerPage={itemsPerPage}
          setMaxItemPerPage={setItemsPerPage}
          maxPageButtonsToShow={3}
        />
      </div>
    </div>
  )
}
