import React, { useEffect, useState } from 'react';

import { Input, Label, UncontrolledTooltip } from 'reactstrap';

export default function UploadDisplay({ attributes, rowId, colId, setValue }) {
  const [selected, setSelected] = useState('');

  useEffect(() => {
    const html = attributes.label;
    const div = document.getElementsByClassName(`upload-label-${rowId}-${colId}`);
  
    for (const element of div) {
      element.innerHTML = html;
    }
  }, [attributes, rowId, colId]);

  useEffect(() => {
    setValue((oldValue) => {
      const newValue = [...oldValue];
      newValue[rowId].cols[colId].attributes.value = selected;
      if (attributes.required && !selected) {
        newValue[rowId].cols[colId].attributes.errorMessage = `O campo ${attributes.label} é obrigatório`;
      } else {
        newValue[rowId].cols[colId].attributes.errorMessage = ''
      }

      return newValue;
    });
  }, [selected, setValue, rowId, colId, attributes.required, attributes.label]);

  return (
    <div className="custom-input-container" id="updload-component">
      <div>
        <div className="displayed-config-container">
          <div className="displayed-label">
            <Label htmlFor="formFile" className={`form-label upload-label-${rowId}-${colId}`} />
            {attributes.hasTip && (
              <div>
                <div className="show-tips" id={`upload-tip-${rowId}-${colId}`}>
                  <span>?</span>
                </div>
                <UncontrolledTooltip style={{ backgroundColor: '#6f42c1' }} placement="right" target={`upload-tip-${rowId}-${colId}`}>
                  {attributes.tipText}
                </UncontrolledTooltip>
              </div>
            )}
          </div>
        </div>
      </div>
      <Input
        className="form-control"
        type="file"
        id="formFile"
        onChange={(e) => setSelected({files: e.target.files, path: e.target.value})}
        defaultValue={null}
      />
    </div>
  )
}
