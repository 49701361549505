import { urlBase } from "./config";
import axiosInstance from "../services/config/axiosinstance";

async function createLoginStatusVehicle(data) {
  var config = {
    method: "post",
    url: `${urlBase}/loginstatusvehicle`,
    headers: {
      "Content-Type": "application/json",
    },
    data,
  };

  return axiosInstance(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

async function getAllLoginStatusVehicle(
  eventId,
  vehicleId,
  loginId,
  offset,
  limit,
  search,
  columnSearch = ""
) {
  try {
    const config = {
      method: "get",
      url: `${urlBase}/loginstatusvehicle/${eventId}/event/${vehicleId}/vehicle/${loginId}/login?offset=${offset}&limit=${limit}&search=${search}&columnSearch=${columnSearch}`,
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await axiosInstance(config);
    return response;
  } catch (error) {
    return error.response;
  }
}

export { createLoginStatusVehicle, getAllLoginStatusVehicle };
