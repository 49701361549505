import React, { useEffect, useRef, useState } from "react";

import {
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Card,
  CardBody,
  Collapse,
} from "reactstrap";

import ImportSchedule from "./ImportSchedule";
import ImportScheduleQuotas from "./ImportScheduleQuotas";

import SectionComponent from "../../Apps/Personalization/SectionComponent";

export default function ModalImportQuotasWrapper(props) {
  const { isOpen, toggle, idEvent, token, checkProductExists } = props;

  const [collapseAgendaCotaIsOpen, setCollapseAgendaCotaIsOpen] =
    useState(false);
  const [collapseAgendaIsOpen, setCollapseAgendaIsOpen] = useState(false);
  const [activeImport, setActiveImport] = useState(false);

  function toggleBackdrop() {
    setCollapseAgendaCotaIsOpen(false);
    setCollapseAgendaIsOpen(false);
    toggle();
  }
  function toggleCollapseLogin() {
    if (activeImport) return;
    setCollapseAgendaCotaIsOpen(false);
    setCollapseAgendaIsOpen(!collapseAgendaIsOpen);
  }

  function toggleCollapseGroup() {
    if (activeImport) return;
    setCollapseAgendaCotaIsOpen(!collapseAgendaCotaIsOpen);
    setCollapseAgendaIsOpen(false);
  }

  function activatedImport(status) {
    setActiveImport(status);
  }

  function setNull() {}

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => toggleBackdrop()}
      autoFocus={true}
      centered={true}
    >
      <ModalHeader toggle={() => toggleBackdrop()}>
        Importação Agenda
      </ModalHeader>
      <ModalBody className="m-1 p-0">
        <Card className="p-1">
          <CardBody className="m-1 p-2">
            {(checkProductExists("APP Eventos Plus") ||
              checkProductExists("RSVP")) && (
              <div>
                <Row
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginBottom: 10,
                    width: "245px",
                  }}
                >
                  <SectionComponent
                    sectionName="Criar Agendas"
                    setIsOpen={!activeImport ? toggleCollapseLogin : setNull}
                    isOpen={collapseAgendaIsOpen}
                  />
                </Row>
                <Collapse isOpen={collapseAgendaIsOpen}>
                  <ImportSchedule
                    isOpen={isOpen}
                    toggle={toggleBackdrop}
                    token={token}
                    idEvent={idEvent}
                  />
                </Collapse>
              </div>
            )}
            {checkProductExists("Distribuição de Cotas") && (
              <div>
                <Row
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginBottom: 10,
                    width: "280px",
                  }}
                >
                  <SectionComponent
                    sectionName="Criar Agendas Cotas"
                    setIsOpen={!activeImport ? toggleCollapseGroup : setNull}
                    isOpen={collapseAgendaCotaIsOpen}
                  />
                </Row>
                <Collapse isOpen={collapseAgendaCotaIsOpen}>
                  <ImportScheduleQuotas
                    isOpen={isOpen}
                    toggle={toggleBackdrop}
                    token={token}
                    idEvent={idEvent}
                  />
                </Collapse>
              </div>
            )}
          </CardBody>
        </Card>
      </ModalBody>
    </Modal>
  );
}
