import React, { useEffect } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

export default function Captcha({ deleteComponent, rowId, colId, setAttributes }) {
  useEffect(() => {
    setAttributes({
      rowId,
      colId,
      attributes: {
        inputName: 'Captcha',
        required: true,
        uniqueField: 'Captcha',
      },
    });
  }, [rowId, colId, setAttributes])

  return (
    <div>
    <div className="displayed-config-container">
      <div className="displayed-label">
        <ReCAPTCHA
          sitekey="6Lcj77QUAAAAALq8HRPFs-tEagWfhWqg7AfYJ8JP"
        />
      </div>
      <div className="config-form-icon">
        <span
          className="mdi mdi-close close-icon"
          title="Remover Campo"
          onClick={() => deleteComponent(rowId, colId)}
        />
      </div>
    </div>
  </div>
  )
}
