import React, { useState } from 'react';

import { Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';

export default function LabelInput({ deleteComponent, rowId, colId, attributes, setAttributes }) {
  const [inputName, setInputName] = useState(attributes ? attributes.inputName : '');
  const [label, setLabel] = useState(attributes ? attributes.label : 'Label');
  // Edit Modal
  const [editModal, setEditModal] = useState(false);

  const [nameError, setNameError] = useState(false);

  const submitForm = (e) => {
    e.preventDefault();

    if (inputName === '') {
      setNameError(true);
      return;
    }

    setNameError(false);

    setAttributes({
      rowId,
      colId,
      attributes: { label, inputName },
    });
    setEditModal(false);
  };

  return (
    <div>
      <div className="custom-input-container">
        <Modal
          size="lg"
          isOpen={editModal}
          toggle={() => setEditModal(!editModal)}
        >
          <ModalHeader toggle={() => setEditModal(false)}>
            Configurar Label
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col>
                <Form>
                  <FormGroup className="input-margin-btm">
                    <Label htmlFor="edit-input-name" className="form-label">
                      Nome do campo
                    </Label>
                    <Input
                      id="edit-input-name"
                      className="form-control"
                      type="text"
                      placeholder="Nome do campo"
                      value={inputName}
                      onChange={({ target }) => setInputName(target.value)}
                    />
                    {nameError && <p style={{color: 'red'}}>O campo Nome do Campo é obrigatório!</p>}
                  </FormGroup>
                  <FormGroup className="input-margin-btm">
                    <Label htmlFor="edit-input-text" className="form-label">
                      Digite o texto do label
                    </Label>
                    <Input
                      id="edit-input-text"
                      className="form-control"
                      type="text"
                      placeholder="Digite o texto aqui"
                      value={label}
                      onChange={({ target }) => setLabel(target.value)}
                    />
                  </FormGroup>
                </Form>
              </Col>
              <Col style={{borderLeft: '1px solid gray'}}>
                <div style={{ display: 'flex' }}>
                  <Label className="form-label" htmlFor="formText">
                    {label === "Label" ? label : <div dangerouslySetInnerHTML={{__html: label}} />}
                  </Label>
                </div>
              </Col>
            </Row>
            <div className="save-btn-form-container">
              <Button
                color="success"
                className="btn btn-success waves-effect waves-light save-btn-form"
                onClick={(e) => submitForm(e)}
              >
                Salvar
              </Button>
            </div>
          </ModalBody>
        </Modal>
        <div>
          <div className="displayed-config-container">
            <div className="displayed-label">
              <Label className="form-label" htmlFor="formText">
                {label === "Label" ? label : <div dangerouslySetInnerHTML={{__html: label}} />}
              </Label>
            </div>
            <div className="config-form-icon">
              <span
                className="mdi mdi-cog-outline"
                onClick={() => setEditModal(!editModal)}
              />
              <span
                className="mdi mdi-close close-icon"
                title="Remover Campo"
                onClick={() => deleteComponent(rowId, colId)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
