import axios from "axios";
import { urlBase } from "./config";

export async function createCupomImport({
  eventId,
  code,
  quantityAvailable,
  valueType,
  value,
  expireAt,
  validityHour,
  products,
  jwt,
}) {
  try {
    const datePart = new Date(expireAt);

    const [validityHourHours, validityHourMinutes] = validityHour
      .split(":")
      .map(Number);

    datePart.setUTCHours(validityHourHours, validityHourMinutes, 0, 0);

    datePart.setUTCHours(datePart.getUTCHours() + 3);

    const expireAtISO = datePart.toISOString();

    const data = JSON.stringify({
      eventId,
      code,
      quantityAvailable,
      expireAt: expireAtISO,
      value,
      valueType,
      products,
    });

    const config = {
      method: "post",
      url: `${urlBase}/payments/promos`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt}`,
      },
      data,
    };

    const response = await axios(config);
    return response.data;
  } catch (error) {
    console.error("Error during API call:", error);
    return { error: "Request failed" };
  }
}
