import React from "react";

export default function Component(props) {
  return (
    <div
      style={{
        width: 40,
        height: 40,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        backgroundColor: props.iconSelected === props.icon ? "	#32CD32" : "",
      }}
      onClick={() => {
        props.setIconSelected(props.icon);
        props.toggleBackdrop(props.icon);
      }}
    >
      <i className={props.icon} />
    </div>
  );
}
