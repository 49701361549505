import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Table,
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  Collapse,
  Button,
} from "reactstrap";
import ModalEditStatusPart from "./modalEditStatusPart";
import Pagination from "../../../components/Paginationv3";
import useDebounce from "../../../utils/hooks/useDebounce";
import { getParticipantsByVehicle } from "../../../utils/services/transfer";
import SectionComponent from "../../Apps/Personalization/SectionComponent";
import SearchFilters from "../../../components/SearchFilters";
import ModalListHistoricPart from "./modalListHistoricPart";
import ModalEditMovePartVehicle from "./modalEditMovePartVehicle";
import ModalEditParticipant from "./modalEditParticipant";
import ModalViewParticipant from "./modalViewParticipants";
import ModalQRCode from "./modalQRCode";
import { getParticipantById } from "../../../utils/services/participants";

export default function ModalListParticipants(props) {
  const { isOpen, toggle, data } = props;

  const columnsFilter = [
    { name: "Nome", value: "nome", type: "text" },
    { name: "Email", value: "email", type: "text" },
  ];
  const [modalEditStatus, setModalEditStatus] = useState(false);
  const [modalEditMoveVehicle, setModalEditMoveVehicle] = useState(false);
  const [modalEditParticipant, setModalEditParticipant] = useState(false);
  const [modalViewParticipant, setModalViewParticipant] = useState(false);
  const [modalQRCode, setModalQRCode] = useState(false);
  const [collapseIsOpen, setCollapseIsOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [countData, setCountData] = useState(0);
  const [search, setSearch] = useState("");
  const [participants, setParticipants] = useState([]);
  const [participant, setParticipant] = useState([]);
  const [selected, setSelected] = useState([]);
  const [modalHistoricPart, setModalHistoricPart] = useState(false);
  const [participantSelected, setParticipantSelected] = useState("");
  const [previousStatus, setPreviousStatus] = useState("");
  const [changeStatusBySelection, setChangeStatusBySelection] = useState(false);
  const [bulkDataStatus, setBulkDataStatus] = useState([]);

  const debouncedSearch = useDebounce(search, 300);

  const [activeFilter, setActiveFilter] = useState("nome");

  useEffect(() => {
    getParticipantsByVehicle(
      data.eventId,
      data.id,
      currentIndex,
      itemsPerPage,
      treatmentSearch(),
      activeFilter
    ).then((res) => {
      if (res.data.data) {
        setParticipants(res.data.data);
        setCountData(res.data.count);
      }
    });
  }, [treatmentSearch()]);

  function getAll() {
    getParticipantsByVehicle(
      data.eventId,
      data.id,
      currentIndex,
      itemsPerPage,
      treatmentSearch(),
      activeFilter
    ).then((res) => {
      if (res.data.data) {
        setParticipants(res.data.data);
        setCountData(res.data.count);
      }
    });
  }
  function getOneParticipant(part) {
    getParticipantById(data.eventId, part, currentIndex, itemsPerPage).then(
      (res) => {
        if (res.data.data) {
          setModalViewParticipant(true);
          setParticipant(res.data.data);
        }
      }
    );
  }
  function treatmentSearch() {
    if (
      columnsFilter.filter((item) => item.value === activeFilter)[0]?.type ===
      "date"
    ) {
      return reverseDate();
    }
    return debouncedSearch;
  }
  function setActiveFilterHandle(columnActive) {
    setActiveFilter(columnActive);
    setSearch("");
  }
  const handleSelectAll = (isChecked) => {
    if (isChecked) {
      setSelected(participants.map((item) => item.participantId));
    } else {
      setSelected([]);
    }
  };

  const handleSelectOne = (id, isChecked) => {
    if (isChecked) {
      setSelected((prev) => [...prev, id]);
    } else {
      setSelected((prev) => prev.filter((selectedId) => selectedId !== id));
    }
  };

  const isAllSelected =
    participants.length > 0 && selected.length === participants.length;
  const isIndeterminate =
    selected.length > 0 && selected.length < participants.length;

  function buildBulkData(opt) {
    const filteredParticipants = participants.filter((item) =>
      selected.includes(item.participantId.toString())
    );

    setBulkDataStatus(filteredParticipants);
    if (opt === "status") {
      setModalEditStatus(true);
    }
    if (opt === "move") {
      setModalEditMoveVehicle(true);
    }
  }

  return (
    <>
      {modalEditStatus && (
        <ModalEditStatusPart
          isOpen={modalEditStatus}
          toggle={() => {
            setModalEditStatus(false);
          }}
          eventId={data.eventId}
          vehicleId={data.id}
          loginId={participantSelected}
          previousStatus={previousStatus}
          getAll={getAll}
          bulkData={bulkDataStatus}
          changeStatusBySelection={changeStatusBySelection}
        />
      )}
      {modalEditMoveVehicle && (
        <ModalEditMovePartVehicle
          isOpen={modalEditMoveVehicle}
          toggle={() => {
            setModalEditMoveVehicle(false);
          }}
          eventId={data.eventId}
          vehicleId={data.id}
          loginId={participantSelected}
          getAll={getAll}
          bulkData={bulkDataStatus}
          changeStatusBySelection={changeStatusBySelection}
        />
      )}
      {modalEditStatus && (
        <ModalEditStatusPart
          isOpen={modalEditStatus}
          toggle={() => {
            setModalEditStatus(false);
          }}
          eventId={data.eventId}
          vehicleId={data.id}
          loginId={participantSelected}
          previousStatus={previousStatus}
          getAll={getAll}
          bulkData={bulkDataStatus}
          changeStatusBySelection={changeStatusBySelection}
        />
      )}
      {modalHistoricPart && (
        <ModalListHistoricPart
          isOpen={modalHistoricPart}
          toggle={() => setModalHistoricPart(false)}
          data={data}
          loginId={participantSelected}
        />
      )}
      {modalEditParticipant && (
        <ModalEditParticipant
          isOpen={modalEditParticipant}
          toggle={() => {
            setModalEditParticipant(false);
          }}
          eventId={data.eventId}
          vehicleId={data.id}
          getAll={getAll}
          participantsInVehicle={participants}
        />
      )}
      {modalViewParticipant && (
        <ModalViewParticipant
          isOpen={modalViewParticipant}
          toggle={() => {
            setModalViewParticipant(false);
          }}
          participant={participant[0]}
          idEvent={data.eventId}
        />
      )}
      {modalQRCode && (
        <ModalQRCode
          isOpen={modalQRCode}
          toggle={() => {
            setModalQRCode(false);
          }}
          data={participants}
          eventId={data.eventId}
          vehicleId={data.id}
          getaAll={getAll}
        />
      )}
      <Modal isOpen={isOpen} centered autoFocus size="lg">
        <ModalHeader toggle={toggle}>Participantes por veículo</ModalHeader>
        <div style={{ marginLeft: "15px", width: "95%" }}>
          <Row
            style={{
              display: "flex",
              flexDirection: "row",
              marginBottom: 10,
              width: "50px",
            }}
          >
            <SectionComponent
              sectionName="Filtros"
              setIsOpen={setCollapseIsOpen}
              isOpen={collapseIsOpen}
            />
          </Row>
          <Collapse isOpen={collapseIsOpen}>
            <SearchFilters
              columns={columnsFilter}
              activeFilter={activeFilter}
              setActiveFilterHandle={setActiveFilterHandle}
            />
          </Collapse>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            // margin: "px 0",
          }}
        >
          <form
            className="app-search d-none d-lg-block events-input-search"
            onSubmit={(e) => e.preventDefault()}
            style={{ marginLeft: "15px", width: "40%" }}
          >
            <div className="position-relative">
              <input
                type={"text"}
                value={search}
                className="form-control"
                placeholder="Pesquisar..."
                onChange={(e) => setSearch(e.target.value)}
              />
              <span className="bx bx-search-alt" />
            </div>
          </form>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "flex-end",
            }}
          >
            <Button
              style={{ marginRight: "20px", marginBottom: "10px" }}
              color="success"
              className="btn-rounded waves-effect waves-light events-button-add"
              onClick={() => {
                setModalQRCode(true);
              }}
            >
              Ler QR Code
            </Button>
            <Button
              style={{ marginRight: "20px", marginBottom: "10px" }}
              color="success"
              className="btn-rounded waves-effect waves-light events-button-add"
              onClick={() => {
                setModalEditParticipant(true);
              }}
            >
              Adicionar Participante
            </Button>
          </div>
          {selected.length > 1 && (
            <div
              className="mb-2 d-flex flex-column flex-md-row"
              style={{
                width: "100%",
                justifyContent: "flex-end",
              }}
            >
              <Button
                style={{ marginRight: "10px", marginBottom: "10px" }}
                color="warning"
                className="btn-rounded waves-effect waves-light events-button-add"
                onClick={() => {
                  setChangeStatusBySelection(true);
                  buildBulkData("status");
                }}
              >
                Alterar Status em Massa
              </Button>
              <Button
                style={{ marginRight: "15px", marginBottom: "10px" }}
                color="warning"
                className="btn-rounded waves-effect waves-light events-button-add"
                onClick={() => {
                  setChangeStatusBySelection(true);
                  buildBulkData("move");
                }}
              >
                Alterar Participantes em Massa
              </Button>
            </div>
          )}
          <div className="mobile-btn-section" style={{ width: "100%" }}>
            <Row className="mobile-btns-top">
              <form
                className="app-search"
                style={{ padding: "0px" }}
                onSubmit={(e) => e.preventDefault()}
              >
                <div className="position-relative">
                  <input
                    type={"text"}
                    value={search}
                    className="form-control"
                    placeholder="Pesquisar..."
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <span className="bx bx-search-alt" />
                </div>
              </form>
            </Row>
          </div>
        </div>
        <ModalBody>
          <div className="table-responsive" style={{ margin: "0px 0" }}>
            <p>
              <span style={{ fontSize: 14, fontWeight: "bold" }}>Veículo:</span>{" "}
              {data.description}
            </p>
            <Table className="table mb-0">
              <thead className="table-light">
                <tr>
                  <th>
                    <input
                      type="checkbox"
                      onChange={(e) => handleSelectAll(e.target.checked)}
                      checked={isAllSelected}
                      ref={(input) => {
                        if (input) input.indeterminate = isIndeterminate;
                      }}
                    />
                  </th>
                  <th>Nome</th>
                  <th>Email</th>
                  <th>Status</th>
                  <th>Ações</th>
                </tr>
              </thead>

              <tbody>
                {participants.map((item) => (
                  <tr key={item.participantId}>
                    <td>
                      <input
                        type="checkbox"
                        checked={selected.includes(item.participantId)}
                        onChange={(e) =>
                          handleSelectOne(item.participantId, e.target.checked)
                        }
                      />
                    </td>
                    <td>{item.participantName}</td>
                    <td>{item.participantEmail}</td>
                    <td>
                      <span
                        className="tagDriver"
                        style={{
                          whiteSpace: "nowrap",
                          backgroundColor:
                            item?.status == "Embarcado"
                              ? "green"
                              : item.status == "Desembarcado"
                              ? "red"
                              : item.status == "Não Embarcou"
                              ? "orange"
                              : "transparent",
                        }}
                      >
                        {item?.status ? item?.status : ""}
                      </span>
                    </td>
                    <td>
                      <i
                        className="mdi mdi-eye"
                        style={{
                          color: "blue",
                          fontSize: 17,
                          cursor: "pointer",
                          marginRight: 5,
                        }}
                        onClick={() => {
                          getOneParticipant(item.participantId);
                        }}
                      />
                      <i
                        className="mdi mdi-car-arrow-right"
                        style={{
                          color: "green",
                          fontSize: 17,
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setModalEditStatus(true);
                          setParticipantSelected(item.participantId);
                          setPreviousStatus(item.status);
                        }}
                      />
                      <i
                        className="mdi mdi-note"
                        style={{
                          marginLeft: "5px",
                          color: "blue",
                          fontSize: 17,
                          cursor: "pointer",
                          fontSize: 17,
                        }}
                        onClick={() => {
                          setModalHistoricPart(true);
                          setParticipantSelected(item.participantId);
                        }}
                      />
                      <i
                        className="mdi mdi-swap-horizontal-circle"
                        style={{
                          marginLeft: "5px",
                          color: "darkblue",
                          fontSize: 17,
                          cursor: "pointer",
                          fontSize: 17,
                        }}
                        onClick={() => {
                          setModalEditMoveVehicle(true);
                          setParticipantSelected(item.participantId);
                        }}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          <div style={{ marginTop: 10 }}>
            <Pagination
              data={[]}
              search={search}
              currentIndex={currentIndex}
              setCurrentIndex={setCurrentIndex}
              countData={countData}
              maxItemPerPage={itemsPerPage}
              setMaxItemPerPage={setItemsPerPage}
              maxPageButtonsToShow={3}
            />
          </div>
        </ModalBody>
      </Modal>
    </>
  );
}
