import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import ListRsvp from "./listRsvp";
import CreatForm from "./createForm";
import EditForm from "./editForm";
import ViewForm from "./viewForm";

export default function Index(props) {
  const location = useLocation();

  const [step, setStep] = useState("_a");
  const [selectedForm, setSelectedForm] = useState("");
  return (
    <div>
      {step === "_a" && (
        <ListRsvp
          setStep={setStep}
          eventId={location.state.idEvent}
          setSelectedForm={setSelectedForm}
        />
      )}
      {step === "_b" && (
        <CreatForm setStep={setStep} eventId={location.state.idEvent} />
      )}
      {step === "_c" && (
        <EditForm
          setStep={setStep}
          eventId={location.state.idEvent}
          selectedForm={selectedForm}
        />
      )}
      {step === "_d" && (
        <ViewForm setStep={setStep} selectedForm={selectedForm} />
      )}
    </div>
  );
}
