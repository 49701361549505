import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody, Row, Col, Button } from "reactstrap";
// componentes
import ButtonSpinner from "../../../components/Buttons/ButtonSpinner";
// alerta
import SweetAlert from "react-bootstrap-sweetalert";

export default function ModalEditStatus(props) {
  const options = [
    "Não Iniciado",
    "Embarcando",
    "Em Trânsito",
    "Desembarcando",
    "Finalizado",
    "Cancelado",
  ];

  const { isOpen, toggle, changeStatus, data } = props;
  const [isLoading, setIsloading] = useState(false);
  // valor do campo
  const [selected, setSelected] = useState("");
  const [notes, setNotes] = useState(null);
  //   alerta
  const [notesError, setNotesError] = useState(null);
  const [success, setSuccess] = useState(false);
  const handleChangeStatus = (newStatus, notes) => {
    changeStatus(newStatus, notes);
  };

  function renderOptions(activeStatus) {
    return (
      <>
        {options.map((item) => (
          <option key={item} value={item} selected={item === activeStatus}>
            {item}
          </option>
        ))}
      </>
    );
  }

  return (
    <>
      {success && (
        <SweetAlert
          success
          title={"Atualizado"}
          onConfirm={() => {
            setSuccess(false);
            toggle();
          }}
        >
          {"Status atualizado com sucesso!"}
        </SweetAlert>
      )}

      <Modal isOpen={isOpen} centered autoFocus>
        <ModalHeader toggle={toggle}>Alterar Status</ModalHeader>
        <ModalBody>
          <Row>
            <Col md={12}>
              <label className="form-label">Status *</label>
              <select
                className="form-control"
                onChange={(e) => setSelected(e.target.value)}
              >
                {renderOptions(data.status)}
              </select>
            </Col>
          </Row>

          {selected === "Cancelado" && (
            <Row style={{ marginTop: 10 }}>
              <Col md={12}>
                <label className="form-label">Justificativa *</label>
                <input
                  className="form-control"
                  type="text"
                  onChange={(e) => setNotes(e.target.value)}
                />
              </Col>
              {notesError && <a style={{ color: "red" }}>{notesError}</a>}
            </Row>
          )}

          <Row
            style={{
              width: "100%",
              margin: "auto",
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "1rem",
            }}
          >
            <Button
              color="danger"
              style={{ width: 80, marginRight: 10 }}
              onClick={() => toggle()}
            >
              Fechar
            </Button>

            <ButtonSpinner
              isLoading={isLoading}
              title={"Salvar"}
              isDisabled={selected === "" || selected === data.status}
              onClick={() => {
                if (!notes && selected === "Cancelado") {
                  setNotesError("O campo Justificativa é obrigatório");
                  return;
                }
                handleChangeStatus(selected, notes);
                setSuccess(true);
              }}
            />
          </Row>
        </ModalBody>
      </Modal>
    </>
  );
}
