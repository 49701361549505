import React, { useEffect, useState } from "react";
import { MetaTags } from "react-meta-tags";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Collapse,
  Row,
  Table,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap";
import useDebounce from "../../utils/hooks/useDebounce";
// Redux
import { Link } from "react-router-dom";

import Pagination from "../../components/Paginationv3";
import ModalNewContract from "./ModalNewContract";
import ModalEditContract from "./ModalEditContract";
import ModalEnableContract from "./ModalEnableContract";
import SweetAlert from "react-bootstrap-sweetalert";
import Products from "./products";
import Screens from "./screens";

//redux
import { connect } from "react-redux";

//service
import { getContract, deleteContract } from "../../utils/services/contracts";
import SectionComponent from "../Apps/Personalization/SectionComponent";

function Contracts(props) {
  //search
  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounce(search, 300);
  //data
  const [contracts, setContracts] = useState([]);

  // opção do dropdwn
  const [dropOption, setDropOption] = useState(false);

  //criar contrato
  const [modalNewContract, setModalNewContract] = useState(false);

  //editar contrato
  const [modalEditContract, setModalEditContract] = useState(false);
  const [editContract, setEditContract] = useState();

  //deletar contrato
  const [deleteContractState, setDeleteContractState] = useState();
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [success_dlg, setsuccess_dlg] = useState(false);
  const [dynamic_title, setdynamic_title] = useState("");
  const [dynamic_description, setdynamic_description] = useState("");

  // modal de produtos
  const [modalProducts, setModalProducts] = useState(false);

  // modal de telas
  const [modalScreens, setModalScreens] = useState(false);

  //paginação
  const [currentIndex, setCurrentIndex] = useState(1);
  const [countData, setCountData] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  // collapse
  const [isOpen, setIsOpen] = useState(false);

  const [enabledContracts, setEnabledContracts] = useState(true);
  const [enableContractAlert, setEnableContractAlert] = useState(false);
  const [enableContractId, setEnableContractId] = useState("");
  const [nameContract, setNameContract] = useState("");

  function getAllContracts() {
    getContract({
      jwt: props.state.global_user_data.data.token,
      offset: currentIndex > 0 ? currentIndex : 1,
      limit: itemsPerPage,
      search: debouncedSearch,
      status: enabledContracts,
    }).then((contractsData) => {
      setCountData(contractsData?.data?.total);
      setContracts(contractsData?.data?.contracts);
    });
  }

  function toggleNewContract() {
    setModalNewContract(!modalNewContract);
  }

  function toggleEditContract() {
    setModalEditContract(!modalEditContract);
  }

  function setContractProps(contract) {
    setNameContract(contract.name);
    setEnableContractId(contract.id);
  }

  useEffect(() => {
    getAllContracts();
  }, []);

  useEffect(() => {
    getAllContracts();
  }, [currentIndex, itemsPerPage, debouncedSearch, enabledContracts]);

  function newContractContent(name, { appPlus, miniMeeting }) {
    // console.log({
    //   name,
    //   appPlus,
    //   miniMeeting,
    // });
    return {
      name,
      appPlus,
      miniMeeting,
    };
  }

  async function deleteContractFunc() {
    await deleteContract({
      jwt: props.state.global_user_data.data.token,
      id: deleteContractState.id,
    })
      .then((response) => {
        setDeleteAlert(false);
        setsuccess_dlg(true);
        setdynamic_title("Desativado");
        setdynamic_description("Contrato desativado");
      })
      .catch((error) => "");
  }

  return (
    <div>
      <MetaTags>
        <title>Contratos | Inteegra Plus</title>
      </MetaTags>
      {modalNewContract && (
        <ModalNewContract
          isOpen={modalNewContract}
          toggle={toggleNewContract}
          refetch={getAllContracts}
          data={contracts}
        />
      )}
      {modalEditContract && (
        <ModalEditContract
          isOpen={modalEditContract}
          toggle={toggleEditContract}
          editContract={editContract}
          refetch={getAllContracts}
          data={contracts}
        />
      )}
      {enableContractAlert && (
        <ModalEnableContract
          enableContractId={enableContractId}
          refetch={getAllContracts}
          setEnableContractAlert={setEnableContractAlert}
          nameContract={nameContract}
        />
      )}
      {deleteAlert && (
        <SweetAlert
          title={`Deseja desativar ${deleteContractState.name}?`}
          warning
          showCancel
          confirmBtnText="Sim"
          cancelBtnText="Não"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            deleteContractFunc();
          }}
          onCancel={() => setDeleteAlert(false)}
        >
          {/* Você não poderá reverter essa ação. */}
        </SweetAlert>
      )}
      {success_dlg ? (
        <SweetAlert
          success
          title={dynamic_title}
          onConfirm={() => {
            getAllContracts();
            setsuccess_dlg(false);
          }}
        >
          {dynamic_description}
        </SweetAlert>
      ) : null}

      {modalProducts && (
        <Products
          isOpen={modalProducts}
          toggle={() => setModalProducts(!modalProducts)}
        />
      )}

      {modalScreens && (
        <Screens
          isOpen={modalScreens}
          toggle={() => setModalScreens(!modalScreens)}
        />
      )}

      <Card>
        <CardBody>
          <CardTitle>Contratos</CardTitle>

          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <form
              className="app-search d-none d-lg-block events-input-search"
              onSubmit={(e) => e.preventDefault()}
            >
              <div className="position-relative">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Pesquisar..."
                  onChange={(e) => setSearch(e.target.value)}
                />
                <span className="bx bx-search-alt" />
              </div>
            </form>
            <div className="mobile-btn-section" style={{ width: "100%" }}>
              <Row className="mobile-btns-top">
                <form
                  className="app-search"
                  style={{ padding: "0px" }}
                  onSubmit={(e) => e.preventDefault()}
                >
                  <div className="position-relative">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Pesquisar..."
                      onChange={(e) => setSearch(e.target.value)}
                    />
                    <span className="bx bx-search-alt" />
                  </div>
                </form>
              </Row>

              <SectionComponent
                sectionName="Opções"
                setIsOpen={setIsOpen}
                isOpen={isOpen}
              />
              <Collapse isOpen={isOpen}>
                <Row className="mobile-btns-top">
                  <div onClick={() => setModalNewContract(true)}>Criar App</div>
                </Row>
              </Collapse>
            </div>
            <div className="header-btns-container">
              <Button
                color="secondary"
                className="btn-rounded waves-effect waves-light events-button-add"
                onClick={() => setEnabledContracts(!enabledContracts)}
              >
                {!enabledContracts
                  ? "Filtrar: Contratos Ativos"
                  : "Filtrar: Contratos Inativos"}
              </Button>
              <Button
                color="success"
                className="btn-rounded waves-effect waves-light events-button-add"
                onClick={() => setModalNewContract(true)}
              >
                Criar Contrato
              </Button>

              <div
                class="dropdown-div"
                onClick={() => setDropOption(!dropOption)}
              >
                <div
                  class="dropdown-content"
                  style={{ display: dropOption ? "block" : "none" }}
                >
                  <a
                    className="option-drop"
                    onClick={() => setModalProducts(true)}
                  >
                    Produtos
                  </a>
                  <a
                    className="option-drop"
                    onClick={() => setModalScreens(true)}
                  >
                    Telas
                  </a>
                </div>
                <span
                  style={{ display: "flex" }}
                  onClick={() => setDropOption(!dropOption)}
                >
                  <i
                    className="dripicons-gear"
                    style={{
                      fontSize: 18,
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  />
                </span>
              </div>
            </div>
          </div>
          <div className="table-responsive">
            <Table className="table mb-0">
              <thead className="table-light">
                <tr>
                  <th>Empresa</th>
                  <th>Produtos</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                {contracts &&
                  contracts
                    .filter((contract) => contract.name.toLocaleLowerCase())
                    .map((contract, i) => (
                      <tr key={i}>
                        <td>{contract.name}</td>
                        <td>
                          {contract.products.map((e) => e.name).join(", ")}
                        </td>
                        {!enabledContracts ? (
                          <td>
                            <i
                              className="mdi mdi-account-reactivate"
                              style={{
                                color: "green",
                                fontSize: 17,
                                cursor: "pointer",
                                marginLeft: 13,
                              }}
                              onClick={() => {
                                setContractProps(contract);
                                setEnableContractAlert(true);
                              }}
                            />
                          </td>
                        ) : (
                          <td key={i}>
                            <i
                              className="mdi mdi-pencil"
                              style={{
                                color: "green",
                                fontSize: 17,
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setEditContract(contract);
                                setModalEditContract(true);
                              }}
                            />
                            <i
                              className="mdi mdi-trash-can"
                              style={{
                                color: "red",
                                fontSize: 17,
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setDeleteContractState(contract);
                                setDeleteAlert(true);
                              }}
                            />
                          </td>
                        )}
                      </tr>
                    ))}
              </tbody>
            </Table>
          </div>
        </CardBody>
      </Card>
      <div className="bottom-navigation" style={{ justifyContent: "end" }}>
        <Pagination
          data={contracts}
          search={search}
          currentIndex={currentIndex}
          setCurrentIndex={setCurrentIndex}
          countData={countData}
          maxItemPerPage={itemsPerPage}
          setMaxItemPerPage={setItemsPerPage}
          maxPageButtonsToShow={3}
        />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return { state };
};

export default connect(mapStateToProps)(Contracts);
