import writeXlsxFile from "write-excel-file";
import { getSurveysReport } from "../../../../utils/services/superScreen";
import { formatData } from "../../../../utils/helpers/files/formatDate";

function formatDate(date) {
  const dateFormat = date.split("T")[0].split("-").reverse().join("/");
  const time = date.split("T")[1].slice(0, 5);
  return `${dateFormat} - ${time}`;
}

export async function createXlsx({
  jwt,
  idEvent,
  idSuperScreen,
  idReportSurvey,
  limit,
  search,
  countData,
  activeFilter,
  currentIndex,
  setFinishExport,
}) {
  const schema = [
    {
      column: "Id",
      type: String,
      value: (survey) => survey.id,
    },
    {
      column: "Nome do Participante",
      type: String,
      value: (survey) => survey.loginName,
    },
    {
      column: "Email do Participante",
      type: String,
      value: (survey) => survey.loginEmail,
    },
    {
      column: "Pergunta",
      type: String,
      value: (survey) => survey.question,
    },
    {
      column: "Resposta",
      type: String,
      value: (survey) => survey.answer,
    },
    {
      column: "Acertou",
      type: String,
      value: (survey) => (survey.hit ? "Sim" : "Não"),
    },
    {
      column: "Data e Hora",
      type: String,
      value: (survey) => formatDate(survey.createdAt),
    },
  ];

  const allRequestsPromises = [];
  const numberOfRequisitions = Math.ceil(countData / limit);

  for (let i = 1; i <= numberOfRequisitions; i += 1) {
    const request = await getSurveysReport(
      idEvent,
      idSuperScreen,
      idReportSurvey,
      currentIndex,
      limit,
      search,
      activeFilter,
      jwt
    );
    allRequestsPromises.push(request);
    setTimeout(() => {}, 500);

    // return

    const progressBar = document.querySelector(".export-progress");
    const percentageBar = document.querySelector(".export-percentage");

    progressBar.style.width = `${(i / numberOfRequisitions) * 100}%`;
    percentageBar.innerHTML = `${((i / numberOfRequisitions) * 100).toFixed(
      0
    )}%`;

    if (i === numberOfRequisitions) {
      setTimeout(() => {
        setFinishExport(true);
      }, 1000);
    }
  }

  const allDataInSingleArray = allRequestsPromises
    .map((e) => e.findOneTransmissionData.data)
    .flat();
  if (allDataInSingleArray) {
    await writeXlsxFile(allDataInSingleArray, {
      schema,
      fileName: "Enquetes.xlsx",
    });
  }
}
