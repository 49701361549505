import React from 'react';

import { Button } from 'reactstrap';

export default function ButtonDisplay({ attributes, saveForm }) {
  return (
    <div style={{width: '100%', display: 'flex', justifyContent: 'flex-end'}}>
      <Button
        className="displayed-label"
        style={{
          backgroundColor: attributes.btnColor,
        }}
        onClick={saveForm}
      >
        <span style={{color: attributes.fontColor}}>{attributes.btnText}</span>
      </Button>
    </div>
  )
}
