import writeXlsxFile from 'write-excel-file';
import { getAllUploadApp } from "../../../utils/services/uploadapp";

export async function createXlsx({ jwt, eventId, limit, search, totalItems, setFinishExport }) {
  const formatDate = (date) => {
    const dateFormat = date.split('T')[0].split('-').reverse().join('-');
    const time = date.split('T')[1].slice(0, 5);;
    return `${dateFormat} - ${time}`;
  }
  const schema = [
    {
      column: 'ID do Evento',
      type: String,
      value: e => e.event,
    },
    {
      column: 'Nome do Evento',
      type: String,
      value: e => e.eventName
    },
    {
      column: 'ID do Participante',
      type: String,
      value: e => e.loginId
    },
    {
      column: 'Nome do Participante',
      type: String,
      value: e => e.loginName,
    },
    {
      column: 'URL',
      type: String,
      value: e => e.url,
    },
    {
      column: 'Data e Hora',
      type: String,
      value: e => formatDate(e.createdAt),
    },
  ];

  const allRequestsPromises = [];
  const numberOfRequisitions = Math.ceil(totalItems / limit);

  for (let i = 1; i <= numberOfRequisitions; i += 1) {
    const request = await getAllUploadApp(
      jwt, i, limit, search, eventId
    );
    allRequestsPromises.push(request);
    setTimeout(() => { }, 500);

    const progressBar = document.querySelector('.export-progress');
    const percentageBar = document.querySelector('.export-percentage')

    progressBar.style.width = `${(i / numberOfRequisitions) * 100}%`;
    percentageBar.innerHTML = `${((i / numberOfRequisitions) * 100).toFixed(0)}%`;

    if (i === numberOfRequisitions) {
      setTimeout(() => {
        setFinishExport(true);
      }, 1000);
    }
  }

  const allDataInSingleArray = allRequestsPromises.map((e) => e.data.findAllUpload.data).flat();

  if (allDataInSingleArray) {
    await writeXlsxFile(allDataInSingleArray, {
      schema,
      fileName: 'download-questionarios.xlsx'
    })
  }
}