import axios from "axios";
import { store } from "../../store/configStore";
import { urlBase } from "../config";
import { setNewToken } from "../../store/actions/token";
import { isTokenExpired } from "./authToken";

const axiosInstance = axios.create({});

axiosInstance.interceptors.request.use(
  (config) => {
    const state = store.getState();
    const token = state.global_user_data?.data?.token;
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    if (
      error.response &&
      error.response.status === 401 &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      try {
        const state = store.getState();
        const refreshToken = state.global_user_data?.data?.refreshToken;

        if (!refreshToken) {
          return Promise.reject(error);
        }
        if (isTokenExpired) {
          const response = await axios.post(`${urlBase}/admin/refreshtoken`, {
            refreshToken: refreshToken,
          });

          if (response.status === 201 || response.status === 200) {
            const newToken = response.data.token.token;

            store.dispatch(setNewToken(newToken));
            console.log("token refreshed");
            axiosInstance.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${newToken}`;
            originalRequest.headers["Authorization"] = `Bearer ${newToken}`;

            return axiosInstance(originalRequest);
          } else {
            console.error("Failed to refresh token, status:", response.status);
            return Promise.reject(error);
          }
        }
      } catch (tokenRefreshError) {
        console.error("Token refresh error:", tokenRefreshError);
        return Promise.reject(tokenRefreshError);
      }
    }

    console.error("Request error:", error);
    return Promise.reject(error);
  }
);

export default axiosInstance;
