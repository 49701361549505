import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Table,
  Row,
  Collapse,
} from "reactstrap";
// service
import { getVehicleByStatus } from "../../../utils/services/vehicles";
// componentes
import RowTableStatus from "./rowTableStatus";
import Pagination from "../../../components/Paginationv3";
import useDebounce from "../../../utils/hooks/useDebounce";
import SearchFilters from "../../../components/SearchFilters";
import SectionComponent from "../../Apps/Personalization/SectionComponent";

export default function ModalViewStatus(props) {
  const { isOpen, toggle, statusSelected, idEvent } = props;
  // collapse
  const [collapseIsOpen, setCollapseIsOpen] = useState(false);
  // paginação
  const [currentIndex, setCurrentIndex] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [countData, setCountData] = useState(0);
  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounce(search, 300);
  // listagem
  const [dataList, setDataList] = useState([]);
  // filtros
  const [activeFilter, setActiveFilter] = useState("description");
  const columnsFilter = [
    { name: "Nome", value: "description", type: "text" },
    { name: "Origem", value: "origin", type: "text" },
    { name: "Destino", value: "destination", type: "text" },
    { name: "Data Saída", value: "departureDateTime", type: "date" },
    { name: "Data Chegada", value: "arrivalDateTime", type: "date" },
  ];

  function setActiveFilterHandle(columnActive) {
    setActiveFilter(columnActive);
    setSearch("");
  }

  function getListVehicles() {
    getVehicleByStatus({
      eventId: idEvent,
      status: statusSelected,
      offset: currentIndex,
      limit: itemsPerPage,
      search: debouncedSearch,
      columnSearch: activeFilter,
    })
      .then((res) => {
        // console.log("ok", res);
        if (res.data.message === "vehicle_by_status") {
          setDataList(res.data.findByIdData.data);
          setCountData(res.data.findByIdData.count);
        }
      })
      .catch((error) => console.log("erro", error));
  }

  useEffect(() => {
    if (statusSelected) {
      getListVehicles();
    }
  }, [statusSelected, debouncedSearch, currentIndex, itemsPerPage]);

  return (
    <>
      <Modal isOpen={isOpen} centered size="lg">
        <ModalHeader toggle={toggle}>Veículos por Status</ModalHeader>
        <ModalBody>
          <div>
            <Row
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: 10,
                width: "50px",
              }}
            >
              <SectionComponent
                sectionName="Filtros"
                setIsOpen={setCollapseIsOpen}
                isOpen={collapseIsOpen}
              />
            </Row>
            <Collapse isOpen={collapseIsOpen}>
              <SearchFilters
                columns={columnsFilter}
                activeFilter={activeFilter}
                setActiveFilterHandle={setActiveFilterHandle}
              />
            </Collapse>
          </div>

          <div
            style={{
              display: "flex",
              width: "100%",
              margin: "10px 0",
            }}
          >
            <form
              className="app-search d-none d-lg-block events-input-search"
              onSubmit={(e) => e.preventDefault()}
              style={{ width: "100%" }}
            >
              <div className="position-relative">
                <input
                  type={
                    activeFilter === "departureDateTime" ||
                    activeFilter === "arrivalDateTime"
                      ? "date"
                      : "text"
                  }
                  value={search}
                  className="form-control"
                  placeholder="Pesquisar..."
                  onChange={(e) => setSearch(e.target.value)}
                />
                <span className="bx bx-search-alt" />
              </div>
            </form>

            <div className="mobile-btn-section" style={{ width: "100%" }}>
              <Row className="mobile-btns-top">
                <form
                  className="app-search"
                  style={{ padding: "0px" }}
                  onSubmit={(e) => e.preventDefault()}
                >
                  <div className="position-relative">
                    <input
                      type={
                        activeFilter === "departureDateTime" ||
                        activeFilter === "arrivalDateTime"
                          ? "date"
                          : "text"
                      }
                      value={search}
                      className="form-control"
                      placeholder="Pesquisar..."
                      onChange={(e) => setSearch(e.target.value)}
                    />
                    <span className="bx bx-search-alt" />
                  </div>
                </form>
              </Row>
            </div>
          </div>

          <div className="table-responsive" style={{ margin: "20px 0" }}>
            <Table className="table mb-0">
              <thead className="table-light">
                <tr>
                  <th>Nome</th>
                  <th>Status</th>
                  <th>Ações</th>
                  <th></th>
                </tr>
              </thead>

              <tbody>
                {dataList.length > 0 &&
                  dataList.map((e, i) => <RowTableStatus e={e} />)}
              </tbody>
            </Table>
          </div>

          <div
            className="bottom-navigation"
            style={{ justifyContent: "flex-end" }}
          >
            <Pagination
              data={dataList}
              search={search}
              currentIndex={currentIndex}
              setCurrentIndex={setCurrentIndex}
              countData={countData}
              maxItemPerPage={itemsPerPage}
              setMaxItemPerPage={setItemsPerPage}
              maxPageButtonsToShow={3}
            />
          </div>
        </ModalBody>
      </Modal>
    </>
  );
}
