import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader, Col, Row, Button } from "reactstrap";

//redux
import { connect } from "react-redux";

import SweetAlert from "react-bootstrap-sweetalert";

//service
import { updateFeed } from "../../../utils/services/feed";
import { getUrlFiles } from "../../../utils/services/files";

import ButtonSpinner from "../../../components/Buttons/ButtonSpinner";

function EditFeed(props) {
  const { isOpen, toggle } = props;
  const [modalBackdrop, setModalBackdrop] = useState(false);
  //imagem
  const [imageView, setImageView] = useState(props.feedEdit.url);
  //confirmação
  const [success_dlg, setsuccess_dlg] = useState(false);
  const [textoErro, setTextoErro] = useState("");
  const [imageErro, setImageErro] = useState("");
  const [isLoading, setIsloading] = useState(false);

  function toggleBackdrop() {
    setModalBackdrop(!modalBackdrop);
  }

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      reader.onload = (e) => {
        setImageView(e.target.result);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  async function submitForm(e) {
    let img = "";
    if (
      e.target[1].value === "" &&
      e.target[2].files[0] === undefined &&
      imageView === ""
    ) {
      setTextoErro("O campo Texto é obrigatório.");
      return;
    } else setTextoErro("");

    if (
      e.target[1].value === "" &&
      e.target[2].files[0] === undefined &&
      imageView === ""
    ) {
      setImageErro("O campo Foto é obrigatório.");
      return;
    } else setImageErro("");

    let urlImg = props.feedEdit.url;
    let expireDate = props.feedEdit.expireDate;

    if (imageView === props.feedEdit.url) {
      img = props.feedEdit.url;
    } else img = e.target[2].files[0];

    var data = new FormData();
    data.append("files", img);
    data.append("folder", "feed");
    data.append("eventId", props.idEvent);

    if (imageView !== props.feedEdit.url) {
      await getUrlFiles(data, props.state.global_user_data.data.token)
        .then((response) => {
          response.map((e) => {
            urlImg = e.url;
            expireDate = e.expires;
          });
        })
        .catch((error) => {
          // console.log(error);
        });
    }
    const dataPost = {
      eventid: props.idEvent,
      title: "",
      text: e.target[1].value,
      url: urlImg,
      active: e.target[3].checked,
      fixed: e.target[4].checked,
      date: new Date(),
      // loginadmin_id: props.state.global_user_data.data.foundLogin.id,
      postsource: null,
      postid: props.feedEdit.id,
      expireDate: expireDate,
    };
    setIsloading(true);
    var feedData = await updateFeed(
      dataPost,
      props.state.global_user_data.data.token
    );
    setIsloading(false);
    if (feedData.message === "updated") {
      setsuccess_dlg(true);
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => {
        toggleBackdrop();
      }}
      autoFocus={true}
      centered={true}
    >
      {success_dlg ? (
        <SweetAlert
          success
          title={"Editado"}
          onConfirm={() => {
            props.getAllFeed();
            setsuccess_dlg(false);
            toggle();
          }}
        >
          {"Post editado com sucesso!"}
        </SweetAlert>
      ) : null}
      <ModalHeader toggle={toggle}>Editar Post</ModalHeader>
      <ModalBody>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            submitForm(e);
          }}
        >
          <Row>
            <Col lg={12}>
              <div>
                <label className="form-label">ID do Post</label>
                <input
                  className="form-control"
                  type="text"
                  defaultValue={props.feedEdit.id}
                  disabled
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg={12}>
              <div>
                <label className="form-label">Texto</label>
                <input
                  className="form-control"
                  type="text"
                  defaultValue={props.feedEdit.text}
                />
                <p style={{ color: "red" }}>{textoErro}</p>
              </div>
            </Col>
          </Row>

          <Row style={{ marginTop: 20 }}>
            <Col sm={6} style={{ display: "flex", alignItems: "center" }}>
              <label
                htmlFor="formFile"
                className="form-label"
                style={{ marginBottom: 0 }}
              >
                Foto
              </label>
              <label htmlFor="inputImage" style={{ marginBottom: 0 }}>
                <i className="bx bx-pencil" style={{ cursor: "pointer" }} />
              </label>

              <input
                id="inputImage"
                type={"file"}
                onChange={(e) => {
                  onImageChange(e);
                }}
                style={{ display: "none" }}
              />
              <p style={{ color: "red" }}>{imageErro}</p>
            </Col>

            <Col sm={6} style={{ display: "flex", alignItems: "center" }}>
              {imageView && (
                <img alt="edit" src={imageView} style={{ width: 70 }} />
              )}
            </Col>
          </Row>

          <Row style={{ marginTop: 20, marginBottom: 20 }}>
            <Col md={6}>
              <div className="form-check form-check-end">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  defaultChecked={props.feedEdit.active ? true : false}
                />
                <label>Visível</label>
              </div>
            </Col>

            <Col md={6}>
              <div className="form-check form-check-end">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  defaultChecked={props.feedEdit.fixed ? true : false}
                />
                <label>Fixado</label>
              </div>
            </Col>
          </Row>

          <Row
            style={{
              width: "100%",
              margin: "auto",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              color="success"
              className="btn btn-danger waves-effect waves-light"
              style={{ width: 80, marginRight: 5 }}
              onClick={() => toggle()}
            >
              Fechar
            </Button>

            <ButtonSpinner isLoading={isLoading} title={"Salvar"} />
          </Row>
        </form>
      </ModalBody>
    </Modal>
  );
}

const mapStateToProps = function (state) {
  return { state: state };
};

export default connect(mapStateToProps)(EditFeed);
