import React, { useState, useRef, useEffect } from "react";

import {
  Table,
  Card,
  CardBody,
  CardTitle,
  Button,
  Collapse,
  Row,
} from "reactstrap";

import SweetAlert from "react-bootstrap-sweetalert";

//redux
import { connect } from "react-redux";

//paginação
import Pagination from "../../../components/Paginationv3";
// import Pagination from "./Pagination";

//useQuery
import { useQueries } from "react-query";

//componentes
import ModalNewFlight from "./ModalNewFlight";
import ModalEditFlight from "./ModalEditFlight";
import ImportFlight from "./ImportFlight";

import { useLocation } from "react-router-dom";

//service
import {
  getFlight,
  deleteFlight,
  deleteAllFlights,
} from "../../../utils/services/flight";
import { getParticipantsLogistic } from "../../../utils/services/participants";
import useDebounce from "../../../utils/hooks/useDebounce";
import { createXlsx } from "./createXlsx";
import WaitExport from "../../../components/WaitExport";
import SectionComponent from "../../Apps/Personalization/SectionComponent";
import SearchFilters from "../../../components/SearchFilters";

function Voo(props) {
  const location = useLocation();

  //pesquisa
  const [search, setSearch] = useState("");
  //modais
  const [modalNewFlight, setModalNewFlight] = useState(false);
  const [modalEditFlight, setModalEditFlight] = useState(false);
  const [modalImportFlight, setModalImportFlight] = useState(false);
  const [voo, setVoo] = useState([]);
  //deletar voo
  const [deleteVooState, setDeleteVooState] = useState();
  const [confirm_alert, setconfirm_alert] = useState(false);
  const [confirmAllDisabled, setConfirmAllDisabled] = useState(false);
  const [success_dlg, setsuccess_dlg] = useState(false);
  const [dynamic_title, setdynamic_title] = useState("");
  const [dynamic_description, setdynamic_description] = useState("");
  //editar voo
  const [editFlight, setEditFlight] = useState();
  //paginação
  const [currentIndex, setCurrentIndex] = useState(1);
  //state de participants
  // const [participants, setParticipants] = useState([]);
  const participants = useRef(null);
  const [countData, setCountData] = useState(1);
  // itens por pagina
  const [itemsPerPage, setItemsPerPage] = useState(10);

  // export in progress
  const [exportInProgress, setExportInProgress] = useState(false);
  const [finishExport, setFinishExport] = useState(false);
  // collapse
  const [isOpen, setIsOpen] = useState(false);
  const [collapseIsOpen, setCollapseIsOpen] = useState(false);
  const [activeFilter, setActiveFilter] = useState("login");

  const columnsFilter = [
    { name: "Participante", value: "login", type: "text" },
    { name: "Companhia", value: "company", type: "text" },
    { name: "Origem", value: "origin", type: "text" },
    { name: "Destino", value: "destination", type: "text" },
    { name: "Data Embarque", value: "boardingdate", type: "date" },
    { name: "Data Chegada", value: "arrivaldate", type: "date" },
  ];

  function setActiveFilterHandle(columnActive) {
    setActiveFilter(columnActive);
    setSearch("");
  }
  const debouncedSearch = useDebounce(search, 300);
  const reverseDate = () => {
    const reverse = debouncedSearch
      .split("/")
      .reverse()
      .join("/")
      .replace(/\//g, "-");
    return reverse;
  };

  function treatmentSearch() {
    if (
      columnsFilter.filter((item) => item.value === activeFilter)[0].type ===
      "date"
    ) {
      return reverseDate();
    }
    return debouncedSearch;
  }
  function toggleNewFlight() {
    setModalNewFlight(!modalNewFlight);
  }

  function toggleEditFlight() {
    setModalEditFlight(!modalEditFlight);
  }

  function toggleImportFlight() {
    setModalImportFlight(!modalImportFlight);
  }

  function formatData(startDate) {
    var data = new Date(startDate + "T00:00:00-03:00");
    var dia = String(data.getDate()).padStart(2, "0");
    var mes = String(data.getMonth() + 1).padStart(2, "0");
    var ano = data.getFullYear();
    var dataAtual = dia + "/" + mes + "/" + ano;

    return dataAtual;
  }

  async function confirmDelete() {
    await deleteFlight({
      jwt: props.state.global_user_data.data.token,
      id: deleteVooState.id,
    }).then((response) => {
      setconfirm_alert(false);
      setsuccess_dlg(true);
      setdynamic_title("Deletado");
      setdynamic_description("Voo deletado");
    });
  }

  async function confirmDeleteAllFlights() {
    await deleteAllFlights({
      jwt: props.state.global_user_data.data.token,
      eventId: location.state.idEvent,
    }).then((response) => {
      setConfirmAllDisabled(false);
      setsuccess_dlg(true);
      setdynamic_title("Deletados");
      setdynamic_description("Voos deletados");
    });
  }

  const data = useQueries([
    {
      queryKey: ["getAllParticipants" + location.state.idEvent, 1],
      queryFn: async () => {
        const partForm = await getParticipantsLogistic({
          jwt: props.state.global_user_data.data.token,
          idEvent: location.state.idEvent,
          offset: 0,
          limit: 0,
          search: "",
        });

        participants.current = partForm?.data?.data;
      },
    },
    {
      queryKey: [
        "getAllVoo" +
          location.state.idEvent +
          currentIndex +
          itemsPerPage +
          debouncedSearch,
        2,
      ],
      notifyOnChangeProps: ["currentIndex"],
      queryFn: async () => {
        const vooData = await getFlight({
          jwt: props.state.global_user_data.data.token,
          idEvent: location.state.idEvent,
          offset: currentIndex > 0 ? currentIndex : 1,
          limit: itemsPerPage,
          search: treatmentSearch(),
          columnSearch: activeFilter,
        });

        setCountData(vooData.findOneFlightData.count);
        setVoo(vooData.findOneFlightData.data);
      },
    },
  ]);

  useEffect(() => {
    getFlight({
      jwt: props.state.global_user_data.data.token,
      idEvent: location.state.idEvent,
      offset: currentIndex > 0 ? currentIndex : 1,
      limit: itemsPerPage,
      search: debouncedSearch,
    }).then((vooData) => {
      setCountData(vooData.findOneFlightData.count);
      setVoo(vooData.findOneFlightData.data);
    });

    getParticipantsLogistic({
      jwt: props.state.global_user_data.data.token,
      idEvent: location.state.idEvent,
      offset: 0,
      limit: 0,
      search: "",
    }).then((partForm) => {
      participants.current = partForm?.data?.data;
    });
  }, []);

  return (
    <div>
      {modalNewFlight && (
        <ModalNewFlight
          isOpen={modalNewFlight}
          toggle={toggleNewFlight}
          idEvent={location.state.idEvent}
          data={data}
          participants={participants?.current}
        />
      )}
      {modalEditFlight && (
        <ModalEditFlight
          isOpen={modalEditFlight}
          toggle={toggleEditFlight}
          idEvent={location.state.idEvent}
          editFlight={editFlight}
          data={data}
          participants={participants?.current}
        />
      )}
      <ImportFlight
        isOpen={modalImportFlight}
        toggle={toggleImportFlight}
        token={props.state.global_user_data.data.token}
        idEvent={location.state.idEvent}
        data={data}
      />

      {confirm_alert ? (
        <SweetAlert
          title={`Deseja deletar o Voo de ${deleteVooState.login.nome}?`}
          warning
          showCancel
          confirmBtnText="Sim"
          cancelBtnText="Não"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            confirmDelete();
          }}
          onCancel={() => setconfirm_alert(false)}
        >
          <a>Você não poderá reverter essa ação.</a>
        </SweetAlert>
      ) : null}

      {confirmAllDisabled ? (
        <SweetAlert
          title={`Deseja remover todos os Voos?`}
          warning
          showCancel
          confirmBtnText="Sim"
          cancelBtnText="Não"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            confirmDeleteAllFlights();
          }}
          onCancel={() => setConfirmAllDisabled(false)}
        >
          <a>Você não poderá reverter essa ação.</a>
        </SweetAlert>
      ) : null}
      {success_dlg ? (
        <SweetAlert
          success
          title={dynamic_title}
          onConfirm={() => {
            data[0].refetch();
            data[1].refetch();
            setsuccess_dlg(false);
          }}
        >
          {dynamic_description}
        </SweetAlert>
      ) : null}
      <WaitExport
        isOpen={exportInProgress}
        name="Voos"
        preposicao="dos"
        finishExport={finishExport}
        setFinishExport={setFinishExport}
        setExportInProgress={setExportInProgress}
      />

      <Card>
        <CardBody>
          <CardTitle>Voo</CardTitle>
          <div>
            <Row
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: 10,
                width: "50px",
              }}
            >
              <SectionComponent
                sectionName="Filtros"
                setIsOpen={setCollapseIsOpen}
                isOpen={collapseIsOpen}
              />
            </Row>
            <Collapse isOpen={collapseIsOpen}>
              <SearchFilters
                columns={columnsFilter}
                activeFilter={activeFilter}
                setActiveFilterHandle={setActiveFilterHandle}
              />
            </Collapse>
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              marginBottom: 10,
            }}
          >
            <form
              className="app-search d-none d-lg-block events-input-search"
              onSubmit={(e) => e.preventDefault()}
            >
              <div className="position-relative">
                <input
                  type={
                    activeFilter === "boardingdate" ||
                    activeFilter === "arrivaldate"
                      ? "date"
                      : "text"
                  }
                  value={search}
                  className="form-control"
                  placeholder="Pesquisar..."
                  onChange={(e) => setSearch(e.target.value)}
                />
                <span className="bx bx-search-alt" />
              </div>
            </form>
            <div className="mobile-btn-section" style={{ width: "100%" }}>
              <Row className="mobile-btns-top">
                <form
                  className="app-search"
                  style={{ padding: "0px" }}
                  onSubmit={(e) => e.preventDefault()}
                >
                  <div className="position-relative">
                    <input
                      type="text"
                      value={search}
                      className="form-control"
                      placeholder="Pesquisar..."
                      onChange={(e) => setSearch(e.target.value)}
                    />
                    <span className="bx bx-search-alt" />
                  </div>
                </form>
              </Row>
              <SectionComponent
                sectionName="Opções"
                setIsOpen={setIsOpen}
                isOpen={isOpen}
              />
              <Collapse isOpen={isOpen}>
                {voo.length > 0 && (
                  <Row className="mobile-btns-top">
                    <div
                      onClick={() =>
                        createXlsx({
                          jwt: props.state.global_user_data.data.token,
                          idEvent: location.state.idEvent,
                          limit: 100,
                          countData,
                          setFinishExport,
                        }) > setExportInProgress(true)
                      }
                    >
                      Exportar Voo
                    </div>
                  </Row>
                )}
                <Row className="mobile-btns-top">
                  <div onClick={() => setModalImportFlight(!modalImportFlight)}>
                    Importar Voo
                  </div>
                </Row>
                <Row className="mobile-btns-top">
                  <div onClick={() => setModalNewFlight(true)}>
                    Adicionar Voo
                  </div>
                </Row>
              </Collapse>
            </div>
            <div className="header-btns-container">
              {voo.length > 0 && (
                <Button
                  color="success"
                  className="btn-rounded waves-effect waves-light events-button-add"
                  onClick={() =>
                    createXlsx({
                      jwt: props.state.global_user_data.data.token,
                      idEvent: location.state.idEvent,
                      limit: 100,
                      countData,
                      setFinishExport,
                    }) > setExportInProgress(true)
                  }
                >
                  Exportar Voo
                </Button>
              )}
              <Button
                color="success"
                className="btn-rounded waves-effect waves-light events-button-add"
                onClick={() => setModalImportFlight(!modalImportFlight)}
              >
                Importar Voo
              </Button>
              <Button
                color="success"
                className="btn-rounded waves-effect waves-light events-button-add"
                onClick={() => setModalNewFlight(true)}
              >
                Adicionar Voo
              </Button>
            </div>
          </div>

          <div className="table-responsive">
            <Table className="table mb-0">
              <thead className="table-light">
                <tr>
                  <th>Participante</th>
                  <th>Companhia</th>
                  <th>Origem</th>
                  <th>Destino</th>
                  <th>Data/hora embarque</th>
                  <th>Data/hora chegada</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                {voo &&
                  voo
                    ?.filter(
                      (vooData) => vooData.login.nome?.toLocaleLowerCase()
                      // ?.indexOf(search.toLocaleLowerCase()) > -1
                    )
                    ?.map((e, i) => (
                      <tr key={i}>
                        <td>{e.login.nome}</td>
                        <td>{e.company}</td>
                        <td>{e.origin}</td>
                        <td>{e.destination}</td>
                        <td>
                          {formatData(e.boardingdate)} {e.boardingtill}
                        </td>
                        <td>
                          {formatData(e.arrivaldate)} {e.arrivaltime}
                        </td>
                        <td>
                          <i
                            className="mdi mdi-pencil"
                            style={{
                              color: "green",
                              fontSize: 17,
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setEditFlight(e);
                              setModalEditFlight(!modalEditFlight);
                            }}
                          />{" "}
                          <i
                            className="mdi mdi-trash-can"
                            style={{
                              color: "red",
                              fontSize: 17,
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setDeleteVooState(e);
                              setconfirm_alert(true);
                            }}
                          />
                        </td>
                      </tr>
                    ))}
              </tbody>
            </Table>
          </div>
        </CardBody>
      </Card>
      <div
        className="bottom-navigation"
        style={{
          justifyContent: `${voo?.length > 0 ? "space-between" : "end"}`,
        }}
      >
        {voo.length > 0 && (
          <Button
            color="danger"
            className="btn-rounded waves-effect waves-light events-button-add"
            onClick={() => setConfirmAllDisabled(true)}
          >
            Limpar Todos Voos
          </Button>
        )}
        {/* 
      Fornecer os 8 parametros obrigatórios;
      ** data - a array contendo as informações da tabela
      ** search - o state do termo de pesquisa
      ** currentIndex - posição atual do index
      ** setCurrentIndex - função para atualizar a posição atual do index
      ** countData - quantidade total de itens
      ** maxItemPerPage - estado quantidade de de itens por página
      ** setMaxItemPerPage - função que troca o estado com a quantidade maxima de itens por página 
      ** maxPageButtonsToShow - quantidade de botões qua vão aparecer para a pessoa clicar, somente números impares
      ** 5 = vai de 1 até 5 / 7 vai de 1 até 7 
      */}
        <Pagination
          data={voo}
          search={search}
          currentIndex={currentIndex}
          setCurrentIndex={setCurrentIndex}
          countData={countData}
          maxItemPerPage={itemsPerPage}
          setMaxItemPerPage={setItemsPerPage}
          maxPageButtonsToShow={3}
        />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return { state };
};

export default connect(mapStateToProps)(Voo);
