import writeXlsxFile from "write-excel-file";
import { getMatch } from "../../../utils/services/match";

export async function createXlsx({
  jwt,
  idEvent,
  limit,
  countData,
  codeQrcode,
  empresa,
  cargo,
  code1,
  code2,
  code3,
  setFinishExport,
}) {
  const roomType = {
    4: 'Quadruplo',
    3: 'Triplo',
    2: 'Duplo',
  };

  const schema = [
    {
      column: 'ID Hóspede 1',
      type: String,
      value: (match) => (match.login_id_1 == null ? '' : match.login_id_1),
    },
    {
      column: 'Nome Hóspede 1',
      type: String,
      value: (match) => (match.login_nome_1 == null ? '' : match.login_nome_1),
    },
    {
      column: 'Email Hóspede 1',
      type: String,
      value: (match) =>
        match.login_email_1 == null ? '' : match.login_email_1,
    },
    {
      column: codeQrcode ? 'Código QRCode Hóspede 1' : null,
      type: String,
      value: (match) => (codeQrcode ? match.codeqrcode_1 : null),
    },

    {
      column: empresa ? 'Empresa Hóspede 1' : null,
      type: String,
      value: (match) => (empresa ? match.empresa_1 : null),
    },
    {
      column: cargo ? 'Cargo Hóspede 1' : null,
      type: String,
      value: (match) => (cargo ? match.cargo_1 : null),
    },

    {
      column: code1 ? 'Código Interno 1 - Hóspede 1' : null,
      type: String,
      value: (match) => (code1 ? match.codigo_interno_1_1 : null),
    },
    {
      column: code2 ? 'Código Interno 2 - Hóspede 1' : null,
      type: String,
      value: (match) => (code2 ? match.codigo_interno_2_1 : null),
    },
    {
      column: code3 ? 'Código Interno 3 - Hóspede 1' : null,
      type: String,
      value: (match) => (code3 ? match.codigo_interno_3_1 : null),
    },
    {
      column: 'Status Hóspede 1',
      type: String,
      value: (match) =>
        match.login_email_1 == null
          ? ''
          : match.login_status_1 === 'completed'
          ? 'Completo'
          : 'Pendente',
    },
    {
      column: 'ID Hóspede 2',
      type: String,
      value: (match) => (match.login_id_2 == null ? '' : match.login_id_2),
    },
    {
      column: 'Nome Hóspede 2',
      type: String,
      value: (match) => (match.login_nome_2 == null ? '' : match.login_nome_2),
    },
    {
      column: 'Email Hóspede 2',
      type: String,
      value: (match) =>
        match.login_email_2 == null ? '' : match.login_email_2,
    },
    {
      column: codeQrcode ? 'Código QRCode Hóspede 2' : null,
      type: String,
      value: (match) => (codeQrcode ? match.codeqrcode_2 : null),
    },

    {
      column: empresa ? 'Empresa Hóspede 2' : null,
      type: String,
      value: (match) => (empresa ? match.empresa_2 : null),
    },
    {
      column: cargo ? 'Cargo Hóspede 2' : null,
      type: String,
      value: (match) => (cargo ? match.cargo_2 : null),
    },
    {
      column: code1 ? 'Código Interno 1 - Hóspede 2' : null,
      type: String,
      value: (match) => (code1 ? match.codigo_interno_1_2 : null),
    },
    {
      column: code2 ? 'Código Interno 2 - Hóspede 2' : null,
      type: String,
      value: (match) => (code2 ? match.codigo_interno_2_2 : null),
    },
    {
      column: code3 ? 'Código Interno 3 - Hóspede 2' : null,
      type: String,
      value: (match) => (code3 ? match.codigo_interno_3_2 : null),
    },
    {
      column: 'Status Hóspede 2',
      type: String,
      value: (match) =>
        match.login_email_2 == null
          ? ''
          : match.login_status_2 === 'completed'
          ? 'Completo'
          : 'Pendente',
    },
    {
      column: 'ID Hóspede 3',
      type: String,
      value: (match) => (match.login_id_3 == null ? '' : match.login_id_3),
    },
    {
      column: 'Nome Hóspede 3',
      type: String,
      value: (match) => (match.login_nome_3 == null ? '' : match.login_nome_3),
    },
    {
      column: 'Email Hóspede 3',
      type: String,
      value: (match) =>
        match.login_email_3 == null ? '' : match.login_email_3,
    },
    {
      column: codeQrcode ? 'Código QRCode Hóspede 3' : null,
      type: String,
      value: (match) => (codeQrcode ? match.codeqrcode_3 : null),
    },

    {
      column: empresa ? 'Empresa Hóspede 3' : null,
      type: String,
      value: (match) => (empresa ? match.empresa_3 : null),
    },
    {
      column: cargo ? 'Cargo Hóspede 3' : null,
      type: String,
      value: (match) => (cargo ? match.cargo_3 : null),
    },
    {
      column: code1 ? 'Código Interno 1 - Hóspede 3' : null,
      type: String,
      value: (match) => (code1 ? match.codigo_interno_1_3 : null),
    },
    {
      column: code2 ? 'Código Interno 2 - Hóspede 3' : null,
      type: String,
      value: (match) => (code2 ? match.codigo_interno_2_3 : null),
    },
    {
      column: code3 ? 'Código Interno 3 - Hóspede 3' : null,
      type: String,
      value: (match) => (code3 ? match.codigo_interno_3_3 : null),
    },
    {
      column: 'Status Hóspede 3',
      type: String,
      value: (match) =>
        match.login_email_3 == null
          ? ''
          : match.login_status_3 === 'completed'
          ? 'Completo'
          : 'Pendente',
    },
    {
      column: 'ID Hóspede 4',
      type: String,
      value: (match) => (match.login_id_4 == null ? '' : match.login_id_4),
    },
    {
      column: 'Nome Hóspede 4',
      type: String,
      value: (match) =>
        match.login_email_1 == null
          ? ''
          : match.login_nome_4 == null
          ? ''
          : match.login_nome_4,
    },
    {
      column: 'Email Hóspede 4',
      type: String,
      value: (match) =>
        match.login_email_4 == null ? '' : match.login_email_4,
    },
    {
      column: codeQrcode ? 'Código QRCode Hóspede 4' : null,
      type: String,
      value: (match) => (codeQrcode ? match.codeqrcode_4 : null),
    },

    {
      column: empresa ? 'Empresa Hóspede 4' : null,
      type: String,
      value: (match) => (empresa ? match.empresa_4 : null),
    },
    {
      column: cargo ? 'Cargo Hóspede 4' : null,
      type: String,
      value: (match) => (cargo ? match.cargo_4 : null),
    },
    {
      column: code1 ? 'Código Interno 1 - Hóspede 4' : null,
      type: String,
      value: (match) => (code1 ? match.codigo_interno_1_4 : null),
    },
    {
      column: code2 ? 'Código Interno 2 - Hóspede 4' : null,
      type: String,
      value: (match) => (code2 ? match.codigo_interno_2_4 : null),
    },
    {
      column: code3 ? 'Código Interno 3 - Hóspede 4' : null,
      type: String,
      value: (match) => (code3 ? match.codigo_interno_3_4 : null),
    },
    {
      column: 'Status Hóspede 4',
      type: String,
      value: (match) =>
        match.login_email_4 == null
          ? ''
          : match.login_status_4 === 'completed'
          ? 'Completo'
          : 'Pendente',
    },
    {
      column: 'Tipo Quarto',
      type: String,
      value: (match) => roomType[match.room_size],
    },
  ];
  const numberOfRequisitions = Math.ceil(countData / limit);

  let allRequests = [];
  for (let i = 1; i <= numberOfRequisitions; i++) {
    const request = await getMatch({
      eventId: idEvent,
      jwt: jwt,
      offset: i,
      limit: limit,
      search: '',
    });
    allRequests.push(...request.data);

    setTimeout(() => {}, 500);
    const progressBar = document.querySelector('.export-progress');
    const percentageBar = document.querySelector('.export-percentage');

    progressBar.style.width = `${(i / numberOfRequisitions) * 100}%`;
    percentageBar.innerHTML = `${((i / numberOfRequisitions) * 100).toFixed(
      0
    )}%`;

    if (i === numberOfRequisitions) {
      setTimeout(() => {
        setFinishExport(true);
      }, 1000);
    }
  }
  await writeXlsxFile(allRequests, {
    schema: schema.filter((e) => e.column != null),
    fileName: 'RelatorioQuartos.xlsx',
  });
}
