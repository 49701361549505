import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader, Button } from "reactstrap";
// service
import { getSalesEmail, resendEmail } from "../../../../utils/services/sales";
// alerta
import SweetAlert from "react-bootstrap-sweetalert";

export default function ModalListEmail(props) {
  const { isOpen, toggle, sale, token } = props;
  const [data, setData] = useState([]);
  // carregamento
  const [loading, setLoading] = useState(true);
  // selecionado para ação
  const [selected, setSelected] = useState({});
  // alertas
  const [confirmResendAlert, setConfirmResendAlert] = useState(false);
  const [confirmedResendAlert, setConfirmedResendAlert] = useState(false);

  function getList() {
    getSalesEmail({
      jwt: token,
      idSale: sale.id,
      // idSale: "ab0ad7cc-242c-4163-b27b-844c6a7f0bb0",
    })
      .then((res) => {
        // console.log(res);
        if (res.emails && res.emails.length > 0) {
          setData(res.emails);
        }

        setLoading(false);
      })
      .catch((error) => console.log("erro", error));
  }

  function resendEmailFunc() {
    resendEmail({ jwt: token, id: selected.id })
      .then((res) => {
        if (res.message === "Success!") {
          setConfirmResendAlert(false);
          setConfirmedResendAlert(true);
        }
      })
      .catch((error) => console.log("erro", error));
  }

  function downloadEmail(email) {
    var blobFile = new Blob([email], { type: "text/html" });

    const url = URL.createObjectURL(blobFile);

    var link = document.createElement("a");
    link.download = "email";
    link.target = "_blank";
    link.href = url;
    link.click();
  }

  useEffect(() => {
    getList();
  }, []);

  return (
    <>
      {confirmResendAlert && (
        <SweetAlert
          title={`Deseja reenviar o email para ${selected.email} ?`}
          warning
          showCancel
          confirmBtnText="Sim"
          cancelBtnText="Não"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            resendEmailFunc();
          }}
          onCancel={() => setConfirmResendAlert(false)}
        >
          <a>Você não poderá reverter essa ação!</a>
        </SweetAlert>
      )}

      {confirmedResendAlert && (
        <SweetAlert
          success
          title="Confirmado"
          onConfirm={() => {
            setConfirmedResendAlert(false);
          }}
        >
          Email reenviado com sucesso!
        </SweetAlert>
      )}

      <Modal isOpen={isOpen} centered>
        <ModalHeader toggle={toggle}>Emails Enviados</ModalHeader>
        <ModalBody>
          {loading ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div className="circle-loading" />
            </div>
          ) : data.length > 0 ? (
            data.map((e, i) => (
              <>
                <div className="div-email-sended">
                  <a>
                    <span style={{ fontWeight: "bold" }}>Email para:</span>{" "}
                    {e.email}
                  </a>

                  <div
                    style={{
                      width: "100%",
                      borderTop: "1px solid #d8d8d8",
                      borderBottom: "1px solid #d8d8d8",
                      margin: "20px 0",
                    }}
                  >
                    <iframe
                      title="_"
                      id="frameEmailSended"
                      className="frame-fill"
                      srcdoc={e.html_content}
                      width="100%"
                      frameborder="0"
                      scrolling="yes"
                    />
                  </div>

                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Button
                      color="success"
                      onClick={() => {
                        setSelected(e);
                        setConfirmResendAlert(true);
                      }}
                    >
                      Reenviar email
                    </Button>

                    <Button
                      color="success"
                      style={{ marginLeft: 10 }}
                      onClick={() => {
                        downloadEmail(e.html_content);
                      }}
                    >
                      Baixar
                    </Button>
                  </div>
                </div>
              </>
            ))
          ) : (
            <div style={{ textAlign: "center" }}>
              <a>Sem Dados</a>
            </div>
          )}
        </ModalBody>
      </Modal>
    </>
  );
}
