import React, { useState, useEffect } from 'react';
import { Modal, ModalBody, ModalHeader, Row, Col, Button } from 'reactstrap';
import { connect } from 'react-redux';

function ModalDeleteSession(props) {
  const { isOpen, toggle, sessionItem, deleteSession } = props;
  const [modalBackdrop, setModalBackdrop] = useState(false);

  function toggleBackdrop() {
    setModalBackdrop(!modalBackdrop);
  }

  async function submitForm(e) {
    e.preventDefault();

    await deleteSession(sessionItem.sessionId);
    toggle();
  }

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => toggleBackdrop()}
      autoFocus={true}
      centered={true}
      size="sm"
    >
      <ModalBody>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            submitForm(e);
          }}
        >
          <Row
            style={{
              marginBottom: 10,
              textAlign: 'center',
              fontSize: '1.4rem',
            }}
          >
            <Col md={12}>
              <label className="form-label">Deseja excluir a sessão?</label>
            </Col>
          </Row>
          <Row
            style={{ marginBottom: 10, textAlign: 'center', fontSize: '1rem' }}
          >
            <Col md={12}>
              <label className="form-label">{sessionItem.title}</label>
            </Col>
          </Row>

          <Row
            style={{
              width: '100%',
              margin: 'auto',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Button
              color="danger"
              style={{ width: 80, marginRight: 10 }}
              onClick={() => toggle()}
            >
              Fechar
            </Button>
            <Button
              color="success"
              className="btn btn-success waves-effect waves-light"
              style={{ width: 80 }}
              type="submit"
            >
              Excluir
            </Button>
          </Row>
        </form>
      </ModalBody>
    </Modal>
  );
}

const mapStateToProps = function (state) {
  return { state: state };
};

export default connect(mapStateToProps)(ModalDeleteSession);
