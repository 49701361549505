import React, { useState, useEffect } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
// gerador de imagem
import html2canvas from "html2canvas";
// nuvem de palavras
import { TagCloud } from "react-tagcloud";
// service
import {
  getWordCloudChat,
  getWordCloudAsks,
} from "../../../../utils/services/dashboardSuperScreen";

export default function WordCloud(props) {
  const { isOpen, toggle } = props;
  // imagem para baixar
  const [img, setImg] = useState();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  function getImage() {
    html2canvas(document.querySelector("#capture")).then((canvas) => {
      setImg(
        canvas
          .toDataURL("image/jpeg")
          .replace("image/jpeg", "image/octet-stream")
      );
    });
  }

  async function getAllWordCloudChat() {
    await getWordCloudChat({
      size: 20,
      eventId: props.eventId,
      idChat: props.idChat,
      mobileapps: props.mobileapps,
    }).then((res) => {
      // console.log("ok chat", res);
      if (res.success) {
        setData(res.data);
        // getImage();
        setTimeout(() => {
          if (document.querySelector("#capture")) {
            getImage();
          }
        }, 100);
      }
    });
    // .catch((error) => console.log("erro", error));

    setLoading(false);
  }

  async function getAllWordCloudAsks() {
    await getWordCloudAsks({
      size: 20,
      eventId: props.eventId,
      transmissionID: props.transmissionID,
      mobileapps: props.mobileapps,
    }).then((res) => {
      // console.log("ok question", res);
      if (res.success) {
        setData(res.data);

        setTimeout(() => {
          if (document.querySelector("#capture")) {
            getImage();
          }
        }, 500);
      }
    });
    // .catch((error) => console.log("erro", error));

    setLoading(false);
  }

  useEffect(() => {
    if (props.type === "chat") {
      getAllWordCloudChat();
    } else if (props.type === "asks") {
      getAllWordCloudAsks();
    } else return;
  }, []);

  return (
    <Modal isOpen={isOpen} centered autoFocus size="lg">
      <ModalHeader toggle={toggle}>Nuvem de Palavras</ModalHeader>
      <ModalBody>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div style={{ maxWidth: 600 }} id="capture">
            {loading ? (
              <a
                className="downloadCloud"
                style={{ backgroundColor: "white", width: 120 }}
              >
                <i className="bx bx-loader bx-spin font-size-16 align-middle me-2" />
                Carregando
              </a>
            ) : (
              <TagCloud
                minSize={20}
                maxSize={60}
                tags={data}
                // onClick={(tag) => alert(`'${tag.value}' was selected!`)}
                shuffle
              />
            )}
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            {!loading && data.length > 0 && img && (
              <a
                className="downloadCloud"
                download="Nuvem.jpg"
                target="_blank"
                href={img}
              >
                Baixar
              </a>
            )}
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
}
