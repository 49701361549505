const colaboradorInscrito = [
  "NOME COMPLETO",
  "NOME CRACHA",
  "EMAIL",
  "DATA DE NASCIMENTO",
  "RG",
  "CPF",
  "GÊNERO",
  "CELULAR",
  "ESTADO",
  "CIDADE",
  "CARGO",
  "ÁREA",
  "CENTRO DE CUSTO",
  "LOCAL DE TRABALHO",
  "PODE TER CAMISA?",
  "TAMANHO",
  "MODELO DE CAMISA",
  "POSSUI ALGUMA RESTRIÇÃO ALIMENTAR",
  "QUAL RESTRIÇÃO ALIMENTAR?",
  "POSSUI ALGUMA NECESSIDADE DE ACESSIBILIDADE",
  "QUAL NECESSIDADE DE ACESSIBILIDADE?",
  "PRECISA DE HOSPEDAGEM?",
  "TIPO DE QUARTO",
  "NOME DO ACOMPANHANTE",
  "DATA CHECK-IN",
  "DATA CHECK-OUT",
  "DATA DE CADASTRO",
  "DATA DE ATUALIZAÇÃO",
];

const colaboradorPendente = [
  "NOME",
  "E-MAIL",
  "GÊNERO",
  "CARGO",
  "ÁREA",
  "CENTRO DE CUSTO",
  "LOCAL DE TRABALHO",
  "CAMISA",
  "PRECISA DE HOSPEDAGEM",
  "TIPO DE QUARTO",
];

const revendaInscrito = [
  "CNPJ",
  "RAZÃO SOCIAL",
  "NOME COMPLETO",
  "NOME CRACHA",
  "EMAIL",
  "DATA DE NASCIMENTO",
  "CPF",
  "CELULAR",
  "ESTADO",
  "CIDADE",
  "POSSUI ALGUMA RESTRIÇÃO ALIMENTAR",
  "QUAL RESTRIÇÃO ALIMENTAR?",
  "POSSUI ALGUMA NECESSIDADE DE ACESSIBILIDADE",
  "QUAL NECESSIDADE DE ACESSIBILIDADE?",
  "ACOMPANHANTE",
  "CÓDIGO SAP",
  "CARGO",
  "OUTRO CARGO",
  "SOU PROPRIETÁRIO",
  "ESCRITÓRIO DE VENDAS",
  "EQUIPE DE VENDAS",
  "CENTRO DE LUCRO",
  "DATA DE CADASTRO",
  "DATA DE ATUALIZAÇÃO",
];

const revendaPendente = [
  "CNPJ",
  "RAZÃO SOCIAL",
  "E-MAIL",
  "CELULAR",
  "UF",
  "CENTRO DE LUCRO",
  "CÓDIGO SAP",
  "EQUIPE DE VENDAS DA CONTA",
  "ESCRITÓRIO DE VENDAS",
];

const convidadoInscrito = [
  "NOME_COMPLETO",
  "NOME_CRACHA",
  "EMAIL",
  "DATA_DE_NASCIMENTO",
  "CPF",
  "CELULAR",
  "EMPRESA",
  "ESTADO",
  "CIDADE",
  "POSSUI_ALGUMA_RESTRICAO_ALIMENTAR",
  "QUAL_RESTRICAO_ALIMENTAR",
  "POSSUI_ALGUMA_NECESSIDADE_DE_ACESSIBILIDADE",
  "QUAL_NECESSIDADE_DE_ACESSIBILIDADE",
  "DATA_CADASTRO",
  "DATA_ATUALIZACAO"
];

const convidadoPendente = [
  "NOME",
  "EMAIL",
  "EMPRESA"
];


const sheets = ['Colaborador', 'Colaborador Pendente', 'Revenda', 'Revenda  Pendente', 'Convidado', 'Convidado Pendente'];

export {
  colaboradorInscrito,
  colaboradorPendente,
  revendaInscrito,
  revendaPendente,
  convidadoInscrito,
  convidadoPendente,
  sheets
};
