import React, { useState, useEffect } from "react";
import { Modal, ModalBody, ModalHeader, Table } from "reactstrap";
//redux
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
// service
import { getParticipants } from "../../../utils/services/participants";

import useDebounce from "../../../utils/hooks/useDebounce";

function ModalSearchUser(props) {
  const { isOpen, toggle, setSelected } = props;
  // token e location
  const token = props.state.global_user_data.data.token;
  const location = useLocation();
  // search
  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounce(search, 300);
  //   list
  const [list, setList] = useState([]);

  async function getUsers() {
    const data = {
      jwt: token,
      idEvent: location.state.idEvent,
      offset: 1,
      limit: 10,
      search: search,
      columnSearch: "email",
      status: "ATIVO",
    };

    await getParticipants(data).then((res) => {
      if (res.data.count > 0) {
        setList(res.data.data);
      } else setList([]);
    });
    // .catch((error) => console.log("erro", error));
  }

  useEffect(() => {
    getUsers();
  }, [debouncedSearch]);

  return (
    <>
      <Modal isOpen={isOpen}>
        <ModalHeader toggle={toggle}>Pesquisar Participante</ModalHeader>
        <ModalBody>
          <input
            className="form-control"
            type="text"
            placeholder="Pesquise pelo email"
            style={{ marginBottom: 20 }}
            onChange={(e) => setSearch(e.target.value)}
          />

          <div className="table-responsive">
            <Table className="table mb-0">
              <thead className="table-light">
                <tr>
                  <th>Nome</th>
                  <th>Email</th>
                </tr>
              </thead>

              <tbody>
                {list.length > 0 &&
                  list.map((e, i) => (
                    <tr
                      style={{ cursor: "pointer" }}
                      onClick={() => setSelected(e) > toggle()}
                    >
                      <td>{e.nome}</td>
                      <td>{e.email}</td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => {
  return { state };
};

export default connect(mapStateToProps)(ModalSearchUser);
