import React, { useCallback, useEffect, useState } from "react";
import {
  Table,
  Card,
  CardBody,
  CardTitle,
  Button,
  Collapse,
  Row,
} from "reactstrap";

import SweetAlert from "react-bootstrap-sweetalert";

import SectionComponent from "../../Apps/Personalization/SectionComponent";
import ModalNewSorteio from "./ModalNewSorteio";
import ModalEditSorteio from "./EditSorteio";
import ModalParticipants from "./ParticipantsSorteio";
import { useLocation } from "react-router-dom";
import moment from "moment";

import {
  getSorteio,
  deleteSorteio,
  processSorteio,
  listParticipants,
  sendEmailOnDeleteSorteio,
  sendEmailToWinnerSorteio,
} from "../../../utils/services/sorteios";
import WaiPrizes from "./WaitPrizes";
import ModalWinnersSorteio from "./ParticipantsWinners";

import Pagination from "../../../components/Paginationv3";
import DeleteButton from "../../../components/Buttons/DeleteButton";
import { useSelector } from "react-redux";

export default function Certificate(props) {
  const location = useLocation();
  const { token: jwt } = useSelector((state) => state.global_user_data.data);
  const { id: eventId } = location.state.event;
  //search and paginations
  const [winnersParticipant, setWinnersParticipant] = useState(false);
  const [search, setSearch] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [success_edit_sorteio, setsuccess_edit_sorteio] = useState(false);
  // collapse
  const [isOpen, setIsOpen] = useState(false);
  const [loadingPrizes, setLoadingPrizes] = useState(false);
  const [finishPrizes, setFinishPrizes] = useState(false);
  //new sorteio
  const [modalNewSorteio, setmodalNewSorteio] = useState(false);
  const [modalEditSorteio, setModalEditSorteio] = useState(false);
  const [modalParticipants, setModalParticipants] = useState(false);
  const [modalDice, setModalDice] = useState(false);
  const [deletedSorteio, setDeletSorteio] = useState(null);
  const [selectedSorteioEdit, setSelectedSorteioEdit] = useState(null);

  const [sorteioSuccess, setSorteioSuccess] = useState(false);
  const [lowParticipantsError, setLowParticipantsError] = useState(false);
  //deletar evento
  const [confirm_alert, setconfirm_alert] = useState(false);
  const [success_dlg, setsuccess_dlg] = useState(false);

  const [offset, setOffset] = useState(1);
  const [limit, setLImit] = useState(10);

  const [items, setItems] = useState([]);

  async function sendEmailToWinner({ id, jwt }) {
    await sendEmailToWinnerSorteio(id, jwt);
    // .then((res) => console.log("enviado ganhador", res))
    // .catch((error) => console.log("erro ganhador", error));
  }

  const getData = useCallback(async () => {
    const { findAllSorteiosData } = await getSorteio({
      id: eventId,
      jwt: jwt,
      offset: offset,
      limit: limit,
      search: search,
    });
    setItems(findAllSorteiosData.data);
    setTotalItems(findAllSorteiosData.count);
  }, [jwt, props.idEvent, currentPage, itemsPerPage, search, offset, limit]);

  async function sendEmail() {
    await sendEmailOnDeleteSorteio(deletedSorteio.id, jwt).then((res) => {
      if (res.message === "emails_sended") {
        deleteSorteioFunc();
      }
    });
    // .catch((error) => console.log("erro", error));
  }

  async function deleteSorteioFunc() {
    await deleteSorteio({ id: deletedSorteio.id, jwt: jwt }).then((res) => {
      setconfirm_alert(false);
      setsuccess_dlg(true);
    });
  }

  function checkProductExists(product) {
    if (location.state) {
      const search = location.state.event.product.filter(
        (e) => e.name === product
      );

      if (search[0]) {
        return true;
      } else return false;
    }
  }

  useEffect(() => {
    getData();
  }, [offset, limit, search]);

  return (
    <div>
      {modalNewSorteio && (
        <ModalNewSorteio
          isOpen={modalNewSorteio}
          toggle={() => setmodalNewSorteio(!modalNewSorteio)}
          eventId={props.idEvent}
          {...props}
          refetch={() => {
            getData();
            setSorteioSuccess(true);
          }}
        />
      )}
      {modalEditSorteio && (
        <ModalEditSorteio
          isOpen={modalEditSorteio}
          toggle={() => setModalEditSorteio(!modalEditSorteio)}
          eventId={props.idEvent}
          selectedSorteioEdit={selectedSorteioEdit}
          refetch={() => {
            setsuccess_edit_sorteio(true);
            getData();
          }}
        />
      )}
      {modalParticipants && (
        <ModalParticipants
          isOpen={modalParticipants}
          toggle={() => setModalParticipants(!modalParticipants)}
          eventId={eventId}
          selectedSorteioEdit={selectedSorteioEdit}
          refetch={() => getData()}
        />
      )}
      {loadingPrizes && <WaiPrizes isOpen={loadingPrizes} position={"100%"} />}
      {modalDice ? (
        <SweetAlert
          title="Deseja sortear?"
          warning
          showCancel
          confirmBtnText="Sim"
          cancelBtnText="Não"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={async () => {
            const { findAllSorteiosData } = await listParticipants({
              id: selectedSorteioEdit.id,
              jwt: jwt,
              offset: offset,
              limit: limit,
              search: "",
            });
            if (findAllSorteiosData.count > 0) {
              setModalDice(false);
              setLoadingPrizes(true);
              processSorteio({
                id: selectedSorteioEdit.id,
                jwt: jwt,
              }).then((r) => {
                setTimeout(() => {
                  setLoadingPrizes(false);
                  setWinnersParticipant(true);
                  getData();
                  sendEmailToWinner({ id: selectedSorteioEdit.id, jwt: jwt });
                }, 1000);
              });
            } else {
              setModalDice(false);
              setLowParticipantsError(true);
            }
          }}
          onCancel={() => setModalDice(false)}
        >
          Você não poderá reverter essa ação!
        </SweetAlert>
      ) : null}

      {winnersParticipant && (
        <ModalWinnersSorteio
          isOpen={winnersParticipant}
          toggle={() => setWinnersParticipant(!winnersParticipant)}
          eventId={props.idEvent}
          refetch={() => console.log("refetch")}
          selectedSorteioEdit={selectedSorteioEdit}
        />
      )}

      {confirm_alert ? (
        <SweetAlert
          title="Deseja deletar esse sorteio?"
          warning
          showCancel
          confirmBtnText="Sim"
          cancelBtnText="Não"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            if (checkProductExists("Distribuição de Cotas")) {
              sendEmail();
            } else deleteSorteioFunc();
          }}
          onCancel={() => setconfirm_alert(false)}
        >
          Você não poderá reverter essa ação!
        </SweetAlert>
      ) : null}

      {success_dlg ? (
        <SweetAlert
          success
          title="Deletado"
          onConfirm={() => {
            setsuccess_dlg(false);
            getData();
          }}
        >
          Sorteio deletado com sucesso
        </SweetAlert>
      ) : null}

      {success_edit_sorteio ? (
        <SweetAlert
          success
          title="Editado"
          onConfirm={() => {
            setsuccess_edit_sorteio(false);
          }}
        >
          Sorteio editado com sucesso
        </SweetAlert>
      ) : null}

      {lowParticipantsError && (
        <SweetAlert
          /* success */
          warning
          title="Falha"
          onConfirm={() => {
            setLowParticipantsError(false);
          }}
        >
          Sorteio não possui participantes.
        </SweetAlert>
      )}

      {sorteioSuccess ? (
        <SweetAlert
          success
          title="Criado"
          onConfirm={() => {
            setSorteioSuccess(false);
          }}
        >
          Sorteio criado com sucesso
        </SweetAlert>
      ) : null}
      <Card>
        <CardBody>
          <CardTitle>Sorteios</CardTitle>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              marginBottom: 10,
            }}
          >
            <form
              className="app-search d-none d-lg-block events-input-search"
              onSubmit={(e) => e.preventDefault()}
            >
              <div className="position-relative">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Pesquisar..."
                  onChange={(e) => setSearch(e.target.value)}
                />
                <span className="bx bx-search-alt" />
              </div>
            </form>
            <div className="header-btns-container">
              <Button
                color="success"
                className="btn-rounded waves-effect waves-light events-button-add"
                onClick={() => setmodalNewSorteio(true)}
              >
                Adicionar Sorteio
              </Button>
            </div>
          </div>
          <div className="table-responsive">
            <Table className="table mb-0">
              <thead className="table-light">
                <tr>
                  <th>Sessão</th>
                  <th>Quantidade de Cotas</th>
                  <th>Data Inicio</th>
                  <th>Data Encerramento</th>
                  <th>Status</th>
                  <th>Data do Cadastro</th>
                  <th>Data do Sorteio</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                {items.length > 0 &&
                  items.map((e, i) => (
                    <tr key={i}>
                      <td>
                        {e.session.title}{" "}
                        {moment.utc(e.session.startDate).format("DD/MM/YYYY")}{" "}
                        {e.session.startHour}
                      </td>
                      <td>{e.quotasAmount}</td>
                      <td>
                        {moment
                          .utc(e.startRegistration)
                          .format("DD [de] MMM. [às] HH:mm")}
                      </td>
                      <td>
                        {moment
                          .utc(e.endRegistration)
                          .format("DD [de] MMM. [às] HH:mm")}
                      </td>
                      <td>
                        <span
                          className="tagDriver"
                          style={{
                            whiteSpace: "nowrap",
                            backgroundColor:
                              e.status == "SORTEADO"
                                ? "#8BC34A"
                                : e.status == "NAO_SORTEADO"
                                ? "#FF9800"
                                : "#607D8B",
                          }}
                        >
                          {e.status == "SORTEADO"
                            ? "Sorteado"
                            : e.status == "NAO_SORTEADO"
                            ? "Não Sorteado"
                            : "Cancelado"}
                        </span>
                      </td>
                      <td>
                        {moment
                          .utc(e.createdAt)
                          .format("DD [de] MMM. [às] HH:mm")}
                      </td>
                      <td>
                        {e.status == "SORTEADO"
                          ? moment
                              .utc(e.drawDate)
                              .format("DD [de] MMM. [às] HH:mm")
                          : ""}
                      </td>
                      <td>
                        {e.status == "NAO_SORTEADO" && (
                          <i
                            className="mdi mdi-pencil"
                            title="Editar"
                            style={{
                              margin: "0 3px",
                              color: "green",
                              fontSize: 17,
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setSelectedSorteioEdit(e);
                              setModalEditSorteio(true);
                            }}
                          />
                        )}
                        <i
                          className="mdi mdi-account-multiple"
                          title="Participantes"
                          style={{
                            margin: "0 3px",
                            color: "blue",
                            fontSize: 17,
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setSelectedSorteioEdit(e);
                            setModalParticipants(true);
                          }}
                        />
                        {e.status != "CANCELADO" && (
                          <i
                            className="mdi mdi-dice-multiple"
                            title="Sortear"
                            style={{
                              margin: "0 3px",
                              color: "blue",
                              fontSize: 17,
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              if (e.status == "SORTEADO") {
                                setSelectedSorteioEdit(e);
                                setWinnersParticipant(!winnersParticipant);
                              } else {
                                setSelectedSorteioEdit(e);
                                setModalDice(true);
                              }
                            }}
                          />
                        )}

                        {e.status == "NAO_SORTEADO" && (
                          // <i
                          //   className="mdi mdi-trash-can"
                          //   style={{
                          //     color: "red",
                          //     fontSize: 17,
                          //     margin: "0 3px",
                          //     cursor: "pointer",
                          //   }}
                          //   onClick={() => {
                          //     setDeletSorteio(e);
                          //     setconfirm_alert(true);
                          //   }}
                          //   title="Excluir"
                          // />
                          <DeleteButton
                            onClick={() => {
                              setDeletSorteio(e);
                              setconfirm_alert(true);
                            }}
                            color="red"
                            fontSize={17}
                          />
                        )}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
        </CardBody>
      </Card>
      <div className="bottom-navigation" style={{ justifyContent: "end" }}>
        {/* 
        Fornecer os 4 parametros obrigatórios;
        ** data - a array contendo as informações da tabela
        ** search - o state do termo de pesquisa
        ** currentIndex - posição atual do index
        ** setCurrentIndex - função para atualizar a posição atual do index
        */}
        <Pagination
          data={items}
          search={search}
          currentIndex={offset}
          setCurrentIndex={setOffset}
          countData={totalItems}
          maxItemPerPage={itemsPerPage}
          setMaxItemPerPage={setLImit}
          maxPageButtonsToShow={3}
        />
      </div>
    </div>
  );
}
