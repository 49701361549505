import React, { useCallback, useEffect, useState } from "react";

import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Collapse,
  Row,
  Table,
} from "reactstrap";

import Pagination from "../../../../../components/Paginationv3";
import SectionComponent from "../../../../Apps/Personalization/SectionComponent";

import { useLocation } from "react-router-dom";
// service
import { getParticipants } from "../../../../../utils/services/reports";
import { createXlsx } from "./createXlsx";
import WaitExport from "../../../../../components/WaitExport";
import SearchFilters from "../../../../../components/SearchFilters";
import useDebounce from "../../../../../utils/hooks/useDebounce";

export default function ReportParticipants(props) {
  const location = useLocation();

  const idEvent = location.state.idEvent;

  const [participants, setParticipants] = useState([]);
  const [search, setSearch] = useState("");

  // collapse
  const [isCollapse, setIsCollapse] = useState(false);

  //Pagination
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);

  // export
  const [exportInProgress, setExportInProgress] = useState(false);
  const [finishExport, setFinishExport] = useState(false);

  const [collapseIsOpen, setCollapseIsOpen] = useState(false);
  const [activeFilter, setActiveFilter] = useState("name");

  const debouncedSearch = useDebounce(search, 300);

  const columnsFilter = [
    { name: "Nome", value: "name", type: "text" },
    { name: "Email", value: "email", type: "text" },
    { name: "Arquivo", value: "file", type: "text" },
    { name: "Data", value: "date", type: "date" },
  ];

  function setActiveFilterHandle(columnActive) {
    setActiveFilter(columnActive);
    setSearch("");
  }
  const reverseDate = () => {
    const reverse = debouncedSearch
      .split("/")
      .reverse()
      .join("/")
      .replace(/\//g, "-");
    return reverse;
  };
  function treatmentSearch() {
    if (
      columnsFilter.filter((item) => item.value === activeFilter)[0].type ===
      "date"
    ) {
      return reverseDate();
    }
    return debouncedSearch;
  }
  const formatDate = (datedownload, timeDownload) => {
    try {
      const date = datedownload.split("T")[0].split("-").reverse().join("/");
      return `${date} ${timeDownload}`;
    } catch (error) {
      return datedownload;
    }
  };

  const allParticipants = useCallback(async () => {
    const response = await getParticipants({
      idEvent,
      offset: currentPage,
      limit: itemsPerPage,
      search: treatmentSearch(),
      columnSearch: activeFilter,
    });
    // console.log(response);
    setParticipants(response.data.files);
    setTotalItems(response.data.total);
  }, [debouncedSearch, currentPage, itemsPerPage, idEvent]);

  useEffect(() => {
    allParticipants();
  }, [allParticipants]);
  return (
    <div>
      <WaitExport
        isOpen={exportInProgress}
        preposicao="do"
        name="Relatório de Participantes"
        finishExport={finishExport}
        setFinishExport={setFinishExport}
        setExportInProgress={setExportInProgress}
      />
      <Card>
        <CardBody>
          <CardTitle>Relatório de Participantes</CardTitle>
          <div>
            <Row
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: 10,
                width: "50px",
              }}
            >
              <SectionComponent
                sectionName="Filtros"
                setIsOpen={setCollapseIsOpen}
                isOpen={collapseIsOpen}
              />
            </Row>
            <Collapse isOpen={collapseIsOpen}>
              <SearchFilters
                columns={columnsFilter}
                activeFilter={activeFilter}
                setActiveFilterHandle={setActiveFilterHandle}
              />
            </Collapse>
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <form
              className="app-search d-none d-lg-block events-input-search"
              onSubmit={(e) => e.preventDefault()}
            >
              <div className="position-relative">
                <input
                  type={activeFilter === "date" ? "date" : "text"}
                  value={search}
                  className="form-control"
                  placeholder="Pesquisar..."
                  onChange={(e) => setSearch(e.target.value)}
                />
                <span className="bx bx-search-alt" />
              </div>
            </form>
            {participants.length > 0 && (
              <div className="mobile-btn-section" style={{ width: "100%" }}>
                <Row className="mobile-btns-top">
                  <form
                    className="app-search"
                    style={{ padding: "0px" }}
                    onSubmit={(e) => e.preventDefault()}
                  >
                    <div className="position-relative">
                      <input
                        type={activeFilter === "date" ? "date" : "text"}
                        value={search}
                        className="form-control"
                        placeholder="Pesquisar..."
                        onChange={(e) => setSearch(e.target.value)}
                      />
                      <span className="bx bx-search-alt" />
                    </div>
                  </form>
                </Row>
                <SectionComponent
                  sectionName="Opções"
                  setIsOpen={setIsCollapse}
                  isOpen={isCollapse}
                />
                <Collapse isOpen={isCollapse}>
                  <Row className="mobile-btns-top">
                    <div
                      onClick={() => {
                        createXlsx({
                          idEvent,
                          countData: totalItems,
                          setFinishExport,
                        });
                        setExportInProgress(true);
                      }}
                    >
                      Exportar Relatório
                    </div>
                  </Row>
                </Collapse>
              </div>
            )}
            <div className="header-btns-container">
              {participants.length > 0 && (
                <Button
                  color="success"
                  className="btn-rounded waves-effect waves-light events-button-add"
                  onClick={() => {
                    createXlsx({
                      idEvent,
                      countData: totalItems,
                      setFinishExport,
                    });
                    setExportInProgress(true);
                  }}
                >
                  Exportar Relatório
                </Button>
              )}
            </div>
          </div>
          <div className="table-responsive">
            <Table className="table mb-0">
              <thead className="table-light">
                <tr>
                  <th>Nome do Participante</th>
                  <th>Email do Participante</th>
                  <th>Nome do Arquivo</th>
                  <th>Data e Hora do Download</th>
                </tr>
              </thead>
              <tbody>
                {participants.length > 0 &&
                  participants.map((participant, index) => (
                    <tr key={index}>
                      <td>{participant.loginname}</td>
                      <td>{participant.loginemail}</td>
                      <td>{participant.filename}</td>
                      <td>
                        {formatDate(
                          participant.datedownload,
                          participant.timedownload
                        )}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
        </CardBody>
      </Card>
      <div className="bottom-navigation" style={{ justifyContent: "end" }}>
        {/* 
        Fornecer os 4 parametros obrigatórios;
        ** data - a array contendo as informações da tabela
        ** search - o state do termo de pesquisa
        ** currentIndex - posição atual do index
        ** setCurrentIndex - função para atualizar a posição atual do index
        */}
        <Pagination
          data={participants}
          // search={search}
          currentIndex={currentPage}
          setCurrentIndex={setCurrentPage}
          countData={totalItems}
          maxItemPerPage={itemsPerPage}
          setMaxItemPerPage={setItemsPerPage}
          maxPageButtonsToShow={3}
        />
      </div>
    </div>
  );
}
