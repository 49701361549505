import React, { useEffect, useState, useRef } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Col,
  Row,
  Card,
  CardText,
  Form,
  CardBody,
} from "reactstrap";
import Dropzone from "react-dropzone";
import SweetAlert from "react-bootstrap-sweetalert";
import readXlsxFile from "read-excel-file";
import ModeloUpload from "../../../assets/statics/modelo-importação-cupom.xlsx";
import { formatBytes } from "../../../utils/helpers/files/formatBytes";
import {
  allExcelColumns,
  findRowsWithError,
  excelColumnsPtBr,
} from "./importValidation";
import { createMatchImport } from "../../../utils/services/match";
import ModalExcelRows from "./ModalExcelRows";
import { createCupomImport } from "../../../utils/services/promo";

export default function ModalImportCupom(props) {
  const { isOpen, toggle, eventId, jwt, list } = props;
  const [modalBackdrop, setModalBackdrop] = useState(false);
  const [selectedFiles, setselectedFiles] = useState([]);
  const [success_dlg, setsuccess_dlg] = useState(false);
  const [imgError, setImgError] = useState("");
  const [totalRows, setTotalRows] = useState(0);
  const [totalSended, setTotalSended] = useState(0);
  const [btnSend, setBtnSend] = useState(false);
  const [btnCancel, setBtnCancel] = useState(false);
  const importActivated = useRef(false);

  // modal de erros no arquivo xlsx
  const [modalErrors, setModalErrors] = useState(false);
  const [errors, setErrors] = useState([]);

  let timerID = null;

  function toggleBackdrop() {
    setModalBackdrop(!modalBackdrop);
  }

  useEffect(() => {
    setselectedFiles([]);
    setImgError("");
  }, [isOpen]);

  function handleAcceptedFiles(files) {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    setselectedFiles(files);
    setTotalRows(0);
    setTotalSended(0);
    setBtnSend(true);
    clearTimeout(timerID);
  }

  function cancelImport() {
    importActivated.current = false;
    setselectedFiles([]);
    setTotalRows(0);
    setTotalSended(0);
    setBtnSend(false);
    setBtnCancel(false);
  }

  async function uploadService() {
    try {
      importActivated.current = true;
      clearTimeout(timerID);
      setBtnSend(false);
      setBtnCancel(true);

      const schema = {
        Código: { prop: "code", type: String },
        Quantidade: { prop: "quantityAvailable", type: Number },
        TipoDesconto: { prop: "valueType", type: String },
        "Valor Desconto %": { prop: "value", type: Number },
        Validade: { prop: "expireAt", type: Date },
        "Hora Validade": { prop: "validityHour", type: String },
        Ingressos: {
          prop: "products",
          type: (value) => value.split(",").map((item) => item.trim()),
        },
      };

      let count = 0;
      const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

      const readXlsx = async () => {
        try {
          if (!selectedFiles.length || !selectedFiles[0]) {
            throw new Error("Nenhum arquivo selecionado.");
          }

          const { rows } = await readXlsxFile(selectedFiles[0], { schema });

          if (!rows || !Array.isArray(rows)) {
            throw new Error(
              "Erro ao ler as linhas do arquivo Excel ou arquivo vazio."
            );
          }

          const validateRows = rows.map((row, i) => allExcelColumns(row, i));
          const rowsWithError = findRowsWithError(validateRows);

          setTotalRows(validateRows.length);

          if (rowsWithError.length > 0 || validateRows.length === 0) {
            setErrors(rowsWithError);
            setModalErrors(true);
            return;
          }

          for (let i = 0; i < validateRows.length; i++) {
            await sleep(100);
            await importCupomService(validateRows[i], i, validateRows.length);
          }
        } catch (error) {
          console.error("Erro ao ler o arquivo Excel:", error);
          setErrors([
            "Erro ao ler o arquivo Excel. Por favor, tente novamente.",
          ]);
          setModalErrors(true);
        }
      };

      readXlsx();

      async function importCupomService(row, index, totalRows) {
        try {
          await createCupomImport({
            eventId,
            code: row.code,
            quantityAvailable: row.quantityAvailable,
            valueType: row.valueType,
            value: row.value,
            expireAt: row.expireAt,
            validityHour: row.validityHour,
            products: row.products,
            jwt,
          }).then((e) => {
            console.log("RESPONSE", e.id);
            if (e.id) {
              setsuccess_dlg(true);
              setTotalSended(count++);
              list();
            }

            if (totalRows === ++index) {
              setBtnCancel(false);
              list();
            }

            if (count === totalRows || (count === 2 && totalRows === 1)) {
              setsuccess_dlg(true);
              list();
              props.data[0].refetch();
              props.data[1].refetch();
            }
          });
        } catch (error) {
          console.error("Erro ao importar cupom:", error);
          setErrors((prevErrors) => [
            ...prevErrors,
            `Erro na linha ${index + 1}: ${error.message}`,
          ]);
        }
      }
    } catch (error) {
      console.error("Erro no processo de upload:", error);
      setErrors(["Erro no processo de upload. Por favor, tente novamente."]);
      setModalErrors(true);
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => toggleBackdrop()}
      autoFocus={true}
      centered={true}
    >
      {success_dlg ? (
        <SweetAlert
          success
          title={"Upload"}
          onConfirm={() => {
            toggle();
            setsuccess_dlg(false);
          }}
        >
          {"O arquivo foi enviado com sucesso!"}
        </SweetAlert>
      ) : null}

      {modalErrors && (
        <ModalExcelRows
          toggle={() => setModalErrors(false)}
          rows={errors}
          columnNames={excelColumnsPtBr}
        />
      )}
      <ModalHeader toggle={toggle}>Importar Cupons</ModalHeader>
      <ModalBody className="m-1 p-0">
        <Card className="p-1">
          <CardBody className="m-1 p-2">
            <CardText className="mb-2 p-10">
              O arquivo a ser importado deve seguir este{" "}
              <a href={ModeloUpload} download="modelo_importação_cupom.xlsx">
                Modelo
              </a>
              , não modifique seu cabeçalho.
            </CardText>

            <Form>
              {selectedFiles.length === 0 && (
                <Dropzone
                  onDrop={(acceptedFiles) => {
                    handleAcceptedFiles(acceptedFiles);
                  }}
                >
                  {({ getRootProps, getInputProps }) => (
                    <div className="dropzone">
                      <div
                        className="dz-message needsclick mt-1"
                        {...getRootProps()}
                      >
                        <input {...getInputProps()} />
                        <div className="mb-4">
                          <i className="display-4 text-muted bx bxs-cloud-upload" />
                          <h4>Arraste o arquivo ou clique aqui e selecione.</h4>
                        </div>
                      </div>
                    </div>
                  )}
                </Dropzone>
              )}
              <div className="dropzone-previews mt-0" id="file-previews">
                {selectedFiles.map((f, i) => {
                  return (
                    <Card
                      className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                      key={i + "-file"}
                    >
                      <div className="p-1">
                        <Row className="align-items-center">
                          <Col xs="11">
                            <strong>{`Nome: ${f.name} - Tamanho: ${f.formattedSize}`}</strong>
                          </Col>
                          <Col xs="1">
                            <span
                              style={{
                                fontSize: "16px",
                                color: "red",
                                fontWeight: "700",
                              }}
                              className="mdi mdi-close"
                              onClick={() => setselectedFiles([])}
                            />
                          </Col>
                        </Row>
                        {importActivated.current && (
                          <Row className="align-items-center">
                            <Col>
                              <strong>{`Total enviado: ${totalSended}/${totalRows}`}</strong>
                            </Col>
                          </Row>
                        )}
                      </div>
                    </Card>
                  );
                })}
              </div>
            </Form>

            <div className="text-center mt-3">
              <p style={{ color: "red" }}>{imgError}</p>
              <button
                type="button"
                disabled={!btnSend}
                className="btn btn-primary waves-effect waves-light"
                onClick={() => uploadService()}
              >
                Enviar Cupons
              </button>
              <button
                type="button"
                className="btn btn-danger mx-1"
                disabled={!btnCancel}
                onClick={() => cancelImport()}
              >
                Cancelar
              </button>
            </div>
          </CardBody>
        </Card>
      </ModalBody>
    </Modal>
  );
}
