import React from 'react';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';

export default function ErrorModal({ toggle, rows, isOpen, formName }) {
  return (
    <Modal isOpen={isOpen}>
      <ModalHeader toggle={() => toggle(false)}>Corrija os erros abaixo</ModalHeader>
      <ModalBody>
        <div style={{ marginTop: 15}}>
          {!formName && <p>O campo Nome do formulário é obrigatório</p>}
          {rows.length > 0 && rows.map((row) => {
            if (row.cols.some((col) => !col.input)) {
              return (
                <p key={row.row}>
                  Linha {row.row + 1}, coluna(s) {row.cols.filter((col) => !col.input).map((col) => col.col + 1).join(', ')}:
                  A coluna está vazia.
                </p>
              )
            }
            if (row.cols.some((col) => !col.attributes)) {
              return (
                <p key={row.row}>
                  Linha {row.row + 1}, coluna(s) {row.cols.filter((col) => !col.attributes).map((col) => col.col + 1).join(', ')}:
                  Altere as informações do componente.
                </p>
              )
            }
            return "";
          })}
        </div>
        <div className="save-btn-form-container">
          <Button
            color="success"
            className="btn btn-success waves-effect waves-light save-btn-form"
            onClick={() => toggle(false)}
          >
            Sair
          </Button>
        </div>
      </ModalBody>
    </Modal>
  )
}
