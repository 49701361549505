import writeXlsxFile from "write-excel-file";
import { getSalesAnalitics } from "../../../../utils/services/sales";

export async function createXlsx({
  token,
  search,
  eventId,
  limit,
  columnSearch,
  countData,
  setFinishExport,
}) {
  const schema = [
    {
      column: "ID da Transação",
      type: String,
      value: (sale) => sale.transactionid,
    },
    {
      column: "Nome Participante",
      type: String,
      value: (sale) => sale.nome,
    },
    {
      column: "Email do Participante",
      type: String,
      value: (sale) => sale.email,
    },
    {
      column: "Ingresso Comprado",
      type: String,
      value: (sale) => sale.product,
    },
    {
      column: "Valor Pago",
      type: String,
      value: (sale) => `R$ ${sale.amount}`,
    },
  ];

  const allRequestsPromises = [];
  const numberOfRequisitions = Math.ceil(countData / limit);

  for (let i = 1; i <= numberOfRequisitions; i += 1) {
    const request = await getSalesAnalitics({
      jwt: token,
      search,
      eventId,
      currentIndex: i,
      itemsPerPage: limit,
      columnSearch,
    });
    // }).then((response) => console.log(response));
    // return;
    allRequestsPromises.push(request);
    setTimeout(() => {}, 500);

    const progressBar = document.querySelector(".export-progress");
    const percentageBar = document.querySelector(".export-percentage");

    progressBar.style.width = `${(i / numberOfRequisitions) * 100}%`;
    percentageBar.innerHTML = `${((i / numberOfRequisitions) * 100).toFixed(
      0
    )}%`;

    if (i === numberOfRequisitions) {
      setTimeout(() => {
        setFinishExport(true);
      }, 1000);
    }
  }

  const allDataInSingleArray = allRequestsPromises
    .map((e) => e.transactions)
    .flat();
  if (allDataInSingleArray) {
    await writeXlsxFile(allDataInSingleArray, {
      schema,
      fileName: "vendasAnaliticas.xlsx",
    });
  }
}
