import React, { useState, useRef, useEffect } from "react";
import {
  Table,
  Card,
  CardBody,
  CardTitle,
  Button,
  Collapse,
  Row,
} from "reactstrap";
import { useLocation } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";

//redux
import { connect } from "react-redux";

//paginação
import Pagination from "../../../components/Paginationv3";

// import Pagination from "./Pagination";

//useQuery
import { useQueries } from "react-query";

//componentes
import ModalNewTransfer from "./ModalNewTransfer";
import ModalEditTransfer from "./ModalEditTransfer";
import ImportTransfer from "./ImportTransfer";
import ModalImportWrapper from "./ModalImportWrapper";
import SearchFilters from "../../../components/SearchFilters";

//services
import {
  getTransfer,
  deleteTransfer,
  deleteAllTransfers,
} from "../../../utils/services/transfer";
import { getParticipantsLogistic } from "../../../utils/services/participants";

import useDebounce from "../../../utils/hooks/useDebounce";
import { createXlsx } from "./createXlsx";
import WaitExport from "../../../components/WaitExport";
import SectionComponent from "../../Apps/Personalization/SectionComponent";

const columnsFilter = [
  { name: "Participante", value: "login", type: "text" },
  { name: "Empresa", value: "company", type: "text" },
  { name: "Origem", value: "origin", type: "text" },
  { name: "Destino", value: "destination", type: "text" },
  { name: "Data Embarque", value: "boardingdate", type: "date" },
  { name: "Data Chegada", value: "arrivaldate", type: "date" },
];
const columnsFilterLogistic = [
  ...columnsFilter,
  { name: "Tipo de Cadastro", value: "registrationtype", type: "text" },
];

function Transfer(props) {
  //pesquisa
  const [search, setSearch] = useState("");
  //modais
  const [modalNewTransfer, setModalNewTransfer] = useState(false);
  const [modalEditTransfer, setModalEditTransfer] = useState(false);
  const [modalImportTransfer, setModalImportTransfer] = useState(false);
  const [transfer, setTransfer] = useState([]);
  //deletar transfer
  const [deleteTransferState, setDeleteTransferState] = useState();
  const [confirm_alert, setconfirm_alert] = useState(false);
  const [confirmAllDisabled, setConfirmAllDisabled] = useState(false);
  const [success_dlg, setsuccess_dlg] = useState(false);
  const [dynamic_title, setdynamic_title] = useState("");
  const [dynamic_description, setdynamic_description] = useState("");
  //editar participante
  const [editTransfer, setEditTransfer] = useState();
  //paginação
  const [currentIndex, setCurrentIndex] = useState(1);
  //state de participants
  const participants = useRef(null);
  // eslint-disable-next-line no-unused-vars
  const [countData, setCountData] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const location = useLocation();

  // export in progress
  const [exportInProgress, setExportInProgress] = useState(false);
  const [finishExport, setFinishExport] = useState(false);
  // collapse
  const [isOpen, setIsOpen] = useState(false);
  const [collapseIsOpen, setCollapseIsOpen] = useState(false);
  const [activeFilter, setActiveFilter] = useState("login");
  const [modalImportWrapper, setModalImportWrapper] = useState(false);

  const debouncedSearch = useDebounce(search, 300);

  function setActiveFilterHandle(columnActive) {
    setActiveFilter(columnActive);
    setSearch("");
  }
  const reverseDate = () => {
    const reverse = debouncedSearch
      .split("/")
      .reverse()
      .join("/")
      .replace(/\//g, "-");
    return reverse;
  };

  function checkProductExists(product) {
    if (location.state) {
      const search = location.state.event.product.filter(
        (e) => e.name === product
      );

      if (search[0]) {
        return true;
      } else return false;
    }
  }

  function treatmentSearch() {
    let columnFilter = checkProductExists("Gestão de Logística")
      ? columnsFilterLogistic
      : columnsFilter;
    if (
      columnFilter.filter((item) => item.value === activeFilter)[0].type ===
      "date"
    ) {
      return reverseDate();
    }
    return debouncedSearch;
  }

  function toggleNewTransfer() {
    setModalNewTransfer(!modalNewTransfer);
  }

  function toggleEditTransfer() {
    setModalEditTransfer(!modalEditTransfer);
  }

  function toggleImportParticipant() {
    setModalImportTransfer(!modalImportTransfer);
  }

  function formatData(startDate) {
    if (!startDate) return "-";
    var data = new Date(startDate + "T00:00:00-03:00");
    var dia = String(data.getDate()).padStart(2, "0");
    var mes = String(data.getMonth() + 1).padStart(2, "0");
    var ano = data.getFullYear();
    var dataAtual = dia + "/" + mes + "/" + ano;

    return dataAtual;
  }

  const getValue = (item, key) => {
    if (!item) return "-"; // Verificação para item nulo ou indefinido

    if (item.registrationType !== "VEICULO") {
      if (key === "boardingdate" || key === "arrivaldate") {
        const hour = key === "boardingdate" ? "boardingtill" : "arrivaltime";
        return `${formatData(item[key])} ${item[hour]}`;
      }
      return item[key] || "-"; // Verificação para item[key] nulo ou indefinido
    }

    if (key === "boardingdate" || key === "arrivaldate") {
      const parseKey =
        key === "boardingdate" ? "departureDatetime" : "arrivalDatetime";
      if (
        !item.vehicle ||
        !item.vehicle[parseKey] ||
        item.vehicle[parseKey].includes("1969")
      )
        return "-";
      const datePart = item.vehicle[parseKey]?.split("T")[0];
      const hour = item.vehicle[parseKey]?.split("T")[1]?.substring(0, 5);
      const [year, month, day] = datePart.split("-");
      return `${day}/${month}/${year} ${hour}`;
    }

    return item?.vehicle?.[key] || "-"; // Verificação para item.vehicle[key] nulo ou indefinido
  };

  function toggleImportWrapper() {
    setModalImportWrapper(!modalImportWrapper);
  }

  async function confirmDelete() {
    await deleteTransfer({
      jwt: props.state.global_user_data.data.token,
      id: deleteTransferState.id,
    }).then(() => {
      setconfirm_alert(false);
      setsuccess_dlg(true);
      setdynamic_title("Deletado");
      setdynamic_description("Transfer deletado");
    });
    // .catch((error) => console.log("error", error));
  }
  async function confirmDeleteAllTransfers() {
    await deleteAllTransfers({
      jwt: props.state.global_user_data.data.token,
      eventId: location.state.idEvent,
    }).then((response) => {
      setConfirmAllDisabled(false);
      setsuccess_dlg(true);
      setdynamic_title("Deletados");
      setdynamic_description("Transfers deletados");
    });
  }
  const data = useQueries([
    {
      queryKey: ["getAllParticipants" + location.state.idEvent, 1],
      queryFn: async () => {
        const partForm = await getParticipantsLogistic({
          jwt: props.state.global_user_data.data.token,
          idEvent: location.state.idEvent,
          offset: 0,
          limit: 0,
          search: "",
        });

        participants.current = partForm.data.data;
      },
    },
    {
      queryKey: [
        "getAllTranfer" +
          location.state.idEvent +
          currentIndex +
          itemsPerPage +
          debouncedSearch,
        2,
      ],
      notifyOnChangeProps: ["currentIndex"],
      queryFn: async () => {
        const transferForm = await getTransfer({
          jwt: props.state.global_user_data.data.token,
          idEvent: location.state.idEvent,
          limit: itemsPerPage,
          offset: currentIndex > 1 ? currentIndex : 1,
          search: treatmentSearch(),
          columnSearch: activeFilter,
          hasGestaoClick: checkProductExists("Gestão de Logística")
            ? "true"
            : "false",
        });
        setCountData(transferForm.findOneTransferData.count);
        setTransfer(transferForm.findOneTransferData.data);
      },
    },
  ]);

  useEffect(() => {
    getTransfer({
      jwt: props.state.global_user_data.data.token,
      idEvent: location.state.idEvent,
      limit: itemsPerPage,
      offset: currentIndex > 1 ? currentIndex : 1,
      search: debouncedSearch,
      columnSearch: activeFilter,
      hasGestaoClick: checkProductExists("Gestão de Logística")
        ? "true"
        : "false",
    }).then((transferForm) => {
      setCountData(transferForm.findOneTransferData.count);
      setTransfer(transferForm.findOneTransferData.data);
    });

    getParticipantsLogistic({
      jwt: props.state.global_user_data.data.token,
      idEvent: location.state.idEvent,
      offset: 0,
      limit: 0,
      search: "",
    }).then((partForm) => {
      participants.current = partForm.data.data;
    });
  }, []);

  return (
    <div>
      {modalNewTransfer && (
        <ModalNewTransfer
          isOpen={modalNewTransfer}
          toggle={toggleNewTransfer}
          idEvent={location.state.idEvent}
          data={data}
        />
      )}
      {modalEditTransfer && (
        <ModalEditTransfer
          isOpen={modalEditTransfer}
          toggle={toggleEditTransfer}
          idEvent={location.state.idEvent}
          editTransfer={editTransfer}
          data={data}
          participants={participants.current}
        />
      )}
      {/* <ImportTransfer
        isOpen={modalImportTransfer}
        toggle={toggleImportParticipant}
        token={props.state.global_user_data.data.token}
        idEvent={location.state.idEvent}
        data={data}
      /> */}
      <ModalImportWrapper
        isOpen={modalImportWrapper}
        toggle={toggleImportWrapper}
        data={data}
        idEvent={location.state.idEvent}
        token=""
      />

      {confirm_alert ? (
        <SweetAlert
          title={`Deseja deletar esse Transfer?`}
          warning
          showCancel
          confirmBtnText="Sim"
          cancelBtnText="Não"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            confirmDelete();
          }}
          onCancel={() => setconfirm_alert(false)}
        >
          <a>Você não poderá reverter essa ação.</a>
        </SweetAlert>
      ) : null}
      {confirmAllDisabled ? (
        <SweetAlert
          title={`Deseja remover todos os Transfers?`}
          warning
          showCancel
          confirmBtnText="Sim"
          cancelBtnText="Não"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            confirmDeleteAllTransfers();
          }}
          onCancel={() => setConfirmAllDisabled(false)}
        >
          <a>Você não poderá reverter essa ação.</a>
        </SweetAlert>
      ) : null}
      {success_dlg ? (
        <SweetAlert
          success
          title={dynamic_title}
          onConfirm={() => {
            data[0].refetch();
            data[1].refetch();
            setsuccess_dlg(false);
          }}
        >
          {dynamic_description}
        </SweetAlert>
      ) : null}
      <WaitExport
        isOpen={exportInProgress}
        name="Tranfers"
        preposicao="dos"
        finishExport={finishExport}
        setFinishExport={setFinishExport}
        setExportInProgress={setExportInProgress}
      />

      <Card>
        <CardBody>
          <CardTitle>Transfer</CardTitle>
          <div>
            <Row
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: 10,
                width: "50px",
              }}
            >
              <SectionComponent
                sectionName="Filtros"
                setIsOpen={setCollapseIsOpen}
                isOpen={collapseIsOpen}
              />
            </Row>
            <Collapse isOpen={collapseIsOpen}>
              <SearchFilters
                columns={
                  checkProductExists("Gestão de Logística")
                    ? columnsFilterLogistic
                    : columnsFilter
                }
                activeFilter={activeFilter}
                setActiveFilterHandle={setActiveFilterHandle}
              />
            </Collapse>
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              marginBottom: 10,
            }}
          >
            <form
              className="app-search d-none d-lg-block events-input-search"
              onSubmit={(e) => e.preventDefault()}
            >
              <div className="position-relative">
                <input
                  value={search}
                  type={
                    activeFilter === "boardingdate" ||
                    activeFilter === "arrivaldate"
                      ? "date"
                      : "text"
                  }
                  className="form-control"
                  placeholder="Pesquisar..."
                  onChange={(e) => setSearch(e.target.value)}
                />
                <span className="bx bx-search-alt" />
              </div>
            </form>
            <div className="mobile-btn-section" style={{ width: "100%" }}>
              <Row className="mobile-btns-top">
                <form
                  className="app-search"
                  style={{ padding: "0px" }}
                  onSubmit={(e) => e.preventDefault()}
                >
                  <div className="position-relative">
                    <input
                      type="text"
                      value={search}
                      className="form-control"
                      placeholder="Pesquisar..."
                      onChange={(e) => setSearch(e.target.value)}
                    />
                    <span className="bx bx-search-alt" />
                  </div>
                </form>
              </Row>
              <SectionComponent
                sectionName="Opções"
                setIsOpen={setIsOpen}
                isOpen={isOpen}
              />
              <Collapse isOpen={isOpen}>
                {transfer.length > 0 && (
                  <Row className="mobile-btns-top">
                    <div
                      onClick={() =>
                        createXlsx({
                          jwt: props.state.global_user_data.data.token,
                          idEvent: location.state.idEvent,
                          limit: 100,
                          countData,
                          setFinishExport,
                        }) > setExportInProgress(true)
                      }
                    >
                      Exportar Transfer
                    </div>
                  </Row>
                )}
                <Row className="mobile-btns-top">
                  <div
                    //onClick={() => setModalImportTransfer(!modalImportTransfer)}
                    onClick={() => setModalImportWrapper(!modalImportWrapper)}
                  >
                    Importar Transfer
                  </div>
                </Row>
                <Row className="mobile-btns-top">
                  <div onClick={() => setModalNewTransfer(true)}>
                    Adicionar Transfer
                  </div>
                </Row>
              </Collapse>
            </div>
            <div className="header-btns-container">
              {transfer?.length > 0 && (
                <Button
                  color="success"
                  className="btn-rounded waves-effect waves-light events-button-add"
                  onClick={() =>
                    createXlsx({
                      jwt: props.state.global_user_data.data.token,
                      idEvent: location.state.idEvent,
                      limit: 100,
                      countData,
                      setFinishExport,
                    }) > setExportInProgress(true)
                  }
                >
                  Exportar Transfer
                </Button>
              )}
              <Button
                color="success"
                className="btn-rounded waves-effect waves-light events-button-add"
                // onClick={() => setModalImportTransfer(!modalImportTransfer)}
                onClick={() => setModalImportWrapper(!modalImportWrapper)}
              >
                Importar Transfer
              </Button>
              <Button
                color="success"
                className="btn-rounded waves-effect waves-light events-button-add"
                onClick={() => setModalNewTransfer(true)}
              >
                Adicionar Transfer
              </Button>
            </div>
          </div>

          <div className="table-responsive">
            <Table className="table mb-0">
              <thead className="table-light">
                <tr>
                  <th>Participante</th>
                  {checkProductExists("Gestão de Logística") && (
                    <>
                      <th>Tipo Cadastro</th>
                      <th>Nome Veículo</th>
                    </>
                  )}
                  <th>Empresa</th>
                  <th>Origem</th>
                  <th>Destino</th>
                  <th>Data/hora embarque</th>
                  <th>Data/hora chegada</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                {transfer &&
                  transfer
                    ?.filter((e) => e.login.nome?.toLocaleLowerCase())
                    ?.map((e, i) => (
                      <tr key={i}>
                        <td>{e.login.nome}</td>
                        {checkProductExists("Gestão de Logística") && (
                          <>
                            <td>{e.registrationType}</td>
                            <td>{e.vehicle ? e.vehicle.description : ""}</td>
                          </>
                        )}
                        <td>{getValue(e, "company")}</td>
                        <td>{getValue(e, "origin")}</td>
                        <td>{getValue(e, "destination")}</td>
                        <td>{getValue(e, "boardingdate")}</td>
                        <td>{getValue(e, "arrivaldate")}</td>
                        {/* <td>{formatData(e.criadoEm)}</td> */}
                        <td>
                          <i
                            className="mdi mdi-pencil"
                            style={{
                              color: "green",
                              fontSize: 17,
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setEditTransfer(e);
                              setModalEditTransfer(!modalEditTransfer);
                            }}
                          />{" "}
                          <i
                            className="mdi mdi-trash-can"
                            style={{
                              color: "red",
                              fontSize: 17,
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setDeleteTransferState(e);
                              setconfirm_alert(true);
                            }}
                          />
                        </td>
                      </tr>
                    ))}
              </tbody>
            </Table>
          </div>
        </CardBody>
      </Card>
      <div
        className="bottom-navigation"
        style={{
          justifyContent: `${transfer?.length > 0 ? "space-between" : "end"}`,
        }}
      >
        {transfer?.length > 0 && (
          <Button
            color="danger"
            className="btn-rounded waves-effect waves-light events-button-add"
            onClick={() => setConfirmAllDisabled(true)}
          >
            Limpar Todos Transferes
          </Button>
        )}
        {/* 
        Fornecer os 4 parametros obrigatórios;
        ** data - a array contendo as informações da tabela
        ** search - o state do termo de pesquisa
        ** currentIndex - posição atual do index
        ** setCurrentIndex - função para atualizar a posição atual do index
        */}
        <Pagination
          data={transfer}
          search={search}
          currentIndex={currentIndex}
          setCurrentIndex={setCurrentIndex}
          countData={countData}
          maxItemPerPage={itemsPerPage}
          setMaxItemPerPage={setItemsPerPage}
          maxPageButtonsToShow={3}
        />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return { state };
};

export default connect(mapStateToProps)(Transfer);
