import React, { useEffect, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { useSelector } from "react-redux";
import Select from 'react-select';
import { Button, Col, Input, Label, Modal, ModalBody, ModalHeader, Row, UncontrolledTooltip } from "reactstrap";

const screens = [
  {
    value: 'screen1',
    label: 'Tela 1',
  },
  {
    value: 'screen2',
    label: 'Tela 2',
  }
];

export default function EditFile(props) {
  const { isOpen, toggle } = props;
  const { token: jwt } = useSelector((state) => state.global_user_data.data);

  const [modalBackdrop, setModalBackdrop] = useState(false);

  //campos fomulário
  const [name, setName] = useState("nome teste");
  const [description, setDescription] = useState("descrição teste");
  const [screen, setScreen] = useState("");
  const [fileType, setFileType] = useState("Arquivo");

  //validações campos formulários
  const [nameError, setNameError] = useState(false);
  const [descError, setDescError] = useState(false);
  const [screenError, setScreenError] = useState(false);

  //editado com sucesso
  const [success, setSuccess] = useState(false);

  function toggleBackdrop() {
    setModalBackdrop(!modalBackdrop);
  }

  function resetFields() {
    setNameError(false);
  }

  async function submitForm() {
    if (name === "") {
      return setNameError(true);
    } else setNameError(false);

    if (screen === "") {
      return setScreenError(true);
    } else setScreenError(false);

    resetFields();

    setSuccess(true);
  }

  return (
    <Modal
      size="lg"
      isOpen={isOpen}
      toggle={() => {
        toggleBackdrop();
      }}
      onClosed={() => {
        resetFields();
      }}
      autoFocus={true}
      centered={true}
    >
      {success && (
        <SweetAlert
          success
          title="Editado"
          onConfirm={() => {
            setSuccess(false);
            toggle();
          }}
        >
          Documento editado com sucesso!
        </SweetAlert>
      )}
      <ModalHeader toggle={toggle}>Editar Arquivo</ModalHeader>
      <ModalBody>
        <form>
          <div>
            <Row style={{ marginTop: 10 }}>
              <Col>
                <label className="form-label">Nome do documento*</label>
                <input
                  className="form-control"
                  type="text"
                  defaultValue={name}
                  onChange={(e) => setName(e.target.value)}
                />
                {nameError && (
                  <p style={{ color: "red" }}>O campo Nome é obrigatório.</p>
                )}
              </Col>
            </Row>
          </div>
          <div>
            <Row style={{ marginTop: 10 }}>
              <Col>
                <label className="form-label">Descrição</label>
                <input
                  className="form-control"
                  type="text"
                  defaultValue={description}
                />
                {descError && (
                  <p style={{ color: "red" }}>
                    O campo Descrição é obrigatório.
                  </p>
                )}
              </Col>
            </Row>
          </div>
          <div>
            <div style={{display: 'flex', marginTop: 15}}>
              <Label style={{fontWeight: 700}}>Será informado um link ou um arquivo?</Label>
            </div>
            <div style={{display: 'flex', marginTop: 10}}>
              <div style={{ marginRight: 30 }}>
                <Input
                  type="radio"
                  name="fileOrLink"
                  value="link"
                  style={{ marginRight: 5 }}
                  id="link"
                  readOnly
                  // onChange={(e) => setScreen(e.target.value)}
                />
                <Label htmlFor="link" className="form-label">{fileType}</Label>
              </div>
            </div>
          </div>
          <div>
            <Row style={{ marginTop: 10 }}>
              <Col>
                <label className="form-label">Link</label>
                <input
                  className="form-control"
                  type="text"
                  disabled
                  value={"Link teste"}
                />
              </Col>
            </Row>
          </div>
          <div>
            <div style={{display: 'flex', marginTop: 15}}>
              <Label style={{fontWeight: 700}}>Selecione uma Tela</Label>
              <div id="screen-tip" style={{ height: "16px", paddingRight: "7px" }}>
                <div
                  className="show-tips"
                >
                  <span style={{ margin: 0 }}>?</span>
                </div>
                <UncontrolledTooltip
                  style={{ backgroundColor: "#6f42c1" }}
                  placement="right"
                  target="screen-tip"
                >
                  Segregar arquivos por tela.
                </UncontrolledTooltip>
              </div>
            </div>
            <Select
              placeholder='Selecione uma Tela'
              onChange={setScreen}
              options={[
                {
                  options: [
                    ...screens.map((e) => {
                      return { label: e.label, value: e.value };
                    }),
                  ],
                },
              ]}
              id="formFile"
            />
            {screenError && (
              <p style={{ color: "red" }}>
                É necessário selecionar uma tela.
              </p>
            )}
          </div>
          <Row
            style={{
              width: "100%",
              marginTop: 15,
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              color="success"
              className="btn btn-success waves-effect waves-light"
              style={{ width: 80, marginTop: 10 }}
              onClick={submitForm}
            >
              Salvar
            </Button>
          </Row>
        </form>
      </ModalBody>
    </Modal>
  );
}
