import { urlBase } from "./config";
import axiosInstance from "../services/config/axiosinstance";

function getEvents({ jwt, offset = 1, limit = 0, search, columnSearch = "" }) {
  var config = {
    method: "get",
    url: `${urlBase}/eventos?offset=${offset}&limit=${limit}&search=${search}&columnSearch=${columnSearch}`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  return axiosInstance(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response.data;
    });
}

function deleteEvent(id, token) {
  var config = {
    method: "delete",
    url: `${urlBase}/eventos/${id}`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  return axiosInstance(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response.data;
    });
}

function newEvent(data, jwt) {
  var config = {
    method: "post",
    url: `${urlBase}/eventos`,
    headers: {
      "content-type": "application/json",
    },
    data: data,
  };

  return axiosInstance(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response.data;
    });
}

function editEvent(data, jwt, id) {
  var config = {
    method: "put",
    url: `${urlBase}/eventos/${id}`,
    headers: {
      "content-type": "application/json",
    },
    data: data,
  };

  return axiosInstance(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response.data;
    });
}

function getMobileApps({ jwt, offset = 1, limit = 1000, search = "" }) {
  var config = {
    method: "get",
    url: `${urlBase}/mobileapp?offset=${offset}&limit=${limit}&search=${search}`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  return axiosInstance(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response.data;
    });
}

function getMobileAppsByEvent({ jwt, eventId }) {
  var config = {
    method: "get",
    url: `${urlBase}/mobileapp/event/${eventId}`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  return axiosInstance(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response.data;
    });
}

export {
  getEvents,
  deleteEvent,
  newEvent,
  editEvent,
  getMobileApps,
  getMobileAppsByEvent,
};
