/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react";
import { Modal, ModalBody, ModalHeader, Col, Row, Button } from "reactstrap";
//redux
import { connect } from "react-redux";
// alerta
import SweetAlert from "react-bootstrap-sweetalert";
// select
import Select from "react-select";
import { useLocation } from "react-router-dom";

//services
// import { getParticipants } from "../../../utils/services/participants";
import { updateTransfer } from "../../../utils/services/transfer";
// componentes
import ButtonSpinner from "../../../components/Buttons/ButtonSpinner";
import { getAllVehicles } from "../../../utils/services/vehicles";

function NewTransfer(props) {
  const { isOpen, toggle } = props;
  const location = useLocation();

  const [modalBackdrop, setModalBackdrop] = useState(false);
  //confirmção de criação
  const [success_dlg, setsuccess_dlg] = useState(false);
  //state de participantes
  const participants = props.participants;

  const [participantSelected, setParticipantSelected] = useState();
  //state dos campos obrigatórios
  const [participantError, setParticipantError] = useState("");
  const [companyError, setCompanyError] = useState("");
  const [sourcerError, setSourceError] = useState("");
  const [destinyError, setDestinyError] = useState("");
  const [dateBoardingError, setDateBoardingError] = useState("");
  const [timeBoardingError, setTimeBoardingError] = useState("");
  const [isLoading, setIsloading] = useState(false);

  const [vehicles, setVehicles] = useState([]);
  const [vehicleSelected, setVehicleSelected] = useState({
    label: props.editTransfer.vehicle?.description,
    value: props.editTransfer.vehicle?.id,
  });
  const [vehicleError, setVehicletError] = useState("");

  const [company, setCompany] = useState(props.editTransfer.company);
  const [origin, setOrigin] = useState(props.editTransfer.origin);
  const [destination, setDestination] = useState(
    props.editTransfer.destination
  );
  const [boardingDate, setBoardingDate] = useState(
    props.editTransfer.boardingdate
  );
  const [boardingTill, setBoardingTill] = useState(
    props.editTransfer.boardingtill
  );
  const [arrivalDate, setArrivalDate] = useState(
    props.editTransfer.arrivaldate
  );
  const [arrivalTime, setArrivalTime] = useState(
    props.editTransfer.arrivaltime
  );
  const [meetingPoint, setMeetingPoint] = useState(
    props.editTransfer.meetingpoint
  );
  const [driverName, setDriverName] = useState(props.editTransfer.drivername);
  const [driverPhone, setDriverPhone] = useState(
    props.editTransfer.driverphone
  );
  const [extraInformation, setExtraInformation] = useState(
    props.editTransfer.extrainformation
  );

  const [registrationType, setRegistrationType] = useState(
    props.editTransfer.registrationType
  );

  function toggleBackdrop() {
    setModalBackdrop(!modalBackdrop);
  }

  const submitForm = async () => {
    //tratamento de erros
    if (!participantSelected) {
      setParticipantError("O campo Participante é obrigatório");
      return;
    } else setParticipantError("");

    if (
      !checkProductExists("Gestão de Logística") ||
      registrationType !== "VEICULO"
    ) {
      if (company === "") {
        setCompanyError("O campo Empresa é obrigatório");
        return;
      } else setCompanyError("");

      if (origin === "") {
        setSourceError("O campo Origem é obrigatório");
        return;
      } else setSourceError("");

      if (destination === "") {
        setDestinyError("O campo Destino é obrigatório");
        return;
      } else setDestinyError("");

      if (boardingDate === "") {
        setDateBoardingError("O campo Data embarque é obrigatório");
        return;
      } else setDateBoardingError("");

      if (boardingTill === "") {
        setTimeBoardingError("O campo Hora embarque é obrigatório");
        return;
      } else setTimeBoardingError("");
    }
    if (registrationType === "VEICULO") {
      if (!participantSelected || participantSelected === "Selecione") {
        setParticipantError("O campo Participante é obrigatório");
        return;
      } else setParticipantError("");

      if (!vehicleSelected || vehicleSelected === "Selecione") {
        setVehicletError("O campo Veículo é obrigatório");
        return;
      } else setVehicletError("");
    }

    const data = {
      event: props.idEvent,
      login: participantSelected.value,
      company,
      origin,
      destination,
      boardingdate: boardingDate,
      boardingtill: boardingTill,
      arrivaldate: arrivalDate,
      arrivaltime: arrivalTime,
      meetingpoint: meetingPoint,
      drivername: driverName,
      driverphone: driverPhone,
      extrainformation: extraInformation,
      jwt: props.state.global_user_data.data.token,
      idTransfer: props.editTransfer.id,
      registrationType,
      vehicle: vehicleSelected ? vehicleSelected : null,
    };

    setIsloading(true);
    await updateTransfer(data)
      .then((response) => {
        setIsloading(false);
        setsuccess_dlg(true);
        // console.log(response);
      })
      .catch((error) => setIsloading(false));
  };

  // async function getAllParticipants() {
  //   await getParticipants({
  //     jwt: props.state.global_user_data.data.token,
  //     idEvent: props.idEvent,
  //     offset: 1,
  //     limit: 0,
  //     search: '',
  //   })
  //     .then((response) => {
  //       setParticipants(response.data.data);
  //     })
  //     .catch((error) => {
  //       console.log("error", error);
  //     });
  // }
  function checkProductExists(product) {
    if (location.state) {
      const search = location.state.event.product.filter(
        (e) => e.name === product
      );

      if (search[0]) {
        return true;
      } else return false;
    }
  }

  function funcParticipants() {
    const userSelected = props.editTransfer.login;

    setParticipantSelected({
      label: userSelected.nome,
      value: userSelected.id,
    });
  }

  useEffect(() => {
    funcParticipants();
  }, [participants]);

  // useEffect(() => {
  //   getAllParticipants();
  // }, []);
  useEffect(() => {}, [participants]);

  async function getAll() {
    getAllVehicles(location.state.idEvent, 1, 10, "", "").then((res) => {
      if (res.data.message === "FOUND") {
        if (res?.data.findAll.data.length > 0) {
          const vehiclesAvailable = res.data.findAll.data.filter(
            (e) =>
              e.id === props.editTransfer.vehicle?.id ||
              e.seatingCapacity > e.amount
          );
          setVehicles(vehiclesAvailable);
        }
      }
    });
  }
  useEffect(() => {
    if (registrationType === "VEICULO") {
      getAll();
    }
  }, [registrationType]);

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => {
        toggleBackdrop();
      }}
      autoFocus={true}
      centered={true}
    >
      {success_dlg ? (
        <SweetAlert
          success
          title={"Editado"}
          onConfirm={() => {
            toggle();
            // props.getAllTransfers();
            props.data[0].refetch();
            props.data[1].refetch();
            setsuccess_dlg(false);
          }}
        >
          {"Transfer editado com sucesso!"}
        </SweetAlert>
      ) : null}
      <ModalHeader toggle={toggle}>Editar Transfer</ModalHeader>
      <ModalBody>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            submitForm(e);
          }}
        >
          <Row>
            <Col lg={12}>
              <div>
                <label className="form-label">ID do Transfer</label>
                <input
                  className="form-control"
                  type="text"
                  defaultValue={props.editTransfer.id}
                  disabled
                />
              </div>
            </Col>
          </Row>

          <Row style={{ marginTop: 20 }}>
            <Col lg={12}>
              <label className="form-label">Nome do Participante*</label>
              <Select
                placeholder={"Selecione um participantes..."}
                isLoading={participants?.length > 0 ? false : true}
                loadingMessage={() => "Carregando..."}
                noOptionsMessage={() => "Nenhum participante encontrado"}
                value={participantSelected}
                onChange={(e) => {
                  setParticipantSelected(e);
                }}
                options={[
                  {
                    options: [
                      ...participants.map((e) => {
                        return { label: e.nome, value: e.id };
                      }),
                    ],
                  },
                ]}
                classNamePrefix="select2-selection"
                menuPortalTarget={document.body}
                styles={{
                  menuPortal: (base) => ({
                    ...base,
                    zIndex: 9999,
                  }),
                  menu: (provided) => ({
                    ...provided,
                    maxHeight: 150,
                  }),
                  menuList: (provided) => ({
                    ...provided,
                    maxHeight: 150,
                    overflowY: "auto",
                  }),
                }}
              />
              {participantError && (
                <a style={{ color: "red" }}>{participantError}</a>
              )}
            </Col>
          </Row>
          {checkProductExists("Gestão de Logística") && (
            <Row style={{ marginTop: "15px" }}>
              <Col lg={12}>
                <Row>
                  <Col md={12}>
                    <div style={{ marginBottom: 10 }}>
                      <div>
                        <label className="form-label">Tipo de Cadastro</label>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignContent: "center",
                        }}
                      >
                        <input
                          type="radio"
                          name="registrationType"
                          checked={registrationType === "LIVRE"}
                          disabled
                          // onChange={(e) => setRegistrationType(e.target.value)}
                        />
                        <label
                          className="form-check-label"
                          style={{ marginLeft: "5px", marginRight: "10px" }}
                        >
                          Cadastro Livre
                        </label>

                        <input
                          type="radio"
                          name="registrationType"
                          checked={registrationType === "VEICULO"}
                          // onChange={(e) => setRegistrationType(e.target.value)}
                          disabled
                        />
                        <label
                          className="form-check-label"
                          style={{ marginLeft: "5px" }}
                        >
                          Selecionar Veículo
                        </label>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
          {registrationType === "VEICULO" ? (
            <Row style={{ marginTop: "10px" }}>
              <Col lg={12}>
                <label className="form-label">Nome do Veículo*</label>
                <Select
                  placeholder={"Selecione um veículo..."}
                  defaultValue={vehicleSelected}
                  // value={vehicleSelected}
                  onChange={(e) => {
                    setVehicleSelected(e.value);
                  }}
                  options={[
                    {
                      options: [
                        ...vehicles.map((e) => {
                          return { label: e.description, value: e.id };
                        }),
                      ],
                    },
                  ]}
                  classNamePrefix="select2-selection"
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: (base) => ({
                      ...base,
                      zIndex: 9999,
                    }),
                    menu: (provided) => ({
                      ...provided,
                      maxHeight: 150,
                    }),
                    menuList: (provided) => ({
                      ...provided,
                      maxHeight: 150,
                      overflowY: "auto",
                    }),
                  }}
                />
                {vehicleError && <a style={{ color: "red" }}>{vehicleError}</a>}
              </Col>
            </Row>
          ) : (
            <>
              <Row style={{ marginTop: 20 }}>
                <Col lg={12}>
                  <div>
                    <label className="form-label">Empresa*</label>
                    <input
                      className="form-control"
                      type="text"
                      defaultValue={props.editTransfer.company}
                      onChange={(e) => setCompany(e.target.value)}
                    />
                  </div>
                  {companyError && (
                    <a style={{ color: "red" }}>{companyError}</a>
                  )}
                </Col>
              </Row>

              <Row style={{ marginTop: 20 }}>
                <Col lg={12}>
                  <div>
                    <label className="form-label">Origem*</label>
                    <input
                      className="form-control"
                      type="text"
                      defaultValue={props.editTransfer.origin}
                      onChange={(e) => setOrigin(e.target.value)}
                    />
                  </div>
                  {sourcerError && (
                    <a style={{ color: "red" }}>{sourcerError}</a>
                  )}
                </Col>
              </Row>

              <Row style={{ marginTop: 20 }}>
                <Col lg={12}>
                  <div>
                    <label className="form-label">Destino*</label>
                    <input
                      className="form-control"
                      type="text"
                      defaultValue={props.editTransfer.destination}
                      onChange={(e) => setDestination(e.target.value)}
                    />
                  </div>
                  {destinyError && (
                    <a style={{ color: "red" }}>{destinyError}</a>
                  )}
                </Col>
              </Row>

              <Row style={{ marginTop: 20 }}>
                <Col lg={6}>
                  <div>
                    <label className="form-label">Data embarque*</label>
                    <input
                      className="form-control"
                      type="date"
                      // defaultValue="2019-08-19T13:45:00"
                      defaultValue={props.editTransfer.boardingdate}
                      id="example-datetime-local-input"
                      onChange={(e) => setBoardingDate(e.target.value)}
                    />
                  </div>
                  {dateBoardingError && (
                    <a style={{ color: "red" }}>{dateBoardingError}</a>
                  )}
                </Col>

                <Col lg={6}>
                  <div>
                    <label className="form-label">Hora embarque*</label>
                    <input
                      className="form-control"
                      type="time"
                      // defaultValue="2019-08-19T13:45:00"
                      defaultValue={props.editTransfer.boardingtill}
                      onChange={(e) => setBoardingTill(e.target.value)}
                      id="example-datetime-local-input"
                    />
                  </div>
                  {timeBoardingError && (
                    <a style={{ color: "red" }}>{timeBoardingError}</a>
                  )}
                </Col>
              </Row>

              <Row style={{ marginTop: 20 }}>
                <Col lg={6}>
                  <div>
                    <label className="form-label">Data chegada</label>
                    <input
                      className="form-control"
                      type="date"
                      // defaultValue="2019-08-19T13:45:00"
                      defaultValue={props.editTransfer.arrivaldate}
                      onChange={(e) => setArrivalDate(e.target.value)}
                      id="example-datetime-local-input"
                    />
                  </div>
                </Col>
                <Col lg={6}>
                  <div>
                    <label className="form-label">Hora chegada</label>
                    <input
                      className="form-control"
                      type="time"
                      // defaultValue="2019-08-19T13:45:00"
                      defaultValue={props.editTransfer.arrivaltime}
                      onChange={(e) => setArrivalTime(e.target.value)}
                      id="example-datetime-local-input"
                    />
                  </div>
                </Col>
              </Row>

              <Row style={{ marginTop: 20 }}>
                <Col lg={12}>
                  <div>
                    <label className="form-label">Ponto de encontro</label>
                    <input
                      className="form-control"
                      type="text"
                      defaultValue={props.editTransfer.meetingpoint}
                      onChange={(e) => setMeetingPoint(e.target.value)}
                    />
                  </div>
                </Col>
              </Row>

              <Row style={{ marginTop: 20 }}>
                <Col lg={12}>
                  <div>
                    <label className="form-label">Nome do motorista</label>
                    <input
                      className="form-control"
                      type="text"
                      defaultValue={props.editTransfer.drivername}
                      onChange={(e) => setDriverName(e.target.value)}
                    />
                  </div>
                </Col>
              </Row>

              <Row style={{ marginTop: 20 }}>
                <Col lg={12}>
                  <div>
                    <label className="form-label">Telefone do motorista</label>
                    <input
                      className="form-control"
                      type="text"
                      defaultValue={props.editTransfer.driverphone}
                      onChange={(e) => setDriverPhone(e.target.value)}
                    />
                  </div>
                </Col>
              </Row>

              <Row style={{ marginTop: 20 }}>
                <Col lg={12}>
                  <div>
                    <label className="form-label">Informações extras</label>
                    <input
                      className="form-control"
                      type="text"
                      defaultValue={props.editTransfer.extrainformation}
                      onChange={(e) => setExtraInformation(e.target.value)}
                    />
                  </div>
                </Col>
              </Row>
            </>
          )}
          <Row
            style={{
              width: "100%",
              margin: "20px auto 0",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              color="danger"
              className="btn waves-effect waves-light"
              style={{ width: 80, marginRight: 15 }}
              onClick={() => toggle()}
            >
              Fechar
            </Button>
            <ButtonSpinner isLoading={isLoading} title={"Salvar"} />
          </Row>
        </form>
      </ModalBody>
    </Modal>
  );
}

const mapStateToProps = function (state) {
  return { state: state };
};

export default connect(mapStateToProps)(NewTransfer);
