import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
import ButtonSpinner from "../../components/Buttons/ButtonSpinner";
import { useState } from "react";

export const ModalVersionApp = (props) => {
  const { isOpen, toggle } = props;
  const [versionApple, setVersionApple] = useState("não");
  const [versionAndroid, setVersionAndroid] = useState("não");

  return (
    <Modal size="lg" isOpen={isOpen} autoFocus={true} centered={true}>
      <ModalHeader toggle={toggle}>Gerar Versão</ModalHeader>
      <ModalBody>
        <form>
          <Row style={{ marginTop: 10, marginBottom: 20 }}>
            <Col lg={12}>
              <label className="form-label text-sm" style={{ display: "flex" }}>
                Gerar versão Apple Store?
                <div
                  id="mainScreen"
                  style={{ height: "16px", paddingRight: "7px" }}
                >
                  <div className="show-tips">
                    <span>?</span>
                  </div>
                  <UncontrolledTooltip
                    style={{ backgroundColor: "#6f42c1" }}
                    placement="right"
                    target="mainScreen"
                  >
                    Gerar uma versão para o app publicado na Apple Store
                  </UncontrolledTooltip>
                </div>
              </label>
              <select
                value={versionApple}
                className="form-control"
                onChange={({ target }) => setVersionApple(target.value)}
              >
                <option value={"sim"}>Sim</option>
                <option value={"não"}>Não</option>
              </select>
            </Col>

            {versionApple === "sim" && (
              <Col style={{ marginTop: 10, marginBottom: 20 }}>
                <label className="form-label">Versão: *</label>
                <input
                  className="form-control"
                  type="text"
                  //   value={termoDeAcesso}
                  //   onChange={(e) => setTermoDeAcesso(e.target.value)}
                />
              </Col>
            )}
          </Row>

          <Row style={{ marginTop: 10, marginBottom: 20 }}>
            <Col lg={12}>
              <label className="form-label text-sm" style={{ display: "flex" }}>
                Gerar versão Play Store?
                <div
                  id="mainScreen"
                  style={{ height: "16px", paddingRight: "7px" }}
                >
                  <div className="show-tips">
                    <span>?</span>
                  </div>
                  <UncontrolledTooltip
                    style={{ backgroundColor: "#6f42c1" }}
                    placement="right"
                    target="mainScreen"
                  >
                    Gerar uma versão para o app publicado na Play Store
                  </UncontrolledTooltip>
                </div>
              </label>
              <select
                value={versionAndroid}
                className="form-control"
                onChange={({ target }) => setVersionAndroid(target.value)}
              >
                <option value={"sim"}>Sim</option>
                <option value={"não"}>Não</option>
              </select>
            </Col>

            {versionAndroid === "sim" && (
              <Col style={{ marginTop: 10, marginBottom: 20 }}>
                <label className="form-label">Versão: *</label>
                <input
                  className="form-control"
                  type="text"
                  //   value={termoDeAcesso}
                  //   onChange={(e) => setTermoDeAcesso(e.target.value)}
                />
              </Col>
            )}
          </Row>

          <Row
            style={{
              width: "100%",
              marginTop: 15,
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              color="success"
              className="btn btn-danger waves-effect waves-light"
              style={{ width: 80, marginRight: 5 }}
              onClick={() => toggle()}
            >
              Fechar
            </Button>

            <Button
              color="success"
              className="btn waves-effect waves-light"
              style={{ width: 120, marginRight: 5 }}
              onClick={() => toggle()}
            >
              Gerar versão
            </Button>
          </Row>
        </form>
      </ModalBody>
    </Modal>
  );
};
