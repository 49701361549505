import { urlBase } from "./config/index";
import axiosInstance from "../services/config/axiosinstance";

function createTransfer({
  event,
  login,
  company,
  origin,
  destination,
  boardingdate,
  arrivaldate,
  boardingtill,
  arrivaltime,
  meetingpoint,
  drivername,
  driverphone,
  extrainformation,
  jwt,
  registrationType,
  vehicle,
}) {
  var data = JSON.stringify({
    event: event,
    login: login,
    company: company,
    origin: origin,
    destination: destination,
    boardingdate: boardingdate,
    arrivaldate: arrivaldate,
    boardingtill: boardingtill,
    arrivaltime: arrivaltime,
    meetingpoint: meetingpoint,
    drivername: drivername,
    driverphone: driverphone,
    extrainformation: extrainformation,
    registrationType: registrationType,
    vehicle: vehicle,
  });

  var config = {
    method: "post",
    url: `${urlBase}/transfers`,
    headers: {
      "Content-Type": "application/json",
    },
    data,
  };

  return axiosInstance(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

function createTransferImport({
  event,
  login,
  company,
  origin,
  destination,
  boardingdate,
  arrivaldate,
  boardingtill,
  arrivaltime,
  meetingpoint,
  drivername,
  driverphone,
  extrainformation,
  jwt,
  registrationType,
  vehicle,
}) {
  console.log("vehicle", vehicle);
  var data = JSON.stringify({
    event: event,
    login: login,
    company: company,
    origin: origin,
    destination: destination,
    boardingdate: boardingdate,
    arrivaldate: arrivaldate,
    boardingtill: boardingtill,
    arrivaltime: arrivaltime,
    meetingpoint: meetingpoint,
    drivername: drivername,
    driverphone: driverphone,
    extrainformation: extrainformation,
    registrationType: registrationType,
    vehicle: vehicle,
  });

  var config = {
    method: "post",
    url: `${urlBase}/transfers/import`,
    headers: {
      "Content-Type": "application/json",
    },
    data,
  };

  return axiosInstance(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

function getTransfer({
  jwt,
  idEvent,
  offset,
  limit,
  search,
  columnSearch = "",
  hasGestaoClick,
}) {
  var config = {
    method: "get",
    url: `${urlBase}/transfers/event/${idEvent}?offset=${offset}&limit=${limit}&search=${search}&columnSearch=${columnSearch}&hasGestaoClick=${hasGestaoClick}`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  return axiosInstance(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response.data;
    });
}

function deleteTransfer({ jwt, id }) {
  var config = {
    method: "delete",
    url: `${urlBase}/transfers/${id}`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  return axiosInstance(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response.data;
    });
}

async function deleteAllTransfers({ jwt, eventId }) {
  var config = {
    method: "put",
    url: `${urlBase}/transfers/disableall/${eventId}`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  return axiosInstance(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response.data;
    });
}

function updateTransfer({
  event,
  login,
  company,
  origin,
  destination,
  boardingdate,
  arrivaldate,
  boardingtill,
  arrivaltime,
  meetingpoint,
  drivername,
  driverphone,
  extrainformation,
  jwt,
  idTransfer,
  registrationType,
  vehicle,
}) {
  var data = JSON.stringify({
    event: event,
    login: login,
    company: company,
    origin: origin,
    destination: destination,
    boardingdate: boardingdate,
    arrivaldate: arrivaldate,
    boardingtill: boardingtill,
    arrivaltime: arrivaltime,
    meetingpoint: meetingpoint,
    drivername: drivername,
    driverphone: driverphone,
    extrainformation: extrainformation,
    registrationType: registrationType,
    vehicle: vehicle,
  });

  var config = {
    method: "put",
    url: `${urlBase}/transfers/${idTransfer}`,
    headers: {
      "Content-Type": "application/json",
    },
    data,
  };

  return axiosInstance(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

async function getTotalVehicle(vehicleId, eventId) {
  try {
    const config = {
      method: "get",
      url: `${urlBase}/transfers/totalvehicle/${vehicleId}/vehicle/${eventId}/event/`,
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await axiosInstance(config);
    return response;
  } catch (error) {
    return error.response;
  }
}

function getTransferDashboard(idEvent) {
  const config = {
    method: "get",
    url: `${urlBase}/transfers/dashboard/${idEvent}/event`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  return axiosInstance(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response.data;
    });
}

async function getParticipantsByVehicle(
  idEvent,
  vehicleId,
  offset,
  limit,
  search,
  columnSearch = ""
) {
  const config = {
    method: "get",
    url: `${urlBase}/transfers/${idEvent}/event/${vehicleId}/vehicle/?offset=${offset}&limit=${limit}&search=${search}&columnSearch=${columnSearch}`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  return axiosInstance(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response.data;
    });
}

function movePartipantsToNewVehicle(data) {
  var config = {
    method: "post",
    url: `${urlBase}/transfers/bulkregister`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  return axiosInstance(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

export {
  createTransfer,
  createTransferImport,
  getParticipantsByVehicle,
  getTransferDashboard,
  getTransfer,
  getTotalVehicle,
  deleteAllTransfers,
  deleteTransfer,
  movePartipantsToNewVehicle,
  updateTransfer,
};
