import React, { useState, useEffect } from "react";
import { Modal, ModalHeader, ModalBody, Row, Col, Button } from "reactstrap";
import ButtonSpinner from "../../../components/Buttons/ButtonSpinner";
import Select from "react-select";
import SweetAlert from "react-bootstrap-sweetalert";
import { getParticipants } from "../../../utils/services/participants";
import { createTransfer } from "../../../utils/services/transfer";
import { createLoginStatusVehicle } from "../../../utils/services/loginStatusVehicle";

export default function modalEditParticipant(props) {
  const { isOpen, toggle, eventId, vehicleId, getAll, participantsInVehicle } =
    props;

  const [isLoading, setIsloading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [fail, setFail] = useState(false);
  const [errorCreate, setErrorCreate] = useState(false);
  const [participants, setParticipants] = useState([]);
  const [participantSelected, setParticipantSelected] = useState();
  const [participantError, setParticipantError] = useState("");
  const [selected, setSelected] = useState("");
  const [notesError, setNotesError] = useState(null);
  const [notes, setNotes] = useState("");

  async function getAllParticipants() {
    await getParticipants({
      jwt: "", //props.state.global_user_data.data.token,
      idEvent: eventId,
      offset: 1,
      limit: 0,
      search: "",
    })
      .then((response) => {
        setParticipants(response.data.data);
      })
      .catch((error) => {});
  }

  useEffect(() => {
    getAllParticipants();
  }, []);

  async function saveParticipantInVehicle() {
    if (!participantSelected || participantSelected === "Selecione") {
      setParticipantError("O campo Participante é obrigatório");
      return;
    } else setParticipantError("");
    setIsloading(true);

    const data = {
      event: eventId,
      login: participantSelected,
      company: null,
      origin: null,
      destination: null,
      boardingdate: null,
      boardingtill: null,
      arrivaldate: null,
      arrivaltime: null,
      meetingpoint: null,
      drivername: null,
      driverphone: null,
      extrainformation: null,
      jwt: "",
      registrationType: "VEICULO",
      vehicle: vehicleId,
    };

    await createTransfer(data)
      .then((response) => {
        if (response.message === "NO_VEHICLE_STOCK") {
          setIsloading(false);
          setErrorCreate(true);
          return;
        }
        if (selected !== "") {
          changeLoginStatusVehicle();
        } else {
          setIsloading(false);
          setSuccess(true);
          getAll();
        }
      })
      .catch((error) => {
        setIsloading(false);
      });
  }

  async function changeLoginStatusVehicle() {
    const data = {
      eventId: eventId,
      vehicleId: vehicleId,
      loginId: participantSelected,
      previousStatus: "",
      newStatus: selected,
      notes: notes,
    };

    await createLoginStatusVehicle(data).then((res) => {
      setIsloading(false);
      setSuccess(true);
      getAll();
    });
  }
  return (
    <>
      {success && (
        <SweetAlert
          success
          title={"Atualizado"}
          onConfirm={() => {
            setSuccess(false);
            toggle();
          }}
        >
          {"Status atualizado com sucesso!"}
        </SweetAlert>
      )}

      {fail && (
        <SweetAlert
          warning
          showCancel
          cancelBtnText="Fechar"
          showConfirm={false}
          cancelBtnBsStyle="warning"
          title={"Atenção!"}
          onCancel={() => {
            setFail(false);
            toggle();
          }}
        >
          {
            "Nem todos os participantes foram movidos para o novo veículo. Capacidade do veículo esgotada."
          }
        </SweetAlert>
      )}

      {errorCreate && (
        <SweetAlert
          warning
          showCancel
          cancelBtnText="Fechar"
          showConfirm={false}
          cancelBtnBsStyle="warning"
          title={"Atenção!"}
          onCancel={() => {
            setFail(false);
            toggle();
          }}
        >
          {"O veículo não possui capacidade disponível"}
        </SweetAlert>
      )}

      <Modal isOpen={isOpen} centered autoFocus>
        <ModalHeader toggle={toggle}>Adicionar Participante</ModalHeader>
        <ModalBody>
          <Row>
            <Col lg={12}>
              <label className="form-label">Nome do Participante*</label>
              <Select
                isLoading={participants.length > 0 ? false : true}
                loadingMessage={() => "Carregando..."}
                noOptionsMessage={() => "Nenhum participante encontrado"}
                placeholder={"Selecione um participante..."}
                onChange={(e) => {
                  setParticipantSelected(e.value);
                }}
                options={[
                  {
                    options: [
                      ...participants
                        .filter(
                          (p) =>
                            !participantsInVehicle.some(
                              (v) => v.participantId === p.id
                            )
                        )
                        .map((e) => {
                          return { label: e.nome, value: e.id };
                        }),
                    ],
                  },
                ]}
                classNamePrefix="select2-selection"
                menuPortalTarget={document.body}
                styles={{
                  menuPortal: (base) => ({
                    ...base,
                    zIndex: 9999,
                  }),
                  menu: (provided) => ({
                    ...provided,
                    maxHeight: 150,
                  }),
                  menuList: (provided) => ({
                    ...provided,
                    maxHeight: 150,
                    overflowY: "auto",
                  }),
                }}
              />
              {participantError && (
                <a style={{ color: "red" }}>{participantError}</a>
              )}
            </Col>
          </Row>
          <Row style={{ marginTop: "10px" }}>
            <Col md={12}>
              <label className="form-label">Alterar Status </label>
              <select
                className="form-control"
                onChange={(e) => {
                  setSelected(e.target.value);
                }}
                defaultValue=""
              >
                <option value={""}></option>
                <option value={"Embarcado"}>Embarcado</option>
                <option value={"Desembarcado"}>Desembarcado</option>
                <option value={"Não Embarcou"}>Não Embarcou</option>
              </select>
            </Col>
          </Row>

          {selected === "Desembarcado" && (
            <Row style={{ marginTop: 10 }}>
              <Col md={12}>
                <label className="form-label">Justificativa *</label>
                <input
                  className="form-control"
                  type="text"
                  onChange={(e) => setNotes(e.target.value)}
                />
              </Col>
              {notesError && <a style={{ color: "red" }}>{notesError}</a>}
            </Row>
          )}
          <Row
            style={{
              width: "100%",
              margin: "auto",
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "1rem",
            }}
          >
            <Button
              color="danger"
              style={{ width: 80, marginRight: 10 }}
              onClick={() => toggle()}
            >
              Fechar
            </Button>

            <ButtonSpinner
              isLoading={isLoading}
              title={"Salvar"}
              isDisabled={participantSelected === ""}
              onClick={() => {
                if (!notes && selected === "Desembarcado") {
                  setNotesError("O campo Justificativa é obrigatório");
                  return;
                }
                saveParticipantInVehicle();
              }}
            />
          </Row>
        </ModalBody>
      </Modal>
    </>
  );
}
