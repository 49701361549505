import { urlBaseFiles } from "./config/index";
import axiosInstance from "../services/config/axiosinstance";

async function postImg(data, jwt) {
  const config = {
    method: "post",
    url: `${urlBaseFiles}/gallery`,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data,
  };

  return axiosInstance(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      // console.log(error.response);
      return error.response.data;
    });
}

async function getImg(
  jwt,
  offset,
  limit,
  type = "InteegraDrive",
  search,
  columnSearch = ""
) {
  const config = {
    method: "get",
    url: `${urlBaseFiles}/gallery?type=${type}&offset=${offset}&limit=${limit}${
      search && `&search=${search}&columnSearch=${columnSearch}`
    }`,
    headers: {},
  };

  return axiosInstance(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      // console.log(error.response);
      return error.response.data;
    });
}

async function deleteImg(jwt, id) {
  const config = {
    method: "delete",
    url: `${urlBaseFiles}/gallery/${id}`,
    headers: {},
  };

  return axiosInstance(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      // console.log(error.response);
      return error.response.data;
    });
}

async function updateImg(jwt, data) {
  const config = {
    method: "put",
    url: `${urlBaseFiles}/gallery`,
    headers: {
      "Content-Type": "application/json",
    },
    data,
  };

  return axiosInstance(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      // console.log(error.response);
      return error.response.data;
    });
}

export { postImg, getImg, deleteImg, updateImg };
