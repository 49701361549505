import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

import waitingGif from "../assets/image/waiting.gif";

export default function WaitExport({
  name,
  isOpen,
  finishExport,
  setFinishExport,
  setExportInProgress,
  preposicao,
  position,
  customFinishTitle,
  customHeaderText,
  customBodyText,
}) {
  return (
    <Modal isOpen={isOpen} toggle={() => {}} autoFocus={true} centered={true}>
      {finishExport && (
        <SweetAlert
          title={
            customFinishTitle ? customFinishTitle : "Exportação finalizada"
          }
          confirmButtonText="Sair"
          confirmBtnBsStyle="success"
          onConfirm={() => {
            setFinishExport(false);
            setExportInProgress(false);
          }}
        />
      )}
      {customHeaderText ? (
        <ModalHeader>{customHeaderText}</ModalHeader>
      ) : (
        <ModalHeader>{`Exportando ${name}`}</ModalHeader>
      )}
      <ModalBody>
        <div>
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              marginBottom: "15px",
            }}
          >
            <img
              style={{ width: "50%", margin: "auto" }}
              src={waitingGif}
              alt="gato batendo na tela do celular"
            />
          </div>
          <p>
            {customBodyText
              ? customBodyText
              : `Em breve a exportação ${preposicao} ${name} será concluída, aguarde alguns instantes.`}
          </p>
        </div>
        <div className="export-progress-container">
          <div className="export-progress" style={{ width: position }}>
            <span
              className="export-percentage"
              style={{
                margin: 0,
                position: "absolute",
                left: "46.5%",
                bottom: "12px",
                width: "34px",
                textAlign: "center",
              }}
            >
              {position ? position : "0%"}
            </span>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
}
