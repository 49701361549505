import React, { useState, useEffect } from "react";
import { Table, Row, Col, Collapse, Button } from "reactstrap";
//paginação
import Pagination from "../../../../components/Paginationv3";
// sessão mobile
import SectionComponent from "../../../Apps/Personalization/SectionComponent";
// service
import { getOnline } from "../../../../utils/services/dashboardSuperScreen";
// carregamento de tabela
import LoadingSkelentonTable from "../../../../components/loadingSkeletonTable";
// exportar tabela
import { createXlsx } from "./exports/createXlsxOnline";
// usedebounce
import useDebounce from "../../../../utils/hooks/useDebounce";
// componente de carregamento de exportação
import WaitExport from "../../../../components/WaitExport";
// moment
import moment from "moment";

export default function TableOnline(props) {
  const eventId = props.idEvent;
  const transmissionId = props.idDashboard;
  // pesquisa da listagem
  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounce(search, 300);
  //paginação
  const [currentIndex, setCurrentIndex] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [countData, setCountData] = useState(0);
  // modal collapse
  const [isOpen, setIsOpen] = useState(false);
  // export in progress
  const [exportInProgress, setExportInProgress] = useState(false);
  const [finishExport, setFinishExport] = useState(false);

  function formatDate(date) {
    const dateFormat = date.split("T")[0].split("-").reverse().join("/");
    const time = date.split("T")[1].slice(0, 5);
    return `${dateFormat} - ${time}`;
  }

  function exportReport() {
    createXlsx({
      eventId: eventId,
      transmissionID: transmissionId,
      limit: itemsPerPage,
      offset: currentIndex - 1,
      search: "",
      countData: countData,
      setFinishExport,
      mobileapps: props.mobileapps,
    });
    setExportInProgress(true);
  }

  function getAllOnline() {
    getOnline({
      eventId: eventId,
      transmissionID: transmissionId,
      pageSize: itemsPerPage,
      offset: currentIndex - 1,
      search: debouncedSearch,
      mobileapps: props.mobileapps,
    }).then((res) => {
      // console.log("online", res);
      if (res.success) {
        setCountData(res.total);
        props.setTableData((state) => {
          return {
            ...state,
            online: {
              loading: false,
              data: res.data,
            },
          };
        });
      }
    });
    // .catch((error) => console.log("error", error));
  }

  function resetPagination() {
    setSearch("");
    setItemsPerPage(10);
    setCurrentIndex(1);
    if (props.mobileapps) {
      getAllOnline();
    }
  }

  useEffect(() => {
    if (props.mobileapps) {
      getAllOnline();
    }
  }, [debouncedSearch, itemsPerPage, currentIndex, props.mobileapps]);

  return (
    <div>
      <WaitExport
        isOpen={exportInProgress}
        name="Relatório"
        preposicao="dos"
        finishExport={finishExport}
        setFinishExport={setFinishExport}
        setExportInProgress={setExportInProgress}
      />
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          margin: "10px 0",
        }}
      >
        <form
          className="app-search d-none d-lg-block events-input-search"
          onSubmit={(e) => e.preventDefault()}
        >
          <div className="position-relative">
            <input
              type="text"
              className="form-control"
              placeholder="Pesquisar..."
              value={search}
              onChange={(e) => setSearch(e.target.value.toLowerCase())}
            />
            <span className="bx bx-search-alt" />
          </div>
        </form>

        <div className="mobile-btn-section" style={{ width: "100%" }}>
          <Row className="mobile-btns-top">
            <form
              className="app-search"
              style={{ padding: "0px" }}
              onSubmit={(e) => e.preventDefault()}
            >
              <div className="position-relative">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Pesquisar..."
                  value={search}
                  onChange={(e) => setSearch(e.target.value.toLowerCase())}
                />
                <span className="bx bx-search-alt" />
              </div>
            </form>
          </Row>
          {props.tableData.online.data.length > 0 && (
            <>
              <SectionComponent
                sectionName="Opções"
                setIsOpen={setIsOpen}
                isOpen={isOpen}
              />
              <Collapse isOpen={isOpen}>
                <Row className="mobile-btns-top">
                  <div onClick={() => exportReport()}>
                    Exportar Participantes
                  </div>
                </Row>
              </Collapse>
            </>
          )}
        </div>

        <div className="header-btns-container">
          {props.tableData.online.data.length > 0 && (
            <>
              <Button
                color="success"
                className="btn-rounded waves-effect waves-light events-button-add"
                onClick={() => exportReport()}
              >
                Exportar Participantes
              </Button>
            </>
          )}
        </div>
      </div>

      <div
        style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
      >
        <a className="btn-reset-table" onClick={() => resetPagination()}>
          <i className="mdi mdi-refresh" style={{ marginRight: 5 }} />
          Refresh
        </a>
      </div>

      <div className="table-responsive" style={{ backgroundColor: "white" }}>
        <Table className="table mb-0">
          <thead className="table-light">
            <tr>
              <th>ID do Participante</th>
              <th>Nome</th>
              <th>Data e hora</th>
            </tr>
          </thead>

          <tbody>
            {props.tableData.online.loading ? (
              <LoadingSkelentonTable lines={3} cols={3} />
            ) : (
              props.tableData.online.data.map((e, i) => (
                <tr key={i}>
                  <td>{e.id}</td>
                  <td>{e.name}</td>
                  <td>{e.date ? formatDate(moment(e.date).format()) : ""}</td>
                </tr>
              ))
            )}
          </tbody>
        </Table>
      </div>
      <Row style={{ marginTop: 20 }}>
        <Col md={12}>
          <Pagination
            data={props.tableData.online.data}
            search={search}
            currentIndex={currentIndex}
            setCurrentIndex={setCurrentIndex}
            countData={countData}
            maxItemPerPage={itemsPerPage}
            setMaxItemPerPage={setItemsPerPage}
            maxPageButtonsToShow={3}
          />
        </Col>
      </Row>
    </div>
  );
}
