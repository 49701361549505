import React, { useState, useEffect } from "react";

import PropTypes from "prop-types";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

// Redux
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { useMsal } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
//tradutor
import { withTranslation } from "react-i18next";

// users
// import user1 from "../../../assets/image/avatar-1.jpg"

import defaultUserAvatar from "../../../assets/image/avatar-default.png";

//components
import EditProfile from "./EditProfile";
import EditContractSelected from "./EditContractSelected";

import { urlDomain } from "../../../utils/services/config";

import { getMe } from "../../../utils/services/loginUser";
function ProfileMenu(props) {
  // Declare a new state variable, which we'll call "menu"
  const { instance } = useMsal();
  const [menu, setMenu] = useState(false);

  const [username, setusername] = useState(props.nome);

  //componentes
  const [editProfile, setEditProfile] = useState(false);
  const [editContract, setEditContract] = useState(false);

  const [profile, setProfile] = useState("");

  function toggleEditProfile() {
    setEditProfile(!editProfile);
  }

  function toggleEditContract() {
    setEditContract(!editContract);
  }

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        const obj = JSON.parse(localStorage.getItem("authUser"));
        setusername(obj.displayName);
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        const obj = JSON.parse(localStorage.getItem("authUser"));
        setusername(obj.username);
      }
    }
  }, [props.success]);

  useEffect(() => {
    getProfile();
  }, []);

  async function getProfile() {
    const res = await getMe();
    if (res?.data?.id) {
      setProfile(res.data.id);
    }
  }

  useEffect(() => {}, [props.nome]);

  return (
    <React.Fragment>
      {editProfile && (
        <EditProfile isOpen={editProfile} toggle={toggleEditProfile} />
      )}

      {editContract && (
        <EditContractSelected
          isOpen={editContract}
          toggle={toggleEditContract}
        />
      )}

      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item waves-effect"
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={props.foto ? props.foto : defaultUserAvatar}
            alt="Header Avatar"
          />
          <span className="d-none d-xl-inline-block ms-2 me-1">{username}</span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem tag="a" onClick={() => setEditProfile(true)}>
            {" "}
            <i className="bx bx-user font-size-16 align-middle me-1" />
            Editar Perfil
          </DropdownItem>

          {/* fazer verificacao de master */}
          {profile === process.env.REACT_APP_PMCODE && (
            <DropdownItem tag="a" onClick={() => setEditContract(true)}>
              {" "}
              <i className="mdi mdi-cog-outline font-size-16 align-middle me-1" />
              Alterar contrato
            </DropdownItem>
          )}

          <div className="dropdown-divider" />
          <Link
            to="/logout"
            className="dropdown-item"
            onClick={() => {
              if (instance.getAllAccounts().length > 0) {
                instance.logoutRedirect({
                  account: instance.getActiveAccount(),
                  postLogoutRedirectUri: `https://admin-plus.${urlDomain}/logout`,
                  authority: "https://login.microsoftonline.com/common",
                });
              } else {
                window.location.href = `https://admin-plus.${urlDomain}/logout`;
              }
            }}
          >
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>Sair</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
}

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
};

const mapStatetoProps = (state) => {
  const { nome, foto } = state.global_user_data.data.foundLogin;
  const { msalInstance } = state;
  return { nome, foto, msalInstance };
};
const mapDispatchToProps = function (dispatch) {
  return {
    saveMsalInstance: (data) => {
      return dispatch({ type: "MSAL_INSTANCE", data });
    },
  };
};

export default connect(
  mapStatetoProps,
  mapDispatchToProps
)(withTranslation()(ProfileMenu));
