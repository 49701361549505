import React from 'react';
import ReactEcharts from 'echarts-for-react';

export default function SalesLineChart({
  data = {},
  title,
}) {
  const sales = !data.totalwithaccess > 0
  ? [
    {x: "Cartão de Crédito", y: 5000.7, percent: '30%'},
    {x: "PIX", y: 12569.3, percent: '70%'}
  ]
  : [];
  const getOptions = () => ({
    xAxis: {
      type: 'category',
      data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
    },
    yAxis: {
      type: 'value'
    },
    series: [
      {
        data: [150, 230, 224, 218, 135, 147, 260],
        type: 'line'
      }
    ]
  });
  return (
    <ReactEcharts option={getOptions()} style={{ width: '100%' }} />
  );
}
