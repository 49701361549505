import React, { useEffect, useRef, useState } from "react";

import {
  Modal,
  ModalHeader,
  ModalBody,
  Col,
  Row,
  Card,
  CardText,
  Form,
  CardBody,
} from "reactstrap";
import { v4 as uuidv4 } from "uuid";
//router dom
// import { Link } from "react-router-dom"

import { createParticipant } from "../../../utils/services/participants";

import Dropzone from "react-dropzone";
import ModeloUpload from "../../../assets/statics/modelo.xlsx";

import SweetAlert from "react-bootstrap-sweetalert";
import readXlsxFile from "read-excel-file";
import {
  allExcelColumns,
  excelColumnsPtBr,
  findRowsWithError,
  getUnregisteredEmails,
  getUnregisteredCpfs,
  verifyDuplicateCpfInDoc,
} from "./importParticipantsValidation";
import ModalExcelRows from "../Voo/ModalExcelRows";
import ModalRegisteredEmails from "./modalRegisteredParticipants";

export default function ImportParticipant(props) {
  const { isOpen, toggle, idEvent, active } = props;
  const [modalBackdrop, setModalBackdrop] = useState(false);
  const [selectedFiles, setselectedFiles] = useState([]);
  const [success_dlg, setsuccess_dlg] = useState(false);
  const [imgError, setImgError] = useState('');

  // rows of excel file
  const [totalRows, setTotalRows] = useState(0);
  const [totalSended, setTotalSended] = useState(0);

  // modal de erros no arquivo xlsx
  const [modalErrors, setModalErrors] = useState(false);
  const [errors, setErrors] = useState([]);
  const [btnSend, setBtnSend] = useState(false);
  const [btnCancel, setBtnCancel] = useState(false);

  // mensagem de emails cadastrados
  const [modalRegisteredEmails, setModalRegisteredEmails] = useState(false);
  const [emails, setEmails] = useState([]);
  //mensagem de cpfs cadastrados
  const [modalRegisteredCpfs, setModalRegisteredCpfs] = useState(false);
  const [modalRegisteredCpfsMessage, setModalRegisteredCpfsMessage] =
    useState('');
  const [cpfs, setCpfs] = useState([]);

  const importActivated = useRef(false);

  let timerID = null;

  function toggleBackdrop() {
    setModalBackdrop(!modalBackdrop);
  }

  function toggleEmails() {
    setModalRegisteredEmails(!modalRegisteredEmails);
  }

  useEffect(() => {
    setselectedFiles([]);
    setImgError('');
  }, [isOpen]);

  function handleAcceptedFiles(files) {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    setselectedFiles(files);
    setTotalRows(0);
    setTotalSended(0);
    setBtnSend(true);
    clearTimeout(timerID);
  }

  function cancelImport() {
    importActivated.current = false;
    active(importActivated.current);
    setselectedFiles([]);
    setTotalRows(0);
    setTotalSended(0);
    setBtnSend(false);
    setBtnCancel(false);
  }

  //formatar cpf
  function cpfFormat(cpf) {
    if (cpf) {
      //retira os caracteres indesejados...
      cpf = cpf.replace(/[^\d]/g, '');

      //realizar a formatação...
      return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    }
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }
  const sleep = (ms) => new Promise((r) => setTimeout(r, ms));
  async function uploadService() {
    importActivated.current = true;
    active(importActivated.current);
    clearTimeout(timerID);
    setBtnSend(false);
    setBtnCancel(true);

    const schema = {
      nome: {
        prop: 'name',
        type: String,
      },
      email: {
        prop: 'email',
        type: String,
      },
      tituloQrCode: {
        prop: 'titleQrCode',
        type: String,
      },
      codigoQrCode: {
        prop: 'codeQrCode',
        type: String,
      },
      informacoesDeAcesso: {
        prop: 'accessInfo',
        type: String,
      },
      senha: {
        prop: 'password',
        type: String,
      },
      grupo: {
        prop: 'group',
        type: String,
      },
      cpf: {
        prop: 'cpf',
        type: String,
      },
      empresa: {
        prop: 'company',
        type: String,
      },
      cargo: {
        prop: 'office',
        type: String,
      },
      bioDoChat: {
        prop: 'chat',
        type: String,
      },
      codigoInterno1: {
        prop: 'code1',
        type: String,
      },
      codigoInterno2: {
        prop: 'code2',
        type: String,
      },
      codigoInterno3: {
        prop: 'code3',
        type: String,
      },
    };

    let count = 0;

    const readXlsx = async () => {
      const { rows } = await readXlsxFile(selectedFiles[0], { schema });
      const validateRows = rows.map((row, i) => allExcelColumns(row, i));

      const [rowsWithError, validRows] = await findRowsWithError(
        validateRows,
        props.token,
        props.idEvent
      );

      const alreadyRegisteredEmails = await getUnregisteredEmails(
        validRows,
        props.token,
        props.idEvent
      );

      const duplicateCpfInDoc = await verifyDuplicateCpfInDoc(rows);

      if (duplicateCpfInDoc.length > 0) {
        setCpfs(duplicateCpfInDoc);
        setModalRegisteredCpfsMessage(
          `O(s) seguinte(s) CPF(s) está(ão) duplicado(s) na planilha:`
        );
        setModalRegisteredCpfs(true);
        return;
      } else {
        setModalRegisteredCpfsMessage('');
        setCpfs([]);
      }

      const alreadyRegisteredCpfs = await getUnregisteredCpfs(
        props.token,
        props.idEvent,
        rows
      );

      if (alreadyRegisteredCpfs.length > 0) {
        setCpfs(alreadyRegisteredCpfs);
        setModalRegisteredCpfsMessage(
          `O(s) seguinte(s) CPF(s) está(ão) em uso: `
        );
        setTimeout(() => {
          setModalRegisteredCpfs(true);
        }, 500);
        return;
      } else {
        setModalRegisteredCpfsMessage('');
        setCpfs([]);
      }

      setEmails(alreadyRegisteredEmails);
      setTotalRows(validRows.length);

      if (rowsWithError.length > 0 || rows.length === 0) {
        setErrors(rowsWithError);
        importActivated.current = false;
        active(importActivated.current);
        return setModalErrors(true);
      }
      let i = 0;
      for (const row of validRows) {
        await sleep(100);
        if (!importActivated.current) {
          break;
        }
        importParticipants(row, i, validRows.length, props.token);
        i++;
      }
      /* validRows.forEach(async (row, i) => {
        await sleep(1000 * i);
        console.log("kk");
        //importParticipants(row, i, validRows.length, props.token);
      }); */
    };

    readXlsx();

    const importId = uuidv4();

    async function importParticipants(row, index, totalRows, token) {
      await createParticipant(
        {
          email: row.email,
          nome: row.name,
          senha: row.password,
          foto: null,
          titleQrCode: row.titleQrCode,
          codeQrCode: row.codeQrCode,
          infoAcess: row.accessInfo,
          idEvento: idEvent,
          grupos: row.group,
          cpf: row.cpf,
          empresa: row.company,
          cargo: row.office,
          interesseChat: row.chat,
          codigoInterno1: row.code1,
          codigoInterno2: row.code2,
          codigoInterno3: row.code3,
          importId,
        },
        token
      ).then((e) => {
        if (e.message === 'SENDED_TO_QUEUE') {
          setTotalSended(++count);
        }
        if (totalRows === ++index) {
          setBtnCancel(false);
        }
        if (count === totalRows) {
          setsuccess_dlg(true);
          props.getAllParticipants();
        }
      });
      // .catch((error) => console.log(error));
    }
  }

  return (
    <div
      isOpen={isOpen}
      toggle={() => toggleBackdrop()}
      autoFocus={true}
      centered={true}
    >
      {success_dlg ? (
        <SweetAlert
          success
          title={'Upload'}
          onConfirm={() => {
            props.getAllParticipants();
            setsuccess_dlg(false);
            if (emails.length > 0) {
              setModalRegisteredEmails(true);
            } else {
              importActivated.current = false;
              active(importActivated.current);
              toggle();
            }
          }}
        >
          {'O arquivo foi enviado com sucesso!'}
        </SweetAlert>
      ) : null}
      {modalRegisteredCpfs ? (
        <SweetAlert
          warning
          title={'CPF(s) duplicados'}
          onConfirm={() => {
            setModalRegisteredCpfs(false);
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <a>{modalRegisteredCpfsMessage}</a>
            {cpfs.map((e, i) => (
              <a>
                {i + 1} - {cpfFormat(e)}
              </a>
            ))}
          </div>
        </SweetAlert>
      ) : null}
      {modalErrors && (
        <ModalExcelRows
          toggle={() => setModalErrors(false)}
          rows={errors.sort((a, b) => a.index - b.index)}
          columnNames={excelColumnsPtBr}
        />
      )}
      <ModalRegisteredEmails
        isOpen={modalRegisteredEmails}
        toggle={toggleEmails}
        emails={emails}
        setEmails={setEmails}
      />
      {/* <ModalHeader toggle={toggle}>Importar Participantes</ModalHeader> */}
      <div className="m-1 p-0">
        <Card className="p-1">
          <CardBody className="m-1 p-2">
            <CardText className="mb-2 p-10">
              O arquivo a ser importado deve seguir este{' '}
              <a href={ModeloUpload} download="modelo.xlsx">
                Modelo
              </a>
              , não modifique seu cabeçalho.
            </CardText>

            <Form>
              {selectedFiles.length === 0 && (
                <Dropzone
                  onDrop={(acceptedFiles) => {
                    handleAcceptedFiles(acceptedFiles);
                  }}
                >
                  {({ getRootProps, getInputProps }) => (
                    <div className="dropzone">
                      <div
                        className="dz-message needsclick mt-1"
                        {...getRootProps()}
                      >
                        <input {...getInputProps()} />
                        <div className="mb-4">
                          <i className="display-4 text-muted bx bxs-cloud-upload" />
                          <h4>Arraste o arquivo ou clique aqui e selecione.</h4>
                        </div>
                      </div>
                    </div>
                  )}
                </Dropzone>
              )}
              <div className="dropzone-previews mt-0" id="file-previews">
                {selectedFiles.map((f, i) => {
                  return (
                    <Card
                      className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                      key={i + '-file'}
                    >
                      <div className="p-1">
                        <Row className="align-items-center">
                          <Col xs="11">
                            <strong>{`Nome: ${f.name} - Tamanho: ${f.formattedSize}`}</strong>
                          </Col>
                          <Col xs="1">
                            <span
                              style={{
                                fontSize: '16px',
                                color: 'red',
                                fontWeight: '700',
                                cursor: 'pointer',
                              }}
                              className="mdi mdi-close"
                              onClick={() => {
                                setselectedFiles([]);
                                setBtnSend(false);
                              }}
                            />
                          </Col>
                        </Row>
                        {importActivated.current && (
                          <Row className="align-items-center">
                            <Col>
                              <strong>{`Total enviado: ${totalSended}/${totalRows}`}</strong>
                            </Col>
                          </Row>
                        )}
                      </div>
                    </Card>
                  );
                })}
              </div>
            </Form>

            <div className="text-center mt-3">
              <p style={{ color: 'red' }}>{imgError}</p>
              <button
                type="button"
                className="btn btn-primary waves-effect waves-light"
                disabled={!btnSend}
                onClick={() => uploadService()}
              >
                Enviar Participantes
              </button>
              <button
                type="button"
                className="btn btn-danger mx-1"
                disabled={!btnCancel}
                onClick={() => cancelImport()}
              >
                Cancelar
              </button>
            </div>
          </CardBody>
        </Card>
      </div>
    </div>
  );
}
